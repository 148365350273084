import Honeybadger from '@honeybadger-io/js';
import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import CreatePatientForm from 'components/LoginSso/AzureMedtronic/create-patient-form';
import useLoginViaMsal from 'components/LoginSso/AzureMedtronic/hooks/use-login-via-msal';
import useRecognizePatientOnBackend from 'components/LoginSso/AzureMedtronic/hooks/use-recognize-patient-on-backend';
import RecognizePatientResultCode from 'components/LoginSso/AzureMedtronic/types/recognize-patient-result-code';
import Loader from 'components/LoginSso/loader';
import { setHoneybadgerContext } from 'kb-redux/user.redux';
import client from 'kb-shared/graphql/client';
import { UPDATE_PATIENT_LAST_SIGN_IN } from 'kb-shared/graphql/mutations';
import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  justify-content: center;
`;

const STEPS = {
  started: 'started',
  recognizingPatientOnBackend: 'recognizingPatientOnBackend',
  creatingNewPatient: 'creatingNewPatient',
  loggedIn: 'loggedIn',
  failed: 'failed'
};

export const LoginSsoAzureMedtronic = () => {
  const history = useHistory();
  const [step, setStep] = useState(STEPS.started);
  const [error, setError] = useState('');

  const { responseState: loginViaMsalResponse, loginViaMsal } = useLoginViaMsal();
  const {
    responseState: recognizePatientOnBackendResponse,
    recognizePatientOnBackend
  } = useRecognizePatientOnBackend();

  useEffect(() => {
    if (step === STEPS.started) {
      loginViaMsal();
    } else if (step === STEPS.loggedIn) {
      window.sessionStorage.setItem('medtronicSsoIdToken', loginViaMsalResponse.idtoken as string);
      client
        .mutate({
          mutation: UPDATE_PATIENT_LAST_SIGN_IN
        })
        .catch(error => Honeybadger.notify(error, 'MedtronicUpdateLastSignIn'))
        .finally(() => history.push('/'));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [step]);

  useEffect(() => {
    if (step === STEPS.started && loginViaMsalResponse.finished) {
      if (loginViaMsalResponse.error) {
        setStep(STEPS.failed);
        setError(loginViaMsalResponse.error);
      } else {
        setStep(STEPS.recognizingPatientOnBackend);
        recognizePatientOnBackend(loginViaMsalResponse.idtoken as string);
      }
    }

    if (step === STEPS.recognizingPatientOnBackend && recognizePatientOnBackendResponse.finished) {
      if (
        recognizePatientOnBackendResponse.resultCode ===
        RecognizePatientResultCode.PatientRecognized
      ) {
        recognizePatientOnBackendResponse.patient &&
          setHoneybadgerContext(recognizePatientOnBackendResponse.patient.id.toString());
        setStep(STEPS.loggedIn);
      } else if (
        recognizePatientOnBackendResponse.resultCode ===
          RecognizePatientResultCode.PatientNotRecognized ||
        recognizePatientOnBackendResponse.resultCode === RecognizePatientResultCode.Failed
      ) {
        setStep(STEPS.failed);
        setError((recognizePatientOnBackendResponse.errors as Array<string>).join('. '));
      } else if (
        recognizePatientOnBackendResponse.resultCode ===
        RecognizePatientResultCode.PatientNotRecognizedButCanBeCreated
      ) {
        setStep(STEPS.creatingNewPatient);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [step, loginViaMsalResponse, recognizePatientOnBackendResponse]);

  const showLoader = step === STEPS.started || step === STEPS.recognizingPatientOnBackend;
  return (
    <Container>
      <div>
        {showLoader && <Loader labelText="Please wait, we are logging you in." />}
        {step === STEPS.creatingNewPatient && (
          <CreatePatientForm
            idtoken={loginViaMsalResponse.idtoken as string}
            onPatientSuccessfullyCreated={() => setStep(STEPS.loggedIn)}
          />
        )}
        {step === STEPS.failed && <div>Failed to log you in. {error}</div>}
        {step === STEPS.loggedIn && (
          <div>You are successfully logged in. Redirecting you to dashboard</div>
        )}
      </div>
    </Container>
  );
};
