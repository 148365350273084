import { useQuery } from '@apollo/client';
import React, { FormEvent, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { MessagePlus } from 'assets/icons/Icons';
import { Text } from 'components/v2/Typography';
import { CONVERSATIONS_QUERY } from 'screens/Messages/Messages.graphql';
import { Conversation, ConversationsResponse, MessageParams } from 'screens/Messages/Messages.type';
import { filterConversation, getCategoryInfo } from 'screens/Messages/Messages.utils';

import { ConversationItem } from '../ConversationItem/ConversationItem';
import { ConversationListHeader } from '../ConversationListHeader/ConversationListHeader';
import { EmptyConversationList } from '../EmptyConversationList/EmptyConversationList';
import {
  ContainerConversationItems,
  ConversationsTitleContainer,
  ConversationsTitleLeftSpacer,
  MessagesContainer,
  NoConversationsContainer,
  NoConversationsIconContainer
} from './ConversationList.styled';
import { Props } from './ConversationList.types';

export const ConversationList = ({ conversations }: Props) => {
  const { data, startPolling, stopPolling } = useQuery<ConversationsResponse>(CONVERSATIONS_QUERY);
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [filteredConversation, setFilteredConversation] = useState<Conversation[]>();
  const { conversationId } = useParams<MessageParams>();

  useEffect(() => {
    const filteredConversation = filterConversation(conversations || [], searchTerm);
    setFilteredConversation(filteredConversation);
  }, [searchTerm, conversations]);

  useEffect(() => {
    startPolling(10000);

    return () => stopPolling();
  }, [startPolling, stopPolling]);

  if (!data) return <></>;

  const getUnreadMessageNumber = (conversation: Conversation) => {
    const conversationFiltered = data.conversations.find(
      conversationItem => conversationItem.id === conversation.id
    );

    if (conversationFiltered?.id === conversationId) {
      return 0;
    }

    return conversationFiltered?.unreadCount || 0;
  };

  const getStatus = (conversation: Conversation) => {
    const conversationFiltered = data.conversations.find(
      conversationItem => conversationItem.id === conversation.id
    );

    return conversationFiltered?.status || 'open';
  };

  const onSearch = (e: FormEvent<HTMLInputElement>) => {
    const { value } = e.currentTarget;
    setSearchTerm(value);
  };

  return (
    <MessagesContainer isEmpty={!filteredConversation?.length}>
      <ConversationListHeader searchTerm={searchTerm} onSearch={onSearch} />

      <div>
        <ConversationsTitleLeftSpacer>
          <ConversationsTitle />
        </ConversationsTitleLeftSpacer>

        {!conversations.length && (
          <NoConversationsContainer>
            <NoConversationsIconContainer>
              <MessagePlus />
            </NoConversationsIconContainer>
            <Text tag="span" size="sm">
              No conversations just yet
            </Text>
          </NoConversationsContainer>
        )}

        {Boolean(conversations.length) && (
          <>
            {filteredConversation?.length ? (
              <ContainerConversationItems>
                {filteredConversation.map(conversation => {
                  const category = getCategoryInfo(conversation.category);
                  const unreadMessageCount = getUnreadMessageNumber(conversation);
                  const status = getStatus(conversation);

                  return (
                    <ConversationItem
                      key={conversation.id}
                      conversation={conversation}
                      category={category}
                      unreadMessageCount={unreadMessageCount}
                      status={status}
                    />
                  );
                })}
              </ContainerConversationItems>
            ) : (
              <EmptyConversationList
                description="We couldn't find any chats 
        matching your search."
              />
            )}
          </>
        )}
      </div>
    </MessagesContainer>
  );
};

const ConversationsTitle = () => (
  <ConversationsTitleContainer>
    <Text tag="span" size="md" fontStyle="bold">
      Conversations
    </Text>
  </ConversationsTitleContainer>
);
