export const INTERSTITIAL_MODAL_ACTIVATED_KEY = 'interstitialModalActivated';

export const INTRODUCTION = `Before you can access your Patient Portal, please read and sign the required consents, and provide information about your insurance and partner. Thank you for your cooperation!`;

export const DEPRECATED_MEMBERSHIP_ACTIVATION = '?step=confirm&enterprise_membership=true';

export const PARTNER_TAB_INFO_BOX_MESSAGE = `Partner information is needed for staff to complete a comprehensive verification of
  benefits. Partners are also required to complete testing, sign treatment plan consents and
  acknowledgments. If your partner doesn’t already have a Kindbody portal account, they will
  be sent a link to register. In order to prevent delay of care and verification of benefits,
  please provide timely notice of partner relationships.`;

export const INSURANCE_TAB_INFO_BOX_MESSAGE = `Your insurance information is required in order
  for us to start checking your coverage and verifying any benefits, if applicable. If you don’t 
  have insurance and will be paying for your treatment out of pocket, you can use the “I don’t 
  have insurance” toggle above to let our team know and proceed.`;
