import React from 'react';

import { Envelope } from 'assets/icons/Icons';
import { GoogleAuthButton } from 'components/GoogleAuthButton';
import { Button } from 'components/v2/Buttons/Button';

import { Props } from './SignUpButton.types';
import { ButtonsContainer } from './SignUpButtons.styled';

export const SignUpButtons = (props: Props) => {
  const { onSignUpWithEmail } = props;

  return (
    <ButtonsContainer>
      <GoogleAuthButton context="signup" />
      <Button
        label="Continue with Email"
        category="secondary"
        size="sm"
        fullWidth
        leftIcon={<Envelope type="solid" />}
        onClick={onSignUpWithEmail}
      />
    </ButtonsContainer>
  );
};
