import React from 'react';

import { PhoneInputBasic } from '../../Inputs';
import { FormControl, getFormControlIds } from '../FormControl';
import { Props } from './PhoneFieldBasic.types';

export const PhoneFieldBasic = ({
  status = 'default',
  country = 'US',
  label,
  id,
  required,
  helperText,
  description,
  value,
  onChange,
  onBlur,
  onFocus,
  placeholder,
  labelBg
}: Props) => {
  const ids = getFormControlIds(id);

  return (
    <FormControl
      status={status}
      label={label}
      required={required}
      helperText={helperText}
      description={description}
      labelId={ids.label}
      inputId={ids.input}
      helperTextId={ids.helperText}
      labelBg={labelBg}
    >
      <PhoneInputBasic
        country={country}
        numberInputId={ids.input}
        status={status}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        onFocus={onFocus}
      />
    </FormControl>
  );
};
