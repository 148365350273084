import styled from 'styled-components';

export const Container = styled.div``;

export const LinkContainer = styled.div`
  display: flex;
  gap: 16px;

  a:hover {
    text-decoration: underline;
  }

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.tabletPortrait}) {
    margin: 8px 0;
  }

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.smallDesktop}) {
    margin: 20px 0 8px 0;
    gap: 24px;
  }
`;

export const LinkTextContainer = styled.div`
  cursor: pointer;
`;
