import { gql, useQuery } from '@apollo/client';

import { ResultType, LoadedResults } from '../screens/Results/Results.types';

type ResponseData = {
  results: LoadedResults;
};

const ResultsQuery = gql`
  query useResults {
    results {
      afc: follicleCounts {
        name
        date
        units
        value
      }
      labs {
        type
        name
        date
        units
        value
        normalRange
        category
        categoryDisplayName
      }
    }
  }
`;

const useResults = () => {
  const { data } = useQuery<ResponseData>(ResultsQuery);

  if (!data) {
    return {
      afcResult: undefined,
      amhResult: undefined
    };
  }

  const { results } = data;
  const afcResult = results.afc[0];
  const amhResult = results.labs.find((value: ResultType) => {
    return value.type === 'amh';
  });

  return {
    afcResult,
    amhResult
  };
};

export { useResults };
export default useResults;
