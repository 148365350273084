import React from 'react';
import { useHistory } from 'react-router-dom';

import BubbleStepTracker from 'components/v2/BubbleStepTracker/BubbleStepTracker';
import MobileStepTracker from 'components/v2/MobileStepTracker/MobileStepTracker';

import { AuthFlowLayout } from '../AuthFlowLayout/AuthFlowLayout';
import {
  BubbleStepTrackerWrapper,
  Container,
  Content,
  MobileStepTrackerWrapper
} from './StepTrackerLayout.styled';
import { Props } from './StepTrackerLayout.types';

export const StepTrackerLayout = (props: Props) => {
  const { title, stepNames, selectedStep, renderMainContent } = props;
  const history = useHistory();

  return (
    <AuthFlowLayout
      title={title}
      onBack={() => history.goBack()}
      renderAboveTitleContent={(mobile: boolean) => {
        if (mobile)
          return (
            <MobileStepTrackerWrapper>
              <MobileStepTracker steps={stepNames} selectedStep={selectedStep} />
            </MobileStepTrackerWrapper>
          );

        return (
          <BubbleStepTrackerWrapper>
            <BubbleStepTracker
              steps={stepNames}
              selectedStep={selectedStep}
              onSelectStep={() => {}}
            />
          </BubbleStepTrackerWrapper>
        );
      }}
      renderMainContent={(mobile: boolean) => {
        return (
          <Container>
            <Content>{renderMainContent(mobile)}</Content>
          </Container>
        );
      }}
    />
  );
};
