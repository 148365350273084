import { Heading } from 'components/v2/Typography';
import { themes } from 'kb-shared';
import styled from 'styled-components';

import { MobileProps } from './AuthFlowLayout.types';

export const FormContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  width: 100%;
  box-sizing: content-box;
  ${({ mobile }: MobileProps) => `
    padding-left: ${mobile ? '0px' : '60px'};
    padding-right: ${mobile ? '0px' : '60px'};
  `};
`;

export const Content = styled.div`
  display: flex;
  width: 100%;
  ${({ mobile }: MobileProps) => `
    padding-top: 0px;
    flex-direction: ${mobile ? 'column' : 'row'};
    align-items: ${mobile ? 'center' : 'flex-start'};
  `}
`;

export const LeftContent = styled.div`
  flex: 3;
  @media screen and (max-width: ${themes.breakpoints.largePhonePortrait}) {
    width: 100%;
  }
`;

export const RightContent = styled.div`
  flex: 1;
`;

export const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding-top: 18px;
  ${({ mobile }: MobileProps) => `
    flex-direction: ${mobile ? 'column' : 'row'};
    margin-top: 30px;
  `}
`;

export const PageTitle = styled(Heading).attrs({ tag: 'h2', styledAs: 'h2' })`
  padding: 20px 0;
  justify-content: center;
  text-align: center;
`;

export const ContentContainer = styled.div`
  ${({ theme }) => theme.grid.pageContainer};
  flex: 1;
  padding-top: 40px;
  background: white;
`;

export const FormContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
`;
