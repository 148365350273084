/* eslint-disable @typescript-eslint/indent */
import React, { ReactNode } from 'react';

import { themes } from 'kb-shared';
import styled from 'styled-components';

import { ReactComponent as CycleIcon } from '../../assets/icons/cycle-yellow.svg';
const { colors, deprecatedColors } = themes;

interface Props {
  color?: string;
  completed: boolean;
  expanded?: boolean;
  icon?: ReactNode;
  substep?: boolean;
}

const FullTimeline = ({ completed, expanded, icon, substep }: Props) => {
  return (
    <TimelineContainer>
      <Timeline substep={substep} completed={completed} />
      <TimelineIconWrapper>
        {icon ? (
          <CustomIcon completed={completed}>{icon}</CustomIcon>
        ) : (
          <>
            <TimelineIcon completed={completed} />
            <TimelineOuterIcon completed={completed} expanded={expanded}>
              <CycleIcon />
            </TimelineOuterIcon>
          </>
        )}
      </TimelineIconWrapper>
    </TimelineContainer>
  );
};

const TimelineContainer = styled.div`
  width: 40px;
`;

const Timeline = styled.div`
  position: absolute;
  width: 6px;
  background-color: ${({ substep, completed }: Props) => {
    if (substep && completed) {
      return deprecatedColors.grayLight;
    } else if (substep) {
      return colors.yellow.lightestYellow;
    }
    return 'white';
  }};
  top: 0;
  bottom: 0;
  left: 17px;
`;

const TimelineIconWrapper = styled.div`
  display: flex;
  position: relative;
  align-items: center;
  z-index: 1;
`;

interface StatusProps {
  completed?: boolean;
  expanded?: boolean;
}

const TimelineIcon = styled.div`
  width: 12px;
  height: 12px;
  position: absolute;
  left: 10px;
  top: 6px;
  background-color: ${({ completed }: StatusProps) =>
    completed ? deprecatedColors.gray : colors.neutral.primaryNavy};
  border: 4px solid white;
  border-radius: 50%;
  box-sizing: content-box;
`;

const TimelineOuterIcon = styled.span<StatusProps>`
  width: 40px;
  position: absolute;
  top: -3px;
  path {
    fill: ${({ theme, completed, expanded }) => {
      if (completed) {
        return theme.deprecatedColors.gray;
      } else if (expanded) {
        return theme.colors.semantic.pink;
      }
      return theme.deprecatedColors.beige;
    }} !important;
  }
`;

const CustomIcon = styled.span<StatusProps>`
  margin: -3px 0;
  circle {
    fill: ${({ theme, completed }) => {
      if (completed) {
        return theme.deprecatedColors.gray;
      }
      return theme.colors.semantic.pink;
    }}
  path {
    fill: ${({ theme }) => theme.colors.neutral.primaryNavy} !important;
  }
`;

export const TIMELINE_OFFSET_MOBILE = '70px';
export const TIMELINE_OFFSET_DESKTOP = '270px';

export default FullTimeline;
