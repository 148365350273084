import { Text } from 'components/v2/Typography';
import styled, { css } from 'styled-components';

export const Container = styled.div<{ isEmpty?: boolean }>`
  display: flex;
  flex-direction: column;
  height: 100%;
  background: ${({ theme }) => theme.colors.neutral.lavenderWhite};

  ${({ isEmpty }) =>
    isEmpty &&
    css`
      display: none;
    `}

  @media (min-width: ${({ theme }) => theme.breakpoints.smallDesktop}) {
    display: grid;
    padding: 0;
    grid-template-rows: auto 1fr auto;
    overflow: auto;
    border-radius: 0 8px 8px 0;
    box-shadow: 0px 1px 3px 0px rgba(67, 71, 146, 0.16), 0px 1px 1px 0px rgba(10, 13, 61, 0.08);

    ${({ isEmpty }) =>
      isEmpty &&
      css`
        grid-template-rows: 60px 1fr;
      `}
  }
`;

export const Bottom = styled.div`
  padding: 16px;
  background: #fff;
  box-shadow: 0px -1px 3px -1px rgba(67, 71, 146, 0.16), 0px -1px 1px -1px rgba(10, 13, 61, 0.08);
  position: fixed;
  bottom: 0;
  width: 100%;
`;

export const EmptyHeader = styled.div`
  height: 80px;
  background-color: white;
  width: 100%;
  box-shadow: 0px 1px 3px 0px rgba(67, 71, 146, 0.16), 0px 1px 1px 0px rgba(10, 13, 61, 0.08);
`;

export const EmptyContent = styled.div`
  padding: 0 150px;
`;

export const ErrorMessage = styled(Text).attrs({ size: 'sm' })`
  display: flex;
  padding: 8px 16px;
  gap: 12px;
  border-radius: 8px;
  background: ${({ theme }) => theme.colors.semantic.pink};
  color: ${({ theme }) => theme.colors.semantic.darkRed};
  align-items: center;
  margin: 0 32px 32px 32px;
`;
