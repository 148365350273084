import React from 'react';

import { CreditCard, Visa, MasterCard, Amex, Discover, DinersClub, Jcb } from 'assets/icons/Icons';

type Props = {
  type: string;
};

export const CreditCardIcon = ({ type }: Props) => {
  const typeLowercase = type.toLowerCase();

  switch (typeLowercase) {
    case 'visa':
      return <Visa />;
    case 'mastercard':
      return <MasterCard />;
    case 'american express':
      return <Amex />;
    case 'discover':
      return <Discover />;
    case 'dinersclub':
      return <DinersClub />;
    case 'jcb':
      return <Jcb />;
    default:
      return <CreditCard />;
  }
};
