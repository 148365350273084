import { useQuery } from '@apollo/client';
import React, { useEffect } from 'react';

import { SelectField } from 'components/v2/Form';
import { GetDropdownOptionsResponse } from 'kb-shared';
import { GET_DROPDOWN_OPTIONS } from 'kb-shared/graphql/queries';
import { BugTracker } from 'kb-shared/utilities/bugTracker';

import { SelectWithOptionsProps } from './SelectWithOptions.types';

export const SelectWithOptions = (props: SelectWithOptionsProps) => {
  const { onOptionsLoaded, status, helperText } = props;
  const { loading, data, error } = useQuery<GetDropdownOptionsResponse>(GET_DROPDOWN_OPTIONS, {
    variables: { key: props.lookup_key },
    onError: error => {
      BugTracker.notify(error, `SelectWithOptions: ${props.lookup_key}`);
    }
  });

  useEffect(() => {
    if (loading) return;
    if (error) onOptionsLoaded?.(false);
    if (data && data.getDropdownOptions?.length > 0) onOptionsLoaded?.(true);
    else onOptionsLoaded?.(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, error, loading]);

  const allOptions = data?.getDropdownOptions || [];
  const selectedOption = allOptions.find(item => item.value === props.value);

  return (
    <SelectField
      id={props.inputId || ''}
      onChange={props.onSelect}
      options={allOptions}
      label={props.label}
      selected={selectedOption}
      placeholder={props.placeholder}
      status={status}
      helperText={helperText}
    />
  );
};
