import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { Loader } from 'components/Loader/Loader';
import { themes } from 'kb-shared';
import styled from 'styled-components';

import VideoPlayer from '../../components/VideoPlayer';
import { typography } from '../../styled';
import { showErrorToast } from '../../utilities/notificationUtils';
import OtherInstructionsRow from './components/OtherInstructionsRow';
import medications from './medications';

const {
  serif: {
    page: { H1 }
  },
  sans: { H3 }
} = typography;
const { colors, breakpoints } = themes;

const Container = styled.div`
  ${({ theme }) => theme.grid.pageContainer};
  margin-top: 100px;
  display: flex;
  flex-direction: column;
`;

const ContentContainer = styled.div`
  align-content: stretch;
  align-items: stretch;
  display: flex;

  @media (max-width: ${breakpoints.tabletLandscape}) {
    flex-direction: column;
  }
`;

const ItemsContainer = styled.div`
  background: ${colors.neutral.primaryNavy};
  display: flex;
  flex-direction: column;
  padding: 20px 24px;

  @media (max-width: ${breakpoints.tabletLandscape}) {
    /* Weird Chrome bug here that creates a bit of whitespace*/
    margin-top: -2px;
  }
`;

const ItemsTitle = styled(H3)`
  color: ${colors.white};
  margin: 0;
`;

const ItemsList = styled.ul`
  list-style: none;
  padding: 0;
`;

const ListItem = styled.li`
  color: ${colors.white};
  padding-bottom: 5px;
  &::before {
    background: ${colors.yellow.primary};
    content: '';
    display: inline-block;
    height: 8px;
    margin-right: 10px;
    width: 8px;
  }
`;

const MedicationInstruction = () => {
  const history = useHistory();
  const [medication, setMedication] = useState();

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const index = params.get('med_index');
    if (!index) {
      showErrorToast("We're sorry something went wrong. Please try again.");
      setTimeout(() => {
        history.goBack();
      }, 5000);
    }
    // @ts-ignore
    setMedication(medications[index]);
  }, [history]);

  if (!medication) return <Loader absoluteCentered />;

  // @ts-ignore
  const { displayName, videoHash, items } = medication;
  return (
    <Container>
      <H1>{`${displayName} Tutorial`}</H1>
      <ContentContainer>
        <VideoPlayer videoHash={videoHash} />
        <ItemsContainer>
          <ItemsTitle>Things you’ll need:</ItemsTitle>
          <ItemsList>
            {// @ts-ignore
            items.map((item: string) => (
              <ListItem key={`list-item-${item}`}>{item}</ListItem>
            ))}
          </ItemsList>
        </ItemsContainer>
      </ContentContainer>
      <OtherInstructionsRow
        // @ts-ignore
        viewingMedication={medication}
      />
    </Container>
  );
};

export default MedicationInstruction;
