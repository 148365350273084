import React from 'react';

import { postEmbryoTransferInstructions } from 'kb-shared';

import instructionRenderer from '../instructionRenderer';
import InstructionsModal, { InstructionRow } from './InstructionsModal';
interface ShowProps {
  show: boolean;
  hide: () => void;
}

const POST_PROCEDURE_INSTRUCTIONS: InstructionRow[] = postEmbryoTransferInstructions.map(
  instructionRenderer
);

const PostEmbryoTransferInstructionsModal = ({ show, hide }: ShowProps) => {
  return (
    <InstructionsModal
      title="Post-procedure instructions"
      show={show}
      hide={hide}
      rows={POST_PROCEDURE_INSTRUCTIONS}
    />
  );
};

export default PostEmbryoTransferInstructionsModal;
