/**
 *  To avoid conflict between web-only imports and native
 * we do not import 'store from 'store'' here.
 * Instead the implementation must be provided by the web apps
 *  */

let _clearPwLocalStorage = () => {};

export function setClearPwLocalStorage(func: () => void) {
  _clearPwLocalStorage = func;
}

export const clearPwLocalStorage = () => {
  _clearPwLocalStorage();
};
