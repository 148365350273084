import styled from 'styled-components';

export const TooltipContainer = styled.div`
  position: relative;
  display: inline-block;
`;

export const TooltipBox = styled.div<{
  position: 'top' | 'bottom' | 'left' | 'right';
  backgroundColor?: string;
  minWidth?: string;
}>`
  position: absolute;
  background-color: ${({ backgroundColor, theme }) =>
    backgroundColor || theme.colors.neutral.primaryNavy};
  z-index: 1000;
  padding: 16px;
  border-radius: 8px;
  white-space: normal;
  max-width: 300px;
  min-width: ${({ minWidth }) => minWidth || 'auto'};
  ${({ position }) => tooltipPositionStyles(position)}
`;

export const TooltipArrow = styled.div<{
  position: 'top' | 'bottom' | 'left' | 'right';
  borderColor?: string;
}>`
  width: 0;
  height: 0;
  position: absolute;
  border-style: solid;
  ${({ position, borderColor, theme }) =>
    arrowPositionStyles(position, borderColor || theme.colors.neutral.primaryNavy)}
`;

const tooltipPositionStyles = (position: 'top' | 'bottom' | 'left' | 'right') => {
  switch (position) {
    case 'top':
      return `
        bottom: 100%;
        left: 50%;
        transform: translateX(-50%);
        margin-bottom: 10px;
      `;
    case 'bottom':
      return `
        top: 100%;
        left: 50%;
        transform: translateX(-50%);
        margin-top: 10px;
      `;
    case 'left':
      return `
        right: 100%;
        top: 50%;
        transform: translateY(-50%);
        margin-right: 10px;
      `;
    case 'right':
      return `
        left: 100%;
        top: 50%;
        transform: translateY(-50%);
        margin-left: 10px;
      `;
  }
};

const arrowPositionStyles = (
  position: 'top' | 'bottom' | 'left' | 'right',
  borderColor: string
) => {
  switch (position) {
    case 'top':
      return `
        top: 100%;
        left: 50%;
        border-width: 5px 5px 0 5px;
        border-color: ${borderColor} transparent transparent transparent;
        transform: translateX(-50%);
      `;
    case 'bottom':
      return `
        bottom: 100%;
        left: 50%;
        border-width: 0 5px 5px 5px;
        border-color: transparent transparent ${borderColor} transparent;
        transform: translateX(-50%);
      `;
    case 'left':
      return `
        left: 100%;
        top: 50%;
        border-width: 5px 0 5px 5px;
        border-color: transparent transparent transparent ${borderColor};
        transform: translateY(-50%);
      `;
    case 'right':
      return `
        right: 100%;
        top: 50%;
        border-width: 5px 5px 5px 0;
        border-color: transparent ${borderColor} transparent transparent;
        transform: translateY(-50%);
      `;
  }
};
