import React from 'react';

const LeftBracket = (props: { color?: string; width?: number; height?: number }) => {
  const width = props.width || 34;
  const height = props.height || 88;
  return (
    <svg
      width={`${width}px`}
      height={`${height}px`}
      viewBox={'0 0 34 88'}
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>Left Parenthesis</title>
      <defs />
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g transform="translate(-1152.000000, -113.000000)">
          <g transform="translate(1063.000000, 0.000000)" fill={props.color || '#FDE464'}>
            <g transform="translate(193.500000, 165.000000) scale(-1, 1) translate(-193.500000, -165.000000) translate(57.000000, 113.000000)">
              <path
                d="M238.533685,10.9069911 L241,9.3634053 L234.522946,0 L232.402481,1.32223697 C220.814768,8.55647769 207,21.7929013 207,43.767332 C207,65.7405916 220.817036,78.9770152 232.405883,86.2124271 L234.551294,87.5487179 L240.868463,78.1466644 L238.438434,76.6253307 C225.763274,68.6883954 218.495864,56.7109662 218.495864,43.767332 C218.495864,30.8576614 225.800694,18.8814034 238.533685,10.9069911"
                transform="translate(224.000000, 43.774359) scale(-1, 1) translate(-224.000000, -43.774359) "
              />
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default LeftBracket;
