import { themes } from 'kb-shared';
import styled from 'styled-components';

import Text from '../../Primitives/FormText';

export const SubheaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
`;

export const Title = styled(Text)`
  max-width: 700px;

  font-size: 28px;
  text-align: left;
  margin: 8px 0px 8px 0px;

  @media screen and (min-width: ${themes.breakpoints.largePhonePortrait}) {
    font-size: 48px;
    margin: 16px 0px 16px 0px;
  }
`;
