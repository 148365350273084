import React from 'react';
import { connect } from 'react-redux';

import { getTimeZone } from 'hooks/getTimeZone';
import { ReduxState } from 'kb-redux';

import { BookingTimeSelectionContainer, TimeZoneText } from './BookingTimeSelection.styled';
import { Props } from './BookingTimeSelection.types';
import { DaySection } from './DaySection';

function BookingTimeSelection(props: Props) {
  const date = props;

  const { formattedTimeZone, displayTimeZone } = getTimeZone(
    props?.selectedLabTimeZone,
    props.appointmentType
  );

  return (
    <BookingTimeSelectionContainer>
      <DaySection
        day={date}
        selectedTimeSlotId={props.selectedTimeSlot ? props.selectedTimeSlot.id : null}
        onSelect={props.onSelectTimeSlot}
        showInTimezone={formattedTimeZone}
      />
      {date.timeslots.length > 0 && <TimeZoneText>All times are {displayTimeZone}</TimeZoneText>}
    </BookingTimeSelectionContainer>
  );
}

const mapStateToProps = (state: ReduxState) => ({
  patient: state.patient
});

export default connect(mapStateToProps)(BookingTimeSelection);
