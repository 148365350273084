import styled from 'styled-components';

export const TotalBalanceContainer = styled.div`
  width: 208px;
  height: 94px;
  text-transform: uppercase;
  border: 1px solid ${({ theme }) => theme.colors.neutral.lavenderLight};

  color: ${({ theme }) => theme.colors.neutral.primaryNavy};
  text-align: center;
  padding: 1.5em;

  background: ${({ theme }) => theme.colors.white};

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tabletLandscape}) {
    width: 100%;
  }
`;
