import { gql } from '@apollo/client';

export const SET_DEFAULT_CARD = gql`
  mutation($stripeIdentifier: String!) {
    kbStripeMakeDefaultCard(stripeIdentifier: $stripeIdentifier) {
      succeeded
    }
  }
`;

export const DELETE_CARD = gql`
  mutation($stripeIdentifier: String!) {
    kbStripeDeleteCard(stripeIdentifier: $stripeIdentifier) {
      succeeded
    }
  }
`;

export const ADD_CARD = gql`
  mutation($stripeTokenId: String!) {
    kbStripeCreateCard(stripeTokenId: $stripeTokenId) {
      kbStripeCard {
        stripeIdentifier
        brand
        default
      }
    }
  }
`;
