import styled from 'styled-components';

export const DashboardButton = styled.a`
  margin: 36px auto;
  background: white;
  display: flex;
  cursor: pointer;
  text-decoration: underline;
  justify-content: center;
  align-items: center;
  text-align: center;
  :hover {
    font-weight: bold;
  }
  font-size: 16px;
`;
