import React, { ChangeEvent } from 'react';

import { Container, Checkbox, Background, Knob, ToggleLabel } from './Toggle.styled';

export interface Props {
  label: string;
  isChecked: boolean;
  onChange: (arg: boolean) => void;
  name: string;
  disabled?: boolean;
}

export const Toggle = (props: Props) => {
  const { isChecked, onChange, label, name, disabled } = props;

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    onChange(event.target.checked);
  };

  return (
    <Container htmlFor={`checkbox-${name}`}>
      <Checkbox
        id={`checkbox-${name}`}
        disabled={disabled}
        checked={isChecked}
        onChange={handleChange}
      />
      <Background>
        <Knob />
      </Background>
      {label && <ToggleLabel>{label}</ToggleLabel>}
    </Container>
  );
};
