/* eslint-disable @typescript-eslint/indent */
import moment from 'moment-timezone';
import React from 'react';

import { Appointment } from 'kb-shared';
import styled from 'styled-components';

import kindbody from '../../../assets/logos/kindbody.svg';
import Modal from '../../../components/Modal';
import { CancellationPolicy } from './CancellationPolicy/CancellationPolicy';

type Props = {
  show: boolean;
  onClickClose: () => void;
  appointment?: Appointment | null;
};

const CancellationPolicyModal = (props: Props) => {
  const { show, onClickClose, appointment } = props;
  const emailBody = appointment
    ? `&body=%0D%0A%0D%0A%0D%0A%0D%0AAppointment # ${appointment.id}%0D%0A${moment(
        appointment.startTime
      ).format('dddd MMMM d, h:mm A z')}`
    : '';
  return (
    <Modal onRequestClose={onClickClose} open={show} label="Cancellation Policy">
      <Container className="modal__wrapper">
        <img className="modal__logo" src={kindbody} alt="KindBody Logo" />
        <h2 className="modal__title h2">Cancellation Policy</h2>
        <div className="modal__content type__body">
          <CancellationPolicy
            emailBody={emailBody}
            noCancelNorRescheduleVariant={
              appointment != null && !appointment.cancelable && !appointment.reschedulable
            }
          />
        </div>
        <button className="modal__button close button type__button" onClick={onClickClose}>
          Close
        </button>
      </Container>
    </Modal>
  );
};

const Container = styled.div`
  margin-top: 20px;
`;

export default CancellationPolicyModal;
