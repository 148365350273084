import React, { RefObject, Component } from 'react';

import styled from 'styled-components';

type Props = {
  topBreakPoint: number;
  bottomBreakPoint: number;
  onActive: Function;
};

type State = {
  isActive: boolean;
};

export default class ScrollItem extends Component<Props, State> {
  wrapRef: RefObject<HTMLDivElement> & RefObject<ScrollItem>;

  constructor(props: Props) {
    super(props);
    this.state = {
      isActive: false
    };
    this.wrapRef = React.createRef();
  }

  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll.bind(this));
    this.handleScroll();
  }

  handleScroll() {
    if (this.wrapRef && this.wrapRef.current) {
      const { top, bottom } = this.wrapRef.current.getBoundingClientRect();
      if (top < this.props.topBreakPoint && bottom > this.props.bottomBreakPoint) {
        if (!this.state.isActive) {
          this.setState({ isActive: true });
          this.props.onActive();
        }
      } else if (
        (bottom < this.props.bottomBreakPoint || top > this.props.topBreakPoint) &&
        this.state.isActive
      ) {
        this.setState({ isActive: false });
      }
    }
  }

  renderChildren() {
    /* Pass isActive to all children */
    // @ts-ignore
    return React.cloneElement(this.props.children, { isActive: this.state.isActive });
  }

  render() {
    const opacity = this.state.isActive ? 1.0 : 0.2;
    return (
      <Container ref={this.wrapRef}>
        <AnimatedWrapper isHidden={!this.state.isActive} opacity={opacity}>
          {this.renderChildren()}
        </AnimatedWrapper>
      </Container>
    );
  }
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
`;

interface AnimatedWrapperProps {
  isHidden: boolean;
  opacity: number;
}

const AnimatedWrapper = styled.div`
  padding: 42px 0px 18px 0px;
  opacity: ${(props: AnimatedWrapperProps) => props.opacity};
  width: 100%;
`;
