import React, { useState } from 'react';

import { BulletText } from 'components/v2/BulletText/BulletText';
import { utils } from 'kb-shared';

import { PasswordField } from '../PasswordField/PasswordField';
import { Note, PasswordRequirement, Divider } from './PasswordFieldWithHints.styled';
import { PasswordFieldWithHintsProps } from './PasswordFieldWithHints.types';

const { PASSWORD_ERRORS, getPasswordErrors } = utils;

export const PasswordFieldWithHints = ({
  inputId,
  label,
  placeholder,
  value,
  onChange,
  status
}: PasswordFieldWithHintsProps) => {
  const [errors, setErrors] = useState<string[]>([]);
  const failedValidation = status === 'error';

  const onPasswordChange = (event: React.FormEvent<HTMLInputElement>) => {
    const passwordErrors = getPasswordErrors((event.target as HTMLInputElement).value);
    setErrors(passwordErrors);
    onChange(event);
  };

  const passwordInputStatus = failedValidation || errors.length ? 'error' : 'default';
  const isPasswordLengthInvalid =
    (errors.length === 0 && failedValidation) ||
    errors.includes(PASSWORD_ERRORS.EQUAL_OR_GREATER_THAN);
  const isPasswordWithoutNumeric =
    (errors.length === 0 && failedValidation) ||
    errors.includes(PASSWORD_ERRORS.CONTAINS_NUMERIC_CHARACTERS);
  const isPasswordWithoutUpperAndLowerCase =
    (errors.length === 0 && failedValidation) ||
    errors.includes(PASSWORD_ERRORS.CONTAINS_UPPER_AND_LOWER_CASE_CHARACTERS);
  const isPasswordWithoutSpecialCharacter =
    (errors.length === 0 && failedValidation) ||
    errors.includes(PASSWORD_ERRORS.CONTAINS_SPECIAL_CHARACTERS);

  return (
    <>
      <PasswordField
        inputId={inputId}
        label={label}
        placeholder={placeholder}
        value={value}
        onChange={onPasswordChange}
        status={passwordInputStatus}
      />
      <PasswordRequirement>
        <Note tag="p" size="xs" fontStyle="lightItalic">
          For security purposes, passwords must contain:
        </Note>
        <ul>
          <li>
            <BulletText text=" 8 or more characters" error={isPasswordLengthInvalid} />
          </li>
          <li>
            <BulletText text=" numbers" error={isPasswordWithoutNumeric} />
          </li>
          <li>
            <BulletText
              text=" lower & uppercase letters"
              error={isPasswordWithoutUpperAndLowerCase}
            />
          </li>
          <li>
            <BulletText
              text=" special characters (e.g. ! @ # $ %)"
              error={isPasswordWithoutSpecialCharacter}
            />
          </li>
        </ul>
      </PasswordRequirement>
      <Divider />
    </>
  );
};
