import { useQuery } from '@apollo/client';
import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import { Button } from 'components/v2/Buttons/Button';
import { usePatient } from 'hooks';
import { AppointmentType } from 'kb-shared';
import { QUERY_MEMBERSHIP } from 'kb-shared/graphql/queries';
import { QueryMembershipResponse } from 'kb-shared/graphql/queries_types';
import { appointmentSupportedInPartnerClinics } from 'kb-shared/utilities/appointment.helper';
import { AdditionalInfoPanel } from 'screens/Book/components/AdditionalInfoPanel';
import HelpText from 'screens/Book/components/HelpText';
import { analytics } from 'utilities/analytics';

import { BookingQuizCategory } from '../BookingQuiz.types';
import { AppointmentTypeCard } from './AppointmentTypeCard';
import { PartnerSearchButton } from './PartnerSearchButton';
import { Question } from './Question';
import { AppointmentTypeCards, BackButtonContainer } from './Question.styled';

interface QuizResultProps {
  appointmentTypes: Array<AppointmentType>;
  category?: BookingQuizCategory;
  isLoggedIn: boolean | undefined;
}

export const QuizResult = ({ appointmentTypes, category, isLoggedIn }: QuizResultProps) => {
  const linkHistory = useHistory();
  const { patient } = usePatient();

  const { data: membershipData } = useQuery<QueryMembershipResponse>(QUERY_MEMBERSHIP, {
    variables: { membershipId: patient?.patientMembership?.membershipId },
    skip: !patient?.patientMembership?.membershipId
  });

  const showPartnerClinicList =
    patient?.patientMembership &&
    patient?.patientMembership?.membershipId &&
    patient?.patientMembership?.employer &&
    !membershipData?.membership.hidePartnerClinics &&
    category === 'fertility' &&
    appointmentTypes.length > 0 &&
    appointmentSupportedInPartnerClinics(appointmentTypes[0].id);

  useEffect(() => {
    appointmentTypes.forEach(appointmentType => {
      analytics.page(analytics.PAGES.APPOINTMENT_RECOMMENDATION, {
        appointment_category: category,
        appointment_type_name: appointmentType.name,
        appointment_type_ids: appointmentTypes.map(apptType => apptType.id)
      });
    });
  }, [appointmentTypes, category]);

  const handleClick = () => {
    const appointmentId = [...appointmentTypes].pop()?.id;
    const paramsString = `step=location&appointment_id=${appointmentId}&partner_clinic_search=search`;
    linkHistory.replace(`/Book?${paramsString}`, {
      data: { partnerClinicSearch: 'search', selectedStep: 'location' }
    });
  };

  return (
    <div>
      <Question category={category} text="We recommend..." />

      <AppointmentTypeCards>
        {appointmentTypes.map(appointmentType => (
          <AppointmentTypeCard key={appointmentType.id} appointmentType={appointmentType} />
        ))}
      </AppointmentTypeCards>

      {category === 'wellness' && (
        <AdditionalInfoPanel>
          In order to provide you with the earliest availability for your appointment, the
          appointment times listed include all providers for this location. If you have a specific
          provider preference, please call us to book: <a href="tel://855-563-263">855-563-263</a>
        </AdditionalInfoPanel>
      )}
      {!isLoggedIn && (
        <HelpText
          text="Looking for another service?"
          linkText="View all Services &amp; Pricing"
          linkUrl="https://kindbody.com/services-pricing/"
        />
      )}
      {isLoggedIn && showPartnerClinicList && (
        <PartnerSearchButton
          linkText="Find a Partner Clinic"
          text={`Looking for another clinic?`}
          onClick={() => handleClick()}
        />
      )}

      <BackButtonContainer>
        <Button
          label="Back"
          category="secondary"
          fullWidth
          size="lg"
          onClick={() => linkHistory.goBack()}
        />
      </BackButtonContainer>
    </div>
  );
};
