import Honeybadger from '@honeybadger-io/js';

export const currencyNumberFormat = (value: number | string) => {
  try {
    if (typeof value === 'string') {
      value = parseFloat(value);
    }

    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD'
    }).format(value);
  } catch (error) {
    console.error(error);
    Honeybadger.notify(error as Error, 'InvoicesNumberFormat', {
      params: { value }
    });

    return 'error';
  }
};
