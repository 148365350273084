import { gql } from '@apollo/client';

export const PAYER_QUERY = gql`
  query payerCompanies {
    payerCompanies {
      id
      name
    }
  }
`;
