import { gql, useMutation, useQuery } from '@apollo/client';
import { useCallback } from 'react';

import { BugTracker } from 'kb-shared/utilities/bugTracker';

export const PREGNANCY_CALCULATOR_QUERY = gql`
  query pregnancyCalculator {
    pregnancyCalculator {
      enabled
      cancelEnabled
      expectedDeliveryDate
      gestationalAgeInDays
    }
  }
`;

const UPDATE_PREGNANCY_ACKNOWLEDGEMENT_MUTATION = gql`
  mutation updatePregnancyAcknowledgement($isPregnant: Boolean!) {
    updatePregnancyAcknowledgement(isPregnant: $isPregnant) {
      isPregnancyAcknowledged
    }
  }
`;

export interface PregnancyCalculatorQueryResponse {
  pregnancyCalculator: {
    enabled: boolean;
    cancelEnabled: boolean;
    expectedDeliveryDate: string | null;
    gestationalAgeInDays: number | null;
  };
}

interface UpdatePregnancyAcknowledgementResponse {
  updatePregnancyAcknowledgement: {
    isPregnancyAcknowledged: boolean;
  };
}

export type PregnancyCalculatorState =
  | 'loading'
  | 'no-confirmed-pregnancy'
  | 'lmp-entered'
  | 'no-lmp-entered';

const calculateCurrentState = (
  data: PregnancyCalculatorQueryResponse | undefined,
  loading: boolean
): PregnancyCalculatorState => {
  if (loading) {
    return 'loading';
  } else if (!data?.pregnancyCalculator.enabled) {
    return 'no-confirmed-pregnancy';
  } else if (data?.pregnancyCalculator.expectedDeliveryDate) {
    return 'lmp-entered';
  }

  return 'no-lmp-entered';
};

export const usePregnancyCalculator = (patientIsFemale: boolean) => {
  const { data, loading, error } = useQuery<PregnancyCalculatorQueryResponse>(
    PREGNANCY_CALCULATOR_QUERY,
    {
      onError: error =>
        BugTracker.notify(error, 'UsePregnancyCalculator - PREGNANCY_CALCULATOR_QUERY'),
      skip: !patientIsFemale
    }
  );

  const [pregnancyAcknowledgmentMutation] = useMutation<UpdatePregnancyAcknowledgementResponse>(
    UPDATE_PREGNANCY_ACKNOWLEDGEMENT_MUTATION,
    {
      refetchQueries: [{ query: PREGNANCY_CALCULATOR_QUERY }],
      onError: error =>
        BugTracker.notify(
          error,
          'usePregnancyCalculator - UPDATE_PREGNANCY_ACKNOWLEDGEMENT_MUTATION'
        )
    }
  );

  const updatePregnancyAcknowledgment = useCallback(
    (isPregnant: boolean) => pregnancyAcknowledgmentMutation({ variables: { isPregnant } }),
    [pregnancyAcknowledgmentMutation]
  );

  const state = calculateCurrentState(data, loading);
  const gestationalAgeInDays = data?.pregnancyCalculator.gestationalAgeInDays ?? undefined;
  const isCancelEnabled = !!data?.pregnancyCalculator.cancelEnabled;

  return {
    state,
    gestationalAgeInDays,
    estimatedDueDate: data?.pregnancyCalculator.expectedDeliveryDate
      ? new Date(data.pregnancyCalculator.expectedDeliveryDate)
      : undefined,
    isCancelEnabled,
    updatePregnancyAcknowledgment,
    error
  };
};
