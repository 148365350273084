import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  gap: 0.5em;
  align-items: center;
  cursor: pointer;
  user-select: none;

  svg {
    color: ${({ theme }) => theme.colors.neutral.lighterNavy};
  }
`;

export const Icon = styled.span`
  svg {
    color: ${({ theme }) => theme.colors.neutral.primaryNavy};
  }
`;
