import * as React from 'react';
import { BrowserRouter, Switch, Redirect, Route } from 'react-router-dom';

import useHomeMarket from 'hooks/useHomeMarket';
import { BookingNavigationTracker } from 'Routes/BookingNavigationTracker/BookingNavigationTracker';
import { InitialModal } from 'screens/InitialModals';

import { AppRoute } from './Routes/AppRoute';
import { Redirects, Routes } from './Routes/Routes';
import { ScrollToTop } from './ScrollToTop';

export const Router = () => {
  const { vios: isViosPatient } = useHomeMarket();

  return (
    <BrowserRouter>
      <InitialModal />
      <BookingNavigationTracker />
      <ScrollToTop />
      <Switch>
        {Redirects}
        {Routes.filter(route => !isViosPatient || !route.hiddenFromVios).map(route => {
          const { path, component, layout, page, ...rest } = route;
          return (
            <AppRoute
              exact
              key={path as string}
              path={path as string}
              component={component}
              layout={layout}
              page={page}
              {...rest}
            />
          );
        })}
        <Route render={() => <Redirect to="/" />} />
      </Switch>
    </BrowserRouter>
  );
};
