import { gql, useQuery } from '@apollo/client';

import { Clinic } from 'kb-shared';
import { BugTracker } from 'kb-shared/utilities/bugTracker';

const QUERY_CLINICS = gql`
  query clinics($appointmentTypeId: Int!, $labId: Int!, $locationId: Int) {
    clinics: locations(
      appointmentTypeId: $appointmentTypeId
      labId: $labId
      locationId: $locationId
    ) {
      id
      name
      address
      timeZone
      imageUrl
      patientFacing
    }
  }
`;

export const useLocations = (appointmentTypeId?: number, labId?: number, locationId?: number) => {
  const { data, loading, error } = useQuery<{
    clinics: Array<Clinic>;
  }>(QUERY_CLINICS, {
    skip: !appointmentTypeId || !labId,
    variables: {
      appointmentTypeId: appointmentTypeId,
      labId: labId,
      locationId: locationId
    },
    onError: error => {
      BugTracker.notify(error, 'Failed to load clinics');
    }
  });

  return { data: data?.clinics, loading, error };
};
