import styled from 'styled-components';

export const Container = styled.div`
  max-width: 580px;
`;

export const TopBar = styled.div`
  display: flex;
  align-items: center;
  padding: 16px;
`;

export const Body = styled.div`
  padding: 0px 20px 15px 20px;

  @media ${({ theme }) => theme.queries.minTablet} {
    padding: 0px 84px 15px 84px;
  }
`;

export const Header = styled.h1`
  margin-bottom: 30px;
  text-align: center;

  font-size: 24px;
  line-height: 32px;

  @media ${({ theme }) => theme.queries.minTablet} {
    font-size: 28px;
    line-height: 37px;
  }
`;

export const CloseIconButton = styled.button`
  padding: 10px 8px 8px 0px;
  margin-left: auto;

  svg path {
    fill: ${({ theme }) => theme.colors.neutral.primaryNavy};
  }
`;

export const Description = styled.p`
  margin-bottom: 23px;
  text-align: center;
  font-size: 16px;
  line-height: 20px;

  @media ${({ theme }) => theme.queries.minTablet} {
    margin-bottom: 32px;
  }
`;

export const Instructions = styled.p`
  margin-bottom: 15px;
  text-align: center;
  font-size: 16px;
  line-height: 20px;
  font-weight: bold;
`;
