import React from 'react';

import { PatientsMembership } from 'kb-shared';
import { momentFormatted } from 'kb-shared/utilities/momentHelpers';

import { MenuUserMembership } from './User.styled';

const UserMembership = (props: UserMembershipProps) => {
  const { membership } = props;
  if (membership == null) return null;

  return membershipText(membership);
};

interface UserMembershipProps {
  membership: PatientsMembership | undefined;
}

const membershipText = (patientMembership: PatientsMembership | undefined) => {
  if (!patientMembership) {
    return null;
  }

  return <MenuUserMembership>{memberText(patientMembership)}</MenuUserMembership>;
};

const memberText = (patientMembership: PatientsMembership) => {
  if (patientMembership.employer) {
    return `${patientMembership.employerName} Member`;
  } else {
    return `Member Since ${momentFormatted(patientMembership.createdAt, 'MMM YYYY')}`;
  }
};

export default UserMembership;
