import { Journey, JourneyType } from 'kb-shared';

import {
  eggFreezing,
  ivf,
  iui,
  embryoBanking,
  ti,
  fet,
  donorIVF,
  mockFactory
} from './journeySteps';
import { compact as compactObJourneyFactory, obJourneyFactory } from './obstetrics';
import { JourneyFactory } from './types';

const DEFAULT_JOURNEYS = {
  'EGG FREEZING': eggFreezing,
  IVF: ivf,
  IUI: iui,
  'EMBRYO BANKING': embryoBanking,
  'TIMED INTERCOURSE': ti,
  'EMBRYO THAW': fet,
  'MOCK CYCLE': mockFactory,
  'DONOR-IVF': donorIVF
} as Record<JourneyType, Journey | undefined>;

const defaultJourneyFactory: JourneyFactory = ({
  appointments,
  results,
  patientTodos,
  triggerInstruction,
  cyclePlan,
  journeyType
}) => {
  const journey = DEFAULT_JOURNEYS[journeyType];
  if (!journey) {
    return;
  }

  const cycleCompleted = cyclePlan && cyclePlan.finished !== 'false' && cyclePlan.finished !== null;

  const steps = journey.steps
    .map(step => {
      const date = step.getDate(appointments, results);
      const completed = cycleCompleted || step.isComplete(appointments, results, patientTodos);
      if (step.type === 'trigger' && journeyType === 'TIMED INTERCOURSE' && !triggerInstruction) {
        return null;
      }

      const descriptionForType = step.descriptionForType && step.descriptionForType(journeyType);

      return {
        ...step,
        date,
        isComplete: completed,
        journeyType,
        descriptionForType,
        showTime: true
      };
    })
    .filter(Boolean);

  return {
    name: journey.name,
    steps
  };
};

// @ts-ignore
const JOURNEY_FACTORIES: Record<JourneyType, JourneyFactory> = {
  'EGG FREEZING': defaultJourneyFactory,
  'DONOR-IVF': defaultJourneyFactory,
  'MOCK CYCLE': defaultJourneyFactory,
  IVF: defaultJourneyFactory,
  IUI: defaultJourneyFactory,
  'EMBRYO BANKING': defaultJourneyFactory,
  'TIMED INTERCOURSE': defaultJourneyFactory,
  'EMBRYO THAW': defaultJourneyFactory,
  OBSTETRICS: obJourneyFactory
};

export const buildJourney: JourneyFactory = data => {
  const factory = JOURNEY_FACTORIES[data.journeyType];
  if (factory) {
    return factory(data);
  }
};

const COMPACT_JOURNEY_FACTORIES: Record<JourneyType, JourneyFactory> = {
  ...JOURNEY_FACTORIES,
  OBSTETRICS: compactObJourneyFactory
};

export const buildCompactJourney: JourneyFactory = data => {
  const factory = COMPACT_JOURNEY_FACTORIES[data.journeyType];
  if (factory) {
    return factory(data);
  }
};
