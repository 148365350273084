import React from 'react';

import { Payment } from '../../types/payment';
import { CardList } from '../InvoiceCardList/InvoiceCardList.styled';
import { UnappliedPaymentCardListItem } from './UnappliedPaymentCardListItem';

export const UnappliedPaymentCardList = ({ payments }: { payments: Payment[] }) => {
  const cards = payments.map(payment => (
    <UnappliedPaymentCardListItem key={payment.id} payment={payment} />
  ));

  return <CardList>{cards}</CardList>;
};
