import React from 'react';

import styled, { css } from 'styled-components';

interface Props {
  id: string;
  text: string;
  selected: boolean;
  className?: string;
  onChange: React.ChangeEventHandler<HTMLInputElement>;
}

const ChoiceButton = ({ id, text, selected, className, onChange }: Props) => (
  <Container className={className}>
    <HiddenRadioInput type="radio" checked={selected} id={id} onChange={onChange} />
    <Label htmlFor={id}>{text}</Label>
  </Container>
);

export default ChoiceButton;

const SelectedState = css`
  background-color: ${({ theme }) => theme.colors.yellow.primary};
  border-color: ${({ theme }) => theme.colors.yellow.primary};
  opacity: 1;

  :before {
    border-width: 4px;
  }
`;

const HiddenRadioInput = styled.input`
  position: absolute;
  margin: 0 !important;
  padding: 0 !important;
  opacity: 0;
  height: 0;
  width: 0;
  pointer-events: none;
`;

const Label = styled.label`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 6px 8px 8px 8px;

  cursor: pointer;
  border: 1px solid #343754;
  opacity: 0.5;
  border-radius: 18px;

  font-size: 14px;
  line-height: 12px;

  :before {
    width: 14px;
    height: 14px;
    margin-right: 4px;

    content: '';

    border-radius: 50%;
    border: 1px solid ${({ theme }) => theme.colors.neutral.primaryNavy};
  }
`;

const Container = styled.div`
  ${HiddenRadioInput}:checked
    + ${Label},
    ${HiddenRadioInput}:active
    + ${Label},
    ${HiddenRadioInput}:focus
    + ${Label} {
    ${SelectedState}
  }

  ${HiddenRadioInput}:focus + ${Label} {
    outline: 0;
  }
`;
