import React from 'react';

import { Text } from 'components/v2/Typography';

import { DescriptionContainer } from './PreviewedFileDescription.styled';

export const PreviewedFileDescription = ({
  extensions,
  maxSize
}: {
  extensions: string;
  maxSize: string;
}) => (
  <DescriptionContainer>
    <Text tag="p">{extensions}</Text>

    <Text tag="p">{maxSize}</Text>
  </DescriptionContainer>
);
