import React from 'react';
import { Redirect, Route } from 'react-router-dom';

interface Props {
  from: string;
  to: string;
}

export const RedirectWithSearchParams = ({ from, to }: Props): JSX.Element => (
  <Route
    path={from}
    render={({ location }) => {
      const searchParams = new URLSearchParams(location.search);
      const [toPath, toSearchQuery] = to.split('?');
      // combine search params from current location and the redirect target
      // target query params will override current query params
      const toSearchParams = new URLSearchParams(toSearchQuery ?? '');
      const combinedSearchParams = new URLSearchParams();
      searchParams.forEach((value, key) => combinedSearchParams.set(key, value));
      toSearchParams.forEach((value, key) => combinedSearchParams.set(key, value));

      return <Redirect to={`${toPath}?${combinedSearchParams}`} />;
    }}
  />
);
