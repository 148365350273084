import { Text } from 'components/v2/Typography';
import styled from 'styled-components';

import { InfoContainer } from './InfoContainer';

export const DatesInfoContainer = styled(InfoContainer)`
  display: flex;
  justify-self: flex-end;
  gap: 32px;
  @media ${({ theme }) => theme.queries.minTablet} {
    text-align: end;
  }
`;

export const Header = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5em;
`;

export const Body = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.5em;
`;

export const NoWrapText = styled(Text)`
  white-space: nowrap;
`;
