import { QueryResult } from '@apollo/client';

export type ConsentOption = {
  consentDecisionId: string;
  description: string;
  id: string;
  name: string;
};

export type ConsentDecision = {
  id: string;
  name: string | null;
  consentId: number | null;
  description: string | null;
  consentOptions: ConsentOption[] | null;
};

export type PartnerConsentDecision = {
  id: string;
  consentOptionId: number;
  consentDecisionId: number;
  patientConsentId: number;
  partnersToMismatch: boolean;
};

export type ComprehensionQuestion = {
  consentId: string;
  id: string;
  correctResponse: string;
  explanation: string;
  options: string[];
  text: string;
};

export type ConsentAdditionalSteps = {
  comprehensionQuestions: ComprehensionQuestion[] | null;
  consentDecisions: ConsentDecision[] | null;
};

export type Consent = {
  patientConsent: {
    patientPortalHtml: string | null;
    versionUuid: string | null;
    id: string;
    name: string;
    consent: ConsentAdditionalSteps | null;
    pdfLink: string | null;
  };
};

export type ConsentData = QueryResult<Consent>;

export interface OpenPatientConsent {
  openPatientConsent: {
    id: string;
  };
}

export type OpenPatientContentData = QueryResult<OpenPatientConsent>;

export type CompleteFormResponseData = {
  id: number;
  consentId: string;
  consentedAt: string;
};

export const Steps = {
  your_consent: 'Your consent',
  additional_options: 'Additional options',
  comprehension: 'Comprehension',
  final_step: 'Final step'
} as const;

export type StepType = keyof typeof Steps;
type StepName = typeof Steps[StepType];

export type Step = {
  type: StepType;
  name: StepName;
  partOfNavigation: boolean;
};

export type SignedPatientConsent = {
  versionUuid: string | null;
  consent: {
    id: string;
  } | null;
};

export type ConsentAndAllSignedPatientConsentsResponse = {
  consent: {
    id: string;
    rawHtml: string | null;
    versionUuid: string | null;
  };
  patientConsents: Array<SignedPatientConsent> | undefined;
};
