import React, { useEffect } from 'react';

import { Loader } from 'components/Loader/Loader';
import { Tab } from 'components/v2/Tabs/Tab.types';
import { Tabs } from 'components/v2/Tabs/Tabs';
import { Heading } from 'components/v2/Typography';
import { ResponsiveContainer } from 'screens/styled_common';
import { analytics } from 'utilities/analytics';

import { ResourcesList } from './components/ResourcesList';
import useResourceLibrary from './hooks/use-resource-library';

export const Resources = () => {
  const { loading, educationalContent, blogsContent, videosContent } = useResourceLibrary();

  const tabs: Tab[] = [
    {
      id: 'educational-content',
      label: 'Educational Content',
      content: <ResourcesList resourcesList={educationalContent} />
    },
    {
      id: 'blogs',
      label: 'Blogs',
      content: <ResourcesList resourcesList={blogsContent} />
    },
    {
      id: 'videos',
      label: 'Videos',
      content: <ResourcesList resourcesList={videosContent} />
    }
  ];

  useEffect(() => {
    analytics.page(analytics.PAGES.RESOURCES);
  }, []);

  if (loading) return <Loader absoluteCentered />;

  return (
    <ResponsiveContainer paddedContent>
      <Heading tag="div" styledAs="h1">
        Kindbody Library
      </Heading>
      <Tabs tabs={tabs} tabUrlKey="tab" noMargin={true} />
    </ResponsiveContainer>
  );
};
