import { gql, useMutation } from '@apollo/client';

import { PATIENT } from 'kb-shared/graphql/queries';
import { AddressResponseData, PatientResponseData } from 'kb-shared/graphql/types';

const UPDATE_PATIENT = gql`
  mutation updatePatient(
    $phone: String
    $preferredTimezone: String
    $sendSmsAppointmentNotification: Boolean
    $sendMarketingEmail: Boolean
  ) {
    updatePatient(
      phone: $phone
      preferredTimezone: $preferredTimezone
      sendSmsAppointmentNotification: $sendSmsAppointmentNotification
      sendMarketingEmail: $sendMarketingEmail
    ) {
      patient {
        id
        phone
        preferredTimezone
        mfaEnabled
        sendSmsAppointmentNotification
        sendMarketingEmail
      }
    }
  }
`;

const UPDATE_PATIENT_ADDRESS = gql`
  mutation updateAddress(
    $address1: String
    $address2: String
    $city: String
    $state: String
    $zipcode: String
    $country: String
  ) {
    updateAddress(
      address1: $address1
      address2: $address2
      city: $city
      state: $state
      zipcode: $zipcode
      country: $country
    ) {
      address {
        id
        address1
        address2
        city
        state
        zipcode
        country
      }
    }
  }
`;

export const useContactInfoFieldsMutations = () => {
  const [updatePatient] = useMutation<PatientResponseData>(UPDATE_PATIENT, {
    refetchQueries: [{ query: PATIENT }]
  });

  const [updateAddress] = useMutation<AddressResponseData>(UPDATE_PATIENT_ADDRESS, {
    refetchQueries: [{ query: PATIENT }]
  });

  return { updatePatient, updateAddress };
};
