import React from 'react';

import { Alert } from 'components/v2/Alert/Alert';
import { useHomeMarket } from 'hooks';

import { AlertContainer } from './ChicagoPatientNotice.styled';

export const ChicagoPatientNotice = (): JSX.Element | null => {
  const { id } = useHomeMarket();

  const isChicagoLab = id === '34';

  if (!isChicagoLab) return null;

  return (
    <AlertContainer>
      <Alert
        type="warning"
        title="Important Notice"
        message="Please be aware that any balances due before July 22, 2024, may not be reflected on your invoices page. If you have any questions, feel free to contact us at 855 KND BODY for assistance. Thank you!"
      />
    </AlertContainer>
  );
};
