import React, { useState } from 'react';

import { BulletText } from 'components/v2/BulletText/BulletText';
import { PasswordField } from 'components/v2/Form/PasswordField/PasswordField';
import { Text } from 'components/v2/Typography';
import { utils } from 'kb-shared';
import styled from 'styled-components';

const { getPasswordErrors, PASSWORD_ERRORS, isPasswordMatch } = utils;

type Props = {
  value: string;
  onChange: (event: React.FormEvent<HTMLInputElement>) => void;
  confirmationValue?: string;
  onChangeConfirmation?: (event: React.FormEvent<HTMLInputElement>) => void;
  onInputValidation?: (valid: boolean) => void;
};

export const CreatePasswordInput = (props: Props) => {
  const {
    value,
    onChange,
    confirmationValue = '',
    onChangeConfirmation = () => null,
    onInputValidation = () => null
  } = props;
  const [errors, setErrors] = useState<string[]>([]);

  return (
    <>
      <PasswordContainer>
        <PasswordField
          inputId="passwordInput"
          label="PASSWORD"
          value={value}
          onChange={event => {
            onChange(event);
            const passwordErrors = getPasswordErrors(event.currentTarget.value);
            setErrors(passwordErrors);
            onInputValidation(passwordErrors.length === 0);
          }}
          placeholder="Create a password"
        />
      </PasswordContainer>

      <PasswordContainer>
        <PasswordField
          inputId="confirmPassword"
          label="CONFIRM  PASSWORD"
          value={confirmationValue}
          onChange={event => onChangeConfirmation(event)}
          placeholder="Confirm the password"
        />
      </PasswordContainer>

      <PasswordRequirement>
        <Note>For security purposes, passwords must contain:</Note>
        <ul>
          <li>
            <BulletText
              text=" 8 or more characters"
              error={errors.includes(PASSWORD_ERRORS.EQUAL_OR_GREATER_THAN)}
            />
          </li>
          <li>
            <BulletText
              text=" numbers"
              error={errors.includes(PASSWORD_ERRORS.CONTAINS_NUMERIC_CHARACTERS)}
            />
          </li>
          <li>
            <BulletText
              text=" lower & uppercase letters"
              error={errors.includes(PASSWORD_ERRORS.CONTAINS_UPPER_AND_LOWER_CASE_CHARACTERS)}
            />
          </li>
          <li>
            <BulletText
              text=" special characters (e.g. ! @ # $ %)"
              error={errors.includes(PASSWORD_ERRORS.CONTAINS_SPECIAL_CHARACTERS)}
            />
          </li>
          <li>
            <BulletText
              text=" passwords must match"
              error={!isPasswordMatch(value, confirmationValue)}
            />
          </li>
        </ul>
      </PasswordRequirement>
    </>
  );
};

const PasswordContainer = styled.div`
  position: relative;
`;

// TODO: refactor to share the same component as on signup page
const Note = styled(Text)`
  color: ${({ theme }) => theme.colors.neutral.navy};
`;

// TODO: refactor to share the same component as on signup page
const PasswordRequirement = styled.div`
  margin-top: 10px;
  color: ${({ theme }) => theme.colors.neutral.navy};

  p,
  ul {
    margin: 0;
  }

  ul {
    list-style-type: disc;
    list-style-position: outside;

    li {
      margin-left: 16px;
    }

    li span {
      position: relative;
    }
  }
`;
