import { compose, createStore, applyMiddleware } from 'redux';
import thunkMiddleware from 'redux-thunk';

import rootReducer from './rootReducer';

const middlewares = [thunkMiddleware];

// if (__DEV__) {
//   const { createLogger } = require('redux-logger'); // eslint-disable-line global-require
//   const logger = createLogger({ collapsed: true });
//   middlewares.push(logger);
// }

const initialState = {};

// @ts-ignore
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(
  // @ts-ignore
  rootReducer,
  initialState,
  composeEnhancers(applyMiddleware(...middlewares))
);

export default store;
