import { Button } from 'components/v2/Buttons/Button';
import styled from 'styled-components';

export const HamburgerMenuContainer = styled.div<{ $height?: number; $backgroundColor?: string }>`
  grid-area: navbar;
  z-index: 300;
  overflow-y: auto;

  @media print {
    display: none;
  }

  background-color: ${({ theme, $backgroundColor }) =>
    $backgroundColor ? $backgroundColor : theme.colors.white};
  width: 100%;
  box-shadow: 0px 1px 3px -1px rgba(67, 71, 146, 0.16), 0px 1px 1px -1px rgba(10, 13, 61, 0.08);
  height: ${({ $height }) => ($height ? `${$height}px` : '52px')};
  position: fixed;

  display: flex;
  flex-direction: row;
  justify-content: space-between;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.smallDesktop}) {
    display: none;
  }

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.smallDesktop}) {
    top: 0px;
  }
`;

export const HamburgerMenuIconContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const HamburgerMenuProfileImageContainer = styled.div`
  margin-right: 8px;
`;

export const HamburgerMenuPatientNameContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const HamburgerMenuIcon = styled.div`
  color: ${({ theme }) => theme.colors.neutral.primaryNavy};
  font-size: 16px;
  padding: 14px 16px 6px 16px;
  cursor: pointer;
`;

export const HamburgerMenuLogoContainer = styled.div`
  padding: 18px 16px 18px 16px;
  cursor: pointer;
`;

export const HamburgerMenuButtonsContainer = styled.div`
display: flex;
flex-direction: row;
align-items: center;s
`;

export const ScheduleButton = styled(Button)`
  margin-left: 20px;
  margin-right: 24px;
`;
