import React from 'react';
import { useHistory } from 'react-router-dom';

import { useFeatureFlags, usePatient } from 'hooks';
import { utils } from 'kb-shared';

const APPOINTMENT_REQUEST_FORM_CHICAGO_SCHEDULING_URL =
  'https://kindbody.com/appointment-request-form-chicago-scheduling-location-confirmed/';

export const redirectChicagoPatients = (WrappedComponent: React.ComponentType<any>) => {
  const Wrapper = (props: any) => {
    const { patient } = usePatient();
    const history = useHistory();
    const { ppPreventSelfAppointmentChicagoPatient } = useFeatureFlags();

    const { CHICAGO } = utils.labs();

    const isChicagoPatient = patient?.lab?.id === CHICAGO;

    if (ppPreventSelfAppointmentChicagoPatient && isChicagoPatient) {
      history.push('/');
      window.location.href = APPOINTMENT_REQUEST_FORM_CHICAGO_SCHEDULING_URL;

      return null;
    }

    return <WrappedComponent {...props} />;
  };

  return Wrapper;
};
