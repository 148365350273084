import { useMutation } from '@apollo/client';
import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import { StepTrackerLayout } from 'components/v2/Layouts/StepTrackerLayout/StepTrackerLayout';
import { PATIENT } from 'kb-shared/graphql/queries';
import { BugTracker } from 'kb-shared/utilities/bugTracker';
import { ActionButtons } from 'screens/ActivateMembership/components/ActionButtons/ActionButtons';
import { Collate } from 'screens/ActivateMembership/components/Collate/Collate';
import { CollateType } from 'screens/ActivateMembership/components/Collate/Collate.types';
import ConfirmationBox from 'screens/ActivateMembership/components/ConfirmationBox/ConfirmationBox';
import { analytics } from 'utilities/analytics';
import { getGraphQLErrorsMessages } from 'utilities/errors';
import { showErrorToast, showSuccessToast } from 'utilities/notificationUtils';
import { RoutesPath } from 'utilities/pageUrl';

import { CREATE_PATIENT_ENTERPRISE_MEMBERSHIP } from './ConfirmationMembershipScreen.graphql';
import { CollateContainer, Container } from './ConfirmationMembershipScreen.styled';
import {
  CreatePatientMembershipResponse,
  CreatePatientMembershipVariables
} from './ConfirmationMembershipScreen.types';

const DEFAULT_ERROR_MESSAGE =
  'There was something wrong with this operation, try again later or contact support if error persists';

export const ConfirmationMembershipScreen = () => {
  const history = useHistory();
  const location = useLocation();
  const [termsChecked, setTermsChecked] = useState(false);
  const { code, uniqueId, firstName, lastName, dateOfBirth } = location?.state ?? {
    code: null,
    uniqueId: null,
    firstName: null,
    lastName: null,
    dateOfBirth: null
  };

  const [activateMembership, { loading }] = useMutation<
    CreatePatientMembershipResponse,
    CreatePatientMembershipVariables
  >(CREATE_PATIENT_ENTERPRISE_MEMBERSHIP, {
    refetchQueries: [{ query: PATIENT }],
    onError: error => {
      showErrorToast(getGraphQLErrorsMessages(error).join(', ') || DEFAULT_ERROR_MESSAGE);
      BugTracker.notify(error, 'Activate membership error(standard)');
    },
    onCompleted: data => {
      analytics.track(analytics.EVENTS.EMU_ACCOUNT_ACTIVATED, {
        enterprise_name:
          data.createPatientEnterpriseMembership.patientMembership?.employerName ?? ''
      });
      showSuccessToast('Your membership has been activated');
      history.push('/book?step=success&enterprise_membership=true');
    }
  });

  useEffect(() => {
    if (code) return;

    history.replace(RoutesPath.ENTER_MEMBERSHIP_ACCESS_CODE);
  }, [code, history]);

  const handleConfirm = async () => {
    activateMembership({
      variables: {
        employerAccessCode: code,
        employeeAccessCode: uniqueId || '',
        employeeFirstName: firstName || '',
        employeeLastName: lastName || '',
        employeeDateOfBirth: dateOfBirth || ''
      }
    });
  };

  return (
    <StepTrackerLayout
      title={'Verify my Eligibility'}
      stepNames={['Services', 'Location', 'Activate']}
      selectedStep={2}
      renderMainContent={() => {
        return (
          <Container>
            <CollateContainer>
              <Collate
                type={CollateType.SUCCESS}
                title="Great news! You are eligible for a Kindbody benefit through your employer."
                description="Explore the available options below and select the one that best suits your needs!"
              />
            </CollateContainer>
            <ConfirmationBox onTermsChecked={checked => setTermsChecked(checked)} />
            <ActionButtons
              onBack={() => history.goBack()}
              onForward={handleConfirm}
              forwardDisabled={!termsChecked || loading}
              forwardText="Finish"
            />
          </Container>
        );
      }}
    />
  );
};
