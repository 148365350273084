import imageBlobReduce from 'image-blob-reduce';

const ALLOWED_FILE_TYPES = ['image/jpeg', 'image/jpg', 'image/png'];
const RESIZE_OPTIONS = { max: 2000 };

const imageResize = async (file: File) => {
  if (!ALLOWED_FILE_TYPES.includes(file.type)) return file;

  return imageBlobReduce().toBlob(file, RESIZE_OPTIONS);
};

export default imageResize;
