import { utils } from 'kb-shared';
const { setConsent, setConsentSearch, getConsent } = utils;

export const checkAndSetConsent = () => {
  const localConsent = getConsent();
  if (localConsent) return localConsent;

  const search = new URLSearchParams(window.location.search);
  const consent = search.get('consent');
  if (consent) {
    setConsent(consent);
    setConsentSearch(window.location.search);
    return consent;
  }
  return false;
};
