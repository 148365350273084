import { gql } from '@apollo/client';

export const UPDATE_PATIENT_PROFILE = gql`
  mutation updatePatient($pronoun: String, $genderIdentity: GenderIdentity, $identifier: String) {
    updatePatient(pronoun: $pronoun, genderIdentity: $genderIdentity, identifier: $identifier) {
      patient {
        id
        firstName
        lastName
        birthday
        pronoun
        genderIdentity
      }
    }
  }
`;
