import React, { ReactNode } from 'react';
import { toast, ToastContainer } from 'react-toastify';

import { ReactComponent as ErrorIconSvg } from '../assets/icons/toast-error-icon.svg';
import { ReactComponent as SuccessIconSvg } from '../assets/icons/toast-success-icon.svg';

const TIMEOUT = 10000;

export const NotificationsContainer = () => (
  <ToastContainer
    autoClose={TIMEOUT}
    position="top-center"
    hideProgressBar={true}
    closeOnClick
    closeButton={false}
  />
);

export const showErrorToast = (content: string | ReactNode) => {
  toast(content, {
    theme: 'colored',
    type: 'error',
    icon: <ErrorIconSvg />
  });
};

export const showSuccessToast = (content: string | ReactNode) => {
  toast(content, {
    theme: 'colored',
    type: 'success',
    icon: <SuccessIconSvg />
  });
};
