import React from 'react';

import { utils } from 'kb-shared';

import { NavigatorNumberLink } from './NavigatorNumber.styled';

export const NavigatorNumber = () => {
  return (
    <NavigatorNumberLink href={`tel:${utils.KBContacts.navigatorNumber}`}>
      {utils.KBContacts.navigatorNumber}
    </NavigatorNumberLink>
  );
};
