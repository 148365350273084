import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: flex-start;
  justify-content: flex-start;
`;

export const FormsContainer = styled.div`
  width: 100%;
  flex-direction: column;
  justify-content: flex-start;
`;

export const FormSectionStyled = styled.div`
  display: ${(props: { active: boolean }) => (props.active ? 'block' : 'none')};
`;

export const ControlButtons = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 0 25px 40px;
`;

export const ControlButton = styled.button`
  border: none;
  background: transparent;
  font-size: 30px;
  position: relative;
  cursor: pointer;
  display: inline-block;
  padding: 0;

  &:before {
    content: '';
    border-style: solid;
    border-width: 0 0 2px 2px;
    display: block;
    width: 12px;
    height: 12px;
    position: absolute;
    margin-top: -4px;
    top: 50%;
    transition: left 0.1s;
  }
`;

export const NextButton = styled(ControlButton)`
  &:before {
    content: '';
    right: -20px;
    transform: rotate(225deg);
  }
`;

export const PreviousButton = styled(ControlButton)`
  &:before {
    content: '';
    left: -20px;
    transform: rotate(45deg);
  }
`;

interface ScrollItemContainerProps {
  minHeight: string | number;
  setMinHeight: boolean;
  isMemberOfLastSection: boolean;
}

export const ScrollItemContainer = styled.div`
  min-height: ${(props: ScrollItemContainerProps) => (props.setMinHeight ? props.minHeight : '0')};

  &:last-child {
    margin-top: ${(props: ScrollItemContainerProps) =>
      props.isMemberOfLastSection ? props.minHeight : 0};
  }
`;

interface MarginBottomProps {
  height: number;
}

export const MarginBottom = styled.div`
  height: ${(props: MarginBottomProps) => props.height + 'px'};
  width: 100%;
`;

export const Link = styled.div`
  margin: 20px 0 0 0;
  text-decoration: underline;
  cursor: pointer;
`;
