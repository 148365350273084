import { useFeatureFlags } from 'hooks';
import { AppointmentType, Membership } from 'kb-shared';
import { isCoachingAppointment } from 'kb-shared/utilities/appointment.helper';

export const useIsPaymentOptional = (product: AppointmentType | Membership) => {
  const { ppCreditCardOptional } = useFeatureFlags();

  // If product is not defined or does not have a category we can't determine if payment is optional
  if (!product || 'category' in product === false) {
    return { isPaymentOptional: false };
  }

  // Only coaching(holistic) appointments are required to submit payment at booking
  const nonHolisticAppointment = isCoachingAppointment(product) === false;
  const isPaymentOptional = Boolean(ppCreditCardOptional && nonHolisticAppointment);

  return { isPaymentOptional };
};
