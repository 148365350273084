import React from 'react';

import { Option } from 'components/v2/Inputs/SelectInput';

import { CountrySelect } from '../../Inputs';
import { FormControl, getFormControlIds } from '../FormControl';
import { Props } from './CountrySelectField.types';

export const CountrySelectField = <T extends Option>({
  id,
  status = 'default',
  label,
  required,
  helperText,
  description,
  placeholder,
  selected,
  searchPlaceholder,
  optionFormatter,
  selectedOptionFormatter,
  onChange,
  onBlur,
  onFocus,
  options,
  labelBg
}: Props<T>) => {
  const ids = getFormControlIds(id);
  const labelId = ids.label;
  const dropdownId = `${ids.input}-dropdown`;

  return (
    <FormControl
      status={status}
      label={label}
      inputId={ids.input}
      labelId={labelId}
      helperTextId={ids.helperText}
      helperText={helperText}
      required={required}
      description={description}
      labelBg={labelBg}
    >
      <CountrySelect<T>
        id={ids.input}
        labelId={labelId}
        dropdownId={dropdownId}
        placeholder={placeholder}
        searchPlaceholder={searchPlaceholder}
        selected={selected}
        options={options}
        optionFormatter={optionFormatter}
        selectedOptionFormatter={selectedOptionFormatter}
        status={status}
        onChange={onChange}
        onBlur={onBlur}
        onFocus={onFocus}
      />
    </FormControl>
  );
};
