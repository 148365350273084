import React from 'react';

import { Text } from 'components/v2/Typography/Text';

import { ImageContainer, IconContainer, PDFIcon, DefaultFileIcon } from './MessageFile.styled';
import { Props } from './MessageFile.types';

export const MessageFile = ({ attachment, fromPatient }: Props) => {
  const fileName = attachment.fileName || 'invalid attachment';
  const fileUrl = attachment.file || undefined;
  const [fileType] = fileName.split('.').slice(-1);

  switch (fileType) {
    case 'png':
    case 'jpg':
    case 'jpeg':
      return (
        <ImageContainer
          key={attachment.id}
          fromPatient={fromPatient}
          href={fileUrl}
          target="_blank"
        >
          <img src={fileUrl} alt="Attached File" loading="lazy" />
        </ImageContainer>
      );

    case 'pdf':
      return (
        <IconContainer href={fileUrl} fromPatient={fromPatient} target="_blank">
          <PDFIcon />
          <Text size="sm" fontStyle="regular">
            {fileName}
          </Text>
        </IconContainer>
      );

    default:
      return (
        <IconContainer href={fileUrl} fromPatient={fromPatient} target="_blank">
          <DefaultFileIcon />
          <Text size="sm" fontStyle="regular">
            {fileName}
          </Text>
        </IconContainer>
      );
  }
};
