import { themes } from 'kb-shared';
import styled from 'styled-components';

export const ContentContainer = styled.div`
  ${({ theme }) => theme.grid.pageContainer};
  flex: 1;
  padding: 40px;
  @media (max-width: ${({ theme }) => theme.breakpoints.smallDesktop}) {
    margin-top: 80px;
  }
`;

export const Title = styled.h1`
  font-size: 24px;
  font-weight: 600;
  margin-top: 20px;
  margin-bottom: 20px;
`;

export const Subtitle = styled.h3`
  font-size: 16px;
  margin-top: 16px;
  margin-bottom: 16px;
`;

export const MarginVertical10 = styled.div`
  margin-top: 10px;
`;

export const CardElementsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 16px;
  max-width: 540px;
  margin-bottom: 20px;
  @media screen and (max-width: ${themes.breakpoints.tabletPortrait}) {
    justify-content: flex-start;
    flex-direction: column;
  }
`;

export const CheckboxContainer = styled.div`
  margin-top: 20px;
`;

export const LocationDetails = styled.div`
  font-size: 14px;
  width: 340px;
  padding-bottom: 10px;
`;

export const PortalLink = styled.button`
  text-decoration: underline;
`;
