import { gql } from '@apollo/client';

import { ResourceItemType } from '../types';

export type MultimediaResourcesReturn = {
  multimediaResources: ResourceItemType[];
};

export default gql`
  query multimediaResources {
    multimediaResources {
      id
      title
      resourceType
      url
      tierForPortal
    }
  }
`;
