import React from 'react';

import SubstepWrapper from '../../SubstepWrapper';
import { Description, Title } from './styled-components';

const ReturnOBAppointment = () => (
  <>
    <Title>Return OB Appointment</Title>
    <Description>
      This appointment includes a visit with your provider, an ultrasound & cervix check.
    </Description>
    <Description>
      <strong>Cervix Check:</strong> Your provider will check the dilation of your cervix. The
      cervix dilates during labor and some women can start to dilate days or weeks before delivery.
    </Description>
  </>
);

const SUBSTEPS = [{ component: <ReturnOBAppointment /> }];

const ObWeek39Substeps = () => <SubstepWrapper substeps={SUBSTEPS} />;

export default ObWeek39Substeps;
