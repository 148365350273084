import React, { Component } from 'react';

import { Answer, themes } from 'kb-shared';
import styled from 'styled-components';

import AnimatedWrapper from '../AnimatedWrapper';
import FormButton from '../FormButton';
import Text from '../Primitives/FormText';

type Props = {
  title: string;
  onSave: (response: string) => void;
  onContinue?: () => void;
  answer: Answer | null;
  isRequired: boolean | null;
};
type State = {
  response: string;
};

export default class IntegerForm extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      response: (props.answer && props.answer.data) || ''
    };
  }

  renderContinueButton() {
    const { onContinue } = this.props;

    if (!onContinue) return null;

    return (
      <AnimatedWrapper>
        <FormButton text={'Continue'} onClick={() => onContinue && onContinue()} />
      </AnimatedWrapper>
    );
  }

  onKeyPress(e: { key: string }) {
    const { onContinue, onSave } = this.props;

    if (e.key === 'Enter') {
      onSave(this.state.response);
      onContinue && onContinue();
    }
  }

  render() {
    return (
      <Container>
        <Text isRequired={this.props.isRequired}>{this.props.title}</Text>
        <TextInput
          type="number"
          pattern="[0-9]"
          value={this.state.response}
          placeholder={'eg. 14'}
          onKeyPress={this.onKeyPress.bind(this)}
          onChange={e => {
            const response = e.target.value;
            this.setState(() => ({
              response: response
            }));
          }}
          onBlur={e => this.props.onSave(e.target.value)}
        />
        {this.renderContinueButton()}
      </Container>
    );
  }
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
`;

const TextInput = styled.input`
  font-size: 24px;
  color: black;
  height: 40px;
  width: 120px;
  border: 0px;
  border-bottom: solid 1px black;
  text-align: left;
  background-color: transparent;

  ::placeholder {
    font-size: 24px;
    text-align: left;
    color: #a9a7b0;
  }
  :focus {
    outline: 0;
    border-bottom: solid 1px ${themes.colors.yellow.primary};
  }
`;
