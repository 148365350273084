import React, { ChangeEvent, useState } from 'react';
import { useDispatch } from 'react-redux';

import {
  CATEGORY,
  UploadFileException,
  UploadFileResponse,
  retrieveFile,
  uploadFile
} from 'api/pf2/files';
import { FilePreviewAndUpload } from 'components/FilePreviewAndUpload/FilePreviewAndUpload';
import {
  ALLOWED_PHOTO_FILES_DESCRIPTION,
  ALLOWED_FILES_DESCRIPTION,
  ALLOWED_PHOTO_MAX_SIZE,
  ALLOWED_FILE_MAX_SIZE
} from 'components/FilePreviewAndUpload/FilePreviewAndUpload.utils';
import { State } from 'components/PhotoUpload/photoUpload.types';
import { getFileUploadState } from 'components/PhotoUpload/photoUpload.utils';
import { Modal } from 'components/v2/Modal/Modal';
import {
  resetInsuranceCardError,
  updateSubmittedInsuranceCard,
  updateSubmittedID,
  resetProfileImageError,
  updateProfileImage,
  profileImageError
} from 'kb-redux/user.redux';
import { BugTracker } from 'kb-shared/utilities/bugTracker';
import { analytics } from 'utilities/analytics';
import { Crop, getCroppedImg } from 'utilities/imageCropper';

import { Container } from './ShareUploadModal.styled';

const FIVE = 5;
const MAX_FILE_UPLOAD_SIZE = FIVE * 1000 * 1000; // 5MB
const TWO = 2;
const PROFILE_PHOTO_MAX_UPLOAD_SIZE = TWO * 1000 * 1000;

type Props = {
  category: UPLOAD_CATEGORY;
  onUploadCompleted: Function;
  visible: boolean;
  onClose: () => void;
};

export const SharedUploadModal = ({ onClose, onUploadCompleted, category, visible }: Props) => {
  const [state, setState] = useState<State>({
    error: null,
    loading: false,
    side: 'front'
  });
  const [cropSettings, setCropSettings] = useState<Crop | undefined>();
  const dispatch = useDispatch();

  const isInsuranceUpload = category === 'upload insurance card';
  const isIDUpload = category === 'upload id';
  const isProfilePhoto = category === 'profile photo';

  const getModalTitle = () => {
    if (isIDUpload) return 'Upload ID or driver’s license';
    if (isInsuranceUpload) return 'Upload insurance card';
    if (isProfilePhoto) return 'Add a photo of yourself';
    return '';
  };

  const [modalTitle, setModalTitle] = useState(getModalTitle());

  const firstStepMessage = () => {
    if (isInsuranceUpload) return 'Drag or click to upload the Front of your insurance card.';
    if (isIDUpload) return 'Drag or click to upload the Front of your ID.';
    if (isProfilePhoto)
      return 'Drag&drop or click to upload your profile photo. Adjust your photo as desired to fit.';
    return '';
  };

  const secondStepMessage = () => {
    if (isInsuranceUpload) return 'Drag or click to upload the Back of your insurance card.';
    return '';
  };

  const uploadCategory = (target: 'front' | 'back'): CATEGORY => {
    if (isInsuranceUpload)
      return target === 'front' ? CATEGORY.INSURANCE_CARD_FRONT : CATEGORY.INSURANCE_CARD_BACK;

    if (isProfilePhoto) return CATEGORY.PROFILE_PHOTO;

    return CATEGORY.ID_FRONT;
  };

  const uploadDescription = () => {
    if (isInsuranceUpload) return 'Insurance card';
    if (isIDUpload) return 'ID or driver license';
    return '';
  };

  const analyticsTooBigEvent = () => {
    if (isInsuranceUpload) return analytics.EVENTS.INSURANCE_FILE_TOO_BIG;
    if (isProfilePhoto) return analytics.EVENTS.PROFILE_IMAGE_FILE_TOO_BIG;
    return analytics.EVENTS.ID_FILE_TOO_BIG;
  };

  const handleFileChange = (target: 'front' | 'back', e: ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    handleFilesToUpload(target, e.target?.files);
  };

  const handleFilesToUpload = async (target: 'front' | 'back', files: FileList | null) => {
    const newState = await getFileUploadState(target, files);

    if (!newState) return;

    const size = newState.frontImage?.size || newState.backImage?.size || 0;
    if (size > getMaxSize()) {
      setState({
        ...state,
        error: 'File size exceeds the maximum limit, please try with a smaller file.'
      });
      return;
    }

    setState({ ...state, ...newState });
  };

  const handleFrontFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    handleFileChange('front', e);

    if (isProfilePhoto) setModalTitle('Adjust your photo to fit');
  };

  const handleBackFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    handleFileChange('back', e);
  };

  const getMaxSize = () => (isProfilePhoto ? PROFILE_PHOTO_MAX_UPLOAD_SIZE : MAX_FILE_UPLOAD_SIZE);

  const fetchUserImage = async () => {
    try {
      const response = await retrieveFile(CATEGORY.PROFILE_PHOTO);
      if ('file' in response) {
        dispatch(updateProfileImage(response.file));
      }
    } catch (error) {
      BugTracker.notify(error, 'fetchUserImage');
      dispatch(
        profileImageError({
          type: 'UnableToLoadImage'
        })
      );
    }
  };

  const onSubmit = async (target: 'front' | 'back', next: 'back' | 'success') => {
    setState({ ...state, loading: true });
    const isFront = target === 'front';
    let file = isFront ? state.frontImage : state.backImage;

    let fileName = isFront ? state.frontImageFileName : state.backImageFileName;
    if (isProfilePhoto) {
      fileName = 'profile-photo';
    }

    if (isProfilePhoto && frontImageFileObjectUrl && cropSettings) {
      file = await getCroppedImg(
        frontImageFileObjectUrl,
        cropSettings,
        fileName || 'profile-photo'
      );
    }

    try {
      if (!file) {
        setState({ ...state, loading: false, error: 'Something went wrong, please try again.' });
        return;
      }

      const maxSize = getMaxSize();
      if (file.size > maxSize) {
        setState({
          ...state,
          loading: false,
          error: `Please use a file smaller than ${isProfilePhoto ? TWO : FIVE}MB.`
        });
        return;
      }

      const response = await uploadFile({
        file,
        category: uploadCategory(target),
        description: uploadDescription(),
        fileName
      });
      if (response?.status === 422 || response?.status === 413) {
        setState({
          ...state,
          loading: false,
          error:
            'Failed to upload, file size exceeds the maximum limit, please try again with a smaller file.'
        });
        analytics.track(analyticsTooBigEvent());
        return;
      }

      const responseJson: UploadFileResponse = await response.json();

      if ('errors' in responseJson && responseJson?.errors) {
        setState({ ...state, loading: false, error: responseJson.errors });
        return;
      }

      if (!response.ok) {
        const error = {
          message: `${response.status} ${response.statusText}: ${await response.text()}`
        };
        BugTracker.notify(error, 'UploadError');
        setState({ ...state, loading: false, error: 'Something went wrong, please try again.' });
        return;
      }

      if (target === 'front') {
        if (isProfilePhoto) dispatch(resetProfileImageError());
        else dispatch(resetInsuranceCardError('front'));
      }
      if (target === 'back') dispatch(resetInsuranceCardError('back'));

      setState({ ...state, side: next, loading: false });

      if (next === 'success') {
        if (isProfilePhoto) {
          fetchUserImage();
        }
        onUploadCompleted();
      }
    } catch (err) {
      BugTracker.notify(err, 'UploadError');

      const error = err as UploadFileException;
      setState({ ...state, error: error?.message || 'Something went wrong, please try again.' });
    }
  };

  const onSubmitFront = async () => {
    if (isInsuranceUpload) await onSubmit('front', 'back');
    if (isIDUpload) await onSubmit('front', 'success');
    if (isProfilePhoto) await onSubmit('front', 'success');
  };

  const onSubmitBack = async () => {
    await onSubmit('back', 'success');
  };

  const onCloseModal = () => {
    const { frontImageFileObjectUrl, backImageFileObjectUrl, side } = state;
    onClose();
    if (
      isInsuranceUpload &&
      frontImageFileObjectUrl &&
      backImageFileObjectUrl &&
      side === 'success'
    )
      dispatch(updateSubmittedInsuranceCard(true));

    if (isIDUpload && frontImageFileObjectUrl && side === 'success')
      dispatch(updateSubmittedID(true));
  };

  const {
    frontImageFileObjectUrl,
    backImageFileObjectUrl,
    loading,
    side,
    error,
    frontImage,
    backImage
  } = state;

  return (
    <Modal title={modalTitle} open={visible} onCancel={onCloseModal}>
      {side === 'front' && (
        <FilePreviewAndUpload
          label={firstStepMessage()}
          image={frontImageFileObjectUrl}
          type={frontImage?.type}
          onFileChange={handleFrontFileChange}
          onFileDropped={files => handleFilesToUpload('front', files)}
          onSubmit={onSubmitFront}
          onCancel={onCloseModal}
          loading={loading}
          error={error}
          acceptedExtensions={isProfilePhoto ? '.jpeg, .jpg, .png' : '.jpeg, .jpg, .png, .pdf'}
          extensionsText={
            isProfilePhoto ? ALLOWED_PHOTO_FILES_DESCRIPTION : ALLOWED_FILES_DESCRIPTION
          }
          maxSizeText={isProfilePhoto ? ALLOWED_PHOTO_MAX_SIZE : ALLOWED_FILE_MAX_SIZE}
          allowCropping={isProfilePhoto}
          onCropSettingsChange={(settings: Crop) => setCropSettings(settings)}
        />
      )}
      {side === 'back' && (
        <FilePreviewAndUpload
          label={secondStepMessage()}
          image={backImageFileObjectUrl}
          type={backImage?.type}
          onFileChange={handleBackFileChange}
          onFileDropped={files => handleFilesToUpload('back', files)}
          onSubmit={onSubmitBack}
          onCancel={onCloseModal}
          loading={loading}
          error={error}
          acceptedExtensions={'.jpeg, .jpg, .png, .pdf'}
          extensionsText={ALLOWED_FILES_DESCRIPTION}
          maxSizeText={ALLOWED_FILE_MAX_SIZE}
        />
      )}
      {side === 'success' && <Container />}
    </Modal>
  );
};

export type UPLOAD_CATEGORY = 'profile photo' | 'upload id' | 'upload insurance card';
