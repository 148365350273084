import React, { useState } from 'react';

import { TermsModal } from 'components/Billing/components/TermsModal';
import { LinkButton } from 'components/v2/Buttons/LinkButton/LinkButton';

const PaymentTermsLink = () => {
  const [isPaymentTermsModalVisible, setIsPaymentTermsModalVisible] = useState(false);
  const togglePaymentTerms = () => {
    setIsPaymentTermsModalVisible(!isPaymentTermsModalVisible);
  };

  return (
    <>
      <LinkButton onClick={togglePaymentTerms} text="Payment Terms." fontStyle="semibold" />

      <TermsModal
        show={isPaymentTermsModalVisible}
        onAgree={() => togglePaymentTerms()}
        onClose={() => togglePaymentTerms()}
      />
    </>
  );
};

export default PaymentTermsLink;
