export const isPasswordEqualOrGreaterThan = (password: string, numberOfCharacter: number) => {
  const regex = new RegExp(`(?=.{${numberOfCharacter},})`);
  return regex.test(password);
};

export const doesPasswordContainsNumericCharacters = (password: string) => {
  const regex = /(?=.*[0-9])/;
  return regex.test(password);
};

export const doesPasswordContainsUpperAndLowerCaseCharacters = (password: string) => {
  const regex = /(?=.*[a-z])(?=.*[A-Z])/;
  return regex.test(password);
};

export const doesPasswordContainsSpecialCharacters = (password: string) => {
  const regex = /[!@#$%^&*()\-_+=|{}[\]:;"'<>,.?/\\~]/;
  return regex.test(password);
};
