import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';

import { Loader } from 'components/Loader/Loader';

import { ReduxState } from '../../../kb-redux';

type Props = {
  children: React.ReactNode;
  isLoggedIn: boolean | undefined;
};

const AuthGuard = ({ children, isLoggedIn }: Props) => {
  const [redirected, setRedirected] = useState(false);

  useEffect(() => {
    if (!isLoggedIn) {
      redirectToLogin();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoggedIn]);

  const redirectToLogin = () => {
    if (redirected) {
      return;
    }

    setRedirected(true);
    window.location.href = '/login?target=/patient-intake';
  };

  if (!isLoggedIn) return <Loader />;

  return <>{children}</>;
};

const mapStateToProps = (state: ReduxState) => ({
  isLoggedIn: state.patient.isLoggedIn
});

const mapDispatchToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(AuthGuard);
