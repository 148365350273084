import React, { useEffect, useState } from 'react';

import { Alert } from 'components/v2/Alert/Alert';
import { Button } from 'components/v2/Buttons/Button';
import { DateField } from 'components/v2/Form';
import { Checkbox } from 'components/v2/Inputs';
import { Modal } from 'components/v2/Modal/Modal';
import { useDeleteInsuranceData } from 'hooks/useDeleteInsuranceData';
import { showSuccessToast } from 'utilities/notificationUtils';

import { Bottom, Content } from './InsuranceModalDelete.styled';
import { Props } from './InsuranceModalDelete.types';

export const InsuranceModalDelete = ({ open, insuranceProvider, onDelete, onCancel }: Props) => {
  const { deleteInsuranceData } = useDeleteInsuranceData();
  const [date, setDate] = useState<Date | null>(null);
  const [dontKnowEndDate, setDontKnowEndDate] = useState<boolean>(false);

  const confirmDeleteModal = async () => {
    if (!insuranceProvider) return;

    // The deleteCards flag determines whether the file attached to the insurance provider should be deleted.
    // This condition is necessary only in the case of a self-relationship, as the file is only attached in such instances.
    const deleteCards = insuranceProvider.relationshipToInsured === 'self';
    const response = await deleteInsuranceData(insuranceProvider.id, date, deleteCards);

    if (response?.data) {
      showSuccessToast('Coverage information successfully deleted.');
    }

    onDelete();
  };

  useEffect(() => {
    if (dontKnowEndDate) {
      setDate(null);
    }
  }, [dontKnowEndDate]);

  return (
    <Modal open={open} title="Confirm Coverage Deletion" onCancel={onCancel} maxWidth={800}>
      <Content>
        {dontKnowEndDate ? (
          <Alert
            type="error"
            message="This action is irreversible and will permanently remove your insurance details from our record."
          />
        ) : (
          <Alert
            type="info"
            message="Before deleting your insurance coverage, please provide the term end date (the last date this coverage is valid)."
          />
        )}

        <DateField
          id="input-birth-date"
          placeholder="Select end date..."
          label="Termination Date"
          value={date}
          onChange={date => setDate(date)}
          status="default"
          dateFormat="MM/dd/yyyy"
          hideHelperText
        />

        <Checkbox
          id="dont-know-end-coverage-date"
          label="I don't know my coverage term end date."
          isChecked={dontKnowEndDate}
          onChange={e => {
            setDontKnowEndDate(e.currentTarget.checked);
          }}
        />
      </Content>

      <Bottom>
        <Button label="Cancel" category="secondary" size="lg" uppercase onClick={onCancel} />
        <Button
          label="Delete Coverage"
          category="error"
          size="lg"
          isDisabled={!(dontKnowEndDate || !!date)}
          uppercase
          onClick={confirmDeleteModal}
        />
      </Bottom>
    </Modal>
  );
};
