import Honeybadger from '@honeybadger-io/js';
import { useState } from 'react';

import EpicDisneyConfig from './../config';

export const useGetPatientDetails = () => {
  const [responseState, setResponseState] = useState<ResponseStateType>({
    patientData: null,
    finished: false,
    error: null
  });
  const getPatientDetails = async (accessToken: any, patientFhirId: any): Promise<any> => {
    const url = EpicDisneyConfig.epicUrl + '/api/FHIR/R4/Patient/' + patientFhirId;
    fetch(url, {
      method: 'get',
      headers: new Headers({
        Authorization: 'Bearer ' + accessToken,
        Accept: 'application/json'
      })
    })
      .then(response => response.json())
      .then(data => {
        setResponseState({
          patientData: data,
          finished: true,
          error: null
        });
      })
      .catch(error => Honeybadger.notify(error));
  };

  return { responseState, getPatientDetails };
};

type ResponseStateType = {
  patientData: any;
  finished: boolean;
  error: string | null;
};
