import React, { ReactNode } from 'react';
import { connect } from 'react-redux';

import { ReduxState } from 'kb-redux';
import { KindbodyLogo } from 'layouts/MainLayout/Logo/KindBodyLogo';
import { NavigationContainer } from 'layouts/MainLayout/Navigation/NavigationContainer/NavigationContainer';
import styled from 'styled-components';

import Footer from '../../components/Footer';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const Main = styled.main`
  margin: 0 10px 80px 0;

  @media (min-width: ${({ theme }) => theme.breakpoints.smallDesktop}) {
    ${({ isLoggedIn }: Props) => `
    margin: ${isLoggedIn ? `0 0 80px 124px` : `0 0 80px 0`};
  `}
  }
  flex: 1 0 auto;
`;

const StickyBottom = styled.div`
  flex-shrink: 0;
  @media (min-width: ${({ theme }) => theme.breakpoints.smallDesktop}) {
    ${({ isLoggedIn }: Props) => `
    margin-left: ${isLoggedIn ? `80px` : `0`};
  `}
  }
`;

interface Props {
  children: ReactNode;
  isLoggedIn: boolean | undefined;
}

const SimpleLayout = (props: Props) => {
  const { children, isLoggedIn } = props;

  return (
    <Container>
      {!isLoggedIn && <KindbodyLogo href="https://kindbody.com/" marginTop="40" />}
      <NavigationContainer />
      <Main isLoggedIn={isLoggedIn}>{children}</Main>
      <StickyBottom isLoggedIn={isLoggedIn}>
        <Footer />
      </StickyBottom>
    </Container>
  );
};

const mapStateToProps = ({ patient: { isLoggedIn } }: ReduxState) => ({
  isLoggedIn: isLoggedIn
});

export default connect(mapStateToProps, null)(SimpleLayout);
