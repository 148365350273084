import { Button } from 'components/v2/Buttons/Button';
import styled from 'styled-components';

export const NoJourneyContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  background-color: ${({ theme }) => theme.colors.neutral.primaryNavy};
  padding: 20px;
`;

export const NoJourneyMessage = styled.h2`
  ${({ theme }) => theme.typos.sectionHeader};
  font-size: 28px;
  color: white;
  text-align: center;
  margin-bottom: 28px;
`;

export const ScheduleButton = styled(Button)`
  margin: 20px auto;
  width: fit-content;
`;
