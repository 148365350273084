import React, { Component } from 'react';

import { themes } from 'kb-shared';
import styled from 'styled-components';

type Props = {
  progress: number;
};
type State = {};

export default class ProgressBar extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <Container>
        <Background>
          <Fill progress={this.props.progress} />
        </Background>
      </Container>
    );
  }
}

const Container = styled.div`
  height: 22px;
  width: 100%;
`;

const Background = styled.div`
  background-color: #fff;
  height: 100%;
  border-radius: 11px;
  margin: 0px 24px 0px 0;
  box-shadow: 0 2px 8px 0 #d8d8d8;
`;

interface FillProps {
  progress: number;
}

const Fill = styled.div`
  background-color: ${themes.colors.neutral.primaryNavy};
  height: 100%;
  width: ${(props: FillProps) => (props.progress * 100).toString() + '%'};
  border-radius: 11px;
`;
