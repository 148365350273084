import styled from 'styled-components';

export const TextContainer = styled.div<{ lineClamp: number }>`
  -webkit-line-clamp: ${({ lineClamp }) => lineClamp};
  -webkit-box-orient: vertical;
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  overflow-wrap: break-word;
  box-sizing: content-box;
`;
