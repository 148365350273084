import * as React from 'react';

import styled from 'styled-components';

import { baseZIndex } from './common';
import { Theme } from './index';

const NavigatorContainer = styled.div`
  display: flex;
  flex-direction: column-reverse;
`;

const NavContainer = styled.div`
  display: flex;
  max-width: 100%;
  overflow: auto;
  overflow-y: hidden;
  position: relative;

  ${({ size }: NavProps) => `
    align-items: ${size === 'large' ? 'stretch' : 'center'};
    max-height: ${size === 'large' ? '5.5em' : null};
  `}
`;

interface NavProps {
  size?: string;
}

interface TabProps {
  selected: boolean;
  color: string;
  fullWidth: boolean;
  size?: string;
}

const Tab = styled.div`
  margin-right: 4px;
  cursor: pointer;

  :last-of-type {
    margin-right: 0;
  }

  ${({ selected, color, fullWidth, size }: TabProps) => `
    background-color: ${color};
    z-index: ${selected ? baseZIndex + 1 : baseZIndex - 1};
    flex: ${fullWidth ? 1 : null};
    min-width: ${size === 'large' ? '210px' : 'auto'};
    height: auto;
  `}
`;

interface TabLabelProps {
  color: string;
  totalCount: number;
  size?: string;
}

const TabLabel = styled.h2`
  font-size: 18px;
  font-weight: bold;
  padding: 0 24px 0;
  margin-top: 11px;
  margin-bottom: 11px;
  line-break: wrap;
  white-space: pre-wrap;
  display: flex;
  text-overflow: ellipsis;
  box-sizing: content-box;

  ${({ color, size }: TabLabelProps) => `
    color: ${color};
    max-width: 100%;
    height: ${size === 'large' ? null : '50px'};
    line-height:${size === 'large' ? '1.3em' : null};
  `}
`;

interface ContentContainerProps {
  backgroundColor: string;
  borderColor?: string;
}

const ContentContainer = styled.div`
  ${({ theme }) => theme.grid.container}
  align-items: center;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  display: flex;
  justify-content: space-between;
  z-index: ${baseZIndex};

  ${({ backgroundColor, borderColor }: ContentContainerProps) => `
    background-color: ${backgroundColor};
    border: ${borderColor ? '1px Solid ' + borderColor : 'none'};
  `}
`;

type Props<T> = {
  currentIndex: number;
  items: Array<T>;
  getSectionTitle: (index: number, item: T) => string;
  renderContent: (index: number, item: T) => React.ReactNode | null;
  getUniqueKey: (index: number, item: T) => string;
  onSelect: (index: number, item: T) => void;
  theme: Theme;
  size?: string;
};

export default class DesktopNavigator<T> extends React.Component<Props<T>> {
  render() {
    const { currentIndex, items, onSelect, theme, size } = this.props;
    if (!items || items.length === 0) return null;

    const currentItem = items[currentIndex];
    if (!currentItem) {
      return null;
    }

    return (
      <NavigatorContainer>
        <ContentContainer
          backgroundColor={theme.content.selectedBG}
          borderColor={theme.content.borderColor}
        >
          {this.props.renderContent(currentIndex, currentItem)}
        </ContentContainer>
        <NavContainer size={size}>
          {items.map((item, index) => {
            const title = this.props.getSectionTitle(index, item);
            const itemKey = this.props.getUniqueKey(index, item);
            const selected = index === currentIndex;
            return (
              <Tab
                data-testid={itemKey}
                key={itemKey}
                selected={selected}
                fullWidth={this.props.theme.tab.fullWidthTabs}
                color={selected ? theme.tab.selectedBG : theme.tab.deselectedBG}
                onClick={e => {
                  e.preventDefault();
                  onSelect(index, item);
                }}
                size={size}
              >
                <TabLabel
                  color={selected ? theme.tab.selectedTitle : theme.tab.deselectedTitle}
                  totalCount={items.length}
                  size={size}
                >
                  {title}
                </TabLabel>
              </Tab>
            );
          })}
        </NavContainer>
      </NavigatorContainer>
    );
  }
}
