import styled from 'styled-components';

export const ThreeColumnGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: 12px;

  @media (min-width: ${({ theme }) => theme.breakpoints.tabletLandscape}) {
    grid-template-columns: repeat(2, minmax(0, 1fr));
    gap: 24px 16px;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.desktop}) {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
`;

export const TwoColumnGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: 24px 12px;

  @media (min-width: ${({ theme }) => theme.breakpoints.tabletLandscape}) {
    grid-template-columns: repeat(2, minmax(0, 1fr));
    gap: 24px 16px;
  }
`;

export const Container = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  gap: 24px;

  @media (min-width: ${({ theme }) => theme.breakpoints.tabletLandscape}) {
    gap: 48px;
  }
`;

export const Icon = styled.i`
  display: inline-block;
  width: 36px;
  height: 36px;
  color: ${({ theme }) => theme.colors.neutral.primaryNavy};
  margin-right: 8px;
  text-align: center;
  line-height: 36px;
`;

export const CreditCardIconContainer = styled.i`
  display: inline-block;
  width: 36px;
  height: 36px;
  font-size: 32px;
  color: ${({ theme }) => theme.colors.neutral.primaryNavy};
`;

export const ModalContent = styled.div`
  padding: 32px 48px 32px 48px;
  width: 650px;

  @media ${({ theme }) => theme.queries.mobile} {
    padding: 20px;
    width: 100%;
  }
`;

export const HeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 16px;
`;

export const PaymentSection = styled.div`
  padding: 16px 8px 16px 8px;
`;

export const ButtonsContainer = styled.div`
  display: flex;
  gap: 16px;
  flex-direction: row;
  margin-top: 8px;
  justify-content: flex-end;
`;
