import styled from 'styled-components';

export const ButtonsContainer = styled.div`
  height: 180px;
  width: 320px;
  min-width: 320px;

  > * {
    margin-top: 10px;
  }

  button {
    height: 40px;
  }

  button svg {
    width: 14px;
    height: 14px;
  }
`;
