import styled from 'styled-components';

export const InvoicesItemContainer = styled.div`
  display: flex;
  flex-direction: column;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tabletPortrait}) {
    background: ${({ theme }) => theme.colors.neutral.lavenderWhite};
  }
`;

export const InvoicesTitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  display: none;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.smallDesktop}) {
    display: block;
  }
`;

export const TableHead = styled.th<{ $alignRight?: boolean }>`
  padding: 24px 16px;
  text-transform: uppercase;
  background-color: ${({ theme }) => theme.colors.yellow.primary};
  border: 0.8px solid ${({ theme }) => theme.colors.yellow.darkYellow};
  ${({ $alignRight }) => ($alignRight ? 'text-align: right;' : 'text-align: start;')}
`;
