import React from 'react';
import Media from 'react-media';

import { themes } from 'kb-shared';

import {
  FormContent,
  Content,
  LeftContent,
  RightContent,
  TitleContainer,
  PageTitle,
  ContentContainer,
  FormContainer
} from './AuthFlowLayout.styles';
import { Props } from './AuthFlowLayout.types';

const breakpoints = themes.breakpoints;

const AuthFlowLayout = ({
  title,
  renderAboveTitleContent,
  renderMainContent,
  renderDesktopRightSidebar
}: Props) => (
  <ContentContainer>
    <Media query={{ minWidth: breakpoints.raw.smallDesktop }}>
      {desktop => {
        if (desktop) {
          return (
            <>
              <TitleContainer>
                <LeftContent>
                  {renderAboveTitleContent && renderAboveTitleContent(false)}
                  {title && <PageTitle>{title}</PageTitle>}
                </LeftContent>
                {renderDesktopRightSidebar && <RightContent />}
              </TitleContainer>
              <Content>
                <LeftContent>
                  <FormContainer>
                    <FormContent>{renderMainContent(false)}</FormContent>
                  </FormContainer>
                </LeftContent>
                {renderDesktopRightSidebar && (
                  <RightContent>{renderDesktopRightSidebar()}</RightContent>
                )}
              </Content>
            </>
          );
        } else {
          return (
            <RightContent>
              <TitleContainer mobile>
                {renderAboveTitleContent && renderAboveTitleContent(true)}
                {title && <PageTitle>{title}</PageTitle>}
              </TitleContainer>
              <Content mobile>
                <FormContainer>
                  <FormContent mobile>{renderMainContent(true)}</FormContent>
                </FormContainer>
              </Content>
            </RightContent>
          );
        }
      }}
    </Media>
  </ContentContainer>
);

export default AuthFlowLayout;
