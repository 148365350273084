import styled from 'styled-components';

export const ButtonBackgroundAndBorder = styled.div`
  padding: 24px 24px;
  width: 316px;
  height: 204px;
  background: ${({ theme }) => theme.colors.neutral.lavenderWhite};
  border: 1px dashed ${({ theme }) => theme.colors.neutral.lighterNavy};
  border-radius: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 26px;
`;
