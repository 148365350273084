export const ROLES_TO_PATIENT_LANGUAGE = {
  billing: 'a patient coordinator',
  coach: 'a coach',
  cx: 'a patient coordinator',
  embryologist: 'an embryologist',
  engineer: 'a tech specialist',
  front_desk: 'a patient coordinator',
  ma: 'a medical assistant',
  navigator: 'a navigator',
  np: 'a nurse practioner',
  nurse: 'a navigator',
  obgyn: 'a doctor',
  operator: 'a patient coordinator',
  pa: 'a physician assistant',
  rei: 'a doctor',
  sonographer: 'a sonographer'
};

export const nameToInitials = (fullName?: string) => {
  return fullName
    ?.replace(/[^A-Za-z\s]/g, '')
    ?.split(' ')
    ?.map((name: string) => name[0])
    ?.join('')
    ?.toUpperCase();
};
