import React from 'react';

import useHomeMarket from 'hooks/useHomeMarket';
import { Patient } from 'kb-shared';
import { pageUrl } from 'utilities/pageUrl';
import { getViosBookingUrl } from 'utilities/vios';

import { Container, Text, Link } from './ScheduleCTA.styled';

export const ScheduleCTA = ({ patient }: { patient: Patient }) => {
  const { id: homeMarketId, vios: isViosPatient } = useHomeMarket();

  if (isViosPatient) {
    return (
      <Container>
        <Text>
          If you are interested in discussing your at-home test results and your fertility options
          with a provider, please schedule an assessment. You will have a 1:1 consultation with a
          fertility specialist and personalized plan for your next step. If you have already had
          your first appointment, please reach out to your care coordinator to schedule a follow-up.
          If this is your first appointment, click on the Schedule button to request a visit.
        </Text>
        <Link href={getViosBookingUrl(parseInt(homeMarketId))} target="_blank">
          Schedule
        </Link>
      </Container>
    );
  }

  return patient.gender === 'male' ? (
    <Container>
      <Text>
        If you are interested in discussing your at-home test results and your fertility options
        with a Kindbody Male Fertility Specialist, please schedule a virtual Hormone Panel
        Consultation. We will work with you to complete a detailed assessment and a male fertility
        evaluation.
      </Text>
      <Link href="/Book?step=location&appointment_id=318">Schedule</Link>
    </Container>
  ) : (
    <Container>
      <Text>
        If you are interested in discussing your at-home test results and your fertility options
        with a Kindbody provider, please schedule a fertility assessment. You will have a 1:1
        consultation with a fertility specialist and personalized plan for your next step.
      </Text>
      <Link href={pageUrl.booking.bookingQuiz({ type: 'answer', id: 274 })}>Schedule</Link>
    </Container>
  );
};
