import React from 'react';
import { useHistory } from 'react-router-dom';

import { Alert } from 'components/v2/Alert/Alert';
import { Button } from 'components/v2/Buttons/Button';
import { CATEGORY_SLUG } from 'screens/Messages/Messages.constants';
import { pageUrl } from 'utilities/pageUrl';

import { scrollToTodoWidget } from '../MedicationsWidget.utils';
import { Wrapper, Popup } from './MedicationsWidgetBlocker.styled';

export const MedicationsWidgetBlocker: React.FC = () => {
  const history = useHistory();
  const messagesUrl = pageUrl.messages({
    categoryName: CATEGORY_SLUG.MEDICAL,
    subject: 'Medications and instructions question'
  });

  return (
    <Wrapper>
      <Popup>
        <Alert
          type="error"
          title="Medications and Instructions are disabled until all consents are signed!"
          message="To ensure we deliver care that’s not only personalized but also fully aligned with your preferences and needs, certain steps are necessary. By completing the required to-do items, you will help us to unlock all aspect of our services for you."
          actions={
            <>
              <Button
                uppercase
                label="I have more questions"
                onClick={() => history.push(messagesUrl)}
              />
              <Button
                uppercase
                label="Sign consents"
                category="primary-dark"
                onClick={scrollToTodoWidget}
              />
            </>
          }
        />
      </Popup>
    </Wrapper>
  );
};
