import { gql, useQuery } from '@apollo/client';

import { AppointmentType } from 'kb-shared';
import { BugTracker } from 'kb-shared/utilities/bugTracker';

import { UrlParamValue } from '../types/types';

const QUERY_APPOINTMENT_TYPES = gql`
  query appointmentTypes($appointmentTypeIds: [Int!]) {
    appointmentTypes(ids: $appointmentTypeIds) {
      id
      name
      virtual
      virtualFert
      description
      videoLink
      rateCents
      channel
      shouldChargeAtBooking
      category {
        id
        name
      }
    }
  }
`;

export const useAppointmentTypes = (appointmentTypeIds?: UrlParamValue<Array<number>>) => {
  const { data, loading, error } = useQuery<{
    appointmentTypes: Array<AppointmentType>;
  }>(QUERY_APPOINTMENT_TYPES, {
    skip: !appointmentTypeIds?.length,
    variables: { appointmentTypeIds: appointmentTypeIds },
    onError: error => {
      BugTracker.notify(error, 'Failed to load appointment types');
    }
  });

  return { data: data?.appointmentTypes, loading, error };
};
