import React from 'react';

import { getUserDisplayName } from 'utilities/userUtil';

import { MenuUserContainer } from './User.styled';
import { MenuUserNameH2 } from './User.styled';
import { UserProps } from './User.types';
import UserMembership from './UserMembership';

const User = ({ patient }: UserProps) => {
  if (!patient) return null;

  return (
    <MenuUserContainer>
      <MenuUserNameH2>{getUserDisplayName(patient)}</MenuUserNameH2>
      <UserMembership membership={patient.patientMembership} />
    </MenuUserContainer>
  );
};

export default User;
