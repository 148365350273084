import { useRef, useEffect, RefObject } from 'react';
import tippy, { Instance } from 'tippy.js';

interface Options {
  triggerElementRef: RefObject<HTMLElement>;
  content: string;
  visible?: boolean;
  allowHTML?: boolean;
}

export const useTooltip = ({
  triggerElementRef,
  content,
  visible = true,
  allowHTML = false
}: Options) => {
  const tooltip = useRef<Instance | null>(null);

  // init tooltip
  useEffect(() => {
    if (!triggerElementRef.current || tooltip.current) return;

    tooltip.current = tippy(triggerElementRef.current, {
      content,
      allowHTML
    });

    if (!visible) {
      tooltip.current.disable();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [triggerElementRef.current]);

  // show/hide tooltip
  useEffect(() => {
    if (!tooltip.current) return;

    if (visible) {
      tooltip.current.enable();
    } else {
      tooltip.current.disable();
    }
  }, [visible]);

  // update content
  useEffect(() => {
    tooltip.current?.setContent(content);
  }, [content]);

  // cleanup on unmount
  useEffect(() => {
    return () => {
      tooltip.current?.destroy();
    };
  }, []);
};
