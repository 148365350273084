import { useMutation } from '@apollo/client';
import React, { useState, useEffect } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';

import { ChevronRight } from 'assets/icons/Icons';
import { Loader } from 'components/Loader/Loader';
import { DateField, TextField } from 'components/v2/Form';
import { StepTrackerLayout } from 'components/v2/Layouts/StepTrackerLayout/StepTrackerLayout';
import { useFeatureFlags } from 'hooks';
import usePatient from 'hooks/usePatient';
import { ActionButtons } from 'screens/ActivateMembership/components/ActionButtons/ActionButtons';
import {
  Container,
  Form,
  TwoColumnContent,
  RighIconWrapper,
  LinkWrapper
} from 'screens/ActivateMembership/VerifyEligibility/VerifyEligibilityScreen.styled';
import { dateToString } from 'utilities/formatDate';
import { RoutesPath } from 'utilities/pageUrl';

import { ValidateMembershipResponse } from '../EnterUniqueId/UniqueIdScreen.types';
import { VALIDATE_ELIGIBILITY } from './VerifyEligibilityScreen.graphql';
import { ValidateMembershipVariables } from './VerifyEligibilityScreen.types';

export const VerifyEligibilityScreen = () => {
  const location = useLocation();
  const history = useHistory();
  const { code } = location?.state ?? { code: null };
  const { patient, loading: loadingPatient } = usePatient();
  const [firstName, setFirstName] = useState(patient?.firstName);
  const [lastName, setLastName] = useState(patient?.lastName);
  const [dateOfBirth, setDateOfBirth] = useState(patient?.birthday);
  const { benefitRegistrationViaFnLnDob } = useFeatureFlags();

  const [validateMembership, { loading }] = useMutation<
    ValidateMembershipResponse,
    ValidateMembershipVariables
  >(VALIDATE_ELIGIBILITY, {
    onError: () =>
      history.push({
        pathname: RoutesPath.ENTER_MEMBERSHIP_UNIQUE_ID,
        state: { code: code, fnLnDobValidationFailed: true }
      }),
    onCompleted: () =>
      history.push({
        pathname: RoutesPath.CONFIRM_MEMBERSHIP_ACTIVATION,
        state: { code, firstName: firstName?.trim(), lastName: lastName?.trim(), dateOfBirth }
      })
  });

  useEffect(() => {
    if (!patient || loadingPatient) return;
    if (!code) history.push(RoutesPath.ENTER_MEMBERSHIP_ACCESS_CODE);
    if (!!patient?.patientMembership?.membership?.id) {
      history.push('/');
    }
    if (!benefitRegistrationViaFnLnDob) history.push(RoutesPath.ENTER_MEMBERSHIP_UNIQUE_ID);
  }, [patient, loadingPatient, code, benefitRegistrationViaFnLnDob, history]);

  const onValidate = async () => {
    validateMembership({
      variables: {
        employerAccessCode: code,
        employeeAccessCode: '',
        employeeFirstName: firstName?.trim() || '',
        employeeLastName: lastName?.trim() || '',
        employeeDateOfBirth: dateOfBirth?.trim() || ''
      }
    });
  };

  if (loading) return <Loader />;

  return (
    <StepTrackerLayout
      title={'Verify my Eligibility'}
      stepNames={['Services', 'Location', 'Activate']}
      selectedStep={2}
      renderMainContent={() => {
        return (
          <Container>
            <Form>
              <TwoColumnContent>
                <TextField
                  type="text"
                  status="default"
                  id="input-first-name"
                  placeholder="Enter your first name"
                  label="FIRST NAME"
                  value={firstName}
                  onChange={event => setFirstName(event.currentTarget.value)}
                />
                <TextField
                  type="text"
                  status="default"
                  id="input-last-name"
                  placeholder="Enter your last name"
                  label="LAST NAME"
                  value={lastName}
                  onChange={event => setLastName(event.currentTarget.value)}
                />
              </TwoColumnContent>
              <TwoColumnContent>
                <DateField
                  id="input-birth-date"
                  placeholder="Enter your date of birth"
                  label="DATE OF BIRTH"
                  value={dateOfBirth ? new Date(dateOfBirth) : undefined}
                  onChange={date => setDateOfBirth(date ? dateToString(date) : undefined)}
                  status="default"
                  dateFormat="MM/dd/yyyy"
                />
              </TwoColumnContent>
              <Link to={{ pathname: RoutesPath.ENTER_MEMBERSHIP_UNIQUE_ID, state: { code: code } }}>
                <LinkWrapper>
                  <div>switch to enter unique ID</div>
                  <RighIconWrapper>
                    <ChevronRight type="solid" />
                  </RighIconWrapper>
                </LinkWrapper>
              </Link>
            </Form>
            <ActionButtons
              onBack={() => history.goBack()}
              onForward={onValidate}
              forwardDisabled={!firstName || !lastName || !dateOfBirth || loading}
            />
          </Container>
        );
      }}
    />
  );
};
