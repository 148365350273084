import { useQuery } from '@apollo/client';
import React from 'react';
import { useHistory } from 'react-router-dom';

import Button from 'components/Button/NewButton';
import { utils } from 'kb-shared';
import { STATUS } from 'screens/InterstitialModal/InterstitialModal.graphql';
import { InterstitialStatusResponse } from 'screens/InterstitialModal/InterstitialModal.types';
import { pageUrl } from 'utilities/pageUrl';

import {
  TitleText,
  AllSetContainer,
  FinishedSubtitle,
  Link
} from '../Components/Comprehension/Comprehension.styled';
import { getReturnBackRoute } from '../ConsentStateManager.utils';

export const FinalStep = ({ consentId }: { consentId: number }) => {
  const history = useHistory();
  const response = useQuery<InterstitialStatusResponse>(STATUS);

  // do not show consent at all if they do not have an outstanding consent to complete
  const requiredConsents = response.data?.interstitialStatus?.requiredConsents || [];
  const consentsIds = requiredConsents.map((consent): number =>
    parseInt(consent.linkUrl.replace(`${pageUrl.consents()}/`, ''))
  );
  const isRequiredConsent = consentsIds?.includes(consentId);

  const title = isRequiredConsent ? 'Thank you for signing this consent!' : `You're all set!`;

  const { text, pathname } = getReturnBackRoute();

  const buttonText = isRequiredConsent ? 'Continue account setup' : text;

  return (
    <AllSetContainer>
      <TitleText>{title}</TitleText>
      <FinishedSubtitle>
        If you have any questions, contact your care team at{' '}
        <Link href={`mailto:${utils.KBContacts.navigatorEmail}?subject=Consent Forms`}>
          {utils.KBContacts.navigatorEmail}
        </Link>{' '}
        or <Link href="tel://1-855-KND-BODY">1-855-KND-BODY</Link>
      </FinishedSubtitle>
      <Button text={buttonText} onClick={() => history.push(pathname)} />
    </AllSetContainer>
  );
};
