import { css } from 'styled-components';

import queries from './queries';

const pageHeader = css`
  font-size: 36px;

  @media ${queries.minDesktop} {
    font-size: 48px;
  }
`;

const sectionHeader = css`
  font-size: 28px;

  @media ${queries.minDesktop} {
    font-size: 36px;
  }
`;

const subsectionHeader = css`
  font-size: 24px;
`;

const subheadText = css`
  font-size: 16px;
  font-weight: 300;

  @media ${queries.minDesktop} {
    font-size: 20px;
  }
`;

const cardLabel = css`
  font-size: 14px;
  font-weight: bold;

  @media ${queries.minDesktop} {
    font-size: 18px;
  }
`;

const tabLabel = css`
  font-size: 18px;
  font-weight: bold;
`;

const bigNumber = css`
  font-size: 48px;
  font-weight: 300;
`;

const unitSymbol = css`
  font-size: 16px;
  font-weight: 300;
`;

const bodyText = css`
  font-size: 14px;
  font-weight: 300;
`;

const linkStyles = css`
  text-decoration: underline;

  &:hover {
    cursor: pointer;
  }
  &:focus {
    outline: none;
    font-weight: 600;
    text-decoration: underline;
  }
`;

const textLink = css`
  font-size: 14px;
  ${linkStyles};
`;

const footnotes = css`
  font-size: 12px;
  font-weight: 300;

  a {
    font-weight: bold;
    ${linkStyles};
  }
`;

const typos = Object.freeze({
  pageHeader,
  sectionHeader,
  cardHeader: sectionHeader,
  subsectionHeader,
  subheadText,
  cardLabel,
  tabLabel,
  bigNumber,
  unitSymbol,
  bodyText,
  textLink,
  footnotes
});

export default typos;
