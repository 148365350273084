import { FileLines, FilePdf } from 'assets/icons/Icons';
import styled from 'styled-components';

export const Preview = styled.img`
  border-radius: 6px;
  width: 100%;
  height: 100%;

  svg {
    font-size: 24px;
  }
`;

export const PDFIcon = styled(FilePdf).attrs({ type: 'regular' })`
  svg {
    font-size: 24px;
  }
`;

export const DefaultFileIcon = styled(FileLines).attrs({ type: 'regular' })`
  svg {
    font-size: 24px;
  }
`;
