import styled from 'styled-components';

export const InputWrap = styled.div`
  margin-bottom: 16px;
`;

export const ButtonWrap = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 16px;
`;
