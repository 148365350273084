import { Text } from 'components/v2/Typography/Text';
import { themes } from 'kb-shared';
import styled, { css } from 'styled-components';

export const Container = styled(Text).attrs({
  size: 'xs',
  fontStyle: 'bold',
  color: themes.colors.neutral.lightNavy
})<{ fromPatient: boolean }>`
  ${({ fromPatient }) =>
    fromPatient
      ? css`
          margin: 8px 4px 4px 0;
          align-self: flex-end;
        `
      : css`
          margin: 8px 0 4px 4px;
          align-self: flex-start;
        `}
`;
