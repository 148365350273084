import styled from 'styled-components';

export const FullPageLoaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-top: 20px;
  width: 100vw;
  height: 100vh;
`;

export const FullPageLoaderContent = styled.div`
  padding: 20px;
`;

export const ErrorContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  font-weight: 400;
  color: ${({ theme }) => theme.colors.neutral.primaryNavy};
  font-size: 18px;
  line-height: 20px;
`;

export const ErrorTextContainer = styled.div`
  padding: 10px 0 20px 0;
`;
