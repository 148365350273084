import { gql } from '@apollo/client';
import Honeybadger from '@honeybadger-io/js';
import {
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
  Elements,
  useElements,
  useStripe
} from '@stripe/react-stripe-js';
import {
  Stripe,
  StripeCardCvcElement,
  StripeCardCvcElementChangeEvent,
  StripeCardExpiryElement,
  StripeCardExpiryElementChangeEvent,
  StripeCardNumberElement,
  StripeCardNumberElementChangeEvent,
  loadStripe
} from '@stripe/stripe-js';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import {
  CardCvcContainer,
  CardExpirationContainer,
  CardNumberContainer
} from 'components/CreditCardForm/CreditCardForm.styled';
import { StripeContext } from 'components/CreditCardForm/types/creditCardForm';
import SelectInput from 'components/SelectInput';
import TextInput, { stripeInputStyle } from 'components/TextInput/NewTextInput';
import { RequiredIndicatorLabel } from 'components/TextInput/NewTextInput.styles';
import { Button } from 'components/v2/Buttons/Button';
import { Checkbox } from 'components/v2/Inputs';
import { DropdownOption, utils } from 'kb-shared';
import client from 'kb-shared/graphql/client';
import { showErrorToast, showSuccessToast } from 'utilities/notificationUtils';
import { RoutesPath } from 'utilities/pageUrl';

import {
  COUNTRIES_OPTIONS,
  DEFAULT_USER,
  LOCATION_OPTIONS,
  STRIPE_ACCOUNTS_BY_LOCATION
} from './DirectPayment.constants';
import {
  CardElementsContainer,
  CheckboxContainer,
  ContentContainer,
  LocationDetails,
  MarginVertical10,
  PortalLink,
  Subtitle,
  Title
} from './DirectPayment.styled';
import {
  filterNumberOnly,
  formatCurrency,
  getStripeAccount,
  getStripeAccountId
} from './DirectPayment.utils';

const { states } = utils;

const DEFAULT_STRIPE_ACCOUNT_ID = STRIPE_ACCOUNTS_BY_LOCATION.find(a => a.state === 'IL')
  ?.stripeAccountId as string;

const DirectPayment = () => {
  const history = useHistory();
  const [customer, setCustomer] = useState<any>(DEFAULT_USER);
  const [paymentData, setPaymentData] = useState<any>({});
  const [success, setSuccess] = useState(false);
  const [stripe, setStripe] = useState<Promise<Stripe | null> | null>(null);
  const [stripeContext, setStripeContext] = useState<StripeContext | undefined>(undefined);
  const [stripePublicKey, setStripePublicKey] = useState(DEFAULT_STRIPE_ACCOUNT_ID);
  const [loading, setLoading] = useState(false);
  const [reloading, setReloading] = useState(false);
  const [cardNumberStatus, setCardNumberStatus] = useState<StripeValidationStatusProps | undefined>(
    undefined
  );
  const [cardCvcStatus, setCardCvcStatus] = useState<StripeValidationStatusProps | undefined>(
    undefined
  );
  const [cardExpiryStatus, setCardExpiryStatus] = useState<StripeValidationStatusProps | undefined>(
    undefined
  );

  useEffect(() => {
    setReloading(true);
    setStripe(loadStripe(stripePublicKey).finally(() => setReloading(false)));
  }, [stripePublicKey]);

  useEffect(() => {
    if (!customer.location) return;
    // TODO: make optimization, change only if state/account changes
    setStripePublicKey(getStripeAccountId(customer.location));
  }, [customer.location]);

  const onStripeCardElementInitialized = (
    stripe: any,
    cardNumberElement: StripeCardNumberElement,
    cardCvcElement: StripeCardCvcElement,
    cardExpiryElement: StripeCardExpiryElement
  ) => {
    setStripeContext({
      stripe,
      cardNumberElement,
      cardCvcElement,
      cardExpiryElement
    });
  };

  const onCardNumberValidation = (status: StripeValidationStatusProps) =>
    setCardNumberStatus(status);
  const onCardCvcValidation = (status: StripeValidationStatusProps) => setCardCvcStatus(status);
  const onCardExpiryValidation = (status: StripeValidationStatusProps) =>
    setCardExpiryStatus(status);

  const setProperty = (prop: string, value: string) => {
    setCustomer({ ...customer, [prop]: value });
  };

  const setSelectionProperty = (option: DropdownOption, action: string) => {
    setCustomer({ ...customer, [action]: option.value, [`${[action]}Option`]: option });
  };

  const setBooleanProperty = (prop: string, value: boolean) => {
    setCustomer({ ...customer, [prop]: value });
  };

  const validateRequiredFieldsInput = () => {
    const errors = [];
    if (!customer.amount) {
      errors.push('amount');
    }
    if (!customer.firstName) {
      errors.push('first name');
    }
    if (!customer.lastName) {
      errors.push('last name');
    }
    if (!customer.addressLine) {
      errors.push('address line 1');
    }
    if (!customer.city) {
      errors.push('city');
    }
    if (!customer.state) {
      errors.push('state');
    }
    if (!customer.zipCode) {
      errors.push('ZIP code');
    }
    if (!customer.country) {
      errors.push('country');
    }
    if (!customer.emailAddress) {
      errors.push('email address');
    }
    if (!customer.location) {
      errors.push('location');
    }
    if (!customer.birthday) {
      errors.push('patient birthday');
    }
    if (!customer.patientName) {
      errors.push('patient first name and last name');
    }
    if (!cardNumberStatus || cardNumberStatus.empty) {
      errors.push('card number');
    }
    if (!cardCvcStatus || cardCvcStatus.empty) {
      errors.push('card CVC');
    }
    if (!cardExpiryStatus || cardExpiryStatus.empty) {
      errors.push('card expiration');
    }

    return errors;
  };

  const validateFieldsFormat = () => {
    const errors = [];

    if (cardNumberStatus && (cardNumberStatus?.error || !cardNumberStatus?.complete)) {
      errors.push('card number');
    }
    if (cardCvcStatus && (cardCvcStatus?.error || !cardCvcStatus?.complete)) {
      errors.push('card CVC');
    }
    if (cardExpiryStatus && (cardExpiryStatus?.error || !cardExpiryStatus?.complete)) {
      errors.push('card expiration');
    }

    return errors;
  };

  const onSubmitPayment = async () => {
    setLoading(true);

    try {
      if (!stripeContext) throw new Error('Stripe is not initialized');

      const { stripe, cardNumberElement } = stripeContext;

      if (!cardNumberElement) throw new Error('Card is not initialized');

      const requiredFiledsErrors = validateRequiredFieldsInput();
      const missingRequiredFieldsMessage =
        requiredFiledsErrors.length > 0
          ? `Fields missing a value: ${requiredFiledsErrors.join(', ')}.`
          : undefined;
      const fieldsFormatErrors = validateFieldsFormat();
      const fieldsFormatMessage =
        fieldsFormatErrors.length > 0
          ? `Fields missing a valid value: ${fieldsFormatErrors.join(', ')}.`
          : undefined;
      if (missingRequiredFieldsMessage || fieldsFormatMessage) {
        showErrorToast(
          <ValidationFailedMessage
            missingRequiredFieldsMessage={missingRequiredFieldsMessage}
            invalidFieldsMessage={fieldsFormatMessage}
          />
        );
        return;
      }

      const { error, paymentMethod } = await stripe.createPaymentMethod({
        type: 'card',
        card: cardNumberElement,
        billing_details: {
          name: `${customer.firstName} ${customer.lastName}`,
          email: customer.emailAddress,
          phone: customer.phone,
          address: {
            line1: customer.addressLine,
            line2: customer.addressLine2,
            city: customer.city,
            state: customer.state,
            postal_code: customer.zipCode,
            country: customer.country
          }
        }
      });

      if (!paymentMethod?.card || error) throw new Error('Payment method is not initialized');

      const amount = parseAmount(customer.amount);

      const response = await client.query({
        query: gql`
          query paymentIntentId(
            $paymentMethod: String!
            $amount: Int!
            $email: String!
            $stripeAccountName: String!
            $location: String!
            $patientId: String
            $patientName: String!
            $birthday: String!
            $isKindbodyRx: Boolean!
          ) {
            paymentIntentId(
              paymentMethod: $paymentMethod
              amount: $amount
              email: $email
              stripeAccountName: $stripeAccountName
              location: $location
              patientId: $patientId
              patientName: $patientName
              birthday: $birthday
              isKindbodyRx: $isKindbodyRx
            )
          }
        `,
        variables: {
          paymentMethod: paymentMethod.id,
          amount,
          email: customer.emailAddress,
          stripeAccountName: getStripeAccount(customer.location).location,
          location: customer.location,
          patientId: customer.patientId ? customer.patientId.toString() : null,
          patientName: customer.patientName,
          birthday: customer.birthday,
          isKindbodyRx: customer.isKindbodyRx
        }
      });

      const { paymentIntentId } = response?.data || {};

      if (!paymentIntentId) throw new Error('Payment intent is not initialized');

      const payment = await stripe.confirmCardPayment(paymentIntentId);

      const succeeded = payment?.paymentIntent?.status === 'succeeded';

      if (!succeeded) throw new Error('There was a problem with payment');

      const amountFormatted = formatCurrency(
        ((payment?.paymentIntent?.amount || 0) / 100).toString()
      );

      setSuccess(true);
      setPaymentData({
        amount: amountFormatted,
        receipt_email: payment?.paymentIntent?.receipt_email
      });
      showSuccessToast('Payment was successful!');
    } catch (error) {
      showErrorToast((error as Error)?.message || 'There was an error. Please try again later.');
      Honeybadger.notify(error as Error);
    } finally {
      setLoading(false);
    }
  };

  if (success) {
    return (
      <ContentContainer>
        <Title>Payment was successful</Title>
        <Subtitle>
          <b>Amount:</b> {paymentData.amount}
        </Subtitle>
        <Subtitle>
          <b>Receipt email:</b> {paymentData.receipt_email}
        </Subtitle>
        <Button
          label="GO BACK"
          onClick={() => {
            setSuccess(false);
            setCustomer(DEFAULT_USER);
            setPaymentData({});
          }}
        />
      </ContentContainer>
    );
  }

  return (
    <ContentContainer>
      <Title>Billing Info</Title>
      <MarginVertical10>
        <TextInput
          inputType="currency"
          name="amount"
          label="AMOUNT"
          value={customer.amount || ''}
          onFocus={event => setProperty('amount', filterNumberOnly(event.target.value))}
          onBlur={event => setProperty('amount', formatCurrency(event.target.value))}
          onChange={event => {
            const value = event.target.value;
            const numberOnly = filterNumberOnly(value);
            setProperty('amount', numberOnly);
          }}
          autoComplete="cc-number"
          spellCheck="false"
          placeholder="$0.00"
          isRequired={true}
        />
      </MarginVertical10>
      <MarginVertical10>
        <TextInput
          name="firstName"
          label="FIRST NAME"
          value={customer.firstName}
          onChange={event => setProperty('firstName', event.target.value)}
          autoComplete="given-name"
          spellCheck="false"
          placeholder="First name"
          isRequired={true}
        />
      </MarginVertical10>
      <MarginVertical10>
        <TextInput
          name="lastName"
          label="LAST NAME"
          value={customer.lastName}
          onChange={event => setProperty('lastName', event.target.value)}
          autoComplete="family-name"
          spellCheck="false"
          placeholder="Last name"
          isRequired={true}
        />
      </MarginVertical10>
      <MarginVertical10>
        <TextInput
          name="addressLine"
          label="ADDRESS LINE 1"
          value={customer.addressLine}
          onChange={event => setProperty('addressLine', event.target.value)}
          placeholder="Address"
          isRequired={true}
        />
      </MarginVertical10>
      <MarginVertical10>
        <TextInput
          name="addressLine2"
          label="ADDRESS LINE 2"
          value={customer.addressLine2}
          onChange={event => setProperty('addressLine2', event.target.value)}
          placeholder={'Address'}
        />
      </MarginVertical10>
      <MarginVertical10>
        <TextInput
          name="city"
          label="CITY"
          placeholder="City"
          value={customer.city}
          onChange={event => setProperty('city', event.target.value)}
          isRequired={true}
        />
      </MarginVertical10>

      <SelectInput
        name="state"
        label="STATE"
        value={customer.state}
        placeholder="Select state"
        selectedOption={customer.stateOption}
        extractTitle={item => (item ? item.label : '')}
        onSelect={event => setSelectionProperty(event, 'state')}
        options={states}
        isRequired={true}
      />

      <MarginVertical10>
        <TextInput
          name="zipCode"
          label="ZIP"
          value={customer.zipCode}
          placeholder="55555"
          autoComplete="postal-code"
          inputType="zip"
          onChange={event => setProperty('zipCode', event.target.value)}
          isRequired={true}
        />
      </MarginVertical10>

      <SelectInput
        name="country"
        label="COUNTRY"
        extractTitle={item => (item ? item.label : '')}
        value={customer.country}
        placeholder="Select country"
        selectedOption={COUNTRIES_OPTIONS.find((option: any) => option.value === customer.country)}
        onSelect={event => setSelectionProperty(event, 'country')}
        options={COUNTRIES_OPTIONS}
        isRequired={true}
      />

      <MarginVertical10>
        <TextInput
          name="emailAddress"
          label="EMAIL ADDRESS"
          value={customer.emailAddress}
          onChange={event => setProperty('emailAddress', event.target.value)}
          autoComplete="email"
          spellCheck="false"
          placeholder="janedoe@email.com"
          inputMode="email"
          isRequired={true}
        />
      </MarginVertical10>
      <MarginVertical10>
        <TextInput
          inputType="phone"
          name="phone"
          label="PHONE #"
          value={customer.phone}
          onChange={event => setProperty('phone', event.target.value)}
          autoComplete="tel"
          inputMode="tel"
          placeholder="(+XXXX)Phone Number"
        />
      </MarginVertical10>

      <Title>Patient Information</Title>

      <LocationDetails>
        Looking for another location? Please visit the patient portal{' '}
        <PortalLink onClick={() => history.push(RoutesPath.INVOICES)}> here </PortalLink> to make
        payments relating to other clinics.
      </LocationDetails>

      <SelectInput
        name="location"
        label="LOCATION"
        extractTitle={item => (item ? item.label : '')}
        value={customer.location}
        placeholder="Select location"
        selectedOption={customer.locationOption}
        onSelect={event => setSelectionProperty(event, 'location')}
        options={LOCATION_OPTIONS}
        isRequired={true}
      />

      <CheckboxContainer>
        <Checkbox
          label="Kindbody Rx"
          isChecked={customer.isKindbodyRx}
          onChange={() => setBooleanProperty('isKindbodyRx', !customer.isKindbodyRx)}
          id="kb-rx"
        />
      </CheckboxContainer>

      <MarginVertical10>
        <TextInput
          inputType="default"
          name="patientId"
          label="PATIENT ACCOUNT NUMBER"
          placeholder="55555"
          value={customer.patientId || ''}
          onChange={event => {
            const value = event.target.value;
            const numberOnly = filterNumberOnly(value);
            setProperty('patientId', numberOnly);
          }}
          spellCheck="false"
        />
      </MarginVertical10>
      <MarginVertical10>
        <TextInput
          name="patientName"
          label="PATIENT FIRST AND LAST NAME"
          value={customer.patientName}
          onChange={event => setProperty('patientName', event.target.value)}
          autoComplete="given-name"
          spellCheck="false"
          placeholder="Patient first and last name"
          isRequired={true}
        />
      </MarginVertical10>
      <MarginVertical10>
        <TextInput
          inputType="date"
          name="birthday"
          label="PATIENT DATE OF BIRTH"
          value={customer.birthday}
          onChange={event => setProperty('birthday', event.target.value)}
          autoComplete="bday"
          isRequired={true}
        />
      </MarginVertical10>
      <Title>Payment Info</Title>
      {!reloading && (
        <Elements stripe={stripe} key={stripePublicKey}>
          <ElementsContent
            onStripeCardElementInitialized={onStripeCardElementInitialized}
            onCardNumberValidation={onCardNumberValidation}
            onCardCvcValidation={onCardCvcValidation}
            onCardExpiryValidation={onCardExpiryValidation}
          />
        </Elements>
      )}
      <Button label="SUBMIT PAYMENT" onClick={onSubmitPayment} isDisabled={loading} />
    </ContentContainer>
  );
};

const ElementsContent = ({
  onStripeCardElementInitialized,
  onCardNumberValidation,
  onCardCvcValidation,
  onCardExpiryValidation
}: any) => {
  const stripe = useStripe();
  const elements = useElements();

  useEffect(() => {
    if (!stripe || !elements) return;

    const cardNumberElement = elements.getElement(CardNumberElement);
    const cardCvcElement = elements.getElement(CardCvcElement);
    const cardExpiryElement = elements.getElement(CardExpiryElement);

    if (cardNumberElement && cardCvcElement && cardExpiryElement) {
      cardNumberElement.on('change', event =>
        onCardNumberValidation(createStripeValidationStatus(event))
      );
      cardCvcElement.on('change', event =>
        onCardCvcValidation(createStripeValidationStatus(event))
      );
      cardExpiryElement.on('change', event =>
        onCardExpiryValidation(createStripeValidationStatus(event))
      );
      onStripeCardElementInitialized(stripe, cardNumberElement, cardCvcElement, cardExpiryElement);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stripe, elements]);

  const options = {
    style: stripeInputStyle
  };

  return (
    <CardElementsContainer>
      <CardNumberContainer>
        <>
          Card number <RequiredIndicatorLabel>*</RequiredIndicatorLabel>
        </>
        <CardNumberElement options={options} />
      </CardNumberContainer>

      <CardExpirationContainer>
        Expiration <RequiredIndicatorLabel>*</RequiredIndicatorLabel>
        <CardExpiryElement options={options} />
      </CardExpirationContainer>

      <CardCvcContainer>
        CVC <RequiredIndicatorLabel>*</RequiredIndicatorLabel>
        <CardCvcElement options={options} />
      </CardCvcContainer>
    </CardElementsContainer>
  );
};

// const remove dot from amount
const parseAmount = (amount: string) => {
  if (!amount) return 0;

  const number = filterNumberOnly(amount);
  const centsString = number.replace('.', '');

  return parseInt(centsString, 10);
};

interface ValidationFailedMessageProps {
  missingRequiredFieldsMessage: string | undefined;
  invalidFieldsMessage: string | undefined;
}
const ValidationFailedMessage = (props: ValidationFailedMessageProps) => {
  const { missingRequiredFieldsMessage, invalidFieldsMessage } = props;
  return (
    <>
      <div>Please, fill in all required fields (fields marked with *).</div>
      {missingRequiredFieldsMessage && (
        <>
          <br />
          <div>{missingRequiredFieldsMessage}</div>
        </>
      )}
      {invalidFieldsMessage && (
        <>
          <br />
          <div>{invalidFieldsMessage}</div>
        </>
      )}
    </>
  );
};

interface StripeValidationStatusProps {
  empty: boolean;
  complete: boolean;
  error:
    | undefined
    | {
        type: 'validation_error';
        code: string;
        message: string;
      };
}

const createStripeValidationStatus = (
  event:
    | StripeCardNumberElementChangeEvent
    | StripeCardCvcElementChangeEvent
    | StripeCardExpiryElementChangeEvent
): StripeValidationStatusProps => {
  return {
    empty: event.empty,
    complete: event.complete,
    error: event.error
  };
};

export default DirectPayment;
