import React from 'react';

import { BookingQuizCategory } from '../BookingQuiz.types';
import { CATEGORY_ICONS } from '../BookingQuizIcons';
import { QuestionContainer, QuestionText } from './Question.styled';

type Props = {
  text: string;
  category?: BookingQuizCategory;
};

export const Question = ({ text, category }: Props) => (
  <QuestionContainer>
    {category && CATEGORY_ICONS[category]}
    <QuestionText>{text}</QuestionText>
  </QuestionContainer>
);
