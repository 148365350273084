import React from 'react';

import { formatDate } from 'utilities/formatDate';

import { PartnerInvite } from '../../InterstitialModal.types';
import { ListItemContainer, Name, BirthDate, Email, Status } from './PartnerListItem.styled';

export const PartnerListItem = (invite: PartnerInvite) => (
  <ListItemContainer>
    <div>
      <Name>{invite.firstName + ' ' + invite.lastName}</Name>
      <BirthDate> ({formatDate(invite.birthDate)})</BirthDate>
      <Email>{invite.email}</Email>
    </div>
    <Status>{formatWordsToCamelCase(invite.status)}</Status>
  </ListItemContainer>
);

const formatWordsToCamelCase = (string: string) =>
  string.replace(/\w\S*/g, (w: string) => w.replace(/^\w/, c => c.toUpperCase()));
