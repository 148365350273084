import { ApolloError } from '@apollo/client';

import { Clinic } from 'kb-shared/types';
import { parseNumber } from 'utilities/parseNumber';

import { LoadedEntities } from '../types/types';
import { useLocations } from './useLocations';

export const usePreselectedClinic = ({
  selectedLab,
  selectedProduct,
  isOnlyVirtual,
  clinicId
}: {
  selectedLab?: LoadedEntities['selectedLab'];
  selectedProduct?: LoadedEntities['selectedProduct'];
  isOnlyVirtual: boolean;
  clinicId?: number;
}): { clinic: Clinic | undefined; loading: boolean; error?: ApolloError } => {
  const appointmentTypeId = parseNumber(
    selectedProduct?.type === 'appointment' ? selectedProduct.data.id : ''
  );
  const labId = parseNumber(selectedLab?.id);
  const { data: clinics, loading, error } = useLocations(appointmentTypeId, labId, clinicId);

  // we only pre-select a clinic if we have a selected lab (aka market), appointment
  // and there is no virtual options to select
  const preselectClinic =
    selectedLab &&
    selectedProduct?.type === 'appointment' &&
    !isOnlyVirtual &&
    clinics?.length === 1;

  return {
    clinic: preselectClinic ? clinics[0] : undefined,
    loading,
    error
  };
};
