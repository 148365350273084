export const formatGestationalAges = (gestationalAgeInDays: number) => {
  const weeks = Math.floor(gestationalAgeInDays / 7);
  const weeksPlural = weeks > 1;

  const remainingDays = Math.floor(gestationalAgeInDays % 7);
  const remainingDaysPlural = remainingDays > 1;

  let format = '';
  if (weeks) {
    format += `${weeks} ${weeksPlural ? 'weeks' : 'week'}`;
  }
  if (remainingDays) {
    format += `${weeks ? ' and ' : ''}${remainingDays} ${remainingDaysPlural ? 'days' : 'day'}`;
  }

  return format;
};
