import { themes } from 'kb-shared';
import styled from 'styled-components';
const { breakpoints } = themes;

const Card = styled.div`
  max-width: 300px;
  padding: 20px;
`;

export default Card;

export const DashboardWidget = styled.div`
  background-color: white;
  box-shadow: 0 2px 8px 0 #d8d8d8;
`;

// Card types and child elements
export const DashboardCard = styled(Card)`
  align-self: center;
  max-width: unset;
  margin-right: initial;
`;

export const ConfirmationCard = styled(Card)`
  padding: 20px;
  width: 300px;
  text-align: left;
`;

interface CardTypeProps {
  confirmation?: boolean;
}

export const CardHeader = styled.div`
  font-size: 14px;
  font-weight: 600;
  margin-bottom: ${(p: CardTypeProps) => (p.confirmation ? '3px' : '15px')};
  @media screen and (min-width: ${breakpoints.largePhonePortrait}) {
    font-size: ${(p: CardTypeProps) => (p.confirmation ? '18px' : '14px')};
  }
`;

export const CardTitle = styled.div`
  margin-bottom: 10px;
  font-size: 28px;
  letter-spacing: -0.3px;
  line-height: 42px;
  text-align: left;
  @media screen and (min-width: ${breakpoints.largePhonePortrait}) {
    font-size: 36px;
    letter-spacing: -0.3px;
    line-height: 42px;
  }
  @media screen and (min-width: ${breakpoints.tabletPortrait}) {
    ${(p: CardTypeProps) => (p.confirmation ? null : 'font-size: 32px')}
  }
`;

export const CardButtonGroup = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: center;
  justify-content: flex-end;
  align-self: center;
  padding-bottom: 18px;
  width: 100% !important;

  > .button__container {
    margin-bottom: 20px !important;

    > button {
      max-width: 300px !important;
    }
  }
`;

export const TimeText = styled.div`
  margin-bottom: 20px;
`;
