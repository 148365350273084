import React from 'react';

import { Button } from 'components/v2/Buttons/Button';
import { Text } from 'components/v2/Typography';

import { Modal } from '../Modal/Modal';
import { Bottom } from './ConfirmModal.styled';

interface Props {
  title: string;
  description: string;
  confirmButtonLabel: string;
  onConfirm: () => void;
  onCancel: () => void;
  open: boolean;
}

export const ConfirmModal = ({
  title,
  description,
  confirmButtonLabel,
  open,
  onConfirm,
  onCancel
}: Props) => {
  return (
    <Modal title={title} open={open} onCancel={onCancel}>
      <Text size="md" fontStyle="regular">
        {description}
      </Text>

      <Bottom>
        <Button label="Cancel" category="secondary" size="lg" onClick={onCancel} />
        <Button label={confirmButtonLabel} category="primary" size="lg" onClick={onConfirm} />
      </Bottom>
    </Modal>
  );
};
