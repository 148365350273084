import { gql } from '@apollo/client';

export const VALIDATE_ELIGIBILITY = gql`
  mutation validatePatientEnterpriseMembership(
    $employerAccessCode: String!
    $employeeAccessCode: String!
    $employeeFirstName: String!
    $employeeLastName: String!
    $employeeDateOfBirth: String!
  ) {
    validatePatientEnterpriseMembership(
      employerAccessCode: $employerAccessCode
      employeeAccessCode: $employeeAccessCode
      employeeFirstName: $employeeFirstName
      employeeLastName: $employeeLastName
      employeeDateOfBirth: $employeeDateOfBirth
    ) {
      patientMembership {
        id
      }
    }
  }
`;
