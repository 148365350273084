import React from 'react';
import { useHistory } from 'react-router-dom';

import { Warning } from 'assets/icons/Icons';
import { Button } from 'components/v2/Buttons/Button';
import { Text } from 'components/v2/Typography/Text';
import { utils } from 'kb-shared';
import theme from 'kb-shared/theme';
import { CycleVisitDaysResponse } from 'screens/Dashboard/Dashboard.types';
import { IconContainer } from 'screens/Dashboard/Widgets/Widgets.styled';
import { Badge } from 'screens/Messages/components/Badge/Badge';
import { useBreakpoints } from 'utilities/useBreakpoints';

import {
  CycleVisitContainer,
  CycleVisitDescription,
  CycleVisitInfo,
  CycleVisitTitle
} from './CycleVisits.styled';

export const CycleVisits = ({
  nextCycleVisit,
  cycleVisitData
}: {
  nextCycleVisit?: string;
  cycleVisitData?: CycleVisitDaysResponse;
}): JSX.Element => {
  const { cycleVisitId } = utils;
  const history = useHistory();
  const { isMobile } = useBreakpoints();

  if (!nextCycleVisit) return <></>;

  const badgeLabel = `${(cycleVisitData?.cycleVisitDays?.indexOf(nextCycleVisit) || 0) +
    1}/${cycleVisitData?.cycleVisitDays?.length || 1}`;
  const navigateToBookingQuiz = () =>
    history.push(`/book?step=location&appointment_id=${cycleVisitId()}&date=${nextCycleVisit}`);

  return (
    <CycleVisitContainer>
      <CycleVisitInfo>
        <CycleVisitTitle>
          <Badge label={badgeLabel} type="error" size="md" />
          <Text size="md" fontStyle="semibold">
            Cycle Visit
          </Text>
        </CycleVisitTitle>
        <CycleVisitDescription>
          <IconContainer
            $width={24}
            $height={24}
            $iconWidth={16}
            $iconHeight={16}
            $noMargin
            $color={theme.colors.semantic.darkRed}
          >
            <Warning type="solid" />
          </IconContainer>
          <Text size="md" fontStyle="semibold" color={theme.colors.semantic.darkRed}>
            Not Scheduled!
          </Text>
        </CycleVisitDescription>
      </CycleVisitInfo>
      <Button
        label={`Schedule Appointment`}
        onClick={navigateToBookingQuiz}
        category="error"
        fullWidth={isMobile}
      />
    </CycleVisitContainer>
  );
};
