import React from 'react';

import { AuthFlowLayout } from 'components/v2/Layouts/AuthFlowLayout/AuthFlowLayout';

import { ConfirmNewEmailForm } from './ConfirmNewEmailForm';

export const ConfirmNewEmail = () => {
  return (
    <AuthFlowLayout
      title={'New Email Address Confirmation'}
      renderMainContent={() => <ConfirmNewEmailForm />}
    />
  );
};
