const pEuploid: { [key: number]: number } = {
  35: 0.574,
  36: 0.564,
  37: 0.486,
  38: 0.466,
  39: 0.44,
  40: 0.359,
  41: 0.327,
  42: 0.285,
  43: 0.206,
  44: 0.127
};

export const getEggsRequired = (numKids: number, pourcentage: number, age: number) => {
  if (age < 25 || age > 44) return null;
  if (age < 35) age = 35;

  const pBlastFirstConst = age < 36 ? 0.95 : 0.85;
  const pBlast = pBlastFirstConst * Math.exp(2.8043 - 0.1112 * age);

  const probability = pourcentage / 100;
  const ln = Math.log(probability);

  const logX = Math.log(1 - 2.71 ** (ln / numKids));
  const logBase = Math.log(1 - 0.6 * pEuploid[age] * pBlast);

  const result = Math.round(logX / logBase);

  return result;
};
