import styled, { css } from 'styled-components';

import { CheckBoxSize } from './Checkbox.types';

export const containerSizeStyle = {
  small: css`
    width: 16px;
    height: 16px;
    font-size: 14px;
  `,
  large: css`
    font-size: 28px;
  `
};

export const CheckmarkContainer = styled.span<{ size?: CheckBoxSize; useAlternateColor: boolean }>`
  display: block;
  height: 1em;
  aspect-ratio: 1 / 1;
  border-radius: 4px;
  align-self: flex-start;
  margin: 4px;
  box-shadow: ${({ theme }) => theme.shadows.button.default};
  ${({ size }) => size && containerSizeStyle[size]}

  &:hover {
    box-shadow: ${({ theme }) => theme.shadows.button.hover};
  }
`;

export const Checkmark = styled.span`
  display: none;
  width: 100%;
  height: 100%;
  color: white;

  svg {
    width: 100%;
    height: 100%;
  }

  svg path {
    fill: currentColor;
  }
`;

export const ContainerLabel = styled.label<{
  size?: CheckBoxSize;
  disabled?: boolean;
  isChecked?: boolean;
}>`
  display: flex;
  cursor: pointer;
  user-select: none;

  color: ${({ theme }) => theme.colors.neutral.primaryNavy};
  gap: 8px;
  align-items: center;

  // Hide the browser's default checkbox input
  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  input:checked:focus ~ ${CheckmarkContainer} {
    border: none;
    box-shadow: ${({ theme }) => theme.shadows.button.active};
  }

  // background when checkbox is checked
  input:checked ~ ${CheckmarkContainer} {
    box-shadow: none;
    border: 1px solid ${({ theme }) => theme.colors.yellow.darkYellow};
    background-color: ${({ theme }) => theme.colors.yellow.primary};
  }

  // foreground when checkbox is checked
  input:checked ~ ${CheckmarkContainer} ${Checkmark} {
    display: flex;
  }

  &:focus {
    outline: 0;

    ${CheckmarkContainer} {
      box-shadow: ${({ theme }) => theme.shadows.button.activeSecondary};
    }
  }

  ${({ disabled, isChecked }) => {
    if (disabled && isChecked) {
      return css`
        ${Checkmark} {
          svg path {
            fill: ${({ theme }) => theme.colors.neutral.lighterNavy};
          }
        }

        input:checked ~ ${CheckmarkContainer} {
          border: 1px solid transparent;
          background-color: ${({ theme }) => theme.colors.neutral.lavender};
        }
      `;
    }

    if (disabled && !isChecked) {
      return css`
        input ~ ${CheckmarkContainer} {
          box-shadow: none;
          border: 1px solid ${({ theme }) => theme.colors.neutral.lavender};
          background-color: ${({ theme }) => theme.colors.neutral.lavender};
        }
      `;
    }
  }}
`;
