import React, { useState } from 'react';

import kindbody from 'assets/logos/kindbody.svg';
import { ConsentModal } from 'components/ConsentModal/ConsentModal';
import { Text } from 'components/v2/Typography';
import { useOnWindowResize } from 'hooks/useOnWindowResize';
import theme from 'kb-shared/theme';
import breakpointsRaw from 'kb-shared/theme/breakpointsRaw';

import { Container, LinkContainer, LinkTextContainer } from './Legal.styled';

export const Legal = () => {
  const [openedTermOfUseModal, setOpenedTermOfUseModal] = useState<boolean>(false);
  const [openedPrivacyPolicy, setOpenedPrivacyPolicy] = useState<boolean>(false);
  const [textSize, setTextSize] = useState<'xs' | 'sm'>('xs');
  const currentYear = new Date().getFullYear();

  useOnWindowResize(() => {
    const isSmallScreen = document.body.clientWidth <= breakpointsRaw.phone;
    setTextSize(isSmallScreen ? 'xs' : 'sm');
  }, 300);

  return (
    <Container>
      <a href="https://kindbody.com/" target="_blank" rel="noopener noreferrer">
        <img src={kindbody} alt="Kindbody logo" />
      </a>
      <LinkContainer>
        <LinkTextContainer onClick={() => setOpenedPrivacyPolicy(true)}>
          <Text size={textSize} color={theme.colors.blue.darkBlue}>
            Privacy Policy
          </Text>
        </LinkTextContainer>
        <span>|</span>
        <LinkTextContainer onClick={() => setOpenedTermOfUseModal(true)}>
          <Text size={textSize} color={theme.colors.blue.darkBlue}>
            Terms of Use
          </Text>
        </LinkTextContainer>
      </LinkContainer>
      <Text size={textSize} color={theme.colors.neutral.primaryNavy}>
        © {currentYear} Kindbody
      </Text>
      <ConsentModal
        id="114"
        open={openedTermOfUseModal}
        title="Term of Use"
        close={() => setOpenedTermOfUseModal(false)}
      />
      <ConsentModal
        id="116"
        open={openedPrivacyPolicy}
        title="Privacy Policy"
        close={() => setOpenedPrivacyPolicy(false)}
      />
    </Container>
  );
};
