import { KB360AppointmentType } from '../graphql/queries';
import { KB360ServiceType } from '../ServiceTypeIcon';

export const filterAppointmentTypes = (
  appointmentTypes: KB360AppointmentType[],
  selectedCategories?: KB360ServiceType[]
) => {
  if (!selectedCategories?.length) return appointmentTypes;

  return appointmentTypes.filter(appointmentType =>
    appointmentType.serviceTypes.find(st => selectedCategories.includes(st.name))
  );
};
