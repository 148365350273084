import { themes } from 'kb-shared';
import styled from 'styled-components';
const { breakpoints } = themes;

export const ActivateBenefitContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 0 16px;
`;
export const ActivateBenefit = styled.div`
  margin-top: 32px;
  border-radius: 8px;
  max-width: 560px;
  width: 100%;
  padding: 16px 24px;

  border: 1px solid ${themes.colors.yellow.lightYellow};
  background: ${themes.colors.yellow.lightYellow};
  box-shadow: 0px 1px 1px 0px rgba(10, 13, 61, 0.16), 0px 0px 0px 8px rgba(249, 225, 104, 0.24);

  display: flex;
  flex-direction: column;
  justify-content: center;

  > div {
    width: 100%;
    padding: 8px 0;
  }

  @media screen and (min-width: ${breakpoints.largePhonePortrait}) {
    flex-direction: row;
    align-items: space-between;

    > div {
      width: 50%;
      padding: 0 8px;
    }
  }
`;
