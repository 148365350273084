import styled from 'styled-components';

export const CreatePatientFormContainer = styled.div`
  padding: 16px;
  widht: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

export const InfoContainer = styled.div`
  text-align: center;
`;

export const ErrorContainer = styled.div`
  margin-top: 15px;
`;
