import React from 'react';

import { AppointmentsWidgetProps } from './AppointmentsWidget.types';
import { BookAppointmentWidget } from './BookAppointmentWidget/BookAppointmentWidget';
import { UpcomingAppointmentsWidget } from './UpcomingAppointmentsWidget/UpcomingAppointmentsWidget';

export const AppointmentsWidget: React.FC<AppointmentsWidgetProps> = ({
  isViosPatient,
  upcomingAppointments,
  refetch
}) => {
  if (isViosPatient) return null;

  return upcomingAppointments.length > 0 ? (
    <UpcomingAppointmentsWidget upcomingAppointments={upcomingAppointments} refetch={refetch} />
  ) : (
    <BookAppointmentWidget />
  );
};
