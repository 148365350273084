import styled from 'styled-components';

export const InfoAreaContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  min-height: 95px;
  margin-top: 8px;
  padding: 16px 24px;
  background-color: white;
  border-radius: 8px;
  gap: 16px;
  color: ${({ theme }) => theme.colors.neutral.primaryNavy};

  @media ${({ theme }) => theme.queries.minTablet} {
    margin-top: 24px;
  }

  @media ${({ theme }) => theme.queries.minDesktop} {
    margin-top: 32px;
  }
`;

export const TextContainer = styled.div`
  margin-top: 16px;
`;
