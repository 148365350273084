import React from 'react';

import styled from 'styled-components';

import RiskAssessmentField from '../types/RiskAssessmentField';
import ChoiceButton from './ChoiceButton';

interface Props {
  field: RiskAssessmentField;
  onChange: (response: boolean) => void;
}

const Field = ({ field, onChange }: Props) => {
  return (
    <Container>
      {field.title}
      <ButtonContainer>
        <StyledChoiceButton
          id={field.id + '-yes'}
          text="Yes"
          selected={field.answer !== undefined && field.answer}
          onChange={() => onChange(true)}
        />
        <StyledChoiceButton
          id={field.id + '-no'}
          text="No"
          selected={field.answer !== undefined && !field.answer}
          onChange={() => onChange(false)}
        />
      </ButtonContainer>
    </Container>
  );
};

export default Field;

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 14px 16px;
  margin: 0;

  border-top: solid 1px #979797;

  font-size: 14px;
  line-height: 19px;

  :last-of-type {
    border-bottom: solid 1px #979797;
  }

  @media ${({ theme }) => theme.queries.minTablet} {
    margin: 16px 0px;
    padding: 0px;
    border: none;

    font-size: 18px;

    :last-of-type {
      border: none;
    }
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-wrap: no-wrap;
  margin-left: 12px;
`;

const StyledChoiceButton = styled(ChoiceButton)`
  margin-right: 12px;

  :last-of-type {
    margin-right: 0px;
  }
`;
