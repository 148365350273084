import { useMutation } from '@apollo/client';
import React, { useState } from 'react';

import { Film, Pills } from 'assets/icons/Icons';
import { Button } from 'components/v2/Buttons/Button';
import { Text } from 'components/v2/Typography';
import { DailyInstruction, Drug, Patient, theme, themes } from 'kb-shared';
import { BugTracker } from 'kb-shared/utilities/bugTracker';
import { MEDICATIONS } from 'kb-shared/utilities/MedicationsInstruction';
import {
  formatToWeekdayMonthDay,
  momentWithParsedTimeZone
} from 'kb-shared/utilities/momentHelpers';
import { analytics } from 'utilities/analytics';
import { formatDate } from 'utilities/formatDate';
import { modifyUrlForEnvironment } from 'utilities/urlUtils';
import { useBreakpoints } from 'utilities/useBreakpoints';

import { IHaveMoreQuestionsModal } from '../IHaveMoreQuestionsModal/IHaveMoreQuestionsModal';
import { TextWithShowMore } from '../TextWithShowMore/TextWithShowMore';
import { ACKNOWLEDGE_DAILY_INSTRUCTION } from './MedicationsAndNotes.graphql';
import {
  AcknowledgeButtonsContainer,
  AcknowledgeContainer,
  DrugContainer,
  DrugIconContainer,
  DrugRecurrenceContainer,
  InformationalTextContainer,
  MedicationsAndNotesContainer,
  Note,
  NotesContainer
} from './MedicationsAndNotes.styled';

export const MedicationsAndNotes = ({
  medicationInstruction,
  hideAcknowledgment,
  patient
}: {
  medicationInstruction: DailyInstruction;
  hideAcknowledgment: boolean;
  patient: Patient;
}) => {
  const { isMobile } = useBreakpoints();
  const [moreQuestionsModalVisible, setMoreQuestionsModalVisible] = useState(false);
  const [acknowledgeDailyInstruction] = useMutation(ACKNOWLEDGE_DAILY_INSTRUCTION, {
    onError: error => BugTracker.notify(error, 'Acknowledge Instruction')
  });

  const onAcknowledge = (acknowledged: boolean) =>
    acknowledgeDailyInstruction({
      variables: {
        dailyInstructionId: Number(medicationInstruction.id),
        acknowledged
      }
    });

  // acknowledged = true -> patient acknowledged instructions
  // acknowledged = false -> patient used 'I have questions'
  // acknowledged = null -> patient didn't acknowledge nor used 'I have questions'
  const isAcknowledged = medicationInstruction.acknowledged != null;

  const dateFormatted = formatToWeekdayMonthDay(
    momentWithParsedTimeZone(medicationInstruction.date || '')
  );

  return (
    <MedicationsAndNotesContainer>
      {medicationInstruction.drugs.map(drug => {
        const drugVideo = MEDICATIONS[drug.name];

        return (
          <DrugContainer key={drug.id}>
            <DrugRecurrenceContainer>
              <DrugIconContainer>
                <Pills type="regular" />
              </DrugIconContainer>
              <div>
                <div>
                  <Text fontStyle="bold">{drug.name}</Text>
                </div>
                <div>
                  <Text size="xs">
                    {medicationInstruction.started
                      ? `${drug.amount} taken ${formatDrugRecurrence(drug)}`
                      : `Your treatment cycle will start on ${
                          medicationInstruction.startDate
                            ? formatDate(medicationInstruction.startDate)
                            : ''
                        }. Medication instructions will be available then.`}
                  </Text>
                </div>
              </div>
            </DrugRecurrenceContainer>
            {drugVideo && (
              <div>
                <Button
                  label={!isMobile ? 'Watch video' : ''}
                  uppercase
                  category="secondary"
                  onClick={() => window.open(modifyUrlForEnvironment(drugVideo.url), '_blank')}
                  leftIcon={<Film type="solid" />}
                />
              </div>
            )}
          </DrugContainer>
        );
      })}
      {medicationInstruction.instructions && medicationInstruction.started && (
        <NotesContainer>
          <Text fontStyle="medium" size="sm">
            Notes
          </Text>
          <Note>
            <TextWithShowMore
              text={medicationInstruction.instructions}
              popupTitle={`Notes for ${dateFormatted}`}
            />
          </Note>
        </NotesContainer>
      )}
      {!hideAcknowledgment && (
        <AcknowledgeContainer>
          {!isAcknowledged && (
            <InformationalTextContainer>
              <Text size="sm" fontStyle="regular">
                Your personalized medication dosing is being coordinated with your physician.
                Medication instructions will typically appear here by <strong>6pm</strong> daily.
                <br />
              </Text>
              <Text fontStyle="bold" color={theme.colors.semantic.darkRed}>
                If it is after 6:00pm and you do not see your instructions, please call our office
                to speak to the on call nursing team.
              </Text>
            </InformationalTextContainer>
          )}
          {!isAcknowledged && (
            <AcknowledgeButtonsContainer>
              <Button
                label="I HAVE MORE QUESTIONS"
                uppercase
                category="secondary"
                fullWidth
                onClick={() => {
                  analytics.track(analytics.EVENTS.MEDICATIONS_MORE_QUESTIONS);
                  setMoreQuestionsModalVisible(true);
                  onAcknowledge(false);
                }}
              />
              <Button
                label="GOT IT!"
                uppercase
                category="primary-dark"
                fullWidth
                onClick={() => {
                  analytics.track(analytics.EVENTS.MEDICATIONS_GOT_IT);
                  onAcknowledge(true);
                }}
              />
            </AcknowledgeButtonsContainer>
          )}
          <Text fontStyle="bold" color={themes.colors.semantic.darkRed}>
            {isAcknowledged
              ? 'Thank you for acknowledging these instructions.'
              : 'Please confirm that you understand these medication instructions.'}
          </Text>
        </AcknowledgeContainer>
      )}
      <IHaveMoreQuestionsModal
        visible={moreQuestionsModalVisible}
        patient={patient}
        onDismiss={() => setMoreQuestionsModalVisible(false)}
      />
    </MedicationsAndNotesContainer>
  );
};

const formatDrugRecurrence = (drug: Drug) => {
  let recurrence = drug.dailyRecurrence ? `${drug.dailyRecurrence} times` : '';
  if (drug.dailyRecurrence <= 1) {
    recurrence = 'once';
  } else if (drug.dailyRecurrence === 2) {
    recurrence = 'twice';
  }

  return recurrence;
};
