import { RenderableItem } from '../types';

export const postEmbryoTransferInstructions: RenderableItem[] = [
  {
    name: 'Activity',
    body: [
      {
        type: 'copy',
        copy:
          'Moderate to light activity. We do not recommend strenuous exercise until pregnancy test.'
      },
      {
        type: 'copy',
        copy: 'No intercourse for the next 2 weeks.'
      }
    ]
  },
  {
    name: 'Diet',
    body: [
      {
        type: 'copy',
        copy: 'As tolerated, but avoid alcohol.'
      },
      {
        type: 'copy',
        copy:
          'Stay well hydrated, with electrolytes, fruit juices and V-8. Drink about 64 - 80 oz a day.'
      }
    ]
  },

  {
    name: 'Medications',
    body: [
      {
        type: 'strong',
        copy: 'PROGESTERONE SUPPLEMENTS'
      },
      {
        type: 'copy',
        copy: 'Continue your progesterone supplementation as prescribed.'
      },
      {
        type: 'strong',
        copy: 'VITAMIN'
      },
      {
        type: 'copy',
        copy: 'Continue your multivitamin with folic acid or prenatal vitamin every day.'
      }
    ]
  },
  {
    name: 'Other Instructions',
    body: [
      {
        type: 'copy',
        copy: 'Continue any other medications that your physician prescribed to you.'
      },
      {
        type: 'strong',
        copy: 'Notify our office if any of the following symptoms occur:'
      },
      {
        type: 'list',
        items: [
          'Fever > 100.5° F',
          'Heavy bleeding',
          'Continuous pain',
          'Nausea, vomiting, diarrhea and / or decreased urination',
          'Any other abnormal symptoms'
        ]
      }
    ]
  }
];
