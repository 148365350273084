import React from 'react';

import Button from 'components/Button/NewButton';
import { ConsentHTMLError } from 'screens/InformedConsent/Components/ConsentHTML/ConsentHTMLError';
import { Body, Divider } from 'screens/InformedConsent/Steps/YourConsent/YourConsent.styled';

import {
  SignedConsentContainer,
  SignedConsentMeta,
  SignedConsentButtonWrapper,
  SignedConsentDecisions,
  SignedConsentDecisionsTitle,
  SignedConsentDecisionName,
  SignedConsentDecisionDesc,
  SignedConsentDecisionWrapper
} from './SignedConsent.styled';
import { SignedConsentProps } from './SignedConsent.types';
import { getPatientName, SignedConsentHeader } from './SignedConsentHeader/SignedConsentHeader';

export const SignedConsent: React.FC<SignedConsentProps> = ({
  consentRawHtml,
  completedAt,
  consentDecisions,
  onBack,
  patient,
  patientNameFromConsent
}: SignedConsentProps) => {
  if (!consentRawHtml) return <ConsentHTMLError />;

  const ConsentHTML = () => <Body dangerouslySetInnerHTML={{ __html: consentRawHtml }} />;

  return (
    <SignedConsentContainer>
      <SignedConsentHeader
        completedAt={completedAt}
        patient={patient}
        patientNameFromConsent={patientNameFromConsent}
      />
      <Divider />
      <ConsentHTML />
      {consentDecisions?.length > 0 && (
        <>
          <Divider />
          <SignedConsentDecisions>
            <SignedConsentDecisionsTitle>Decisions </SignedConsentDecisionsTitle>
            <SignedConsentMeta>
              {'Electronically Signed On: '}
              {completedAt}
            </SignedConsentMeta>
            <SignedConsentMeta>
              {'Electronically Signed By: '}
              {getPatientName(patient, patientNameFromConsent)}
            </SignedConsentMeta>
            {consentDecisions.map((decision, index) => {
              return (
                <SignedConsentDecisionWrapper key={index}>
                  <div>
                    <SignedConsentDecisionName>
                      {decision.consentDecision.name}:
                    </SignedConsentDecisionName>{' '}
                    <SignedConsentDecisionDesc>
                      {decision.consentDecision.description}
                    </SignedConsentDecisionDesc>
                  </div>
                  <div>
                    <SignedConsentDecisionName>Patient&apos;s choice:</SignedConsentDecisionName>{' '}
                    <SignedConsentDecisionDesc>
                      {decision.consentOption.name}
                    </SignedConsentDecisionDesc>
                    <br />
                    <SignedConsentDecisionDesc>
                      {decision.consentOption.description}
                    </SignedConsentDecisionDesc>
                  </div>
                </SignedConsentDecisionWrapper>
              );
            })}
          </SignedConsentDecisions>
        </>
      )}
      <Divider />
      <SignedConsentButtonWrapper>
        <Button text={'Back to documents'} onClick={onBack} />
      </SignedConsentButtonWrapper>
    </SignedConsentContainer>
  );
};
