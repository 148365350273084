import React from 'react';

import {
  MyJourneyStep,
  fertilityAssessmentBase,
  resultsBase,
  preparingForCycleBase,
  duringCycleBase,
  triggerBase,
  retrievalBase,
  embryoTransferBase,
  biopsyBase,
  pregnancyTestBase,
  followUpGenericBase,
  followUpCallBase,
  followUpEmbryoBase,
  iuiProcedureBase,
  startProgesteroneSupplementsBase,
  Journey
} from 'kb-shared';

import { ReactComponent as RetrievalSvg } from '../../../assets/icons/retrieval.svg';
import { ReactComponent as TriggerSvg } from '../../../assets/icons/trigger.svg';
import { Biopsy } from '../components/Biopsy';
import FollowUpBiopsy from '../components/FollowUpBiopsy';
import {
  EmbryoTransferDetail,
  FollowUpCallDetail,
  FollowUpGenericDetail,
  FollowUpEmbryoDetail,
  FullFertilityAssessmentDetail,
  PregnancyTestDetail,
  RetrievalDetail,
  ReviewResultsDetail,
  TriggerInjectionDetail,
  StartProgesteroneSupplementsWithEmbryoTransfer,
  StartProgesteroneSupplementsWithBiopsy
} from '../details';
import IUIProcedureDetail from '../details/IUIProcedure';
import RetrievalDetailForDonorIVF from '../details/RetrievalForDonorIVF';
import ViewDailyInstructionsDetail from '../details/ViewDailyInstructionsDetail';
import PreparingForCycleList from '../substeps/PreparingForCycleList';
import PreparingForCycleListForDonorIVF from '../substeps/PreparingForCycleListForDonorIVF';

const fertilityAssessment: MyJourneyStep = {
  ...fertilityAssessmentBase,
  detail: <FullFertilityAssessmentDetail />
};

const results: MyJourneyStep = {
  ...resultsBase,
  detail: <ReviewResultsDetail />
};

const preparingForCycle: MyJourneyStep = {
  ...preparingForCycleBase,
  substeps: <PreparingForCycleList />
};

const preparingForCycleForDonorIVF: MyJourneyStep = {
  ...preparingForCycleBase,
  substeps: <PreparingForCycleListForDonorIVF />
};

const duringCycle: MyJourneyStep = {
  ...duringCycleBase,
  detail: <ViewDailyInstructionsDetail />
};

const trigger: MyJourneyStep = {
  ...triggerBase,
  detail: <TriggerInjectionDetail />,
  icon: <TriggerSvg />
};

const retrieval: MyJourneyStep = {
  ...retrievalBase,
  detail: <RetrievalDetail />,
  icon: <RetrievalSvg />
};

const retrievalForDonors: MyJourneyStep = {
  ...retrievalBase,
  detail: <RetrievalDetailForDonorIVF />,
  icon: <RetrievalSvg />
};

const followUpCall: MyJourneyStep = {
  ...followUpCallBase,
  detail: <FollowUpCallDetail />
};

const embryoTransfer: MyJourneyStep = {
  ...embryoTransferBase,
  detail: <EmbryoTransferDetail />
};

const biopsy: MyJourneyStep = {
  ...biopsyBase,
  detail: <Biopsy />
};

const followUpBiopsy: MyJourneyStep = {
  ...followUpGenericBase,
  detail: <FollowUpBiopsy />
};

const pregnancyTest: MyJourneyStep = {
  ...pregnancyTestBase,
  detail: <PregnancyTestDetail />
};

const followUpGeneric: MyJourneyStep = {
  ...followUpGenericBase,
  detail: <FollowUpGenericDetail />
};

const followUpEmbryo: MyJourneyStep = {
  ...followUpEmbryoBase,
  detail: <FollowUpEmbryoDetail />
};

const iuiProcedure: MyJourneyStep = {
  ...iuiProcedureBase,
  detail: <IUIProcedureDetail />
};

const startProgesteroneSupplements: MyJourneyStep = {
  ...startProgesteroneSupplementsBase,
  detail: <StartProgesteroneSupplementsWithEmbryoTransfer />
};

const startProgesteroneSupplementsWithBiopsy: MyJourneyStep = {
  ...startProgesteroneSupplementsBase,
  detail: <StartProgesteroneSupplementsWithBiopsy />
};

export const eggFreezing: Journey = {
  name: 'Egg Freezing',
  steps: [
    fertilityAssessment,
    results,
    preparingForCycle,
    duringCycle,
    trigger,
    retrieval,
    followUpCall
  ]
};

export const donorIVF: Journey = {
  name: 'Donor IVF',
  steps: [
    fertilityAssessment,
    results,
    preparingForCycleForDonorIVF,
    duringCycle,
    trigger,
    retrievalForDonors
  ]
};

export const ivf: Journey = {
  name: 'IVF',
  steps: [
    fertilityAssessment,
    results,
    preparingForCycle,
    duringCycle,
    trigger,
    retrieval,
    embryoTransfer,
    pregnancyTest,
    followUpGeneric
  ]
};

export const iui: Journey = {
  name: 'IUI',
  steps: [
    fertilityAssessment,
    results,
    preparingForCycle,
    duringCycle,
    trigger,
    iuiProcedure,
    pregnancyTest,
    followUpGeneric
  ]
};

export const ti: Journey = {
  name: 'TI',
  steps: [
    fertilityAssessment,
    results,
    preparingForCycle,
    duringCycle,
    trigger,
    pregnancyTest,
    followUpGeneric
  ]
};

export const embryoBanking: Journey = {
  name: 'Embryo Banking',
  steps: [
    fertilityAssessment,
    results,
    preparingForCycle,
    duringCycle,
    trigger,
    retrieval,
    followUpEmbryo
  ]
};

export const fet: Journey = {
  name: 'FET',
  steps: [
    fertilityAssessment,
    results,
    preparingForCycle,
    duringCycle,
    startProgesteroneSupplements,
    embryoTransfer,
    pregnancyTest,
    followUpGeneric
  ]
};

export const mockFactory: Journey = {
  name: 'MOCK CYCLE',
  steps: [
    fertilityAssessment,
    results,
    preparingForCycle,
    duringCycle,
    startProgesteroneSupplementsWithBiopsy,
    biopsy,
    followUpBiopsy // TODO: change of text
  ]
};
