import React, { useEffect, useState } from 'react';

import { List } from 'screens/Documents/components/List/List';

import { Item } from '../List/List.types';
import { Container } from './PatientSignedDocuments.styled';
import { PatientSignedDocumentsProps } from './PatientSignedDocuments.types';
import { parseDate } from './PatientSignedDocuments.utils';

export const PatientSignedDocuments: React.FC<PatientSignedDocumentsProps> = ({
  items,
  onView,
  patient
}) => {
  const [documents, setDocuments] = useState<Item[]>([]);

  useEffect(() => {
    const newItems = items.map(item => {
      const { id, fileName, date, presignedUrl } = item;
      const formattedDate = date ? parseDate(item, patient) : null;

      return {
        id: parseInt(id),
        name: fileName,
        date: formattedDate,
        onView: presignedUrl ? () => window.open(presignedUrl, '_blank') : () => onView(item)
      } as Item;
    });

    setDocuments(newItems);
  }, [items, onView, patient]);

  return (
    <Container>
      <List title="Uploads" items={documents} />
    </Container>
  );
};
