import styled from 'styled-components';

export const IdentityConfirmationStep = styled.div`
  margin: 0 0 24px 0;
`;

export const Description = styled.div`
  font-size: 14px;
`;

export const TitleContainer = styled.div`
  margin-top: 16px;
  margin-bottom: 4px;
  flex-direction: row;
`;

export const Title = styled.span`
  font-size: 18px;
  color: ${({ theme }) => theme.colors.neutral.primaryNavy};

  font-weight: 600;
`;
