import React, { Component } from 'react';

import { themes } from 'kb-shared';
import styled from 'styled-components';

import { DailyResult } from '../Results.types';
import { Title, Container, Sections, SectionContainer, AnimatedContentContainer } from '../styled';
import Graph from './Graph';
import GraphDetails from './GraphDetails';
import Interperatation from './Interperatation';

const GraphSectionContainer = styled(SectionContainer)`
  padding: 0px;
  margin: 0px;

  @media screen and (min-width: ${themes.breakpoints.tabletPortrait}) {
    padding: 0px;
    margin: 0px;
  }
`;

const GraphContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const GraphTopBottomContainer = styled.div`
  flex: 1;
`;

const TitleContainer = styled.div`
  padding: 10px 20px 0px 0px;
`;

type Props = {
  dailyResults: Array<DailyResult>;
};

type State = {
  currentDay: number;
};

export default class Stimulation extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    const stepData = this.resultIndiciesWithLabs(props.dailyResults);
    this.state = {
      currentDay: stepData[stepData.length - 1] || 0
    };
  }

  // Generate an array of all the step indices that have a recorded result.
  // We pass this to the slider so that we only "snap" to days with results.
  resultIndiciesWithLabs(dailyResults: DailyResult[]): number[] {
    return dailyResults.reduce((newSteps: Array<number>, result: DailyResult, index: number) => {
      if (result.labs.length > 0) {
        newSteps.push(index);
      }
      return newSteps;
    }, []);
  }

  render() {
    const resultIndicesWithLabs = this.resultIndiciesWithLabs(this.props.dailyResults);
    return (
      <Container>
        <TitleContainer>
          <Title>Stimulation Progress for Days 1-{this.props.dailyResults.length - 1}</Title>
        </TitleContainer>
        <Sections>
          <GraphSectionContainer>
            <GraphContainer>
              <GraphTopBottomContainer>
                <Graph
                  results={this.props.dailyResults}
                  currentResultIndex={this.state.currentDay}
                />
              </GraphTopBottomContainer>
              <GraphTopBottomContainer>
                <GraphDetails
                  results={this.props.dailyResults}
                  currentDayIndex={this.state.currentDay}
                  resultIndicesWithLabs={resultIndicesWithLabs}
                  onValueChange={(value: number) => {
                    this.setState({
                      currentDay: value
                    });
                  }}
                />
              </GraphTopBottomContainer>
            </GraphContainer>
          </GraphSectionContainer>
        </Sections>
        <AnimatedContentContainer pose={'visible'}>
          <Interperatation />
        </AnimatedContentContainer>
      </Container>
    );
  }
}
