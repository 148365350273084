import { PatientCareLocation } from './CareTeam.types';

export const hasRequiredLocationData = (locationData?: PatientCareLocation) => {
  if (!locationData) {
    return false;
  }

  const { name, phone, address, city, state, zipcode } = locationData;

  return Boolean(name || phone || address || city || state || zipcode);
};

export const getFormattedAddress = (
  street?: string,
  city?: string,
  state?: string,
  zipCode?: string
) => {
  const addressElements = [];

  if (street) {
    addressElements.push(street);
  }

  if (city) {
    addressElements.push(city);
  }

  if (state && zipCode) {
    addressElements.push(`${state} ${zipCode}`);
  } else if (street) {
    addressElements.push(state);
  } else if (zipCode) {
    addressElements.push(zipCode);
  }

  return addressElements.join(', ');
};
