import { ApolloError, gql, useMutation } from '@apollo/client';

import { COB_PRIORITY_CODE } from 'components/InsuranceForm/InsuranceForm.utils';
import { BugTracker } from 'kb-shared/utilities/bugTracker';
import { STATUS } from 'screens/InterstitialModal/InterstitialModal.graphql';
import { graphQLErrorMessage } from 'utilities/errors';
import { showErrorToast } from 'utilities/notificationUtils';

const CREATE_INSURANCE_PROVIDER = gql`
  mutation createInsuranceProvider(
    $payerCompanyId: String
    $payerCompanyNameByPatient: String!
    $memberId: String!
    $cobPriorityCode: String!
    $relationshipToInsured: String!
    $groupNumber: String!
    $insuredFirstName: String
    $insuredLastName: String
    $insuredMemberId: String
    $insuredGender: String
    $insuredAddress: String
    $insuredCity: String
    $insuredState: String
    $insuredZipcode: String
    $insuredPhoneNumber: String
    $insuredDob: String
    $insuranceCardFrontFileId: Int
    $insuranceCardBackFileId: Int
  ) {
    createInsuranceProvider(
      payerCompanyId: $payerCompanyId
      payerCompanyNameByPatient: $payerCompanyNameByPatient
      memberId: $memberId
      cobPriorityCode: $cobPriorityCode
      relationshipToInsured: $relationshipToInsured
      groupNumber: $groupNumber
      insuredFirstName: $insuredFirstName
      insuredLastName: $insuredLastName
      insuredMemberId: $insuredMemberId
      insuredGender: $insuredGender
      insuredAddress: $insuredAddress
      insuredCity: $insuredCity
      insuredState: $insuredState
      insuredZipcode: $insuredZipcode
      insuredPhoneNumber: $insuredPhoneNumber
      insuredDob: $insuredDob
      insuranceCardFrontFileId: $insuranceCardFrontFileId
      insuranceCardBackFileId: $insuranceCardBackFileId
    ) {
      insuranceProvider {
        id
      }
    }
  }
`;

export interface InsuranceFormSelfData {
  payerCompanyNameByPatient: string;
  payerCompanyId: string;
  memberId: string;
  cobPriorityCode: COB_PRIORITY_CODE;
  relationshipToInsured: string;
  groupNumber: string;
  insuranceCardFrontFileId: number | null;
  insuranceCardBackFileId: number | null;
}

export interface InsuranceFormNonSelfData {
  payerCompanyId: string;
  payerCompanyNameByPatient: string;
  memberId: string;
  cobPriorityCode: COB_PRIORITY_CODE;
  relationshipToInsured: string;
  groupNumber: string;
  insuredFirstName: string;
  insuredLastName: string;
  insuredMemberId: string;
  insuredGender: string;
  insuredAddress: string;
  insuredCity: string;
  insuredState: string;
  insuredZipcode: string;
  insuredPhoneNumber: string;
  insuredDob: string;
}

export type InsuranceFormData = InsuranceFormSelfData | InsuranceFormNonSelfData;

export const useSubmitInsuranceData = () => {
  const [createSubmitInsuranceDataMutation, { data, loading, error }] = useMutation<
    unknown,
    InsuranceFormData
  >(CREATE_INSURANCE_PROVIDER);

  const submitInsuranceData = async (insuranceData: InsuranceFormData) => {
    try {
      const result = await createSubmitInsuranceDataMutation({
        variables: insuranceData,
        refetchQueries: [{ query: STATUS }]
      });
      return result as Response;
    } catch (error) {
      showErrorToast(graphQLErrorMessage(error as ApolloError));
      BugTracker.notify(error as Error, 'Failed to submit insurance data from interstitial modal.');
    }
  };

  return {
    submitInsuranceData,
    data,
    submitting: loading,
    error
  };
};
