import styled from 'styled-components';

export const FootnotesStar = styled.span`
  vertical-align: top;
`;

export const Paragraph = styled.p`
  ${({ theme }) => theme.typos.footnotes};
  color: ${({ theme }) => theme.colors.neutral.primaryNavy};
  padding-bottom: 30px;

  @media ${({ theme }) => theme.queries.tablet} {
    position: absolute;
    bottom: 0;

    span {
      display: inline-block;
    }
  }
`;
