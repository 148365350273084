import { Heading } from 'components/v2/Typography';
import styled from 'styled-components';

export const StepHeadingContainer = styled.div`
  padding: 20px 0px;
`;

export const StepHeading = styled(Heading).attrs({ tag: 'h2', styledAs: 'h2' })`
  justify-content: center;
`;
