import { disabledState, readOnlyState, filledOutlineShadow } from 'components/v2/common.styled';
import styled, { css } from 'styled-components';

import { Status } from '../../types';
import InputWithMask from '../InputWithMask/InputWithMask';
import { inputBaseCSS, inputStatusStyle } from '../TextInput/TextInput.styled';

const inputStyle = css<{ status: Status; isFilled: boolean }>`
  ${inputBaseCSS}

  ${({ isFilled }) =>
    isFilled &&
    css`
      box-shadow: ${filledOutlineShadow};
    `}

  ${({ status }) => {
    if (status === 'disabled') {
      return `
        background: ${disabledState.backgroundColor};
        ::placeholder {
          color: ${disabledState.placeholderColor};
        }
      `;
    }
    if (status === 'readOnly') {
      return `
        background: ${readOnlyState.backgroundColor};
        ::placeholder {
          color: ${readOnlyState.placeholderColor};
        }
      `;
    }
  }}
`;

export const MaskedInput = styled(InputWithMask)<{ status: Status; isFilled: boolean }>`
  ${inputStyle}
  ${({ status }) => inputStatusStyle[status]}
`;

export const RegularInput = styled.input<{ status: Status; isFilled: boolean }>`
  ${inputStyle}
  ${({ status }) => inputStatusStyle[status]}
`;
