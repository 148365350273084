import React from 'react';

import { DefaultFileIcon, PDFIcon, Preview } from './FilePreviewItem.styled';

export const FilePreviewItem = ({ attachment, preview }: { attachment: File; preview: string }) => {
  const IMAGES = ['image/jpeg', 'image/png'];
  const DOCUMENT = ['application/pdf'];

  if (IMAGES.includes(attachment.type)) {
    return <Preview src={preview} alt="Image Preview" />;
  } else if (DOCUMENT.includes(attachment.type)) {
    return <PDFIcon />;
  } else {
    return <DefaultFileIcon />;
  }
};
