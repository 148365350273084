import moment from 'moment-timezone';
import React, { useState } from 'react';

import { Answer } from 'kb-shared';
import { getNow, inPast } from 'kb-shared/utilities/momentHelpers';

import AnimatedWrapper from '../../AnimatedWrapper';
import FormButton from '../../FormButton';
import Text from '../../Primitives/FormText';
import TextInput from '../../Primitives/TextInput';
import { Container, InputContainer } from './DateForm.styled';

export const DateForm = ({
  onContinue,
  onSave,
  answer,
  title,
  isRequired,
  skipLinkComponent
}: {
  onContinue?: () => void;
  onSave: (response: string) => void;
  answer: Answer | null;
  title: string;
  isRequired: boolean | null;
  skipLinkComponent: JSX.Element | null;
}) => {
  const [date, setDate] = useState(answer?.data || '');

  const renderContinueButton = () => {
    if (!onContinue) return null;

    return (
      <AnimatedWrapper isHidden={!date}>
        <FormButton text={'Continue'} onClick={() => onContinue?.()} />
      </AnimatedWrapper>
    );
  };

  return (
    <Container>
      <Text isRequired={isRequired}>{title}</Text>
      <InputContainer>
        <TextInput
          value={date}
          placeholder={'(mm/dd/yyyy)'}
          type="date"
          max={getNow('YYYY-MM-DD')}
          onChange={e => {
            const date = e.target.value;
            const safariFormatDate = moment(date, 'MM/DD/YYYY');
            const momentDate = safariFormatDate.isValid() ? safariFormatDate : moment(date);
            if (momentDate.isValid() && inPast(momentDate)) {
              setDate(date);
            } else {
              setDate('');
            }
            onSave(date);
          }}
        />
      </InputContainer>
      {skipLinkComponent}
      {renderContinueButton()}
    </Container>
  );
};
