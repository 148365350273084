import React from 'react';

import SubstepWrapper from '../../SubstepWrapper';
import { Description, Title } from './styled-components';

const ClinicVisit = () => (
  <>
    <Title>Postpartum In Clinic Visit</Title>
    <Description>
      This visit will include a physical exam, contraception counseling (if needed), breast health &
      much more! You are welcome to bring your baby with you.
    </Description>
  </>
);

const NutritionAppointment = () => (
  <>
    <Title>Nutrition Appointment</Title>
    <Description>
      This is your last individualized nutrition appointment and will focus on tips to help with
      breastfeeding and starting the journey of caring for your postpartum body. Good diet and
      nutrition are essential for a healthy mom & baby. Our team will help you schedule additional
      visits if needed.
    </Description>
  </>
);

const MedicalFitnessAppointment = () => (
  <>
    <Title>Medical Fitness Appointment</Title>
    <Description>
      This is your last individualized physical fitness visit. These visits can also help with
      resuming exercise as well as postpartum kegels exercises to help your pelvic muscles recover.
      Exercise is a vital part of a new mom's physical & mental health. Our team will help you
      schedule additional visits if needed.
    </Description>
  </>
);

const VirtualMentalHealthSession = () => (
  <>
    <Title>Virtual Mental Health Session</Title>
    <Description>
      This visit focuses on your emotional health, adjustment to motherhood and your daily plans now
      that the initial new baby phase is ending. If you plan to return to work, this visit can also
      help with making the transition.
    </Description>
  </>
);

const SUBSTEPS = [
  { component: <ClinicVisit /> },
  { component: <NutritionAppointment /> },
  { component: <MedicalFitnessAppointment /> },
  { component: <VirtualMentalHealthSession /> }
];

const ObPostPartumWeek6Substeps = () => <SubstepWrapper substeps={SUBSTEPS} />;

export default ObPostPartumWeek6Substeps;
