import { gql, useQuery } from '@apollo/client';

import { Membership } from 'kb-shared';
import { BugTracker } from 'kb-shared/utilities/bugTracker';

import { UrlParamValue } from '../types/types';
import { isFalsyNonNumber } from '../utils/validation';

const QUERY_MEMBERSHIP = gql`
  query membership($membershipId: Int!) {
    membership(membershipId: $membershipId) {
      description
      id
      name
      employer
      rateCents
    }
  }
`;

export const useMembership = (membershipId?: UrlParamValue<number>) => {
  const { data, loading, error } = useQuery<{
    membership: Membership;
  }>(QUERY_MEMBERSHIP, {
    skip: isFalsyNonNumber(membershipId),
    variables: {
      membershipId: membershipId
    },
    onError: error => {
      BugTracker.notify(error, 'Failed to load membership');
    }
  });

  return { data: data?.membership, loading, error };
};
