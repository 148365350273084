import React from 'react';

import { CheckMark } from 'assets/icons/Icons';

import { Container } from './BubbleStep.styled';
import { Props } from './BubbleStep.types';

const BubbleStep = (props: Props) => {
  const { title, showSpacer, status } = props;
  return (
    <Container>
      <div className="circleAndSpacerHolder">
        {showSpacer && <div className={`spacer ${status.toLowerCase()}`} />}
        <div className={`circle ${status.toLowerCase()}`}>
          {status === 'Completed' && <CheckMark type="solid" />}
        </div>
      </div>
      <div className="titleHolder">
        <div className={`title ${status.toLowerCase()}`}>{title}</div>
      </div>
    </Container>
  );
};

export default BubbleStep;
