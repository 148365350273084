import { ResultCategoryType, ResultType } from '../Results.types';

export type Category = {
  results: Array<ResultType>;
  categoryName: string;
  categorySlug: string;
};

export type Results = {
  [category: string]: Category;
};

export type Props = {
  results: Array<ResultType>;
};
export type State = {
  results: Results;
  resultsColumnMobileLayout: ResultColumns;
  resultsPopoverVisible: boolean;
  modalType: ResultCategoryType | null;
  contactModal: boolean;
};

export enum ResultColumns {
  YOUR_RESULT = 'your_results',
  NORMAL_RANGE = 'normal_range'
}
