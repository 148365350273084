import React, { useEffect, useState } from 'react';

import { FormField } from 'kb-shared';

import AnimatedWrapper from '../../AnimatedWrapper';
import FormButton from '../../FormButton';
import Text from '../../Primitives/FormText';
import TextInput from '../../Primitives/TextInput';
import {
  Container,
  ContinueButtonWrapper,
  GroupWrapper,
  OptionsContainer
} from './FieldGroupStringForm.styled';
import { FieldGroupStringFormProps, StateAnswers } from './FieldGroupStringForm.types';

export const FieldGroupStringForm = ({
  title,
  fieldGroup,
  savedAnswers,
  onContinue,
  onSubmit
}: FieldGroupStringFormProps) => {
  const [answers, setAnswers] = useState<StateAnswers>({});

  useEffect(() => {
    const existingAnswers: StateAnswers = {};
    Object.keys(savedAnswers).forEach(key => (existingAnswers[key] = savedAnswers[key].data));
    setAnswers(existingAnswers);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const renderContinueButton = () => {
    return (
      <ContinueButtonWrapper>
        <AnimatedWrapper isHidden={false}>
          <FormButton
            text={'Continue'}
            onClick={() => {
              // Save all text fields first
              Object.keys(answers).forEach((id: string) => {
                onSubmit(answers[id], id);
              });
              onContinue();
            }}
          />
        </AnimatedWrapper>
      </ContinueButtonWrapper>
    );
  };

  const renderOptions = () => {
    return [...fieldGroup.formFields]
      .sort((field1: FormField, field2: FormField) => {
        return field1.position - field2.position;
      })
      .map((field: FormField) => {
        return (
          <GroupWrapper key={field.id}>
            <TextInput
              value={answers[field.id]}
              placeholder={field.title}
              onChange={e => {
                const response = e.target.value;
                const newAnswers = { ...answers };
                newAnswers[field.id] = response;
                setAnswers(newAnswers);
              }}
              onBlur={e => onSubmit(e.target.value, field.id)}
            />
          </GroupWrapper>
        );
      });
  };

  return (
    <Container>
      <Text>{title}</Text>
      <OptionsContainer>{renderOptions()}</OptionsContainer>
      {renderContinueButton()}
    </Container>
  );
};
