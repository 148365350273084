import React from 'react';

import StepDetail, { Description, Subheader } from '../StepDetail';

const ObDeliveryDetails = () => (
  <StepDetail>
    <Subheader>Labor & Delivery</Subheader>
    <Description>
      Your Doula & Labor Coach will be with you every step of the way. Our team of doctors, nurses,
      and specialists will be with you throughout your labor and delivery. Our goal is to provide
      you with clinical excellence, compassionate care, and the highest level of care with you and
      your baby's health as our primary focus.
    </Description>
  </StepDetail>
);

export default ObDeliveryDetails;
