import React from 'react';

import { AppointmentProduct, AppointmentType } from 'kb-shared';

import { DisclaimerText } from '../ConfirmOrder.styled';

type Props = {
  hasEmployer: boolean;
  product: AppointmentProduct<AppointmentType> | null;
};

export const CoachingBenefitsDisclaimer = ({ hasEmployer, product }: Props) => {
  const categoryName = product?.type === 'appointment' ? product?.data?.category?.name : undefined;

  if (hasEmployer || categoryName !== 'Coaching') return null;

  return (
    <DisclaimerText>
      If you have employer benefits with us, please call us at 855-KND-BODY or email
      navigator@kindbody.com to book your appointment and apply any applicable benefit discounts.
    </DisclaimerText>
  );
};
