import React, { useEffect, useState } from 'react';

import { CaretDown, CaretUp, Eye, Trash } from 'assets/icons/Icons';
import { Button } from 'components/v2/Buttons/Button';
import { ConfirmModal } from 'components/v2/ConfirmModal/ConfirmModal';
import { Heading, Text } from 'components/v2/Typography';

import { HeaderItem } from './HeaderItem';
import {
  Actions,
  Container,
  EmptyState,
  Header,
  Info,
  MobileRow,
  MobileTitle,
  Row,
  SortInfo,
  Title
} from './List.styled';
import { Columns, Item, Props } from './List.types';
import { generateUniqueNames, sortByDate, sortByText } from './List.utils';

const DEFAULT_NUMBER_OF_COLUMNS = 3;

export const List = ({ title, items }: Props) => {
  const [orientation, setOrientation] = useState<'asc' | 'desc'>('asc');
  const [sortedByColumn, setSortedByColumn] = useState<Columns>('date');
  const [sortedItems, setSortedItems] = useState(items);
  const [fileToDelete, setFileToDelete] = useState<Item>();

  const onSort = (columnName: Columns, orientation: 'asc' | 'desc') => {
    let newSortedItems = null;

    if (columnName === 'name' || columnName === 'description' || columnName === 'type') {
      newSortedItems = sortByText(columnName, sortedItems, orientation);
    }

    if (columnName === 'date') {
      newSortedItems = sortByDate(sortedItems, orientation);
    }

    newSortedItems && setSortedItems(newSortedItems);
    setSortedByColumn(columnName);
    setOrientation(orientation === 'asc' ? 'desc' : 'asc');
  };

  useEffect(() => {
    const newSortedItems = sortByDate(items, 'asc');
    const itemsWithUniqueNames = generateUniqueNames(newSortedItems);
    setSortedItems(itemsWithUniqueNames);
  }, [items]);

  const hasDescription = items.some(item => item.description);
  const hasType = items.some(item => item.type);

  const getNumberOfColumns = () => {
    let numberOfColumns = DEFAULT_NUMBER_OF_COLUMNS;

    if (hasDescription) numberOfColumns++;
    if (hasType) numberOfColumns++;

    return numberOfColumns;
  };

  if (items.length === 0) {
    return (
      <EmptyState>
        <Text tag="p" size="md" fontStyle="semibold">
          You haven't uploaded any documents yet
        </Text>
        <Text tag="p" size="xs">
          Once you upload a document, it will appear in the list view below.
        </Text>
      </EmptyState>
    );
  }

  return (
    <Container>
      {title && (
        <Title>
          <Heading tag="div" noMargin styledAs="h4">
            {title}
          </Heading>
        </Title>
      )}

      <MobileTitle>
        <Text transform="uppercase" size="xs">
          {title}
        </Text>
        <SortInfo>
          <Text tag="span" size="sm">
            Sort By:
          </Text>
          <div onClick={() => onSort('date', orientation)}>
            <Text tag="strong" size="sm">
              Date
              {orientation === 'asc' ? <CaretDown type="solid" /> : <CaretUp type="solid" />}
            </Text>
          </div>
        </SortInfo>
      </MobileTitle>

      <Header $columns={getNumberOfColumns()}>
        <HeaderItem
          columnName="name"
          label="Name"
          active={sortedByColumn === 'name'}
          onSort={onSort}
        />

        <HeaderItem
          columnName="date"
          label="Date"
          active={sortedByColumn === 'date'}
          onSort={onSort}
        />

        {hasDescription && (
          <HeaderItem
            columnName="description"
            label="Description"
            active={sortedByColumn === 'description'}
            onSort={onSort}
          />
        )}

        {hasType && (
          <HeaderItem
            columnName="type"
            label="Type"
            active={sortedByColumn === 'type'}
            onSort={onSort}
          />
        )}
      </Header>

      <div>
        {sortedItems.map(item => (
          <Row key={item.id} $columns={getNumberOfColumns()}>
            <Heading tag="div" styledAs="h4" noMargin>
              {item.name}
            </Heading>
            <Text size="lg" fontStyle="regular">
              {item.date}
            </Text>
            {hasDescription && (
              <Text size="lg" multilineEllipses={2} fontStyle="regular">
                {item.description}
              </Text>
            )}
            {hasType && (
              <Text size="lg" fontStyle="regular">
                {item.type}
              </Text>
            )}
            <Actions>
              {item.onDelete && (
                <>
                  <Button
                    rightIcon={<Trash type="solid" />}
                    size="md"
                    category="danger"
                    onClick={() => setFileToDelete(item)}
                  />
                </>
              )}
              {item.onView && (
                <Button
                  rightIcon={<Eye type="solid" />}
                  category="secondary"
                  size="md"
                  onClick={item.onView}
                />
              )}
            </Actions>
          </Row>
        ))}

        {sortedItems.map(item => (
          <MobileRow key={`${item.id}-mobile`}>
            <Info>
              <Text size="md" fontStyle="bold" multilineEllipses={3}>
                {item.name}
              </Text>
              <Text size="xs">{item.date}</Text>
            </Info>
            <Actions>
              {item.onDelete && (
                <Button
                  rightIcon={<Trash type="solid" />}
                  size="md"
                  category="danger"
                  onClick={() => setFileToDelete(item)}
                />
              )}
              {item.onView && (
                <Button
                  rightIcon={<Eye type="solid" />}
                  size="md"
                  category="secondary"
                  onClick={item.onView}
                />
              )}
            </Actions>
          </MobileRow>
        ))}
      </div>

      {fileToDelete?.onDelete && (
        <ConfirmModal
          open={!!fileToDelete}
          title="Confirm File Deletion"
          description={`Are you sure you want to delete your file ${fileToDelete.name}?`}
          confirmButtonLabel="Confirm Delete"
          onCancel={() => setFileToDelete(undefined)}
          onConfirm={fileToDelete.onDelete}
        />
      )}
    </Container>
  );
};
