import { themes } from 'kb-shared';
import styled from 'styled-components';

import { imageSize } from './common';
const { colors } = themes;

interface SizeProps {
  size?: number;
}

export const HiddenInput = styled.input`
  display: none;
`;

export const FileUpload = styled.input`
  cursor: pointer;
  position: absolute;
  opacity: 0;
  ${(props: SizeProps) => imageSize(props.size)}
  background: white;
`;

export const FileUploadContainer = styled.label`
  cursor: pointer;
  width: ${(props: SizeProps) => props.size}px;
`;

const LabelBase = styled.p`
  font-size: 20px;
  max-width: 160px;
  padding-top: 10px;
  text-align: center;
`;

export const ModalLabel = styled(LabelBase)`
  color: ${colors.neutral.primaryNavy};
`;

export const ErrorLabel = styled(LabelBase)`
  font-size: 16px;
  color: ${colors.semantic.darkRed};
`;
