import { Text } from 'components/v2/Typography';
import { ResponsiveContainer } from 'screens/styled_common';
import styled from 'styled-components';

export const Header = styled.div<{ $tabSelected: boolean }>`
  display: ${({ $tabSelected }) => ($tabSelected ? 'none' : 'block')};

  @media (min-width: ${({ theme }) => theme.breakpoints.smallDesktop}) {
    display: block;
  }
`;

export const Content = styled.div`
  margin: 2em 0;
`;

export const Description = styled(Text)`
  margin-top: 0.5em;
  max-width: 720px;
`;

export const Container = styled(ResponsiveContainer)`
  padding: 0 24px;
`;
