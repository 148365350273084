import React from 'react';

import { PersonBreastfeeding } from 'assets/icons/Icons';
import { Heading, Text } from 'components/v2/Typography';
import { Patient } from 'kb-shared';

import {
  IconContainer,
  IllustratedBackgroundWidget,
  TextRow,
  WidgetTitleContainer
} from '../../Widgets/Widgets.styled';
import { EmployerWidgetActions } from '../components/EmployerWidgetActions';
import { widgetActions } from './EmbryoIncWidget.utils';

export const EmbryoIncWidget = ({ patient }: { patient: Patient }) => {
  if (patient.patientMembership?.membership?.employerId !== 86) return null;

  return (
    <IllustratedBackgroundWidget $variant={8}>
      <IconContainer $iconWidth={42} $iconHeight={48}>
        <PersonBreastfeeding type="solid" />
      </IconContainer>
      <WidgetTitleContainer>
        <Heading tag="div" styledAs="h2">
          Baby Prep Program
        </Heading>
      </WidgetTitleContainer>
      <TextRow>
        <Text fontStyle="semibold">
          The Baby Prep Program is provided by Kindbody and connects parents to be with educational
          resources and trimester-specific support.
        </Text>
      </TextRow>
      <TextRow>
        <Text>
          Pregnancy is a time of excitement and anxiety and your Kindbody team is so excited to be a
          part of this journey with you!
          <br />
          Our goal is to provide you information that is evidence-based and supported by the
          American College of Obstetrics and Gynecology.
          <br />
          Click on the links below to get started!
        </Text>
      </TextRow>
      <EmployerWidgetActions actions={widgetActions} />
    </IllustratedBackgroundWidget>
  );
};
