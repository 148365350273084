import React, { useCallback, useEffect, useState } from 'react';

import styled from 'styled-components';
import { formatDate } from 'utilities/formatDate';

import { FoundFile } from '../Documents.types';
import { List } from './List/List';

type Props = {
  items: FoundFile[];
};

const DocumentsList = ({ items }: Props) => {
  const [documents, setDocuments] = useState([]);

  const openRawHtml = (title: string, data: string) => {
    const win = window.open('', title, '');

    win?.document.write(data);
  };

  const onView = useCallback(item => {
    const { type, fileName, path } = item;

    if (type === 'HTML') {
      return openRawHtml(fileName, path);
    } else {
      return window.open(path, '_blank');
    }
  }, []);

  useEffect(() => {
    const newItems = items.map(item => {
      //@ts-ignore
      const { path, fileName, type, date } = item;
      const formattedDate = date ? formatDate(date, 'MM/DD/YYYY') : null;

      return {
        id: path,
        name: fileName,
        type,
        date: formattedDate,
        onView: () => onView(item)
      };
    });

    // @ts-ignore
    setDocuments(newItems);
  }, [items, onView]);

  return (
    <Container>
      <List title="Uploads" items={documents} />
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  margin-bottom: 28px;
  display: grid;
  grid-gap: 2px;
`;

export default DocumentsList;
