import React from 'react';
import { Link } from 'react-router-dom';

import { utils } from 'kb-shared';

import {
  TitleText,
  FinishedSubtitle,
  LinkStyled,
  Button,
  ContainerStyled
} from './AcknowledgeStatus.styles';

const SuccessfullyAcknowledged = () => (
  <ContainerStyled>
    <TitleText>You&apos;re all set!</TitleText>
    <FinishedSubtitle>
      If you have any questions about what you’ve just reviewed, contact your care team at
      <LinkStyled href={`mailto:${utils.KBContacts.clinical}?subject=Consent Forms`}>
        {' '}
        {utils.KBContacts.clinical}{' '}
      </LinkStyled>
      or <span style={{ fontWeight: 'bold' }}>1-855-KND-BODY</span>
    </FinishedSubtitle>
    <Link to="/dashboard">
      <Button text="Back to dashboard" />
    </Link>
  </ContainerStyled>
);

export default SuccessfullyAcknowledged;
