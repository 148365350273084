import React, { useState, useRef, useEffect } from 'react';

import { ReactComponent as ArrowDown } from 'assets/icons/arrow_down.svg';
import { ReactComponent as ArrowUp } from 'assets/icons/arrow_up.svg';
import { Option as SelectInputOption } from 'components/v2/Inputs/SelectInput';

import { getInputStates } from '../../utils';
import { DropdownMenu, Option as DropdownOption } from '../DropdownMenu';
import {
  SelectedOption,
  ArrowIcon,
  SelectedOptionLabel,
  Container,
  Placeholder
} from './CountrySelect.styled';
import { Props } from './CountrySelect.types';
import { escapeRegex } from './CountrySelect.utils';

export const CountrySelect = <T extends SelectInputOption>({
  id,
  labelId,
  dropdownId,
  status = 'default',
  searchPlaceholder,
  selected,
  placeholder,
  onChange,
  onFocus,
  onBlur,
  unstyled = false,
  options,
  selectedOptionFormatter
}: Props<T>): JSX.Element => {
  const [isDropDownOpened, setIsDropDownOpened] = useState<boolean>(false);
  const dropDownMenuRef = useRef<HTMLDivElement>(null);
  const labelRef = useRef<HTMLDivElement>(null);
  const { isDisabled } = getInputStates(status);
  const isFilled = Boolean(selected);

  const clickOutside = (e: Event) => {
    if (
      !dropDownMenuRef.current?.contains(e.target as Node) &&
      !labelRef.current?.contains(e.target as Node)
    ) {
      setIsDropDownOpened(false);
      onBlur?.();
    }
  };

  const toggle = () => {
    if (isDisabled) return;

    const newIsDropDownOpened = !isDropDownOpened;
    setIsDropDownOpened(newIsDropDownOpened);

    if (newIsDropDownOpened) {
      onFocus?.();
    } else {
      onBlur?.();
    }
  };

  const search = (options: Array<DropdownOption>, term: string): Array<DropdownOption> => {
    if (!term) {
      return options;
    }

    const regex = new RegExp(`.*${escapeRegex(term)}.*`, 'i');
    const filteredByTerm = options.filter(option => regex.test(option.label));

    return filteredByTerm;
  };

  const select = (option: T) => {
    setIsDropDownOpened(false);
    const selectedOption = selectedOptionFormatter ? selectedOptionFormatter(option) : option;
    onChange(selectedOption);
  };

  useEffect(() => {
    document.addEventListener('mousedown', clickOutside);

    return () => document.removeEventListener('mousedown', clickOutside);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDropDownOpened]);

  return (
    <Container unstyled={unstyled}>
      <SelectedOption
        id={id}
        onClick={toggle}
        isDropDownOpened={isDropDownOpened}
        role="combobox"
        aria-labelledby={labelId}
        aria-expanded={isDropDownOpened}
        aria-controls={dropdownId}
        aria-autocomplete="none"
        status={status}
        unstyled={unstyled}
        isFilled={isFilled}
      >
        <SelectedOptionLabel ref={labelRef} title={selected?.label}>
          {selected?.label ? (
            selected?.label
          ) : (
            <Placeholder status={status}>{placeholder}</Placeholder>
          )}
        </SelectedOptionLabel>
        <ArrowIcon>{isDropDownOpened ? <ArrowUp /> : <ArrowDown />}</ArrowIcon>
      </SelectedOption>

      {isDropDownOpened && (
        <DropdownMenu
          id={dropdownId}
          ref={dropDownMenuRef}
          options={options}
          selected={selected}
          onSelect={option => select(option as T)}
          searchPlaceholder={searchPlaceholder}
          searchFn={search}
          searchable
        />
      )}
    </Container>
  );
};
