import React from 'react';
import { useHistory } from 'react-router-dom';

import { LinkButton } from 'components/v2/Buttons/LinkButton/LinkButton';
import { Heading } from 'components/v2/Typography';
import { Text } from 'components/v2/Typography/Text';

import { TotalBalance } from '../TotalBalance/TotalBalance';
import { InvoicesPageHeaderContainer, TotalBalanceContainer } from './InvoicesPageHeader.styled';

export function InvoicesPageHeader({ totalBalance }: { totalBalance: number }) {
  const history = useHistory();

  return (
    <InvoicesPageHeaderContainer>
      <div>
        <Heading tag="h1">Your Statements & Payments</Heading>
        <div>
          <Text tag="p">
            Review statements and payments for Kindbody services. Make payments for individual dates
            of service or a cycle package. Your “Total Balance” listed at the top of the page
            reflects the amount you owe for all services previously rendered (excluding cycle
            packages).
          </Text>
          <br />
          <Text tag="p">
            Please be aware that there may be a delay of up to 48 hours before your payment is
            reflected in the portal, depending on the method used to complete the transaction. If
            you have any questions or concerns regarding your billing, please do not hesitate to
            reach out by{' '}
            <LinkButton
              text="sending us a message"
              onClick={() => history.push('/messages/billing')}
            />
            . We are here to assist you and ensure your concerns are addressed promptly.
          </Text>
        </div>
      </div>

      <TotalBalanceContainer>
        <TotalBalance value={totalBalance} />
      </TotalBalanceContainer>
    </InvoicesPageHeaderContainer>
  );
}
