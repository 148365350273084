import { gql, useQuery } from '@apollo/client';
import React from 'react';

import { OrderMedications, Injections, Todos, LogYourPeriod } from '.';
import SubstepWrapper from '../SubstepWrapper';

const JOURNEY_TYPE_QUERY = gql`
  query preparingForCycle {
    journeyType

    cyclePlan {
      id
      hasMedications
      __typename
    }
  }
`;

const PREPARE_SUBSTEPS = [
  { component: <OrderMedications key="medications" /> },
  { component: <Todos key="todos" /> },
  { component: <LogYourPeriod key="log-period" /> },
  { component: <Injections key="injections" /> }
];

const PreparingForCycleList = () => {
  const { data } = useQuery(JOURNEY_TYPE_QUERY);
  const journeyType = data?.journeyType;
  const hasMedications = data?.cyclePlan?.hasMedications === 'true';

  if (!journeyType) return null;

  const [medications, todos, logPeriod, injections] = PREPARE_SUBSTEPS;

  let substeps = [];

  if (hasMedications) {
    substeps.push(medications);
  }

  substeps = substeps.concat([todos, logPeriod]);

  if (hasMedications) {
    substeps.push(injections);
  }

  return <SubstepWrapper substeps={substeps} />;
};

export default PreparingForCycleList;
