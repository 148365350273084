import React from 'react';

import { BookingQuizCategory } from '../BookingQuiz.types';
import { CATEGORY_ICONS } from '../BookingQuizIcons';
import { LargeOption, LargeOptionIcon, MediumText } from './Option.styled';

interface Props {
  text: string;
  category?: BookingQuizCategory;
}

export const Option = ({ text, category }: Props) => {
  return category ? (
    <LargeOption>
      {CATEGORY_ICONS[category] && <LargeOptionIcon>{CATEGORY_ICONS[category]}</LargeOptionIcon>}
      <span>{text}</span>
    </LargeOption>
  ) : (
    <MediumText>{text}</MediumText>
  );
};
