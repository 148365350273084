import { ResponsiveContainer } from 'screens/styled_common';
import styled from 'styled-components';

export const ResultContainer = styled(ResponsiveContainer)`
  padding: 24px;

  @media ${({ theme }) => theme.queries.minDesktop} {
    padding: 0 24px;
  }

  @media ${({ theme }) => theme.queries.minLargeDesktop} {
    padding: 0;
  }
`;

export const ResultDesktopTitleContainer = styled.div`
  margin-bottom: 8px;
`;
