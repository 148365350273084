export type Crop = {
  x: number;
  y: number;
  width: number;
  height: number;
  aspect: number;
  unit: 'px' | '%';
};

const PROFILE_PICTURE_DIMENSIONS = { width: 620, height: 620 };

// https://github.com/DominicTobias/react-image-crop#what-about-showing-the-crop-on-the-client
export const getCroppedImg = (blob: string, crop: Crop, fileName: string): Promise<File> => {
  return new Promise((resolve, reject) => {
    const canvas = document.createElement('canvas');
    const image = new Image();
    image.src = blob;

    image.onload = () => {
      const sx = (crop.x / 100) * image.width;
      const sy = (crop.y / 100) * image.height;
      const sWidth = (crop.width / 100) * image.width;
      const sHeight = (crop.height / 100) * image.height;

      canvas.width = PROFILE_PICTURE_DIMENSIONS.width;
      canvas.height = PROFILE_PICTURE_DIMENSIONS.height;

      const ctx = canvas.getContext('2d');

      if (!ctx) {
        reject('Could not create canvas');
        return;
      }

      const dx = 0;
      const dy = 0;

      ctx.drawImage(
        image,
        sx,
        sy,
        sWidth,
        sHeight,
        dx,
        dy,
        PROFILE_PICTURE_DIMENSIONS.width,
        PROFILE_PICTURE_DIMENSIONS.height
      );

      canvas.toBlob(
        blob => {
          if (!blob) {
            reject('Could not create file blob');
            return;
          }

          resolve(new File([blob], fileName, { type: 'image/jpeg' }));
        },
        'image/jpeg',
        0.95
      );
    };

    image.onerror = () => reject(new Error('Could not load image when trying to make crop'));
  });
};
