import React, { FormEvent, useState } from 'react';

import { CATEGORY } from 'api/pf2/files';
import { Button } from 'components/v2/Buttons/Button';
import { SelectField, TextField } from 'components/v2/Form';
import { Option } from 'components/v2/Inputs/SelectInput';
import { Row } from 'screens/Book/steps/Insurance/Insurance.styled';
import { TwoColumnContent } from 'screens/styled_common';

import { InsuranceCardForm } from '../InsuranceCardForm/InsuranceCardForm';
import { ButtonWrap } from './InsuranceForm.styled';
import { RelationshipOption } from './InsuranceForm.types';
import { RELATIONSHIP_TO_INSURED } from './InsuranceForm.utils';
import { InsuranceFormSelfState, Props } from './InsuranceFormSelf.types';

export const InsuranceFormSelf = ({
  insuranceProvider,
  disableSubmit,
  payerCompanies,
  relationshipToInsured,
  backgroundColor,
  onSave,
  setRelationshipToInsured,
  onCancel
}: Props) => {
  const [formState, setFormState] = useState<InsuranceFormSelfState>({
    groupNumber: insuranceProvider?.groupNumber ?? '',
    payerCompany: {
      id: insuranceProvider?.payerCompanyId ?? '',
      name: insuranceProvider?.derivedPayerCompanyName ?? ''
    },
    memberId: insuranceProvider?.memberId ?? '',
    insuranceCardFrontFileId: insuranceProvider?.insuranceCardFrontId || null,
    insuranceCardBackFileId: insuranceProvider?.insuranceCardBackId || null
  });

  const { groupNumber, payerCompany, memberId } = formState;
  const isFormValid = Boolean(
    payerCompany.id &&
      memberId &&
      formState.insuranceCardBackFileId &&
      formState.insuranceCardFrontFileId
  );

  const updateFormState = (key: string, event: FormEvent<HTMLInputElement>) => {
    const value = event?.currentTarget?.value;
    setFormState({ ...formState, [key]: value });
  };

  const updateInsuranceCardPhotos = (category: CATEGORY, id: number, _url: string) => {
    if (category === CATEGORY.INSURANCE_CARD_FRONT) {
      setFormState({ ...formState, insuranceCardFrontFileId: id });
    } else if (category === CATEGORY.INSURANCE_CARD_BACK) {
      setFormState({ ...formState, insuranceCardBackFileId: id });
    }
  };

  const updatePayerCompany = (option: Option) => {
    setFormState({
      ...formState,
      payerCompany: {
        id: option.value,
        name: option.label
      }
    });
  };

  const updateMemberId = (memberId: string) => {
    const scrubbedMemberId = memberId.replace(/\s+/g, '');
    setFormState({ ...formState, memberId: scrubbedMemberId });
  };

  const payerCompaniesOptions = payerCompanies.map(payer => ({
    label: payer.name,
    value: payer.id.toString()
  }));

  const hasPayerCompanyInfo = Boolean(formState.payerCompany.name && formState.payerCompany.id);
  const selectedPayerCompanyOption = hasPayerCompanyInfo
    ? {
        label: formState.payerCompany.name,
        value: formState.payerCompany.id
      }
    : undefined;

  return (
    <>
      <TwoColumnContent>
        <SelectField
          id="input-relationship-to-insured"
          label="RELATIONSHIP TO INSURED"
          selected={relationshipToInsured}
          placeholder={'Chose your relationship to the pearson insured'}
          onChange={item => setRelationshipToInsured(item as RelationshipOption)}
          options={RELATIONSHIP_TO_INSURED}
          required
          labelBg={backgroundColor}
        />

        <TextField
          type="text"
          id="input-group-number"
          placeholder="Enter group number"
          label="Group number (optional)"
          value={groupNumber}
          onChange={event => updateFormState('groupNumber', event)}
          labelBg={backgroundColor}
        />
      </TwoColumnContent>

      <Row>
        <SelectField
          id="insurance-form-self-payer"
          searchable
          label="Payer"
          placeholder="Enter payer"
          searchPlaceholder="Search for a payer"
          required
          options={payerCompaniesOptions}
          selected={selectedPayerCompanyOption}
          onChange={updatePayerCompany}
          description="(If you can’t find your payer, type in and select “Unknown Payer” instead)"
          wrapSelectedOptionText
          labelBg={backgroundColor}
        />
        <TextField
          type="text"
          id="input-member-id"
          placeholder="Enter member ID"
          label="Member ID"
          required
          value={memberId}
          onChange={event => updateMemberId(event.currentTarget.value)}
          labelBg={backgroundColor}
        />
      </Row>

      <InsuranceCardForm
        onImageUrlChange={updateInsuranceCardPhotos}
        insuranceProvider={insuranceProvider}
      />

      <ButtonWrap>
        {onCancel && <Button label="Cancel" size="md" category="secondary" onClick={onCancel} />}
        <Button
          label="Save"
          category="primary"
          fullWidth={!onCancel}
          size="md"
          onClick={() => onSave(formState)}
          isDisabled={!isFormValid || disableSubmit}
        />
      </ButtonWrap>
    </>
  );
};
