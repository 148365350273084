import React from 'react';

const ArrowLeft = () => {
  return (
    <svg
      width="8px"
      height="13px"
      viewBox="0 0 8 13"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>Arrow pointed to the left</title>
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g transform="translate(-115.000000, -552.000000)" stroke="#343754">
          <g transform="translate(97.000000, 534.000000)">
            <polyline
              transform="translate(22.000000, 24.000000) scale(-1, 1) translate(-22.000000, -24.000000) "
              points="19 18 25 24.1570625 19 30"
            />
          </g>
        </g>
      </g>
    </svg>
  );
};

export default ArrowLeft;
