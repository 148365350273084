import { useMutation } from '@apollo/client';
import React, { useState } from 'react';

import Modal from 'components/Modal';
import { Button } from 'components/v2/Buttons/Button';
import { Text } from 'components/v2/Typography';
import { DISABLE_MFA } from 'kb-shared/graphql/mutations';
import { BugTracker } from 'kb-shared/utilities/bugTracker';
import { showErrorToast, showSuccessToast } from 'utilities/notificationUtils';

import { DisableMfaModalProps, DisableMfaResponse } from './DisableMfaModal.types';
import { ButtonContainer, Container } from './EnableMfaModal.styled';

export const DisableMfaModal = (props: DisableMfaModalProps) => {
  const { open, onClose } = props;
  const [submitting, setSubmitting] = useState(false);
  const [disableMfa] = useMutation<DisableMfaResponse>(DISABLE_MFA);

  const onActionButtonClick = () => {
    setSubmitting(true);
    onDisableMfaRequested();
  };

  const onDisableMfaRequested = () => {
    disableMfa()
      .then(r => {
        if (r.data?.disableMfa?.succeeded) {
          showSuccessToast('2FA with SMS is disabled!');
          onClose();
        } else {
          BugTracker.notify(r.data?.disableMfa?.errorCode, 'DisableMfa');
          showErrorToast('Failed to disable 2FA with SMS. Please try again.');
        }
      })
      .catch(error => {
        BugTracker.notify(error, 'DisableMfa');
        showErrorToast('Failed to disable 2FA with SMS. Please try again.');
      })
      .finally(() => setSubmitting(false));
  };

  return (
    <Modal onRequestClose={onClose} open={open}>
      <Container>
        <div>
          <Text size="md" tag="p">
            Are you sure that you want to disable 2FA with SMS?
          </Text>
        </div>
        <ButtonContainer>
          <Button label={'No'} category="primary-dark" size="lg" onClick={onClose} />
          <Button
            label={'Yes, disable 2FA'}
            category="primary"
            isDisabled={submitting}
            size="lg"
            onClick={onActionButtonClick}
          />
        </ButtonContainer>
      </Container>
    </Modal>
  );
};
