import styled from 'styled-components';

export const TextWrap = styled.p`
  margin-bottom: 24px;
`;

export const Header = styled.header<{ tabSelected: boolean }>`
  display: ${({ tabSelected }) => (tabSelected ? 'none' : 'block')};

  @media (min-width: ${({ theme }) => theme.breakpoints.smallDesktop}) {
    display: block;
  }
`;

export const HeadingButtonWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
`;
