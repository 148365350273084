import { useLazyQuery } from '@apollo/client';
import React, { useEffect, useState } from 'react';

import { useEditInsuranceData } from 'hooks/useEditInsuranceData';
import {
  InsuranceFormNonSelfData,
  InsuranceFormSelfData,
  useSubmitInsuranceData
} from 'hooks/useSubmitInsuranceData';
import { BugTracker } from 'kb-shared/utilities/bugTracker';
import { InsuranceOutlineIndent } from 'screens/Book/steps/Insurance/Insurance.styled';
import { showErrorToast } from 'utilities/notificationUtils';
import { scrubPhoneNumber } from 'utilities/phoneNumber';

import { PAYER_QUERY } from './InsuranceForm.graphql';
import { Props, RelationshipOption, RelationshipToInsured } from './InsuranceForm.types';
import { COB_PRIORITY_CODE, getDefaultRelationShipToInsured } from './InsuranceForm.utils';
import { InsuranceFormNonSelf } from './InsuranceFormNonSelf';
import { InsuranceFormNonSelfState } from './InsuranceFormNonSelf.types';
import { InsuranceFormNotLoadedModal } from './InsuranceFormNotLoadedModal';
import { InsuranceFormSelf } from './InsuranceFormSelf';
import { InsuranceFormSelfState } from './InsuranceFormSelf.types';

export const InsuranceForm = ({
  insuranceProvider,
  cobPriorityCode = COB_PRIORITY_CODE.PRIMARY,
  backgroundColor,
  onSuccess,
  onCancel
}: Props) => {
  const [relationshipToInsured, setRelationshipToInsured] = useState<RelationshipOption>(
    getDefaultRelationShipToInsured(
      insuranceProvider?.relationshipToInsured as RelationshipToInsured
    )
  );
  const { submitInsuranceData, submitting } = useSubmitInsuranceData();
  const { editInsuranceData, loading: editing } = useEditInsuranceData();
  const [fetchPayerCompanies, { data, loading, error }] = useLazyQuery<any>(PAYER_QUERY);

  useEffect(() => {
    fetchPayerCompanies();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (error) {
      BugTracker.notify(error, 'Failed to fetch payer companies');
    }
  }, [error]);

  const onSaveSelfInsured = (formData: InsuranceFormSelfState) => {
    const newCobPriorityCode = insuranceProvider
      ? insuranceProvider.cobPriorityCode
      : cobPriorityCode;

    const insuranceData: InsuranceFormSelfData = {
      groupNumber: formData.groupNumber,
      payerCompanyNameByPatient: formData.payerCompany.name,
      payerCompanyId: formData.payerCompany.id,
      memberId: formData.memberId,
      cobPriorityCode: newCobPriorityCode,
      relationshipToInsured: relationshipToInsured.value,
      insuranceCardFrontFileId: formData.insuranceCardFrontFileId,
      insuranceCardBackFileId: formData.insuranceCardBackFileId
    };

    if (insuranceProvider?.id) {
      editInsuranceData(insuranceProvider.id, insuranceData)
        .then(() => onSuccess?.())
        .catch(() => {
          showErrorToast('Failed to edit insurance data. Please try again.');
        });
      return;
    } else {
      submitInsuranceData(insuranceData)
        .then(() => onSuccess?.())
        .catch(() => {
          showErrorToast('Failed to submit insurance data. Please try again.');
        });
    }
  };

  const onSaveNonSelfInsured = (formData: InsuranceFormNonSelfState) => {
    if (!formData.sexAtBirth?.value) {
      if (insuranceProvider?.id) {
        formData.sexAtBirth = { label: 'unknown', value: 'unknown' };
      } else return;
    }

    const newCobPriorityCode = insuranceProvider
      ? insuranceProvider.cobPriorityCode
      : cobPriorityCode;

    const insuranceData: InsuranceFormNonSelfData = {
      relationshipToInsured: relationshipToInsured.value,
      payerCompanyId: formData.payerCompany.id,
      payerCompanyNameByPatient: formData.payerCompany.name,
      memberId: formData.memberId,
      cobPriorityCode: newCobPriorityCode,
      groupNumber: formData.groupNumber,
      insuredFirstName: formData.firstName,
      insuredLastName: formData.lastName,
      insuredAddress: formData.address,
      insuredCity: formData.city,
      insuredDob: formData.dob,
      insuredGender: formData.sexAtBirth.value,
      insuredMemberId: formData.memberId,
      insuredPhoneNumber: scrubPhoneNumber(formData.phoneNumber),
      insuredState: formData.state,
      insuredZipcode: formData.zipCode
    };

    if (insuranceProvider?.id) {
      editInsuranceData(insuranceProvider.id, insuranceData)
        .then(() => onSuccess?.())
        .catch(() => {
          showErrorToast('Failed to edit insurance data. Please try again.');
        });
      return;
    } else {
      submitInsuranceData(insuranceData)
        .then(() => onSuccess?.())
        .catch(() => {
          showErrorToast('Failed to submit insurance data. Please try again.');
        });
    }
  };

  if (loading) return null;

  if (error || !data?.payerCompanies) {
    return (
      <InsuranceFormNotLoadedModal
        modalVisible={true}
        onLoadDataAgain={() => fetchPayerCompanies()}
      />
    );
  }

  return (
    <InsuranceOutlineIndent>
      {relationshipToInsured.value === 'self' && (
        <InsuranceFormSelf
          insuranceProvider={insuranceProvider}
          onSave={onSaveSelfInsured}
          disableSubmit={submitting || editing}
          payerCompanies={data.payerCompanies}
          relationshipToInsured={relationshipToInsured}
          setRelationshipToInsured={setRelationshipToInsured}
          onCancel={onCancel}
          backgroundColor={backgroundColor}
        />
      )}

      {relationshipToInsured.value !== 'self' && (
        <InsuranceFormNonSelf
          insuranceProvider={insuranceProvider}
          onSave={onSaveNonSelfInsured}
          disableSubmit={submitting || editing}
          payerCompanies={data.payerCompanies}
          relationshipToInsured={relationshipToInsured}
          setRelationshipToInsured={setRelationshipToInsured}
          onCancel={onCancel}
          backgroundColor={backgroundColor}
        />
      )}
    </InsuranceOutlineIndent>
  );
};
