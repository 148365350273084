import React, { useEffect, useState } from 'react';

import { CaretDown, CaretUp, Sort } from 'assets/icons/Icons';
import { Text } from 'components/v2/Typography';

import { Container, Icon } from './HeaderItem.styled';
import { Columns } from './List.types';

interface Props {
  columnName: Columns;
  label: string;
  active: boolean;
  onSort: (columnName: Columns, orientation: 'asc' | 'desc') => void;
}

export const HeaderItem = ({ columnName, label, active, onSort }: Props) => {
  const [orientation, setOrientation] = useState<'asc' | 'desc'>('asc');

  const onClick = () => {
    const newOrientation = orientation === 'asc' ? 'desc' : 'asc';

    setOrientation(newOrientation);
    onSort(columnName, newOrientation);
  };

  useEffect(() => {
    if (!active) setOrientation('asc');
  }, [active]);

  const renderIcon = () => {
    if (!active) {
      return <Sort type="solid" />;
    }

    return (
      <Icon>{orientation === 'asc' ? <CaretUp type="solid" /> : <CaretDown type="solid" />}</Icon>
    );
  };

  return (
    <Container onClick={onClick}>
      <Text tag={active ? 'strong' : 'span'} size="sm">
        {label}
      </Text>
      {renderIcon()}
    </Container>
  );
};
