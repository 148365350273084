import React from 'react';

import { Heading } from 'components/v2/Typography';

import { InfoContainer } from './InfoContainer';
import { BlockText } from './LineItemsTable.styled';

interface Props {
  name: string;
  address: string;
  taxId: string;
  clinicLocation: string;
}

export const PayeeInfo = ({ name, address, taxId, clinicLocation }: Props) => {
  return (
    <InfoContainer>
      <Heading tag="h6" noMargin>
        FROM
      </Heading>
      <Heading tag="h4" noMargin>
        {name}
      </Heading>

      <BlockText>{address}</BlockText>
      {taxId && <BlockText>Tax ID: {taxId}</BlockText>}
      {clinicLocation && <BlockText>Clinic Location: {clinicLocation}</BlockText>}
    </InfoContainer>
  );
};
