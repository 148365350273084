import React from 'react';

import { InsuranceList } from 'components/InsuranceList/InsuranceList';
import { Loader } from 'components/Loader/Loader';
import { Text } from 'components/v2/Typography';
import { useContactInfo } from 'hooks';
import { usePatientInsuranceProviders } from 'hooks/usePatientInsuranceProviders';

import { Container } from '../shared.styled';
import { AdditionalInfo, AdditionalInfoHeader, ContactList } from './Insurance.styled';

export const Insurance = () => {
  const { number, email } = useContactInfo();
  const { data, loading, refetch } = usePatientInsuranceProviders();

  if (!data || loading) return <Loader container />;

  return (
    <Container>
      <InsuranceList
        formStyle="modal"
        insuranceProviders={data.patient.insuranceProviders}
        onAdd={refetch}
        onDelete={refetch}
      />

      <AdditionalInfo>
        <div>
          <AdditionalInfoHeader>Clinic Contact Details</AdditionalInfoHeader>
          <ContactList>
            <li>
              <Text>
                Phone: <a href={`tel:${number}`}>{number}</a>
              </Text>
            </li>
            <li>
              <Text>
                Email: <a href={`mailto:${email}`}>{email}</a>
              </Text>
            </li>
          </ContactList>
        </div>
      </AdditionalInfo>
    </Container>
  );
};
