import theme from 'kb-shared/theme';
import styled from 'styled-components';

import { MenuItemWrapperProps } from './NavigationItems.types';

export const NavItems = styled('div')`
  display: flex;
  flex-direction: column;
`;

export const MenuItemWrapper = styled.div<MenuItemWrapperProps>`
  width: 48px;
  height: 48px;
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;

  svg {
    height: 24px;
    width: 24px;
  }

  svg > path,
  svg > g > path {
    fill: ${({ active }) => (active ? 'white' : theme.colors.neutral.lavender)};
    stroke: ${({ active }) => (active ? 'white' : theme.colors.neutral.lavender)};
  }
`;

export const UnreadBubble = styled.span`
  position: absolute;
  top: -5px;
  right: -5px;
  background: ${({ theme }) => theme.colors.yellow.primary};
  border-radius: 50%;
  padding: 7px;
  line-height: 5px;
  font-size: small;
  color: ${({ theme }) => theme.colors.neutral.primaryNavy};
`;

export const MenuContent = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  flex-direction: column;
`;

export const NavigationItemLabel = styled.div`
  margin-left: 16px;
  font-size: 24px;
  line-height: 32px;
`;

export const NavItemContainer = styled.div`
  margin-bottom: 8px;
`;

export const NavItem = styled.div<MenuItemWrapperProps>`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  background-color: ${({ active }) => (active ? theme.colors.neutral.navy : 'inherit')};
  border-radius: 8px;
  &:hover {
    background-color: ${({ theme }) => theme.colors.neutral.navy};
    text-decoration: underline;
  }
`;
