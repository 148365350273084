import React from 'react';
import { useHistory } from 'react-router-dom';

import { Button } from 'components/v2/Buttons/Button';
import { Text } from 'components/v2/Typography/Text';
import { usePatient } from 'hooks';
import { pageUrl } from 'utilities/pageUrl';

import { CATEGORIES_INFO, CATEGORY_SLUG } from '../../Messages.constants';
import { CategoryIcon } from '../CategoryIcon/CategoryIcon';
import {
  Container,
  Modal,
  Categories,
  Category,
  CategoryName,
  CategoryDescription,
  Icon,
  Bottom
} from './SelectCategoryModal.styled';

export const SelectCategoryModal = ({ open, close }: { open: boolean; close: () => void }) => {
  const history = useHistory();
  const { patient } = usePatient();

  const filteredCategories = patient?.patientMembership
    ? CATEGORIES_INFO.filter(category => category.slug !== CATEGORY_SLUG.BENEFITS)
    : CATEGORIES_INFO.filter(category => category.slug !== CATEGORY_SLUG.EMPLOYER_BENEFIT);

  if (!open) return <></>;

  return (
    <Container>
      <Modal>
        <Text size="md">Select a message category:</Text>

        {filteredCategories.map(category => (
          <Categories key={category.slug}>
            <Category
              data-testid={`category-${category.slug}-item`}
              onClick={() => {
                close();
                const url = pageUrl.messages({ categoryName: category.slug });
                history.push(url);
              }}
            >
              <Icon>
                <CategoryIcon size="md" icon={category.icon} />
              </Icon>
              <CategoryName>{category.title}</CategoryName>
              <CategoryDescription>{category.description}</CategoryDescription>
            </Category>
          </Categories>
        ))}

        <Bottom>
          <Button
            fullWidth
            category="secondary"
            size="md"
            label="Close"
            uppercase
            onClick={close}
          />
        </Bottom>
      </Modal>
    </Container>
  );
};
