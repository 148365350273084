import React from 'react';

import { Eye } from 'assets/icons/Icons';
import { Button } from 'components/v2/Buttons/Button';
import { Heading } from 'components/v2/Typography';
import { Text } from 'components/v2/Typography';
import { useBreakpoints } from 'utilities/useBreakpoints';

import { ResourceItemType } from '../types';
import { ItemContainer, ItemTitle, ItemButtonContainer } from './ListItem.styles';

export const ListItem = ({ title, url }: ResourceItemType) => {
  const { isDesktop } = useBreakpoints();

  return (
    <ItemContainer>
      {isDesktop ? (
        <>
          <ItemTitle>
            <Heading tag="h4">{title}</Heading>
          </ItemTitle>
          <ItemButtonContainer>
            <Button
              category="secondary"
              size="md"
              label="VIEW"
              onClick={() => window.open(url, '_blank')}
            />
          </ItemButtonContainer>
        </>
      ) : (
        <>
          <ItemTitle>
            <Text fontStyle="regular" size="md">
              {title}
            </Text>
          </ItemTitle>
          <ItemButtonContainer>
            <Button
              category="secondary"
              size="md"
              rightIcon={<Eye type="solid" />}
              onClick={() => window.open(url, '_blank')}
            />
          </ItemButtonContainer>
        </>
      )}
    </ItemContainer>
  );
};
