import { gql } from '@apollo/client';

export const PATIENT_MEMBERSHIP = gql`
  query patientMembership {
    patient {
      id
      patientMembership {
        id
        employer
        membershipRules {
          code
          id
          discountId
        }
      }
    }
  }
`;
