import React from 'react';

import styled from 'styled-components';

import { ResourceItemType } from '../types';
import { ListItem } from './ListItem';

const ItemsContainer = styled.ul`
  width: 100%;
  padding: 15px 0 15px 0;
`;

export const ResourcesList = ({ resourcesList }: { resourcesList: ResourceItemType[] }) => {
  return (
    <ItemsContainer>
      {resourcesList.map(({ id, title, url, resourceType, tierForPortal }) => (
        <ListItem
          key={id}
          id={id}
          title={title}
          url={url}
          resourceType={resourceType}
          tierForPortal={tierForPortal}
        />
      ))}
    </ItemsContainer>
  );
};
