import React, { FormEvent, useState } from 'react';

import { Pen, MagnifyingGlass } from 'assets/icons/Icons';
import { TextInput } from 'components/v2/Inputs';

import { SelectCategoryModal } from '../SelectCategoryModal/SelectCategoryModal';
import { Square, SearchBox } from './ConversationListHeader.styled';

export const ConversationListHeader = ({
  searchTerm,
  onSearch
}: {
  searchTerm: string;
  onSearch: (e: FormEvent<HTMLInputElement>) => void;
}) => {
  const [openedModal, setOpenedModal] = useState<boolean>(false);

  return (
    <>
      <div>
        <SearchBox>
          <TextInput
            placeholder="Search messages"
            type="text"
            leftIcon={<MagnifyingGlass type="solid" />}
            fullWidth
            value={searchTerm}
            onChange={onSearch}
          />

          <Square
            data-testid="create-conversation"
            onClick={() => setOpenedModal(true)}
            category="secondary"
            size="lg"
            leftIcon={<Pen type="solid" />}
          />
        </SearchBox>
      </div>

      <SelectCategoryModal open={openedModal} close={() => setOpenedModal(false)} />
    </>
  );
};
