import React, { useState } from 'react';

import { Eye, EyeSlash } from 'assets/icons/Icons';

import { TextField } from '../TextField/TextField';
import { PasswordFieldProps } from './PasswordField.types';

export const PasswordField = ({
  inputId,
  label = '',
  value,
  placeholder,
  onChange,
  status,
  hideDescription,
  hideHelperText
}: PasswordFieldProps) => {
  const [maskedPassword, setMaskedPassword] = useState(true);

  const setPasswordVisibility = () => {
    setMaskedPassword(!maskedPassword);
  };

  return (
    <TextField
      id={inputId}
      label={label}
      value={value}
      placeholder={placeholder}
      onChange={onChange}
      type={maskedPassword ? 'password' : 'text'}
      rightIcon={maskedPassword ? <Eye /> : <EyeSlash />}
      onRightIconClick={setPasswordVisibility}
      status={status}
      hideDescription={hideDescription}
      hideHelperText={hideHelperText}
    />
  );
};
