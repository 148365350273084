import React from 'react';

import { Text } from 'components/v2/Typography';
import theme from 'kb-shared/theme';

export const BulletText = ({ text, error }: { text: string; error: boolean }) => {
  return (
    <Text
      tag="span"
      size="xs"
      fontStyle="lightItalic"
      color={error ? theme.colors.semantic.darkRed : 'auto'}
    >
      {text}
    </Text>
  );
};
