import React, { useEffect } from 'react';

import { SignUpScreenProps } from 'screens/Book/components/SignUp.types';

import { SignUpForm } from './SignUpForm';

export const SignUpScreen = (props: SignUpScreenProps) => {
  const {
    loading,
    signUpUser,
    signUpType,
    isLoggedIn,
    onSuccess,
    userConfirmationState,
    signUpError,
    onError,
    onUserAlreadyExists,
    preselectedLab,
    patient,
    signInLinkIsVisible
  } = props;

  useEffect(() => {
    if (isLoggedIn == null) return;
    if (isLoggedIn) {
      onSuccess();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoggedIn]);

  useEffect(() => {
    if (userConfirmationState == null) return;
    onSuccess(userConfirmationState || undefined);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userConfirmationState]);

  useEffect(() => {
    if (signUpError == null) return;
    switch (signUpError?.type) {
      case 'UserNameExists':
        onError(
          'We are unable to proceed with your request. In case you have an account already, please log in using the same method as your registration or create a new account using a different email.'
        );
        setTimeout(() => {
          onUserAlreadyExists();
        }, 5000);
        break;
      case 'InvalidPhoneNumber':
        onError('We were unable to create your account with the entered phone number.');
        break;
      case 'Unknown':
      default:
        onError('Sorry, we were unable to create your account. Please try again.');
        break;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [signUpError]);

  return (
    <SignUpForm
      patient={patient}
      preselectedLab={preselectedLab}
      onSignUpPatient={signUpUser}
      loading={loading}
      signUpType={signUpType}
      signInLinkIsVisible={signInLinkIsVisible}
    />
  );
};
