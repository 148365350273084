import { ApolloError, useMutation } from '@apollo/client';
import React, { useState } from 'react';

import {
  Appointment,
  AppointmentProduct,
  AppointmentType,
  Clinic,
  graphql,
  isAppointment,
  isAppointmentType,
  RescheduleAppointmentResponseData,
  TimeSlot,
  ValidPromo
} from 'kb-shared';
import { analytics } from 'utilities/analytics';
import { showErrorToast } from 'utilities/notificationUtils';

import { ConfirmDetails } from '../../../../components/ConfirmDetails';
import { PaymentFormWrapper } from '../../../../styles';
import { RESCHEDULE_APPOINTMENT } from '../ConfirmOrder.graphql';
import { generateErrorString } from '../ConfirmOrder.utils';

export const RescheduleAppointmentForm = ({
  product,
  clinic,
  timeSlot
}: {
  product: AppointmentProduct<AppointmentType> | null;
  clinic: Clinic | null;
  timeSlot: TimeSlot | null;
}) => {
  const [loading, setLoading] = useState(false);
  const [paymentTermsChecked, setPaymentTermsChecked] = useState(false);
  const [validPromo, setValidPromo] = useState<ValidPromo | null>(null);

  const [rescheduleAppointment] = useMutation<
    RescheduleAppointmentResponseData,
    { appointmentId: number; newTimeSlotId: number }
  >(RESCHEDULE_APPOINTMENT, {
    refetchQueries: [{ query: graphql.query.PATIENT_APPOINTMENTS }]
  });

  const onError = (err: ApolloError | null) => {
    const errorString = generateErrorString(err);

    analytics.track(analytics.EVENTS.APPOINTMENT_BOOKING_FAILED, { error: errorString });

    setLoading(false);
    showErrorToast(errorString);
  };

  if (!product) return null;
  if (product?.type !== 'reschedule_appointment' || !timeSlot) return null;

  // Grab a reference to the appointment that is being rescheduled
  const appointment: Appointment = product.data;

  const onSubmit = () =>
    rescheduleAppointment({
      variables: {
        appointmentId: parseInt(appointment.id, 10),
        newTimeSlotId: parseInt(timeSlot.id, 10)
      }
    })
      .then(response =>
        //@ts-ignore
        purchaseAppointmentSucceeded(response?.data?.rescheduleAppointment)
      )
      .catch(onError);

  if (!isAppointment(appointment) && !isAppointmentType(appointment)) return null;

  return (
    <PaymentFormWrapper>
      <form
        onSubmit={async e => {
          e.preventDefault();
          setLoading(true);
          onSubmit();
        }}
      >
        <ConfirmDetails
          clinic={clinic}
          appointment={appointment}
          product={product}
          timeSlot={timeSlot}
          loading={loading}
          paymentTermsChecked={paymentTermsChecked}
          validPromo={validPromo}
          onPaymentTermsChecked={checked => setPaymentTermsChecked(checked)}
          onValidPromoChange={validPromo => setValidPromo(validPromo)}
        />
      </form>
    </PaymentFormWrapper>
  );
};
