import { themes } from 'kb-shared';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: column wrap;
  padding: 20px;
  background-color: white;
`;

export const Text = styled.div`
  color: ${themes.colors.neutral.primaryNavy};
  font-size: 20px;
  text-align: center;
  padding: 0px 35px 28px 35px;
`;

export const ButtonContainer = styled.div`
  flex-flow: row;
  & > button {
    margin: 12px;
  }
`;
