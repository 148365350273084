export const bookingDataParams = [
  // data related params
  'appointment_id',
  'appointment_types',
  'membership_id',
  'lab_id',
  'clinic_id',
  'week_string',
  'time_slot_id',
  'partner_clinic_search',
  'reschedule_appointment_id',
  'confirmed_appointment_id',
  'confirmed_membership_id',
  'enterprise_membership',
  'require_unique_id',
  'provider_id',
  'date'
] as const;

export const bookingFormParams = [
  // booking multi step form params
  'target',
  'step'
] as const;

export const bookingParams = [...bookingDataParams, ...bookingFormParams] as const;
