import { Button } from 'components/v2/Buttons/Button';
import { theme } from 'kb-shared';
import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;

  @media screen and (min-width: ${theme.breakpoints.tabletPortrait}) {
    // needs to be at 90% to be aligned with AuthFlowLayout sections
    width: 90%;
  }
`;

export const ActionButton = styled(Button).attrs({ size: 'lg', radius: 'xl' })`
  margin-top: 20px;
  width: 100%;
`;

export const ActionsContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 32px;
`;

export const InsuranceOutlineIndent = styled.div`
  text-align: left;
`;

export const Title = styled.label`
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  text-transform: uppercase;
  color: ${({ theme }) => theme.colors.neutral.navy};
  margin: 0 0 8px 0;
`;

export const Description = styled.label`
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: ${({ theme }) => theme.colors.neutral.navy};
  margin: 0 0 8px 0;
`;

export const Row = styled.div`
  margin-bottom: 16px;
`;
