import styled from 'styled-components';

export const BirthDate = styled.span`
  font-weight: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  color: ${({ theme }) => theme.colors.neutral.navy};
`;

export const Name = styled.span`
  font-weight: 700;
  font-size: 24px;
  ${({ theme }) => theme.colors.neutral.primaryNavy};
  line-height: 32px;
`;

export const ListItemContainer = styled.div`
  // 16 in the end adds 0.16 opacity to the color provided
  box-shadow: 0px 12px 64px -32px ${({ theme }) => theme.colors.neutral.primaryNavy}16;
  padding: 24px 16px;
  margin-bottom: 16px;
  border-radius: 24px;

  @media ${({ theme }) => theme.queries.minTablet} {
    padding: 24px 32px;

    isolation: isolate;
    background: white;

    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    margin-left: 0px;
    margin-right: 0px;
  }
`;

export const Status = styled.div`
  padding: 4px 8px;
  background: ${({ theme }) => theme.colors.blue.lightestBlue};
  border-radius: 4px;
  height: fit-content;
  margin-top: 8px;

  font-size: 18px;
  line-height: 24px;
  /* should be added to list of colors */
  color: ${({ theme }) => theme.colors.blue.darkBlue};

  text-align: center;
  @media ${({ theme }) => theme.queries.minTablet} {
    margin-top: 0px;
  }
`;

export const Email = styled.span`
  display: block;

  font-size: 18px;
  line-height: 24px;
  color: ${({ theme }) => theme.colors.neutral.lightNavy};
`;
