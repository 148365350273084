import { ChevronLeft } from 'assets/icons/Icons';
import styled from 'styled-components';

export const InvoicesPageHeaderContainer = styled.div`
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 1.5em;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tabletLandscape}) {
    flex-direction: column;
  }
`;

export const TotalBalanceContainer = styled.div`
  display: flex;
  padding-left: 1.5em;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tabletLandscape}) {
    padding: 1.5em 0 0 0;
  }
`;

export const GoBackArrow = styled(ChevronLeft).attrs({ type: 'solid' })`
  color: ${({ theme }) => theme.colors.neutral.lighterNavy};
  margin-right: 0.5em;
  font-size: 1.5em;
  line-height: 1.14;
  margin-bottom: 1.5em;
  margin-top: 0.5em;
`;
