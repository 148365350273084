import { gql } from '@apollo/client';

export const CREATE_PARTNER_INVITE = gql`
  mutation CreatePartnerInvite(
    $email: String!
    $birthDate: String!
    $firstName: String!
    $lastName: String!
  ) {
    createPartnerInvite(
      email: $email
      birthDate: $birthDate
      firstName: $firstName
      lastName: $lastName
    ) {
      partnerInvite {
        id
        email
        firstName
        lastName
        birthDate
      }
    }
  }
`;
