import { combineReducers } from 'redux';

import { patient, initialization } from '.';

const reducers = {
  patient: patient.reducer,
  initialization: initialization.reducer
};

const rootReducer = combineReducers(reducers);

export default rootReducer;
