import styled, { css } from 'styled-components';

export const TableComponent = styled.table`
  width: 100%;
  margin-top: 1em;
  border-spacing: 0px;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.tabletLandscape}) {
    th {
      white-space: nowrap;
    }
  }

  td {
    white-space: nowrap;
  }

  td:nth-child(2) {
    width: 99%;
    white-space: normal;
  }
`;

export const TableBody = styled.tbody`
  width: 100%;
  font-size: 16px;
  line-height: 20px;

  background: ${({ theme }) => theme.colors.white};

  tr {
    &:nth-child(even) {
      background-color: ${({ theme }) => theme.colors.neutral.lavenderWhite};
    }
  }
`;

export const TableCell = styled.td<{ $alignRight?: boolean }>`
  padding: 1.5em 1em;
  border: 0.8px solid ${({ theme }) => theme.colors.neutral.lavender};

  ${({ $alignRight }) =>
    $alignRight &&
    css`
      text-align: right;
    `};
`;
