import React from 'react';

import BubbleStep from './BubbleStep';
import { Status } from './BubbleStep.types';
import { Container } from './BubbleTracker.styled';
import { Props } from './BubbleTracker.types';

const BubbleStepTracker = (props: Props) => {
  const { steps, selectedStep } = props;
  const renderedSteps = steps.map((title, index) => {
    const status = determineStatus(index, selectedStep);

    return <BubbleStep key={index} status={status} title={title} showSpacer={index !== 0} />;
  });

  return <Container>{renderedSteps}</Container>;
};

const determineStatus = (stepIndex: number, selectedStepIndex: number): Status => {
  if (stepIndex === selectedStepIndex) return 'InProgress';

  if (stepIndex < selectedStepIndex) return 'Completed';

  return 'Todo';
};

export default BubbleStepTracker;
