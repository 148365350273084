import { useMutation } from '@apollo/client';
import Honeybadger from '@honeybadger-io/js';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { Loader } from 'components/Loader/Loader';
import { Button } from 'components/v2/Buttons/Button';
import { themes } from 'kb-shared';
import { CONFIRM_NEW_PATIENT_EMAIL } from 'kb-shared/graphql/mutations';
import KBContacts from 'kb-shared/utilities/kindbody_contacts';

import { showErrorToast, showSuccessToast } from '../../utilities/notificationUtils';
import {
  ButtonWrapper,
  FormContent,
  FormContainer
} from '../ForgotPassword/ForgotPasswordForm.styled';
import { Bullets, InstructionText, Link } from './ConfirmNewEmail.styled';
import { ConfirmNewEmailVariables, ConfirmNewEmailResponse } from './ConfirmNewEmail.types';

enum STEP {
  ChangingEmail,
  EmailChanged,
  ChangeFailed
}

export const ConfirmNewEmailForm = () => {
  const [activeStep, setActiveStep] = useState(STEP.ChangingEmail);
  const history = useHistory();
  const [confirmNewEmail] = useMutation<ConfirmNewEmailResponse, ConfirmNewEmailVariables>(
    CONFIRM_NEW_PATIENT_EMAIL
  );

  useEffect(() => {
    const search = new URLSearchParams(window.location.search);
    const id = search.get('id');
    if (!id) return;
    const variables: ConfirmNewEmailVariables = { requestId: id };
    confirmNewEmail({
      variables
    })
      .then(r => {
        if (r.data?.confirmNewEmail?.succeeded) {
          setActiveStep(STEP.EmailChanged);
          showSuccessToast('New email is confirmed!');
        } else {
          setActiveStep(STEP.ChangeFailed);
          handleErrorCode(r.data?.confirmNewEmail?.errorCode);
        }
      })
      .catch(error => {
        setActiveStep(STEP.ChangeFailed);
        Honeybadger.notify(error, 'Confirm new patient email');
        showErrorToast('Failed to confirm new email address.');
      })
      .finally(() => {});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleErrorCode = (errorCode: string | null | undefined) => {
    Honeybadger.notify(errorCode || 'Unspecified error', 'Change patient email');
    if (errorCode === 'LimitExceededException' || errorCode === 'TooManyRequestsException') {
      showErrorToast(
        `You have exceeded the limit for requesting email change. Please wait then try again, or contact ${KBContacts.navigatorEmail}`
      );
    } else if (errorCode === 'WrongRequest') {
      showErrorToast(
        `We are unable to proceed with the request. Please wait then try again, or contact ${KBContacts.navigatorEmail}`
      );
    } else {
      showErrorToast('Failed to confirm new email address.');
    }
  };

  if (activeStep === STEP.ChangingEmail) {
    return <Loader color={themes.colors.yellow.primary} height={200} width={200} />;
  }

  if (activeStep === STEP.ChangeFailed) {
    return null;
  }

  return (
    <FormContainer>
      <FormContent>
        <InstructionText>
          <div>
            If you&apos;re not using SSO ( single-sign-on) please reset your password by following{' '}
            <Link href="/forgot-password">link</Link>, otherwise use button bellow.
          </div>
          <div>
            <p>How to determinate if you&apos;re using SSO or not:</p>
            <Bullets>
              <li>
                <b>Using SSO</b>: If you usually log into your Kindbody portal by clicking on a
                button that says something like &quot;Log in with [Google/Apple]&quot; or if
                you&apos;re redirected to another page (from another service you recognize) to enter
                your credentials, you&apos;re probably using SSO.
              </li>
              <li>
                <b>Not Using SSO</b>: If you usually enter your email and password directly on your
                Kindbody portal without being redirected elsewhere, then you&apos;re not using SSO.
              </li>
            </Bullets>
          </div>
        </InstructionText>
        <ButtonWrapper>
          <Button
            category="primary"
            label="PROCEED TO LOGIN"
            fullWidth={true}
            onClick={() => history.replace('/login')}
          />
        </ButtonWrapper>
      </FormContent>
    </FormContainer>
  );
};
