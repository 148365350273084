import React from 'react';

import { Text } from 'components/v2/Typography';

export const CardContentItemValue = ({ value }: { value: string }) => {
  return (
    <div>
      <Text tag="span" fontStyle="bold" size="lg">
        {value}
      </Text>
    </div>
  );
};
