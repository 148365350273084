import React, { useState } from 'react';

import { TermsModal } from 'components/Billing/components/TermsModal';

import { TermsButton } from '../TermsLinks/TermsLinks.styled';

export const PaymentTermsLink = () => {
  const [isPaymentTermsModalVisible, setIsPaymentTermsModalVisible] = useState(false);
  const togglePaymentTerms = () => {
    setIsPaymentTermsModalVisible(!isPaymentTermsModalVisible);
  };

  return (
    <>
      <TermsButton
        onClick={e => {
          e?.preventDefault();
          togglePaymentTerms();
        }}
      >
        Payment Terms.
      </TermsButton>

      <TermsModal
        show={isPaymentTermsModalVisible}
        onAgree={() => togglePaymentTerms()}
        onClose={() => togglePaymentTerms()}
      />
    </>
  );
};
