import React from 'react';

import { themes } from 'kb-shared';
import styled from 'styled-components';

import SignupPhoto from '../assets/images/signup_photo.jpg';

export const WIDTH = '280px';

const Aside = styled.aside`
  display: flex;
  position: fixed;
  flex-direction: column;
  justify-content: flex-end;
  width: ${WIDTH};
  height: 100vh;
`;

const BGImage = styled.img`
  object-fit: cover;
  width: 100%;
  height: 100%;
`;

const QuoteBox = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 190px;
  background-color: ${themes.colors.yellow.primary};
  opacity: 0.84;
`;

const QuoteText = styled.div`
  font-size: 56px;
  color: ${themes.colors.neutral.primaryNavy};
  text-align: center;
`;

const QuoteImage = () => {
  return (
    <Aside>
      <BGImage src={SignupPhoto} alt={'Sign Up'} />
      <QuoteBox>
        <QuoteText>Own your (future)</QuoteText>
      </QuoteBox>
    </Aside>
  );
};

export default QuoteImage;
