import { Button } from 'components/v2/Buttons/Button';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1em;
`;

export const ProfileInfo = styled.div`
  display: flex;
  align-items: center;
`;

export const Greeting = styled.div`
  color: ${({ theme }) => theme.colors.neutral.primaryNavy};

  > div:first-child > h1 {
    @media screen and (max-width: ${({ theme }) => theme.breakpoints.largePhonePortrait}) {
      font-size: 14px;
      font-weight: 400;
    }
  }
`;

export const GreetingLine = styled.div`
  > h1 {
    font-weight: 700;
    margin-left: 24px;

    @media screen and (max-width: ${({ theme }) => theme.breakpoints.smallDesktop}) {
      font-size: 32px;
      margin-left: 4px;
    }

    @media screen and (max-width: ${({ theme }) => theme.breakpoints.largePhonePortrait}) {
      font-size: 18px;
    }
  }
`;

export const BookingButton = styled(Button)`
  margin-left: 20px;
`;
