import React, { FormEvent, SyntheticEvent, useEffect, useState, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Envelope } from 'assets/icons/Icons';
import AppleAuthButton from 'components/AppleAuthButton';
import { GoogleAuthButton } from 'components/GoogleAuthButton';
import { Button } from 'components/v2/Buttons/Button';
import { LinkButton } from 'components/v2/Buttons/LinkButton/LinkButton';
import { TextField } from 'components/v2/Form';
import { PasswordField } from 'components/v2/Form/PasswordField/PasswordField';
import { Text } from 'components/v2/Typography';
import { ReduxState } from 'kb-redux';
import * as redux from 'kb-redux';
import { utils } from 'kb-shared';
import { AppleAuthError, GoogleAuthError, LoginError, NullableString } from 'kb-shared/types';
import { BugTracker } from 'kb-shared/utilities/bugTracker';
import { analytics } from 'utilities/analytics';
import { showErrorToast } from 'utilities/notificationUtils';
import { pageUrl } from 'utilities/pageUrl';

import {
  LoginContainer,
  LoginContent,
  LoginButtonsContainer,
  Form,
  ButtonWrapper,
  VTextFieldMargin,
  Link,
  SignUpLinkContainer,
  ResendSmsCodeWrapper
} from './LoginForm.styled';
import { Props } from './LoginForm.types';
import { getAppleSignUpDisabledMessage } from './LoginForm.utils';

const KBContacts = utils.KBContacts;
const { login } = redux.patient;

export const LoginForm = ({ onForgotPassword, onLogin, onGoogleAuthError, onError }: Props) => {
  const dispatch = useDispatch();
  const [emailAddress, setEmailAddress] = useState('');
  const [password, setPassword] = useState('');
  const [showSignInWithEmail, setShowSignInWithEmail] = useState(false);
  const [mfaChallengeRequested, setMfaChallengeRequested] = useState(false);
  const [otp, setOtp] = useState('');
  const [maskedPhone, setMaskedPhone] = useState<NullableString>();
  const isLoggedIn = Boolean(useSelector<ReduxState>(state => state.patient.isLoggedIn));
  const loading = Boolean(useSelector<ReduxState>(state => state.patient.loading));
  const loginError = useSelector<ReduxState, LoginError | null>(state => state.patient.loginError);
  const loginErrorMetaData = useSelector<ReduxState, NullableString>(
    state => state.patient.loginErrorMetaData
  );
  const googleAuthError = useSelector<ReduxState, GoogleAuthError | null>(
    state => state.patient.googleAuthError
  );
  const appleAuthError = useSelector<ReduxState>(state => state.patient.appleAuthError);

  const googleUserId = useSelector<ReduxState>(state =>
    state.patient.patient.google ? state.patient.patient.google.id : null
  );

  const handleAppleAuthError = (error: AppleAuthError) => {
    if (error.type !== 'UserHasNotSignedUp') {
      showErrorToast(
        'We were unable to proceed with this request. If you are registered with a Gmail account, please log in with Google. If you are registered with an Apple ID, please log in with Apple. Otherwise, please check your credentials and try again.'
      );
    } else {
      // If error is 'UserHasNotSignedUp' that means that user signed in with
      // Apple account, but user doesn't yet exist in KB BE.
      showErrorToast(getAppleSignUpDisabledMessage(error.email || ''));
    }
  };

  const updateEmail = (event: FormEvent<HTMLInputElement>) => {
    setEmailAddress(event.currentTarget.value);
  };

  const updatePassword = (event: FormEvent<HTMLInputElement>) => {
    setPassword(event.currentTarget.value);
  };

  const updateOtp = (event: FormEvent<HTMLInputElement>) => {
    setOtp(event.currentTarget.value);
  };

  const handleSubmit = async (event: SyntheticEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (mfaChallengeRequested) {
      analytics.track(analytics.EVENTS.MFA_CODE_SUBMITTED);
      dispatch(login(emailAddress, password, otp));
    } else {
      if (!isValidEmail(emailAddress)) {
        showErrorToast('Please enter a valid email address.');
        return;
      } else if (!isValidPassword(password)) {
        showErrorToast('Please enter a valid password.');
        return;
      }
      analytics.track(analytics.EVENTS.EMAIL_SIGN_IN_STARTED);
      dispatch(login(emailAddress, password));
    }
  };

  const renderGoogleButton = useMemo(() => <GoogleAuthButton />, []);

  const renderAppleButton = useMemo(() => <AppleAuthButton operation="login" />, []);

  const renderLoginButton = useMemo(
    () => (
      <Button
        label="Continue with Email"
        category="secondary"
        size="sm"
        fullWidth
        leftIcon={<Envelope type="solid" />}
        onClick={() => {
          setShowSignInWithEmail(true);
        }}
      />
    ),
    []
  );

  useEffect(() => {
    analytics.page(analytics.PAGES.LOG_IN_PAGE);

    return () => {
      if (isLoggedIn) {
        onLogin();
      }
      if (googleAuthError && onGoogleAuthError) {
        onGoogleAuthError(googleAuthError, googleUserId as string);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!appleAuthError) return;
    handleAppleAuthError(appleAuthError as AppleAuthError);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appleAuthError]);

  useEffect(() => {
    if (!loginError) return;

    switch (loginError.type) {
      case 'Unconfirmed':
        showErrorToast(
          'You have an account that has not been confirmed, please check your email for a verification code.'
        );
        onError(loginError, emailAddress, password);
        break;
      case 'UnconfirmedWithResendLimitReached':
        showErrorToast(
          "You have an account that has not been confirmed. At the moment verification code can't be sent for your account. Please wait and try again later."
        );
        break;
      case 'InvalidCredentials':
        showErrorToast(
          'We were unable to proceed with this request. If you are registered with a Gmail account, please log in with Google. If you are registered with an Apple ID, please log in with Apple. Otherwise, please check your credentials and try again.'
        );
        break;
      case 'LimitExceededException':
        showErrorToast(
          `You have exceeded the limit for making a login request. Please wait then try again, or contact ${KBContacts.navigatorEmail}`
        );
        break;
      case 'MfaRequired':
        analytics.track(analytics.EVENTS.MFA_CODE_REQUESTED);
        setMfaChallengeRequested(true);
        window.scrollTo(0, 0);
        break;
      case 'MfaCodeExpired':
        showErrorToast(
          'Code that you entered has expired. Please use the resend SMS code option, or repeat the login.'
        );
        break;
      case 'MfaBlocked':
        showErrorToast(
          'You had too many invalid attempts to sign in in a short period of time. Please try again in 1 hour.'
        );
        break;
      case 'WrongMfaCode':
        showErrorToast('Entered code seems to be invalid.');
        break;
      default:
        showErrorToast('Something went wrong. Please try again.');
        BugTracker.notify(loginError.type, 'LoginError');
        break;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loginError?.type]);

  useEffect(() => {
    setMaskedPhone(loginErrorMetaData);
  }, [loginErrorMetaData]);

  useEffect(() => {
    if (isLoggedIn) {
      onLogin();
    }

    if (onGoogleAuthError) {
      onGoogleAuthError(googleAuthError as GoogleAuthError, googleUserId as string);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoggedIn, googleAuthError]);

  const onBackToLoginForm = () => {
    setOtp('');
    setMaskedPhone('');
    setMfaChallengeRequested(false);
    window.scrollTo(0, 0);
  };

  const onResendCode = (e: React.MouseEvent) => {
    e.preventDefault();
    setOtp('');
    // login attempt will trigger SMS if email + pwd are valid, and
    // if SMS is turned on for the patient, and
    // if MFA isn't temp blocked for the patient
    dispatch(login(emailAddress, password));
  };

  const onForgotPasswordClick = () => {
    analytics.track(analytics.EVENTS.FORGOT_PASSWORD_CLICKED);
    onForgotPassword();
  };

  const isFormBusy = loading || isLoggedIn;
  const mfaFormInputsValid = otp.length >= 4;

  return (
    <LoginContainer>
      <LoginContent>
        {!mfaChallengeRequested && (
          <LoginButtonsContainer>
            {renderGoogleButton}
            {renderAppleButton}
            {!showSignInWithEmail && renderLoginButton}
          </LoginButtonsContainer>
        )}

        {showSignInWithEmail && (
          <Form onSubmit={handleSubmit}>
            {!mfaChallengeRequested && (
              <>
                <VTextFieldMargin>
                  <TextField
                    id="emailInput"
                    label="EMAIL ADDRESS"
                    type="text"
                    value={emailAddress}
                    onChange={updateEmail}
                    autoComplete="email"
                    spellCheck={false}
                    placeholder="janedoe@email.com"
                    hideDescription={true}
                    hideHelperText={true}
                  />
                </VTextFieldMargin>

                <VTextFieldMargin>
                  <PasswordField
                    inputId="passwordInput"
                    label="PASSWORD"
                    value={password}
                    onChange={updatePassword}
                    placeholder="Your password"
                    hideDescription={true}
                    hideHelperText={true}
                  />
                </VTextFieldMargin>
                <Text tag="p" size="sm" fontStyle="medium">
                  <LinkButton onClick={onForgotPasswordClick} text="FORGOT YOUR PASSWORD?" />
                </Text>
              </>
            )}
            {mfaChallengeRequested && (
              <VTextFieldMargin>
                <TextField
                  id="otpInput"
                  label="SMS CODE"
                  helperText={maskedPhone ? `Code sent to ${maskedPhone}` : ''}
                  type="text"
                  value={otp}
                  onChange={updateOtp}
                  spellCheck={false}
                />
                <ResendSmsCodeWrapper>
                  <Text tag="p" size="sm" fontStyle="medium">
                    <LinkButton onClick={onResendCode} text="RESEND SMS CODE" />
                  </Text>
                </ResendSmsCodeWrapper>
              </VTextFieldMargin>
            )}

            <ButtonWrapper>
              {mfaChallengeRequested && (
                <Button
                  type="submit"
                  label="VERIFY CODE"
                  category="primary"
                  isDisabled={isFormBusy || !mfaFormInputsValid}
                  fullWidth
                />
              )}
              {!mfaChallengeRequested && (
                <Button
                  type="submit"
                  label="LOG IN"
                  category="primary"
                  isDisabled={isFormBusy}
                  fullWidth
                />
              )}
              {mfaChallengeRequested && (
                <Button
                  type="button"
                  label="BACK TO LOGIN"
                  onClick={onBackToLoginForm}
                  fullWidth={true}
                />
              )}
            </ButtonWrapper>
          </Form>
        )}
        {!mfaChallengeRequested && (
          <>
            <SignUpLinkContainer>
              <div>
                <Text tag="p" size="sm" fontStyle="medium">
                  DON&apos;T HAVE AN ACCOUNT?
                </Text>
              </div>
              <div>
                <Text tag="p" size="sm" fontStyle="medium">
                  <Link
                    href={pageUrl.createAccount({
                      step: 'create_account',
                      previous: 'login'
                    })}
                  >
                    SIGN UP HERE
                  </Link>
                </Text>
              </div>
            </SignUpLinkContainer>
          </>
        )}
      </LoginContent>
    </LoginContainer>
  );
};

const isValidEmail = (email: string) =>
  email?.length >= 5 && email.indexOf('@') !== -1 && email.indexOf('.') !== -1;

const isValidPassword = (password: string) => password?.length > 1;
