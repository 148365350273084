import React, { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router';

import { Loader } from 'components/Loader/Loader';
import { LOG_OUT, logoutPatient } from 'kb-redux/user.redux';
import { getPostLogoutLandingUrl } from 'utilities/userUtil';

export const Logout = () => {
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();

  const clearAuthSessionAndRedirect = useCallback(async () => {
    const postLogoutLandingUrl = getPostLogoutLandingUrl();
    dispatch({ type: LOG_OUT });
    await logoutPatient();
    redirectPatient(postLogoutLandingUrl);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    clearAuthSessionAndRedirect();
  }, [clearAuthSessionAndRedirect]);

  const redirectPatient = (postLogoutLandingUrl: string) => {
    let redirectUrl = '';

    if (location.search.match(/login/) || location.search.match(/logout/)) {
      redirectUrl = '/login';
    } else if (location.state?.redirectToPasswordReset) {
      redirectUrl = `/forgot-password?email=${encodeURIComponent(location.state?.email)}`;
    } else {
      redirectUrl = `/login${location.search}`;
      if (redirectUrl.includes('target=')) {
        redirectUrl = `${redirectUrl}&loggedOut=1`;
      }
    }

    if (postLogoutLandingUrl && !location.state?.redirectToPasswordReset) {
      window.location.href = postLogoutLandingUrl;
    } else {
      history.replace(redirectUrl);
    }
  };

  return <Loader absoluteCentered />;
};
