import { gql, useQuery } from '@apollo/client';

import { Lab } from 'kb-shared';
import { BugTracker } from 'kb-shared/utilities/bugTracker';

import { UrlParamValue } from '../types/types';
import { isFalsyNonNumber } from '../utils/validation';

const QUERY_LAB = gql`
  query lab($labId: Int!) {
    lab(labId: $labId) {
      id
      name
    }
  }
`;

export const useLab = (labId?: UrlParamValue<number>) => {
  const { data, loading, error } = useQuery<{
    lab: Lab;
  }>(QUERY_LAB, {
    skip: isFalsyNonNumber(labId),
    variables: {
      labId: labId
    },
    onError: error => {
      BugTracker.notify(error, 'Failed to load lab');
    }
  });

  return { data: data?.lab, loading, error };
};
