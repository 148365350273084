import React from 'react';

import { Text } from 'components/v2/Typography/Text';

import { UnappliedPaymentCardList } from '../../components/UnappliedPaymentCardList/UnappliedPaymentCardList';
import { UnappliedPaymentsTable } from '../../components/UnappliedPaymentsTable/UnappliedPaymentsTable';
import { Payment } from '../../types/payment';
import { TextContainer } from '../UnappliedPayments/UnappliedPayments.styled';

export const UnappliedPaymentsDisplayed = ({
  displayAsTable,
  payments
}: {
  displayAsTable: boolean;
  payments: Payment[];
}) => {
  if (payments.length === 0)
    return (
      <TextContainer>
        <Text tag="span" fontStyle="medium">
          {UNAPPLIED_PAYMENTS_MISSING_MESSAGE}
        </Text>
      </TextContainer>
    );
  return displayAsTable ? (
    <UnappliedPaymentsTable payments={payments} />
  ) : (
    <UnappliedPaymentCardList payments={payments} />
  );
};

const UNAPPLIED_PAYMENTS_MISSING_MESSAGE =
  'There are currently no unapplied payments on your account.';
