export const modifyUrlForEnvironment = (url: string) => {
  if (process.env.REACT_APP_ENV === 'production') return url;

  const currentEnv = window.location.host;
  return url.replace('https://portal.kindbody.com', `http://${currentEnv}`);
};

/**
 * Creates URLSearchParams from an object, filtering out undefined and null values
 */
export const createURLSearchParams = (params: Record<string, any>) => {
  const searchParams = new URLSearchParams();

  for (const key in params) {
    if (params[key] !== undefined && params[key] !== null) {
      searchParams.append(key, params[key]);
    }
  }

  return searchParams;
};
