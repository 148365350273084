import { gql } from '@apollo/client';

export const CREDIT_CARDS_QUERY = gql`
  query {
    kbStripeCards {
      stripeIdentifier
      brand
      expirationMonth
      expirationYear
      lastFourDigits
      default
    }
  }
`;
