import { gql } from '@apollo/client';

export const CREATE_PATIENT_WALMART_DETAIL = gql`
  mutation createPatientWalmartDetail(
    $associatesDateOfBirth: String!
    $walmartUid: String!
    $walmartUidType: String!
  ) {
    createPatientWalmartDetail(
      associatesDateOfBirth: $associatesDateOfBirth
      walmartUid: $walmartUid
      walmartUidType: $walmartUidType
    ) {
      benefitsProfiles {
        bid
        firstName
        lastName
        dateOfBirth
      }
    }
  }
`;
