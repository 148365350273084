import { gql } from '@apollo/client';

export const QUERY_CYCLE_PLAN_ACKNOWLEDGED = gql`
  query cyclePlanAcknowledged($id: Int!) {
    cyclePlanAcknowledged(id: $id)
  }
`;

export const QUERY_CYCLE_PLAN_TO_ACKNOWLEDGE = gql`
  query cyclePlanToAcknowledge($id: Int!) {
    cyclePlanToAcknowledge(id: $id) {
      id
      patientAcknowledgementJson
      patientAcknowledgementJsonHash
      partners {
        id
        name
        birthday
      }
      patient {
        id
        name
        birthday
      }
      cycleType
      labNotes
    }
  }
`;

export const MUTATION_CYCLE_PLAN_ACKNOWLEDGE = gql`
  mutation acknowledgeCyclePlan($cyclePlanId: Int!, $hash: String!) {
    acknowledgeCyclePlan(cyclePlanId: $cyclePlanId, hash: $hash) {
      cyclePlanAcknowledgement {
        id
        acknowledgedAt
      }
      errors {
        message
        code
      }
    }
  }
`;
