import styled from 'styled-components';

export const AppointmentSection = styled.div`
  margin: 50px 0;
`;

export const H3 = styled.h3`
  ${({ theme }) => theme.typos.subsectionHeader};
  margin-bottom: 25px;
`;
