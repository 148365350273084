import React from 'react';

import { V2_COMPONENT_CLASS_NAME } from 'components/v2/common.styled';
import { getInputStates } from 'components/v2/utils';

import { TextInputStyled, Container, LeftIcon, RightIcon } from './TextInput.styled';
import { Props } from './TextInput.types';

export const TextInput = ({
  id,
  helperTextId,
  labelId,
  type = 'text',
  status = 'default',
  placeholder,
  required = false,
  spellCheck = false,
  autoComplete,
  value = '',
  onChange,
  onBlur,
  onKeyPress,
  onFocus,
  leftIcon,
  className,
  fullWidth,
  backgroundColor,
  rightIcon,
  onRightIconClick,
  postfix,
  hideArrowsForNumberInput,
  ariaLabel,
  max,
  step
}: Props) => {
  const isFilled = Boolean(value);
  const { isDisabled, isReadOnly, isValid } = getInputStates(status);

  return (
    <Container className={className} fullWidth={fullWidth}>
      {leftIcon && <LeftIcon>{leftIcon}</LeftIcon>}
      <TextInputStyled
        readOnly={isReadOnly}
        className={V2_COMPONENT_CLASS_NAME}
        type={type}
        status={status}
        isFilled={isFilled}
        id={id}
        disabled={isDisabled}
        placeholder={placeholder}
        spellCheck={spellCheck}
        autoComplete={autoComplete}
        leftIcon={!!leftIcon}
        rightIcon={!!rightIcon}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        onKeyPress={onKeyPress}
        onFocus={onFocus}
        required={required}
        aria-labelledby={labelId}
        aria-disabled={isDisabled}
        aria-invalid={!isValid}
        aria-readonly={isReadOnly}
        aria-errormessage={helperTextId}
        backgroundColor={backgroundColor}
        hideArrowsForNumberInput={hideArrowsForNumberInput}
        aria-label={ariaLabel}
        max={max}
        step={step}
      />
      {rightIcon && <RightIcon onClick={onRightIconClick}>{rightIcon}</RightIcon>}
      {postfix}
    </Container>
  );
};
