import { gql } from '@apollo/client';

export const CREATE_WALMART_PATIENT_MEMBERSHIP = gql`
  mutation createWalmartPatientMembership($patientsDateOfBirth: String!, $bid: String!) {
    createWalmartPatientMembership(patientsDateOfBirth: $patientsDateOfBirth, bid: $bid) {
      patientMembership {
        id
        employerName
      }
    }
  }
`;
