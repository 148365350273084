import { InsuranceProvider } from 'components/InsuranceForm/InsuranceForm.types';
import { COB_PRIORITY_CODE } from 'components/InsuranceForm/InsuranceForm.utils';

import { InsuranceListUI } from './InsuranceList.types';

export const INSURANCE_PROVIDER = {
  primary: {
    title: 'Primary Insurance Coverage',
    label: 'Primary Insurance',
    type: COB_PRIORITY_CODE.PRIMARY,
    disableDelete: false
  },
  secondary: {
    title: 'Secondary Insurance Coverage',
    label: 'Secondary Insurance',
    type: COB_PRIORITY_CODE.SECONDARY,
    disableDelete: false
  },
  tertiary: {
    title: 'Tertiary Insurance Coverage',
    label: 'Tertiary Insurance',
    type: COB_PRIORITY_CODE.TERTIARY,
    disableDelete: false
  }
};

export const DEFAULT_INSURANCE_PROVIDER = {
  title: 'Insurance Coverage',
  label: '',
  type: COB_PRIORITY_CODE.PRIMARY
};

export const isPrimaryInsurance = (cobPriorityCode: string) =>
  cobPriorityCode === COB_PRIORITY_CODE.PRIMARY;

const getInsuranceProvider = (insuranceProviders: InsuranceProvider[], cobPriorityCode: string) =>
  insuranceProviders.find(insurance => insurance.cobPriorityCode === cobPriorityCode);

export const getInsuranceProviders = (
  insuranceProviders: InsuranceProvider[] = []
): InsuranceListUI[] => {
  const primaryInsurance = {
    ...INSURANCE_PROVIDER.primary,
    data: getInsuranceProvider(insuranceProviders, INSURANCE_PROVIDER.primary.type) ?? null
  };

  const secondaryInsurance = {
    ...INSURANCE_PROVIDER.secondary,
    data: getInsuranceProvider(insuranceProviders, INSURANCE_PROVIDER.secondary.type) ?? null
  };

  const tertiraryInsurance = {
    ...INSURANCE_PROVIDER.tertiary,
    data: getInsuranceProvider(insuranceProviders, INSURANCE_PROVIDER.tertiary.type) ?? null
  };

  const defaultInsurance = {
    ...DEFAULT_INSURANCE_PROVIDER,
    data: null,
    disableDelete: false
  };

  if (secondaryInsurance.data || tertiraryInsurance.data) {
    return [{ ...primaryInsurance, disableDelete: true }, secondaryInsurance, tertiraryInsurance];
  }

  if (primaryInsurance.data) {
    return [primaryInsurance, secondaryInsurance];
  }

  return [defaultInsurance];
};
