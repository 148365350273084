import React, { forwardRef, useState } from 'react';

import { MagnifyingGlass } from 'assets/icons/Icons';

import { escapeRegex } from '../CountrySelect';
import { TextInput } from '../TextInput';
import { Container, OptionList, OptionStyled, SearchContainer } from './DropdownMenu.styled';
import { Props, Option } from './DropdownMenu.types';

export const DropdownMenu = forwardRef<HTMLDivElement, Props>((props, ref) => {
  const { id, searchable, searchPlaceholder, onSelect, searchFn, selected, options } = props;
  const isSelected = (option: Option): boolean =>
    selected?.value === option.value && selected?.label === option.label;

  const [value, setValue] = useState('');
  const [filteredOptions, setFilteredOptions] = useState<Option[]>(options);

  const search = (term: string): void => {
    if (!term) {
      return setFilteredOptions(options);
    }

    if (searchFn) {
      const filteredByTerm = searchFn(options, term);
      setFilteredOptions(filteredByTerm);
    } else {
      const regex = new RegExp(`.*${escapeRegex(term)}.*`, 'i');
      const filteredByTerm = options.filter(option => regex.test(option.label));
      setFilteredOptions(filteredByTerm);
    }
  };

  return (
    <Container id={id} role="listbox" ref={ref}>
      {searchable && (
        <SearchContainer>
          <TextInput
            id={`input-dropdown-search-${id}`}
            leftIcon={<MagnifyingGlass type="solid" />}
            type="search"
            placeholder={searchPlaceholder}
            value={value}
            onChange={e => {
              setValue(e.currentTarget.value);
              search(e.currentTarget.value);
            }}
          />
        </SearchContainer>
      )}

      <OptionList>
        {filteredOptions.map(option => (
          <OptionStyled
            onClick={() => onSelect(option)}
            key={`${option.value} ${option.label}`}
            aria-selected={isSelected(option)}
          >
            {option.label}
          </OptionStyled>
        ))}
      </OptionList>
    </Container>
  );
});

// must be set because of forwardRef otherwise eslint complains
DropdownMenu.displayName = 'DropdownMenu';
