import { filledOutlineShadow } from 'components/v2/common.styled';
import { Status } from 'components/v2/types';
import styled, { css } from 'styled-components';

import InputWithMask from '../InputWithMask/InputWithMask';
import { inputBaseCSS, inputStatusStyle } from '../TextInput/TextInput.styled';

export const MaskedInput = styled(InputWithMask)<{ status: Status; isFilled: boolean }>`
  ${inputBaseCSS}
  ${({ isFilled }) =>
    isFilled &&
    css`
      box-shadow: ${filledOutlineShadow};
    `}
  ${({ status }) => inputStatusStyle[status]}
`;

export const RegularInput = styled.input<{ status: Status; isFilled: boolean }>`
  ${inputBaseCSS}
  ${({ isFilled }) =>
    isFilled &&
    css`
      box-shadow: ${filledOutlineShadow};
    `}
  ${({ status }) => inputStatusStyle[status]}
`;
