import { AppointmentType } from 'kb-shared';

const getAppointmentChannel = (appointmentType: AppointmentType) => {
  const isPhoneCall = appointmentType.channel === 'phone';
  const isVideoCall = appointmentType.channel === 'video';
  const isInPerson = appointmentType.channel === 'in_person';

  return {
    isPhoneCall,
    isVideoCall,
    isInPerson
  };
};

export default getAppointmentChannel;
