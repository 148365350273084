import React, { useEffect, useRef, useState } from 'react';

import { Tooltip } from 'components/v2/Tooltip/Tooltip';
import { Heading, Text } from 'components/v2/Typography';
import { useFeatureFlags } from 'hooks';
import { PatientToDo, isPatientToDo } from 'kb-shared';
import { analytics, EventName } from 'utilities/analytics';
import { pageUrl } from 'utilities/pageUrl';
import { filterNonConsentToDosWithDuplicateTitle } from 'utilities/todo';
import { useBreakpoints } from 'utilities/useBreakpoints';

import { ToDoItem } from '../ToDoItem/ToDoItem';
import {
  AdditionalNames,
  ContextMenuContainer,
  ContextMenuItem,
  ContextMenuItemNumber,
  ContextMenuItemTitle,
  ContextMenuItems,
  CountContainer,
  CounterContainer,
  DisplayedItemsNamesContainer,
  ItemContainer,
  ItemNumberContainer,
  ItemTitle,
  ItemTitleContainer,
  ItemsContainer,
  ItemsNamesContainer,
  ToDoItemsContainer
} from './ToDoItems.styled';
import {
  INTAKE_TODO_TITLE,
  additionalInfoForIntakeToDo,
  preProcessToDos,
  todoIndex,
  todosForDisplay
} from './ToDoItems.utils';

export const ToDoItems = ({
  apiTodos,
  intakeFormCompletionPercent,
  onToDoCompleted
}: {
  apiTodos: PatientToDo[];
  intakeFormCompletionPercent: number;
  onToDoCompleted: Function;
}) => {
  const { ppMedicationInstructions } = useFeatureFlags();
  const { isDesktop } = useBreakpoints();
  const filteredToDos = preProcessToDos(filterNonConsentToDosWithDuplicateTitle(apiTodos));
  const additionalNamesRef = useRef<HTMLDivElement>(null);
  const contextMenuRef = useRef<HTMLDivElement>(null);
  const [contextMenuPosition, setContextMenuPosition] = useState({ x: 0, y: 0 });
  const [selectedToDo, setSelectedToDo] = useState<PatientToDo | undefined>(
    filteredToDos.length > 0 ? filteredToDos[0] : undefined
  );
  const MAX_LINE_APPROXIMATE_LENGTH_TAB = 29;
  const MAX_LINE_APPROXIMATE_LENGTH_MENU = 24;

  useEffect(() => {
    document.body.addEventListener('click', onDocumentClick);
    return () => {
      document.body.removeEventListener('click', onDocumentClick);
    };
  }, []);

  useEffect(() => {
    const doesTodoIncludeConsentsPageUrl = apiTodos.some(todo =>
      todo.linkUrl?.includes(`${pageUrl.consents()}/`)
    );

    if (doesTodoIncludeConsentsPageUrl) {
      analytics.track(analytics.EVENTS.PATIENT_HAS_CONSENTS_TO_SIGN);
    }
  }, [apiTodos]);

  const onDocumentClick = () => {
    if (contextMenuRef.current && contextMenuRef.current.style.display !== 'none')
      contextMenuRef.current.style.display = 'none';
  };

  const onToDoItemSelected = (todo: PatientToDo) => {
    if (isPatientToDo(todo)) {
      if (todo.linkUrl?.includes(`${pageUrl.consents()}/`)) {
        analytics.track(analytics.EVENTS.PATIENT_CONSENT_SELECTED);
      }
    }

    analytics.track(`${todo.title} ${analytics.EVENTS.TODO_SELECTED}` as EventName);
    setSelectedToDo(todo);
  };

  if (filteredToDos.length === 0 || !selectedToDo) return null;

  const MAX_NUMBER_OF_DISPLAYED_TODOS = 3;
  const todosToDisplay = isDesktop
    ? todosForDisplay(filteredToDos, selectedToDo, MAX_NUMBER_OF_DISPLAYED_TODOS)
    : filteredToDos;
  return (
    <ToDoItemsContainer>
      <CounterContainer>
        <CountContainer>
          <Text fontStyle="bold" tag="span">
            {filteredToDos.length}
          </Text>
        </CountContainer>
        <div>
          <Heading tag="h2" noMargin>
            {isDesktop ? 'To-Do Items Below' : 'To-Do Items'}
          </Heading>
        </div>
      </CounterContainer>
      <ItemsContainer>
        <ItemsNamesContainer>
          <DisplayedItemsNamesContainer>
            {todosToDisplay.map(todo => {
              return (
                <Tooltip
                  key={todo.id}
                  tooltip={todo.title}
                  displayTooltip={todo.title.length > MAX_LINE_APPROXIMATE_LENGTH_TAB}
                  minWidth="220px"
                >
                  <ItemContainer
                    $active={todo.id === selectedToDo?.id}
                    onClick={() => onToDoItemSelected(todo)}
                    key={todo.id}
                  >
                    <ItemNumberContainer $active={todo.id === selectedToDo?.id}>
                      <Text fontStyle="bold" tag="span">
                        {todoIndex(filteredToDos, todo) + 1}
                      </Text>
                    </ItemNumberContainer>
                    <ItemTitleContainer>
                      <ItemTitle>
                        <Text
                          fontStyle={todo.id === selectedToDo?.id ? 'bold' : 'regular'}
                          tag="span"
                        >
                          {todo.title}
                        </Text>
                      </ItemTitle>
                      {todo.title === INTAKE_TODO_TITLE && (
                        <Text size="sm">
                          {additionalInfoForIntakeToDo(todo.title, intakeFormCompletionPercent)}
                        </Text>
                      )}
                    </ItemTitleContainer>
                  </ItemContainer>
                </Tooltip>
              );
            })}
          </DisplayedItemsNamesContainer>
          {filteredToDos.length > MAX_NUMBER_OF_DISPLAYED_TODOS && (
            <AdditionalNames
              ref={additionalNamesRef}
              onClick={e => {
                e?.stopPropagation();
                if (additionalNamesRef.current && contextMenuRef.current) {
                  setContextMenuPosition({
                    x: additionalNamesRef.current.offsetLeft - 237,
                    y: additionalNamesRef.current.offsetTop + 42
                  });

                  contextMenuRef.current.style.display = 'flex';
                }
              }}
            >
              ...
            </AdditionalNames>
          )}
        </ItemsNamesContainer>
        {isDesktop && (
          <ToDoItem
            todo={selectedToDo}
            onToDoCompleted={onToDoCompleted}
            medicationsInstructionsFlagIsOn={Boolean(ppMedicationInstructions)}
          />
        )}
        {!isDesktop &&
          todosToDisplay.map((todo, index) => {
            return (
              <ToDoItem
                key={todo.id}
                todo={todo}
                onToDoCompleted={onToDoCompleted}
                medicationsInstructionsFlagIsOn={Boolean(ppMedicationInstructions)}
                orderNumber={index + 1}
              />
            );
          })}
        <ContextMenuContainer
          $x={contextMenuPosition.x}
          $y={contextMenuPosition.y}
          ref={contextMenuRef}
        >
          <ContextMenuItems>
            {filteredToDos.map((todo, index) => {
              return (
                <Tooltip
                  position="left"
                  key={todo.id}
                  tooltip={todo.title}
                  displayTooltip={todo.title.length > MAX_LINE_APPROXIMATE_LENGTH_MENU}
                >
                  <ContextMenuItem
                    key={index}
                    $active={todo.id === selectedToDo?.id}
                    onClick={() => onToDoItemSelected(todo)}
                  >
                    <ContextMenuItemNumber $active={todo.id === selectedToDo?.id}>
                      <Text fontStyle="bold">{index + 1}</Text>
                    </ContextMenuItemNumber>
                    <ContextMenuItemTitle>
                      <Text fontStyle={todo.id === selectedToDo?.id ? 'bold' : 'regular'}>
                        {todo.title}
                      </Text>
                    </ContextMenuItemTitle>
                  </ContextMenuItem>
                </Tooltip>
              );
            })}
          </ContextMenuItems>
        </ContextMenuContainer>
      </ItemsContainer>
    </ToDoItemsContainer>
  );
};
