import styled from 'styled-components';

import { IllustratedBackgroundWidget } from '../Widgets/Widgets.styled';

export const SessionContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1em;

  span {
    position: absolute;
    top: 20px;
    right: 20px;
  }
`;

export const PredictedBox = styled.div`
  display: flex;
  padding: 16px 24px 24px 24px;
  flex-direction: column;
  gap: 8px;
  border-radius: 8px;
  box-shadow: ${({ theme }) => theme.shadows.card.level0};
  background: ${({ theme }) => theme.colors.white};
  background-repeat: no-repeat;
  background-position: top -20px right -50px;
  background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTk0IiBoZWlnaHQ9IjE5MiIgdmlld0JveD0iMCAwIDE5NCAxOTIiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxnIGlkPSJLaW5kYm9keSBMb2dvIiBvcGFjaXR5PSIwLjI0Ij4KPHBhdGggaWQ9IlVuaW9uIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiIGNsaXAtcnVsZT0iZXZlbm9kZCIgZD0iTTc2LjI1NDYgNDIuNTM1Nkw3Mi4zMTggNDQuOTc4TDcyLjMxOCA0NC45Nzc5QzUxLjk4MjIgNTcuNTk3OCA0MC4zMTgzIDc2LjU1NTUgNDAuMzE4MyA5Ni45ODU0QzQwLjMxODMgMTE3LjQ3NSA1MS45MjUyIDEzNi40MyA3Mi4xNjM1IDE0OC45OTNMNzYuMDQ3IDE1MS40TDY1Ljk1ODQgMTY2LjI4Nkw2Mi41MzA1IDE2NC4xNjZDNDQuMDIzNCAxNTIuNzE1IDIxLjk2NDQgMTMxLjc2NiAyMS45NjQ0IDk2Ljk4NTVDMjEuOTY0NCA2Mi4yMDYzIDQ0LjAyMTQgNDEuMjU3IDYyLjUyNTggMjkuODA3N0w2NS45MTE5IDI3LjcxNDRMNzYuMjU0NiA0Mi41MzU2Wk0xMjguMTM0IDI3LjcxNDRMMTMxLjUyIDI5LjgwNzhMMTMxLjUyMSAyOS44MDc4QzE1MC4wMjYgNDEuMjU3IDE3Mi4wODMgNjIuMjA2MyAxNzIuMDgzIDk2Ljk4NTVDMTcyLjA4MyAxMzEuNzYzIDE1MC4wMjYgMTUyLjcxNSAxMzEuNTIxIDE2NC4xNjRMMTI4LjA5MSAxNjYuMjg2TDExOC4wMDYgMTUxLjQwM0wxMjEuODgzIDE0OC45OTZDMTQyLjExOCAxMzYuNDMzIDE1My43MjUgMTE3LjQ3NSAxNTMuNzI1IDk2Ljk4NTVDMTUzLjcyNSA3Ni41NTM2IDE0Mi4wNjQgNTcuNTk3OSAxMjEuNzI5IDQ0Ljk3OEwxMTcuNzk0IDQyLjUzNTZMMTI4LjEzNCAyNy43MTQ0WiIgZmlsbD0iI0Y5RTE2OCIvPgo8L2c+Cjwvc3ZnPgo=');
`;

export const Container = styled(IllustratedBackgroundWidget)`
  box-shadow: none;

  @media ${({ theme }) => theme.queries.tablet} {
    box-shadow: ${({ theme }) => theme.shadows.card.level0};
  }
`;
