import React from 'react';

import { utils } from 'kb-shared';
import HelpText from 'screens/Book/components/HelpText';

import { BookingQuizAnswer } from '../BookingQuiz.types';
import { Option } from './Option';
import { FlexWrapper, LargeOption, MediumOption, VerticalWrapper } from './OptionsList.styled';

interface Props {
  options: Array<BookingQuizAnswer>;
  large: boolean;
  onSelect: (opt: BookingQuizAnswer) => void;
  isLoggedIn: boolean | undefined;
}

export const OptionsList = ({ options, large, onSelect, isLoggedIn }: Props) => (
  <div data-testid="service-options-container">
    {large ? (
      <FlexWrapper>
        {options.map((opt: BookingQuizAnswer) => (
          <LargeOption key={opt.text} onClick={() => onSelect(opt)}>
            <Option {...opt} />
          </LargeOption>
        ))}
      </FlexWrapper>
    ) : (
      <VerticalWrapper>
        {options.map((opt: BookingQuizAnswer) => (
          <MediumOption key={opt.text} onClick={() => onSelect(opt)}>
            <Option text={opt.text} />
          </MediumOption>
        ))}
      </VerticalWrapper>
    )}
    {!isLoggedIn && (
      <HelpText
        text="Not sure which option?"
        linkText="Contact us"
        linkUrl={`mailto:${utils.KBContacts.navigatorEmail}?subject=Appointment Booking Question`}
      />
    )}
  </div>
);
