import styled from 'styled-components';

export const SignedConsentHeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const SignedConsentTitle = styled.div`
  font-size: 3em;
  font-weight: bold;
`;
