import { AppointmentType } from 'kb-shared';

export const formatDuration = (appointmentType: AppointmentType) => {
  const { hourDuration, minuteDuration } = appointmentType;
  let duration = '';

  if (hourDuration) {
    duration = `${hourDuration}h `;
  }

  if (minuteDuration) {
    duration += `${minuteDuration}m `;
  }

  return duration.trim();
};
