import { useMutation } from '@apollo/client';
import _mapValues from 'lodash/mapValues';
import React, { useState } from 'react';

import { ReactComponent as HandTrustIcon } from 'assets/icons/MyProfile/handTrust.svg';
import { TextField, PhoneFieldBasic } from 'components/v2/Form';
import { Checkbox } from 'components/v2/Inputs';
import { Heading } from 'components/v2/Typography';
import { Text } from 'components/v2/Typography';
import { PatientTrustee, utils } from 'kb-shared';
import { PATIENT } from 'kb-shared/graphql/queries';
import { PatientTrusteeResponseData } from 'kb-shared/graphql/types';
import { BugTracker } from 'kb-shared/utilities/bugTracker';
import { getGraphQLErrorsMessages } from 'utilities/errors';
import { showErrorToast } from 'utilities/notificationUtils';

import { TwoColumnGrid } from '../shared.styled';
import { SectionHeader } from '../Trustees.styled';
import { addPreferredContact, removePreferredContact } from '../utils/preferredContact';
import {
  Container,
  CheckboxOptions,
  CheckboxOptionsTextContainer,
  CheckboxGroup
} from './TrusteesField.styled';
import { UPDATE_PATIENT_TRUSTEE, DELETE_PATIENT_TRUSTEE } from './TrusteesFields.graphql';

const { isEmailValid } = utils;

type Props = {
  id?: string | null;
  index?: number;
  name?: string | null;
  relationship?: string | null;
  phone?: string | null;
  state?: string | null;
  position?: number | null;
  email?: string | null;
  preferredContacts?: string[] | null;
};

export default function TrusteesFields({
  id,
  index,
  name,
  relationship,
  phone,
  state,
  position,
  email,
  preferredContacts
}: Props) {
  const [trusteeDetails, setTrusteeDetails] = useState({
    name,
    relationship,
    phone,
    state,
    id,
    email,
    preferredContacts
  });
  const [updateTrusteeDetails] = useMutation<PatientTrusteeResponseData>(UPDATE_PATIENT_TRUSTEE, {
    refetchQueries: [{ query: PATIENT }]
  });
  const [deleteTrusteeMutation] = useMutation(DELETE_PATIENT_TRUSTEE, {
    refetchQueries: [{ query: PATIENT }]
  });

  const handleFieldChange = (field: string, value: string) => {
    setTrusteeDetails({
      ...trusteeDetails,
      [field]: value
    });
  };

  const shouldDelete = (details: typeof trusteeDetails) => {
    const { id, ...rest } = details;
    return !!id && Object.keys(rest).every(key => !(details as any)[key]?.length);
  };

  const submitTrustee = async (trusteeDetails: PatientTrustee) => {
    const response = await updateTrusteeDetails({ variables: { ...trusteeDetails } });
    if (response.data) {
      const { ...patientTrustee } = response.data.updatePatientTrustee.patientTrustee;
      setTrusteeDetails(patientTrustee);
    }
  };

  const deleteTrustee = async () => {
    await deleteTrusteeMutation({ variables: { id: trusteeDetails.id } });

    const emptyTrustee = _mapValues(trusteeDetails, () => null);
    setTrusteeDetails(emptyTrustee);
  };

  const handleUpdateTrustee = async (value: string) => {
    if (value.length === 0 && !trusteeDetails.id) {
      return;
    }

    try {
      if (shouldDelete(trusteeDetails)) {
        deleteTrustee();
      } else {
        submitTrustee(trusteeDetails as PatientTrustee);
      }
    } catch (error) {
      BugTracker.notify(error as Error);
      const errors = getGraphQLErrorsMessages(error);
      const errorCopy = errors.join(', ');
      return showErrorToast(errorCopy);
    }
  };

  const handleUpdateTrusteeEmail = (value: string) => {
    if (value && !isEmailValid(value)) {
      return showErrorToast('Please enter a valid email');
    }

    handleUpdateTrustee(value);
  };

  const handleChangePreferredContacts = (value: string) => {
    const preferredContacts = trusteeDetails.preferredContacts?.includes(value)
      ? removePreferredContact(trusteeDetails.preferredContacts || [], value)
      : addPreferredContact(trusteeDetails.preferredContacts || [], value);

    const newtrusteeDetails = { ...(trusteeDetails as PatientTrustee), preferredContacts };

    if (shouldDelete(newtrusteeDetails)) {
      return deleteTrustee();
    }

    submitTrustee(newtrusteeDetails);
  };

  return (
    <Container>
      <SectionHeader>
        <HandTrustIcon />
        <Heading tag="h2" styledAs="h4" noMargin>
          Trustee {position}
        </Heading>
      </SectionHeader>
      <div>
        <TwoColumnGrid>
          <TextField
            id={`inputNameTrustee${index}`}
            label="Name of trustee"
            placeholder="Enter trustee name"
            value={trusteeDetails.name ?? ''}
            onChange={e => handleFieldChange('name', e.currentTarget.value)}
            onBlur={e => handleUpdateTrustee(e.currentTarget.value)}
          />
          <TextField
            id={`inputRelation${index}`}
            label="Relationship"
            placeholder="Enter relationship to trustee"
            value={trusteeDetails.relationship ?? ''}
            onChange={e => handleFieldChange('relationship', e.currentTarget.value)}
            onBlur={e => handleUpdateTrustee(e.currentTarget.value)}
          />

          <TwoColumnGrid>
            <PhoneFieldBasic
              id={`inputPhone${index}`}
              label="Phone number"
              placeholder="555-555-5555"
              value={trusteeDetails.phone ?? ''}
              onChange={e => handleFieldChange('phone', e.currentTarget.value)}
              onBlur={e => handleUpdateTrustee(e.currentTarget.value)}
            />

            <TextField
              id={`inputState${index}`}
              label="State"
              placeholder="Enter state"
              value={trusteeDetails.state ?? ''}
              onChange={e => handleFieldChange('state', e.currentTarget.value)}
              onBlur={e => handleUpdateTrustee(e.currentTarget.value)}
            />
          </TwoColumnGrid>

          <TextField
            id={`inputEmail${index}`}
            label="Email"
            placeholder="Enter email"
            value={trusteeDetails.email ?? ''}
            onChange={e => handleFieldChange('email', e.currentTarget.value)}
            onBlur={e => handleUpdateTrusteeEmail(e.currentTarget.value)}
          />
        </TwoColumnGrid>

        <CheckboxOptions>
          <CheckboxOptionsTextContainer>
            <Text size="sm" fontStyle="semibold">
              How would you like to share your information with this trustee?
            </Text>
            <Text size="xs">Choose all that apply.</Text>
          </CheckboxOptionsTextContainer>

          <CheckboxGroup>
            <Checkbox
              id={`email-checkbox-${index}`}
              label="Email"
              isChecked={!!trusteeDetails.preferredContacts?.includes('email')}
              onChange={() => handleChangePreferredContacts('email')}
            />
            <Checkbox
              id={`phoneCall-checkbox-${index}`}
              label="Phone Call"
              isChecked={!!trusteeDetails.preferredContacts?.includes('phone_call')}
              onChange={() => handleChangePreferredContacts('phone_call')}
            />
            <Checkbox
              id={`text-checkbox-${index}`}
              label="Text Message"
              isChecked={!!trusteeDetails.preferredContacts?.includes('text')}
              onChange={() => handleChangePreferredContacts('text')}
            />
          </CheckboxGroup>
        </CheckboxOptions>
      </div>
    </Container>
  );
}
