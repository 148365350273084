import { Status } from './types';

export const getInputStates = (status: Status) => {
  const isDisabled = status === 'disabled';
  const isValid = status !== 'error';
  const isReadOnly = status === 'readOnly';

  return {
    isDisabled,
    isValid,
    isReadOnly
  };
};
