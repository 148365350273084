import React from 'react';

import { themes } from 'kb-shared';
import styled from 'styled-components';

const InfoButton = () => {
  return (
    <Container>
      <Text>?</Text>
    </Container>
  );
};

export default InfoButton;

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  border-radius: 10px;
  background-color: ${themes.colors.yellow.primary};
`;

const Text = styled.div`
  font-weight: bolder;
  font-size: 18px;
`;
