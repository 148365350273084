import React from 'react';

import { Title, Description } from './styled-components';

const OrderMedications = () => (
  <>
    <Title>Order medications</Title>
    <Description>
      After your care team has notified the pharmacy about your care plan, the pharmacy will reach
      out to you with a quote. You will need to indicate to the pharmacy that you’d like them to
      move forward and &quot;fill it&quot; in order to get a quote. The pharmacy will not charge you
      for anything until you confirm shipment. If you have not heard from the pharmacy then please
      contact your care team.
    </Description>
  </>
);

export default OrderMedications;
