import React from 'react';

import CalendarIcon from 'components/SVG/Calendar';
import LeftBracket from 'components/SVG/LeftBracket';
import RightBracket from 'components/SVG/RightBracket';
import { getTimeZone } from 'hooks/getTimeZone';
import { AppointmentType, Clinic, TimeSlot, themes } from 'kb-shared';
import { isCoachingAppointment } from 'kb-shared/utilities/appointment.helper';

import {
  AdditionalInfo,
  AppointmentBlock,
  AppointmentBlockContainer,
  AppointmentBlockDate,
  AppointmentBlockTitle,
  AppointmentName,
  CalendarIconGroupContainer,
  Content
} from './AppointmentDetailBlock.styled';
import { formatDuration } from './AppointmentDetailBlock.utils';
import AppointmentLocation from './AppointmentLocation';

const { colors } = themes;

type AppointmentDetailProps = {
  appointmentType: AppointmentType;
  clinic: Clinic | null;
  timeSlot: TimeSlot | null;
};
const AppointmentDetailBlock = (props: AppointmentDetailProps) => {
  const { appointmentType, timeSlot, clinic } = props;

  const formatTime = () => {
    if (!timeSlot) return '';

    const { formatTime } = getTimeZone(timeSlot.timeZone, appointmentType);
    const formattedDate = formatTime(timeSlot.startTime, `ddd, MMM D, YYYY [at] h:mma z`);

    return formattedDate;
  };

  const showLicensedProviderName =
    appointmentType.validateProviderLicense && timeSlot?.licensedProviderName;

  return (
    <AppointmentBlock>
      <Content>
        <CalendarIconGroup />

        {timeSlot && (
          <AppointmentBlockContainer>
            <AppointmentBlockTitle>
              <AppointmentName>{appointmentType.name}</AppointmentName>
            </AppointmentBlockTitle>

            <AppointmentBlockDate>{formatTime()}</AppointmentBlockDate>
            <AppointmentLocation
              timeSlot={timeSlot}
              appointmentType={appointmentType}
              clinic={clinic}
            />
            {isCoachingAppointment(appointmentType) && (
              <AdditionalInfo>
                <p>Session Duration: {formatDuration(appointmentType)}</p>
                {showLicensedProviderName && (
                  <p>Licensed Provider: {timeSlot.licensedProviderName}</p>
                )}
              </AdditionalInfo>
            )}
          </AppointmentBlockContainer>
        )}
      </Content>
    </AppointmentBlock>
  );
};

const CalendarIconGroup = () => {
  return (
    <CalendarIconGroupContainer>
      <LeftBracket color={colors.yellow.primary} width={13} height={33} />
      <CalendarIcon />
      <RightBracket color={colors.yellow.primary} width={13} height={33} />
    </CalendarIconGroupContainer>
  );
};

export default AppointmentDetailBlock;
