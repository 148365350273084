import { CONTAINER_PADDING_SINGLE_SIDE } from './DeprecatedTabs.constants';
import { NavBounds } from './DeprecatedTabs.types';

export const getNavBoundaries = (
  tabListElement: HTMLUListElement,
  navItemsSelector: string
): NavBounds => {
  const { width: containerWidth } = tabListElement.getBoundingClientRect();
  const navItems = tabListElement.querySelectorAll(navItemsSelector);
  let previousItemEnd = 0;

  const items: NavBounds['items'] = Array.from(navItems).map(item => {
    const { width } = item.getBoundingClientRect();
    const left = previousItemEnd;
    const right = previousItemEnd + width;
    const itemWidth = {
      element: item,
      width,
      left,
      right
    };

    previousItemEnd = right;

    return itemWidth;
  });

  return {
    items,
    itemsTotalWidth: items.reduce((acc, item) => acc + item.width, 0),
    containerWidth
  };
};

export const findItemsInView = (
  offset: number,
  containerWidth: number,
  items: NavBounds['items']
) => {
  const boundaryLeft = offset + CONTAINER_PADDING_SINGLE_SIDE;
  const boundaryRight = offset + containerWidth - CONTAINER_PADDING_SINGLE_SIDE;
  const visibleItems = items.filter(({ left, right }) => {
    const itemLeft = left + CONTAINER_PADDING_SINGLE_SIDE;
    const itemRight = right + CONTAINER_PADDING_SINGLE_SIDE;

    // left item border inside the container
    if (itemLeft >= boundaryLeft && itemLeft < boundaryRight) {
      return true;
    }

    // right item border inside the container
    if (itemRight > boundaryLeft && itemRight <= boundaryRight) {
      return true;
    }

    // item covers the whole container
    if (itemLeft < boundaryLeft && itemRight >= boundaryRight) {
      return true;
    }

    return false;
  });

  return visibleItems;
};

export const getForwardMoveOffset = (offset: number, widths: NavBounds) => {
  const visibleItems = findItemsInView(offset, widths.containerWidth, widths.items);
  const lastVisibleItem = visibleItems[visibleItems.length - 1];
  const lastVisibleItemIndex = widths.items.findIndex(
    item => item.element === lastVisibleItem.element
  );
  const lastVisibleIsLastItem = lastVisibleItemIndex === widths.items.length - 1;

  // handles the case when the last visible item covers the whole container
  // so we need to force move to the next item if there is one
  if (!lastVisibleIsLastItem && lastVisibleItem.left === offset) {
    return lastVisibleItem.right;
  }

  return lastVisibleItem.left;
};

export const getBackwardMoveOffset = (offset: number, widths: NavBounds) => {
  const visibleItems = findItemsInView(offset, widths.containerWidth, widths.items);
  const firstVisibleItem = visibleItems[0];
  const firstVisibleItemIndex = widths.items.findIndex(
    item => item.element === firstVisibleItem.element
  );
  const firstVisibleItemIsFirstItem = firstVisibleItemIndex === 0;

  const newOffset =
    firstVisibleItem.right + 2 * CONTAINER_PADDING_SINGLE_SIDE - widths.containerWidth;

  // handles the case when the first visible item covers the whole container
  // so we need to force move to the item before it if there is one
  if (!firstVisibleItemIsFirstItem && offset === newOffset) {
    const previousItem = widths.items[firstVisibleItemIndex - 1];

    return previousItem.right + 2 * CONTAINER_PADDING_SINGLE_SIDE - widths.containerWidth;
  }

  return newOffset;
};

export const getMaximalOffset = (widths: NavBounds) => {
  const itemsTotalWithPadding = widths.itemsTotalWidth + CONTAINER_PADDING_SINGLE_SIDE * 2;
  const maximalOffset = itemsTotalWithPadding - widths.containerWidth;
  return maximalOffset;
};
