import { themes } from 'kb-shared';
import styled from 'styled-components';

export const DrugContainer = styled.div`
  border-radius: 8px;
  background: radial-gradient(
      112.26% 141.42% at 0% 0%,
      rgba(255, 255, 255, 0.64) 0%,
      rgba(255, 255, 255, 0) 100%
    ),
    ${themes.colors.neutral.lavenderWhite};
  box-shadow: ${themes.shadows.card.level1};
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0.75em 1em;
  margin-bottom: 0.5em;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tabletPortrait}) {
    background: ${themes.colors.white};
  }
`;

export const DrugRecurrenceContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const DrugIconContainer = styled.div`
  display: flex;
  width: 48px;
  height: 48px;
  padding: 9px;
  justify-content: center;
  align-items: center;
  border-radius: 100px;
  background: ${themes.colors.white};
  box-shadow: ${themes.shadows.card.level0};
  margin-right: 0.5em;
`;

export const MedicationsAndNotesContainer = styled.div`
  padding: 0 3em 1em 3em;
  display: flex;
  flex-direction: column;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.smallDesktop}) {
    padding: 0 0 1em 0;
  }

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tabletPortrait}) {
    margin-top: 1em;
  }
`;

export const NotesContainer = styled.div`
  margin-top: 1em;
`;

export const Note = styled.div`
  border-radius: 8px;
  background: ${themes.colors.white};
  box-shadow: 0px 0px 1px 0px rgba(10, 13, 61, 0.08), 0px 1px 1px 0px rgba(10, 13, 61, 0.12),
    0px 1px 4px 1px rgba(67, 71, 146, 0.16);
  padding: 1.25em 1.5em;
  margin-top: 0.25em;
  white-space: pre-line;
`;

export const AcknowledgeContainer = styled.div`
  padding: 1em 1.5em;
  margin: 0 1px;
  border-radius: 8px;
  margin-top: 1em;
  background: ${themes.colors.semantic.lightPink};
  box-shadow: ${themes.shadows.container.glowRed};
  text-align: center;
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tabletPortrait}) {
    box-shadow: ${themes.shadows.button.hoverDanger};
  }
`;

export const AcknowledgeButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin: 1em 0;
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tabletPortrait}) {
    flex-direction: column;
  }

  > button {
    max-width: 272px;
    @media screen and (max-width: ${({ theme }) => theme.breakpoints.tabletPortrait}) {
      margin-bottom: 0.5em;
    }
  }

  > button:nth-child(1) {
    margin-right: 0.5em;
    @media screen and (max-width: ${({ theme }) => theme.breakpoints.tabletPortrait}) {
      margin-right: 0;
    }
  }

  > button:nth-child(2) {
    margin-left: 0.5em;
    @media screen and (max-width: ${({ theme }) => theme.breakpoints.tabletPortrait}) {
      margin-left: 0;
    }
  }
`;

export const InformationalTextContainer = styled.div`
  text-align: center;
  margin-bottom: 8px;
`;
