import React from 'react';

import { CreditCartNumber } from './CreditCard.styled';

type CreditCardNumberProps = {
  lastFourDigits: string;
};

export const CreditCardNumber = ({ lastFourDigits }: CreditCardNumberProps) => {
  const formattedCardNumber = '•••• '.repeat(3) + lastFourDigits;
  return <CreditCartNumber>{formattedCardNumber}</CreditCartNumber>;
};
