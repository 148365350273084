import styled from 'styled-components';

export const ModalContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const CallTextNumberContainer = styled.div`
  display: flex;
  align-items: flex-start;
`;
