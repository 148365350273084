import React, { useState } from 'react';

import { ArrowLeft, ArrowRight, CheckCircle } from 'assets/icons/Icons';
import { Button } from 'components/v2/Buttons/Button';
import { GenericDropdown } from 'components/v2/DropdownMenu/Dropdown';
import { Heading, Text } from 'components/v2/Typography';
import { useFeatureFlags } from 'hooks';
import { Patient, themes } from 'kb-shared';
import { momentFormatted } from 'kb-shared/utilities/momentHelpers';
import { useBreakpoints } from 'utilities/useBreakpoints';

import { WidgetTitleContainer } from '../Widgets/Widgets.styled';
import { IntercourseReminder } from './IntercourseReminder/IntercourseReminder';
import { MedicationsAndNotes } from './MedicationsAndNotes/MedicationsAndNotes';
import { medicationInstructionsMock } from './MedicationsWidget.constants';
import { useConsentTodos, useNextInstructions } from './MedicationsWidget.hooks';
import {
  CollapsibleDayContainer,
  CollapsibleDayContent,
  CollapsibleDayTitleContainer,
  DaysAndLastUpdateContainer,
  DaysContainer,
  GoForwardArrow,
  GoUpArrow,
  InstructionDay,
  LastUpdateContainer,
  LastUpdateIconContainer,
  LastUpdateTextContainer,
  MedicationInstructionsWrapper,
  MedicationsWidgetContainer,
  MedicationsWidgetTitleContainer,
  MobileButtonContainer
} from './MedicationsWidget.styled';
import { MedicationInstruction } from './MedicationsWidget.types';
import {
  areInstructionsMissing,
  getFormattedDateLabel,
  getFormattedTitleLabel,
  getInstructionForMobile,
  getInstructionsAfter,
  getInstructionsBefore,
  getLongFormattedDateLabel,
  getOpenInstructions
} from './MedicationsWidget.utils';
import { MedicationsWidgetBlocker } from './MedicationsWidgetBlocker/MedicationsWidgetBlocker';
import { MedicationsWidgetRetry } from './MedicationsWidgetRetry/MedicationsWidgetRetry';

export const MedicationsWidget = ({ patient }: { patient: Patient }) => {
  const {
    data: instructionsData,
    error: instructionsError,
    loading: instructionsLoading,
    refetch: refetchInstructions
  } = useNextInstructions();
  const { data: consentTodos, loading: consentTodosLoading } = useConsentTodos();
  const { ppMedicationInstructions } = useFeatureFlags();
  const { isMobile } = useBreakpoints();
  const [selectedInstructionIndex, setSelectedInstructionIndex] = useState<number>(0);
  const [showAllInstructionsOnMobile, setShowAllInstructionsOnMobile] = useState<boolean>(false);
  const hasPendingConsentTodos = consentTodos.length > 0;
  const blockMedicationInstructions = Boolean(ppMedicationInstructions && hasPendingConsentTodos);

  if (instructionsError) return <MedicationsWidgetRetry onRetry={() => refetchInstructions()} />;

  if (
    !instructionsData ||
    areInstructionsMissing(instructionsData) ||
    instructionsLoading ||
    consentTodosLoading
  ) {
    return null;
  }

  const instructions = instructionsData.nextInstructions;
  const items: Array<MedicationInstruction> = instructions.map((instruction, index) => ({
    index: index as number,
    titleLabel: getFormattedTitleLabel(instruction),
    dateLabel: isMobile
      ? getLongFormattedDateLabel(instruction)
      : getFormattedDateLabel(instruction),
    data: instruction
  }));
  // when medication instructions are blocked with overlay, we show mock data so that it's non-obvious how to get or act upon real medication instruction data
  // even if the user tampers with DOM and/or removes elements/styling
  const shownItems: Array<MedicationInstruction> = blockMedicationInstructions
    ? medicationInstructionsMock
    : items;

  const firstDay = instructions[0];
  const hasIntercourseReminder = 'haveIntercourse' in firstDay && firstDay.haveIntercourse;
  const medicationsExist = shownItems.find(item => item.data.drugs?.length > 0) != null;
  const instructionsExist = shownItems.find(item => item.data.instructions) != null;

  const instructionsToDisplay = isMobile
    ? getInstructionForMobile(shownItems, showAllInstructionsOnMobile)
    : getOpenInstructions(shownItems, selectedInstructionIndex);

  const showPreviousInstructionsButton = shownItems.length > 3 && selectedInstructionIndex > 1;
  const showNextInstructionsButton =
    shownItems.length > 3 && selectedInstructionIndex < shownItems.length - 2;
  const showMedicationInstructionContent = selectedInstructionIndex > -1;
  return (
    <>
      {hasIntercourseReminder && firstDay && <IntercourseReminder date={firstDay.date} />}
      {(medicationsExist || instructionsExist) && (
        <MedicationsWidgetContainer>
          <MedicationInstructionsWrapper>
            {blockMedicationInstructions && <MedicationsWidgetBlocker />}
            <MedicationsWidgetTitleContainer>
              <WidgetTitleContainer $noMargin>
                <Heading tag="div" styledAs="h2">
                  Medications and Instructions
                </Heading>
              </WidgetTitleContainer>
            </MedicationsWidgetTitleContainer>
            {!isMobile && (
              <>
                <DaysAndLastUpdateContainer>
                  <DaysContainer>
                    {showPreviousInstructionsButton && (
                      <GenericDropdown
                        items={getInstructionsBefore(
                          shownItems,
                          instructionsToDisplay,
                          setSelectedInstructionIndex
                        )}
                        className="dropdown-menu-modal open-modal-bottom-left"
                      >
                        <Button
                          className="dropdown-open-button"
                          category="secondary"
                          leftIcon={<ArrowLeft type="solid" />}
                        />
                      </GenericDropdown>
                    )}
                    {instructionsToDisplay.map(item => {
                      const isActive = selectedInstructionIndex === item.index;

                      return (
                        <InstructionDay
                          $active={isActive}
                          onClick={() => setSelectedInstructionIndex(item.index)}
                          key={item.data.id}
                        >
                          <Text fontStyle={isActive ? 'bold' : 'regular'}>{item.titleLabel}</Text>
                          <Text color={themes.colors.neutral.lightNavy} size="xs">
                            {item.dateLabel}
                          </Text>
                        </InstructionDay>
                      );
                    })}
                    {showNextInstructionsButton && (
                      <GenericDropdown
                        items={getInstructionsAfter(
                          shownItems,
                          instructionsToDisplay,
                          setSelectedInstructionIndex
                        )}
                        className="dropdown-menu-modal"
                      >
                        <Button
                          className="dropdown-open-button"
                          category="secondary"
                          leftIcon={<ArrowRight type="solid" />}
                        />
                      </GenericDropdown>
                    )}
                  </DaysContainer>
                  {showMedicationInstructionContent && (
                    <LastUpdateContainer>
                      <LastUpdateTextContainer>
                        <Text size="sm" fontStyle="medium" color={themes.colors.green.darkestGreen}>
                          Last updated
                        </Text>
                        <Text size="sm" fontStyle="medium" color={themes.colors.green.darkestGreen}>
                          {momentFormatted(
                            instructionsData.nextInstructions[selectedInstructionIndex as number]
                              .updatedAt,
                            'M/D/YY h:mm A zz'
                          )}
                        </Text>
                      </LastUpdateTextContainer>
                      <LastUpdateIconContainer>
                        <CheckCircle type="solid" />
                      </LastUpdateIconContainer>
                    </LastUpdateContainer>
                  )}
                </DaysAndLastUpdateContainer>
                {showMedicationInstructionContent && (
                  <MedicationsAndNotes
                    medicationInstruction={
                      instructionsData.nextInstructions[selectedInstructionIndex]
                    }
                    patient={patient}
                    hideAcknowledgment={
                      blockMedicationInstructions ||
                      !instructionsData.nextInstructions[selectedInstructionIndex].started
                    }
                  />
                )}
              </>
            )}
            {isMobile && (
              <>
                {instructionsToDisplay.map(item => {
                  const isActive = selectedInstructionIndex === item.index;
                  return (
                    <CollapsibleDayContainer $active={isActive} key={item.data.id}>
                      <CollapsibleDayTitleContainer
                        onClick={() =>
                          item.index !== selectedInstructionIndex
                            ? setSelectedInstructionIndex(item.index)
                            : setSelectedInstructionIndex(-1)
                        }
                      >
                        <div>
                          <div>
                            <Text fontStyle="bold">{item.titleLabel}</Text>
                          </div>
                          <div>
                            <Text size="xs">{item.dateLabel}</Text>
                          </div>
                        </div>
                        {isActive ? <GoUpArrow /> : <GoForwardArrow />}
                      </CollapsibleDayTitleContainer>
                      {showMedicationInstructionContent && (
                        <CollapsibleDayContent pose={isActive ? 'visible' : 'hidden'}>
                          <LastUpdateContainer>
                            <LastUpdateTextContainer>
                              <Text
                                size="sm"
                                fontStyle="medium"
                                color={themes.colors.green.darkestGreen}
                              >
                                Updated: {momentFormatted(item.data.updatedAt, 'M/D/YY h:mm A zz')}
                              </Text>
                            </LastUpdateTextContainer>
                            <LastUpdateIconContainer>
                              <CheckCircle type="solid" />
                            </LastUpdateIconContainer>
                          </LastUpdateContainer>
                          <MedicationsAndNotes
                            medicationInstruction={item.data}
                            patient={patient}
                            hideAcknowledgment={blockMedicationInstructions || !item.data.started}
                          />
                        </CollapsibleDayContent>
                      )}
                    </CollapsibleDayContainer>
                  );
                })}
                <MobileButtonContainer>
                  <Button
                    label={
                      showAllInstructionsOnMobile ? 'Hide All Medications' : 'Show All Medications'
                    }
                    category="primary"
                    size="lg"
                    type="button"
                    onClick={() => setShowAllInstructionsOnMobile(!showAllInstructionsOnMobile)}
                  />
                </MobileButtonContainer>
              </>
            )}
          </MedicationInstructionsWrapper>
        </MedicationsWidgetContainer>
      )}
    </>
  );
};
