import { Text } from 'components/v2/Typography/Text';
import styled, { css } from 'styled-components';

import { Badge } from '../Badge/Badge';

export const Container = styled.li<{ isActive: boolean }>`
  display: flex;
  flex-direction: column;
  padding: 12px 0;
  border-bottom: 1px solid ${({ theme }) => theme.colors.neutral.lavender};
  display: grid;
  grid-template-columns: 48px 1fr;
  grid-template-rows: 25px;
  grid-template-areas:
    'icon category'
    'icon subject';
  column-gap: 16px;
  cursor: pointer;

  @media (min-width: ${({ theme }) => theme.breakpoints.smallDesktop}) {
    padding: 12px 16px 16px 16px;
    border-bottom: none;
    border-left: 2px solid transparent;

    :hover {
      background-color: ${({ theme }) => theme.colors.yellow.lightestYellow};
    }

    ${({ isActive }) =>
      isActive &&
      css`
        border-left: 2px solid ${({ theme }) => theme.colors.yellow.darkYellow};
        background-color: ${({ theme }) => theme.colors.yellow.lighterYellow};

        :hover {
          background-color: ${({ theme }) => theme.colors.yellow.lighterYellow};
        }
      `}
  }
`;

export const Category = styled.div`
  width: 100%;
  grid-area: category;
  display: flex;
  gap: 4px;
  white-space: nowrap;
  overflow: hidden;

  > *:first-child {
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

export const SubjectPreview = styled(Text).attrs({ size: 'sm' })`
  grid-area: subject;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  @media (min-width: ${({ theme }) => theme.breakpoints.smallDesktop}) {
    display: flex;
    align-items: center;
  }
`;

export const Icon = styled.div`
  grid-area: icon;
  position: relative;
`;

export const CategoryInfo = styled.div`
  flex: 1%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

export const BadgeStyled = styled(Badge)`
  @media (min-width: ${({ theme }) => theme.breakpoints.smallDesktop}) {
    display: none;
  }
`;

export const UnreadCounter = styled(Text).attrs({ size: 'xs' })`
  background-color: ${({ theme }) => theme.colors.semantic.darkRed};
  border-radius: 50%;
  color: ${({ theme }) => theme.colors.white};
  width: 24px;
  height: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: -8px;
  right: -8px;
`;
