import { useQuery } from '@apollo/client';
import React from 'react';

import { Patient, utils } from 'kb-shared';
import { BugTracker } from 'kb-shared/utilities/bugTracker';
import { TODO_WIDGET_ELEMENT_ID } from 'utilities/todo';

import { ToDoItems } from './ToDoItems/ToDoItems';
import { INTAKE_AND_TODO } from './ToDoWidget.graphql';
import { IntakeTodoResult } from './ToDoWidget.types';

export const ToDoWidget = ({ patient }: { patient: Patient }) => {
  const { data, loading, error, refetch } = useQuery<IntakeTodoResult>(INTAKE_AND_TODO, {
    variables: { formId: utils.intakeFormId(patient?.gender) },
    skip: !patient?.gender,
    onError: error => BugTracker.notify(error, 'ToDosFetch')
  });
  const intakeFormCompletionPercent = data?.formCompletion?.percentageComplete || null;
  const patientTodos = data?.patientTodos || [];

  if (loading || error || !data || !data.patientTodos) return null;

  return (
    <div id={TODO_WIDGET_ELEMENT_ID}>
      <ToDoItems
        intakeFormCompletionPercent={intakeFormCompletionPercent ?? 0}
        apiTodos={patientTodos}
        onToDoCompleted={refetch}
      />
    </div>
  );
};
