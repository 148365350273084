import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 30px;
  flex-direction: column;

  & > div {
    width: 100%;
    max-width: 400px;
    margin-bottom: 30px;
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  & > button {
    margin: 12px;
  }
`;

export const Title = styled.div`
  font-size: 18px;
  padding: 10px 0px;
`;
