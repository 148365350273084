import { gql, useQuery } from '@apollo/client';
import { useEffect } from 'react';

import { COB_PRIORITY_CODE } from 'components/InsuranceForm/InsuranceForm.utils';
import { BugTracker } from 'kb-shared/utilities/bugTracker';

export const PATIENT_INSURANCE_PROVIDERS = gql`
  query patient {
    patient {
      id
      insuranceProviders {
        id
        payerCompanyId
        payerCompanyNameByPatient
        derivedPayerCompanyName
        memberId
        groupNumber
        cobPriorityCode
        relationshipToInsured
        insuredFirstName
        insuredLastName
        insuredMemberId
        insuredGender
        insuredAddress
        insuredCity
        insuredState
        insuredZipcode
        insuredPhoneNumber
        insuredDob
        insuranceCardFront
        insuranceCardBack
        insuranceCardFrontId
        insuranceCardBackId
      }
    }
  }
`;

export interface PatientInsuranceProvidersResponse {
  patient: {
    id: string;
    insuranceProviders: Array<{
      id: string;
      payerCompanyId: string;
      payerCompanyNameByPatient: string;
      derivedPayerCompanyName: string;
      memberId: string;
      groupNumber: string;
      cobPriorityCode: COB_PRIORITY_CODE;
      relationshipToInsured: string;
      insuredFirstName: string;
      insuredLastName: string;
      insuredMemberId: string;
      insuredGender: string;
      insuredAddress: string;
      insuredCity: string;
      insuredState: string;
      insuredZipcode: string;
      insuredPhoneNumber: string;
      insuredDob: string;
      insuranceCardFront: string;
      insuranceCardBack: string;
      insuranceCardFrontId: number;
      insuranceCardBackId: number;
    }>;
  };
}

export const usePatientInsuranceProviders = (skip?: boolean) => {
  const { data, error, loading, refetch } = useQuery<PatientInsuranceProvidersResponse>(
    PATIENT_INSURANCE_PROVIDERS,
    { skip }
  );

  useEffect(() => {
    if (!error) return;

    BugTracker.notify(error, "Failed to fetch patient's insurance providers.");
  }, [error]);

  return {
    data,
    error,
    loading,
    refetch
  };
};
