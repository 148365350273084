import { Patient } from 'kb-shared';

export type Params = {
  id: string;
};

export type CyclePlanResult = {
  id?: string;
  patientAcknowledgementJson: CyclePlanType[];
  patientAcknowledgementJsonHash: string;
  partners?: Patient[];
  patient: Patient;
  cycleType?: string;
  labNotes?: string;
  donor?: string;
};

export type SpermPlanType = {
  id: string;
  source: string;
  donorIdentity: string;
  donorRequiresFdaScreening: string;
  donorFdaScreeningCompleted: string;
  donorFdaEligible: string;
  splitDetails: string;
  collectionMethod: string;
  surgicalCollectionLocation: string;
  surgicalLocationDetails: string;
  state: string;
};

export type CyclePlanField = {
  label: string;
  value: string | number | undefined;
};

export type CyclePlanViewSection = {
  title?: string | undefined;
  fields: CyclePlanField[];
};

export type CyclePlanType = {
  cycle_type: string;
  id: number;
  sections: CyclePlanViewSection[];
};

export type CyclePlanHookResult = {
  loading: boolean;
  data: {
    cyclePlans?: CyclePlanType[];
    patientAcknowledgementJsonHash?: string;
    patient?: CyclePlanViewSection[];
  };
  resentNeeded?: boolean;
};

export type AcknowledgeStatus = {
  isAcknowledged: boolean;
  hasError?: boolean;
  outOfDateVersion?: boolean;
  resentNeeded?: boolean;
  refreshNeeded?: boolean;
};

export type CyclePlanAcknowledge = {
  id: number;
  acknowledgedAt: string;
};

export type ErrorBase = {
  message: string;
  code: string;
};
export type CyclePlanAcknowledgeData = {
  cyclePlanAcknowledgement: CyclePlanAcknowledge;
  errors?: ErrorBase[];
};

export type CyclePlanAcknowledgeResponse = {
  acknowledgeCyclePlan: CyclePlanAcknowledgeData;
};

export enum CyclePlanAcknowledgeErrors {
  CONTACT_PROVIDER_RESEND = 'CONTACT_PROVIDER_RESEND',
  OUT_OF_DATE_VERSION = 'OUT_OF_DATE_VERSION'
}

export interface CyclePlanAcknowledgeProps {
  patientCyclePlans: CyclePlanType[];
  patientData: CyclePlanViewSection[];
  onBack: () => void;
}
