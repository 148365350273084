import styled from 'styled-components';

export const Container = styled.div`
  padding-left: 20px;
  padding-right: 20px;
  margin: 0 14px;
  width: 100%;
`;

export const Form = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ContactText = styled.div`
  font-size: 14px;
  text-decoration: none;
  a {
    text-decoration: underline;
  }
`;
