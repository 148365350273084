import styled, { css } from 'styled-components';

export const Tabs = styled.div`
  display: flex;
  gap: 1em;
  margin: 1em 0;
`;

export const TabItem = styled.div<{ active: boolean }>`
  flex: 1;
  text-align: center;
  padding: 8px 16px;
  border-radius: 8px;
  background: ${({ theme }) => theme.colors.white};
  box-shadow: ${({ theme }) => theme.shadows.card.level0};
  cursor: pointer;

  ${({ active }) =>
    active &&
    css`
      box-shadow: ${({ theme }) => theme.shadows.button.activeSecondary};
    `};
`;
