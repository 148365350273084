import { gql } from '@apollo/client';
import { useQuery } from '@apollo/client';
import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import { AppointmentProduct, AppointmentType } from 'kb-shared';
import { BugTracker } from 'kb-shared/utilities/bugTracker';
import { pageUrl } from 'utilities/pageUrl';

export const PATIENT_MEMBERSHIP = gql`
  query patientMembership {
    patient {
      id
      patientMembership {
        id
        membershipRules {
          code
          id
          discountId
        }
      }
    }
  }
`;

// If the user lands on confirm order page to buy a membership, but is already a member, redirect them to book.
export const useRedirectIfMember = ({
  product
}: {
  product: AppointmentProduct<AppointmentType> | null;
}): void => {
  const history = useHistory();
  const { data } = useQuery(PATIENT_MEMBERSHIP, {
    skip: (product && product.type !== 'membership') || false,
    onError: e => {
      BugTracker.notify(e, 'Patient membership query failed');
    }
  });

  useEffect(() => {
    if (data?.patient?.patientMembership && product?.type === 'membership') {
      history.push(pageUrl.booking.index());
    }
  }, [data, product, history]);
};
