import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

export const CheckboxOptions = styled.div`
  margin-top: 24px;
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const CheckboxOptionsTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const CheckboxGroup = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;

  @media (min-width: ${({ theme }) => theme.breakpoints.tabletPortrait}) {
    flex-direction: row;
    gap: 32px;
  }
`;
