/* eslint-disable @typescript-eslint/indent */
import moment, { Moment } from 'moment-timezone';
import React, { useState } from 'react';
import Media from 'react-media';
import posed from 'react-pose';

import { JourneyType, themes, utils } from 'kb-shared';
import styled from 'styled-components';

import { ReactComponent as ExpandSvg } from '../../assets/icons/expand.svg';
import { ReactComponent as MedicationCheckmark } from '../../assets/icons/medication-checkmark.svg';
import StepDetail, { Description } from './details/StepDetail';
import { SimpleJourneyStep } from './journeySteps/types';
import Timeline, { TIMELINE_OFFSET_DESKTOP, TIMELINE_OFFSET_MOBILE } from './Timeline';

const { deprecatedColors, colors, breakpoints } = themes;
const { isToday } = utils;

interface Props extends SimpleJourneyStep {
  completed: boolean;
  date?: string | Moment | null;
  journeyType: JourneyType;
  showDate?: boolean;
}

const Step = ({
  completed,
  date,
  description,
  descriptionForType,
  detail,
  icon,
  showTime,
  substeps,
  title,
  showDate
}: Props) => {
  const dateIsToday = !!date && isToday(moment(date, 'YYYY-MM-DDHH:mm:ss')); // must parse date here because of the formatting from backend
  const current = date === 'Current' || dateIsToday;
  const [expanded, setExpanded] = useState<boolean>(current);

  const toggleExpand = () => {
    setExpanded(!expanded);
  };

  let dateString = '';
  if (typeof date === 'string') {
    dateString = date;
  } else if (date) {
    dateString = date.format('ddd M/D');
  }

  let time = '';
  if (date && typeof date !== 'string' && moment(date, 'YYYY-MM-DDHH:mm:ss').isValid()) {
    time = moment(date, 'YYYY-MM-DDHH:mm:ss').format('h:mmA');
  }

  return (
    <Media query={{ minWidth: breakpoints.tabletPortrait }}>
      {desktop => {
        return (
          <AnimatedStepWrapper
            pose={expanded ? 'expanded' : 'collapsed'}
            completed={completed}
            expanded={expanded}
            desktop={desktop}
          >
            <StepHeader onClick={toggleExpand}>
              {desktop ? (
                <DateWrapper>
                  {showDate && dateString}
                  {showTime && expanded && <Time>{time}</Time>}
                </DateWrapper>
              ) : null}
              <TimelineWrapper>
                <Timeline completed={completed} expanded={expanded} icon={icon} />
              </TimelineWrapper>
              <RightContent>
                <TitleDateWrapper>
                  {desktop ? null : (
                    <DateWrapper>
                      {showDate && dateString} {showTime && time && `@ ${time}`}
                    </DateWrapper>
                  )}
                  <Title>
                    {title} {showTime && desktop && !completed && time && `@ ${time}`}{' '}
                    {completed && <MedicationCheckmark />}
                  </Title>
                </TitleDateWrapper>
                <IconsWrapper>
                  <ExpandIcon expanded={expanded ? 1 : 0} completed={completed ? 1 : 0} />
                </IconsWrapper>
              </RightContent>
            </StepHeader>

            {expanded && descriptionForType && (
              <StepExpanded>
                <StepDetail>
                  <Description>{descriptionForType}</Description>
                </StepDetail>
              </StepExpanded>
            )}
            {expanded && description && <StepExpanded>{description}</StepExpanded>}
            {expanded && detail && <StepExpanded>{detail}</StepExpanded>}
            {expanded && <>{substeps}</>}
          </AnimatedStepWrapper>
        );
      }}
    </Media>
  );
};

export default Step;

interface StepWrapperProps {
  completed?: boolean;
  expanded?: boolean;
  desktop: boolean;
}

const StepWrapper = styled.article`
  background: ${({ completed, expanded }: StepWrapperProps) => {
    if (expanded && completed) {
      return colors.neutral.primaryNavy;
    } else if (expanded) {
      return deprecatedColors.beige;
    } else if (completed) {
      return deprecatedColors.grayLight;
    }
    return colors.yellow.lightestYellow;
  }};

  margin: 2px 0;
  cursor: pointer;
  position: relative;
  color: ${({ completed, expanded }) => {
    if (expanded && completed) {
      return colors.white;
    }
    return colors.neutral.primaryNavy;
  }};

  .arrow {
    path {
      fill: ${({ completed }) => {
        if (completed) {
          return colors.neutral.primaryNavy;
        }
        return deprecatedColors.beige;
      }};
    }
  }
`;

const AnimatedStepWrapper = posed(StepWrapper)({
  expanded: {
    height: 'auto',
    duration: 200
  },
  collapsed: {
    height: 'auto',
    duration: 200
  }
});

const Title = styled.h2`
  font-size: 24px;
  margin: 20px 0;
`;

const DateWrapper = styled.time`
  text-align: end;
  font-weight: bold;
  font-size: 14px;
  display: inline-block;
  position: absolute;

  @media screen and (min-width: ${breakpoints.tabletPortrait}) {
    width: 200px;

    font-size: 24px;
    font-weight: normal;
  }
`;

const Time = styled.span`
  position: absolute;
  right: 0;
  top: 100%;
`;

interface SharedStepProps {
  expanded?: boolean | number;
  completed?: boolean | number;
}

const ExpandIcon = styled(ExpandSvg)<SharedStepProps>`
  transform: ${({ expanded }) => (expanded ? 'rotate(180deg)' : '')};
  margin: 0 16px;
  path {
    fill: ${({ expanded, completed }) => (expanded && completed ? 'white' : 'initial')};
  }

  @media screen and (min-width: ${breakpoints.tabletPortrait}) {
    margin-right: 100px;
  }
`;

const TimelineWrapper = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  padding-top: 28px;
  left: 16px;
  @media screen and (min-width: ${breakpoints.tabletPortrait}) {
    padding-top: 18px;
    left: 220px;
  }
`;

const RightContent = styled.div`
  display: flex;
  align-items: center;
  margin-left: ${TIMELINE_OFFSET_MOBILE};
  padding-top: 19px;
  flex: 0 1 100%;
  justify-content: space-between;

  @media screen and (min-width: ${breakpoints.tabletPortrait}) {
    padding-top: 0px;
    margin-left: ${TIMELINE_OFFSET_DESKTOP};
  }
`;

const TitleDateWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const IconsWrapper = styled.div`
  flex: 0 0 auto;
  margin-left: 16px;
`;

const StepHeader = styled.div`
  display: flex;
  align-items: center;
`;

const StepExpanded = styled.div`
  margin-left: ${TIMELINE_OFFSET_MOBILE};
  margin-bottom: 28px;
  margin-right: 28px;

  @media screen and (min-width: ${breakpoints.tabletPortrait}) {
    margin-left: ${TIMELINE_OFFSET_DESKTOP};
    margin-right: 48px;
  }

  @media screen and (min-width: ${breakpoints.tabletLandscape}) {
    margin-right: 96px;
  }
`;
