import { ApolloClient, InMemoryCache, from, HttpLink } from '@apollo/client';
import { GraphQLErrors } from '@apollo/client/errors';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { RetryLink } from '@apollo/client/link/retry';
import fetch from 'cross-fetch';
import { GraphQLError } from 'graphql';

import { getIdToken } from '../utilities/api';

interface DecodedToken {
  iss?: string;
  nonce?: string;
  oid?: string;
}

export const isTokenExpired = (token: string) => {
  const hasIdToken = !!token && token !== 'null';

  if (!hasIdToken) {
    return false;
  }

  const payload = token.split('.')[1];
  const payloadDecoded = JSON.parse(atob(payload));
  if (process.env.REACT_APP_ENV !== 'production' && isDevMedtronicToken(payloadDecoded))
    return false;
  return new Date().getTime() > payloadDecoded.exp * 1000;
};

const isDevMedtronicToken = (payloadDecoded: DecodedToken) => {
  return (
    payloadDecoded.iss ===
      'https://login.microsoftonline.com/0a29d274-1367-4a8f-99c5-90c3dc7d4043/v2.0' &&
    payloadDecoded.nonce === '885fcdcc-2b3b-4579-b987-5399a5a6cb63' &&
    payloadDecoded.oid === '728bf4ee-11d8-439f-9e8d-ff970e862ab7'
  );
};

const shouldLogout = (graphQLError: GraphQLError, isTokenExpired: boolean) => {
  const errorMessage = graphQLError.message.toLowerCase();
  if (errorMessage === 'maintenance') {
    return true;
  }

  if (errorMessage === 'unauthorized' || isTokenExpired) {
    return true;
  }

  return false;
};

const isOnScreenRequiringAuthenticatedUser = () => {
  const location = window.location;
  // some steps of booking process under /create-account are protected by authentication
  // and are an exception to the /create-account rule
  if (location.pathname === '/create-account') {
    const searchParams = new URLSearchParams(location.search);
    const stepValue = searchParams.get('step') ?? '';
    const authenticationProtectedSteps = ['insurance', 'confirm', 'success'];
    const isOnProtectedStep = authenticationProtectedSteps.includes(stepValue);
    return isOnProtectedStep;
  }

  return (
    location.pathname !== '/create-account' &&
    location.pathname !== '/login' &&
    location.pathname !== '/logout' &&
    location.pathname !== '/forgot-password'
  );
};

const onClientError = (errors: GraphQLErrors) => {
  if (!isOnScreenRequiringAuthenticatedUser()) return;

  const location = window.location;

  for (const graphQLError of errors) {
    const idToken = ''; //graphQLError.operation?.getContext().headers?.idtoken;
    if (shouldLogout(graphQLError, isTokenExpired(idToken))) {
      // Check if user is on auth screen (Sign-in or Sign-up screen) exists, because we
      // don't want to redirect unauthenticated users to logout. There is no purpose in
      // trying to logout them and we don't want to put them in a loop looking like this:
      // signin/signup -> logout -> signin/signup
      if (graphQLError.message.toLowerCase() === 'maintenance') {
        return (location.href = '/logout?maintanence=true');
      }

      const targetValue = encodeURIComponent(`${location.pathname}${location.search}`);
      return (location.href = `/logout?target=${targetValue}`);
    }
  }
};

const authLink = setContext((_, { headers }) => {
  return {
    headers: {
      ...headers,
      idtoken: getIdToken()
    }
  };
});

const errorLink = onError(({ graphQLErrors }) => {
  if (graphQLErrors) {
    onClientError(graphQLErrors);
  }
});

const httpLink = new HttpLink({ uri: process.env.REACT_APP_APOLLO_URL, fetch: fetch });

const retryLink = new RetryLink({
  delay: {
    initial: 500,
    max: 5000, // if we didn't get response in 5s, stop retrying
    jitter: true // this will result with random next request in interval [initial, 2x initial]
  },
  attempts: {
    max: 10
  }
});

const appLink = from([retryLink, errorLink, httpLink]);

const client = new ApolloClient<unknown>({
  link: authLink.concat(appLink),
  cache: new InMemoryCache()
});

client.defaultOptions = {
  watchQuery: {
    fetchPolicy: 'cache-and-network'
  }
};

export default client;
