import { pageUrl } from 'utilities/pageUrl';

export const getReturnBackRoute = () => {
  const redirectBackToMembership = localStorage.getItem('redirectBackToMembership');

  if (redirectBackToMembership) {
    return {
      pathname: pageUrl.enterpriseMembershipAccessCode(),
      text: 'Continue to Activate Benefit'
    };
  }

  const redirectBackToBooking = localStorage.getItem('redirectBackToBooking');
  if (redirectBackToBooking) {
    return {
      pathname: localStorage.getItem('redirectBackToBookingUrl'),
      text: 'Continue to Appointment Booking'
    };
  } else {
    return { pathname: '/', text: 'Back to dashboard' };
  }
};
