import { useQuery } from '@apollo/client';

import { Lab } from 'kb-shared';
import { LABS } from 'kb-shared/graphql/queries';
import { BugTracker } from 'kb-shared/utilities/bugTracker';

export const useDefaultLab = ({ skip = false }: { skip: boolean }) => {
  const { data, loading, error } = useQuery<{
    labs: Array<Lab> | undefined;
  }>(LABS, {
    skip,
    onError: error => {
      BugTracker.notify(error, 'Failed to load labs');
    }
  });

  // is labs.length === 1 ever true? Maybe this is some old check?
  const defaultLab = data?.labs?.length === 1 ? data.labs[0] : undefined;

  return { data: defaultLab, loading, error };
};
