import React from 'react';

import SubstepWrapper from '../../SubstepWrapper';
import { Description, Title } from './styled-components';

const ReturnOBAppointment = () => (
  <>
    <Title>Return OB Appointment</Title>
    <Description>
      This appointment includes a visit with your provider, an ultrasound, a vaginal culture & a
      cervix check.
    </Description>
    <Description>
      <strong>GBS Swab:</strong> This is a vaginal culture that checks for Group B Strep. If you
      test positive, you will need antibiotics during your labor. This bacteria is not harmful to
      you. The antibiotics will prevent the bacteria from getting into your baby's system as he/she
      passes through the birth canal.
    </Description>
    <Description>
      <strong>Cervix Check:</strong> Your provider will check the dilation of your cervix.
    </Description>
  </>
);

const HospitalistMeetGreetEvent = () => (
  <>
    <Title>Hospitalist Meet & Greet Event</Title>
    <Description>
      This Meet & Greet will be held at the CLW-Celebration. You will meet your delivery team and
      get an orientation to what to expect during your labor, delivery, and postpartum period. You
      are welcome to bring your partner or support person with you. Refreshments and snacks will be
      served.
    </Description>
  </>
);

const SUBSTEPS = [
  { component: <ReturnOBAppointment /> },
  { component: <HospitalistMeetGreetEvent /> }
];

const ObWeek35Substeps = () => <SubstepWrapper substeps={SUBSTEPS} />;

export default ObWeek35Substeps;
