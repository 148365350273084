import styled from 'styled-components';

export const EnterpriseTermsBlock = styled.div`
  background-color: ${({ theme }) => theme.colors.neutral.lavenderLight};
  color: ${({ theme }) => theme.colors.neutral.darkestNavy};
  padding: 16px;
  align-items: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
`;

export const TermsInnerContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const TermsTextContainer = styled.div`
  margin-bottom: 24px;
  margin-left: 2px;

  :last-of-type {
    margin-bottom: 0;
  }
`;

export const Separator = styled.div`
  box-sizing: border-box;
  height: 1px;
  border: 1px solid #979797;
  background-color: #d8d8d8;
  margin: 16px 0px 16px 0px;
`;

export const CheckboxContainer = styled.div`
  margin-top: 4px;
  margin-right: 22px;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
`;

export const EmployeeAttestation = styled.div`
  padding-left: 48px;
  letter-spacing: 0;
  cursor: pointer;

  p {
    margin-top: 16px;
  }
`;

export const AcceptTermsWrapper = styled.div`
  padding-top: 24px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`;
