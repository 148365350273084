import styled from 'styled-components';

export const PlusIcon = styled.img`
  width: 24px;
  height: 24px;
  margin: 4px 14px 4px 4px;
`;

export const ButtonText = styled.span`
  font-weight: 700;
  font-size: 24px;
  ${({ theme }) => theme.colors.neutral.primaryNavy};
`;

export const ButtonBackgroundAndBorder = styled.button`
  padding: 24px 32px;
  width: 100%;
  height: 80px;
  background: ${({ theme }) => theme.colors.yellow.lightestYellow};
  border: 2px dashed ${({ theme }) => theme.colors.yellow.lighterYellow};
  border-radius: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 26px;
`;
