import { AppointmentProduct, Appointment, NullableString } from 'kb-shared';

export function getAppointmentId(product: AppointmentProduct): string | null {
  switch (product.type) {
    case 'appointment':
      return product.data.id;
    case 'package':
      return null;
    case 'package_appointment':
      // @ts-ignore
      return product.data.appointment.appointmentTypeId.toString();
    case 'reschedule_appointment':
      return product.data.appointmentType.id;
    // @ts-ignore
    case 'amh_event':
      // @ts-ignore
      return product.data.id;
    default:
      return null;
  }
}

export function isVirtual(product: AppointmentProduct): boolean {
  switch (product.type) {
    case 'appointment':
      return !!product.data.virtual;
    case 'package':
      return false;
    case 'package_appointment':
      // @ts-ignore
      return !!product.data.appointment.virtual;
    case 'reschedule_appointment':
      // @ts-ignore
      return product.data.appointmentType.virtual;
    default:
      return false;
  }
}

const determineAppointmentLink = (meetingLink: NullableString, videoLink: string | null) =>
  meetingLink ? meetingLink : videoLink;

// https://github.com/InteractionDesignFoundation/add-event-to-calendar-docs/blob/master/services/google.md
type AppointmentProps = Pick<Appointment, 'appointmentType' | 'startTime' | 'endTime' | 'location'>;
export function generateCalendarLink(
  appointment: AppointmentProps,
  meetingLink: NullableString
): string {
  const { appointmentType, startTime, endTime, location } = appointment;
  // Use stripped date format for google:
  // https://stackoverflow.com/questions/41697206/google-calendar-dates-format-explanation
  const formatDate = (date: string) =>
    new Date(date)
      .toISOString()
      .split('.')[0]
      .replace(/:|-|\./g, '') + 'Z';
  const formattedStartTime = formatDate(startTime);
  const formattedEndTime = formatDate(endTime);
  const dates = `${formattedStartTime}/${formattedEndTime}`;

  const { name, description, virtual, videoLink } = appointmentType;
  const appointmentLink = determineAppointmentLink(meetingLink, videoLink);
  const address = virtual ? appointmentLink : location.address;
  const details = appointmentLink
    ? `Meeting link: <a href="${appointmentLink}">${appointmentLink}</a> \n${description}`
    : description;
  return encodeURI(
    `https://www.google.com/calendar/event?action=TEMPLATE&location=${address}&text=${name}&dates=${dates}&details=${details}&sprop=website:https://kindbody.com/`
  );
}
