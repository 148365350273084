import { BugTrackerInterface, setBugTracker } from '../utilities/bugTracker';

type ConfigOptions = {
  bugTracker: BugTrackerInterface;
};

export default function configure(options: ConfigOptions) {
  const { bugTracker } = options;
  if (bugTracker) {
    setBugTracker(bugTracker);
  }
}
