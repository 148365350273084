import { showErrorToast } from './notificationUtils';

const ALLOWED_FILE_EXTENSION = ['image/jpeg', 'image/jpg', 'image/png', 'application/pdf'];
const ALLOWED_PROFILE_IMAGE_EXTENSION = ['image/jpeg', 'image/jpg', 'image/png'];

export const isValidFileExtension = (type: string): boolean =>
  ALLOWED_FILE_EXTENSION.includes(type);

export const isValidProfileImageExtension = (type: string): boolean =>
  ALLOWED_PROFILE_IMAGE_EXTENSION.includes(type);

export const showErrorValidExtensionToast = (): void =>
  showErrorToast(
    'Error uploading file. Please upload your file in one of the allowed file types. Accepted file types are: .jpeg, .jpg,  .png, .pdf. Please contact navigator@kindbody.com if you need assistance.'
  );

export const showErrorProfileImageExtensionToast = () =>
  showErrorToast(
    'Error uploading file. Please upload your file in one of the allowed file types. Accepted file types are: .jpeg, .jpg or .png. Please contact navigator@kindbody.com if you need assistance.'
  );

export const showInvalidImageToast = (): void =>
  showErrorToast("Selected file doesn't seem to be an image!");
