import styled from 'styled-components';

export const LogoLink = styled.a<{
  $display: string;
  $justifyContent: string;
  $marginTop: string;
}>`
  display: ${({ $display }) => $display};
  justify-content: ${({ $justifyContent }) => $justifyContent};
  margin-top: ${({ $marginTop }) => $marginTop}px;
`;
