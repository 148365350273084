import { capitalize } from 'lodash';
import React, { useState } from 'react';

import { InsuranceEmbeddedForm } from 'components/InsuranceEmbeddedForm/InsuranceEmbeddedForm';
import { InsuranceProvider } from 'components/InsuranceForm/InsuranceForm.types';
import { COB_PRIORITY_CODE } from 'components/InsuranceForm/InsuranceForm.utils';
import { InsuranceInfoBox } from 'components/InsuranceInfoBox/InsuranceInfoBox';
import { InsuranceModalDelete } from 'components/InsuranceModalDelete/InsuranceModalDelete';
import { InsuranceModalForm } from 'components/InsuranceModalForm/InsuranceModalForm';
import { InsurancePlaceholder } from 'components/InsurancePlaceholder/InsurancePlaceholder';

import { ContentWrapper } from './InsuranceList.styled';
import { getInsuranceProviders } from './InsuranceList.utils';

interface Props {
  formStyle: 'embedded' | 'modal';
  insuranceProviders: InsuranceProvider[];
  onAdd?: () => void;
  onDelete?: () => void;
  onOpenForm?: () => void;
  onCloseForm?: () => void;
}

export const InsuranceList = ({
  formStyle = 'modal',
  insuranceProviders,
  onAdd = () => {},
  onDelete = () => {},
  onOpenForm = () => {},
  onCloseForm = () => {}
}: Props) => {
  const [insuranceToDelete, setInsuranceToDelete] = useState<InsuranceProvider>();
  const [COBToEditOrCreate, setCOBToEditOrCreate] = useState<COB_PRIORITY_CODE>();
  const [insuranceToEdit, setInsuranceToEdit] = useState<InsuranceProvider>();

  const title = insuranceToEdit
    ? `Edit ${capitalize(COBToEditOrCreate)} Insurance Coverage Details`
    : `Add ${capitalize(COBToEditOrCreate)} Insurance Coverage Details`;

  const openDeleteModal = (insuranceProvider: InsuranceProvider) => {
    setInsuranceToDelete(insuranceProvider);
  };

  const closeDeleteModal = () => {
    setInsuranceToDelete(undefined);
  };

  const onDeleteSuccess = () => {
    closeDeleteModal();

    onDelete();
  };

  const onAddSuccess = () => {
    closeAddForm();

    onAdd();
  };

  const closeAddForm = () => {
    setCOBToEditOrCreate(undefined);
    setInsuranceToEdit(undefined);

    onCloseForm();
  };

  const openAddForm = (
    cobPriorityCode: COB_PRIORITY_CODE,
    insuranceProvider?: InsuranceProvider
  ) => {
    setInsuranceToEdit(insuranceProvider);
    setCOBToEditOrCreate(cobPriorityCode);

    onOpenForm();
  };

  const insurances = getInsuranceProviders(insuranceProviders);

  return (
    <>
      <ContentWrapper>
        {insurances.map(insurance => {
          if (insurance.data && COBToEditOrCreate !== insurance.type) {
            return (
              <InsuranceInfoBox
                key={insurance.data.id}
                title={insurance.title}
                insurance={insurance.data}
                cobPriorityCode={insurance.data.cobPriorityCode}
                badgeLabel={insurance.label}
                disableDelete={insurance.disableDelete}
                openAddForm={openAddForm}
                openDeleteModal={() => {
                  insurance.data && openDeleteModal(insurance.data);
                }}
              />
            );
          }

          if (formStyle === 'embedded' && COBToEditOrCreate === insurance.type) {
            return (
              <InsuranceEmbeddedForm
                title={insurance.title}
                heading={title}
                insuranceProvider={insuranceToEdit}
                cobPriorityCode={insurance.type}
                onSuccess={onAddSuccess}
                onCancel={closeAddForm}
              />
            );
          }

          return (
            <InsurancePlaceholder
              key={insurance.type}
              title={insurance.title}
              cobPriorityCode={insurance.type}
              openAddForm={openAddForm}
            />
          );
        })}
      </ContentWrapper>

      <InsuranceModalDelete
        open={!!insuranceToDelete}
        insuranceProvider={insuranceToDelete}
        onCancel={closeDeleteModal}
        onDelete={onDeleteSuccess}
      />

      {formStyle === 'modal' && (
        <InsuranceModalForm
          open={!!COBToEditOrCreate}
          title={title}
          insuranceProvider={insuranceToEdit}
          cobPriorityCode={COBToEditOrCreate}
          onSuccess={onAddSuccess}
          onClose={closeAddForm}
        />
      )}
    </>
  );
};
