import { ApolloCache, FetchResult, useMutation, useQuery } from '@apollo/client';
import _merge from 'lodash/merge';

import { FORM_COMPLETION_ANSWERS } from 'kb-shared/graphql/queries';
import { BugTracker } from 'kb-shared/utilities/bugTracker';

import {
  FormCompletionQueryArguments,
  FormCompletionQueryResponse
} from '../graphql/form-completion-query';
import {
  SaveFormAnswerMutationArguments,
  SaveFormAnswerMutationResponse,
  SAVE_FORM_ANSWER_MUTATION
} from '../graphql/save-form-answer-mutation';

const updateCache = (variables: FormCompletionQueryArguments) => (
  cache: ApolloCache<SaveFormAnswerMutationResponse>,
  result: FetchResult<SaveFormAnswerMutationResponse>
) => {
  if (!result.data) {
    return;
  }

  const { answer } = result.data.createAnswer;
  const cachedData = cache.readQuery<FormCompletionQueryResponse>({
    query: FORM_COMPLETION_ANSWERS,
    variables
  });

  // If thre is no formCompletion this is the first saveAnswer call,
  // we populate formCompletion with a temporary one for instant feedback
  if (!cachedData?.formCompletion) {
    return cache.writeQuery<FormCompletionQueryResponse>({
      query: FORM_COMPLETION_ANSWERS,
      variables,
      data: {
        formCompletion: {
          id: 'tmp',
          percentageComplete: 0,
          answers: [answer],
          __typename: 'FormCompletion'
        }
      }
    });
  }

  // append answer to formCompletion response (source of truth for form answers)
  const newData = _merge({}, cachedData, {
    formCompletion: { answers: cachedData.formCompletion?.answers.concat([answer]) }
  });
  cache.writeQuery<FormCompletionQueryResponse>({
    query: FORM_COMPLETION_ANSWERS,
    variables,
    data: newData
  });
};

// instant feedback for the user with optimisticResponse
const saveAnswerOptimisticResponse = (variables: SaveFormAnswerMutationArguments) => ({
  createAnswer: {
    answer: {
      id: `tmp-${variables.formElementId}`,
      data: variables.data,
      formElementId: variables.formElementId,
      percentageComplete: 0,
      __typename: 'Answer'
    },
    __typename: 'CreateAnswerPayload'
  }
});

export const useRiskAssessmentAnswers = (formId: number) => {
  const formCompletionVariables = { formId };

  const { data } = useQuery<FormCompletionQueryResponse, FormCompletionQueryArguments>(
    FORM_COMPLETION_ANSWERS,
    {
      variables: formCompletionVariables,
      onError: error => BugTracker.notify(error, 'RiskAssessmentLoadingAnswers')
    }
  );

  const [saveAnswerMutation] = useMutation<
    SaveFormAnswerMutationResponse,
    SaveFormAnswerMutationArguments
  >(SAVE_FORM_ANSWER_MUTATION, {
    update: updateCache(formCompletionVariables),
    optimisticResponse: saveAnswerOptimisticResponse,
    refetchQueries: [{ query: FORM_COMPLETION_ANSWERS, variables: formCompletionVariables }],
    onError: error => BugTracker.notify(error, 'RiskAssessmentSaveAnswer')
  });

  const saveAnswer = (formFieldId: number, answer: boolean) =>
    saveAnswerMutation({
      variables: { formId, formElementId: formFieldId, data: answer.toString() }
    });

  const answers = data?.formCompletion?.answers ?? [];
  const answerByFieldId = answers.reduce((acc, answer) => {
    if (answer) {
      acc[answer.formElementId] = answer.data === 'true';
    }
    return acc;
  }, {} as Record<string, boolean>);

  return { answerByFieldId, saveAnswer };
};
