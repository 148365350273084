import React from 'react';

import { AppointmentProduct, AppointmentType } from 'kb-shared';
import { isRequiredToBeInTheSameState } from 'kb-shared/utilities/appointment.helper';

import { DisclaimerText } from '../ConfirmOrder.styled';

type Props = {
  product: AppointmentProduct<AppointmentType> | null;
};

export const LicensedProviderDisclaimer = ({ product }: Props) => {
  const appointmentIsRequiredToBeInTheSameState =
    product?.type === 'appointment' && isRequiredToBeInTheSameState(product.data);

  if (!appointmentIsRequiredToBeInTheSameState) return null;

  return (
    <DisclaimerText>
      <b>
        During your virtual visit, you will need to be physically in the state that you selected
        when you signed up for a Kindbody account.
      </b>{' '}
      Kindbody providers are licensed to practice in certain states, and your appointment may need
      to be rescheduled if you are in a different location than originally specified at the time of
      your visit. Please make sure you are in a quiet place with enough privacy to discuss personal
      details about your health.
    </DisclaimerText>
  );
};
