import * as React from 'react';
import Media from 'react-media';

import { themes } from 'kb-shared';

import DesktopNavigator from './DesktopNavigator';
import MobileNavigator from './MobileNavigator';

export type Theme = {
  tab: {
    selectedBG: string;
    deselectedBG: string;
    selectedTitle: string;
    deselectedTitle: string;
    fullWidthTabs: boolean; // Should Tabs fill full width or just size to fit on Desktop layout.
  };
  content: {
    selectedBG: string;
    deselectedBG: string;
    borderColor?: string;
  };
};

type Props<T> = {
  currentIndex: number;
  items: Array<T>;
  getSectionTitle: (index: number, item: T) => string;
  renderContent: (index: number, item: T) => React.ReactNode | null;
  getUniqueKey: (index: number, item: T) => string;
  onSelect: (index: number, item: T) => void;
  theme: Theme;
  size?: string;
};

export default class TabNavigator<T> extends React.Component<Props<T>> {
  static defaultProps = {
    currentIndex: 0,
    items: [],
    getSectionTitle: () => '',
    renderContent: () => {
      return null;
    },
    getUniqueKey: () => '',
    onSelect: () => {},
    theme: {
      tab: {
        selectedBG: themes.deprecatedColors.beige,
        deselectedBG: themes.colors.yellow.lightestYellow,
        selectedTitle: themes.colors.neutral.primaryNavy,
        deselectedTitle: themes.colors.neutral.navy,
        fullWidthTabs: true
      },
      content: {
        selectedBG: themes.deprecatedColors.beige,
        deselectedBG: themes.colors.yellow.lightestYellow
      }
    }
  };

  render() {
    const {
      currentIndex,
      items,
      getSectionTitle,
      renderContent,
      getUniqueKey,
      onSelect,
      theme,
      size
    } = this.props;
    return (
      <div>
        <Media query={{ minWidth: themes.breakpoints.raw.tabletLandscape }}>
          {matches => {
            if (matches) {
              return (
                <DesktopNavigator
                  items={items}
                  currentIndex={currentIndex}
                  getSectionTitle={getSectionTitle}
                  getUniqueKey={getUniqueKey}
                  renderContent={renderContent}
                  onSelect={onSelect}
                  theme={theme}
                  size={size}
                />
              );
            } else {
              return (
                <MobileNavigator
                  items={items}
                  currentIndex={currentIndex}
                  getSectionTitle={getSectionTitle}
                  getUniqueKey={getUniqueKey}
                  renderContent={renderContent}
                  onSelect={onSelect}
                  theme={theme}
                />
              );
            }
          }}
        </Media>
      </div>
    );
  }
}
