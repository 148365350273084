import styled from 'styled-components';

export const SubTitleContainer = styled.div`
  width: 100%;
  text-align: center;
  margin-bottom: 32px;
  display: flex;
  flex-direction: column;
  align-items: center;

  > div {
    margin-top: 4px;
    margin-bottom: 4px;
  }
`;

export const Form = styled.form`
  width: 100%;
`;

export const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding-top: 40px;
`;
