import React from 'react';
import DatePicker from 'react-datepicker';

import 'react-datepicker/dist/react-datepicker.css';
import { getInputStates } from '../../utils';
import { TextInputStyled } from '../TextInput/TextInput.styled';
import { DEFAULT_DATE_FORMAT } from './DateInput.constants';
import { CalendarIcon, DateInputStyled } from './DateInput.styled';
import { Props } from './DateInput.types';

export const DateInput = ({
  id,
  labeldId,
  value,
  status = 'default',
  placeholder,
  required = false,
  autoComplete,
  dateFormat = DEFAULT_DATE_FORMAT,
  blockFutureDays,
  onChange,
  onBlur,
  onKeyDown,
  onFocus
}: Props) => {
  const selectedDate = value ? new Date(value) : null;
  const isFilled = Boolean(value);
  const { isDisabled, isReadOnly, isValid } = getInputStates(status);
  const TODAY = new Date();

  return (
    <DateInputStyled status={status} isFilled={isFilled}>
      <DatePicker
        id={id}
        className="v2"
        showIcon
        customInput={<TextInputStyled status={status} isFilled={isFilled} />}
        disabled={isDisabled}
        selected={selectedDate}
        readOnly={isReadOnly}
        ariaInvalid={isValid ? 'false' : 'true'}
        ariaLabelledBy={labeldId}
        ariaRequired={`${required}`}
        placeholderText={placeholder}
        autoComplete={autoComplete}
        dateFormat={dateFormat}
        onBlur={onBlur}
        onChange={value => onChange?.(value)}
        onKeyDown={onKeyDown}
        onFocus={onFocus}
        popperPlacement="bottom"
        peekNextMonth
        showMonthDropdown
        showYearDropdown
        dropdownMode="select"
        yearDropdownItemNumber={60}
        scrollableYearDropdown
        icon={<CalendarIcon />}
        maxDate={blockFutureDays ? TODAY : null}
      />
    </DateInputStyled>
  );
};
