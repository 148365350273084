import { themes } from 'kb-shared';
import styled from 'styled-components';

export const CycleVisitContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background-color: ${themes.colors.neutral.primaryNavy};
  padding: 1.5em;
  background-image: url("data:image/svg+xml,%3Csvg width='315' height='108' viewBox='0 0 315 108' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Ctitle%3EHalf circle illustration%3C/title%3E%3Cpath opacity='0.24' fill-rule='evenodd' clip-rule='evenodd' d='M310.241 31.099L315 23.4271L281.308 -1.4727e-06L275.756 8.91461C247.069 54.9875 203.979 81.4083 157.533 81.4083C110.956 81.4083 67.8616 55.1107 39.3031 9.26324L33.8312 0.479376L-1.0197e-06 23.328L4.82278 31.0991C30.8499 73.0258 78.4772 123 157.533 123C236.593 123 284.215 73.0258 310.241 31.0991L310.241 31.099Z' fill='%236464CE'/%3E%3C/svg%3E");
  background-repeat: no-repeat;

  @media ${themes.queries.mobile} {
    background-position: top 16px right -40px;

    > button {
      width: 100%;
      margin-top: 1.5em;
    }
  }

  @media ${themes.queries.minTablet} {
    padding: 1.5em 2em;
    margin-top: 1.5em;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    box-shadow: ${themes.shadows.card.level0};

    background-position: top 16px right 10px;
  }

  @media ${themes.queries.minDesktop} {
    border-radius: 8px;
    background-position: top 16px right 120px;
    box-shadow: 0px 0px 0px 6px rgba(100, 100, 206, 0.12), 0px 1px 3px 0px rgba(39, 42, 94, 0.16),
      0px 0px 0px 1px rgba(39, 42, 94, 0.1), 0px 1px 0.5px 0px rgba(39, 42, 94, 0.16);
    margin-bottom: 0.5em;
  }
`;

export const CycleVisitTextContainer = styled.div`
  display: flex;
  flex-direction: column;

  @media ${themes.queries.minTablet} {
    margin-right: 1em;
  }
`;
