import React, { useState } from 'react';

import { ConsentModal } from 'components/ConsentModal/ConsentModal';
import { Text } from 'components/v2/Typography';

import { TermsOfUseContainer } from './TermsOfUse.styled';

export const TermsOfUse = () => {
  const [openedTermOfUseModal, setOpenedTermOfUseModal] = useState<boolean>(false);
  const [openedPrivacyPolicy, setOpenedPrivacyPolicy] = useState<boolean>(false);

  return (
    <TermsOfUseContainer>
      <div>
        <Text tag="span" size="sm">
          By signing up, I agree to the{' '}
          <span onClick={() => setOpenedPrivacyPolicy(true)}>Kindbody Privacy Policy</span> &{' '}
          <span onClick={() => setOpenedTermOfUseModal(true)}>Terms of Service</span>
        </Text>
      </div>
      <ConsentModal
        id="114"
        open={openedTermOfUseModal}
        title="Term of Use"
        close={() => setOpenedTermOfUseModal(false)}
      />
      <ConsentModal
        id="116"
        open={openedPrivacyPolicy}
        title="Privacy Policy"
        close={() => setOpenedPrivacyPolicy(false)}
      />
    </TermsOfUseContainer>
  );
};
