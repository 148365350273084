import { MedicationInstruction } from './MedicationsWidget.types';

export const medicationInstructionsMock = ([
  {
    title: 'Day 1',
    data: {
      acknowledged: null,
      date: '',
      dateLabel: 'Today',
      dayNumber: 1,
      haveIntercourse: false,
      id: '1',
      instructions: '',
      updatedAt: new Date().toISOString(),
      drugs: [
        {
          amount: '_ mg',
          dailyRecurrence: 1,
          id: '1',
          name: 'Medication 1',
          timeOfDay: null
        },
        {
          amount: '_ IU',
          dailyRecurrence: 1,
          id: '2',
          name: 'Medication 2',
          timeOfDay: null
        },
        {
          amount: '_ mcg',
          dailyRecurrence: 1,
          id: '3',
          name: 'Medication 3',
          timeOfDay: null
        },
        {
          amount: '_ mcg',
          dailyRecurrence: 1,
          id: '4',
          name: 'Medication 4',
          timeOfDay: null
        }
      ]
    }
  },
  {
    title: 'Day 2',
    data: {
      acknowledged: null,
      date: '',
      dateLabel: 'Tomorrow',
      dayNumber: 2,
      haveIntercourse: false,
      id: '2',
      instructions: '',
      updatedAt: new Date().toISOString(),
      drugs: [
        {
          amount: '_ mcg',
          dailyRecurrence: 1,
          id: '3',
          name: 'Medication 3',
          timeOfDay: null
        }
      ]
    }
  },
  {
    title: 'Day 3',
    data: {
      acknowledged: null,
      date: '',
      dateLabel: 'Next day',
      dayNumber: 3,
      haveIntercourse: false,
      id: '3',
      instructions: '',
      updatedAt: new Date().toISOString(),
      drugs: [
        {
          amount: '_ IU',
          dailyRecurrence: 1,
          id: '2',
          name: 'Medication 2',
          timeOfDay: null
        }
      ]
    }
  }
] as unknown) as Array<MedicationInstruction>;
