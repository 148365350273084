import React from 'react';

import {
  Text,
  StepperBackground,
  Stepper,
  TextRow,
  Container,
  TextRowContainer,
  TextRowContainerMargin
} from './StepTracker.styles';
import { Props } from './StepTracker.types';

const StepTracker = ({ steps, selectedStep, onSelectStep }: Props) => (
  <Container>
    <TextRowContainer>
      <Stepper currentIndex={selectedStep} />
      <StepperBackground />
    </TextRowContainer>
    <TextRowContainerMargin>
      {steps?.map((step: string, index: number) => {
        const clickable = index < selectedStep;
        return (
          <TextRow
            clickable={clickable}
            key={step}
            onClick={() => {
              if (clickable) {
                onSelectStep(index);
              }
            }}
          >
            <Text selected={index === selectedStep}>{step}</Text>
          </TextRow>
        );
      })}
    </TextRowContainerMargin>
  </Container>
);

export default StepTracker;
