import { gql, QueryResult } from '@apollo/client';
import get from 'lodash.get';
import React from 'react';
import { useHistory } from 'react-router-dom';

import { Query } from '@apollo/client/react/components';
import { Button } from 'components/v2/Buttons/Button';
import styled from 'styled-components';

import { medicationCheckmark as checkmark } from '../../../assets/icons';
import { CheckIcon, Description, FlexWrapper, Title } from './styled-components';

type TodoResult = QueryResult<{
  patientTodos: {
    id: string;
    todoId: string;
    title: string;
    linkUrl: string;
    description: string;
    completed: string;
    order: unknown;
  };
}>;

const TODOS = gql`
  query patientTodos {
    patientTodos {
      id
      todoId
      title: name
      linkUrl
      buttonText
      description
      completed
      order
    }
  }
`;

const ReviewTodos = () => {
  const history = useHistory();

  return (
    <Query query={TODOS}>
      {({ data, error, loading }: TodoResult) => {
        if (loading || error || !data) {
          return null;
        }
        const patientTodos = get(data, 'patientTodos', []);
        // @ts-ignore
        const completed = patientTodos.length === 0;

        return (
          <>
            <Title>
              Review to-dos on your dashboard
              {completed && <CheckIcon src={checkmark} alt="check" />}
            </Title>
            <FlexWrapper>
              <div>
                <Description>
                  Your care team has selected the next steps necessary for your care plan. These are
                  ready for your review on your dashboard.
                </Description>
              </div>
              {!completed && (
                <ButtonStyled label="View dashboard" onClick={() => history.push('/')} />
              )}
            </FlexWrapper>
          </>
        );
      }}
    </Query>
  );
};

export default ReviewTodos;

const ButtonStyled = styled(Button).attrs({ category: 'secondary', size: 'md' })`
  min-width: fit-content;
  height: fit-content;
  width: fit-content;
  white-space: nowrap;
`;
