export type Params = {
  appointmentPublicIdentifier: string;
};

export enum Status {
  Open = 'open',
  NotOpenYet = 'not_open_yet',
  Ended = 'ended',
  Error = 'error'
}

export enum Software {
  Zoom = 'zoom',
  GoogleMeet = 'google_meet'
}

export type MeetingInfo = {
  realMeetingUrl: string | undefined;
  uiMessage: string;
  status: Status;
  software: Software;
};
