import React from 'react';

import { Text } from 'components/v2/Typography';

export const ListItem = ({ item }: { item: string }) => {
  if (!item) return null;

  return (
    <>
      <Text fontStyle="regular" size="md">
        {item}
      </Text>
    </>
  );
};
