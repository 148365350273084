import { LinkButton } from 'components/v2/Buttons/LinkButton/LinkButton';
import styled from 'styled-components';

export const EnterInsuranceDetailsBox = styled.div`
  padding: 24px 24px;
  width: 100%;
  height: 112px;
  background: ${({ theme }) => theme.colors.neutral.lavenderWhite};
  border: 1px dashed ${({ theme }) => theme.colors.neutral.lighterNavy};
  border-radius: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 26px;
  margin-top: 20px;
`;

export const OpenInsuranceModalButton = styled(LinkButton).attrs({
  size: 'lg',
  underline: 'none'
})`
  background-color: ${({ theme }) => theme.colors.neutral.lavenderWhite};
  text-transform: uppercase;
`;
