import React from 'react';

import { ReactComponent as HandshakeIcon } from 'assets/icons/MyProfile/handshake.svg';
import { Heading } from 'components/v2/Typography';
import { Text } from 'components/v2/Typography';
import { Patient, PatientTrustee } from 'kb-shared';
import TrusteesFields from 'screens/MyProfile/components/TrusteesFields';

import { Container } from './shared.styled';
import { SectionHeader } from './Trustees.styled';

export interface TrusteesProps {
  patient: Patient;
}

export function Trustees(props: TrusteesProps) {
  const { patient } = props;
  const patientTrustees: Array<PatientTrustee | null> = patient?.patientTrustees ?? [];
  const formatedTrustees = patientTrustees.concat([null, null, null]).slice(0, 3);

  return (
    <Container>
      <div>
        <SectionHeader>
          <HandshakeIcon />
          <Heading tag="h2" styledAs="h4">
            Allow others to view your health information
          </Heading>
        </SectionHeader>

        <Text>
          I do hereby assign the following persons as trustees of my personal health information and
          I acknowledge that they may have access my medical information at any time:
        </Text>
      </div>

      {formatedTrustees.map((trustee, index: number) => (
        <TrusteesFields
          id={trustee?.id}
          index={index}
          name={trustee?.name}
          relationship={trustee?.relationship}
          phone={trustee?.phone}
          state={trustee?.state}
          key={trustee?.id ?? `tmp-${index}`}
          position={index + 1}
          email={trustee?.email}
          preferredContacts={trustee?.preferredContacts}
        />
      ))}
    </Container>
  );
}
