import debounce from 'lodash.debounce';
import React, { ChangeEvent, FormEvent, useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { PaperPlane, Paperclip } from 'assets/icons/Icons';
import { useQueryParams } from 'hooks/useQueryParams';
import { BugTracker } from 'kb-shared/utilities/bugTracker';
import { UploadFileModal } from 'screens/Documents/components/UploadFileModal/UploadFileModal';
import { showErrorToast } from 'utilities/notificationUtils';
import { pageUrl } from 'utilities/pageUrl';

import { CreateConversation, CreateMessage } from '../Conversation/Conversation.types';
import { FilePreviewList } from '../FilePreviewList/FilePreviewList';
import {
  AttachmentButton,
  Buttons,
  Container,
  InputContainer,
  SendButton,
  SubjectInput,
  TextArea,
  TextAreaWrap
} from './Inputs.styled';
import { MessageAttachment, Props } from './Inputs.types';

export const Inputs = ({ send, conversationId, categoryName, purposes }: Props) => {
  const queryString = useQueryParams();
  const history = useHistory();
  const [creatingConversation, setCreatingConversation] = useState<boolean>(false);
  const [message, setMessage] = useState<string>(queryString.get('message') || '');
  const [subject, setSubject] = useState<string>(queryString.get('subject') || '');
  const [attachments, setAttachments] = useState<MessageAttachment[]>([]);
  const [inputFocused, setInputFocused] = useState(false);
  const textareaRef = useRef<HTMLTextAreaElement>(null);
  const [selectedFiles, setSelectedFiles] = useState<File[]>([]);
  const [fileDescriptionModalOpened, setFileDescriptionModalOpened] = useState(false);

  const resetInputs = () => {
    setMessage('');
    setAttachments([]);
  };

  const sendMessage = async (parameters: CreateMessage) => {
    // clean up the input first is important to avoid to keep the message in the textbox
    // when it's sendint the message. The other way around It will keep the message
    // and cleaning it only when the message is successfully sent.
    resetInputs();
    await send(parameters, attachments);
  };

  const createNewConversation = async (parameters: CreateConversation) => {
    const response = await send(parameters, attachments);

    if (!response) return;

    const url = pageUrl.messages({
      categoryName: categoryName,
      conversationId: response.data.createConversation.conversation.id
    });

    history.push(url);
  };

  const prepareOnClick = () => {
    return conversationId ? onClickToSendMessage : onClickToCreateConversation;
  };

  const onClickToCreateConversation = debounce(() => {
    try {
      if (subject.trim() === '') return showErrorToast('Subject is required!');
      if (message.trim() === '') return showErrorToast('Message is required!');
      if (creatingConversation) return;

      const parameters = {
        category: categoryName,
        subject: subject,
        body: message
      };
      setCreatingConversation(true);
      createNewConversation(parameters);
    } catch (error) {
      setCreatingConversation(false);
      BugTracker.notify(error, 'Create New Conversation');
      showErrorToast("We're sorry something unexpected happened. Please try again.");
    }
  }, 300);

  const onClickToSendMessage = () => {
    try {
      if (!conversationId) return;
      if (message.trim() === '') return showErrorToast('Message is required!');

      const parameters = {
        conversationId: conversationId,
        body: message
      };

      sendMessage(parameters);
    } catch (error) {
      BugTracker.notify(error, 'Send Message');
      showErrorToast("We're sorry something unexpected happened. Please try again.");
    }
  };

  const onChangeFiles = (e: ChangeEvent<HTMLInputElement>) => {
    const { files } = e.target;
    if (!files?.length) return;

    const predictedNumberOfFile = attachments.length + files.length;

    if (predictedNumberOfFile > 10) {
      showErrorToast(' You excided the maximum number file per message which is 10.');
      e.target.value = '';
      return;
    }

    setSelectedFiles(Array.from(files));
    setFileDescriptionModalOpened(true);
    e.target.value = '';
  };

  const upload = async (filesPurpose: string | undefined, filesDescription: string) => {
    setFileDescriptionModalOpened(false);
    const filesWithDescription = selectedFiles.map(file => {
      return { file: file, purpose: filesPurpose, description: filesDescription };
    });
    setAttachments([...attachments, ...filesWithDescription]);
    setSelectedFiles([]);
  };

  const onDeleteAttachment = (index: number) => {
    const newAttachments = [...attachments];
    newAttachments.splice(index, 1);

    setAttachments(newAttachments);
  };

  const onChangeInput = (e: FormEvent<HTMLTextAreaElement>) => {
    setMessage(e.currentTarget.value);
    adjustTextAreaHeight();
  };

  const adjustTextAreaHeight = () => {
    const textarea = textareaRef.current;
    if (!textarea) return;

    if (textarea.scrollHeight <= 180) {
      textarea.style.height = 'auto';
      textarea.style.height = textarea.scrollHeight + 'px';
      textarea.style.overflowY = 'hidden';
    } else {
      textarea.style.overflowY = 'auto';
      textarea.style.height = '180px';
    }
  };

  const onFocusInput = () => {
    setInputFocused(true);
  };

  const onBlurInput = () => {
    setInputFocused(false);
  };

  useEffect(() => {
    if (message === '') {
      adjustTextAreaHeight();
    }
  }, [message]);

  return (
    <Container>
      {attachments.length > 0 && (
        <FilePreviewList
          attachments={attachments.map(attachment => attachment.file)}
          onDeleteAttachment={onDeleteAttachment}
        />
      )}

      {!conversationId && (
        <SubjectInput
          type="text"
          placeholder="Subject (required)"
          value={subject}
          autoFocus={!conversationId}
          onChange={e => setSubject(e.currentTarget.value)}
        />
      )}

      <InputContainer>
        <TextAreaWrap focused={inputFocused}>
          <TextArea
            placeholder="Start typing..."
            rows={1}
            ref={textareaRef}
            value={message}
            onFocus={onFocusInput}
            onChange={onChangeInput}
            onBlur={onBlurInput}
            autoFocus={!!conversationId}
          />
        </TextAreaWrap>

        <Buttons>
          <AttachmentButton htmlFor="input-upload-file" data-testid="attach-files">
            <input
              id="input-upload-file"
              type="file"
              onChange={onChangeFiles}
              accept=".jpeg, .jpg, .png, .pdf"
              multiple
            />
            <Paperclip type="regular" />
          </AttachmentButton>
          <SendButton
            onClick={prepareOnClick()}
            category="secondary"
            size="md"
            leftIcon={<PaperPlane type="regular" />}
            data-testid="send-message"
            aria-label="Send Message"
          />
        </Buttons>
      </InputContainer>

      {fileDescriptionModalOpened && (
        <UploadFileModal
          open={true}
          purposes={purposes?.fileUploadPurposes}
          onClose={() => setFileDescriptionModalOpened(false)}
          onDescriptionEntered={upload}
        />
      )}
    </Container>
  );
};
