import { gql, useQuery } from '@apollo/client';

import { utils } from 'kb-shared';
import { getLabInfo } from 'kb-shared/utilities/kindbody_contacts';
import { isPortlandPatient } from 'kb-shared/utilities/lab.helper';
import { formatPhoneNumber } from 'kb-shared/utilities/phones';

import { usePatient } from './usePatient';

type ResponseData = {
  journeyType: string;
};

const CONTACT_INFO = gql`
  query contactInfo {
    journeyType
  }
`;

export const useContactInfo = () => {
  const { patient, isLoggedIn } = usePatient();

  const defaultLabInfo = getLabInfo();
  const marketId = patient?.lab?.id || '';

  const { data, error } = useQuery<ResponseData>(CONTACT_INFO, {
    skip: !isLoggedIn
  });

  if (!isLoggedIn) return defaultLabInfo;

  const info = getLabInfo(marketId);

  const employer = patient?.patientMembership?.employer;
  const employerPhone = patient?.patientMembership?.employerPhone;

  if (employer) {
    // portland patients should use the default market number
    const phoneNumber = !isPortlandPatient(marketId)
      ? employerPhone || defaultLabInfo.number
      : info.number;

    return {
      ...info,
      email: utils.KBContacts.employeeBenefitsEmail,
      number: phoneNumber,
      numberLabel: formatPhoneNumber(phoneNumber)
    };
  }

  if (isPortlandPatient(marketId)) {
    return getLabInfo(marketId);
  }

  if (!data || error || !data.journeyType) {
    return {
      ...info,
      email: defaultLabInfo.email,
      number: defaultLabInfo.number,
      numberLabel: defaultLabInfo.numberLabel
    };
  }

  return {
    ...info
  };
};

export default useContactInfo;
