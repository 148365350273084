import React, { useState } from 'react';

import { Answer } from 'kb-shared';

import AnimatedWrapper from '../../AnimatedWrapper';
import ChoiceButton from '../../ChoiceButton';
import FormButton from '../../FormButton';
import Text from '../../Primitives/FormText';
import { Container, ButtonContainer, ButtonWrapper } from './MultipleSelectionForm.styled';

export const MultipleSelectionForm = ({
  title = '',
  options = [],
  onContinue,
  onSave,
  answer,
  isRequired
}: {
  title: string;
  options: Array<string>;

  onContinue?: () => void;
  onSave: (response: Array<string> | undefined) => void;

  answer?: Answer | null;
  isRequired?: boolean | null;
}) => {
  const [selectedOptions, setSelectedOptions] = useState<string[]>(answer?.dataArray || []);

  const selectOption = (option: string) => {
    if (selectedOptions.find(select => select === option)) {
      const newOptions = selectedOptions.filter(selected => {
        return selected !== option;
      });
      setSelectedOptions(newOptions);

      onSave(newOptions.length === 0 ? undefined : newOptions);
    } else {
      const newOptions = selectedOptions.concat([option]);
      setSelectedOptions(newOptions);

      onSave(newOptions);
    }
  };

  const renderOptions = () => {
    return options.map((option: string) => {
      const selected = selectedOptions.find(selected => selected === option);
      return (
        <ButtonWrapper key={option}>
          <ChoiceButton text={option} selected={!!selected} onClick={() => selectOption(option)} />
        </ButtonWrapper>
      );
    });
  };

  return (
    <Container>
      <Text isRequired={isRequired}>{title}</Text>
      <ButtonContainer>{renderOptions()}</ButtonContainer>
      <AnimatedWrapper isHidden={!onContinue || !selectedOptions?.length}>
        <FormButton
          text={'Continue'}
          onClick={() => {
            onContinue && onContinue();
          }}
        />
      </AnimatedWrapper>
    </Container>
  );
};
