const appointmentsOffer = [
  'Fertility assessments, egg freezing, and IVF',
  'Gynecological services including well-woman check-ups, urgent care, and contraceptive & preconception counseling',
  'Therapy sessions & wellness coaching for fertility-related concerns and overall wellness'
];

export const componentTitle = 'Book your next appointment';
export const componentDescription =
  'We provide a complete suite of clinical care & coaching specially designed for women:';

export function getAppointmentsOfferString(): string {
  return appointmentsOffer.join('\n');
}
