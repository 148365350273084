import { gql } from '@apollo/client';

export const MY_JOURNEY_QUERY = gql`
  query myJourney {
    journeyType

    appointments: journeyAppointments {
      id
      startTime
      endTime
      timeZone

      appointmentType {
        id
        channel
      }
    }

    results {
      afc: follicleCounts {
        date
      }
      labs {
        date
      }
    }

    patientTodos {
      id
      todoId
      title: name
      linkUrl
      buttonText
      description
      completed
    }

    cyclePlan {
      id
      finished
      expectedDeliveryDate
      deliveryDate
    }
  }
`;

export const CYCLE_VISIT_DAYS = gql`
  query cycleVisitDays {
    cycleVisitDays

    cycleVisits {
      id
      startTime
    }
  }
`;
