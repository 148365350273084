import { Text } from 'components/v2/Typography/Text';
import styled, { css } from 'styled-components';

import { Props, IconContainerProps } from './Label.types';

export const IconContainer = styled.span<IconContainerProps>`
  display: inline-flex;

  ${({ size }) => {
    if (size === 'lg') {
      return `font-size: 16px;`;
    }

    if (size === 'sm') {
      return `font-size: 10px;`;
    }

    return `
      font-size: 12px;
    `;
  }};
`;

export const LabelText = styled(Text)``;

const typeCss = css<Props>`
  ${({ type, size, theme }) => {
    switch (type) {
      case 'information':
        return css`
          background-color: ${theme.colors.blue.lighterBlue};
          border: ${size === 'xs' ? `1px solid ${theme.colors.blue.darkBlue}` : `none`};
          color: ${theme.colors.blue.darkBlue};

          ${LabelText} {
            color: ${theme.colors.blue.darkBlue};
          }
        `;
      case 'success':
        return css`
          background-color: ${theme.colors.green.lightestGreen};
          border: ${size === 'xs' ? `1px solid ${theme.colors.green.darkestGreen}` : `none`};
          color: ${theme.colors.green.darkestGreen};

          ${LabelText} {
            color: ${theme.colors.green.darkestGreen};
          }
        `;
      case 'error':
        return css`
          background-color: ${theme.colors.semantic.lightPink};
          border: ${size === 'xs' ? `1px solid ${theme.colors.semantic.darkRed}` : `none`};
          color: ${theme.colors.semantic.darkRed};

          ${LabelText} {
            color: ${theme.colors.semantic.darkRed};
          }
        `;
      case 'warning':
        return css`
          background-color: ${theme.colors.yellow.lighterYellow};
          border: 1px solid ${theme.colors.yellow.darkYellow};
          color: ${theme.colors.neutral.primaryNavy};

          ${LabelText} {
            color: ${theme.colors.neutral.primaryNavy};
          }
        `;
      case 'neutral':
        return css`
          background-color: ${theme.colors.neutral.lavenderLight};
          border: ${size === 'xs' ? `1px solid ${theme.colors.neutral.lighterNavy}` : `none`};
          color: ${theme.colors.neutral.primaryNavy};

          ${LabelText} {
            color: ${theme.colors.neutral.primaryNavy};
          }
        `;
      default:
        return '';
    }
  }}
`;

const paddingCss = css<Props>`
  ${({ icon, iconLeft, iconRight, size }) => {
    if (icon) {
      switch (size) {
        case 'xs':
          return `padding: 2px;`;
        case 'sm':
          return `padding: 4px 6px;`;
        case 'md':
          return `padding: 6px`;
        case 'lg':
          return `padding: 8px;`;
        default:
          return `padding: 0;`;
      }
    }
    if (iconLeft) {
      switch (size) {
        case 'sm':
          return `padding: 0 8px 0 4px;`;
        case 'md':
          return `padding: 2px 8px 2px 4px;`;
        case 'lg':
          return `padding: 6px 16px;`;
        default:
          return `padding: 0;`;
      }
    }
    if (iconRight) {
      switch (size) {
        case 'sm':
          return `padding: 0 4px 0 8px;`;
        case 'md':
          return `padding: 2px 4px 2px 8px;`;
        case 'lg':
          return `padding: 6px 16px;`;
        default:
          return `padding: 0;`;
      }
    }

    // handle text cases
    switch (size) {
      case 'xs':
        return `padding: 0;`;
      case 'sm':
        return `padding: 0 8px;`;
      case 'md':
        return `padding: 2px 16px;`;
      case 'lg':
        return `padding: 6px 16px;`;
      default:
        return `padding: 2px 16px;`;
    }
  }};
`;

export const Container = styled.span<Props>`
  display: inline-flex;
  border-radius: 4px;
  align-items: center;
  gap: 6px;
  width: ${({ width }) => (width === 'full' ? '100%' : 'fit-content')};
  ${typeCss}
  ${paddingCss}

  ${LabelText} {
    font-weight: 500;
    line-height: 20px;
  }
`;
