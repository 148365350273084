import { gql } from '@apollo/client';

export const CREATE_PATIENT_ENTERPRISE_MEMBERSHIP = gql`
  mutation createPatientEnterpriseMembership(
    $employerAccessCode: String!
    $employeeAccessCode: String!
    $employeeFirstName: String!
    $employeeLastName: String!
    $employeeDateOfBirth: String!
  ) {
    createPatientEnterpriseMembership(
      employerAccessCode: $employerAccessCode
      employeeAccessCode: $employeeAccessCode
      employeeFirstName: $employeeFirstName
      employeeLastName: $employeeLastName
      employeeDateOfBirth: $employeeDateOfBirth
    ) {
      patientMembership {
        id
        employerName
      }
    }
  }
`;
