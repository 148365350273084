import { useEffect, useMemo, useState } from 'react';

import { useOnWindowResize } from '../../../hooks/useOnWindowResize';
import { HANDLE_RESIZE_DELAY, INITIAL_REFRESH_DELAY } from './DeprecatedTabs.constants';
import { NavBounds } from './DeprecatedTabs.types';
import {
  getBackwardMoveOffset,
  getForwardMoveOffset,
  getMaximalOffset,
  getNavBoundaries
} from './DeprecatedTabs.utils';

export const useWindowResizeHandler = ({
  tabListRef,
  navItemsSelector
}: {
  tabListRef: React.RefObject<HTMLUListElement>;
  navItemsSelector: string;
}) => {
  const [inOverflowMode, setInOverflowMode] = useState(false);
  const [offset, setOffset] = useState(0);
  const [widths, setWidths] = useState<NavBounds>({
    items: [],
    itemsTotalWidth: 0,
    containerWidth: 0
  });
  const maximalOffset = useMemo(() => getMaximalOffset(widths), [widths]);
  const isAtStart = offset === 0;
  const isAtEnd = offset === maximalOffset;

  const moveForward = () => {
    const forwardOffset = getForwardMoveOffset(offset, widths);
    const maximalOffset = getMaximalOffset(widths);
    const newOffset = Math.min(maximalOffset, forwardOffset);
    setOffset(newOffset);
  };

  const moveBackward = () => {
    const backwardOffset = getBackwardMoveOffset(offset, widths);
    const newOffset = Math.max(0, backwardOffset);
    setOffset(newOffset);
  };

  const rectifyOffset = (widths: NavBounds) => {
    const maximalOffset = getMaximalOffset(widths);
    if (offset > maximalOffset) {
      setOffset(maximalOffset);
    }
  };

  const refreshWidths = () => {
    if (!tabListRef.current) {
      return;
    }
    const widths = getNavBoundaries(tabListRef.current, navItemsSelector);
    const oversizeNavItems = Math.round(widths.containerWidth) < Math.round(widths.itemsTotalWidth);

    if (oversizeNavItems) {
      setInOverflowMode(true);
    } else {
      setInOverflowMode(false);
    }

    setWidths(widths);
    rectifyOffset(widths);
  };

  useOnWindowResize(() => {
    refreshWidths();
  }, HANDLE_RESIZE_DELAY);

  // get initial widths
  useEffect(() => {
    // wait for tab items to render
    setTimeout(() => {
      refreshWidths();
    }, INITIAL_REFRESH_DELAY);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // update offset on change
  useEffect(() => {
    if (!tabListRef.current) {
      return;
    }

    tabListRef.current.style.marginLeft = `${-offset}px`;
  }, [tabListRef, offset]);

  // reset offset when no longer in narrow mode
  useEffect(() => {
    if (!inOverflowMode) {
      setOffset(0);
    }
  }, [inOverflowMode, setOffset]);

  return {
    inOverflowMode,
    moveForward,
    moveBackward,
    isAtStart,
    isAtEnd
  };
};
