import React from 'react';
import Select from 'react-select';

import Text from '../../Primitives/FormText';
import { Container, SelectContainer, SelectStyles } from './DropDownForm.styled';
import { DropDownFormProps } from './DropDownForm.types';

export const DropDownForm = ({ title, answer, onSubmit, options }: DropDownFormProps) => {
  return (
    <Container>
      <Text>{title}</Text>
      <SelectContainer>
        <Select
          value={{ value: answer, label: answer }}
          placeholder={title}
          options={options.map(option => ({ value: option, label: option }))}
          onChange={e => {
            if (!e?.value) return;
            onSubmit(e.value);
          }}
          styles={SelectStyles}
        />
      </SelectContainer>
    </Container>
  );
};
