import moment, { Moment } from 'moment';

import { TimeSlot } from 'kb-shared';

export const requiresPointerToDoctorPages = (appointmentTypeId: number): boolean => {
  return (
    appointmentTypeId === 93 ||
    appointmentTypeId === 143 ||
    appointmentTypeId === 351 ||
    appointmentTypeId === 157
  );
};

export const cleanResults = (data: Array<TimeSlot>) => {
  return data.reduce((allSlots: Array<TimeSlot>, currentTimeSlot: TimeSlot) => {
    const utcStartTime = moment(currentTimeSlot.startTime).utc();

    const hasTimeSlot = allSlots.find(timeSlot => utcStartTime.isSame(timeSlot.utcStartTime));

    if (!hasTimeSlot) {
      allSlots.push({
        ...currentTimeSlot,
        utcStartTime
      });
    }
    return allSlots;
  }, []);
};

export const findSelectedTimeSlots = (allSlots: Array<TimeSlot>, selectedDate?: Moment) =>
  allSlots.filter(timeSlot =>
    selectedDate ? selectedDate.date() === moment.parseZone(timeSlot.startTime).date() : false
  );
