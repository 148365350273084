import React, { useRef, useState } from 'react';

import { leftArrow3, rightArrow3 } from 'assets/icons';

import { useTabEvents } from './DeprecatedTab.hooks';
import { useWindowResizeHandler } from './DeprecatedTabs.hooks';
import {
  Container,
  Icon,
  Panel,
  PanelContainer,
  Tab,
  TabGroup,
  TabGroupContainer,
  TabGroupNextButton,
  TabGroupPreviousButton,
  Text
} from './DeprecatedTabs.styles';
import { TabsProps } from './DeprecatedTabs.types';

export const DeprecatedTabs = ({
  options,
  size,
  name,
  offset,
  initialActiveTabId,
  fixedHeightScrollContainer
}: TabsProps) => {
  const tabListRef = useRef<HTMLUListElement>(null);
  const [activeTabId, setActiveTabId] = useState(
    initialActiveTabId ? `tab-${initialActiveTabId}` : `tab-${options[0].id}`
  );
  const [onKeyDown] = useTabEvents(tabListRef);
  const isTabActive = (id: string) => id === activeTabId;
  const handleTabIndex = (id: string) => (isTabActive(id) ? 0 : -1);
  const { inOverflowMode, moveForward, moveBackward, isAtStart, isAtEnd } = useWindowResizeHandler({
    tabListRef,
    navItemsSelector: 'li[role="tab"]'
  });

  return (
    <Container offset={offset}>
      <TabGroupContainer overflowMode={inOverflowMode}>
        <TabGroupPreviousButton
          display={inOverflowMode}
          disabled={isAtStart}
          onClick={moveBackward}
        >
          <img src={leftArrow3} alt="Go back" />
        </TabGroupPreviousButton>
        <TabGroupNextButton display={inOverflowMode} disabled={isAtEnd} onClick={moveForward}>
          <img src={rightArrow3} alt="Go forward" />
        </TabGroupNextButton>
        <TabGroup role="tablist" aria-label={name} ref={tabListRef}>
          {options.map(tab => {
            const tabId = `tab-${tab.id}`;
            const panelId = `panel-${tab.id}`;

            return (
              <Tab
                id={tabId}
                key={tabId}
                active={isTabActive(tabId)}
                size={size}
                tabIndex={handleTabIndex(tabId)}
                role="tab"
                aria-controls={panelId}
                aria-selected={isTabActive(tabId)}
                onKeyDown={onKeyDown}
                onClick={() => setActiveTabId(tabId)}
              >
                {tab.icon && (
                  <Icon position={tab.icon.position} active={isTabActive(tabId)} size={size}>
                    {tab.icon.component}
                  </Icon>
                )}
                <Text>{tab.label}</Text>
              </Tab>
            );
          })}
        </TabGroup>
      </TabGroupContainer>

      <PanelContainer scrollContainerHeight={fixedHeightScrollContainer}>
        {options.map(tab => {
          const tabId = `tab-${tab.id}`;
          const panelId = `panel-${tab.id}`;

          return (
            <Panel
              active={isTabActive(tabId)}
              key={panelId}
              id={panelId}
              role="tabpanel"
              aria-labelledby={tabId}
            >
              {tab.panel}
            </Panel>
          );
        })}
      </PanelContainer>
    </Container>
  );
};
