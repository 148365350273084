import React from 'react';

import { themes } from 'kb-shared';
import styled from 'styled-components';

type Props = {
  title: string;
  options: Array<string>;
  onSelect: (option: string) => void;
  answer: string | null;
};

const FieldGroupSelection = (props: Props) => {
  const selectOption = (option: string) => {
    props.onSelect(option);
  };

  const renderOptions = () => {
    return props.options.map((option: string) => {
      const selected = props.answer === option;
      return (
        <ButtonWrapper selected={selected} key={option} onClick={selectOption.bind(this, option)}>
          <ButtonText>{option}</ButtonText>
        </ButtonWrapper>
      );
    });
  };

  return (
    <Container>
      <Text>{props.title}</Text>
      <ButtonsContainer>{renderOptions()}</ButtonsContainer>
    </Container>
  );
};

export default FieldGroupSelection;

const Container = styled.div`
  display: flex;
  flex: 50%;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  padding: 0px 12px 12px 12px;
`;

const Text = styled.div`
  max-width: 700px;

  font-size: 24px;
  text-align: left;
  margin-bottom: 12px;
`;

const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  box-shadow: 0 2px 8px 0 #d8d8d8;
`;

interface ButtonWrapperProps {
  selected: boolean;
}

const ButtonWrapper = styled.div`
  cursor: pointer;
  display: flex;
  min-width: 200px;
  height: 43px;
  background-color: ${(props: ButtonWrapperProps) =>
    props.selected ? themes.colors.yellow.primary : themes.deprecatedColors.beige};
  align-items: center;
  justify-content: center;
  :hover {
    background-color: ${themes.colors.yellow.primary};
  }
  @media (max-width: 700px) {
    min-width: auto;
    padding: 0 20px;
  }
`;

const ButtonText = styled.div``;
