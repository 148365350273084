import React from 'react';

import { Heading } from 'components/v2/Typography';

import { InfoContainer } from './InfoContainer';
import { BlockText } from './LineItemsTable.styled';

interface Props {
  className?: string;
  name: string;
  addressLine1: string | null;
  addressLine2: string | null;
  city: string | null;
  state: string | null;
  zipCode: string | null;
}

export const PayerInfo = ({
  name,
  addressLine1,
  addressLine2,
  city,
  state,
  zipCode,
  className
}: Props) => {
  let lastAddressLine = '';
  if (city || state || zipCode) {
    lastAddressLine = `${city}, ${state} ${zipCode}`;
  }

  return (
    <InfoContainer className={className}>
      <Heading tag="h6" noMargin>
        BILL TO
      </Heading>
      <Heading tag="h4" noMargin>
        {name}
      </Heading>
      {addressLine1 && <BlockText>{addressLine1}</BlockText>}
      {addressLine2 && <BlockText>{addressLine2}</BlockText>}
      {lastAddressLine && <BlockText>{lastAddressLine}</BlockText>}
    </InfoContainer>
  );
};
