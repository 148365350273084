import { themes } from 'kb-shared';
import styled from 'styled-components';

export const ItemContainer = styled.li`
  padding: 0.75em 0;
  border-bottom: 1px solid ${themes.colors.neutral.lavender};
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  &:last-child {
    border-bottom: none;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.largePhonePortrait}) {
    flex-direction: row;
  }
`;

export const ItemTitle = styled.div`
  flex: 1;
  font-size: 24px;
  font-weight: 700;
  margin-right: 1em;
  color: ${themes.colors.neutral.primaryNavy};
`;

export const ItemButtonContainer = styled.div`
  flex-shrink: 0;
`;
