import theme from 'kb-shared/theme';
import styled from 'styled-components';

export const Wrapper = styled.div`
  position: absolute;
  top: -4px;
  left: -4px;
  right: -4px;
  bottom: -4px;
  backdrop-filter: blur(4px);
  z-index: 100;
`;

export const Popup = styled.div`
  position: absolute;
  width: 90%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 100;

  @media screen and (min-width: ${theme.breakpoints.tabletLandscape}) {
    width: 100%;
    max-width: 800px;
  }
`;
