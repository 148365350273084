import React from 'react';

import { Text } from 'components/v2/Typography';

import { formatDate } from '../utils/format-date';
import { DatesInfoContainer, Header, Body, NoWrapText } from './DateInfo.styled';
interface Props {
  className?: string;
  invoiceDate: string;
  terms: string;
  datesOfService: string[];
}

export const DatesInfo = ({ invoiceDate, terms, datesOfService, className }: Props) => {
  return (
    <DatesInfoContainer className={className}>
      <Header>
        <Text fontStyle="bold">Invoice Date:</Text>
        <Text fontStyle="bold">Terms:</Text>
        <Text fontStyle="bold">Due Date:</Text>
        <Text fontStyle="bold">Date(s) of Service:</Text>
      </Header>
      <Body>
        <Text>{formatDate(invoiceDate)}</Text>
        <Text>{terms}</Text>
        <NoWrapText>Due upon Receipt</NoWrapText>
        <Text>{datesOfService.map(formatDate).join(', ')}</Text>
      </Body>
    </DatesInfoContainer>
  );
};
