import React from 'react';

import { Text } from 'components/v2/Typography/Text';
import { currencyNumberFormat } from 'utilities/number-format';

import { TotalBalanceContainer } from './TotalBalance.styled';

export const TotalBalance = ({ value }: { value: number }) => {
  return (
    <TotalBalanceContainer>
      <Text>Total Balance</Text>
      <br />
      <Text fontStyle="bold">{currencyNumberFormat(value)}</Text>
    </TotalBalanceContainer>
  );
};
