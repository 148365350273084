import { BugTracker } from 'kb-shared/utilities/bugTracker';
import {
  isValidFileExtension,
  showErrorValidExtensionToast,
  showInvalidImageToast
} from 'utilities/fileValidation';
import imageResize from 'utilities/imageResize';

import { FileUploadState } from './photoUpload.types';

export const getFileUploadState = async (
  target: 'front' | 'back',
  files: FileList | null
): Promise<FileUploadState | undefined> => {
  if (!files || !files[0]) return;

  const fileToUpload = files[0];

  if (!isValidFileExtension(fileToUpload.type)) {
    showErrorValidExtensionToast();
    return;
  }

  try {
    const resizedFile = await imageResize(fileToUpload);

    if (target === 'front') {
      return getFrontImage(resizedFile, fileToUpload);
    } else {
      return getBackImage(resizedFile, fileToUpload);
    }
  } catch (error) {
    if (error instanceof Error) {
      if (error.message?.includes(INVALID_IMAGE_FILE_INDICATOR)) {
        showInvalidImageToast();
        return;
      } else if (error.message?.includes(FINGERPRINTING_DISABLED_INDICATOR)) {
        // if fingerprinting protection prevents resize, we will upload file without resizing
        if (target === 'front') {
          return getFrontImage(fileToUpload, fileToUpload);
        } else {
          return getBackImage(fileToUpload, fileToUpload);
        }
      }
    } else {
      BugTracker.notify(error, 'ImageResize');
      return;
    }
  }
};

const getFrontImage = (resizedFile: Blob, fileToUpload: File) => {
  return {
    frontImage: resizedFile,
    frontImageFileName: fileToUpload.name,
    frontImageFileObjectUrl: URL.createObjectURL(resizedFile),
    error: ''
  };
};

const getBackImage = (resizedFile: Blob, fileToUpload: File) => {
  return {
    backImage: resizedFile,
    backImageFileName: fileToUpload.name,
    backImageFileObjectUrl: URL.createObjectURL(resizedFile),
    error: ''
  };
};

export const INVALID_IMAGE_FILE_INDICATOR = 'failed to create Image() from blob';
export const FINGERPRINTING_DISABLED_INDICATOR =
  "make sure fingerprinting protection isn't enabled";
