import React, { useEffect } from 'react';
import { useHistory } from 'react-router';

import { usePatient } from 'hooks';

import { PaymentDetails } from '../PaymentDetails/PaymentDetails';
import { CoachingBenefitsDisclaimer } from './components/CoachingBenefitsDisclaimer';
import { CreateAppointmentForm } from './components/CreateAppointmentForm';
import { LicensedProviderDisclaimer } from './components/LicensedProviderDisclaimer';
import { MembershipDetails } from './components/MembershipDetails';
import { RescheduleAppointmentForm } from './components/RescheduleAppointmentForm';
import { Container } from './ConfirmOrder.styled';
import { Props } from './ConfirmOrder.types';

export const ConfirmOrder = ({
  product,
  hasEmployer,
  timeSlot,
  clinic,
  onBookAppointment,
  providerId
}: Props) => {
  const history = useHistory();
  const { isLoggedIn, patient } = usePatient();
  const hasMembershipEmployer = patient?.patientMembership?.employer ?? false;

  useEffect(() => {
    if (!product) history.replace('/');
  }, [product, history]);

  /*
  Without this line, this component starts to render before auth token becomes available (stored in session storage).
  Any API request gets executed without auth token and gets rejected by API.
  */
  if (!isLoggedIn) return null;

  return (
    <Container>
      <LicensedProviderDisclaimer product={product} />
      <CoachingBenefitsDisclaimer product={product} hasEmployer={hasMembershipEmployer} />
      <MembershipDetails hasEmployer={hasEmployer} />
      <PaymentDetails product={product} hasEmployer={hasEmployer} />
      <RescheduleAppointmentForm product={product} timeSlot={timeSlot} clinic={clinic} />
      <CreateAppointmentForm
        providerId={providerId}
        product={product}
        timeSlot={timeSlot}
        clinic={clinic}
        hasEmployer={hasEmployer}
        onBookAppointment={onBookAppointment}
      />
    </Container>
  );
};
