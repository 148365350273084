import { themes } from 'kb-shared';
import styled from 'styled-components';

import { MobileProps } from './AuthFlowLayout.types';

export const FormContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  box-sizing: content-box;
  ${({ mobile }: MobileProps) => `
    padding-left: ${mobile ? '0px' : '60px'};
    padding-right: ${mobile ? '0px' : '60px'};
  `};
`;

export const Content = styled.div`
  display: flex;
  width: 100%;
  ${({ mobile }: MobileProps) => `
    padding-top: 0px;
    flex-direction: ${mobile ? 'column' : 'row'};
    align-items: ${mobile ? 'center' : 'flex-start'};
  `}
`;

export const LeftContent = styled.div`
  flex: 3;
  width: 100%;
`;

export const RightContent = styled.div`
  flex: 1;
`;

export const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding-top: 16px;
  ${({ mobile }: MobileProps) => `
    flex-direction: ${mobile ? 'column' : 'row'};
  `}
`;

export const PageTitleRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  color: ${themes.colors.neutral.primaryNavy};
  font-size: 16px;
  font-weight: 500;
  width: 100%;
  padding-bottom: 32px;
`;

export const BackContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  width: 120px;
  @media screen and (max-width: ${themes.breakpoints.largePhonePortrait}) {
    width: 30px;
  }
`;

export const BackText = styled.div`
  @media screen and (max-width: ${themes.breakpoints.largePhonePortrait}) {
    display: none;
  }
`;

export const ArrowLeftContainer = styled.div`
  width: 24px;
  text-align: center;
`;

export const ForwardContainer = styled.div`
  width: 36px;
  @media screen and (max-width: ${themes.breakpoints.largePhonePortrait}) {
    width: 15px;
  }
`;

export const PageTitle = styled.div`
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;
  text-align: center;
  width: 100%;
`;

export const UnderlinedText = styled.div`
  border-bottom: 2px solid ${themes.colors.yellow.primary};
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  max-width: 560px;
  padding-bottom: 16px;
`;

export const ContentContainer = styled.div`
  ${({ theme }) => theme.grid.stretchedPageContainerCss};
  flex: 1;
  background: white;
`;

export const FormContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  @media screen and (max-width: ${themes.breakpoints.largePhonePortrait}) {
    width: 100%;
   }
  }
`;
