import moment, { Moment as TMoment } from 'moment-timezone';

import { DayObject, Days, NormalizedTimeSlots, TimeSlot } from '../types';

type WeekString = {
  string: string;
  yearWeek: string;
};

export function getWeek(date: TMoment): WeekString {
  const dateCopy = moment(date);
  let weekString = '';
  // weekNumber: eg. 26 is the 26th week in the year. Represents for example June 23-June 30
  const weeknumber = dateCopy.week();

  // Beware moment is mutable, copy with moment(date) to avoid mutation
  // Here we generate the 7 dates in the given week.
  const newDate = moment(dateCopy)
    .week(weeknumber)
    .startOf('week');
  let weeklength = 7;
  const result = [];

  //TODO: Optimize this, we don't need all the dates of the week, just first and last.
  while (weeklength--) {
    result.push(moment(newDate));
    newDate.add(1, 'day');
  }

  //Take first date of given week and last date of given week
  const firstDayOfWeek = result[0];
  const lastDayOfWeek = result[result.length - 1];
  // Check if today's date is within range of the generated week: https://stackoverflow.com/a/29495647/287814
  // If so, weekString should be "This Week"
  const isThisWeek = moment().isBetween(firstDayOfWeek, lastDayOfWeek, undefined, '[]'); // all inclusive

  if (isThisWeek) {
    weekString = 'This Week';
  } else {
    // Check if it's "Next Week"
    const isNextWeek = moment()
      .add(1, 'week')
      .isBetween(firstDayOfWeek, lastDayOfWeek, undefined, '[]'); // all inclusive
    if (isNextWeek) {
      weekString = 'Next Week';
    } else {
      // Otherwise, render as standard date range:
      // If start and finish are in the same month, show as: July 7 - 13
      // If they are in different months, show as June 30 - July 6
      const isSameMonth = firstDayOfWeek.isSame(lastDayOfWeek, 'month');
      const formatLastDay = isSameMonth ? 'D' : 'MMMM D';
      weekString = `${result[0].format('MMMM D')} - ${result[result.length - 1].format(
        formatLastDay
      )}`;
    }
  }

  return {
    string: weekString,
    yearWeek: `${dateCopy.weekYear()}-${dateCopy.week()}`
  };
}

export function normalizeAppointments(appointments: Array<TimeSlot>): NormalizedTimeSlots {
  const mapped = appointments.reduce((obj, appt) => {
    const format = 'MM-DD-YYYY';
    const date = moment(appt.startTime);
    const week = getWeek(date);
    // @ts-ignore
    if (!obj[week.yearWeek]) {
      // @ts-ignore
      obj[week.yearWeek] = { days: [], daysById: {}, ...week };
    }

    const day = date.format(format);
    // @ts-ignore
    if (!obj[week.yearWeek].daysById[day]) {
      // @ts-ignore
      obj[week.yearWeek].daysById[day] = { timeslots: [], date };
      // @ts-ignore
      obj[week.yearWeek].days.push(day);
    }

    // @ts-ignore
    if (!obj[week.yearWeek].daysById[day].timeslots.includes(appt)) {
      // @ts-ignore
      obj[week.yearWeek].daysById[day].timeslots.push(appt);
    }

    // @ts-ignore
    obj[week.yearWeek].daysById[day].timeslots = obj[week.yearWeek].daysById[day].timeslots.sort(
      // @ts-ignore
      (a, b) => new Date(a.startTime) - new Date(b.startTime)
    );

    return obj;
  }, {});

  // @ts-ignore
  const weeks = Object.keys(mapped).sort((a, b) => {
    const dateA = moment(a, 'YYYY-WW');
    const dateB = moment(b, 'YYYY-WW');

    if (dateA.isBefore(dateB)) {
      return -1;
    } else {
      return 1;
    }
  });

  const result = {
    weeks: weeks,
    weeksById: mapped
  };

  return result;
}

// Unpack days object into an array of objects we can sort by date.
export function normalizeDays(days: Days): DayObject[] {
  return (
    Object.keys(days)
      .map(key => {
        const numKey = parseInt(key, 10);
        const { timeslots, date } = days[numKey];
        return {
          timeslots,
          date,
          numKey
        };
      })
      // @ts-ignore
      .sort((a: DayObject, b: DayObject) => a.date - b.date)
  );
}

// mapping Rails timezones
export const MOMENT_TIME_ZONES = {
  Hawaii: 'Pacific/Honolulu',
  Alaska: 'America/Juneau',
  'Pacific Time (US & Canada)': 'America/Los_Angeles',
  'Mountain Time (US & Canada)': 'America/Denver',
  Arizona: 'America/Phoenix',
  'Central Time (US & Canada)': 'America/Chicago',
  'Eastern Time (US & Canada)': 'America/New_York',
  UTC: 'Etc/UTC',
  'Atlantic Standard (Puerto Rico)': 'America/Puerto_Rico'
};

export function momentFormatted(date: string, format: string) {
  return moment(date).format(format);
}

export function mappedTimeZone(timeZone: keyof typeof MOMENT_TIME_ZONES) {
  return MOMENT_TIME_ZONES[timeZone];
}

export function inFuture(time: TMoment | string) {
  return moment(time) > moment();
}

export function inFutureWithDelay(time: TMoment | string, delay: number) {
  return moment(time).add(delay, 'minutes') > moment();
}

export function inPast(time: TMoment | string) {
  return moment(time) < moment();
}

export function isToday(time: TMoment | string) {
  return moment(time).isSame(moment(), 'day');
}

export function momentInTimeZone(time: string, timeZone: string): moment.Moment {
  return moment(time).tz(MOMENT_TIME_ZONES[timeZone as keyof typeof MOMENT_TIME_ZONES]);
}

export function momentInTimeZoneAndFormat(time: string, timeZone: string, format: string): string {
  // In case that time is in format "YYYY-MM-DD ..." date parsing will fail in Safari.
  // If time is in format "YYYY/MM/DD...." date parsing will also work in Safari.
  return momentInTimeZone(time.replace(/-/g, '/'), timeZone).format(format);
}

export function stripTimeAndFormat(date: string, format: string): string {
  return moment(date, 'YYYY-MM-DD').format(format);
}

export function daysSince(date: string) {
  const dateOfTodoCreation = moment.utc(date);
  return moment().diff(dateOfTodoCreation, 'days');
}

export const convertDateForm = (date: string, format = 'M/D/YY'): string => {
  return moment(date)
    .parseZone()
    .format(format);
};

export function formatToWeekday(moment: moment.Moment | null): string | undefined {
  return moment?.format('dddd');
}

export function formatToShortDate(moment: moment.Moment | null): string | undefined {
  return moment?.format('MMM DD');
}

export function formatToWeekdayMonthDay(moment: moment.Moment | null): string | undefined {
  return moment?.format('ddd, MMM DD');
}

export function formatToLongWeekdayMonthDay(moment: moment.Moment | null): string | undefined {
  return moment?.format('dddd, MMM DD');
}

export function momentWithParsedTimeZone(time: string): moment.Moment {
  return moment.parseZone(time);
}

export function getMoment(time: string): moment.Moment {
  return moment(time);
}

export function getMomentAndStripTime(dateAndTime: string): moment.Moment {
  return moment(dateAndTime, 'YYYY-MM-DD');
}

export function getGuessedTimeZone(): string {
  return moment.tz.guess(true);
}

export function getNow(format: string): string {
  return moment().format(format);
}

export function diffYear(date?: string): number {
  if (!date) return 0;
  return moment().diff(date, 'years');
}
