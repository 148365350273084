import React from 'react';

import { DateInput } from '../../Inputs';
import { FormControl, getFormControlIds } from '../FormControl';
import { Props } from './DateField.types';

export const DateField = ({
  status = 'default',
  label,
  id,
  required,
  helperText,
  description,
  hideDescription,
  placeholder,
  autoComplete,
  value,
  onChange,
  onBlur,
  onFocus,
  onKeyDown,
  dateFormat,
  onSelect,
  labelBg,
  hideHelperText,
  blockFutureDays
}: Props) => {
  const ids = getFormControlIds(id);

  return (
    <FormControl
      status={status}
      label={label}
      required={required}
      helperText={helperText}
      description={description}
      hideDescription={hideDescription}
      labelId={ids.label}
      inputId={ids.input}
      helperTextId={ids.helperText}
      labelBg={labelBg}
      hideHelperText={hideHelperText}
    >
      <DateInput
        id={ids.input}
        status={status}
        placeholder={placeholder}
        autoComplete={autoComplete}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        onFocus={onFocus}
        onKeyDown={onKeyDown}
        dateFormat={dateFormat}
        onSelect={onSelect}
        blockFutureDays={blockFutureDays}
      />
    </FormControl>
  );
};
