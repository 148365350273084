import { FeatureFlag, FeatureFlags } from './FeatureFlags.types';

export const extractFeatureFlags = (featureFlags?: FeatureFlag[]) => {
  if (!featureFlags) return {};

  return featureFlags.reduce((acc: Partial<FeatureFlags>, flag: FeatureFlag) => {
    const key = transformSnakeCaseToCamelCase(flag.key);
    acc[key as keyof FeatureFlags] = flag.enabled;
    return acc;
  }, {});
};

export const transformSnakeCaseToCamelCase = (key: string) =>
  key.replace(/_([a-z])/g, g => g[1].toUpperCase());
