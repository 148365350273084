import { faBars } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { useHistory } from 'react-router-dom';

import { ProfileImageUploadButton } from 'components/PhotoUpload/ProfilePhoto/ProfileImageUploadButton';
import { Button } from 'components/v2/Buttons/Button';
import { Text } from 'components/v2/Typography';
import { KindbodyLogo } from 'layouts/MainLayout/Logo/KindBodyLogo';
import { pageUrl } from 'utilities/pageUrl';
import { useBreakpoints } from 'utilities/useBreakpoints';

import {
  HamburgerMenuButtonsContainer,
  HamburgerMenuContainer,
  HamburgerMenuIcon,
  HamburgerMenuIconContainer,
  HamburgerMenuLogoContainer,
  HamburgerMenuPatientNameContainer,
  HamburgerMenuProfileImageContainer,
  ScheduleButton
} from './HamburgerMenu.styled';
import { HamburgerMenuProps } from './HamburgerMenu.types';

export const HamburgerMenu = ({
  onClick,
  name,
  $height,
  $backgroundColor,
  hasMembershipDocument,
  documentUrl,
  isDashboard
}: HamburgerMenuProps) => {
  const { isMobile } = useBreakpoints();
  const history = useHistory();

  return (
    <HamburgerMenuContainer $height={$height} $backgroundColor={$backgroundColor}>
      <HamburgerMenuIconContainer>
        <HamburgerMenuIcon role="img" aria-label="menu icon" onClick={onClick}>
          <FontAwesomeIcon icon={faBars} title="Open menu icon" />
        </HamburgerMenuIcon>
        {isDashboard && (
          <>
            <HamburgerMenuProfileImageContainer>
              <ProfileImageUploadButton id="header-profile-image-upload" size={40} />
            </HamburgerMenuProfileImageContainer>
            <HamburgerMenuPatientNameContainer>
              <Text size="sm">Hello,</Text>
              <Text fontStyle="medium">{name}</Text>
            </HamburgerMenuPatientNameContainer>
          </>
        )}
      </HamburgerMenuIconContainer>
      {!isDashboard && (
        <HamburgerMenuLogoContainer>
          <KindbodyLogo alt="kindbody menu logo" width="93" height="16" />
        </HamburgerMenuLogoContainer>
      )}
      {isDashboard && (
        <HamburgerMenuButtonsContainer>
          {hasMembershipDocument && !isMobile && (
            <Button
              category="secondary"
              label="View Member Benefit"
              onClick={() => window.open(documentUrl, '_blank', 'noreferrer noopener')}
              size="sm"
            />
          )}
          <ScheduleButton
            category="primary-dark"
            label={isMobile ? 'Schedule' : 'Schedule Appointment'}
            onClick={() => history.push(pageUrl.booking.bookingQuiz())}
            size="sm"
          />
        </HamburgerMenuButtonsContainer>
      )}
    </HamburgerMenuContainer>
  );
};
