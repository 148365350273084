import { Text } from 'components/v2/Typography/Text';
import theme from 'kb-shared/theme';
import styled, { css } from 'styled-components';

export const MessageText = styled(Text).attrs({
  size: 'md',
  tag: 'p',
  fontStyle: 'regular',
  color: theme.colors.neutral.darkestNavy
})<{ fromPatient: boolean }>`
  max-width: 90%;
  padding: 8px;
  min-width: 100px;
  white-space: pre-wrap;
  margin-bottom: 4px;
  border-radius: 8px;

  ${({ fromPatient }) =>
    fromPatient
      ? css`
          background: ${({ theme }) => theme.colors.yellow.lightYellow};
          box-shadow: 0px 0.48px 0px 0px ${({ theme }) => theme.colors.yellow.darkYellow},
            0px 1px 1px 0px rgba(10, 13, 61, 0.08);
          align-self: flex-end;
        `
      : css`
          background: #fff;
          box-shadow: 0px 0.48px 0px 0px ${({ theme }) => theme.colors.lilac.lighterLilac},
            0px 1px 1px 0px rgba(10, 13, 61, 0.08);
          align-self: flex-start;
        `}

  a {
    text-decoration: underline;
  }
`;
