import React, { ReactNode } from 'react';

import {
  IndicatorDotsOverlayContainer,
  Container,
  Text,
  IndicatorLineContainer,
  IndicatorFillOverlay,
  Indicator,
  InnerIndicator,
  IndicatorContainer
} from './MobileStepTracker.styled';
import { Props, IndicatorLineProps, IndicatorIconProps } from './MobileStepTracker.types';

const MobileStepTracker = ({ steps, selectedStep, onStepSelected }: Props) => {
  const renderSteps = (): ReactNode => {
    return (
      <IndicatorDotsOverlayContainer>
        {steps.map((step: string, index: number) => {
          const clickable = index < selectedStep && onStepSelected != null;
          return (
            <IndicatorIcon
              active={selectedStep >= index}
              clickable={clickable}
              key={step}
              onSelected={() => clickable && onStepSelected && onStepSelected(index)}
            />
          );
        })}
      </IndicatorDotsOverlayContainer>
    );
  };

  const numSteps = steps.length;
  // Clamp the selectedStep to a max value in order to avoid "overflow" of the line
  const selectedStepClamp = Math.min(selectedStep, numSteps - 1);
  return (
    <Container>
      <IndicatorLine progress={selectedStepClamp / (numSteps - 1)}>{renderSteps()}</IndicatorLine>
      {//Do not render text if step is greater than # of steps.
      selectedStep >= numSteps ? null : (
        <Text>
          Step {selectedStep + 1}: {steps[selectedStep]}
        </Text>
      )}
    </Container>
  );
};

const IndicatorLine = (props: IndicatorLineProps) => {
  return (
    <IndicatorLineContainer>
      <IndicatorFillOverlay progress={props.progress} />
      {props.children}
    </IndicatorLineContainer>
  );
};

const IndicatorIcon = (props: IndicatorIconProps) => {
  return (
    <IndicatorContainer onClick={() => props.onSelected && props.onSelected()}>
      <Indicator active={props.active}>
        <InnerIndicator active={props.active} clickable={props.clickable} />
      </Indicator>
    </IndicatorContainer>
  );
};

export default MobileStepTracker;
