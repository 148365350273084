import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
`;

export const TodaysAppointments = styled.div`
  margin-bottom: 8px;

  @media ${({ theme }) => theme.queries.minTablet} {
    margin-bottom: 24px;
  }
`;
