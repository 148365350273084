import { AppointmentType } from 'kb-shared/types';
import { parseNumber } from 'utilities/parseNumber';

import { StepType } from '../types/types';

export const isFalsyNonNumber = (value: unknown): boolean => !value && typeof value !== 'number';

export const toIntOrNull = (value?: string | null): number | null => {
  const isNumber = typeof value === 'string' && value.length && !isNaN(Number(value));

  if (!value || !isNumber) {
    return null;
  }

  const parsedInt = parseNumber(value);

  return parsedInt !== undefined ? parsedInt : null;
};

export const isVirtualAppointmentType = (appointmentType: AppointmentType): boolean =>
  Boolean(appointmentType.virtual);

export const isInPersonAppointmentType = (appointmentType: AppointmentType): boolean =>
  !isVirtualAppointmentType(appointmentType);

export const containsOnlyVirtualAppointmentType = (
  appointmentTypes: Array<AppointmentType>
): boolean => {
  const virtualAppointmentType = appointmentTypes.find(isVirtualAppointmentType);
  const inPersonAppointmentType = appointmentTypes.find(isInPersonAppointmentType);
  const isOnlyVirtual = Boolean(virtualAppointmentType && !inPersonAppointmentType);

  return isOnlyVirtual;
};

export const isSupportedStep = (step: string): step is StepType =>
  ['location', 'time', 'insurance', 'confirm', 'success'].includes(step);
