import React, { useEffect } from 'react';

import { Loader } from 'components/Loader/Loader';
import { themes } from 'kb-shared';
import styled from 'styled-components';

const { colors } = themes;

const Container = styled.div`
  align-items: center;
  background-color: ${colors.yellow.primary};
  display: flex;
  flex: 1;
  justify-content: center;
  min-height: 100vh;
  min-width: 100vw;
`;

interface QueryParams {
  code: string;
}

const MobileOAuth = () => {
  useEffect(() => {
    const {
      location: { search }
    } = window;
    if (!search.includes('code')) return;

    const items = (search
      .slice(1)
      .split('&')
      .map(p => p.split('='))
      .reduce((obj, [key, value]) => ({ ...obj, [key]: value }), {}) as unknown) as QueryParams;

    window.location.href = `kindbody://oauth?code=${items.code}`;
  }, []);

  return (
    <Container>
      <Loader color={colors.neutral.lavender} />
    </Container>
  );
};

export default MobileOAuth;
