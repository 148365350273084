import { Appointment } from 'kb-shared';
import { inFutureWithDelay } from 'kb-shared/utilities/momentHelpers';

// logic is changed in a way that appointment are regarded as future appointments for 1 hour after they are started
export const filterAppointmentsByStartTime = (
  appointments: Appointment[] | undefined,
  filterPastAppointment = false
): Appointment[] =>
  appointments?.filter((a: Appointment) => {
    if (!filterPastAppointment) {
      return inFutureWithDelay(a.startTime, 60);
    } else {
      return !inFutureWithDelay(a.startTime, 60);
    }
  }) || [];

export const getPastAppointments = (appointments: Appointment[] | undefined): Appointment[] => {
  return filterAppointmentsByStartTime(appointments, true).sort(compareByStartTimeDescending);
};

export const compareByStartTimeDescending = (a: Appointment, b: Appointment): number => {
  return new Date(b.startTime).getTime() - new Date(a.startTime).getTime();
};
