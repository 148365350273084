import { useMutation } from '@apollo/client';
import Honeybadger from '@honeybadger-io/js';
import React, { useState } from 'react';

import { Toggle } from 'components/v2/Toggle';
import { UPDATE_DECLARED_PARTNER } from 'kb-shared/graphql/mutations';
import styled from 'styled-components';
import { isNull } from 'utilities';
import { analytics } from 'utilities/analytics';

import { usePartnerInvites } from '../../hooks/usePartnerInvites';
import { STATUS } from '../../InterstitialModal.graphql';
import { StatusProp } from '../../InterstitialModal.types';
import { InvitePartnerForm } from './InvitePartnerForm';

export const PartnerDetails = ({ status }: StatusProp) => {
  const [hasPartner, setHasPartner] = useState<boolean | null>(status.hasPartnersPatientDeclared);
  const { invites, refetch, loading: loadingPartnerInvites } = usePartnerInvites();
  const showInviteForm = isNull(hasPartner) || hasPartner;

  const [updatePatient] = useMutation(UPDATE_DECLARED_PARTNER);
  const hasActiveInvites = invites.some(invite => invite.status !== 'Cancelled');

  const onToggleChange = (value: boolean) => {
    // if active invite for the partner exists, don't allow to toggle to "I don't have a partner"
    if (hasActiveInvites && value === true) return;

    // value should be negated, UI shows when you don't have a partner as true
    // we are storing it as false in the local state and on the backend

    setHasPartner(!value);
    updatePatient({
      variables: {
        hasPartnersPatientDeclared: !value
      },
      refetchQueries: [{ query: STATUS }]
    }).catch(error => {
      Honeybadger.notify(error, 'Declaring partner status failed');
    });

    analytics.track(analytics.EVENTS.PATIENT_DECLARED_PARTNER_STATUS);
  };

  const isChecked = !isNull(hasPartner) ? !hasPartner : false;

  return (
    <Container>
      <Toggle
        name={'partner-toggle'}
        label={'I don’t have a partner or I do not wish to include them at this time'}
        onChange={onToggleChange}
        isChecked={isChecked}
        disabled={hasActiveInvites}
      />
      {showInviteForm && !loadingPartnerInvites && (
        <InvitePartnerForm invites={invites} refetch={refetch} />
      )}
    </Container>
  );
};

const Container = styled.div`
  padding-top: 32px;
  min-height: 380px;
`;
