import styled from 'styled-components';

interface AnimatedWrapperProps {
  isHidden?: boolean;
}
export default styled.div`
  margin: 42px 0px 18px 0px;
  visibility: ${(props: AnimatedWrapperProps) => (props.isHidden ? 'hidden' : 'visible')};
  opacity: ${(props: AnimatedWrapperProps) => (props.isHidden ? 0 : 1)};
`;
