import { themes } from 'kb-shared';
import styled from 'styled-components';

export const StepsContainer = styled.div`
  border-radius: 8px;
  background: ${themes.colors.neutral.lavenderWhite};
  box-shadow: ${themes.shadows.card.level0};
  padding: 1em;
  margin-top: 1em;
`;

export const SingleStepContainer = styled.div<{ $last: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: flex-start;

  > span {
    padding-bottom: ${({ $last }) => ($last ? '0' : '1.5em')};
  }
`;

export const SingleStepIllustrationContainer = styled.div<{ $completed: boolean; $last: boolean }>`
  margin-right: 0.5em;
  display: flex;
  flex-direction: column;
  min-height: ${({ $last }) => ($last ? 'auto' : '72px')};

  background-image: ${({ $last, $completed }) =>
    $last
      ? 'none'
      : $completed
      ? `url("data:image/svg+xml,%3Csvg width='3' height='58' viewBox='0 0 3 58' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Ctitle%3EDotted line%3C/title%3E%3Cpath d='M1.3335 0L1.3335 58' stroke='%23F9E168' stroke-width='2' stroke-dasharray='3 3'/%3E%3C/svg%3E ")`
      : `url("data:image/svg+xml,%3Csvg width='3' height='56' viewBox='0 0 3 56' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Ctitle%3EDotted line%3C/title%3E%3Cpath d='M1.3335 0L1.3335 56' stroke='%239EA3E5' stroke-width='2' stroke-dasharray='3 3'/%3E%3C/svg%3E%0A")`};
  background-repeat: no-repeat;
  background-position: top 16px left 50%;

  > svg {
    color: ${({ $completed, theme }) =>
      $completed ? theme.colors.yellow.primary : theme.colors.neutral.lighterNavy};
  }
`;
