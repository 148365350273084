import { Size } from 'components/v2/Typography/Text.types';
import styled, { css } from 'styled-components';

import { Underline } from './LinkButton.types';

export const StyledLinkButton = styled.button<{
  underline: Underline;
  size: Size;
}>`
  :has(svg) {
    min-width: auto;
    display: inline-flex;
    align-items: center;
    gap: 8px;
  }

  ${({ underline }) => {
    switch (underline) {
      case 'always':
        return css`
          text-decoration: underline;
        `;

      case 'hover':
        return css`
          text-decoration: none;

          :hover {
            text-decoration: underline;
          }
        `;

      case 'none':
        return css`
          text-decoration: none;
        `;
    }
  }}

  ${({ size }) => {
    switch (size) {
      case 'lg':
        return css`
          svg {
            width: 24px;
            height: 24px;
          }
        `;

      case 'md':
        return css`
          svg {
            width: 20px;
            height: 20px;
          }
        `;

      case 'sm':
        return css`
          svg {
            width: 20px;
            height: 20px;
          }
        `;
    }
  }}
`;
