import { usePatient } from 'hooks';
import { Lab } from 'kb-shared';

const useHomeMarket = (): Lab => {
  const { patient } = usePatient();

  const homeMarket = patient?.lab as Lab;

  return {
    ...homeMarket,
    vios: homeMarket?.vios || false
  };
};

export { useHomeMarket };

export default useHomeMarket;
