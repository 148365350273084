export const formatAddress = (
  address: string
): { streetAddress: string; cityStateZip: string } | null => {
  const pattern = /^(.*?),\s*([^,]+),\s*([^,]+),\s*(\d{5})$/;
  const match = address.match(pattern);

  if (match) {
    const streetAddress = match[1];
    const cityStateZip = `${match[2]}, ${match[3]}, ${match[4]}`;

    return { streetAddress, cityStateZip };
  } else {
    return null;
  }
};
