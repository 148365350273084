import styled from 'styled-components';

export const WrapperDiv = styled.div`
  position: relative;

  span {
    font-size: 24px;
    position: absolute;
    top: 14px;
    right: 32px;
  }
`;
