import { themes } from 'kb-shared';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;

export const MobileStepTrackerWrapper = styled.div`
  padding-top: 24px;
  padding-bottom: 24px;
`;

export const RightContentWrapper = styled.div`
  padding-right: 24px;
  position: fixed;
`;

export const PageTitle = styled.div`
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;

  color: ${themes.colors.neutral.primaryNavy};
  font-size: 36px;
  @media screen and (min-width: ${themes.breakpoints.smallDesktop}) {
    font-size: 48px;
  }
`;

export const SubTitle = styled.p`
  padding: 0 20px;
  margin: 10px 0 15px;
`;
