import React from 'react';

const RightBracket = (props: { color?: string; width?: number; height?: number }) => {
  const width = props.width || 34;
  const height = props.height || 88;
  return (
    <svg
      width={`${width}px`}
      height={`${height}px`}
      viewBox={'0 0 34 88'}
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>Right Parenthesis</title>
      <defs />
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g transform="translate(-1326.000000, -113.000000)">
          <g transform="translate(1063.000000, 0.000000)" fill={props.color || '#FDE464'}>
            <g transform="translate(193.500000, 165.000000) scale(-1, 1) translate(-193.500000, -165.000000) translate(57.000000, 113.000000)">
              <path d="M64.5336846,11.3582731 L67,9.81468735 L60.5229456,0.451282051 L58.4024813,1.77351902 C46.8147679,9.00775974 33,22.2441833 33,44.2186141 C33,66.1918737 46.8170358,79.4282973 58.4058831,86.6637091 L60.551294,88 L66.8684632,78.5979465 L64.4384338,77.0766127 C51.7632737,69.1396774 44.4958645,57.1622483 44.4958645,44.2186141 C44.4958645,31.3089435 51.8006937,19.3326855 64.5336846,11.3582731" />
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default RightBracket;
