import { themes } from 'kb-shared';
import styled from 'styled-components';

export const FallbackWrapper = styled.div`
  > div {
    padding: 0;
  }
`;

export const BookingCalendarTimeContainer = styled.div`
  @media screen and (max-width: ${themes.breakpoints.tabletPortrait}) {
    flex-direction: column;
    align-items: center;
  }
  display: flex;
  flex-grow: 1;
`;
