import React from 'react';

import { SubheaderContainer, Title } from './Subheader.styled';

export const Subheader = ({ text }: { text: string }) => {
  return (
    <SubheaderContainer>
      <Title>{text}</Title>
    </SubheaderContainer>
  );
};
