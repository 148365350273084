import { Button } from 'components/v2/Buttons/Button/Button.styled';
import styled from 'styled-components';

export const VerticalWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const AddPartnerButtonContainer = styled.div`
  display: flex;
  justify-content: center;

  @media (min-width: ${({ theme }) => theme.breakpoints.tabletPortrait}) {
    justify-content: flex-start;
  }

  ${Button} {
    svg path {
      fill: ${({ theme }) => theme.colors.yellow.primary};
    }
  }
`;
