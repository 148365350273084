import React, { useState } from 'react';

import Modal from 'components/Modal';
import { Button } from 'components/v2/Buttons/Button';
import { SelectField, TextField } from 'components/v2/Form';
import { DropdownOption } from 'kb-shared';
import { sortNullableStrings } from 'utilities/sort';

import { ButtonContainer, Container } from './UploadFileModal.styled';
import { Props } from './UploadFileModal.types';

export const UploadFileModal = (props: Props) => {
  const { open, purposes, uploading, onClose, onDescriptionEntered } = props;
  const [purpose, setPurpose] = useState<DropdownOption | undefined>();
  const [description, setDescription] = useState('');

  const buttonText = uploading === undefined ? 'Add' : 'Upload';

  const purposeSelectionSatisfied = () => !purposes || purpose;

  const sortedPurposes = () => {
    if (!purposes) return [];

    const filteredPurposes = purposes.filter(purpose => purpose.value !== 'other');
    const otherPurpose = purposes.find(purpose => purpose.value === 'other');
    filteredPurposes.sort((prev, next) => sortNullableStrings(prev.label, next.label));
    if (otherPurpose) {
      filteredPurposes.push(otherPurpose);
    }

    return filteredPurposes;
  };

  return (
    <Modal onRequestClose={onClose} open={open}>
      <Container>
        {purposes && (
          <div>
            <SelectField
              label="Purpose"
              placeholder="Select purpose"
              selected={purpose}
              onChange={item => setPurpose(item)}
              options={sortedPurposes()}
              id="purposeInput"
              required={true}
            />
          </div>
        )}
        <div>
          <TextField
            label="Description"
            placeholder="Short description of the file(s)"
            id="upload-file-description"
            value={description}
            onChange={e => setDescription(e.currentTarget.value)}
          />
        </div>
        <ButtonContainer>
          <Button label={'Cancel'} category="primary-dark" size="lg" onClick={onClose} />
          <Button
            label={uploading ? `${buttonText}ing...` : buttonText}
            category="primary"
            isDisabled={!purposeSelectionSatisfied() || uploading}
            size="lg"
            onClick={() => onDescriptionEntered(purpose?.value, description)}
          />
        </ButtonContainer>
      </Container>
    </Modal>
  );
};
