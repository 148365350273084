import React from 'react';

import { DivAsHeading, H1, H2, H3, H4, H5, H6, SpanAsHeading } from './Heading.styled';
import { Props } from './Heading.types';

const DEFAULT_TAG = 'h1';
const DEFAULT_TAB_INDEX = 0;

/**
 * Usage example:
 * <Heading tag="h1">Heading</Heading>
 * <Heading tag="h2" styledAs="h4">Heading</Heading>
 * <Heading tag="div" styledAs="h2">Heading</Heading>
 *
 * Allows us to use h1-h6 tags as well as divs for headings with any of the heading styles.
 */
export const Heading = ({
  className,
  tag = DEFAULT_TAG,
  styledAs,
  children,
  bg,
  color,
  flexDirection,
  noMargin,
  tabIndex
}: Props) => {
  switch (tag) {
    case 'h1':
      return (
        <H1
          tabIndex={tabIndex ?? DEFAULT_TAB_INDEX}
          className={className}
          styledAs={styledAs ? styledAs : 'h1'}
          bg={bg}
          color={color}
          flexDirection={flexDirection}
          noMargin={noMargin}
        >
          {children}
        </H1>
      );
    case 'h2':
      return (
        <H2
          tabIndex={tabIndex}
          className={className}
          styledAs={styledAs ? styledAs : 'h2'}
          bg={bg}
          color={color}
          flexDirection={flexDirection}
          noMargin={noMargin}
        >
          {children}
        </H2>
      );
    case 'h3':
      return (
        <H3
          tabIndex={tabIndex}
          className={className}
          styledAs={styledAs ? styledAs : 'h3'}
          bg={bg}
          color={color}
          flexDirection={flexDirection}
          noMargin={noMargin}
        >
          {children}
        </H3>
      );
    case 'h4':
      return (
        <H4
          tabIndex={tabIndex}
          className={className}
          styledAs={styledAs ? styledAs : 'h4'}
          bg={bg}
          color={color}
          flexDirection={flexDirection}
          noMargin={noMargin}
        >
          {children}
        </H4>
      );
    case 'h5':
      return (
        <H5
          tabIndex={tabIndex}
          className={className}
          styledAs={styledAs ? styledAs : 'h5'}
          bg={bg}
          color={color}
          flexDirection={flexDirection}
          noMargin={noMargin}
        >
          {children}
        </H5>
      );
    case 'h6':
      return (
        <H6
          tabIndex={tabIndex}
          className={className}
          styledAs={styledAs ? styledAs : 'h6'}
          bg={bg}
          color={color}
          flexDirection={flexDirection}
          noMargin={noMargin}
        >
          {children}
        </H6>
      );
    case 'div':
      return (
        <DivAsHeading
          tabIndex={tabIndex}
          className={className}
          styledAs={styledAs ? styledAs : 'h1'}
          bg={bg}
          color={color}
          flexDirection={flexDirection}
          noMargin={noMargin}
        >
          {children}
        </DivAsHeading>
      );
    case 'span':
      return (
        <SpanAsHeading
          tabIndex={tabIndex}
          className={className}
          styledAs={styledAs ? styledAs : 'h1'}
          bg={bg}
          color={color}
          flexDirection={flexDirection}
        >
          {children}
        </SpanAsHeading>
      );
  }
};
