import React from 'react';

import CyclePlanInfo from '../CyclePlanInfo';
import { Title, SectionTitle } from './CyclePlanSummary.styled';
import { CyclePlanSummaryProps } from './CyclePlanSummary.types';

const CyclePlanSummary: React.FC<CyclePlanSummaryProps> = ({ cyclePlans, patient }) => {
  return (
    <>
      <Title>Cycle Plan Summary</Title>

      <CyclePlanInfo sections={patient} />

      {cyclePlans?.map((cyclePlan, index) => {
        if (index >= 3) return null;
        return (
          <React.Fragment key={index}>
            {index === 1 && <SectionTitle>Secondary Cycle Plan</SectionTitle>}
            {index === 2 && <SectionTitle>Tertiary Cycle Plan</SectionTitle>}
            <CyclePlanInfo sections={cyclePlan.sections} />
          </React.Fragment>
        );
      })}
    </>
  );
};

export default CyclePlanSummary;
