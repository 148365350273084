import { themes } from 'kb-shared';
import styled from 'styled-components';

export const HEADER_FOOTER_HEIGHT = 130;

export const Container = styled.div`
  flex-direction: column;
  display: flex;
  height: 100vh;
  width: 100%;
`;

export const BackgroundColor = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: ${themes.colors.yellow.lightestYellow};
  z-index: 0;
`;

export const marginTopBottom = HEADER_FOOTER_HEIGHT + 'px';
export const Content = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
  justify-content: center;
  margin: ${marginTopBottom} 20px ${marginTopBottom} 20px;

  @media screen and (min-width: ${themes.breakpoints.largePhonePortrait}) {
    margin: ${marginTopBottom} 70px ${marginTopBottom} 70px;
  }
`;

export const SectionContainer = styled.div`
  position: fixed;
  display: flex;
  align-items: center;
  right: 0px;
  top: 0px;
  bottom: 0px;
  height: 100%;
`;

export const FormContainer = styled.div`
  width: 100%;
  display: flex;
  flex: 1;
  z-index: 1;
`;

export const AbsoluteCentered = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

export const StickyFooter = styled.div`
  position: fixed;
  bottom: 0px;
  width: 100%;
  z-index: 2;
`;

export const StickyHeader = styled.div`
  position: fixed;
  top: 0px;
  width: 100%;
  z-index: 2;
`;
