import { CheckCircle, CircleExclamation, CircleInfo, Warning } from 'assets/icons/Icons';
import theme from 'kb-shared/theme';
import styled, { css } from 'styled-components';

import { Heading, Text } from '../Typography';
import { Props } from './Alert.types';

const getIconCss = (size: Props['size']) => {
  if (size === 'md') {
    return css`
      width: 16px;
      height: 16px;
    `;
  }

  return css`
    width: 48px;
    height: 48px;

    @media (min-width: ${theme.breakpoints.tabletLandscape}) {
      width: 24px;
      height: 24px;
    }
  `;
};

export const IconExclamation = styled(CircleExclamation).attrs({ type: 'solid' })<{
  size: Props['size'];
}>`
  ${({ size }) => getIconCss(size)}
`;
export const IconCheckmark = styled(CheckCircle).attrs({ type: 'solid' })<{
  size: Props['size'];
}>`
  ${({ size }) => getIconCss(size)}
`;
export const IconInfo = styled(CircleInfo).attrs({ type: 'solid' })<{
  size: Props['size'];
}>`
  ${({ size }) => getIconCss(size)}
`;
export const IconWarning = styled(Warning).attrs({ type: 'solid' })<{
  size: Props['size'];
}>`
  ${({ size }) => getIconCss(size)}
`;

export const TitleLarge = styled(Heading).attrs({
  tag: 'span',
  styledAs: 'h4',
  color: theme.colors.semantic.darkRed
})``;

export const TitleMedium = styled(Text).attrs({
  tag: 'strong',
  fontStyle: 'semibold'
})`
  height: 100%;
  padding-top: 4px;
`;

export const Header = styled.div<{ $size: Props['size'] }>`
  display: flex;
  flex-direction: column;
  gap: ${({ $size }) => ($size === 'md' ? '12px' : '24px')};
  align-items: center;

  ${TitleLarge}, ${TitleMedium} {
    text-align: center;
  }

  @media (min-width: ${theme.breakpoints.tabletLandscape}) {
    flex-direction: row;
    gap: ${({ $size }) => ($size === 'md' ? '8px' : '16px')};
    align-items: ${({ $size }) => ($size === 'md' ? 'center' : 'flex-start')};

    ${TitleLarge}, ${TitleMedium} {
      text-align: left;
    }

    ${IconExclamation}, ${IconCheckmark}, ${IconInfo}, ${IconWarning} {
      margin-top: 3px;
    }
  }
`;

export const MessageWrapper = styled.div<{ $size: Props['size'] }>`
  display: flex;
  flex-direction: column;
  gap: ${({ $size }) => ($size === 'md' ? '12px' : '24px')};

  // handles icon and message alignment
  > * {
    width: 100%;
  }

  @media (min-width: ${theme.breakpoints.tabletLandscape}) {
    flex-direction: row;
    gap: ${({ $size }) => ($size === 'md' ? '8px' : '16px')};
    align-items: ${({ $size }) => ($size === 'md' ? 'center' : 'flex-start')};

    // handles icon and message alignment
    > * {
      width: auto;
    }
  }
`;

export const Message = styled(Text).attrs({ tag: 'p' })``;

export const TextContent = styled.div<{ $size: Props['size'] }>`
  display: flex;
  flex-direction: column;
  gap: ${({ $size }) => ($size === 'md' ? '12px' : '24px')};

  @media (min-width: ${theme.breakpoints.tabletLandscape}) {
    gap: 8px;
  }
`;

export const ActionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  justify-content: flex-end;

  @media (min-width: ${theme.breakpoints.tabletLandscape}) {
    flex-direction: row;
  }
`;

export const Body = styled.div<{ $size: Props['size'] }>`
  display: flex;
  flex-direction: column;
  gap: ${({ $size }) => ($size === 'md' ? '12px' : '24px')};
`;

export const Wrapper = styled.div<{ $type: Props['type']; $noShadow: Props['noShadow'] }>`
  display: flex;
  flex-direction: column;
  padding: 24px;
  border-radius: 16px;

  ${({ $type, $noShadow }) => {
    if ($type === 'info') {
      return css`
        background: ${theme.gradients.containerRadial}, ${theme.colors.blue.lightestBlue};
        box-shadow: ${$noShadow ? 'none' : theme.shadows.container.glowBlue};

        ${IconInfo} {
          color: ${theme.colors.blue.blue};
        }

        ${TitleLarge}, ${TitleMedium} {
          color: ${theme.colors.neutral.primaryNavy};
        }
      `;
    }
    if ($type === 'success') {
      return css`
        background: ${theme.gradients.containerRadial}, ${theme.colors.green.lightestGreen};
        box-shadow: ${$noShadow ? 'none' : theme.shadows.container.glowGreen};

        ${IconCheckmark} {
          color: ${theme.colors.green.darkGreen};
        }

        ${TitleLarge}, ${TitleMedium} {
          color: ${theme.colors.green.darkestGreen};
        }
      `;
    }
    if ($type === 'warning') {
      return css`
        background: ${theme.gradients.containerRadial}, ${theme.colors.yellow.lighterYellow};
        box-shadow: ${$noShadow ? 'none' : theme.shadows.container.glowYellow};

        ${IconWarning} {
          color: ${theme.colors.yellow.darkYellow};
        }

        ${TitleLarge}, ${TitleMedium} {
          color: ${theme.colors.neutral.primaryNavy};
        }
      `;
    }
    if ($type === 'error') {
      return css`
        background: ${theme.gradients.containerRadial}, ${theme.colors.semantic.pink};
        box-shadow: ${$noShadow ? 'none' : theme.shadows.container.glowRed};

        ${IconExclamation} {
          color: ${theme.colors.semantic.darkRed};
        }

        ${TitleLarge}, ${TitleMedium} {
          color: ${theme.colors.semantic.darkRed};
        }
      `;
    }
  }}
`;
