import React from 'react';

import { AuthFlowLayout } from 'components/v2/Layouts/AuthFlowLayout/AuthFlowLayout';

import { EmailChangeContainer } from './EmailChange.styled';
import { EmailChangeForm } from './EmailChangeForm';

export const EmailChange = () => {
  return (
    <EmailChangeContainer>
      <AuthFlowLayout
        title={'Change Your Email Address'}
        renderMainContent={() => <EmailChangeForm />}
      />
    </EmailChangeContainer>
  );
};
