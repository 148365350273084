import FollistemSRC from '../../assets/images/follistim-pen-thumb.jpg';
import GanirelixSRC from '../../assets/images/ganirelix-thumb.jpg';
import GonalFSRC from '../../assets/images/gonal-f-thumb.jpg';
import MenopurSRC from '../../assets/images/menopur-thumb.jpg';

export interface MedicationInstruction {
  id: string;
  displayName: string;
  items: Array<string>;
  imageSrc: string;
  videoHash: string;
  timeOfDay: 'am' | 'pm';
}

const medications: Array<MedicationInstruction> = [
  {
    id: 'follistim',
    displayName: 'Follistim',
    items: [
      'One Follistim® Pen',
      'One Follistim® AQ Cartridge',
      'BD Micro-Fine Pen needle provided with cartridge',
      'Alcohol swabs',
      'Sterile gauze',
      'Safety container'
    ],
    imageSrc: FollistemSRC,
    videoHash: 'PyJ8UfjrFq8',
    timeOfDay: 'am'
  },
  {
    id: 'ganirelix',
    displayName: 'Ganirelix',
    items: [
      'One Ganirelix Acetate PreFilled Syringe',
      'Alcohol swabs',
      'Sterile gauze',
      'Safety container'
    ],
    imageSrc: GanirelixSRC,
    videoHash: 'f_CZRDLwvfI',
    timeOfDay: 'pm'
  },
  {
    id: 'gonalf',
    displayName: 'Gonal-f',
    items: [
      'One multiple-dose vial Gonal-f powder',
      'Prefilled syringe labeled Bacteriostatic water for injection',
      'Gonal-f Multi-Dose Custom-Dosing Injection syringe',
      'Alcohol swabs',
      'Sterile gauze',
      'Safety container'
    ],
    imageSrc: GonalFSRC,
    videoHash: '0DDKpVm16qI',
    timeOfDay: 'am'
  },
  {
    id: 'menopur',
    displayName: 'Menopur',
    items: [
      'One vial of Menopur® 75 IU (additional vials will be used if your dose is greater than 75 units)',
      'One vial of sterile diluent, even if you require more than 1 vial of medication',
      'One syringe with two needles. One for reconstitution and one for administration',
      'Alcohol swabs',
      'Sterile gauze',
      'Safety container'
    ],
    imageSrc: MenopurSRC,
    videoHash: 'uimI4A3iNNo',
    timeOfDay: 'am'
  }
];

export default medications;
