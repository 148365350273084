import React from 'react';

import StepDescription from './StepDescription';

const FollowUpCallDetail = () => {
  return (
    <StepDescription
      description={
        <>
          <p>
            When you get your next period, please schedule a post-op call so we can review how
            you&apos;re feeling and make sure you&apos;re feeling fully recovered.
          </p>
          <p>
            <strong>
              If you do not get a period in 2 weeks, please go ahead and schedule call the post-op
              call.
            </strong>
          </p>
        </>
      }
    />
  );
};

export default FollowUpCallDetail;
