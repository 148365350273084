import React from 'react';

import { Contact } from './Contact/Contact';
import { Content, Container } from './Footer.styled';
import { Legal } from './Legal/Legal';

const Footer = ({ $hasColoredBackground }: { $hasColoredBackground?: boolean }) => {
  return (
    <Container $hasColoredBackground={Boolean($hasColoredBackground)}>
      <Contact />
      <Content>
        <Legal />
      </Content>
    </Container>
  );
};

export default Footer;
