import React from 'react';

import { ValidLabel, ValidValue, DetailRow } from './CreditCard.styled';

type CreditCardDetailProps = {
  valid: string;
};

export const CreditCardDetail = ({ valid }: CreditCardDetailProps) => (
  <DetailRow>
    <ValidLabel>
      VALID
      <br />
      THRU
    </ValidLabel>
    <ValidValue>{valid}</ValidValue>
  </DetailRow>
);
