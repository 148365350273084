import { useMutation } from '@apollo/client';
import React, { FormEvent, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { Loader } from 'components/Loader/Loader';
import { Button } from 'components/v2/Buttons/Button';
import { LinkButton } from 'components/v2/Buttons/LinkButton/LinkButton';
import { TextField } from 'components/v2/Form';
import { Heading } from 'components/v2/Typography';
import { usePatient } from 'hooks';
import { utils } from 'kb-shared';
import { UPDATE_PATIENT_EMAIL } from 'kb-shared/graphql/mutations';
import {
  getAppleSsoIdToken,
  getCognitoAccessToken,
  getGoogleIdToken
} from 'kb-shared/utilities/api';
import { BugTracker } from 'kb-shared/utilities/bugTracker';
import KBContacts from 'kb-shared/utilities/kindbody_contacts';

import { showErrorToast, showSuccessToast } from '../../utilities/notificationUtils';
import { FormContent, FormContainer } from '../ForgotPassword/ForgotPasswordForm.styled';
import { ButtonWrapper, Form, SubTitleContainer } from './EmailChangeForm.styled';
import { UpdateEmailVariables, UpdateEmailResponse } from './EmailChangeForm.types';

const { isEmailValid } = utils;

export const EmailChangeForm = () => {
  const { patient, loading: loadingPatient } = usePatient();
  const [newEmail, setNewEmail] = useState('');
  const [newEmailRepeated, setNewEmailRepeated] = useState('');
  const [submitting, setSubmitting] = useState(false);

  const [verificationCode, setVerificationCode] = useState('');
  const [activeStep, setActiveStep] = useState<STEP>('requestChange');
  const history = useHistory();
  const [updatePatientEmail] = useMutation<UpdateEmailResponse, UpdateEmailVariables>(
    UPDATE_PATIENT_EMAIL,
    {
      onCompleted: response => {
        setSubmitting(false);
        if (response?.updatePatientEmail?.succeeded && activeStep === 'requestChange') {
          setVerificationCode('');
          setActiveStep('verifyChange');
        } else if (response?.updatePatientEmail?.succeeded && activeStep === 'verifyChange') {
          showSuccessToast(
            isGoogleOrApple() ? VERIFIED_CHANGE_PASSWORD_NEEDED_MESSAGE : VERIFIED_CHANGE_MESSAGE
          );
          onGoToLogout();
        } else {
          handleErrorCode(response?.updatePatientEmail?.errorCode);
        }
      },
      onError: error => {
        setSubmitting(false);
        BugTracker.notify(error, 'Change patient email');
        showErrorToast(UPDATE_FAILED_MESSAGE);
      }
    }
  );

  const onGoBack = () => {
    if (activeStep === 'requestChange') history.goBack();
    else setActiveStep('requestChange');
  };

  const isGoogleOrApple = () => getGoogleIdToken() || getAppleSsoIdToken();

  const onGoToLogout = () => {
    history.replace({
      pathname: '/logout',
      state: { redirectToPasswordReset: !!isGoogleOrApple(), email: newEmail }
    });
  };

  const onFormSubmit = async (e: FormEvent) => {
    e.preventDefault();
    const inputValidationErrors = getInputValidationErrors();
    if (inputValidationErrors.length !== 0) {
      showErrorToast(inputValidationErrors.join(' '));
      return;
    }

    setSubmitting(true);
    submitInput();
  };

  const getInputValidationErrors = () => {
    const errors = [];
    if (activeStep === 'requestChange' && !isEmailValid(newEmail)) {
      errors.push("New email address isn't valid.");
    }

    if (activeStep === 'requestChange' && newEmail.toLowerCase() === patient?.email.toLowerCase()) {
      errors.push("New email can't be the same as existing email.");
    }

    if (activeStep === 'verifyChange' && verificationCode.length !== 6) {
      errors.push("Verification code isn't valid.");
    }

    return errors;
  };

  const submitInput = () => {
    const variables: UpdateEmailVariables = { newEmail: newEmail.toLowerCase() };
    if (activeStep === 'verifyChange') {
      variables.code = verificationCode;
      const accessToken = getCognitoAccessToken();
      if (accessToken) {
        variables.accessToken = accessToken;
      }
    }
    updatePatientEmail({
      variables
    });
  };

  const handleErrorCode = (errorCode: string | null | undefined) => {
    BugTracker.notify(errorCode || 'Unspecified error', 'Change patient email');
    if (errorCode === 'LimitExceededException' || errorCode === 'TooManyRequestsException') {
      showErrorToast(
        `You have exceeded the limit for requesting email change. Please wait then try again, or contact ${KBContacts.navigatorEmail}`
      );
    } else if (errorCode === 'CodeMismatchException') {
      showErrorToast('Verification code incorrect, please check your email.');
    } else if (errorCode === 'ExpiredCodeException') {
      showErrorToast('Verification code has expired. Please go back and try again.');
    } else if (errorCode === 'WrongEmail') {
      showErrorToast(
        'We are unable to proceed with the request please try with the valid email address.'
      );
    } else {
      showErrorToast(UPDATE_FAILED_MESSAGE);
    }
  };

  const isInvalidInput = () => {
    if (activeStep === 'requestChange')
      return !Boolean(
        newEmail && newEmailRepeated && newEmail === newEmailRepeated && isEmailValid(newEmail)
      );
    else return !Boolean(verificationCode);
  };

  if (loadingPatient) return <Loader />;

  return (
    <FormContainer>
      <FormContent>
        <SubTitleContainer>
          <Heading tag="span" styledAs="h5">
            {activeStep === 'requestChange'
              ? ENTER_EMAIL_INSTRUCTION
              : ENTER_VERIFICATION_INSTRUCTION}
          </Heading>
        </SubTitleContainer>

        <Form onSubmit={onFormSubmit}>
          {activeStep === 'requestChange' && (
            <>
              <TextField
                id="current-email-input"
                label="Current email address"
                value={patient?.email}
                status="disabled"
              />
              <TextField
                id="new-email-input"
                label="New email address"
                value={newEmail}
                onChange={e => setNewEmail(removeWhitespaces(e.currentTarget.value))}
                placeholder="Enter your new email address"
                spellCheck={false}
                type="email"
              />
              <TextField
                id="verify-new-email-input"
                label="Verify new email address"
                value={newEmailRepeated}
                onChange={e => setNewEmailRepeated(removeWhitespaces(e.currentTarget.value))}
                placeholder="Verify your new email address"
                spellCheck={false}
                type="email"
              />
            </>
          )}
          {activeStep === 'verifyChange' && (
            <TextField
              id="new-email-verification-input"
              label="Verification code"
              value={verificationCode}
              onChange={e => setVerificationCode(removeWhitespaces(e.currentTarget.value))}
              placeholder="Enter verification code"
              type="number"
            />
          )}
          <ButtonWrapper>
            <Button
              category="primary"
              isDisabled={submitting || isInvalidInput()}
              label={activeStep === 'requestChange' ? REQUEST_VERIFICATION_BUTTON : VERIFY_BUTTON}
              fullWidth={true}
            />
          </ButtonWrapper>
        </Form>
        <LinkButton onClick={onGoBack} text="GO BACK" size="sm" fontStyle="semibold" />
      </FormContent>
    </FormContainer>
  );
};

type STEP = 'requestChange' | 'verifyChange';

const ENTER_EMAIL_INSTRUCTION =
  'Enter your new email address to receive a verification code that will enable you to change your email.';
const ENTER_VERIFICATION_INSTRUCTION =
  'To change your email, enter verification code sent to your new email.';
const REQUEST_VERIFICATION_BUTTON = 'REQUEST VERIFICATION CODE';
const VERIFY_BUTTON = 'VERIFY EMAIL CHANGE';
const UPDATE_FAILED_MESSAGE = 'Failed to update email address.';
const VERIFIED_CHANGE_MESSAGE = 'Your email has been changed! Please sign-in with your new email.';
const VERIFIED_CHANGE_PASSWORD_NEEDED_MESSAGE =
  'Your email has been changed! Please check your new email for verification code and assign new password to your account.';

const removeWhitespaces = (value: string) => value.replace(/\s/g, '');
