import styled from 'styled-components';

export const WidgetsContainer = styled.div`
  display: grid;
  padding-bottom: 0.5em;
  grid-template-columns: repeat(1, minmax(200px, 1fr));
  grid-template-rows: repeat(2, auto);

  @media ${({ theme }) => theme.queries.minTablet} {
    grid-template-columns: repeat(2, minmax(200px, 1fr));
    grid-template-rows: repeat(2, auto);
    grid-gap: 1.5em;
  }

  @media ${({ theme }) => theme.queries.tablet} {
    padding: 1.5em;
  }

  @media ${({ theme }) => theme.queries.minDesktop} {
    grid-template-columns: repeat(3, minmax(200px, 1fr));
    grid-template-rows: repeat(2, auto);
    margin-top: 1.5em;
  }
`;

export const ViewMemberBenefitButtonContainer = styled.div`
  padding: 1em 0;
  margin: 0 1.5em;

  @media ${({ theme }) => theme.queries.minDesktop} {
    padding-top: 2em;
    margin: 0;
  }
`;
