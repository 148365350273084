import { useQuery } from '@apollo/client';

import { Lab } from 'kb-shared';
import { BugTracker } from 'kb-shared/utilities/bugTracker';

import { QUERY_LAB } from './CreateAccount.graphql';

const isFalsyNonNumber = (value: unknown) =>
  !value && (typeof value !== 'number' || Number.isNaN(value));

export const useLab = (labId?: number) => {
  const { loading, data } = useQuery<{ lab: Lab }>(QUERY_LAB, {
    skip: isFalsyNonNumber(labId),
    variables: { labId: labId },
    onError: error => {
      BugTracker.notify(error, `Failed to load lab`, `labId: ${labId}`);
    }
  });

  return {
    loading,
    lab: data?.lab
  };
};
