function getEuploidProbability(age: number): number {
  const euploidHash: Record<number, number> = {
    36: 0.564,
    37: 0.486,
    38: 0.466,
    39: 0.44,
    40: 0.359,
    41: 0.327,
    42: 0.285,
    43: 0.206
  };

  if (age < 36) {
    return 0.574;
  } else if (age > 43) {
    return 0.127;
  } else {
    return euploidHash[age];
  }
}

function getBlastProbability(age: number) {
  const constant = age < 36 ? 0.95 : 0.85;

  return constant * Math.pow(Math.E, 2.8043 - 0.1112 * age);
}

export function calculateEggCountRange(age: number, amh: number, afc: number) {
  let eggCountRange;

  // only calculate when all three values are truthy
  let eggCountPrediction = Math.exp(2.32 - 0.017 * age + 0.039 * amh + 0.03 * afc);

  if (eggCountPrediction) {
    eggCountPrediction = Math.floor(eggCountPrediction);

    if (eggCountPrediction < 6) {
      eggCountRange = '<5';
    } else if (eggCountPrediction === 6) {
      eggCountRange = '<6';
    } else if (eggCountPrediction > 15) {
      eggCountRange = '15+';
    } else {
      // show a range to user instead of actual value
      const lowEstimate = eggCountPrediction - 4;
      const highEstimate = eggCountPrediction - 1;
      eggCountRange = lowEstimate.toString() + '-' + highEstimate.toString();
    }

    return eggCountRange;
  }
  return null;
}

export function calculateBirthProbability(age: number, eggs: number) {
  let birthProbability = 0;

  if (age > 24 && age < 45) {
    // only calculate inside of age bounds
    let ageValue = age;
    if (ageValue >= 25 && ageValue <= 35) {
      // study model dictates that results from 25-35 inclusive treat the age as 35
      ageValue = 35;
    }

    const euploidProbability = getEuploidProbability(ageValue);
    const blastProbability = getBlastProbability(ageValue);

    const singleEggProbability = 1 - 0.6 * euploidProbability * blastProbability;

    birthProbability = 1 - Math.pow(singleEggProbability, eggs || 0);

    birthProbability = Math.round(birthProbability * 100);

    // Use Math.round for every float except for 99.x (can't be 100%),
    if (birthProbability === 100) {
      birthProbability = 99;
    }
    return birthProbability;
  }

  // If outside the age range, return null. Do no calculate.
  return null;
}
