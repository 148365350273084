import theme from 'kb-shared/theme';
import styled from 'styled-components';

export const UnpaidInvoicesIconContainer = styled.div`
  margin-top: -15px;
  margin-left: -20px;
  position: absolute;

  @media screen and (max-width: ${theme.breakpoints.tabletPortrait}) {
    display: none;
  }
`;

export const UnpaidInvoicesContainer = styled.div`
  border-radius: 16px;
  box-shadow: ${theme.shadows.container.glowRed};
  padding: 1em 3em 1em 3em;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;

  > h2 {
    margin-left: 40px;
  }

  > button {
    max-width: 500px;
  }

  @media screen and (max-width: ${theme.breakpoints.smallDesktop}) {
    flex-direction: column;
    justify-content: center;
    text-align: center;

    > button {
      margin-top: 1em;
      width: 100%;
    }

    > h2 {
      margin-left: 0;
    }
  }

  @media screen and (max-width: ${theme.breakpoints.tabletPortrait}) {
    padding: 4em 1.5em;
    background-color: ${theme.colors.semantic.lightPink};
    border-radius: 0;
    box-shadow: none;
    background-repeat: no-repeat;
    background-position: top 16px left 70%;
    background-image: url("data:image/svg+xml,%3Csvg width='153' height='136' viewBox='0 0 153 136' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M75.8597 29.072C78.5852 29.072 81.0079 31.4947 81.0079 33.9173V72.68C81.0079 75.4055 78.5852 77.5253 75.8597 77.5253C73.4371 77.5253 71.0144 75.4055 71.0144 72.68V33.9173C71.0144 31.4947 73.4371 29.072 75.8597 29.072ZM83.4306 96.9067C83.4306 101.146 80.0994 104.175 75.8597 104.175C71.9229 104.175 68.5917 101.146 68.5917 96.9067C68.5917 92.9698 71.9229 89.6387 75.8597 89.6387C80.0994 89.6387 83.4306 92.9698 83.4306 96.9067ZM49.8161 0H102.206C109.777 0 116.742 4.23967 120.679 10.5992L148.843 56.6298C153.082 63.595 153.082 72.3772 148.843 79.3423L120.679 125.373C116.742 131.732 109.777 135.669 102.206 135.669H49.8161C42.2452 135.669 35.2801 131.732 31.3432 125.373L3.17975 79.3423C-1.05992 72.3772 -1.05992 63.595 3.17975 56.6298L31.3432 10.5992C35.2801 4.23967 42.2452 0 49.8161 0ZM11.6591 61.778C9.23642 65.412 9.23642 70.2573 11.6591 74.1942L39.5197 120.225C41.9424 123.859 45.8792 125.979 49.8161 125.979H102.206C106.143 125.979 110.08 123.859 112.503 120.225L140.363 74.1942C142.786 70.2573 142.786 65.412 140.363 61.778L112.503 15.7473C110.08 12.1133 106.143 9.69067 102.206 9.69067H49.8161C45.8792 9.69067 41.9424 12.1133 39.5197 15.7473L11.6591 61.778Z' fill='%23F0C8BF'/%3E%3C/svg%3E%0A");

    > button {
      margin-top: 1.5em;
      width: 100%;
    }
  }

  @media screen and (min-width: ${theme.breakpoints.tabletPortrait}) {
    background: ${theme.gradients.containerRadial}, ${theme.colors.semantic.pink};
  }
`;

export const Container = styled.div`
  padding: 0;

  @media ${({ theme }) => theme.queries.tablet} {
    padding: 2em 1.5em 1em 1.5em;
  }

  @media ${({ theme }) => theme.queries.minDesktop} {
    padding: 2em 0 0 0;
    margin-bottom: 0.5em;
  }
`;
