import { gql } from '@apollo/client';

import { DropdownOption } from 'kb-shared';

import { AttachmentInformation } from '../Documents.types';

export interface AllAttachmentResponse {
  allPatientAttachments: AttachmentInformation[];
  allKindbodyAttachments: AttachmentInformation[];
  fileUploadPurposes: DropdownOption[];
}

export const AllAttachmentsQuery = gql`
  {
    allPatientAttachments {
      id
      fileName
      presignedUrl
      createdAt
      uuid
      category
      description
    }
    allKindbodyAttachments {
      id
      fileName
      presignedUrl
      createdAt
      purpose
    }
    fileUploadPurposes {
      id
      label
      value
    }
  }
`;
