import { useMutation } from '@apollo/client';
import React, { FormEvent, useEffect, useState } from 'react';

import { ReactComponent as PlusIcon } from 'assets/icons/plusYellow.svg';
import { Button } from 'components/v2/Buttons/Button';
import { TextField, DateField } from 'components/v2/Form';
import { UPDATE_DECLARED_PARTNER } from 'kb-shared/graphql/mutations';
import theme from 'kb-shared/theme';
import { BugTracker } from 'kb-shared/utilities/bugTracker';
import { isEmailValid, isBirthDateValid } from 'kb-shared/utilities/validation';
import { useCreatePartnerInvite } from 'screens/InterstitialModal/hooks/useCreatePartnerInvite';
import { STATUS } from 'screens/InterstitialModal/InterstitialModal.graphql';
import { dateToString, getUtcDate } from 'utilities/formatDate';
import { showErrorToast } from 'utilities/notificationUtils';

import { Container, Columns } from './PartnerInviteForm.styled';
import { InvitePartnerFormProps, FormState } from './PartnerInviteForm.types';

export const PartnerInviteForm = ({ onDone }: InvitePartnerFormProps) => {
  const { createPartnerInvite, loading, error } = useCreatePartnerInvite();
  const [updatePatient] = useMutation(UPDATE_DECLARED_PARTNER);

  const [formState, setFormState] = useState<FormState>({
    firstName: '',
    lastName: '',
    email: '',
    birthDate: null
  });

  useEffect(() => {
    if (!error) return;

    if (error.message.includes('Email already received invitation')) {
      showErrorToast('This email address already received invitation.');
      return;
    }

    if (error.message?.includes("You can't send invite to existing partner")) {
      showErrorToast("You can't send invite to existing partner");
      return;
    }

    showErrorToast('Something went wrong while trying to add partner. Please try again later.');
    BugTracker.notify(error, 'Send Partner Invite Error');
  }, [error]);

  const { firstName, lastName, email, birthDate } = formState;
  const isEmailEntered = email && isEmailValid(email);
  const isFormValid = Boolean(
    firstName && lastName && isEmailEntered && birthDate && isBirthDateValid(birthDate)
  );

  const updateFormState = (key: string, event: FormEvent<HTMLInputElement>) => {
    const value = event?.currentTarget?.value;
    setFormState({ ...formState, [key]: value });
  };

  const updateDate = (date: Date | null) => {
    setFormState({ ...formState, birthDate: date });
  };

  const onSendInvite = async () => {
    const { firstName, lastName, email, birthDate } = formState;

    const formattedDate = birthDate ? dateToString(getUtcDate(birthDate)) : '';
    let response;
    try {
      response = await createPartnerInvite(email, formattedDate, firstName, lastName);
    } catch {
      return;
    }

    const partnerInvite = response?.data?.createPartnerInvite?.partnerInvite;

    if (partnerInvite) onDone();

    updatePatient({
      variables: { hasPartnersPatientDeclared: true },
      refetchQueries: [{ query: STATUS }]
    }).catch(error => {
      BugTracker.notify(error, 'Declaring partner status failed');
    });
  };

  return (
    <Container>
      <Columns>
        <TextField
          required
          type="text"
          status="default"
          id="input-first-name"
          placeholder="Enter legal first name"
          label="partner legal first name"
          value={formState.firstName}
          onChange={event => updateFormState('firstName', event)}
          labelBg={theme.colors.neutral.lavenderWhite}
        />
        <TextField
          required
          type="text"
          status="default"
          id="input-last-name"
          placeholder="Enter legal last name"
          label="partner legal last name"
          value={formState.lastName}
          onChange={event => updateFormState('lastName', event)}
          labelBg={theme.colors.neutral.lavenderWhite}
        />
        <TextField
          required
          type="text"
          status="default"
          id="input-email"
          placeholder="Enter email address"
          label="partner email address"
          value={formState.email}
          onChange={event => updateFormState('email', event)}
          labelBg={theme.colors.neutral.lavenderWhite}
        />
        <DateField
          required
          id="input-birth-date"
          status="default"
          placeholder="Select date of birth"
          label="partner date of birth"
          value={formState.birthDate}
          onChange={date => updateDate(date)}
          labelBg={theme.colors.neutral.lavenderWhite}
        />
        <Button
          size="lg"
          gap="lg"
          label={'INVITE PARTNER'}
          onClick={onSendInvite}
          category="primary"
          isDisabled={loading || !isFormValid}
          leftIcon={<PlusIcon />}
        />
      </Columns>
    </Container>
  );
};
