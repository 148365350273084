import { useState } from 'react';

export const useDropdownMenu = () => {
  const [showMenu, setShowMenu] = useState<Boolean>(false);
  const toggleMenu = (value?: Boolean) => {
    if (value !== undefined) {
      setShowMenu(value);
      return;
    }

    setShowMenu(!showMenu);
  };
  return { showMenu, toggleMenu };
};
