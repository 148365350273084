import * as msal from '@azure/msal-browser';
import { useState } from 'react';

import config from '../config';

const useLoginViaMsal = () => {
  const [responseState, setResponseState] = useState<ResponseStateType>({
    idtoken: null as string | null,
    finished: false as boolean,
    error: null as string | null
  });
  const loginViaMsal = async (): Promise<any> => {
    const urlParams = new URLSearchParams(window.location.search);
    const manualIdtoken = urlParams.get('manualIdtoken');
    if (manualIdtoken) {
      setResponseState({
        idtoken: manualIdtoken,
        finished: true,
        error: null
      });
      return;
    }

    const msalConfig = {
      auth: {
        clientId: config.clientId,
        authority: config.authority,
        redirectUri: `${window.location.origin}/login-sso/azure-medtronic`
      }
    };
    const msalInstance = new msal.PublicClientApplication(msalConfig);

    const loginRequest = {
      scopes: []
    };

    function handleMsalLoginResponse(response: any) {
      if (response !== null) {
        setResponseState({
          idtoken: response.idToken,
          finished: true,
          error: null
        });
      } else {
        // full page redirect to https://login.microsoftonline.com/...
        msalInstance.loginRedirect(loginRequest);
      }
    }

    msalInstance
      .handleRedirectPromise()
      .then(handleMsalLoginResponse)
      .catch(error => {
        setResponseState({
          idtoken: null,
          finished: true,
          error: `${error.name} ${error.message}`
        });
      });
  };

  return { responseState, loginViaMsal };
};

type ResponseStateType = {
  idtoken: string | null;
  finished: boolean;
  error: string | null;
};

export default useLoginViaMsal;
