import React from 'react';

import { V2_COMPONENT_CLASS_NAME } from 'components/v2/common.styled';

import { getInputStates } from '../../utils';
import { masks } from './PhoneInputBasic.constants';
import { MaskedInput, RegularInput } from './PhoneInputBasic.styled';
import { Props } from './PhoneInputBasic.types';

export const PhoneInputBasic = ({
  status = 'default',
  onChange,
  numberInputId,
  value,
  country = 'US',
  placeholder,
  onBlur,
  onFocus
}: Props) => {
  const { isDisabled, isReadOnly, isValid } = getInputStates(status);
  const isFilled = Boolean(value);
  const inputMask = masks[country];

  return (
    <>
      {inputMask && (
        <MaskedInput
          className={V2_COMPONENT_CLASS_NAME}
          id={numberInputId}
          status={status}
          mask={inputMask}
          value={value}
          placeholder={placeholder}
          disabled={isDisabled}
          aria-disabled={isDisabled}
          aria-invalid={!isValid}
          aria-readonly={isReadOnly}
          onChange={onChange}
          isFilled={isFilled}
          onBlur={onBlur}
          onFocus={onFocus}
        />
      )}
      {!inputMask && (
        <RegularInput
          className={V2_COMPONENT_CLASS_NAME}
          id={numberInputId}
          status={status}
          placeholder={placeholder}
          value={value}
          disabled={isDisabled}
          aria-disabled={isDisabled}
          aria-invalid={!isValid}
          aria-readonly={isReadOnly}
          onChange={onChange}
          isFilled={isFilled}
          onBlur={onBlur}
          onFocus={onFocus}
        />
      )}
    </>
  );
};
