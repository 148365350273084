import React from 'react';

import { obStepFactories, ObJourneyData } from 'kb-shared';

import ObDeliveryDetails from '../details/obstetrics/ObDeliveryDetails';
import ObPostPartumDay2Details from '../details/obstetrics/ObPostPartumDay2Details';
import ObWeek24Details from '../details/obstetrics/ObWeek24Details';
import ObWeek30Details from '../details/obstetrics/ObWeek30Details';
import ObPostPartumDay14Substeps from '../substeps/obstetrics/ObPostPartumDay14Substeps';
import ObPostPartumDay7Substeps from '../substeps/obstetrics/ObPostPartumDay7Substeps';
import ObPostPartumWeek4Substeps from '../substeps/obstetrics/ObPostPartumWeek4Substeps';
import ObPostPartumWeek6Substeps from '../substeps/obstetrics/ObPostPartumWeek6Substeps';
import ObWeek0Substeps from '../substeps/obstetrics/ObWeek0Substeps';
import ObWeek10to12Substeps from '../substeps/obstetrics/ObWeek10to12Substeps';
import ObWeek16Substeps from '../substeps/obstetrics/ObWeek16Substeps';
import ObWeek20Substeps from '../substeps/obstetrics/ObWeek20Substeps';
import ObWeek26To28Substeps from '../substeps/obstetrics/ObWeek26To28Substeps';
import ObWeek28Substeps from '../substeps/obstetrics/ObWeek28Substeps';
import ObWeek32Substeps from '../substeps/obstetrics/ObWeek32Substeps';
import ObWeek35Substeps from '../substeps/obstetrics/ObWeek35Substeps';
import ObWeek36Substeps from '../substeps/obstetrics/ObWeek36Substeps';
import ObWeek37Substeps from '../substeps/obstetrics/ObWeek37Substeps';
import ObWeek38Substeps from '../substeps/obstetrics/ObWeek38Substeps';
import ObWeek39Substeps from '../substeps/obstetrics/ObWeek39Substeps';
import ObWeek40And3DaysSubsteps from '../substeps/obstetrics/ObWeek40And3DaysSubsteps';
import ObWeek40And6DaysSubsteps from '../substeps/obstetrics/ObWeek40And6DaysSubsteps';
import ObWeek40Substeps from '../substeps/obstetrics/ObWeek40Substeps';
import ObWeek41And2DaysSubsteps from '../substeps/obstetrics/ObWeek41And2DaysSubsteps';
import ObWeek41Substeps from '../substeps/obstetrics/ObWeek41Substeps';
import { JourneyData, JourneyFactory } from './types';

const journeyDataToObData = ({ cyclePlan }: JourneyData): ObJourneyData => {
  const cycleCompleted = cyclePlan && cyclePlan.finished !== 'false' && cyclePlan.finished !== null;
  const expectedDeliveryDate = cyclePlan.expectedDeliveryDate
    ? new Date(cyclePlan.expectedDeliveryDate)
    : undefined;

  const deliveryDate = cyclePlan.deliveryDate ? new Date(cyclePlan.deliveryDate) : undefined;

  return { finished: cycleCompleted, expectedDeliveryDate, deliveryDate };
};

export const obJourneyFactory: JourneyFactory = journeyData => {
  const data = journeyDataToObData(journeyData);
  return {
    name: 'Obstetrics',
    steps: [
      {
        ...obStepFactories.initialVisit,
        substeps: <ObWeek0Substeps />
      },
      {
        ...obStepFactories.week10to12(data),
        substeps: <ObWeek10to12Substeps />
      },
      {
        ...obStepFactories.week16(data),
        substeps: <ObWeek16Substeps />
      },
      {
        ...obStepFactories.week20(data),
        substeps: <ObWeek20Substeps />
      },
      {
        ...obStepFactories.week24(data),
        detail: <ObWeek24Details />
      },
      {
        ...obStepFactories.week26to28(data),
        substeps: <ObWeek26To28Substeps />
      },
      {
        ...obStepFactories.week28(data),
        substeps: <ObWeek28Substeps />
      },
      {
        ...obStepFactories.week30(data),
        detail: <ObWeek30Details />
      },
      {
        ...obStepFactories.week32(data),
        substeps: <ObWeek32Substeps />
      },
      {
        ...obStepFactories.week35(data),
        substeps: <ObWeek35Substeps />
      },
      {
        ...obStepFactories.week36(data),
        substeps: <ObWeek36Substeps />
      },
      {
        ...obStepFactories.week37(data),
        substeps: <ObWeek37Substeps />
      },
      {
        ...obStepFactories.week38(data),
        substeps: <ObWeek38Substeps />
      },
      {
        ...obStepFactories.week39(data),
        substeps: <ObWeek39Substeps />
      },
      {
        ...obStepFactories.week40(data),
        substeps: <ObWeek40Substeps />
      },
      {
        ...obStepFactories.week40and3days(data),
        substeps: <ObWeek40And3DaysSubsteps />
      },
      {
        ...obStepFactories.week40and6days(data),
        substeps: <ObWeek40And6DaysSubsteps />
      },
      {
        ...obStepFactories.week41(data),
        substeps: <ObWeek41Substeps />
      },
      {
        ...obStepFactories.week41and2days(data),
        substeps: <ObWeek41And2DaysSubsteps />
      },
      {
        ...obStepFactories.delivery(data),
        detail: <ObDeliveryDetails />
      },
      {
        ...obStepFactories.postPartumDay2(data),
        detail: <ObPostPartumDay2Details />
      },
      {
        ...obStepFactories.postPartumDay7(data),
        substeps: <ObPostPartumDay7Substeps />
      },
      {
        ...obStepFactories.postPartumDay14(data),
        substeps: <ObPostPartumDay14Substeps />
      },
      {
        ...obStepFactories.postPartumWeek4(data),
        substeps: <ObPostPartumWeek4Substeps />
      },
      {
        ...obStepFactories.postPartumWeek6(data),
        substeps: <ObPostPartumWeek6Substeps />
      }
    ]
  };
};

export const compact: JourneyFactory = journeyData => {
  const data = journeyDataToObData(journeyData);

  return {
    name: 'Obstetrics',
    steps: [
      obStepFactories.initialVisit,
      obStepFactories.firstTrimester(data),
      obStepFactories.secondTrimester(data),
      obStepFactories.thirdTrimester(data),
      obStepFactories.delivery(data),
      obStepFactories.postPartum(data)
    ]
  };
};
