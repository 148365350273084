import { useState } from 'react';

import { ValidPromo, graphql, AppointmentProduct, AppointmentType } from 'kb-shared';

const usePromo = (product: AppointmentProduct<AppointmentType>) => {
  const [validPromo, setValidPromo] = useState<ValidPromo | null>(null);

  const validatePromo = (
    code: string | null
  ): Promise<{ validPromo?: ValidPromo; error?: string }> => {
    return new Promise(resolve => {
      if (!code || code.length === 0) resolve({ error: 'You must enter a code.' });

      graphql.requests
        .checkPromoCode(code || '', product)
        .then((result: ValidPromo) => {
          setValidPromo(result);
          resolve({ validPromo: result });
        })
        .catch((err: string) => {
          resolve({ error: err });
        });
    });
  };

  return {
    validPromo,
    validatePromo
  };
};

export { usePromo };
export default usePromo;
