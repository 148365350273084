import React from 'react';

import { Main, Link } from './PartnerSearchButton.styles';

interface Props {
  text: string;
  linkText: string;
  onClick: () => void;
}

export const PartnerSearchButton = ({ text, linkText, onClick }: Props) => {
  return (
    <Main>
      {`${text} `}
      <Link onClick={onClick}>{linkText}</Link>
    </Main>
  );
};
