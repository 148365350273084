import React from 'react';

import { Loader } from 'components/Loader/Loader';
import { analytics } from 'utilities/analytics';

import { LMPEntered } from './components/LMPEntered';
import { NoConfirmedPregnancy } from './components/NoConfirmedPregnancy';
import { NoLMPEntered } from './components/NoLMPEntered';
import { usePregnancyCalculator } from './hooks/use-pregnancy-calculator';

export const PregnancyCalculatorWidget = ({ patientIsFemale }: { patientIsFemale: boolean }) => {
  const {
    state,
    gestationalAgeInDays,
    estimatedDueDate,
    isCancelEnabled,
    updatePregnancyAcknowledgment
  } = usePregnancyCalculator(patientIsFemale);

  if (!patientIsFemale) return null;

  const onCancelClick = isCancelEnabled ? () => updatePregnancyAcknowledgment(false) : undefined;

  if (state === 'no-confirmed-pregnancy') {
    const onPregnantClick = () => {
      analytics.track(analytics.EVENTS.PREGNANCY_INDICATED);
      updatePregnancyAcknowledgment(true);
    };
    return <NoConfirmedPregnancy onPregnantClick={onPregnantClick} />;
  } else if (state === 'lmp-entered' && gestationalAgeInDays && estimatedDueDate) {
    return (
      <LMPEntered
        onCancelClick={onCancelClick}
        gestationalAgeInDays={gestationalAgeInDays}
        estimatedDueDate={estimatedDueDate}
      />
    );
  } else if (state === 'no-lmp-entered') {
    return <NoLMPEntered onCancelClick={onCancelClick} />;
  }

  return <Loader />;
};
