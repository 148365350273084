import moment from 'moment';
import React, { useState } from 'react';

import { ReactComponent as DownCaretIcon } from 'assets/icons/down-caret.svg';
import { ReactComponent as UpCaretIcon } from 'assets/icons/up-caret.svg';
import { Patient } from 'kb-shared';
import { analytics } from 'utilities/analytics';

import { HomeTestingResultType, ResultType } from '../Results.types';
import {
  HelpIcon,
  HormoneBox,
  HormoneChart,
  HormoneDescription,
  HormoneTitle,
  Recommendation,
  ResultBox,
  ResultHeader,
  ResultTitle,
  ResultWrapper,
  ToggleResultIcon,
  Wrapper
} from './AtHomeTesting.styled';
import { HormoneHelperModal } from './HormoneHelperModal';
import { HormoneValueRange } from './HormoneValueRange';
import { ScheduleCTA } from './ScheduleCTA/ScheduleCTA';

export const AtHomeTesting = ({
  patient,
  results
}: {
  results: Array<HomeTestingResultType>;
  patient: Patient;
}) => {
  const [helperModal, setHelperModal] = useState<{
    open: boolean;
    hormone: ResultType | null;
  }>({
    open: false,
    hormone: null
  });
  const firstResultId: string | null = results[0]?.id ?? null;

  const [openedReport, setOpenedReport] = useState<string | null>(firstResultId);
  const toggleOpenedReport = (id: string) => (event: React.MouseEvent<HTMLAnchorElement>) => {
    if (event.currentTarget !== event.target && event.target instanceof HTMLAnchorElement) {
      return;
    }

    setOpenedReport(openedReport === id ? null : id);
  };

  const openHormoneHelperModal = (hormone: ResultType) => {
    setHelperModal({
      open: true,
      hormone
    });
  };

  const closeHormoneHelper = () => {
    setHelperModal({
      open: false,
      hormone: null
    });
  };

  return (
    <Wrapper>
      {results.map(result => (
        <ResultWrapper key={result.id}>
          <ResultHeader onClick={toggleOpenedReport(result.id)}>
            <div>
              <ResultTitle>At-Home Fertility Test</ResultTitle>
              <p>
                Tested on {moment(result.createdAt).format('MM/DD/YY')} ({result.customCode})
                {result.pdfLink && ' | '}
                {result.pdfLink && (
                  <a
                    href={result.pdfLink}
                    onClick={onViewResults}
                    target="_blank"
                    rel="noreferrer noopener"
                  >
                    View Lab Result
                  </a>
                )}
              </p>
            </div>
            <ToggleResultIcon>
              {openedReport === result.id ? (
                <UpCaretIcon className="icon" />
              ) : (
                <DownCaretIcon className="icon" />
              )}
            </ToggleResultIcon>
          </ResultHeader>

          {openedReport === result.id && [
            <ScheduleCTA key={result.id} patient={patient} />,
            result.labOrderResultValues.map(value => (
              <ResultBox key={value.shortName}>
                <HormoneBox>
                  <HormoneTitle>{value.shortName}</HormoneTitle>
                  <HormoneDescription>
                    {value.fullName}{' '}
                    <HelpIcon onClick={() => openHormoneHelperModal(value)}>?</HelpIcon>
                  </HormoneDescription>
                  <Recommendation>{value.recommendation}</Recommendation>
                </HormoneBox>

                <HormoneChart>
                  <HormoneValueRange
                    low={value.low}
                    value={value.value}
                    high={value.high}
                    units={value.units}
                  />
                </HormoneChart>
              </ResultBox>
            ))
          ]}
        </ResultWrapper>
      ))}
      <HormoneHelperModal
        open={helperModal.open}
        hormone={helperModal.hormone}
        onDismiss={closeHormoneHelper}
      />
    </Wrapper>
  );
};

const onViewResults = () => {
  analytics.track(analytics.EVENTS.AHT_LABS_VIEWED);
};
