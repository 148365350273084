import styled from 'styled-components';

export const Header = styled.div`
  margin-bottom: 8px;
`;

export const Result = styled.div`
  border-radius: 16px;
  background: ${({ theme }) => theme.colors.neutral.navy};
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 12px 24px 16px 24px;
  gap: 16px;
  min-height: 130px;
`;

export const ResultContainer = styled.div`
  display: flex;
  gap: 1.5em;
  margin: 1.5em 0;
  flex-direction: column;
`;

export const ResultValue = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: baseline;
`;
