import styled from 'styled-components';

import { SpaceContentProps } from './LocationCards.types';

export const LocationContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-content: center;
  align-items: stretch;

  ${(props: SpaceContentProps) => `
    justify-content: ${props.centerContent ? 'space-evenly' : 'flex-start'};
  `}
  margin-top: 40px;
  @media screen and (max-width: 1288px) {
    justify-content: space-evenly;
  }
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tabletLandscape}) {
    flex-direction: column;
  }
  width: 100%;
  min-height: 100px;
`;

export const LoaderContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 32px;
`;
