import styled from 'styled-components';

export const SectionHeader = styled.div`
  display: flex;
  justify-content: flex-start;
  gap: 8px;

  svg {
    width: 36px;
    height: 36px;
    flex-shrink: 0;
  }
`;
