import { useLazyQuery } from '@apollo/client';
import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import { Loader } from 'components/Loader/Loader';
import { TextField } from 'components/v2/Form';
import { StepTrackerLayout } from 'components/v2/Layouts/StepTrackerLayout/StepTrackerLayout';
import { useFeatureFlags } from 'hooks';
import usePatient from 'hooks/usePatient';
import KBContacts from 'kb-shared/utilities/kindbody_contacts';
import { ActionButtons } from 'screens/ActivateMembership/components/ActionButtons/ActionButtons';
import { RECOGNIZE_PATIENT_ENTERPRISE_MEMBERSHIP } from 'screens/ActivateMembership/EnterAccessCode/ActivateMembershipScreen.graphql';
import { graphQLErrorMessage } from 'utilities/errors';
import { showErrorToast, showSuccessToast } from 'utilities/notificationUtils';
import { RoutesPath } from 'utilities/pageUrl';

import { Container, Form, ContactText } from './ActivateMembershipScreen.styled';
import { Result } from './ActivateMembershipScreen.types';

export const ActivateMembershipScreen = () => {
  const history = useHistory();
  const [code, setCode] = useState('');
  const { patient, loading: loadingPatient } = usePatient();
  const { benefitRegistrationViaFnLnDob } = useFeatureFlags();

  const [recognizePatientEnterpriseMembership, { loading, error, data }] = useLazyQuery<Result>(
    RECOGNIZE_PATIENT_ENTERPRISE_MEMBERSHIP
  );

  useEffect(() => {
    if (!data) return;
    if (data?.recognizePatientEnterpriseMembership?.walmartWorkflow) {
      showSuccessToast(ACCESS_CODE_FOUND_FOR_WALMART_FLOW);
      history.push(RoutesPath.ACTIVATE_MEMBERSHIP_WALMART_WIN_BID, { code: code });
    } else if (data?.recognizePatientEnterpriseMembership?.requireUniqueId) {
      if (benefitRegistrationViaFnLnDob) {
        history.push(RoutesPath.VERIFY_MEMBERSHIP_ELIGIBILITY, { code: code });
      } else {
        history.push(RoutesPath.ENTER_MEMBERSHIP_UNIQUE_ID, { code: code });
      }
    } else {
      history.push(RoutesPath.CONFIRM_MEMBERSHIP_ACTIVATION, { code: code });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  useEffect(() => {
    if (!error) return;
    showErrorToast(graphQLErrorMessage(error));
  }, [error]);

  useEffect(() => {
    if (!patient || loadingPatient) return;
    if (!!patient.patientMembership?.membership?.id) {
      history.push('/');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [patient, loadingPatient]);

  const onSubmitCode = () => {
    recognizePatientEnterpriseMembership({
      variables: {
        employerAccessCode: code
      }
    });
  };

  if (loadingPatient) return <Loader absoluteCentered />;

  return (
    <StepTrackerLayout
      title={'Activate Membership'}
      stepNames={['Services', 'Location', 'Activate']}
      selectedStep={2}
      renderMainContent={() => {
        return (
          <Container>
            <Form>
              <TextField
                type="text"
                status="default"
                id="input-employer-access-code"
                placeholder="Enter access code"
                label="EMPLOYER ACCESS CODE"
                value={code}
                onChange={event => setCode(event.currentTarget.value)}
              />
            </Form>
            <ContactText>
              If you do not have your Employer Access Code please contact a member of your HR
              benefits team or email Kindbody at{' '}
              <a href={`mailto:${KBContacts.employeeBenefitsEmail}`}>
                {KBContacts.employeeBenefitsEmail}
              </a>
            </ContactText>
            <ActionButtons
              onBack={() => history.goBack()}
              onForward={onSubmitCode}
              forwardDisabled={!code || loading}
            />
          </Container>
        );
      }}
    />
  );
};

const ACCESS_CODE_FOUND_FOR_WALMART_FLOW =
  "Employer Access Code found! Please enter your Walmart WIN or BID and Associate's Date of Birth";
