import React from 'react';

import StepDescription from './StepDescription';
import { Description } from './StepDetail';

const IUIProcedureDetail = () => {
  return (
    <>
      <StepDescription
        description={
          <Description>
            Your care team will schedule your IUI procedure once your provider determines
            you&apos;re ready. Please note that this may be in a different location than where you
            have been coming for your prior appointments.
          </Description>
        }
      />
    </>
  );
};

export default IUIProcedureDetail;
