import jwt_decode from 'jwt-decode';

export interface AppleAuthJsonPayload {
  auth_time: number;
  email: string;
  email_verified: string;
  exp: number;
  iat: number;
  iss: string;
}

const decodeInBrowser = (jwtToken: string) => {
  const base64Url = jwtToken.split('.')[1];
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');

  const jsonPayload = decodeURIComponent(
    atob(base64)
      .split('')
      .map(c => '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2))
      .join('')
  );

  return JSON.parse(jsonPayload);
};

export const decodeJwtPayload = (jwtToken: string): AppleAuthJsonPayload => {
  if (!window || !window.atob) {
    return jwt_decode(jwtToken);
  } else {
    return decodeInBrowser(jwtToken);
  }
};
