import React, { ChangeEvent } from 'react';

import { Checkbox } from 'components/v2/Inputs';

import { CheckboxGroup } from './LegalAgreements.styled';
import { SmsConsentLabel } from './SmsConsentLabel';

export function LegalAgreements({
  sendSmsNotificationSelected,
  sendMarketingEmailSelected,
  onSmsNotificationChange,
  onMarketingEmailChange
}: {
  sendSmsNotificationSelected: boolean;
  sendMarketingEmailSelected: boolean;
  onSmsNotificationChange: (event: ChangeEvent<HTMLInputElement>) => void;
  onMarketingEmailChange: (event: ChangeEvent<HTMLInputElement>) => void;
}) {
  return (
    <CheckboxGroup>
      <Checkbox
        id="sms-checkbox"
        label={<SmsConsentLabel />}
        isChecked={sendSmsNotificationSelected}
        onChange={onSmsNotificationChange}
      />
      <Checkbox
        id="email-checkbox"
        label="I agree to receive marketing emails from Kindbody"
        isChecked={sendMarketingEmailSelected}
        onChange={onMarketingEmailChange}
      />
    </CheckboxGroup>
  );
}
