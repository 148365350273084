import React, { ChangeEvent, useEffect, useRef, useState } from 'react';
import Loader from 'react-loading';

import { CATEGORY } from 'api/pf2/files';
import close from 'assets/icons/close-icon.svg';
import plus from 'assets/icons/plusYellow.svg';
import Modal from 'components/Modal';
import { useTheme } from 'styled-components';

import { useUploadFile } from './UploadButton.hooks';
import {
  ButtonErrorText,
  ClosePreviewBtn,
  FileInput,
  Icon,
  MainText,
  UploadBtn,
  UploadBtnContainer,
  UploadPreview,
  UploadPreviewBtn,
  UploadPreviewFileName,
  UploadPreviewImg,
  UploadPreviewImgContainer,
  UploadPreviewImgFull,
  UploadPreviewOptions
} from './UploadButton.styled';

type Props = {
  label: string;
  category: CATEGORY;
  onImageUrlChange: (category: CATEGORY, id: number, url: string) => void;
  readonly?: boolean;
  insuranceCardUrl?: string;
};

const MAX_FILE_UPLOAD_SIZE = 5 * 1000 * 1000; // 5MB

export const UploadButton = ({
  category,
  label,
  onImageUrlChange,
  readonly,
  insuranceCardUrl
}: Props) => {
  const theme = useTheme();
  const inputRef = useRef<HTMLInputElement>(null);
  const [file, setFile] = useState<File | null>(null);
  const [fileTooBig, setFileTooBig] = useState(false);
  const [showPreview, setShowPreview] = useState(false);
  const [fileUrl, setFileUrl] = useState(insuranceCardUrl);
  const {
    upload,
    uploading,
    error: errorUploading,
    errorPatientMessage: errorPatientMessageUploading
  } = useUploadFile(category, file);

  const onClickUpload = () => {
    inputRef.current && inputRef.current.click();
  };

  const onFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    const selectedFile = e.target.files?.[0];

    if (!selectedFile) {
      return;
    }

    // max file size is 5MB
    if (selectedFile.size > MAX_FILE_UPLOAD_SIZE) {
      setFileTooBig(true);
      return;
    }

    setFileTooBig(false);
    setFile(selectedFile);
  };

  useEffect(() => {
    setFile(null);
  }, [errorUploading]);

  useEffect(() => {
    async function uploadFile() {
      if (file) {
        const fileUploaded = await upload();
        if (fileUploaded == null) return;
        onImageUrlChange(category, fileUploaded.patient_file.id, fileUploaded.temporary_url);
        setFileUrl(fileUploaded.temporary_url);
      }
    }

    uploadFile();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [file]);

  return (
    <UploadBtnContainer>
      <FileInput
        ref={inputRef}
        type="file"
        accept=".jpeg, .jpg, .png, .pdf"
        onChange={onFileChange}
      />
      <Modal open={showPreview} maxWidth="960px" mobileFullscreen>
        <UploadPreviewImgContainer>
          <ClosePreviewBtn
            // eslint-disable-next-line
            aria-description="Close image preview"
            onClick={() => setShowPreview(false)}
          >
            <Icon src={close} />
            Close preview
          </ClosePreviewBtn>
          <UploadPreviewImgFull src={fileUrl} />
        </UploadPreviewImgContainer>
      </Modal>

      {!fileUrl && (
        <UploadBtn
          // eslint-disable-next-line
          aria-description="Upload image"
          onClick={onClickUpload}
          disabled={uploading}
        >
          {uploading && (
            <Loader type="spin" color={theme.colors.yellow.primary} height={50} width={50} />
          )}
          {!uploading && (
            <>
              <Icon src={plus} alt="Plus sign" />
              <MainText>{label}</MainText>
            </>
          )}
        </UploadBtn>
      )}

      {fileUrl && (
        <UploadPreview>
          <UploadPreviewImg src={fileUrl} />
          <UploadPreviewFileName>{file?.name}</UploadPreviewFileName>
          <UploadPreviewOptions>
            {uploading && (
              <Loader type="spin" color={theme.colors.yellow.primary} height={50} width={50} />
            )}
            {!uploading && (
              <>
                {!file?.name.toLowerCase().endsWith('.pdf') && (
                  <UploadPreviewBtn
                    // eslint-disable-next-line
                    aria-description="Preview uploaded image"
                    variant="primary"
                    onClick={() => setShowPreview(true)}
                  >
                    Preview
                  </UploadPreviewBtn>
                )}
                {!readonly && (
                  <UploadPreviewBtn
                    // eslint-disable-next-line
                    aria-description="Reupload image"
                    variant="secondary"
                    onClick={onClickUpload}
                  >
                    Reupload
                  </UploadPreviewBtn>
                )}
              </>
            )}
          </UploadPreviewOptions>
        </UploadPreview>
      )}

      {fileTooBig && <ButtonErrorText>Please use a file smaller than 5MB.</ButtonErrorText>}
      {errorPatientMessageUploading && (
        <ButtonErrorText>{errorPatientMessageUploading}</ButtonErrorText>
      )}
    </UploadBtnContainer>
  );
};
