const LABS_PRODUCTION = {
  ARKANSAS: '41',
  ATLANTA: '16',
  AUSTIN: '22',
  BETHESDA: '44',
  CHARLOTTE: '49',
  CHICAGO: '34',
  COLUMBUS: '47',
  DALLAS: '29',
  DENVER: '25',
  DETROIT: '39',
  EAST_BAY: '48',
  HOUSTON: '30',
  LOS_ANGELES: '11',
  MIAMI: '31',
  MILWAUKEE: '35',
  MINNEAPOLIS: '27',
  NEW_YORK: '5',
  NEWPORT_BEACH: '42',
  ORLANDO: '24',
  OTHER: '14',
  PHILADELPHIA: '33',
  PORTLAND: '38',
  PRINCETON: '12',
  SAN_DIEGO: '50',
  SAN_FRANCISCO: '8',
  SEATTLE: '32',
  SILICON_VALLEY: '21',
  ST_LOUIS: '37',
  WASHINGTON_DC: '28'
};

export function labs(): Record<string, string> {
  return LABS_PRODUCTION;
}

export function isPortlandPatient(labId: string): boolean {
  return LABS_PRODUCTION.PORTLAND === labId;
}

export function isChicagoPatient(labId: string): boolean {
  return LABS_PRODUCTION.CHICAGO === labId;
}
