import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';

import { AuthFlowLayout } from 'components/v2/Layouts/AuthFlowLayout/AuthFlowLayout';
import { pageUrl } from 'utilities/pageUrl';

import { ForgotPasswordContainer } from './ForgotPassword.styled';
import { ForgotPasswordForm } from './ForgotPasswordForm';
import { FormType } from './ForgotPasswordForm.types';

export const ForgotPassword = () => {
  const [formType, setFormType] = useState<FormType>('request_code');
  const history = useHistory();

  const onGoBack = () => {
    window.scrollTo(0, 0);
    formType === 'request_code' ? history.replace(pageUrl.login()) : setFormType('request_code');
  };

  return (
    <ForgotPasswordContainer>
      <AuthFlowLayout
        title={getTitle(formType)}
        renderMainContent={() => {
          return (
            <ForgotPasswordForm
              onSuccessfulReset={() => history.replace(pageUrl.login())}
              onGoBack={onGoBack}
              handleUnconfirmed={email => {
                history.push(pageUrl.emailAddressVerification(), {
                  email,
                  forgotPassword: true
                });
              }}
              formType={formType}
              updateFormType={formType => setFormType(formType)}
            />
          );
        }}
      />
    </ForgotPasswordContainer>
  );
};

const getTitle = (formType: FormType) =>
  formType === 'request_code' ? 'Forgot Your Password?' : 'Create New Password';
