import React from 'react';

import { XMark } from 'assets/icons/Icons';
import Modal from 'components/Modal';
import { Button } from 'components/v2/Buttons/Button';
import { LinkButton } from 'components/v2/Buttons/LinkButton/LinkButton';
import { Heading } from 'components/v2/Typography';
import { Text } from 'components/v2/Typography/Text';

import { Bottom, ModalContent, ModalHeader } from './TermsModal.styles';

type Props = {
  show: boolean;
  onClose: () => void;
  onAgree: () => void;
};

export const TermsModal = (props: Props) => {
  const { show, onClose, onAgree } = props;
  const title = 'Kindbody Payment Terms';

  return (
    <Modal
      label=" Electronic Communications Consent"
      open={show}
      onRequestClose={onClose}
      maxWidth="880px"
      mobileFullscreen
    >
      <ModalContent>
        <ModalHeader>
          <Heading noMargin tag="h4">
            {title}
          </Heading>
          <LinkButton leftIcon={<XMark type="solid" />} size="sm" onClick={onClose} />
        </ModalHeader>
        <Text tag="p">
          By submitting payment through Kindbody’s portal, I agree to the following payment terms:
        </Text>
        <br />
        <Text tag="p">
          Kindbody is authorized to charge my credit card for the amount indicated on the payment
          page. Kindbody may only charge my credit card for amounts I have agreed to pay and{' '}
          <span style={{ fontWeight: 'bold' }}>
            these payment terms apply to any future payments
          </span>
          .
        </Text>
        <br />
        <Text tag="p">
          If I am submitting a deposit only, I agree that I will submit the remaining balance of my
          egg-freezing or IVF treatment cycle(s) within 60 days from today’s date (the “End Date”)
          by either submitting payment at my appointment or by hereby permitting Kindbody to charge
          my credit card saved on file on the End Date. I understand that even if I schedule my
          treatment cycle after the 60 day payment period, I will still need to complete payment by
          the End Date. I further understand that if the payment of my outstanding balance is not
          made within the 60 day period, my deposit will be forfeited and will not be refunded to
          me.
        </Text>
        <br />
        <Text tag="p">
          A cancellation of a treatment cycle and any applicable refund is subject to Kindbody’s
          cancellation policy.
        </Text>

        <Bottom>
          <Button fullWidth label="I consent" category="primary" size="lg" onClick={onAgree} />
        </Bottom>
      </ModalContent>
    </Modal>
  );
};
