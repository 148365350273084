import React, { forwardRef } from 'react';

import { Paragraph, Span, Strong, Time } from './Text.styled';
import { Props } from './Text.types';

export const Text = forwardRef<HTMLElement, Props>(
  (
    {
      children,
      className,
      tag = 'span',
      size,
      fontStyle,
      color,
      bg,
      decoration,
      transform,
      tabIndex,
      multilineEllipses
    },
    ref
  ) => {
    if (tag === 'span') {
      return (
        <Span
          ref={ref}
          className={className}
          fontSize={size}
          fontStyle={fontStyle}
          color={color}
          bg={bg}
          decoration={decoration}
          transform={transform}
          tabIndex={tabIndex}
          multilineEllipses={multilineEllipses}
        >
          {children}
        </Span>
      );
    }

    if (tag === 'strong') {
      // force bold font style for strong HTML element
      const selectedFontStyle =
        !fontStyle || !['semibold', 'bold'].includes(fontStyle) ? 'bold' : fontStyle;

      return (
        <Strong
          ref={ref}
          className={className}
          fontSize={size}
          fontStyle={selectedFontStyle}
          color={color}
          bg={bg}
          decoration={decoration}
          transform={transform}
          tabIndex={tabIndex}
          multilineEllipses={multilineEllipses}
        >
          {children}
        </Strong>
      );
    }

    if (tag === 'p') {
      return (
        <Paragraph
          ref={ref as React.RefObject<HTMLParagraphElement>}
          className={className}
          fontSize={size}
          fontStyle={fontStyle}
          color={color}
          bg={bg}
          decoration={decoration}
          transform={transform}
          tabIndex={tabIndex}
          multilineEllipses={multilineEllipses}
        >
          {children}
        </Paragraph>
      );
    }

    if (tag === 'time') {
      return (
        <Time
          ref={ref}
          className={className}
          fontSize={size}
          fontStyle={fontStyle}
          color={color}
          bg={bg}
          decoration={decoration}
          transform={transform}
          tabIndex={tabIndex}
          multilineEllipses={multilineEllipses}
        >
          {children}
        </Time>
      );
    }

    return (
      <Span
        ref={ref}
        className={className}
        fontSize={size}
        fontStyle={fontStyle}
        color={color}
        bg={bg}
        decoration={decoration}
        transform={transform}
        tabIndex={tabIndex}
        multilineEllipses={multilineEllipses}
      >
        {children}
      </Span>
    );
  }
);

Text.displayName = 'Text';
