import { gql, useQuery } from '@apollo/client';

import { TimeSlot } from 'kb-shared';
import { BugTracker } from 'kb-shared/utilities/bugTracker';

import { UrlParamValue } from '../types/types';
import { isFalsyNonNumber } from '../utils/validation';

const QUERY_TIME_SLOT = gql`
  query timeSlot($timeSlotId: Int!) {
    timeslot(timeslotId: $timeSlotId) {
      id
      videoLink
      location {
        id
        name
        address
        timeZone
      }
      lab {
        id
      }
      startTime
      endTime
      timeZone
      licensedProviderName
    }
  }
`;

export const useTimeSlot = (timeSlotId?: UrlParamValue<number>) => {
  const { data, loading, error } = useQuery<{
    timeslot: TimeSlot;
  }>(QUERY_TIME_SLOT, {
    skip: isFalsyNonNumber(timeSlotId),
    variables: {
      timeSlotId: timeSlotId
    },
    onError: error => {
      BugTracker.notify(error, 'Failed to load time slot');
    }
  });

  return { data: data?.timeslot, loading, error };
};
