import React, { FC } from 'react';

import { themes } from 'kb-shared';
import styled from 'styled-components';

type Props = {
  title: string;
  description: string;
  icon: FC<{}>;
};

export const NextStepItem = (props: Props) => {
  const Icon = props.icon;
  return (
    <Container>
      <IconWrapper>
        <Icon />
      </IconWrapper>
      <TextContainer>
        <Title>{props.title}</Title>
        <Description>{props.description}</Description>
      </TextContainer>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: 12px;
  padding-right: 12px;
  @media screen and (min-width: ${themes.breakpoints.tabletPortrait}) {
    align-items: center;
    flex-direction: row;
  }
`;

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const Title = styled.div`
  font-size: 14px;
  font-weight: bold;
  color: ${themes.colors.neutral.primaryNavy};
  line-height: 20px;
`;

const Description = styled.div`
  font-size: 14px;
  color: ${themes.colors.neutral.primaryNavy};
  line-height: 20px;
`;

const IconWrapper = styled.div`
  margin-bottom: 6px;
  margin-right: 12px;
  @media screen and (min-width: ${themes.breakpoints.tabletPortrait}) {
    margin-bottom: 0px;
  }
`;
