import React from 'react';

import { ShieldHeart } from 'assets/icons/Icons';
import { InsuranceForm } from 'components/InsuranceForm/InsuranceForm';
import { InsuranceProvider } from 'components/InsuranceForm/InsuranceForm.types';
import { COB_PRIORITY_CODE } from 'components/InsuranceForm/InsuranceForm.utils';
import { Heading } from 'components/v2/Typography';
import { theme } from 'kb-shared';
import { Icon } from 'screens/MyProfile/components/shared.styled';

import { FormContainer } from './InsuranceEmbeddedForm.styled';

interface Props {
  title: string;
  heading: string;
  insuranceProvider?: InsuranceProvider;
  cobPriorityCode: COB_PRIORITY_CODE;
  onSuccess: () => void;
  onCancel: () => void;
}

export const InsuranceEmbeddedForm = ({
  title,
  heading,
  insuranceProvider,
  cobPriorityCode,
  onSuccess,
  onCancel
}: Props) => {
  return (
    <div>
      <Heading tag="h2" styledAs="h5" noMargin>
        <Icon>
          <ShieldHeart />
        </Icon>
        {title}
      </Heading>
      <FormContainer>
        <Heading tag="h4">{heading}</Heading>
        <InsuranceForm
          insuranceProvider={insuranceProvider}
          cobPriorityCode={cobPriorityCode}
          onSuccess={onSuccess}
          onCancel={onCancel}
          backgroundColor={theme.colors.neutral.lavenderWhite}
        />
      </FormContainer>
    </div>
  );
};
