import {
  Answer,
  FieldGroupDataType,
  FormConditionWithAnswer,
  FormConditions,
  FormFieldDataType,
  FormSection,
  IntakeForm,
  fieldGroupDataTypes,
  formFieldDataTypes
} from 'kb-shared';
import { FormSubheader, FormField, FieldGroup } from 'kb-shared';
import { BugTracker } from 'kb-shared/utilities/bugTracker';
import {
  FormAnswers,
  areConditionsSatisfied,
  findLastIndex
} from 'kb-shared/utilities/intake_form.helper';

import { FormElement, NewFormState, NextQuestionSearchResult } from './IntakeFormRoute.types';

const hasAnswer = (
  formElement: FormField,
  existingAnswers: {
    [id: string]: Answer;
  }
) => {
  return !!existingAnswers[formElement.id];
};

const getLastFormSectionAnswered = (
  intakeData: IntakeForm,
  existingAnswers: {
    [id: string]: Answer;
  }
): FormSection | null => {
  if (intakeData.formSections.length === 0) return null;

  const lastFormSectionIndexAnswered = getLastFormSectionIndexAnswered(intakeData, existingAnswers);
  if (lastFormSectionIndexAnswered == null) return null;

  return intakeData.formSections[lastFormSectionIndexAnswered];
};

const getLastFormElementIndexAnswered = (
  lastFormSectionAnswered: FormSection | null,
  existingAnswers: {
    [id: string]: Answer;
  },
  idsOfHiddenQuestions: string[]
): number | undefined => {
  const notFound = -1;

  if (lastFormSectionAnswered?.formElements == null) return undefined;

  const displayedQuestions = lastFormSectionAnswered?.formElements.filter(
    element => !idsOfHiddenQuestions.includes(element.id)
  );
  const index = findLastIndex(displayedQuestions, formElement => {
    switch (formElement.type) {
      case 'FormField':
        return hasAnswer(formElement, existingAnswers);
      case 'FieldGroup':
        return lastFormSectionAnswered?.fieldGroupsMap[formElement.id].formFields.some(field =>
          hasAnswer(field, existingAnswers)
        );
      default:
        return false;
    }
  });

  return index === notFound ? undefined : index;
};

const getLastFormSectionIndexAnswered = (
  intakeData: IntakeForm,
  existingAnswers: {
    [id: string]: Answer;
  }
): number | null => {
  const notFound = -1;

  if (intakeData.formSections.length === 0) {
    return null;
  }

  const index = findLastIndex(intakeData.formSections, (formSection: FormSection) => {
    return formSection.formElements.some(formElement => {
      switch (formElement.type) {
        case 'FormField':
          return hasAnswer(formElement, existingAnswers);
        case 'FieldGroup':
          return formSection.fieldGroupsMap[formElement.id].formFields.some(field =>
            hasAnswer(field, existingAnswers)
          );
        default:
          return false;
      }
    });
  });

  return index === notFound ? null : index;
};

export const scrollToFormElement = (id: string) => {
  document.getElementById(`fe-${id}`)?.scrollIntoView({ behavior: 'smooth' });
};

export const isFormField = (
  formElement: FormSubheader | FormField | FieldGroup
): formElement is FormField => formElement.type === 'FormField';

export const getNextQuestion = (
  intakeData: IntakeForm,
  currentSectionIndex: number,
  currentQuestionId: string,
  idsOfHiddenQuestions: string[],
  answerValue: string | undefined
): NextQuestionSearchResult => {
  const questionSection = intakeData.formSections[currentSectionIndex];

  const nextQuestionSearchResult: NextQuestionSearchResult = {
    nextQuestion: undefined
  };

  if (!questionSection || questionSection.formElements.length === 0)
    return nextQuestionSearchResult;

  const displayedQuestions = questionSection.formElements.filter(
    element => !idsOfHiddenQuestions.includes(element.id)
  );

  const currentQuestionIndex = displayedQuestions.findIndex(
    question => question.id === currentQuestionId
  );

  if (currentQuestionIndex === -1) return nextQuestionSearchResult;

  if (currentQuestionIndex === displayedQuestions.length - 1) {
    const conditionsWithCurrentQuestion = intakeData.conditionalFormElements?.filter(
      condition => condition.dependencyFormElementId?.toString() === currentQuestionId
    );
    if (conditionsWithCurrentQuestion?.length && answerValue) {
      const {
        currentQuestionIsLastInSection,
        nextQuestion
      } = searchForNextQuestionInConditionalQuestions(
        conditionsWithCurrentQuestion,
        answerValue,
        questionSection
      );
      nextQuestionSearchResult.nextQuestion = nextQuestion;
      nextQuestionSearchResult.currentQuestionIsLastInSection = currentQuestionIsLastInSection;
    } else {
      nextQuestionSearchResult.currentQuestionIsLastInSection = true;
    }

    return nextQuestionSearchResult;
  }

  nextQuestionSearchResult.nextQuestion = displayedQuestions[currentQuestionIndex + 1];
  return nextQuestionSearchResult;
};

const searchForNextQuestionInConditionalQuestions = (
  conditionsWithCurrentQuestion: FormConditions[],
  answerValue: string,
  questionSection: FormSection
) => {
  const nextQuestionSearchResult: NextQuestionSearchResult = {
    nextQuestion: undefined
  };

  // If there are conditionally rendered questions, answer on a question that is currently last question
  // in a section, can result with new questions being rendered. That means that what is currently the
  // last question, can stop being the last question in a section when answer is provided.
  const matchedConditions = conditionsWithCurrentQuestion.filter(
    condition => condition.value === answerValue
  );
  if (matchedConditions?.length) {
    for (const condition of matchedConditions) {
      const nextQuestion = questionSection.formElements.find(
        element => element.id === condition.conditionalFormElementId?.toString()
      );
      // Conditional logic definitions don't have to be fetched in the same order
      // questions are rendered. Because of that, among all questions conditionally
      // rendered based on current question's answer, we need to find the conditionally
      // rendered question with a smallest value for postion (position defines questions' order).
      if (
        nextQuestion &&
        (nextQuestionSearchResult.nextQuestion == null ||
          nextQuestionSearchResult.nextQuestion.position > nextQuestion.position)
      ) {
        nextQuestionSearchResult.nextQuestion = nextQuestion;
      }
    }

    nextQuestionSearchResult.currentQuestionIsLastInSection = false;
  } else {
    nextQuestionSearchResult.currentQuestionIsLastInSection = true;
  }

  return nextQuestionSearchResult;
};

const getFirstNextQuestionIndex = (
  lastAnsweredQuestionIndex: number,
  lastFormSectionWithAnsweredQuestion: FormSection | null,
  conditionalFormElements: FormConditions[],
  questionsAnswers: FormAnswers | null
) => {
  if (!lastFormSectionWithAnsweredQuestion) return null;

  const displayedQuestions = lastFormSectionWithAnsweredQuestion.formElements.filter(
    element =>
      !getQuestionIdsToHide(
        lastFormSectionWithAnsweredQuestion,
        conditionalFormElements,
        questionsAnswers
      ).includes(element.id)
  );
  const allNextQuestions = displayedQuestions.slice(lastAnsweredQuestionIndex + 1);

  if (allNextQuestions.length === 0) return null;
  return displayedQuestions.indexOf(allNextQuestions[0]);
};

export const getQuestionIdsToHide = (
  section: FormSection | null,
  conditionalFormElements: FormConditions[],
  questionsAnswers: FormAnswers | null
) => {
  if (section == null) {
    return [];
  }

  return section.formElements
    .filter(el => !isFormElementForRendering(el, conditionalFormElements, questionsAnswers))
    .map(el => el.id);
};

const isFormElementForRendering = (
  formElement: FormElement,
  conditionalFormElements: FormConditions[],
  questionsAnswers: FormAnswers | null
) => {
  if (formElement.type !== 'FormField') {
    // only FormFields are conditionally rendered
    return true;
  }

  if (!conditionalFormElements) {
    return true;
  }

  const elementId = parseInt(formElement.id);
  const conditions = conditionalFormElements.filter(
    el => el.conditionalFormElementId === elementId
  );
  if (conditions.length === 0) {
    return true;
  }

  const conditionsAndAnswers: FormConditionWithAnswer[] = [];
  for (const condition of conditions) {
    const answerOnDependency = getAnswersForDependency(
      condition.dependencyFormElementId,
      questionsAnswers
    );
    conditionsAndAnswers.push({
      renderingCondition: condition,
      answerOnDependency: answerOnDependency
    });
  }

  return areConditionsSatisfied(conditionsAndAnswers);
};

const getAnswersForDependency = (
  dependencyId: number,
  questionsAnswers: FormAnswers | null
): string | undefined => {
  if (questionsAnswers == null) return undefined;

  const newAnswer = questionsAnswers[dependencyId];
  if (newAnswer?.data) {
    return newAnswer.data;
  }
};

const isValidFormFieldDataType = (dataType: FormFieldDataType): boolean => {
  return formFieldDataTypes.includes(dataType);
};

const isValidFieldGroupdDataType = (dataType: FieldGroupDataType): boolean => {
  return fieldGroupDataTypes.includes(dataType);
};

export const validateDataTypes = (formData: IntakeForm) => {
  const invalidQuestions: string[] = [];
  formData.formSections.forEach(formSection => {
    formSection.formElements.forEach(formElement => {
      if (formElement.type === 'FormField' && !isValidFormFieldDataType(formElement.dataType)) {
        invalidQuestions.push(formElement.id);
        BugTracker.notify(JSON.stringify(formElement), 'Invalid IntakeForm Question Definition');
      } else if (
        formElement.type === 'FieldGroup' &&
        !isValidFieldGroupdDataType(formElement.dataType)
      ) {
        invalidQuestions.push(formElement.id);
        BugTracker.notify(
          JSON.stringify(formElement),
          'Invalid IntakeForm Question Group Definition'
        );
      }
    });
  });

  return invalidQuestions;
};

export const getNewFormState = (
  questions: IntakeForm,
  questionsAnswers: FormAnswers,
  questionsWithInvalidDataType: string[]
): NewFormState | undefined => {
  const lastFormSectionAnswered = getLastFormSectionAnswered(questions, questionsAnswers);

  const lastFormSectionIndexAnswered = getLastFormSectionIndexAnswered(questions, questionsAnswers);
  const hiddenQuestions = getQuestionIdsToHide(
    lastFormSectionAnswered,
    questions.conditionalFormElements || [],
    questionsAnswers
  ).concat(questionsWithInvalidDataType);
  const lastFormElementIndexAnswered = getLastFormElementIndexAnswered(
    lastFormSectionAnswered,
    questionsAnswers,
    hiddenQuestions
  );
  const displayedQuestions = lastFormSectionAnswered?.formElements.filter(
    element => !hiddenQuestions.includes(element.id)
  );
  const numberQuestionsInLastAnsweredFormSection = displayedQuestions?.filter(
    element => !hiddenQuestions.includes(element.id)
  ).length;
  const shouldRedirectNextSection =
    lastFormElementIndexAnswered != null &&
    numberQuestionsInLastAnsweredFormSection === lastFormElementIndexAnswered + 1;

  if (shouldRedirectNextSection && lastFormSectionIndexAnswered != null) {
    const nextFormSectionIndex = lastFormSectionIndexAnswered + 1;
    return {
      newSectionIndex: nextFormSectionIndex,
      newQuestionIndex: 0,
      isComplete: false
    };
  }

  let nextFormElementIndex = 0;
  if (lastFormElementIndexAnswered != null) {
    const firstNextQuestionIndex = getFirstNextQuestionIndex(
      lastFormElementIndexAnswered,
      lastFormSectionAnswered,
      questions.conditionalFormElements || [],
      questionsAnswers
    );
    if (firstNextQuestionIndex) nextFormElementIndex = firstNextQuestionIndex;
  }

  return {
    newSectionIndex: lastFormSectionIndexAnswered || 0,
    newQuestionIndex: nextFormElementIndex,
    isComplete: false
  };
};

export const scrollToQuestion = (questionId: string): void => {
  const nextQuestionVerticalPosition = getQuestionScrollVerticalPosition(questionId);
  if (nextQuestionVerticalPosition) window.scrollTo({ top: nextQuestionVerticalPosition });
};

export const smoothScrollToQuestion = (questionId: string): void => {
  const nextQuestionVerticalPosition = getQuestionScrollVerticalPosition(questionId);
  if (nextQuestionVerticalPosition)
    window.scrollTo({ top: nextQuestionVerticalPosition, behavior: 'smooth' });
};

const getQuestionScrollVerticalPosition = (questionId: string): number | undefined => {
  const nextQuestionElement = document.getElementById(`fe-${questionId}`);
  const headerElement = document.getElementById('intake-form-header');
  if (!nextQuestionElement || !headerElement) return;
  return (
    nextQuestionElement.getBoundingClientRect().top + window.scrollY - headerElement.offsetHeight
  );
};
