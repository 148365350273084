import {
  fontCSS,
  filledOutlineShadow,
  defaultState,
  errorState,
  focusedState,
  disabledState,
  readOnlyState
} from 'components/v2/common.styled';
import styled, { css } from 'styled-components';

import { Status } from '../../types';

export const Container = styled.div<{ unstyled?: boolean }>`
  ${({ unstyled }) => (unstyled ? '' : 'position: relative;')}
`;

export const Placeholder = styled.span<{ status: Status }>`
  ${fontCSS}
  line-height: 24px;
  color: ${defaultState.placeholderColor};

  ${({ status }) => {
    if (status === 'error') {
      return css`
        color: ${errorState.textColor};
      `;
    }

    if (status === 'disabled') {
      return css`
        color: ${disabledState.textColor};
      `;
    }

    if (status === 'readOnly') {
      return css`
        color: ${disabledState.textColor};
      `;
    }
  }}
`;

export const SelectedOption = styled.div<{
  isDropDownOpened: boolean;
  status: Status;
  unstyled: boolean;
  isFilled: boolean;
}>`
  display: flex;
  align-items: center;
  line-height: 24px;
  padding: 20px 24px;
  cursor: default;
  border-radius: ${defaultState.borderRadius};
  box-shadow: 0px 1px 3px 0px rgba(67, 71, 146, 0.16), 0px 1px 1px 0px rgba(10, 13, 61, 0.08);
  ${fontCSS}

  ${({ unstyled }) => {
    if (unstyled) {
      return css``;
    }

    return css`
      color: ${defaultState.textColor};
      background-color: ${defaultState.backgroundColor};
      border-style: ${defaultState.borderStyle};
      border-color: ${defaultState.borderColor};
      border-width: ${defaultState.borderWidth};
    `;
  }}


  ${({ isDropDownOpened, unstyled }) =>
    !unstyled &&
    isDropDownOpened &&
    css`
      box-shadow: ${focusedState.boxShadow};
    `}

  ${({ isFilled, unstyled, isDropDownOpened }) =>
    !unstyled &&
    !isDropDownOpened &&
    isFilled &&
    css`
      box-shadow: ${filledOutlineShadow};
    `}

  ${({ unstyled, status }) => {
    if (unstyled) {
      return css``;
    }

    if (status === 'error') {
      return css`
        color: ${errorState.textColor};
        box-shadow: ${errorState.boxShadow};
      `;
    }

    if (status === 'disabled') {
      return css`
        color: ${disabledState.textColor};
        background: ${disabledState.backgroundColor};
        border-style: ${disabledState.borderStyle};
        border-color: ${disabledState.borderColor};
        border-width: ${disabledState.borderWidth};
        cursor: ${disabledState.cursor};
      `;
    }

    if (status === 'readOnly') {
      return css`
        color: ${readOnlyState.textColor};
        background: ${readOnlyState.backgroundColor};
        border-style: ${readOnlyState.borderStyle};
        border-color: ${readOnlyState.borderColor};
        border-width: ${readOnlyState.borderWidth};
        cursor: ${readOnlyState.cursor};
      `;
    }
  }}
`;

export const SelectedOptionLabel = styled.span`
  flex: 1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const ArrowIcon = styled.div`
  padding: 0px 16px 0 16px;

  svg {
    width: 12px;
    fill: ${defaultState.sideIconFillColor};
  }
`;
