import { ApolloError, gql, useQuery } from '@apollo/client';
import { useCallback, useState } from 'react';

import { BugTracker } from 'kb-shared/utilities/bugTracker';

import Invoice from '../types/invoice';

export const INVOICES_QUERY = gql`
  query invoice(
    $after: String
    $before: String
    $first: Int
    $last: Int
    $includeAzInvoices: Boolean
  ) {
    invoices(
      after: $after
      before: $before
      first: $first
      last: $last
      includeAzInvoices: $includeAzInvoices
    ) {
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
        startCursor
      }
      nodes {
        balance
        dateOfInvoice
        service
        id
        azEncounterIdentifier
        paidViaPpApp
        marketId
      }
    }
  }
`;

const useInvoices = (pageSize: number, skip: boolean): UseInvoicesResponse => {
  const [currentPage, setCurrentPage] = useState(1);
  const [queryVariables, setQueryVariables] = useState<InvoicesVariables>({
    after: null,
    before: null,
    first: pageSize,
    last: null,
    includeAzInvoices: true // we cannot remove includeAzInvoices from backend yet due to mobile app
  });
  const { loading, data, error, refetch } = useQuery<InvoicesResponse, InvoicesVariables>(
    INVOICES_QUERY,
    {
      variables: queryVariables,
      skip,
      onError: error => BugTracker.notify(error, 'Invoice Fetch Failed')
    }
  );

  const startCursor = data?.invoices?.pageInfo.startCursor;
  const endCursor = data?.invoices?.pageInfo.endCursor;

  const onNextPage = useCallback(() => {
    if (!endCursor) {
      return;
    }

    setCurrentPage(currentPage => currentPage + 1);

    setQueryVariables({
      after: endCursor,
      before: null,
      first: pageSize,
      last: null,
      includeAzInvoices: true
    });
  }, [setCurrentPage, setQueryVariables, endCursor, pageSize]);

  const onPreviousPage = useCallback(() => {
    if (!startCursor) {
      return;
    }

    setCurrentPage(currentPage => currentPage - 1);

    setQueryVariables({
      after: null,
      before: startCursor,
      first: null,
      last: pageSize,
      includeAzInvoices: true
    });
  }, [setCurrentPage, setQueryVariables, startCursor, pageSize]);

  const invoices = data?.invoices?.nodes ?? [];
  const hasNextPage = !!data?.invoices?.pageInfo.hasNextPage;
  const hasPreviousPage = !!data?.invoices?.pageInfo.hasPreviousPage;

  return {
    loading,
    error,
    invoices,
    currentPage,
    onNextPage: hasNextPage ? onNextPage : undefined,
    onPreviousPage: hasPreviousPage ? onPreviousPage : undefined,
    refetch
  };
};

export default useInvoices;

interface UseInvoicesResponse {
  loading: boolean;
  error: ApolloError | undefined;
  invoices: Invoice[];
  currentPage: number;
  onNextPage?: () => void;
  onPreviousPage?: () => void;
  refetch: () => {};
}

interface InvoicesVariables {
  after: string | null;
  before: string | null;
  first: number | null;
  last: number | null;
  includeAzInvoices: boolean;
}

interface InvoicesResponse {
  invoices: {
    pageInfo: {
      startCursor: string;
      endCursor: string;
      hasPreviousPage: boolean;
      hasNextPage: boolean;
    };
    nodes: Invoice[];
  };
}
