import moment from 'moment-timezone';
import React from 'react';

import { Day, TimeSlot } from 'kb-shared';

import {
  BookingTimeContainer,
  DayContainer,
  TimeOfDayText,
  TimeSlotContainer,
  TimeSlotMessage,
  TimeSlotBubble,
  TimeSlotText
} from './DaySection.styled';
import { OrganizedListType } from './DaySection.types';

const getParsedTime = (time: string, timezone: string | undefined) => {
  return timezone ? moment(time).tz(timezone) : moment(time);
};

export const DaySection = (props: {
  day?: Day;
  selectedTimeSlotId: string | null;
  onSelect: (slot: TimeSlot) => void;
  showInTimezone: string | undefined;
}) => {
  const organizedList = {
    Morning: Array<TimeSlot>(),
    Afternoon: Array<TimeSlot>(),
    Evening: Array<TimeSlot>()
  };
  if (props.day && props.day.timeslots) {
    props.day.timeslots.forEach((slot: TimeSlot) => {
      const timeParsed = getParsedTime(slot.startTime, props.showInTimezone);
      const hour = timeParsed.hours();

      if (hour < 12) {
        organizedList['Morning'].push(slot);
      } else if (hour < 17) {
        organizedList['Afternoon'].push(slot);
      } else {
        organizedList['Evening'].push(slot);
      }
    });
  }

  return (
    <BookingTimeContainer>
      {Object.keys(organizedList).map((key: string) => (
        <DayContainer key={key}>
          <TimeOfDayText>{key}</TimeOfDayText>
          <TimeSlotContainer>
            {organizedList[key as keyof OrganizedListType].length === 0 ? (
              <TimeSlotMessage>There are no available time slots</TimeSlotMessage>
            ) : (
              organizedList[key as keyof OrganizedListType].map((slot: TimeSlot) => {
                const selected = slot.id === props.selectedTimeSlotId;
                return (
                  <TimeSlotBubble
                    key={slot.id}
                    selected={selected}
                    onClick={() => {
                      props.onSelect(slot);
                    }}
                  >
                    <TimeSlotText selected={selected}>
                      {getParsedTime(slot.startTime, props.showInTimezone).format('h:mm A')}
                    </TimeSlotText>
                  </TimeSlotBubble>
                );
              })
            )}
          </TimeSlotContainer>
        </DayContainer>
      ))}
    </BookingTimeContainer>
  );
};
