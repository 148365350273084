import React from 'react';

import { themes } from 'kb-shared';
import styled from 'styled-components';

import { CardButtonBase, typography } from '../../../styled';
import { MedicationInstruction } from '../medications';

const {
  serif: {
    card: { H3 }
  },
  sans: {
    copy: { Nano }
  }
} = typography;
const { colors } = themes;

const ButtonCard = styled(CardButtonBase)`
  align-items: stretch;
  flex-direction: column;
  overflow: hidden;
  padding: 0;
`;

const TopOfCard = styled.div`
  padding: 10px 10px 0 10px;
  width: 100%;
`;

const BottomOfCard = styled.div`
  background: ${colors.yellow.lightestYellow};
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-width: 100%;
`;

const MedicationImage = styled.img`
  object-fit: contain;
  max-width: 100%;
  vertical-align: bottom;
`;

const CallToAction = styled.div`
  align-items: center;
  background: ${colors.yellow.primary};
  border-radius: 24px;
  display: flex;
  height: 48px;
  margin: 0 30px 20px 30px;
  justify-content: center;
`;

type Props = {
  instruction: MedicationInstruction;
  onClick: () => void;
};

const InstructionCard = (props: Props) => {
  const {
    instruction: { displayName, imageSrc },
    onClick
  } = props;

  return (
    // @ts-ignore
    <ButtonCard onClick={onClick} alt={`Watch ${displayName} instruction`}>
      <TopOfCard>
        <MedicationImage src={imageSrc} alt={displayName} />
      </TopOfCard>
      <BottomOfCard>
        <H3 style={{ margin: '25px 0' }}>{displayName}</H3>
        <CallToAction>
          <Nano>VIEW TUTORIAL</Nano>
        </CallToAction>
      </BottomOfCard>
    </ButtonCard>
  );
};

export default InstructionCard;
