import { gql } from '@apollo/client';

export const LMP_TRACKER_QUERY = gql`
  query lmpTracker {
    lastPeriod {
      periodDate
      nextPeriodDate
      daysBetweenCycles
      id
    }

    patient {
      id
      lmpTrackerEnabled
    }
  }
`;
