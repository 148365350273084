import { Columns, Item } from './List.types';

export function generateUniqueNames(items: Item[]): Item[] {
  const nameCounts: Record<string, number> = {};

  return items.map(item => {
    const baseName = item.name;
    let newName = baseName;

    if (nameCounts[baseName] === undefined) {
      nameCounts[baseName] = 0;
    } else {
      nameCounts[baseName]++;
      newName = `${baseName}(${nameCounts[baseName]})`;
    }

    nameCounts[newName] = 0;
    return { ...item, name: newName };
  });
}

export const sortByDate = (items: Item[], orientation: 'asc' | 'desc') => {
  return [...items].sort((item1, item2) => {
    const dateA = new Date(item1.date);
    const dateB = new Date(item2.date);
    return orientation === 'asc'
      ? dateA.getTime() - dateB.getTime()
      : dateB.getTime() - dateA.getTime();
  });
};

export const sortByText = (
  property: Columns,
  items: Item[],
  orientation: 'asc' | 'desc'
): Item[] => {
  return [...items].sort((item1, item2) => {
    const value1 = item1[property] ?? '';
    const value2 = item2[property] ?? '';

    if (typeof value1 === 'string' && typeof value2 === 'string') {
      const textA = value1.toLowerCase();
      const textB = value2.toLowerCase();

      return orientation === 'asc' ? textA.localeCompare(textB) : textB.localeCompare(textA);
    }

    return 0;
  });
};
