import React from 'react';

import styled from 'styled-components';

import { YellowButton } from '../../../components/Buttons';
import RiskAssessmentFormType from '../types/RiskAssessmentForm';
import Field from './Field';

interface Props {
  form: RiskAssessmentFormType;
  onSubmit: () => void;
  onSaveAnswer: (fieldId: number, answer: boolean) => Promise<any>;
}

const RiskAssessmentForm = ({ form, onSaveAnswer, onSubmit }: Props) => (
  <Form
    onSubmit={e => {
      e.preventDefault();
      onSubmit();
    }}
  >
    <Header>{form.title}</Header>

    <Description>Please select Yes or No if you have any condition below.</Description>

    <Fields>
      {form.fields.map(field => (
        <Field key={field.id} field={field} onChange={answer => onSaveAnswer(field.id, answer)} />
      ))}
    </Fields>

    <ConfirmButton>CONFIRM</ConfirmButton>
  </Form>
);

const Form = styled.form`
  display: flex;
  flex-direction: column;
  padding: 16px 16px 22px 16px;

  color: ${({ theme }) => theme.colors.neutral.primaryNavy};

  @media ${({ theme }) => theme.queries.minTablet} {
    padding: 26px 26px 30px 44px;
  }
`;

const Header = styled.h2`
  margin-bottom: 8px;

  font-size: 24px;
  line-height: 32px;

  @media ${({ theme }) => theme.queries.minTablet} {
    font-size: 28px;
    line-height: 37px;
  }
`;

const Description = styled.p`
  margin-bottom: 23px;

  font-size: 16px;
  line-height: 20px;

  @media ${({ theme }) => theme.queries.minTablet} {
    margin-bottom: 32px;
  }
`;

const Fields = styled.div`
  margin: 0px -16px 32px -16px;

  @media ${({ theme }) => theme.queries.minTablet} {
    margin: 0px 0px 32px 0px;
  }
`;

const ConfirmButton = styled(YellowButton)`
  align-self: center;
`;

export default RiskAssessmentForm;
