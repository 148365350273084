import { Button } from 'components/v2/Buttons/Button';
import styled from 'styled-components';

export const SearchBox = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;

  input {
    background-color: ${({ theme }) => theme.colors.neutral.lavenderWhite};
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.smallDesktop}) {
    input {
      background-color: white;
    }
  }
`;

export const Square = styled(Button)`
  svg {
    width: 16px;
    height: 16px;
  }
`;
