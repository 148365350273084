import { RenderableItem } from '../types';

export const preOpInstructions: RenderableItem[] = [
  {
    name: 'Evening prior to retrieval',
    body: [
      {
        type: 'copy',
        copy: 'After midnight, please FAST.'
      },
      {
        type: 'copy',
        copy: 'Do not EAT or DRINK ANYTHING (including WATER and chewing gum).'
      }
    ]
  },
  {
    name: 'On the day of retrieval',
    body: [
      {
        type: 'copy',
        copy:
          'Come to the office in comfortable clothing. Do not wear jewelry, nail polish, make-up, hair spray, scented lotions, perfume or contact lenses. After the retrieval, you will need someone to drive you home.'
      },
      {
        type: 'copy',
        copy:
          'You will not be allowed to leave the office alone. For 24 hours after the procedure, you may be tired and fatigued. We recommend not driving, as it can take up to 24 hours for the anesthesia to wear off. You may go to work the next day.'
      }
    ]
  },
  {
    name: 'After retrieval',
    body: [
      {
        type: 'copy',
        copy:
          'You may resume moderate activity as soon as you feel comfortable, however, avoid heavy lifting and strenuous activity for 1 week.'
      },
      {
        type: 'copy',
        copy:
          'Make sure you have Extra Strength Tylenol at home in case you experience discomfort. If you are in need of stronger pain medication, please contact us.'
      },
      {
        type: 'copy',
        copy:
          'You may eat and drink normal after your procedure is complete. Slight abdominal pain and vaginal bleeding is normal. Please call us if you have any of the following:'
      },
      {
        type: 'list',
        items: [
          'Heavy vaginal bleeding',
          'Nausea and vomiting',
          'Fainting, dizziness or lightheadedness, with a rapid pulse rate (>100bpm)',
          'High fever greater than 101° F',
          'Difficulty urinating',
          'Severe abdominal pain or swelling'
        ]
      }
    ]
  },
  {
    name: 'Bleeding',
    body: [
      {
        type: 'copy',
        copy:
          'Vaginal bleeding should be minimal for 1 - 2 days.If it is heavy, please call our office.'
      }
    ]
  },
  {
    name: 'Medications',
    body: [
      {
        type: 'strong',
        copy: 'Refer to any additional information given to you by your patient navigator.'
      }
    ]
  },
  {
    name: 'Other Instructions',
    body: [
      {
        type: 'copy',
        copy: 'Continue any other medications that your physician prescribed to you.'
      },
      {
        type: 'strong',
        copy: 'Notify our office if any of the following symptoms occur:'
      },
      {
        type: 'list',
        items: [
          'Fever > 100.5° F',
          'Heavy bleeding',
          'Continuous pain',
          'Bloating and / or weight gain of more than 2 lbs in 2 consecutive days',
          'Nausea, vomiting, diarrhea and / or decreased urination',
          'Any other abnormal symptoms'
        ]
      }
    ]
  }
];
