import React from 'react';

import { Button } from 'components/v2/Buttons/Button';

export const CustomButton = ({
  style,
  text,
  url
}: {
  style: 'small' | 'call_to_action';
  text: string;
  url: string;
}) => {
  if (!style || !text || !url) return null;

  if (style === 'small')
    return (
      <Button
        category="secondary"
        onClick={() => window.open(formatUrl(url), '_blank')}
        label={text}
        size="lg"
      />
    );

  return (
    <Button
      category="primary-dark"
      onClick={() => window.open(formatUrl(url), '_blank')}
      label={text}
      size="lg"
    />
  );
};

const formatUrl = (url: string): string => {
  const trimmedUrl = url.trim();
  return trimmedUrl.startsWith('https://') ? trimmedUrl : `https://${trimmedUrl}`;
};
