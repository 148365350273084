import { themes } from 'kb-shared';
import styled from 'styled-components';

export const BookAppointmentInfoTitleContainer = styled.div`
  align-self: stretch;
  margin-bottom: 16px;

  span {
    font-size: 18px;
  }
`;

export const BookAppointmentInfoContainer = styled.div`
  align-self: stretch;
  margin-bottom: 24px;

  @media screen and (min-width: ${themes.breakpoints.smallDesktop}) {
    margin-bottom: 32px;
  }
`;
