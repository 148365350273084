import React from 'react';

import { CyclePlanField, CyclePlanViewSection } from '../../CyclePlanAcknowledge.types';
import { Container, Header, Row, Value, Title } from './CyclePlanInfo.styles';

const CyclePlanInfo = ({ sections }: any) => {
  return sections.map(
    (section: CyclePlanViewSection) =>
      !!section.fields.length && (
        <Container key={`title-${section.title}`}>
          {section.title && <Header>{section.title}</Header>}
          {section.fields.map((field: CyclePlanField) => (
            <Row key={`label-${field.label}`}>
              <Title>{field.label}</Title>
              <Value>{field.value}</Value>
            </Row>
          ))}
        </Container>
      )
  );
};

export default CyclePlanInfo;
