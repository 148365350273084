import { FileLines, FilePdf } from 'assets/icons/Icons';
import styled, { css } from 'styled-components';

export const ImageContainer = styled.a<{ fromPatient: boolean }>`
  min-height: 160px;
  max-width: 250px;
  overflow: hidden;
  border-radius: 8px;
  margin-bottom: 4px;

  img {
    height: 100%;
    border-radius: 8px;
  }

  ${({ fromPatient }) =>
    fromPatient
      ? css`
          align-self: flex-end;
        `
      : css`
          align-self: flex-start;
        `}
`;

export const IconContainer = styled.a<{ fromPatient: boolean }>`
  min-width: 140px;
  max-width: 280px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  border-radius: 8px;
  margin-bottom: 4px;
  padding: 12px 8px;

  span {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  svg {
    font-size: 24px;
    padding: 6px 4px 4px 6px;
    background: #fff;
    border-radius: 4px;
    margin-right: 8px;
  }

  ${({ fromPatient }) =>
    fromPatient
      ? css`
          align-self: flex-end;
          background: ${({ theme }) => theme.colors.yellow.lightYellow};
          border: 1px solid ${({ theme }) => theme.colors.yellow.darkYellow};
        `
      : css`
          align-self: flex-start;
          background: #fff;
          border: 1px solid ${({ theme }) => theme.colors.neutral.lavender};
        `}
`;

export const PDFIcon = styled(FilePdf).attrs({ type: 'regular' })`
  font-size: 36px;
`;

export const DefaultFileIcon = styled(FileLines).attrs({ type: 'regular' })`
  font-size: 36px;
`;
