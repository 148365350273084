import { useState } from 'react';

import { SignUpAddress, graphql } from 'kb-shared';
import { BugTracker } from 'kb-shared/utilities/bugTracker';

import NewPatientData from '../types/new-patient-data';
import Patient from '../types/patient';
import RecognizePatientResultCode from '../types/recognize-patient-result-code';

const { client, mutate } = graphql;
const { CREATE_PATIENT_VIA_MEDTRONIC_SSO } = mutate;

export const useCreatePatient = () => {
  const [responseState, setResponseState] = useState<ResponseStateType>({
    patient: null,
    finished: false,
    errors: null,
    resultCode: null
  });

  const createPatient = async (
    idtoken: string,
    newPatientData: NewPatientData,
    address: SignUpAddress
  ): Promise<any> => {
    try {
      // make sure idtoken is NOT set in the header here, because that will cause egg-chicken problem:
      // 1. backend is about to set current_user from idtoken from header
      // 2. but the relationship between idtoken.sub (fhir) and patient is not established yet for
      // a person that is using medtronic sso for the very first time
      const { data } = await client.mutate({
        mutation: CREATE_PATIENT_VIA_MEDTRONIC_SSO,
        variables: {
          medtronicIdtoken: idtoken,
          newPatientData: newPatientData,
          address: address
        }
      });
      setResponseState({
        patient: data.recognizePatientFromMedtronicSso.patient,
        finished: true,
        errors: data.recognizePatientFromMedtronicSso.errors,
        resultCode: data.recognizePatientFromMedtronicSso.resultCode
      });
    } catch (error) {
      BugTracker.notify(
        error,
        'Medtronic SSO: recognizePatientOnBackend mutation (create new patient scenario) failed'
      );
      setResponseState({
        patient: null,
        finished: true,
        errors: [`Unexpected error: ${error}`],
        resultCode: RecognizePatientResultCode.Failed
      });
    }
  };

  return { responseState, createPatient };
};

type ResponseStateType = {
  patient: Patient | null;
  finished: boolean;
  errors: string[] | null;
  resultCode: RecognizePatientResultCode | null;
};
