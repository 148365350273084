import React from 'react';

import { PeopleArrows } from 'assets/icons/Icons';
import { getFirstName } from 'screens/Messages/Messages.utils';

import { Conversation, Message } from '../../Messages.type';
import {
  Icon,
  ReassignmentInfoContainer,
  ReassignmentMessageText
} from './ReassignmentInfo.styled';

export const ReassignmentInfo = ({
  message,
  conversation
}: {
  message: Message;
  conversation: Conversation;
}) => {
  // this applies only to non-patient messages
  // when the current message sender is different from the previous one
  // display that the conversation is now reassigned to the new owner

  if (message?.fromPatient) return null;

  const nonPatientMessages = conversation?.messages?.filter((m: Message) => !m.fromPatient);

  const previousNonPatientMessageIndex =
    nonPatientMessages.findIndex((m: Message) => m.id === message.id) - 1;

  if (previousNonPatientMessageIndex < 0) return null;

  const previousNonPatientMessage = nonPatientMessages[previousNonPatientMessageIndex];

  if (previousNonPatientMessage.senderName === message.senderName) return null;

  return (
    <ReassignmentInfoContainer>
      <Icon>
        <PeopleArrows />
      </Icon>
      <ReassignmentMessageText>
        Reassigned to {getFirstName(message.senderName)}
      </ReassignmentMessageText>
    </ReassignmentInfoContainer>
  );
};
