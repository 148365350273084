import { gql } from '@apollo/client';

export interface TimeSlotsAvailableQueryResponse {
  hasTimeSlots: boolean;
}

export const TIMESLOTS_AVAILABLE_QUERY = gql`
  query appointmentsAvailable(
    $appointmentTypeId: Int!
    $labId: Int
    $locationId: Int
    $startDateTime: String!
    $providerId: Int
  ) {
    hasTimeSlots(
      appointmentTypeId: $appointmentTypeId
      labId: $labId
      locationId: $locationId
      startDateTime: $startDateTime
      providerId: $providerId
    )
  }
`;

export interface ProviderNameQueryResponse {
  providerName: string;
}

export const PROVIDER_NAME_QUERY = gql`
  query providerName($providerId: Int!) {
    providerName(providerId: $providerId)
  }
`;
