import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  box-sizing: content-box;
`;

export const BubbleStepTrackerWrapper = styled.div`
  margin: 88px 0 48px 0;
  display: flex;
  justify-content: center;
`;

export const MobileStepTrackerWrapper = styled.div`
  padding-top: 54px;
  padding-bottom: 24px;
`;
