import React from 'react';

import kindbody from 'assets/logos/kindbody.svg';
import { pageUrl } from 'utilities/pageUrl';

import { LogoLink } from './KindBodyLogo.styled';
import { KindBodyLogoProps } from './KindBodyLogo.types';

export const KindbodyLogo = ({
  href,
  alt = 'Kindbody logo',
  display = 'flex',
  justifyContent = 'center',
  marginTop = '0',
  height,
  width,
  ...rest
}: KindBodyLogoProps): JSX.Element => {
  return (
    <LogoLink
      $display={display}
      $justifyContent={justifyContent}
      $marginTop={marginTop}
      href={href ?? pageUrl.dashboard()}
      {...rest}
    >
      <img src={kindbody} height={height} width={width} alt={alt} />
    </LogoLink>
  );
};
