import React, { useState } from 'react';

import { Software } from './AppointmentVirtualMeeting.types';
import { Container, Button, List, ListHolder, Link } from './SoftwareInstructions.styled';

interface Props {
  software: Software;
}

const SoftwareInstructions = ({ software }: Props) => {
  const [expanded, setExpanded] = useState(false);
  return (
    <Container>
      <Button onClick={() => setExpanded(true)}>
        {software === Software.Zoom ? 'Zoom Installation' : 'Google Meet Installation'}
      </Button>
      {expanded && software === Software.Zoom && (
        <ListHolder>
          <List>
            <li>
              First, download the Zoom application from{' '}
              <Link href="https://zoom.us/" target="_blank">
                their website
              </Link>{' '}
              or your app store (if you’re using a mobile device).
            </li>
            <li>
              When you join a meeting, you will be prompted to turn on your audio and video. Make
              sure your microphone and camera are working properly before the meeting starts.
            </li>
            <li>
              You can also use the chat feature to send messages to other participants during the
              meeting.
            </li>
            <li>
              When the meeting is over, you can leave the meeting by clicking on the "Leave Meeting"
              button.
            </li>
          </List>
        </ListHolder>
      )}
      {expanded && software === Software.GoogleMeet && (
        <ListHolder>
          <List>
            <li>
              First, open Google Meet on your browser or download the app from your app store (if
              you’re using a mobile device).
            </li>
            <li>
              When you join a meeting, you will be prompted to turn on your audio and video. Make
              sure your microphone and camera are working properly before the meeting starts.
            </li>
            <li>
              You can also use the chat feature to send messages to other participants during the
              meeting.
            </li>
            <li>
              When the meeting is over, you can leave the meeting by clicking on the "Leave Call"
              button.
            </li>
          </List>
        </ListHolder>
      )}
    </Container>
  );
};

export default SoftwareInstructions;
