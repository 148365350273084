import styled from 'styled-components';

export const CardContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 10px;
  box-shadow: -4px 4px 0 0 ${({ theme }) => theme.colors.neutral.primaryNavy};
  border: solid 2px ${({ theme }) => theme.colors.neutral.primaryNavy};

  @media ${({ theme }) => theme.queries.minDesktop} {
    width: 359px;
  }

  @media ${({ theme }) => theme.queries.tablet} {
    width: 276px;
  }

  @media ${({ theme }) => theme.queries.mobile} {
    width: 288px;
  }
`;

export const Container = styled.div`
  display: flex;
  justify-content: center;

  @media ${({ theme }) => theme.queries.mobile} {
    flex-flow: row wrap;
  }
`;
