import React from 'react';

import SubstepWrapper from '../../SubstepWrapper';
import { Description, Title } from './styled-components';

const VirtualAppointment = () => (
  <>
    <Title>Virtual Appointment with your Provider</Title>
    <Description>
      This appointment includes a virtual visit with your provider and a postpartum depression
      screening. Postpartum mental health is very important for you & your baby. Your provider will
      make sure your physical and mental health are optimized.
    </Description>
  </>
);

const DoulaLocationAppointment = () => (
  <>
    <Title>Doula (Lactation) In Home Appointment</Title>
    <Description>
      This is an in-home check-in with your Doula to address your emotional wellbeing and to provide
      lactation support. The benefits of breastfeeding for both mother and baby are endless and
      highly supported by both OB doctors and pediatricians. We realize that breastfeeding can
      initially be difficult. This consultation is to ensure appropriate technique, latch, and
      feeding to help you achieve breastfeeding success.
    </Description>
  </>
);

const VirtualMentalHealthSession = () => (
  <>
    <Title>Virtual Mental Health Session (if needed)</Title>
    <Description>
      This visit focuses on your emotional health, adjustment to motherhood, and how you are coping
      with having a new baby at home. You can discuss any anxiety, fears, or any issues you are
      having.
    </Description>
  </>
);

const SUBSTEPS = [
  { component: <VirtualAppointment /> },
  { component: <DoulaLocationAppointment /> },
  { component: <VirtualMentalHealthSession /> }
];

const ObPostPartumDay7Substeps = () => <SubstepWrapper substeps={SUBSTEPS} />;

export default ObPostPartumDay7Substeps;
