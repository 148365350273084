import { gql } from '@apollo/client';

export const SAVE_FORM_ANSWER_MUTATION = gql`
  mutation createAnswer($formId: Int!, $formElementId: Int!, $data: String!) {
    createAnswer(formId: $formId, formElementId: $formElementId, data: $data) {
      answer {
        id
        data
        formElementId
      }
    }
  }
`;

export interface SaveFormAnswerMutationArguments {
  formId: number;
  formElementId: number;
  data: string;
}

export interface SaveFormAnswerMutationResponse {
  createAnswer: {
    answer: {
      id: string;
      data: string;
      formElementId: number;
      percentageComplete: number;
    };
  };
}
