import { gql } from '@apollo/client';

export const PATIENT_CONSENT_SUBMIT = gql`
  mutation createPatientConsent($consentId: Int!, $patientFilledFields: JSON) {
    createPatientConsent(consentId: $consentId, patientFilledFields: $patientFilledFields) {
      patientConsent {
        id
      }
    }
  }
`;
