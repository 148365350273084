import posed from 'react-pose';

import { ChevronRight, ChevronUp } from 'assets/icons/Icons';
import { themes } from 'kb-shared';
import styled from 'styled-components';

export const MedicationsWidgetContainer = styled.div`
  font-size: 16px;
  margin-top: 1em;
  background: ${themes.colors.white};

  @media ${({ theme }) => theme.queries.minTablet} {
    padding: 0 1.5em;
  }

  @media ${({ theme }) => theme.queries.minDesktop} {
    margin-top: 1.5em;
    border-radius: 16px;
    box-shadow: ${themes.shadows.card.level0};
    padding: 0;
  }
`;

export const MedicationsWidgetTitleContainer = styled.div`
  padding: 1em;
  border-bottom: 1px solid ${themes.colors.neutral.lavender};

  @media ${({ theme }) => theme.queries.minTablet} {
    padding: 1em 0;
    border-bottom: 0;
  }

  @media ${({ theme }) => theme.queries.minDesktop} {
    padding: 1.5em 3em;
  }
`;

export const DaysAndLastUpdateContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0 0 1em 0;

  @media ${({ theme }) => theme.queries.minDesktop} {
    padding: 0 3em 1em 3em;
  }
`;

export const DaysContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 0.5em;

  @media ${({ theme }) => theme.queries.minDesktop} {
    gap: 1em;
  }

  .dropdown-open-button {
    width: 56px;
    padding: 1em;
  }

  .dropdown-menu-modal {
    width: 330px;
    padding: 12px;
    border: transparent;
    border-radius: 8px;
    box-shadow: ${({ theme }) => theme.shadows.card.level3};

    & :hover {
      background-color: ${props => props.theme.colors.neutral.lavenderLight};
    }

    & button {
      padding: 0;
      margin: 0;
    }

    > div {
      padding: 12px;
    }
  }

  .open-modal-bottom-left {
    left: 0px;
  }
`;

export const LastUpdateContainer = styled.div`
  display: flex;
  flex-direction: row-reverse;
  justify-content: flex-end;
  margin-top: 1.5em;
  margin-left: 0;

  @media ${({ theme }) => theme.queries.minTablet} {
    flex-direction: row;
    justify-content: flex-start;
    margin-top: 0;
  }

  @media ${({ theme }) => theme.queries.minDesktop} {
    margin-left: 1.5em;
  }
`;

export const LastUpdateTextContainer = styled.div`
  display: flex;
  flex-direction: row;
  text-align: left;

  @media ${({ theme }) => theme.queries.minTablet} {
    flex-direction: column;
    text-align: right;
  }
`;

export const LastUpdateIconContainer = styled.div`
  margin-left: 0;
  margin-right: 0.25em;
  display: flex;
  align-items: center;
  justify-content: center;

  > svg {
    color: ${themes.colors.green.darkGreen};
  }

  @media ${({ theme }) => theme.queries.minTablet} {
    margin-left: 0.25em;
    margin-right: 0;
  }

  @media ${({ theme }) => theme.queries.minDesktop} {
    margin-left: 0.75em;
  }
`;

export const InstructionDay = styled.div<{ $active: boolean }>`
  padding: 0.5em 1em;
  border-radius: 8px;
  box-shadow: ${({ $active }) =>
    $active ? themes.shadows.container.glowYellow : themes.shadows.card.level0};
  cursor: pointer;
  display: flex;
  flex-direction: column;
`;

export const MedicationInstructionsWrapper = styled.div`
  position: relative;
`;

export const CollapsibleDayContainer = styled.div<{ $active: boolean }>`
  padding: 0.75em 1em;
  border-bottom: 1px solid ${themes.colors.neutral.lavender};
  background: ${({ $active }) =>
    $active ? themes.colors.neutral.lavenderWhite : themes.colors.white};
`;

export const CollapsibleDayTitleContainer = styled.div`
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const GoForwardArrow = styled(ChevronRight).attrs({ type: 'solid' })`
  display: inline;
  color: ${({ theme }) => theme.colors.neutral.lighterNavy};
`;

export const GoUpArrow = styled(ChevronUp).attrs({ type: 'solid' })`
  display: inline;
  color: ${({ theme }) => theme.colors.neutral.lighterNavy};
`;

const CollapsibleDayContentArea = styled.div`
  overflow: hidden;
`;

export const CollapsibleDayContent = posed(CollapsibleDayContentArea)({
  visible: {
    height: 'auto',
    duration: 200
  },
  hidden: {
    height: 0,
    duration: 200
  }
});

export const MobileButtonContainer = styled.div`
  display: flex;
  padding: 0.25em 1em 0.75em 1em;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-self: stretch;
  margin: 8px 0px;
`;
