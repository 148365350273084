import React, { FC, PropsWithChildren } from 'react';

import { ErrorBoundary } from './ErrorBoundary';
import { ErrorFallback } from './ErrorFallback';

export const ErrorCatcher: FC<PropsWithChildren<{ id: string }>> = ({ id, children }) => {
  return (
    <ErrorBoundary id={id} fallback={<ErrorFallback />}>
      {children}
    </ErrorBoundary>
  );
};
