import { useQuery } from '@apollo/client';
import React, { FC } from 'react';

import usePatient from 'hooks/usePatient';

import { GET_FEATURE_FLAGS } from './FeatureFlags.graphql';
import { FeatureFlags } from './FeatureFlags.types';
import { extractFeatureFlags } from './FeatureFlags.utils';
import FeatureFlagsContext from './FeatureFlagsContext';

export const FeatureFlagsProvider: FC = ({ children }) => {
  const { isLoggedIn } = usePatient();
  const { data, loading } = useQuery(GET_FEATURE_FLAGS, { skip: !isLoggedIn });
  const featureFlags: Partial<FeatureFlags> = extractFeatureFlags(data?.featureFlags);

  return (
    <FeatureFlagsContext.Provider value={{ ...featureFlags, _loading: loading }}>
      {children}
    </FeatureFlagsContext.Provider>
  );
};
