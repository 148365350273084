import debounce from 'lodash.debounce';
import React, { useEffect, useRef, useState } from 'react';

import { Text } from 'components/v2/Typography';

import { NotesModal } from '../NotesModal/NotesModal';
import { ShowMoreContainer, TextContainer } from './TextWithShowMore.styled';

export const TextWithShowMore = ({ text, popupTitle }: { text: string; popupTitle: string }) => {
  const [showMoreVisible, setShowMoreVisible] = useState(false);
  const [notesModalVisible, setNotesModalVisible] = useState(false);
  const containerRef = useRef<HTMLDivElement | null>(null);

  const onShowMore = () => setNotesModalVisible(true);

  const hasClamping = (el: HTMLDivElement) => {
    const { clientHeight, scrollHeight } = el;
    return clientHeight !== scrollHeight;
  };

  useEffect(() => {
    const checkShowMoreAvailability = () => {
      if (!containerRef.current) return;

      setShowMoreVisible(hasClamping(containerRef.current));
    };

    const debouncedCheck = debounce(checkShowMoreAvailability, 50);

    checkShowMoreAvailability();
    window.addEventListener('resize', debouncedCheck);

    return () => {
      window.removeEventListener('resize', debouncedCheck);
    };
  }, [containerRef]);

  useEffect(() => {
    if (!containerRef.current) return;

    setShowMoreVisible(hasClamping(containerRef.current));
  }, [text]);

  return (
    <>
      <TextContainer ref={containerRef}>
        <Text>{text}</Text>
      </TextContainer>
      {showMoreVisible && (
        <ShowMoreContainer onClick={onShowMore}>
          <Text fontStyle="bold">Show more</Text>
        </ShowMoreContainer>
      )}
      <NotesModal
        visible={notesModalVisible}
        notes={text}
        title={popupTitle}
        onDismiss={() => setNotesModalVisible(false)}
      />
    </>
  );
};
