import React from 'react';

import { CATEGORY } from 'api/pf2/files';
import { InsuranceProvider } from 'components/InsuranceForm/InsuranceForm.types';
import { ALLOWED_FILES_DESCRIPTION } from 'components/PhotoUpload/photoUpload.constants';
import { TwoColumnContent } from 'screens/styled_common';

import { CardContainer, Description, Subtitle, Title } from './InsuranceCardForm.styled';
import { UploadButton } from './UploadButton';

interface Props {
  onImageUrlChange: (category: CATEGORY, id: number, url: string) => void;
  insuranceProvider?: InsuranceProvider;
}

export const InsuranceCardForm = ({ onImageUrlChange, insuranceProvider }: Props) => {
  return (
    <CardContainer>
      <Title>Take photos of both sides of your insurance card</Title>
      <Subtitle>
        Please upload photos of both sides of your insurance card so we can start checking your
        coverage!
      </Subtitle>
      <TwoColumnContent>
        <UploadButton
          label="Upload Front Side"
          category={CATEGORY.INSURANCE_CARD_FRONT}
          onImageUrlChange={onImageUrlChange}
          insuranceCardUrl={insuranceProvider?.insuranceCardFront}
        />
        <UploadButton
          label="Upload Back Side"
          category={CATEGORY.INSURANCE_CARD_BACK}
          onImageUrlChange={onImageUrlChange}
          insuranceCardUrl={insuranceProvider?.insuranceCardBack}
        />
      </TwoColumnContent>
      <Description>{ALLOWED_FILES_DESCRIPTION}</Description>
    </CardContainer>
  );
};
