import { themes } from 'kb-shared';
import styled from 'styled-components';

export const InstructionText = styled.div`
  font-size: 14px;
  color: ${themes.colors.neutral.primaryNavy};
  display: flex;
  flex-direction: column;
  flex: 1;
  margin-bottom: 12px;

  > div {
    width: 100%;
    margin-bottom: 20px;
  }
`;

export const Link = styled.a`
  font-size: 12px;
  color: ${themes.colors.neutral.primaryNavy};
  font-weight: bold;
  text-decoration: underline;
`;

export const Bullets = styled.ul`
  list-style: circle;
  padding: 5px 30px 5px 30px;
`;
