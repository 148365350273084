import React from 'react';

import { CancellationPolicyProps } from './CancellationPolicy.types';
import { NavigatorEmail } from './NavigatorEmail';
import { NavigatorNumber } from './NavigatorNumber';

const getNoCancelNorRescheduleVariant = (emailBody: string) => {
  return (
    <>
      <p>
        <span>
          This appointment cannot be canceled nor rescheduled in the Patient Portal. Please call us
          at{' '}
        </span>
        <NavigatorNumber />
        <span> or email us at </span>
        <NavigatorEmail emailBody={emailBody} />
        <span> to cancel or reschedule your appointment.</span>
      </p>
    </>
  );
};

const getMoreThan24HoursVariant = (emailBody: string) => {
  return (
    <p>
      <span>
        To reschedule or cancel with less than 24 hours notice, or if you have any questions, email
        us at{' '}
      </span>
      <NavigatorEmail emailBody={emailBody} />
      <span> or call us at </span>
      <NavigatorNumber />
      <span>.</span>
    </p>
  );
};

export const CancellationPolicy = ({
  emailBody,
  noCancelNorRescheduleVariant
}: CancellationPolicyProps): JSX.Element => {
  if (noCancelNorRescheduleVariant) {
    return getNoCancelNorRescheduleVariant(emailBody);
  } else {
    return getMoreThan24HoursVariant(emailBody);
  }
};
