import { Text } from 'components/v2/Typography';
import styled from 'styled-components';

export const Note = styled(Text)`
  color: ${({ theme }) => theme.colors.neutral.navy};
`;

export const Divider = styled.div`
  width: 100%;
  height: 1px;
  background-color: ${({ theme }) => theme.colors.neutral.lavender};
  margin: 28px 0 8px 0;
`;

export const PasswordRequirement = styled.div`
  margin-top: 10px;
  color: ${({ theme }) => theme.colors.neutral.navy};

  p,
  ul {
    margin: 0;
  }

  ul {
    list-style-type: disc;
    list-style-position: outside;

    li {
      margin-left: 16px;
    }

    li span {
      position: relative;
    }
  }
`;
