import React, { useState } from 'react';

import { ConsentModal } from 'components/ConsentModal/ConsentModal';
import PaymentTermsLink from 'screens/ActivateMembership/components/PaymentTermsLink';
import {
  TermsLink,
  TermsText,
  TermsTextContainer
} from 'screens/ActivateMembership/components/TermsLinks.styled';

const TermsLinks = () => {
  const [openedTermOfUseModal, setOpenedTermOfUseModal] = useState<boolean>(false);
  const [openedPrivacyPolicy, setOpenedPrivacyPolicy] = useState<boolean>(false);
  const [openedNoticePrivacyPractices, setOpenedNoticePrivacyPractices] = useState<boolean>(false);

  return (
    <TermsTextContainer>
      <TermsText>
        By clicking the button below, I agree that I have read and accept Kindbody’s{' '}
        <TermsLink onClick={() => setOpenedTermOfUseModal(true)}>Terms of Use</TermsLink>{' '}
        <TermsLink onClick={() => setOpenedPrivacyPolicy(true)}>Privacy Policy</TermsLink>{' '}
        <TermsLink onClick={() => setOpenedNoticePrivacyPractices(true)}>
          Notice of Privacy Practices
        </TermsLink>{' '}
        <PaymentTermsLink />
      </TermsText>

      <ConsentModal
        id="114"
        open={openedTermOfUseModal}
        title="Term of Use"
        close={() => setOpenedTermOfUseModal(false)}
      />
      <ConsentModal
        id="116"
        open={openedPrivacyPolicy}
        title="Privacy Policy"
        close={() => setOpenedPrivacyPolicy(false)}
      />
      <ConsentModal
        id="117"
        open={openedNoticePrivacyPractices}
        title="Notice of Privacy Practices"
        close={() => setOpenedNoticePrivacyPractices(false)}
      />
    </TermsTextContainer>
  );
};

export default TermsLinks;
