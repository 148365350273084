import { gql, useQuery } from '@apollo/client';
import get from 'lodash.get';
import React, { useEffect, useState } from 'react';

import { preOpInstructions, partnerInstructions } from 'kb-shared';

import instructionRenderer from '../instructionRenderer';
import InstructionsModal, { InstructionRow } from './InstructionsModal';

interface ShowProps {
  show: boolean;
  hide: () => void;
}

const PRE_OP_INSTRUCTIONS: InstructionRow[] = preOpInstructions.map(instructionRenderer);

const PARTNER_INSTRUCTIONS: InstructionRow[] = partnerInstructions.map(instructionRenderer);

const CYCLE_PLAN = gql`
  query cyclePlan {
    cyclePlan {
      id
      spermSource
    }
  }
`;

const PreopInstructionsModal = ({ show, hide }: ShowProps) => {
  const [instructions, setInstructions] = useState(PRE_OP_INSTRUCTIONS);

  const { data, loading, error } = useQuery(CYCLE_PLAN);

  useEffect(() => {
    if (!data) return;
    const spermSource = get(data, ['cyclePlan', 'spermSource']);
    if (spermSource === 'Partner') {
      setInstructions(() => [...PRE_OP_INSTRUCTIONS, ...PARTNER_INSTRUCTIONS]);
    }
  }, [data]);

  if (loading || error || !data) {
    return null;
  }

  return (
    <InstructionsModal
      title="Pre-operative Instructions"
      show={show}
      hide={hide}
      rows={instructions}
    />
  );
};

export default PreopInstructionsModal;
