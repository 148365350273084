import React from 'react';

import { Button } from 'components/v2/Buttons/Button';

import UltrasoundsPDF from '../../assets/Booklet-Ultrasounds.pdf';
import SubstepWrapper from '../../SubstepWrapper';
import { Description, FlexWrapper, Title } from './styled-components';

const ReturnOBAppointment = () => (
  <>
    <Title>Return OB Appointment</Title>
    <Description>
      This appointment includes a visit with your provider & a Fetal Growth Ultrasound.
    </Description>
    <FlexWrapper>
      <Description>
        <strong>Fetal Growth Ultrasound:</strong> This ultrasound checks your baby's estimated
        weight, placenta location, and amniotic fluid levels.
      </Description>
      <Button
        label="View More"
        category="secondary"
        size="md"
        onClick={() => window.open(UltrasoundsPDF)}
      />
    </FlexWrapper>
  </>
);

const HospitalTour = () => (
  <>
    <Title>Hospital Tour</Title>
    <Description>
      Your Doula will provide a personalized tour of your delivery hospital with you & your partner
      or support person. The focus of this tour is with you in mind, covering nuances from best
      entrance to the hospital, preview of what to expect on delivery day.
    </Description>
  </>
);

const SUBSTEPS = [{ component: <ReturnOBAppointment /> }, { component: <HospitalTour /> }];

const ObWeek32Substeps = () => <SubstepWrapper substeps={SUBSTEPS} />;

export default ObWeek32Substeps;
