import { theme } from 'kb-shared';
import styled from 'styled-components';

export const Header = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const Section = styled.section`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

export const DetailsWrapper = styled.div`
  margin-top: 12px;
  display: flex;
  flex-direction: column;
  gap: 4px;

  @media screen and (min-width: ${theme.breakpoints.tabletPortrait}) {
    margin-top: 0;
    gap: 16px;
    padding-bottom: 20px;
    border-bottom: 1px solid ${theme.colors.neutral.lightNavy};
  }
`;

export const LocationDetailsWrapper = styled.div`
  background: ${theme.colors.white};
  display: flex;
  flex-direction: column;
  gap: 4px;
  padding: 4px 0 12px 0;
`;
