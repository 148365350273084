import { gql } from '@apollo/client';

export const RECOGNIZE_PATIENT_ENTERPRISE_MEMBERSHIP = gql`
  query recognizePatientEnterpriseMembership($employerAccessCode: String!) {
    recognizePatientEnterpriseMembership(employerAccessCode: $employerAccessCode) {
      requireUniqueId
      walmartWorkflow
    }
  }
`;
