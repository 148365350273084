import React from 'react';

import SubstepWrapper from '../../SubstepWrapper';
import { Description, Title } from './styled-components';

const ReturnOBAppointment = () => (
  <>
    <Title>Return OB Appointment</Title>
    <Description>
      This appointment includes a visit with your provider, an ultrasound, cervix check & fetal NST.
    </Description>
    <Description>
      <strong>Nonstress Test:</strong> A nonstress test (NST) is a test during pregnancy that
      measures your baby's heart rate and response to baby's movement and uterine contractions.
      Indications for an NST include: you feel the baby is not moving as frequently as usual, you
      are overdue, or if you are a high risk pregnancy.
    </Description>
  </>
);

const SUBSTEPS = [{ component: <ReturnOBAppointment /> }];

const ObWeek41Substeps = () => <SubstepWrapper substeps={SUBSTEPS} />;

export default ObWeek41Substeps;
