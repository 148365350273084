import { Button } from 'components/v2/Buttons/Button';
import styled from 'styled-components';

export const HeaderContainer = styled.div`
  margin: 0 1em;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const BookingButton = styled(Button)`
  display: none;
  margin-left: 20px;

  @media ${({ theme }) => theme.queries.minDesktop} {
    display: block;
  }
`;

export const Content = styled.div`
  margin: 0 16px;
  display: flex;
  flex-direction: column;
  gap: 24px;
  margin-top: 32px;
`;
