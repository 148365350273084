import React from 'react';

import { TimelineWrapper, TodoBlock, Wrapper, StepContent } from './SubstepWrapper.styled';
import { SubstepProps } from './SubstepWrapper.types';
import FullTimeline from './Timeline';

const SubstepWrapper = ({ substeps }: SubstepProps) => {
  const isComponent = substeps.some(substep => {
    return !!substep?.component;
  });

  if (!isComponent) return null;

  return (
    <StepContent>
      {substeps.map((substep: any) => {
        return (
          <Wrapper key={substep.component.key || undefined}>
            <TimelineWrapper>
              <FullTimeline substep={true} completed={false} expanded={false} />
            </TimelineWrapper>
            <TodoBlock>{substep.component}</TodoBlock>
          </Wrapper>
        );
      })}
    </StepContent>
  );
};

export default SubstepWrapper;
