import { gql, useQuery } from '@apollo/client';

import { Clinic } from 'kb-shared';
import { BugTracker } from 'kb-shared/utilities/bugTracker';

import { UrlParamValue } from '../types/types';
import { isFalsyNonNumber } from '../utils/validation';

const QUERY_LOCATION = gql`
  query location($locationId: Int!) {
    location(locationId: $locationId) {
      id
      name
      address
      timeZone
      imageUrl
      patientFacing
    }
  }
`;

export const useLocation = (locationId?: UrlParamValue<number>) => {
  const { data, loading, error } = useQuery<{
    location: Clinic;
  }>(QUERY_LOCATION, {
    skip: isFalsyNonNumber(locationId),
    variables: {
      locationId: locationId
    },
    onError: error => {
      BugTracker.notify(error, 'Failed to load location');
    }
  });

  return { data: data?.location, loading, error };
};
