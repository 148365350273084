import React from 'react';

import { MessageFile } from '../MessageFile/MessageFile';
import { MessageText } from './Message.styled';
import { Props } from './Message.types';

export const Message = ({ message }: Props) => {
  return (
    <>
      <MessageText key={message.id} fromPatient={message.fromPatient}>
        <span dangerouslySetInnerHTML={{ __html: message.body }} />
      </MessageText>

      {message.attachments?.map((attachment, index) => {
        return (
          <MessageFile key={index} attachment={attachment} fromPatient={message.fromPatient} />
        );
      })}
    </>
  );
};
