import _sortBy from 'lodash/sortBy';
import React from 'react';

import styled from 'styled-components';

const SelectWrapper = styled.div`
  display: flex;
  flex: 1;
  left: 20px;
  right: 20px;
  flex-wrap: wrap;
  justify-content: center;

  margin-top: -20px;
  @media screen and (min-width: ${({ theme }) => theme.breakpoints.largePhonePortrait}) {
    left: 300px;
    right: 20px;
  }
`;

interface StyledButtonProps {
  selected: boolean;
}

const StyledButton = styled.button`
  width: 150px;
  height: 60px;
  box-sizing: border-box;
  border-radius: 4px;
  margin: 16px;
  line-height: 21px;
  font-size: 17px;
  color: #343754;
  font-weight: 600;
`;

const SelectableButton = styled(StyledButton)<StyledButtonProps>`
  border: solid
    ${({ selected, theme }) =>
      selected ? `3px ${theme.colors.yellow.primary}` : `1px ${theme.deprecatedColors.gray}`};
`;

interface Props<T> {
  labs: T[];
  selectedLab?: T | null;
  onSelect: (lab: T) => void;
}

function InPersonLabSelectorInput<T extends { name: string }>({
  labs,
  selectedLab,
  onSelect
}: Props<T>) {
  const sortedLabs = _sortBy(labs, ['name']);

  const otherLabIndex = sortedLabs.findIndex(lab => lab.name === 'Other');
  if (otherLabIndex !== -1) {
    const otherLab = sortedLabs[otherLabIndex];
    const otherCityLab = { ...otherLab, name: 'Other City' };
    sortedLabs.splice(otherLabIndex, 1);
    sortedLabs.splice(sortedLabs.length + 1, 0, otherCityLab);
  }

  const inPersonLabs = sortedLabs.filter(lab => lab.name !== 'Virtual');

  return (
    <SelectWrapper>
      {inPersonLabs.map(lab => (
        <SelectableButton
          selected={lab.name === selectedLab?.name}
          key={lab.name}
          onClick={() => onSelect(lab)}
        >
          {lab.name}
        </SelectableButton>
      ))}
    </SelectWrapper>
  );
}

export default InPersonLabSelectorInput;
