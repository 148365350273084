import React from 'react';

import styled from 'styled-components';

import {
  NormalValue,
  NormalValueBar,
  RangeBar,
  Legend,
  Value,
  HormoneChartNumber,
  HormoneChartUnit
} from './AtHomeTesting.styled';
import { getNormalizedPosition } from './HormoneValueRange.utils';

interface Props {
  low?: number;
  value: string;
  high?: number;
  units: string;
}

const Wrapper = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  height: 100%;
`;

export const HormoneValueRange = ({ low, value, high, units }: Props) => {
  if (high === undefined || low === undefined) {
    return null;
  }

  const position = getNormalizedPosition(value, low, high);

  return (
    <Wrapper>
      <RangeBar>
        <Legend>Low</Legend>
        <Value position={33}>{low}</Value>
        <Legend>Normal</Legend>
        <Value position={66}>{high}</Value>
        <Legend>High</Legend>
      </RangeBar>

      <NormalValue position={position}>
        <HormoneChartNumber>{value}</HormoneChartNumber>
        <HormoneChartUnit>{units}</HormoneChartUnit>
        <NormalValueBar />
      </NormalValue>
    </Wrapper>
  );
};
