import React from 'react';

import { Text } from 'components/v2/Typography';
import { LabInfo } from 'kb-shared/utilities/kindbody_contacts';

import { CallTextNumberContainer, ModalContentContainer } from './ModalContent.styled';

export const ModalContent = ({ lab }: { lab: LabInfo }) => {
  const message = encodeURI('I have more questions.\nCan you help me?');
  return (
    <ModalContentContainer>
      <CallTextNumberContainer>
        <Text>
          If you have more questions please call our office at{' '}
          <a href={`tel:${lab.number}`}>
            <strong>{lab.number}</strong>
          </a>{' '}
          at any time. Urgent medical questions will be routed to our on-call care team after hours.
        </Text>
      </CallTextNumberContainer>

      {lab.textingNumber && (
        <CallTextNumberContainer>
          <Text>
            You may also text the nurse on call between 4:00 PM and 9:00 PM with any questions about
            your medications at{' '}
            <a href={`sms:${lab.textingNumber}?body=${message}`}>
              <strong>{lab.textingNumber}</strong>
            </a>
            {'. '}
            (This line should only be used for urgent medical questions).
          </Text>
        </CallTextNumberContainer>
      )}
    </ModalContentContainer>
  );
};
