import React, { useRef, useEffect, useState } from 'react';

import { usePatient } from 'hooks';

import { Navigation } from '../Navigation/Navigation';

export const NavigationContainer = () => {
  const { patient } = usePatient();
  const ref = useRef<HTMLDivElement>(null);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [closeOnClickOutsideDisabled, setCloseOnClickOutsideDisabled] = useState(false);

  const toggleMenu = () => setIsMenuOpen(!isMenuOpen);

  const handleClickOutside = (event: MouseEvent) => {
    if (
      isMenuOpen &&
      !closeOnClickOutsideDisabled &&
      ref.current &&
      //@ts-ignore
      !ref.current.contains(event.target)
    ) {
      setIsMenuOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  });

  if (patient == null) return null;

  return (
    <div ref={ref}>
      <Navigation
        isMenuOpen={isMenuOpen}
        toggleMenu={toggleMenu}
        patient={patient}
        onEnableMenuClose={() => setCloseOnClickOutsideDisabled(false)}
        onDisableMenuClose={() => setCloseOnClickOutsideDisabled(true)}
      />
    </div>
  );
};
