import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
`;

export const Title = styled.div`
  margin-bottom: 1.5em;
  display: none;

  @media (min-width: ${({ theme }) => theme.breakpoints.smallDesktop}) {
    display: block;
  }
`;

export const MobileTitle = styled.div`
  display: flex;
  justify-content: space-between;

  @media (min-width: ${({ theme }) => theme.breakpoints.smallDesktop}) {
    display: none;
  }
`;

export const Header = styled.div<{ $columns: number }>`
  display: none;

  @media (min-width: ${({ theme }) => theme.breakpoints.smallDesktop}) {
    display: grid;
    grid-template-columns: 3fr repeat(${({ $columns }) => $columns - 1}, 1fr);
    gap: 2em;
  }
`;

const BaseRow = styled.div`
  border-bottom: 1px solid ${({ theme }) => theme.colors.neutral.lavender};
  align-items: center;
  padding: 0.75em 0;
  min-height: 64px;
`;

export const Row = styled(BaseRow)<{ $columns: number }>`
  display: none;

  @media (min-width: ${({ theme }) => theme.breakpoints.smallDesktop}) {
    display: grid;
    grid-template-columns: 3fr repeat(${({ $columns }) => $columns - 1}, 1fr);
    gap: 2em;

    > :last-child {
      justify-self: end;
    }
  }
`;

export const MobileRow = styled(BaseRow)`
  display: flex;
  gap: 1em;

  > :first-child {
    flex: 1;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.smallDesktop}) {
    display: none;
  }
`;

export const Info = styled.div`
  display: flex;
  gap: 0.5em;
  flex-direction: column;
`;

export const Actions = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
`;

export const SortInfo = styled.div`
  display: flex;
  align-items: center;
  gap: 0.16em;
`;

export const EmptyState = styled.div`
  text-align: center;
  padding: 24px 24px;
  gap: 32px;
  border-radius: 8px;
  border: 1px dashed ${({ theme }) => theme.colors.neutral.lighterNavy};
  background: ${({ theme }) => theme.colors.neutral.lavenderLight};
  display: flex;
  flex-direction: column;
  gap: 0.33em;
`;
