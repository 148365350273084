import React, { useEffect, useRef, useState } from 'react';

import { useOnWindowResize } from 'hooks/useOnWindowResize';

export const PdfConsentViewer = ({ pdf }: { pdf: string }) => {
  const ref = useRef<HTMLObjectElement>(null);
  const [height, setHeight] = useState(0);

  const updateSize = () => {
    if (ref.current) {
      const width = ref.current.offsetWidth;
      const a4AspectRatio = 1.414;
      const contentWidthPercentage = 0.913;
      const height = width * contentWidthPercentage * a4AspectRatio;
      setHeight(height);
    }
  };

  // Custom hook to handle resize
  useOnWindowResize(() => {
    updateSize();
  }, 300);

  useEffect(() => {
    updateSize(); // Initial size update on component mount
  }, []);

  const pdfWithoutToolbar = pdf + '#toolbar=0';
  return (
    <object
      ref={ref}
      data={pdfWithoutToolbar}
      width="100%"
      height={height}
      type="application/pdf"
      aria-label="PDF consent"
    />
  );
};
