import React, { useState } from 'react';

import { isEmailValid } from 'kb-shared/utilities/validation';
import { showErrorToast } from 'utilities/notificationUtils';

import Text from '../../Primitives/FormText';
import TextInput from '../../Primitives/TextInput';
import { Container } from './EmailForm.styled';
import { EmailFormProps } from './EmailForm.types';

const EmailForm: React.FC<EmailFormProps> = ({
  title,
  onSave,
  answer,
  isRequired
}: EmailFormProps) => {
  const [email, setEmail] = useState(answer?.data || '');

  const onKeyPress = (e: { key: string }) => {
    if (e.key === 'Enter') {
      onAnswered(email);
    }
  };

  const onAnswered = (value: string) => {
    if (isEmailValid(value)) {
      onSave(value);
    } else {
      showErrorToast('Please enter valid email!');
    }
  };

  return (
    <Container>
      <Text isRequired={isRequired}>{title}</Text>

      <TextInput
        value={email}
        placeholder={'Type your email here...'}
        onKeyPress={onKeyPress}
        onChange={e => setEmail(e.target.value)}
        onBlur={e => onAnswered(e.target.value)}
      />
    </Container>
  );
};

export default EmailForm;
