import styled from 'styled-components';

import { typography } from '../../../styled';

const {
  serif: {
    copy: { Large }
  },
  sans: {
    copy: { Medium }
  }
} = typography;

export const LargeOption = styled(Large)`
  display: flex;
  flex-direction: column;
  align-items: center;
  background: white;
`;

export const LargeOptionIcon = styled.span`
  margin-top: 0.75em;
`;

export const MediumText = Medium;
