import { Calendar } from 'assets/icons/Icons';
import { defaultState, filledOutlineShadow } from 'components/v2/common.styled';
import styled, { css } from 'styled-components';

import { Status } from '../../types';
import { inputBaseCSS, inputStatusStyle } from '../TextInput/TextInput.styled';

export const CalendarIcon = styled(Calendar)`
  padding: 0.6rem 21px !important;
`;

export const DateInputStyled = styled.div<{ status: Status; isFilled: boolean }>`
  color: ${defaultState.textColor};

  & svg {
    width: 22px;
    height: 22px;
    margin-top: 10px;
    fill: ${defaultState.sideIconFillColor};
  }

  & input {
    ${inputBaseCSS}

    ${({ isFilled }) =>
      isFilled &&
      css`
        box-shadow: ${filledOutlineShadow};
      `}

    ${({ status }) => inputStatusStyle[status]}

    padding: 20px 12px 20px 56px;
  }

  > div.react-datepicker-wrapper {
    width: 100%;
  }
`;
