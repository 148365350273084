import React from 'react';

import { RenderableItem } from 'kb-shared';

export default (item: RenderableItem) => {
  const { name, body } = item;
  return {
    name,
    body: (
      <>
        {body.map((b, i) => {
          const { type, copy, items } = b;
          const itemKey = `${name}-instr-row-${i}`;
          switch (type) {
            case 'copy':
              return <p key={itemKey}>{copy}</p>;
            case 'strong':
              return (
                <p key={itemKey}>
                  <strong>{copy}</strong>
                </p>
              );
            case 'list':
              return (
                <ul key={itemKey}>
                  {(items || []).map(i => (
                    <li key={`items-${type}-${i}`}>{i}</li>
                  ))}
                </ul>
              );
            default:
              return null;
          }
        })}
      </>
    )
  };
};
