import React from 'react';

import { Text } from '../../Typography';
import { StyledLinkButton } from './LinkButton.styled';
import { Props } from './LinkButton.types';

/**
 * Component to be used for links that don't have meaningful href.
 */
export const LinkButton = (props: Props) => {
  const {
    text,
    size = 'md',
    fontStyle = 'regular',
    onClick,
    color,
    ariaLabel,
    underline = 'always'
  } = props;

  return (
    <StyledLinkButton
      type="button"
      onClick={onClick}
      aria-label={ariaLabel}
      underline={underline}
      size={size}
    >
      {props.leftIcon ?? <></>}
      {text && (
        <Text size={size} fontStyle={fontStyle} color={color} transform={props.transform}>
          {text}
        </Text>
      )}
      {props.rightIcon ?? <></>}
    </StyledLinkButton>
  );
};
