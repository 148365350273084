import React from 'react';

import { CreditCard as CreditCardType } from 'hooks/useCreditCards/useCreditCards.types';

import { CreditCardBackgroundAndBorder } from './CreditCard.styled';
import { CreditCardDetail } from './CreditCardDetail';
import { CreditCardHeader } from './CreditCardHeader';
import { CreditCardNumber } from './CreditCardNumber';
import { useCreditCardMutation } from './useCreditCardMutation';

type CreditCardProps = {
  creditCard: CreditCardType;
};

export const CreditCard = ({ creditCard }: CreditCardProps) => {
  const { setDefaultCard, deleteCard } = useCreditCardMutation();
  const { brand, stripeIdentifier, lastFourDigits, expirationMonth, expirationYear } = creditCard;

  // default is a reserved word in javascript, can't use it as a variable name
  const isDefault = creditCard.default || false;

  const setAsPrimary = () => {
    setDefaultCard({
      variables: { stripeIdentifier: stripeIdentifier }
    });
  };

  const removeCard = () => {
    deleteCard({
      variables: { stripeIdentifier: stripeIdentifier }
    });
  };

  return (
    <CreditCardBackgroundAndBorder isDefault={isDefault} data-testid="credit-card">
      <CreditCardHeader
        type={brand}
        isDefault={isDefault}
        setAsPrimary={setAsPrimary}
        removeCard={removeCard}
      />
      <CreditCardNumber lastFourDigits={lastFourDigits} />
      <CreditCardDetail valid={`${expirationMonth}/${expirationYear}`} />
    </CreditCardBackgroundAndBorder>
  );
};
