import styled from 'styled-components';

export const ConsentCompletedContainer = styled.div`
  flex-direction: column;
  height: 300px;
  align-content: center;
  justify-content: center;
  display: flex;
  align-items: center;
`;
export const Explanation = styled.span`
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
`;
