import React from 'react';

import kindbody from 'assets/logos/kindbody.svg';

import Modal from '../Modal';
import { AppleModalContainer } from './AppleDisclaimerModal.styled';
import { AppleModalProps } from './AppleDisclaimerModal.types';

const AppleNotLoadedModal = (props: AppleModalProps) => {
  return (
    <Modal
      open={props.showModal}
      onRequestClose={props.handleClose}
      label="Apple not loaded disclaimer"
    >
      <AppleModalContainer className="modal__wrapper">
        <img className="modal__logo" src={kindbody} alt="KindBody Logo" />
        <h2 className="modal__title h2">Apple sign-in failed</h2>
        <p>Apple sign-in failed to load. Please try refreshing your web browser.</p>
        <button className="modal__button button type__button" onClick={props.handleClose}>
          Refresh
        </button>
      </AppleModalContainer>
    </Modal>
  );
};

export default AppleNotLoadedModal;
