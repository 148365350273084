import invert from 'lodash.invert';

import { labs } from './lab.helper';

const KBContacts = Object.freeze({
  employeeBenefitsEmail: 'employeebenefits@kindbody.com',
  navigatorEmail: 'navigator@kindbody.com',
  navigatorNumber: '+01 (855) 563-2639',

  // default texting values
  clinical: 'clinical@kindbody.com',
  centralNumber: '+01 (720) 903-1535',
  southernNumber: '+01 (404) 991-6278',
  easternNumber: '+01 (609) 293-3480',
  texasNumber: '+01 (210) 908-5258',
  californiaNumber: '+01 (315) 659-9094',
  chicagoNumber: '+01 (689) 232-4843',
  stLouisNumber: '+01 (689) 232-4181',

  portlandEmail: 'pnw.pc@kindbody.com'
});

const market = labs();

const defaultMarketInfo: LabInfo = {
  numberLabel: '855 KND BODY',
  number: KBContacts.navigatorNumber,
  email: KBContacts.navigatorEmail
};

type LabName = keyof typeof LABS;

export interface LabInfo {
  numberLabel: string;
  email: string;
  number: string;
  textingNumber?: string;
}

const LABS: Record<string, LabInfo> = Object.freeze({
  NONE: {
    id: '0',
    ...defaultMarketInfo
  },
  ARKANSAS: {
    id: market.ARKANSAS,
    ...defaultMarketInfo,
    textingNumber: KBContacts.centralNumber
  },
  ATLANTA: {
    id: market.ATLANTA,
    ...defaultMarketInfo,
    textingNumber: KBContacts.southernNumber
  },
  AUSTIN: {
    id: market.AUSTIN,
    ...defaultMarketInfo,
    textingNumber: KBContacts.texasNumber
  },
  BETHESDA: {
    id: market.BETHESDA,
    ...defaultMarketInfo,
    textingNumber: KBContacts.southernNumber
  },
  CHICAGO: {
    id: market.CHICAGO,
    ...defaultMarketInfo,
    textingNumber: KBContacts.chicagoNumber
  },
  COLUMBUS: {
    id: market.COLUMBUS,
    ...defaultMarketInfo,
    textingNumber: KBContacts.easternNumber
  },
  DALLAS: {
    id: market.DALLAS,
    ...defaultMarketInfo,
    textingNumber: KBContacts.texasNumber
  },
  DENVER: {
    id: market.DENVER,
    ...defaultMarketInfo,
    textingNumber: KBContacts.centralNumber
  },
  DETROIT: {
    id: market.DETROIT,
    ...defaultMarketInfo
    // has just default market info default number
  },
  EAST_BAY: {
    id: market.EAST_BAY,
    ...defaultMarketInfo,
    textingNumber: KBContacts.californiaNumber
  },
  HOUSTON: {
    id: market.HOUSTON,
    ...defaultMarketInfo,
    textingNumber: KBContacts.texasNumber
  },
  LOS_ANGELES: {
    id: market.LOS_ANGELES,
    ...defaultMarketInfo,
    textingNumber: KBContacts.californiaNumber
  },
  MIAMI: {
    id: market.MIAMI,
    ...defaultMarketInfo,
    textingNumber: KBContacts.southernNumber
  },
  MILWAUKEE: {
    id: market.MILWAUKEE,
    ...defaultMarketInfo,
    textingNumber: KBContacts.centralNumber
  },
  MINNEAPOLIS: {
    id: market.MINNEAPOLIS,
    ...defaultMarketInfo,
    textingNumber: KBContacts.centralNumber
  },
  NEWPORT_BEACH: {
    id: market.NEWPORT_BEACH,
    ...defaultMarketInfo,
    textingNumber: KBContacts.californiaNumber
  },
  NEW_YORK: {
    id: market.NEW_YORK,
    ...defaultMarketInfo,
    textingNumber: KBContacts.easternNumber
  },
  ORLANDO: {
    id: market.ORLANDO,
    ...defaultMarketInfo
  },
  OTHER: {
    id: market.OTHER,
    ...defaultMarketInfo
  },
  PHILADELPHIA: {
    id: market.PHILADELPHIA,
    ...defaultMarketInfo,
    textingNumber: KBContacts.easternNumber
  },
  PORTLAND: {
    id: market.PORTLAND,
    ...defaultMarketInfo,
    textingNumber: KBContacts.californiaNumber,
    email: KBContacts.portlandEmail
  },
  PRINCETON: {
    id: market.PRINCETON,
    ...defaultMarketInfo,
    textingNumber: KBContacts.easternNumber
  },
  SAN_DIEGO: {
    id: market.SAN_DIEGO,
    ...defaultMarketInfo,
    textingNumber: KBContacts.californiaNumber
  },
  SAN_FRANCISCO: {
    id: market.SAN_FRANCISCO,
    ...defaultMarketInfo,
    textingNumber: KBContacts.californiaNumber
  },
  SILICON_VALLEY: {
    id: market.SILICON_VALLEY,
    ...defaultMarketInfo,
    textingNumber: KBContacts.californiaNumber
  },
  ST_LOUIS: {
    id: market.ST_LOUIS,
    ...defaultMarketInfo,
    textingNumber: KBContacts.stLouisNumber
  },
  WASHINGTON_DC: {
    id: market.WASHINGTON_DC,
    ...defaultMarketInfo,
    textingNumber: KBContacts.southernNumber
  }
});

export function getLabInfo(labId: string | undefined = 'NONE'): LabInfo {
  const labsForEnvMap = labs();
  // Reverse keys/values
  const inverted = invert(labsForEnvMap);
  //@ts-ignore
  const locationAbbreviation: LabName = inverted[labId] || 'NONE';

  const contactInfo = locationAbbreviation && LABS[locationAbbreviation];
  return contactInfo || LABS.NONE;
}

export default KBContacts;
