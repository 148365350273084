import { AppointmentProduct } from 'kb-shared/types';

export const getAppointmentType = (product?: AppointmentProduct) => {
  if (product?.type === 'appointment') {
    return product.data;
  }

  if (product?.type === 'reschedule_appointment') {
    return product.data?.appointmentType;
  }

  return null;
};
