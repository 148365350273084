import React, { ReactNode, ComponentType } from 'react';
import { RouteComponentProps } from 'react-router-dom';

import { ProtectedRoute } from './ProtectedRoute';

interface ProtectedProps extends RouteComponentProps {
  children?: ReactNode;
}

// withProtectedRoute will check if user is logged in before
// rendering component or if they have a consent requirement
export function withProtectedRoute(WrappedComponent: ComponentType<RouteComponentProps>) {
  const Protected = (props: ProtectedProps) => (
    <ProtectedRoute>
      <WrappedComponent {...props} />
    </ProtectedRoute>
  );

  return Protected;
}
