import debounce from 'lodash.debounce';
import React, { useEffect, useRef } from 'react';

import { TextContainer } from './ClampedTextContainer.styled';

export const ClampedTextContainer = ({
  lineClamp,
  children,
  onClampVisibilityChange
}: {
  lineClamp: number;
  children: React.ReactNode;
  onClampVisibilityChange: (visibility: boolean) => void;
}) => {
  const containerRef = useRef<HTMLDivElement | null>(null);

  const hasClamping = (el: HTMLDivElement) => {
    const { clientHeight, scrollHeight } = el;
    // Sometimes there is a small diff between client and scroll height, but too small for browser to cut the text.
    return Math.abs(clientHeight - scrollHeight) > 10;
  };

  useEffect(() => {
    const checkClampVisibility = () => {
      if (!containerRef.current) return;

      onClampVisibilityChange(hasClamping(containerRef.current));
    };

    const debouncedCheck = debounce(checkClampVisibility, 50);

    checkClampVisibility();
    window.addEventListener('resize', debouncedCheck);

    return () => {
      window.removeEventListener('resize', debouncedCheck);
    };
  }, [containerRef, onClampVisibilityChange]);

  useEffect(() => {
    if (!containerRef.current) return;

    onClampVisibilityChange(hasClamping(containerRef.current));
  }, [children, onClampVisibilityChange]);

  return (
    <TextContainer ref={containerRef} lineClamp={lineClamp}>
      {children}
    </TextContainer>
  );
};
