import { Location } from 'history';

import { RoutesPath } from 'utilities/pageUrl';
import { pageUrl } from 'utilities/pageUrl';

import { DEPRECATED_MEMBERSHIP_ACTIVATION } from './InterstitialModal.constants';
import { INTERSTITIAL_MODAL_ACTIVATED_KEY } from './InterstitialModal.constants';

export const activateModal = () => {
  sessionStorage.setItem(INTERSTITIAL_MODAL_ACTIVATED_KEY, 'true');
};

export const deactivateModal = () => {
  sessionStorage.removeItem(INTERSTITIAL_MODAL_ACTIVATED_KEY);
};

export const checkShouldRedirectBackToMembership = (location: Location, open: boolean) => {
  if (!open) return;

  const shouldRedirectBackToMembership =
    location.search === DEPRECATED_MEMBERSHIP_ACTIVATION ||
    location.pathname === RoutesPath.ENTER_MEMBERSHIP_ACCESS_CODE;

  if (shouldRedirectBackToMembership) {
    localStorage.setItem('redirectBackToMembership', 'true');
  }

  const shouldRedirectBackToBooking = location.pathname === '/create-account';
  if (shouldRedirectBackToBooking) {
    localStorage.setItem('redirectBackToBooking', 'true');
    localStorage.setItem('redirectBackToBookingUrl', `${location.pathname}${location.search}`);
  }
};

export const getProceedButtonProps = () => {
  const redirectBackToMembership = localStorage.getItem('redirectBackToMembership');

  if (redirectBackToMembership) {
    return {
      pathname: RoutesPath.ENTER_MEMBERSHIP_ACCESS_CODE,
      text: 'Proceed to Activate Benefit'
    };
  }

  const location = window.location;

  if (location.pathname === '/create-account') {
    return { pathname: '', text: 'Proceed to Appointment Booking' };
  }

  if (location.pathname === '/my-profile') {
    return { pathname: pageUrl.myProfile('contact'), text: 'Proceed to My Profile' };
  }

  return { pathname: '/', text: 'Proceed to Dashboard' };
};

export const formatAsList = (array: string[], prefix: string): string => {
  try {
    // Our current TS types don't support the Intl.ListFormat
    // @ts-ignore-next-line
    const formatter = new Intl.ListFormat('en');
    return `${prefix}${formatter.format(array)}`;
  } catch {
    return `${prefix}${array.join(', ')}`;
  }
};
