import styled from 'styled-components';

export const MenuIconContainer = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  justify-content: center;

  width: 48px;
  height: 48px;
  margin-top: 24px;
  margin-bottom: 32px;

  svg {
    left: 0px;
    g:first-child {
      circle {
        opacity: 0 !important;
      }
    }
  }
`;
export const MenuIconImageContainer = styled.div`
  display: flex;
`;

export const MenuCenterIcon = styled.span`
  color: white;
  font-size: 16px;
  margin-top: 7px;
`;
