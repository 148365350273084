import React, { Component } from 'react';

import { Answer } from 'kb-shared';
import styled from 'styled-components';

import ChoiceButton from '../ChoiceButton';
import Text from '../Primitives/FormText';

type Props = {
  title: string;
  options: Array<string>;
  answer: Answer | null;
  isRequired: boolean | null;
  onSubmit: (response: string) => void;
};

type State = {
  selectedOption: string;
};

export default class SelectionForm extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      selectedOption: (props.answer && props.answer.data) || ''
    };
  }

  selectOption(option: string) {
    this.setState({
      selectedOption: option
    });
    this.props.onSubmit(option);
  }

  renderOptions() {
    return this.props.options.map((option: string) => {
      const selected = this.state.selectedOption === option;
      return (
        <ButtonWrapper key={option}>
          <ChoiceButton
            text={option}
            selected={selected}
            onClick={this.selectOption.bind(this, option)}
          />
        </ButtonWrapper>
      );
    });
  }

  render() {
    return (
      <Container>
        <Text isRequired={this.props.isRequired}>{this.props.title}</Text>
        <ButtonContainer>{this.renderOptions()}</ButtonContainer>
      </Container>
    );
  }
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-flow: row wrap;
  @media (max-width: 700px) {
    flex-direction: column;
  }
`;

const ButtonWrapper = styled.div`
  margin: 24px 12px 24px 12px;
  @media (max-width: 700px) {
    margin: 10px;
  }
`;
