import moment, { Moment as TMoment } from 'moment';
import React, { Component, MouseEvent } from 'react';

import ArrowLeft from 'components/SVG/ArrowLeft';
import ArrowRight from 'components/SVG/ArrowRight';
import { TimeSlot, themes } from 'kb-shared';
import { momentFormatted } from 'kb-shared/utilities/momentHelpers';
import styled from 'styled-components';

interface ContainerProps {
  renderBorder?: boolean;
}

interface ArrowProps {
  isActive?: boolean;
}

const Container = styled.div`
  max-width: 330px;
  max-height: 300px;
  border: ${(props: ContainerProps) => {
    return props.renderBorder ? 'solid 1px #ccc' : '0px';
  }};
  border-radius: 4px;
  overflow: 'hidden';
  margin-bottom: 20px;
`;

const CalendarHeader = styled.h2`
  font-size: 1.5em;
  background: white;
`;

const Navigation = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  height: 46px;
  width: 100%;
`;

const ArrowWrapperRight = styled.button.attrs({
  'data-testid': 'button-prev-month',
  type: 'button'
})`
  cursor: pointer;
  padding: 5px 8px;
  border-width: 0px;
  background-color: ${themes.colors.yellow.primary};
  :focus {
    outline: 0;
  }
  border-top-right-radius: 16px;
  border-bottom-right-radius: 16px;
`;

const ArrowWrapperLeft = styled.button.attrs({
  'data-testid': 'button-next-month',
  type: 'button'
})`
  cursor: pointer;
  padding: 5px 8px;
  border-width: 0px;
  background-color: ${(props: ArrowProps) => {
    return props.isActive ? themes.colors.yellow.primary : themes.deprecatedColors.grayLight;
  }};
  :focus {
    outline: 0;
  }
  border-top-left-radius: 16px;
  border-bottom-left-radius: 16px;
`;

const DatesWrapper = styled.div.attrs({ 'data-testid': 'calendar-dates-container' })`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const RowWrapper = styled.div`
  height: 38px;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const CurrentDate = `
  border: 2px solid ${themes.deprecatedColors.gray};
  border-radius: 25px;
  width: 32px;
  height: 32px;
  margin: 4px;
`;

const SelectedDate = `
  background-color: ${themes.colors.yellow.primary};
  border-radius: 25px;
  width: 32px;
  height: 32px;
  margin: 6px;
`;

const Date = styled.button.attrs({ type: 'button' })`
  cursor: pointer;
  width: 27px;
  height: 27px;
  margin: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: black;
  ${(props: DateProps) => {
    return props.selected ? SelectedDate : ``;
  }}
  ${(props: DateProps) => {
    return props.current ? CurrentDate : ``;
  }}
  ${(props: DateProps) => {
    return props.disabled ? `color: #ccc` : ``;
  }}
`;

const DayOfWeek = styled.div`
  width: 27px;
  height: 27px;
  margin: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 15px;
  font-weight: 600;
`;

interface DateProps {
  selected?: boolean;
  current?: boolean;
  disabled?: boolean;
}

export type CalendarCell = {
  isDate?: boolean;
  isSelected?: boolean;
  isToday?: boolean;
  value: string;
  date?: TMoment;
};

export function getCalendarCell(
  value: string,
  isDate?: boolean,
  isSelected?: boolean,
  isToday?: boolean,
  date?: TMoment
): CalendarCell {
  return {
    value,
    isDate,
    isSelected,
    isToday,
    date
  };
}

export function getCalendarRows(
  currentMonth: TMoment,
  selectedDate: TMoment | null,
  currentDayCanBeSelected: boolean | undefined
): Array<Array<CalendarCell>> {
  const daysInMonth = currentMonth.daysInMonth();
  const firstWeekdayOfMonth = currentMonth.startOf('month').day();
  const today = moment();

  const rowsInMonth = Math.ceil((daysInMonth + firstWeekdayOfMonth) / 7);
  const calendarCells = [];
  for (let i = 0; i < rowsInMonth * 7; i++) {
    calendarCells.push(getCalendarCell(''));
  }

  let dayOfMonth = 1;
  for (
    let cellIndex = firstWeekdayOfMonth;
    cellIndex < daysInMonth + firstWeekdayOfMonth;
    cellIndex++
  ) {
    const isToday =
      today.date() === dayOfMonth &&
      today.month() === currentMonth.month() &&
      today.year() === currentMonth.year();
    const cellDate = currentMonth.clone().date(dayOfMonth);
    let isSelected =
      selectedDate &&
      currentMonth.month() === selectedDate.month() &&
      dayOfMonth === selectedDate.tz(moment.tz.guess()).date() &&
      currentMonth.year() === selectedDate.year();

    if (
      isSelected === true &&
      selectedDate?.date() === today.date() &&
      selectedDate?.month() === today.month() &&
      !currentDayCanBeSelected
    ) {
      isSelected = false;
      selectedDate.add(1, 'days');
    }
    if (!isSelected) isSelected = false;

    calendarCells[cellIndex] = getCalendarCell(
      dayOfMonth.toString(),
      true,
      isSelected,
      isToday,
      cellDate
    );
    dayOfMonth++;
  }

  const calendarRows = [];
  for (let i = 0; i < rowsInMonth; i++) {
    calendarRows.push(calendarCells.slice(i * 7, (i + 1) * 7));
  }

  return calendarRows;
}

export function getDaysOfWeek() {
  const daysOfWeek = [];

  for (let i = 0; i < 7; i++) {
    const dayOfWeek = moment()
      .isoWeekday(i)
      .format('dd');

    daysOfWeek.push(getCalendarCell(dayOfWeek));
  }

  return daysOfWeek;
}

type Props = {
  selectedDate: TMoment | null;
  currentMonth: TMoment;
  onPrevious: (event: MouseEvent) => void;
  onNext: (event: MouseEvent) => void;
  onSelectDate?: (date: TMoment) => void;
  renderBorder?: boolean;
  timeSlotsResults: Array<TimeSlot>;
  currentDayCanBeSelected?: boolean;
};

type State = {
  today: TMoment;
  yesterday: TMoment;
};

export default class BookingCalendar extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      today: moment(),
      yesterday: moment().add(-1, 'days')
    };
  }

  selectDate(day: number) {
    const { onSelectDate } = this.props;
    const date = this.props.currentMonth.clone().date(day);
    if (!onSelectDate) return;
    onSelectDate(date);
  }

  enableArrowDateComparison = (currentDate: TMoment, dateComparison: TMoment) => {
    const currentMonth = currentDate.month();
    const currentYear = currentDate.year();

    const comparisonMonth = dateComparison.month();
    const comparisonYear = dateComparison.year();

    return (
      currentYear < comparisonYear ||
      (currentYear === comparisonYear && currentMonth < comparisonMonth)
    );
  };

  findAvailableDate = (date: TMoment | undefined, timeSlotsResults: Array<TimeSlot>) => {
    const foundAvailableDate = timeSlotsResults.find(
      timeSlot =>
        date?.date() ===
        moment(timeSlot.startTime)
          .tz(moment.tz.guess())
          .date()
    );
    return foundAvailableDate != null;
  };

  render() {
    const { today } = this.state;
    const { currentMonth, timeSlotsResults } = this.props;
    const yesterday = today.subtract(1, 'days');
    const activeArrow = this.enableArrowDateComparison(today, currentMonth);

    return (
      <Container renderBorder={this.props.renderBorder}>
        <Navigation>
          <ArrowWrapperLeft
            disabled={!activeArrow}
            isActive={activeArrow}
            onClick={this.props.onPrevious}
            aria-label="Previous month"
          >
            {' '}
            <ArrowLeft />{' '}
          </ArrowWrapperLeft>
          <CalendarHeader>{this.props.currentMonth.format('MMMM YYYY')}</CalendarHeader>
          <ArrowWrapperRight onClick={this.props.onNext} aria-label="Next month">
            <ArrowRight />{' '}
          </ArrowWrapperRight>
        </Navigation>
        <DatesWrapper>
          <RowWrapper>
            <DayOfWeek>Sun</DayOfWeek>
            <DayOfWeek>Mon</DayOfWeek>
            <DayOfWeek>Tue</DayOfWeek>
            <DayOfWeek>Wed</DayOfWeek>
            <DayOfWeek>Thu</DayOfWeek>
            <DayOfWeek>Fri</DayOfWeek>
            <DayOfWeek>Sat</DayOfWeek>
          </RowWrapper>
          {getCalendarRows(
            moment(this.props.currentMonth),
            this.props.selectedDate,
            this.props.currentDayCanBeSelected
          ).map((row: CalendarCell[], index: number) => {
            const month = this.props.currentMonth.month();
            return (
              <RowWrapper key={`row-${month}-${index}`}>
                {row.map((cell: CalendarCell, cellIndex: number) => {
                  const isDisabled =
                    !cell.date ||
                    yesterday > cell.date ||
                    today.endOf('day') > cell.date ||
                    !this.findAvailableDate(cell.date, timeSlotsResults);
                  return (
                    <Date
                      key={`date-${month}-${cellIndex}`}
                      selected={cell.isSelected}
                      current={cell.isToday}
                      onClick={
                        !isDisabled ? this.selectDate.bind(this, parseInt(cell.value)) : () => {}
                      }
                      disabled={isDisabled}
                      aria-hidden={!cell.date}
                      aria-selected={cell.isSelected}
                      aria-label={
                        cell.date && momentFormatted(cell.date.toString(), 'MMM DD, YYYY')
                      }
                    >
                      {cell.value}
                    </Date>
                  );
                })}
              </RowWrapper>
            );
          })}
        </DatesWrapper>
      </Container>
    );
  }
}
