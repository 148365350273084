import { useQuery } from '@apollo/client';
import React from 'react';
import { useParams } from 'react-router-dom';

import { BugTracker } from 'kb-shared/utilities/bugTracker';
import KBContacts from 'kb-shared/utilities/kindbody_contacts';
import { getGraphQLErrorsMessages } from 'utilities/errors';

import { VIRTUAL_MEETING_QUERY } from './AppointmentVirtualMeeting.graphql';
import { Container, Title, Loader, Message, Button } from './AppointmentVirtualMeeting.styled';
import { Params, MeetingInfo, Status } from './AppointmentVirtualMeeting.types';
import SoftwareInstructions from './SoftwareInstructions';

const AppointmentVirtualMeeting = () => {
  const params = useParams<Params>();
  const appointmentPublicIdentifier = params.appointmentPublicIdentifier;
  const { data, loading, error } = useQuery(VIRTUAL_MEETING_QUERY, {
    variables: { appointmentPublicIdentifier },
    onError: error => {
      BugTracker.notify(error, 'Failed to fetch appt virtual meeting');
    }
  });

  if (loading)
    return (
      <Container>
        <Title>Virtual Meeting</Title>
        <Loader />
      </Container>
    );

  if (error) {
    return (
      <Container>
        <Title>Virtual Meeting</Title>
        <Message>{getGraphQLErrorsMessages(error).join(' ') || DEFAULT_ERROR_MESSAGE}</Message>
      </Container>
    );
  }

  const {
    realMeetingUrl,
    uiMessage,
    status,
    software
  }: MeetingInfo = data.appointmentVirtualMeeting;

  return (
    <Container>
      <Title>Virtual Meeting</Title>
      <Message>{uiMessage}</Message>
      {status === Status.Open && realMeetingUrl && (
        <Button role="link" onClick={() => openInNewTab(realMeetingUrl)}>
          Join Meeting
        </Button>
      )}
      {software && <SoftwareInstructions software={software} />}
    </Container>
  );
};

const openInNewTab = (url: string) => {
  window.open(url, '_blank', 'noreferrer');
};

const DEFAULT_ERROR_MESSAGE = `Information for joining the meeting currently can't be displayed. Please refresh your browser to try again, or contact ${KBContacts.navigatorEmail}`;

export default AppointmentVirtualMeeting;
