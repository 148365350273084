import React from 'react';

import { ConsentAcceptAction } from '../../Components/ConsentAcceptAction/ConsentAcceptAction';
import { ConsentHTMLError } from '../../Components/ConsentHTML/ConsentHTMLError';
import { PdfConsentViewer } from './PdfConsentViewer';
import { Body, ConsentText, Container, Content, Divider } from './YourConsent.styled';
import { YourConsentProps } from './YourConsent.types';

export function YourConsent({
  consentRawHtml,
  consentId,
  hasMultipleSteps,
  onAgree,
  pdf
}: YourConsentProps) {
  if (!consentRawHtml && !pdf)
    return <ConsentHTMLError text="Return to dashboard" link="/dashboard" />;

  return (
    <Container>
      <Content>
        {pdf && !consentRawHtml ? (
          <PdfConsentViewer pdf={pdf} />
        ) : (
          <Body id="consentContainer" dangerouslySetInnerHTML={{ __html: consentRawHtml }} />
        )}
        {onAgree && (
          <>
            <Divider />
            <ConsentText>
              {hasMultipleSteps
                ? 'By clicking the button to move to the next section, I acknowledge that I have read and understand the information above.'
                : 'By clicking “I consent”, I agree and acknowledge that I have read and understand the information provided above.'}
            </ConsentText>
            <ConsentAcceptAction
              consentId={consentId}
              onAgree={onAgree}
              buttonText={hasMultipleSteps ? 'Acknowledge and Continue' : 'I consent'}
            />
          </>
        )}
      </Content>
    </Container>
  );
}
