import React from 'react';

const Expand = (props: { color?: string }) => {
  return (
    <svg
      width="24px"
      height="24px"
      viewBox="0 0 24 24"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>Expand item</title>
      <g id="expand" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <polyline
          id="Path-7-Copy-2"
          stroke={props.color || '#343754'}
          strokeWidth="1.10831153"
          points="1 7 12.0831153 18 23 7"
        />
      </g>
    </svg>
  );
};

export default Expand;
