import { getGuessedTimeZone } from 'kb-shared/utilities/momentHelpers';

import { Option } from '../SelectInput';

export const getSelectedTimeZoneOption = (
  allOptions: Option[] | undefined,
  savedPreferredTimeZone: string | undefined,
  ianaTimeZoneMappings: Option[] | undefined
) => {
  return (
    allOptions?.find(item => item.label === savedPreferredTimeZone) ||
    getSelectedOptionForGuessedTimeZone(allOptions, ianaTimeZoneMappings)
  );
};

export const getSelectedOptionForGuessedTimeZone = (
  allOptions: Option[] | undefined,
  ianaTimeZoneMappings: Option[] | undefined
) => {
  return allOptions?.find(
    option =>
      option.value ===
      ianaTimeZoneMappings?.find(timeZone => timeZone.label === getGuessedTimeZone())?.value
  );
};
