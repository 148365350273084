import { RenderableItem } from '../types';

export const partnerInstructions: RenderableItem[] = [
  {
    name: 'Your Partner',
    body: [
      {
        type: 'copy',
        copy:
          'Your partner must ejaculate the same night as your trigger shot, and then abstain until the day of the egg retrieval.'
      },
      {
        type: 'copy',
        copy:
          'He will need to produce a fresh semen sample IN THE OFFICE on the day of egg retrieval. He should avoid coming to the office with any strong perfumes or scents on his skin. He will not be able to use any type of lubrication to produce the sample.'
      }
    ]
  }
];
