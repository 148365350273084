import React, { Component } from 'react';

import { themes, utils } from 'kb-shared';
import styled from 'styled-components';

import LabResultCard from './LabResultCard';
import NextStepSection from './NextStepSection';
import { getAMHDisclaimer } from './ResultDescriptions';
import { Container, Sections, SectionContainer } from './styled';

const { getAMHDescription, getAFCDescription } = utils;

type Props = {
  onScheduleAssessment: () => void;
  amhResult: { value: string; units: string; date: string } | null;
  afcResult: { value: string; units: string; date: string } | null;
};

export default class FertilityResult extends Component<Props> {
  renderNextSteps() {
    if (this.props.amhResult && !this.props.afcResult) {
      return (
        <SectionContainer>
          <NextStepSection onScheduleAssessment={this.props.onScheduleAssessment} />
        </SectionContainer>
      );
    }
  }

  renderAFC() {
    const { afcResult } = this.props;
    if (afcResult) {
      return (
        <SectionContainer>
          <LabResultCard
            title={'Antral follicle count'}
            result={afcResult}
            detailsText={getAFCDescription(afcResult.value)}
          />
        </SectionContainer>
      );
    }
  }

  renderAMH() {
    const { amhResult } = this.props;
    if (amhResult) {
      const value = amhResult.value.replace(/[><]/g, ''); // remove > or < from value string
      const amhValue = parseFloat(value);

      return (
        <React.Fragment>
          <SectionContainer>
            <LabResultCard
              title={'Your AMH level'}
              result={amhResult}
              detailsText={getAMHDescription(amhValue) || ''}
              disclaimer={getAMHDisclaimer(amhValue) || ''}
            />
          </SectionContainer>
          <SectionHorizontalSpacer />
        </React.Fragment>
      );
    }
  }

  render() {
    return (
      <Container>
        <Sections>
          {this.renderAMH()}
          {this.renderAFC()}
          {this.renderNextSteps()}
        </Sections>
      </Container>
    );
  }
}

const SectionHorizontalSpacer = styled.div`
  width: 0px;
  @media screen and (min-width: ${themes.breakpoints.tabletLandscape}) {
    width: 24px;
  }
`;
