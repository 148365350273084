import React, { ComponentType } from 'react';
import { Route, RouteProps } from 'react-router-dom';

import { ErrorCatcher } from 'layouts/errorCatcher/ErrorCatcher';

type Props = {
  exact?: boolean;
  path: string;
  page?: { name: string };
  component: ComponentType<any>;
  layout: ComponentType<any>;
};

export class AppRoute extends React.Component<Props> {
  componentDidMount() {
    const { page } = this.props;
    if (!page) return;

    if (window.segment) window.segment.page(page.name);

    const segmentExists = setInterval(() => {
      if (window.segment) {
        window.segment.page(page.name);
        clearInterval(segmentExists);
      }
    });
  }

  render() {
    const { component: Component, layout: Layout, ...rest } = this.props;

    return (
      <Route
        {...rest}
        render={(props: RouteProps) => (
          <Layout>
            <ErrorCatcher id="route">
              <Component {...props} {...rest} />
            </ErrorCatcher>
          </Layout>
        )}
      />
    );
  }
}
