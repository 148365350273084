import React from 'react';

import { Button } from 'components/v2/Buttons/Button';
import { Heading } from 'components/v2/Typography';
import { Text } from 'components/v2/Typography/Text';
import { InvoicesItemContainer, InvoicesTitleContainer } from 'screens/Invoices/Invoices.styled';

import { ChicagoPatientNotice } from '../ChicagoPatientNotice/ChicagoPatientNotice';
import { CyclePaymentButtonContainer } from './CyclePayments.styled';

export function CyclePayments({ onMakeCyclePayment }: { onMakeCyclePayment: () => void }) {
  return (
    <InvoicesItemContainer>
      <InvoicesTitleContainer>
        <Heading tag="h2">Payments</Heading>
      </InvoicesTitleContainer>
      <ChicagoPatientNotice />
      <Text tag="p">
        Patients pay for cycles in advance of treatment. Your Financial Navigator will send you a
        detailed cycle contract that outlines the specific costs associated with your cycle
        treatment. Select the &quot;Make Payment&quot; button and select the Reason of{' '}
        <strong>Cycle Payment</strong> to submit payment in advance of cycle treatment start. Your
        payment will appear in the portal in Unapplied Payments until your cycle treatment is
        complete. Once complete, the cycle statement will be available in Statements and reflect all
        applied payments.
      </Text>
      <br />
      <Text tag="p">
        Please select the reason of <strong>Kindbody Rx (non-employer)</strong> to make a Kindbody
        prescription payment. Please do not select this reason if you are an employer benefit
        patient.
      </Text>
      <br />
      <Text tag="p">
        If you are an employer benefit patient, please select the reason of{' '}
        <strong>Kindbody Benefit Payment</strong> to submit payments related to your appointments.
        If you are NOT an employer benefit patient, do not select this reason at any time.
      </Text>
      <CyclePaymentButtonContainer>
        <Button
          uppercase
          fullWidth
          label="Make payment"
          category="primary"
          size="md"
          onClick={onMakeCyclePayment}
        />
      </CyclePaymentButtonContainer>
    </InvoicesItemContainer>
  );
}
