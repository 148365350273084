import { gql, useQuery } from '@apollo/client';

import { AppointmentType } from 'kb-shared';
import { BugTracker } from 'kb-shared/utilities/bugTracker';

import { UrlParamValue } from '../types/types';
import { isFalsyNonNumber } from '../utils/validation';

const QUERY_APPOINTMENT_TYPE = gql`
  query appointmentType($appointmentId: Int!) {
    appointmentType(appointmentTypeId: $appointmentId) {
      id
      name
      virtual
      virtualFert
      description
      videoLink
      rateCents
      shouldChargeAtBooking
      channel
      minuteDuration
      hourDuration
      validateProviderLicense
      category {
        id
        name
      }
    }
  }
`;

export const useAppointmentType = (appointmentId?: UrlParamValue<number>) => {
  const { data, loading, error } = useQuery<{ appointmentType: AppointmentType }>(
    QUERY_APPOINTMENT_TYPE,
    {
      skip: isFalsyNonNumber(appointmentId),
      variables: {
        appointmentId: appointmentId
      },
      onError: error => {
        BugTracker.notify(error, 'Failed to load appointment type');
      }
    }
  );

  return { data: data?.appointmentType, loading, error };
};
