import { Button } from 'components/v2/Buttons/Button';
import styled, { css } from 'styled-components';

export const Container = styled.div`
  padding: 16px;
  background: #fff;
  box-shadow: 0px -1px 3px -1px rgba(67, 71, 146, 0.16), 0px -1px 1px -1px rgba(10, 13, 61, 0.08);
  bottom: 0;
  width: 100%;
  max-width: 100vw;
`;

export const SubjectInput = styled.input`
  padding: 4px 12px;
  color: #272a5e;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px;
  width: 100%;
  border: 0;

  &::placeholder {
    color: ${({ theme }) => theme.colors.neutral.lighterNavy};
  }

  &:focus {
    border: 0 !important;
  }
`;

export const InputContainer = styled.div`
  position: relative;

  input {
    width: 100%;
    padding: 20px 60px 20px 12px;
  }
`;

export const SendButton = styled(Button)`
  > svg {
    width: 16px;
    height: 16px;
    position: relative;
    left: -1px;
  }
`;

export const AttachmentButton = styled.label`
  width: 48px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  svg {
    color: ${({ theme }) => theme.colors.neutral.primaryNavy};
  }

  input[type='file'] {
    display: none;
  }
`;

export const TextAreaWrap = styled.div<{ focused: boolean }>`
  padding: 22px 0 20px 12px;
  border-bottom-style: solid;
  border-bottom-color: ${({ theme }) => theme.colors.neutral.lighterNavy};
  border-bottom-width: 1px;

  ${({ focused }) =>
    focused &&
    css`
      border-bottom-color: ${({ theme }) => theme.colors.yellow.primary};
    `}
`;

export const TextArea = styled.textarea`
  border: none;
  font-weight: 400;
  font-size: 16px;
  width: 100%;
  resize: none;
  outline: 0;
  line-height: 20px;
  padding-right: 90px;

  ::placeholder {
    color: ${({ theme }) => theme.colors.neutral.lightNavy};
  }
`;

export const Buttons = styled.div`
  position: absolute;
  top: 9px;
  right: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
`;
