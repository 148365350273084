import React, { useState } from 'react';

import styled from 'styled-components';

import { KB360ServiceType, SERVICE_TYPES, SERVICE_TYPE_ICONS } from './ServiceTypeIcon';

interface ServiceTypeSelectProps {
  options?: KB360ServiceType[];
  value?: KB360ServiceType[];
  onChange?: (selected: KB360ServiceType[]) => void;
}

export const ServiceTypeSelect = ({ options, value, onChange }: ServiceTypeSelectProps) => {
  const serviceTypes = options || SERVICE_TYPES;
  const [selectedOptions, setSelectedOptions] = useState(value || []);

  const toggleOption = (option: KB360ServiceType) => {
    const newOptions: KB360ServiceType[] = selectedOptions.includes(option)
      ? selectedOptions.filter(o => o !== option)
      : selectedOptions.concat(option);
    setSelectedOptions(newOptions);
    onChange && onChange(newOptions);
  };

  return (
    <>
      <SelectTitle>Choose all the service types that fit your needs</SelectTitle>
      {serviceTypes.map(serviceType => (
        <SelectCard
          key={serviceType}
          text={serviceType}
          selected={selectedOptions.includes(serviceType)}
          onCardToggled={toggleOption}
        />
      ))}
    </>
  );
};

interface SelectCardProps {
  text: KB360ServiceType;
  selected?: boolean;
  onCardToggled?: (text: KB360ServiceType) => void;
}

const SelectCard = ({ text, selected, onCardToggled }: SelectCardProps) => {
  const Icon = SERVICE_TYPE_ICONS[text];
  return (
    <CardBorder selected={selected} onClick={() => onCardToggled && onCardToggled(text)}>
      <CardIconContainer>
        {Icon && <Icon width={51} height={51} />}
        <CardTextContainer>{text}</CardTextContainer>
      </CardIconContainer>
    </CardBorder>
  );
};

const SelectTitle = styled.div`
  font-weight: bold;
  color: #353148;
  font-size: 16px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: 20px;
`;

const CardBorder = styled.div<{ selected?: boolean }>`
  background-color: ${({ selected }) => (selected ? 'rgba(249,225,104,0.2)' : '#FFFFFF')};
  box-sizing: border-box;
  cursor: pointer;
  height: 80px;
  width: 340px;
  border: ${({ selected }) => (selected ? '2px solid #F9E168' : '1px solid #C6C7CE')};
  border-radius: 6px;
  display: inline-block;
  margin: 10px 10px 10px 0px;
  position: relative;
`;

const CardIconContainer = styled.div`
  padding-left: 11px;
  padding-top: 14px;
`;

const CardTextContainer = styled.div`
  color: #353148;
  font-size: 18px;
  letter-spacing: 0;
  line-height: 24px;
  position: absolute;
  left: 80px;
  top: 29px;
`;
