import Honeybadger from '@honeybadger-io/js';
import React, { useEffect, useState } from 'react';

import { StripeContext } from 'components/CreditCardForm/types/creditCardForm';
import {
  AppointmentProduct,
  AppointmentType,
  Clinic,
  Membership,
  TimeSlot,
  ValidPromo
} from 'kb-shared';
import { PaymentDetails } from 'screens/Invoices/components/InvoicePayModal/PaymentDetails';
import styled from 'styled-components';
import { analytics } from 'utilities/analytics';

import usePromo from '../../../hooks/usePromo';
import { showErrorToast, showSuccessToast } from '../../../utilities/notificationUtils';
import PromoCodeBase from '../components/PromoCode';
import { PaymentFormWrapper } from '../styles';
import { ConfirmDetails } from './ConfirmDetails';

interface Props {
  appointment: AppointmentType | Membership;
  clinic: Clinic | null;
  loading?: boolean;
  noPayment?: () => void;
  onPaymentOptional?: () => void;
  onPaymentTermsChecked: (checked: boolean) => void;
  onSubmit: (stripeTokenId?: string, kbStripeCardStripeIdentifier?: string) => void;
  onValidPromoChange: (validPromo: ValidPromo | null) => void;
  paymentTermsChecked?: boolean;
  product: AppointmentProduct<AppointmentType>;
  timeSlot: TimeSlot | null;
  hasEmployer?: boolean;
  validPromo: ValidPromo | null;
}

const PaymentForm = (props: Props) => {
  const {
    appointment,
    clinic,
    noPayment,
    onPaymentOptional,
    onPaymentTermsChecked,
    onSubmit,
    onValidPromoChange,
    paymentTermsChecked,
    product,
    timeSlot,
    loading = false,
    hasEmployer = false,
    validPromo
  } = props;
  const [promoCode, setPromoCode] = useState<string | null>(null);
  const [kbStripeCardStripeIdentifierSelected, setKbStripeCardStripeIdentifier] = useState('');
  const [promoError, setPromoError] = useState(false);
  const [inputValid, setInputValid] = useState(false);
  const [loadingStripeToken, setLoadingStripeToken] = useState(false);
  const { validatePromo } = usePromo(product);
  const [stripeContext, setStripeContext] = useState<StripeContext | undefined>(undefined);
  const [creditCardFieldEmpty, setCreditCardFieldEmpty] = useState(true);
  const isDisabled = () => {
    if (noPayment || onPaymentOptional) {
      return loading;
    }

    return loading || !inputValid || (!stripeContext && !kbStripeCardStripeIdentifierSelected);
  };

  useEffect(() => {
    analytics.track(analytics.EVENTS.PATIENT_PORTAL_BOOKING_APPOINTMENT_PAYMENT_LOADED);
  }, []);

  return (
    <PaymentFormWrapper>
      <form
        onSubmit={async e => {
          e.preventDefault();
          if (noPayment) return noPayment();

          // if payment is optional and there is no card info, submit without card info
          // otherwise, go through the normal flow(validate card, get token, submit)
          if (onPaymentOptional && !kbStripeCardStripeIdentifierSelected && creditCardFieldEmpty) {
            return onPaymentOptional();
          }

          if (stripeContext) {
            setLoadingStripeToken(true);
            const tokenResponse = await stripeContext.stripe.createToken(
              stripeContext.cardNumberElement
            );
            setLoadingStripeToken(false);
            if (tokenResponse.error) {
              const tokenResponseError = tokenResponse.error.message;
              if (tokenResponseError) {
                showErrorToast(tokenResponseError);
                Honeybadger.notify(tokenResponseError);
              }
              return;
            }
            const stripeTokenId = tokenResponse.token?.id;
            onSubmit(stripeTokenId);
            return;
          }

          if (kbStripeCardStripeIdentifierSelected) {
            onSubmit(undefined, kbStripeCardStripeIdentifierSelected);
          }
        }}
      >
        {(!noPayment || hasEmployer) && (
          <PaymentFormContainer>
            {!hasEmployer && (
              <PaymentDetails
                paymentOptional={Boolean(onPaymentOptional)}
                onSelectCreditCard={data => setKbStripeCardStripeIdentifier(data)}
                onStripeCardElementInitialized={(stripe: any, cardNumberElement: any) => {
                  setStripeContext({
                    stripe,
                    cardNumberElement
                  });
                }}
                onValidation={valid => setInputValid(valid)}
                onCreditCardChange={({ empty }) => setCreditCardFieldEmpty(empty)}
              />
            )}

            <PromoCode
              value={promoCode || ''}
              error={promoError !== null}
              hasEmployer={hasEmployer}
              onChange={event => {
                setPromoCode(event.target.value);
              }}
              onSubmit={() => {
                setPromoError(false);
                validatePromo(promoCode)
                  .then(({ validPromo, error }) => {
                    onValidPromoChange(validPromo || null);
                    if (error) {
                      setPromoError(true);
                      analytics.track(analytics.EVENTS.PROMO_CODE_FAILED, {
                        promo_code: promoCode
                      });
                      return showErrorToast(error);
                    }
                    showSuccessToast('Promo code accepted.');
                    analytics.track(analytics.EVENTS.PROMO_CODE_SUCCEEDED, {
                      promo_code: promoCode
                    });
                  })
                  .catch(error => Honeybadger.notify(error));
              }}
            />
          </PaymentFormContainer>
        )}
        <ConfirmDetails
          clinic={clinic}
          appointment={appointment}
          product={product}
          timeSlot={timeSlot}
          loading={loading || loadingStripeToken}
          disabled={isDisabled()}
          paymentTermsChecked={paymentTermsChecked}
          validPromo={validPromo}
          hasEmployer={hasEmployer}
          onPaymentTermsChecked={onPaymentTermsChecked}
          onValidPromoChange={onValidPromoChange}
        />
      </form>
    </PaymentFormWrapper>
  );
};

export default PaymentForm;

const PaymentFormContainer = styled.div`
  display: flex;
  align-items: flex-end;
  flex-wrap: wrap;
  margin-bottom: 30px;
  width: 100%;

  @media ${({ theme }) => theme.queries.minTablet} {
    width: 500px;
  }
`;

const PromoCode = styled(PromoCodeBase)`
  width: 100%;
  flex: 0 0 auto;
  margin-top: 15px;
  text-align: left;
  justify-content: flex-start;
`;
