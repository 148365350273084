import React from 'react';

import { Dropdown } from 'components/v2/DropdownMenu/Dropdown';
import { MenuItemDetailsArray } from 'components/v2/DropdownMenu/Dropdown.types';
import { Label } from 'components/v2/Label/Label';

import { CreditCardIconContainer } from '../shared.styled';
import { HeaderContainer, PrimaryButtonContainer } from './CreditCard.styled';
import { CreditCardIcon } from './CreditCardIcon';

type HeaderProps = {
  type: string;
  isDefault: boolean;
  isSingleCard?: boolean;
  setAsPrimary: () => void;
  removeCard: () => void;
};

export const CreditCardHeader = ({ type, isDefault, setAsPrimary, removeCard }: HeaderProps) => {
  const menuItems: MenuItemDetailsArray = [
    { label: 'Set as Primary', onClick: setAsPrimary, type: 'default' },
    { label: 'Remove Card', onClick: removeCard, type: 'warning' }
  ];

  return (
    <HeaderContainer>
      <CreditCardIconContainer>
        <CreditCardIcon type={type} />
      </CreditCardIconContainer>
      <PrimaryButtonContainer>
        {isDefault ? (
          <Label size="md" type="neutral">
            Primary
          </Label>
        ) : (
          <Dropdown items={menuItems} />
        )}
      </PrimaryButtonContainer>
    </HeaderContainer>
  );
};
