import React from 'react';

import styled from 'styled-components';

interface Props {
  text: string;
  linkText: string;
  linkUrl: string;
}

const HelpText = ({ text, linkText, linkUrl }: Props) => {
  return (
    <Main>
      {text} <Link href={linkUrl}>{linkText}</Link>
    </Main>
  );
};

export default HelpText;

const Main = styled.p`
  text-align: center;
  margin-top: 5em;
`;

const Link = styled.a`
  font-weight: bold;
  text-decoration: underline;
  white-space: nowrap;
`;
