import styled from 'styled-components';

export const TextContainer = styled.div`
  white-space: pre-line;
  max-height: 60vh;
  overflow: scroll;
`;

export const ButtonContainer = styled.div`
  margin-top: 1.5em;
`;
