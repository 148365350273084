import styled from 'styled-components';

export const CollapsedMenuContainer = styled.div`
  grid-area: navbar;
  position: fixed;
  z-index: 300;
  background-color: ${({ theme }) => theme.colors.neutral.primaryNavy};
  height: 100%;
  width: 80px;
  overflow-y: auto;
  box-shadow: 0 5px 12px 0 rgba(0, 0, 0, 0.65);
  border-radius: 0 32px 32px 0;

  @media print {
    display: none;
  }

  @media ${({ theme }) => theme.queries.minDesktop} {
    border-radius: 0;
    top: 0;
    left: 0;
    right: auto;
    bottom: 0;
    min-width: 80px;
    align-items: center;
    position: fixed;
    overflow-y: auto;
    overflow-x: hidden;
  }

  @media ${({ theme }) => theme.queries.maxTablet} {
    display: none;
  }
`;

export const MenuContent = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  flex-direction: column;
`;

export const MenuUserContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding-bottom: 16px;
`;
