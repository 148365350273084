import { Appointment } from 'kb-shared';
import { inFutureWithDelay, isToday } from 'kb-shared/utilities/momentHelpers';

// logic is changed in a way that appointment are regarded as future appointments for 1 hour after they are started
export const filterAppointmentsByStartTime = (
  appointments: Appointment[] | undefined,
  filterPastAppointment = false
): Appointment[] =>
  appointments?.filter((a: Appointment) => {
    if (!filterPastAppointment) {
      return inFutureWithDelay(a.startTime, 60);
    } else {
      return !inFutureWithDelay(a.startTime, 60);
    }
  }) || [];

export const filterTodaysAppointmentsByStartTime = (
  appointments: Appointment[] | undefined
): Appointment[] =>
  appointments?.filter((a: Appointment) => {
    return isToday(a.startTime);
  }) || [];

export const getFutureAppointments = (appointments: Appointment[] | undefined): Appointment[] => {
  return filterAppointmentsByStartTime(appointments, false).sort(compareByStartTimeAscending);
};

export const getTodaysAndFutureAppointments = (
  appointments: Appointment[] | undefined
): { todaysAppointments: Appointment[]; futureAppointments: Appointment[] } => {
  const todaysAppointments = filterTodaysAppointmentsByStartTime(appointments).sort(
    compareByStartTimeAscending
  );

  const futureAppointments = getFutureAppointments(
    appointments?.filter(a => !todaysAppointments.includes(a))
  );
  return { todaysAppointments, futureAppointments };
};

export const compareByStartTimeAscending = (a: Appointment, b: Appointment): number => {
  return new Date(a.startTime).getTime() - new Date(b.startTime).getTime();
};
