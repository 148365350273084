import React from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { ProfileImage } from 'components/PhotoUpload/ProfilePhoto/components/ProfileImage';
import { ReduxState } from 'kb-redux';
import { BugTracker } from 'kb-shared/utilities/bugTracker';
import { pageUrl } from 'utilities/pageUrl';

export const UserProfileButton = ({ size }: { size: number }): JSX.Element => {
  const history = useHistory();
  const profileImage = useSelector<ReduxState, string | undefined>(
    state => state.patient?.profileImage
  );

  function redirectToMyProfile() {
    history.push(pageUrl.myProfile('demographic'));
  }

  function handleImageError() {
    BugTracker.notify('Error loading profile image', 'UserProfileButton');
  }

  return (
    <button onClick={redirectToMyProfile} aria-label="Go to my profile">
      <ProfileImage
        profileImage={profileImage}
        error={null}
        size={size}
        onError={handleImageError}
      />
    </button>
  );
};
