import { useQuery } from '@apollo/client';

import { GetDropdownOptionsResponse } from 'kb-shared';
import { GET_DROPDOWN_OPTIONS } from 'kb-shared/graphql/queries';
import { BugTracker } from 'kb-shared/utilities/bugTracker';
import { sortNullableStrings } from 'utilities/sort';

import { DropDownOptionsProps } from './DropDownOptions.types';

export const useDropDownOptions = ({ key, sort, skip }: DropDownOptionsProps) => {
  const { data, loading, error, refetch } = useQuery<GetDropdownOptionsResponse>(
    GET_DROPDOWN_OPTIONS,
    {
      variables: { key },
      skip: skip,
      onError: error => {
        BugTracker.notify(error, `useDropDownOptions for key ${key}`);
      }
    }
  );

  let options = data?.getDropdownOptions.map(option => {
    return { label: option.label, value: option.value };
  });
  if (options && sort) {
    options = options.sort((prev, next) => sortNullableStrings(prev.label, next.label));
  }

  return { options, loading, error, refetch };
};
