import styled from 'styled-components';

export const ActionContainer = styled.div<{ $last: boolean }>`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 12px 0;
  border-bottom: 1px solid
    ${({ $last, theme }) => ($last ? theme.colors.white : theme.colors.neutral.lavender)};
`;

export const ActionTextContainer = styled.div`
  margin-right: 0.5em;
`;
