import styled from 'styled-components';

export const FallbackMessage = styled.div`
  text-align: center;
  color: ${props => props.theme.colors.neutral.primaryNavy};
  padding-top: 50px;
  font-size: 24px;

  h2 {
    font-size: 28px;
  }

  a {
    text-decoration: underline;
  }
`;
