import { gql } from '@apollo/client';

export const ResultsQuery = gql`
  query resultsWidget {
    results {
      afc: follicleCounts {
        type
        name
        date
        units
        value
      }
      labs {
        type
        name
        date
        units
        value
      }
    }
  }
`;
