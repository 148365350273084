import { useMutation, useQuery } from '@apollo/client';
import React, { useState, useEffect } from 'react';

import { Loader } from 'components/Loader/Loader';
import { BugTracker } from 'kb-shared/utilities/bugTracker';
import { showErrorToast } from 'utilities/notificationUtils';

import { AdditionalOptionQuestion } from '../Components/AdditionalOptionQuestion';
import { ConsentOption, PartnerConsentDecision } from '../InformedConsent.types';
import { CREATE_CONSENT_DECISION, PARTNER_CONSENT_DECISIONS } from './AdditionalOptions.graphql';
import {
  Container,
  TitleText,
  SubtitleText,
  QuestionsContainer,
  OptionContainer
} from './AdditionalOptions.styled';
import { AdditionalOptionsProps } from './AdditionalOptions.types';
import { CreateConsentDecisionResponseData } from './AdditionalOptions.types';

export const AdditionalOptions: React.FC<AdditionalOptionsProps> = ({
  consentDecisions,
  patientConsentId,
  onCompleted
}: AdditionalOptionsProps) => {
  const [currentDecision, setCurrentDecision] = useState<number>(0);

  const { data: partnerConsentDecisionsData } = useQuery(PARTNER_CONSENT_DECISIONS, {
    variables: { patientConsentId: Number(patientConsentId) },
    onError: error => BugTracker.notify(error, 'Failed to fetch partner consent decisions')
  });

  const partnerConsentDecisions = partnerConsentDecisionsData?.partnerConsentDecisions;

  const [submitDecision, { loading }] = useMutation<CreateConsentDecisionResponseData>(
    CREATE_CONSENT_DECISION,
    {
      onError: error => {
        BugTracker.notify(error, 'Failed to save consent decision');
        showErrorToast('An error occurred: ' + error?.message || 'An unknown error');
      },
      onCompleted: () => {
        if (currentDecision === consentDecisions.length - 1) {
          onCompleted();
        } else {
          setCurrentDecision(prev => prev + 1);
        }
      }
    }
  );

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const onSelectOption = async (option: ConsentOption) => {
    await submitDecision({
      variables: {
        patientConsentId: parseInt(patientConsentId),
        consentOptionId: parseInt(option.id),
        consentDecisionId: option.consentDecisionId
      }
    });
  };

  if (loading) return <Loader />;

  const decision = consentDecisions[currentDecision];

  const partnerDecision = partnerConsentDecisions?.find(
    (item: PartnerConsentDecision) => item.consentDecisionId === Number(decision.id)
  ) as PartnerConsentDecision;

  if (!decision) return null;

  return (
    <Container>
      <TitleText>{decision.name}</TitleText>
      {decision && <SubtitleText>{decision.description}</SubtitleText>}
      <QuestionsContainer>
        {decision.consentOptions?.map(option => (
          <OptionContainer key={option.id}>
            <AdditionalOptionQuestion
              option={option}
              onSelect={onSelectOption}
              partnerDecisionOptionId={partnerDecision?.consentOptionId}
              shouldMatch={!partnerDecision?.partnersToMismatch}
            />
          </OptionContainer>
        ))}
      </QuestionsContainer>
    </Container>
  );
};
