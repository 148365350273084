import styled from 'styled-components';

import Button from '../../../../components/Button/NewButton';

export const FinishedSubtitle = styled.span`
  font-size: 20px;
  color: ${({ theme }) => theme.colors.neutral.primaryNavy};
  text-align: center;
  padding-top: 8px;
  padding-bottom: 28px;
`;

export const LinkStyled = styled.a`
  font-size: 20px;
  color: ${({ theme }) => theme.colors.neutral.primaryNavy};
  text-align: center;
  font-weight: bold;
  align-self: center;
`;

export const TitleText = styled.span`
  font-size: 28px;
  color: ${({ theme }) => theme.colors.neutral.primaryNavy};
  align-self: center;
`;

export const ContainerStyled = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 80%;
  margin: 100px auto;
`;

export { Button };
