import React from 'react';

import styled from 'styled-components';

interface Props {
  className?: string;
  currentPage: number;
  onPreviousClick?: () => void;
  onNextClick?: () => void;
}

const Pagination = ({ currentPage, onPreviousClick, onNextClick, className }: Props) => (
  <Container className={className}>
    <ArrowButton
      left
      disabled={!onPreviousClick}
      onClick={onPreviousClick}
      aria-label="Previous page"
    />
    <PageNumber>{currentPage}</PageNumber>
    <ArrowButton disabled={!onNextClick} onClick={onNextClick} aria-label="Next page" />
  </Container>
);

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 12px auto;

  font-size: 14px;
  @media screen and (min-width: ${({ theme }) => theme.breakpoints.tabletPortrait}) {
    margin-top: 18px;

    font-size: 18px;
  }
`;

interface ArrowButtonProps {
  left?: boolean;
}

const ArrowButton = styled.button<ArrowButtonProps>`
  padding: 6px 6px 6px 9px;

  transform: ${({ left }) => (left ? 'none' : 'scale(-1, 1)')};
  border-radius: 50% 0 0 50%;
  background-color: ${({ theme }) => theme.colors.yellow.primary};

  text-align: center;
  color: ${({ theme }) => theme.colors.neutral.primaryNavy};

  &::before {
    content: '<';
  }

  &:disabled {
    background-color: ${({ theme }) => theme.colors.yellow.lightestYellow};
  }
`;

const PageNumber = styled.span`
  margin: 0 16px;
  background: white;
`;

export default Pagination;
