import React, { ReactNode } from 'react';

import styled from 'styled-components';

import Footer from '../../components/Footer';
import { NavigationContainer } from './Navigation/NavigationContainer/NavigationContainer';

const GridContainer = styled.div`
  display: grid;
  grid-template-columns: auto;
  grid-template-rows: auto 1fr auto;
  grid-template-areas:
    'main'
    'footer';
  height: 100vh;
  background: ${({ theme }) => theme.colors.neutral.lavenderWhite};

  @media ${({ theme }) => theme.queries.minDesktop} {
    grid-template-columns: 80px auto;

    grid-template-areas:
      'navbar main'
      'navbar footer';
  }
`;

const Main = styled.main`
  grid-area: main;
  background: ${({ theme }) => theme.colors.neutral.lavenderWhite};
`;

interface Props {
  children: ReactNode;
}

export const MainColoredLayout = (props: Props) => {
  const { children } = props;

  return (
    <GridContainer>
      <NavigationContainer />
      <Main>{children}</Main>
      <Footer $hasColoredBackground={true} />
    </GridContainer>
  );
};
