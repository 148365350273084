import { themes } from 'kb-shared';
import styled from 'styled-components';
const { breakpoints } = themes;

export const LargeOption = styled.button`
  padding: 2em;
  border: 2px solid ${({ theme }) => theme.deprecatedColors.gray};
  border-radius: 4px;
  margin: 1em;
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: 0;
  text-align: center;
  cursor: pointer;
  min-height: min-content;

  &:hover,
  &:focus {
    border: 2px solid ${({ theme }) => theme.colors.yellow.primary};
    outline: none;
  }
`;

export const FlexWrapper = styled.div`
  display: flex;
  flex-direction: column;

  @media screen and (min-width: ${breakpoints.tabletLandscape}) {
    flex-direction: row;
  }
`;

export const MediumOption = styled.button`
  padding: 1em 2em;
  border: 2px solid ${({ theme }) => theme.deprecatedColors.gray};
  border-radius: 4px;
  margin: 0.5em;
  font-weight: bold;
  max-width: 525px;
  width: 100%;
  text-align: center;
  cursor: pointer;
  background: white;

  &:hover,
  &:focus {
    border: 2px solid ${({ theme }) => theme.colors.yellow.primary};
    outline: none;
  }
`;

export const VerticalWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
