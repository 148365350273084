interface ProtocolDrug {
  name: string;
  url: string;
}

interface ProtocolDrugsList {
  [key: string]: ProtocolDrug;
}

export const MEDICATIONS: ProtocolDrugsList = Object.freeze({
  Cetrotide: {
    name: 'Cetrotide',
    url: 'http://freedommedteach.com/eng/videos.html?play=cetrotide'
  },
  Follistim: {
    name: 'Follistim',
    url: 'https://portal.kindbody.com/medication-instruction?med_index=0&medication_name=Follistim'
  },
  Ganirelix: {
    name: 'Ganirelix',
    url: 'https://portal.kindbody.com/medication-instruction?med_index=1&medication_name=Ganirelix'
  },
  Delestrogen: {
    name: 'Delestrogen',
    url: 'https://freedommedteach.com/eng/videos.html?play=delestrogen'
  },
  'Gonal F': {
    name: 'Gonal-f',
    url: 'https://portal.kindbody.com/medication-instruction?med_index=2&medication_name=Gonal-f'
  },
  'hCG Trigger IM': {
    name: 'hCG Trigger IM',
    url: 'http://freedommedteach.com/eng/videos.html?play=novarel-hcg_draw_up%2Cgeneral_im'
  },
  'hCG Trigger SQ': {
    name: 'hCG Trigger SQ',
    url: 'http://freedommedteach.com/eng/videos.html?play=general_sq'
  },
  'Lupron Down Regulation': {
    name: 'Lupron Down Regulation',
    url: 'http://freedommedteach.com/eng/videos.html?play=leuprolide_acetate'
  },
  'Lupron Trigger': {
    name: 'Lupron Trigger',
    url: 'http://freedommedteach.com/eng/videos.html?play=leuprolide_trigger'
  },
  Menopur: {
    name: 'Menopur',
    url: 'https://portal.kindbody.com/medication-instruction?med_index=3&medication_name=Menopur'
  },
  'Microdose Lupron': {
    name: 'Microdose Lupron',
    url: 'http://freedommedteach.com/eng/videos.html?play=leuprolide_acetate'
  },
  'Microflare Lupron': {
    name: 'Microflare Lupron',
    url: 'http://freedommedteach.com/eng/videos.html?play=leuprolide_acetate'
  },
  'Ovidrel Trigger': {
    name: 'Ovidrel',
    url: 'http://www.freedommedteach.com/eng/videos.html?play=ovidrel'
  },
  'Progesterone in Oil': {
    name: 'Progesterone in Oil',
    url: 'http://freedommedteach.com/eng/videos.html?play=progesterone_in_oil'
  },
  Omnitrope: {
    name: 'Omnitrope',
    url: 'https://www.youtube.com/watch?v=NS0ca3T7wRM'
  }
});
