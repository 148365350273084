import { gql } from '@apollo/client';

export const VALIDATE_PATIENT_ENTERPRISE_MEMBERSHIP = gql`
  mutation validatePatientEnterpriseMembership(
    $employerAccessCode: String!
    $employeeAccessCode: String!
  ) {
    validatePatientEnterpriseMembership(
      employerAccessCode: $employerAccessCode
      employeeAccessCode: $employeeAccessCode
    ) {
      patientMembership {
        id
      }
    }
  }
`;
