import React from 'react';

import StepDetail from '../details/StepDetail';
import { Description } from '../details/StepDetail';

export const Biopsy = () => {
  return (
    <StepDetail>
      <Description>
        A biopsy of your uterine lining will be performed today. This means a small piece of tissue
        will be removed from the endometrium, which is the lining of the uterus and sent to the lab
        for testing. The analysis on the tissue will show if your lining is at a stage where
        implantation of an embryo is favorable. Please note: This analysis is usually not covered by
        insurance and costs $890 out of pocket. Also, the results will take up to 4 weeks to return.
        We will not be able to start preparing for your frozen embryo transfer until we have these
        results.
      </Description>
    </StepDetail>
  );
};
