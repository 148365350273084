import React from 'react';

import { Patient } from 'kb-shared';
import ContactInfoFields from 'screens/MyProfile/components/ContactInfoFields';

export interface ContactInfoProps {
  patient: Patient;
}

export function ContactInfo(props: ContactInfoProps) {
  const { patient } = props;
  const body = document.body;

  if (!body) return null;

  return <ContactInfoFields {...patient?.address} {...patient} />;
}
