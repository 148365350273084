export type VIOS_LAB = {
  id: number;
  portalURL: string;
  bookingURL: string;
};

export const VIOS_CHICAGO_LAB_ID = 34;
export const VIOS_MILWAUKEE_LAB_ID = 35;
export const VIOS_CREST_HILL_LAB_ID = 36;
export const VIOS_ST_LOUIS_LAB_ID = 37;
export const VIOS_PORTLAND_LAB_ID = 38;
export const VIOS_DETROIT_LAB_ID = 39;

export const VIOS_LABS = [
  {
    id: VIOS_CHICAGO_LAB_ID,
    portalURL: 'https://portalct.viosfertility.com/account/login',
    bookingURL:
      'https://kindbody.com/appointment-request-form-chicago-scheduling-location-confirmed/'
  },
  {
    id: VIOS_MILWAUKEE_LAB_ID,
    portalURL: 'https://portalct.viosfertility.com/account/login',
    bookingURL: 'https://kindbody.com/appointment-request-form-milwaukee/'
  },
  {
    id: VIOS_CREST_HILL_LAB_ID,
    portalURL: 'https://portalct.viosfertility.com/account/login',
    bookingURL:
      'https://kindbody.com/appointment-request-form-chicago-scheduling-location-confirmed/'
  },
  {
    id: VIOS_ST_LOUIS_LAB_ID,
    portalURL: 'https://portalct.viosfertility.com/account/login',
    bookingURL: 'https://kindbody.com/appointment-request-form-stlouis/'
  },
  {
    id: VIOS_PORTLAND_LAB_ID,
    portalURL: 'https://portalpt.viosfertility.com/account/login',
    bookingURL: 'https://kindbody.com/appointment-request-form-portland/'
  },
  {
    id: VIOS_DETROIT_LAB_ID,
    portalURL: 'https://portalet.viosfertility.com/account/login',
    bookingURL: 'https://kindbody.com/appointment-request-form-detroit/'
  }
];

const selectViosLab = (labId: number) => VIOS_LABS.find(lab => lab.id === labId);

const openUrl = (labId: number, property: 'portalURL' | 'bookingURL') => {
  const viosLab = selectViosLab(labId);

  if (viosLab !== undefined && viosLab[property]) {
    window.open(viosLab[property]);
  } else {
    console.error(`No url for ${labId}.`);
  }
};

export const openViosPatientPortal = (labId: number) => openUrl(labId, 'portalURL');
export const openViosBookingURL = (labId: number) => openUrl(labId, 'bookingURL');
export const getViosBookingUrl = (labId: number): string => {
  const viosLab = selectViosLab(labId);

  if (!viosLab) {
    throw new Error(`No booking URL for vios lab: ${labId}`);
  }

  return viosLab.bookingURL;
};
