import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
`;

export const ButtonContainer = styled.div`
  display: flex;
  flex-flow: row wrap;
`;

export const ButtonWrapper = styled.div`
  margin: 24px 12px 24px 12px;
  @media (max-width: 700px) {
    margin: 10px 18px 10px 12px;
  }
`;
