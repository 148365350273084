import { MenuItemDetails } from 'components/v2/DropdownMenu/Dropdown.types';
import { getFirstName } from 'screens/Messages/Messages.utils';

import { Conversation } from '../../Messages.type';

export const getAdditionalParticipants = (conversation?: Conversation) => {
  if (!conversation) return [];
  const owner = conversation.assignedToUserName;
  const clinicalMessages = conversation.messages.filter(message => !message.fromPatient);
  return [
    ...new Set(clinicalMessages.map(message => message.senderName).filter(name => name !== owner))
  ];
};

export const getParticipantsMenuStructure = (conversation?: Conversation) => {
  const participantsMenuStructure: MenuItemDetails[] = [];
  const additionalParticipants = getAdditionalParticipants(conversation);
  if (additionalParticipants.length > 0) {
    participantsMenuStructure.push({
      label: 'Currently assigned to',
      type: 'section'
    } as MenuItemDetails);

    participantsMenuStructure.push({
      label: getFirstName(conversation?.assignedToUserName),
      type: 'default'
    } as MenuItemDetails);

    participantsMenuStructure.push({
      label: 'Previously assigned',
      type: 'section'
    } as MenuItemDetails);

    additionalParticipants.forEach(participant => {
      participantsMenuStructure.push({
        label: getFirstName(participant),
        type: 'default',
        isDisabled: true
      } as MenuItemDetails);
    });
  }
  return { participantsMenuStructure, additionalParticipants };
};

export const getParticipantsTooltipHTMLStructure = (conversation?: Conversation) => {
  let participantsTooltipHTMLStructure = '';
  const additionalParticipants = getAdditionalParticipants(conversation);
  if (additionalParticipants.length > 0) {
    participantsTooltipHTMLStructure += `<div class="message-additional-participants-tooltip">`;
    participantsTooltipHTMLStructure += `<h4>Currently assigned to:</h4>`;
    participantsTooltipHTMLStructure += `<span>${getFirstName(
      conversation?.assignedToUserName
    )}</span>`;
    participantsTooltipHTMLStructure += `<h4>Previously assigned:</h4>`;

    additionalParticipants.forEach(participant => {
      participantsTooltipHTMLStructure += `<span>${getFirstName(participant)}</span><br />`;
    });

    participantsTooltipHTMLStructure += `</div>`;
  }

  return { participantsTooltipHTMLStructure, additionalParticipants };
};
