import { Heading } from 'components/v2/Typography';
import styled from 'styled-components';

export const Container = styled.div`
  margin-bottom: 2em;
`;

export const ValueContainer = styled.div`
  position: relative;
`;

export const Value = styled(Heading)`
  position: relative;
  z-index: 1;
`;

export const Stripe = styled.div`
  position: absolute;
  width: 100%;
  height: 28px;
  transform: rotate(-5deg);
  background-color: ${({ theme }) => theme.colors.yellow.primary};
  top: 15px;
  z-index: 0;
`;

export const Row = styled.div`
  display: flex;
`;

export const ButtonContainer = styled.div`
  display: flex;
  flex: 1;
  justify-content: flex-end;

  svg {
    width: 16px;
    height: 16px;
  }
`;
