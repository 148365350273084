import { Text } from 'components/v2/Typography';
import styled from 'styled-components';

export const Table = styled.table`
  width: 100%;
  border-spacing: 0px;
  line-height: 24px;

  td,
  th {
    padding: 16px;

    text-align: start;
    vertical-align: middle;
  }

  thead {
    padding: 24px 16px;

    background-color: ${({ theme }) => theme.colors.yellow.primary};

    th {
      border-left: 1px solid ${({ theme }) => theme.colors.yellow.darkYellow};
      border-right: 1px solid ${({ theme }) => theme.colors.yellow.darkYellow};
      border-bottom: 1px solid ${({ theme }) => theme.colors.yellow.darkYellow};
      padding: 24px 16px;
    }
  }

  tbody td {
    border: 1px solid ${({ theme }) => theme.colors.neutral.lavender};
    background-color: white;
  }

  tfoot {
    padding: 24px 16px;
    background-color: ${({ theme }) => theme.colors.yellow.lighterYellow};

    tr:first-child th,
    tr:first-child td {
      padding-top: 24px;
    }

    tr:nth-last-child(2) td,
    tr:nth-last-child(2) th {
      padding-bottom: 24px;
    }

    tr:last-child th,
    tr:last-child td {
      padding-bottom: 24px;
      padding-top: 24px;
      border: 1px solid ${({ theme }) => theme.colors.neutral.lavender};
    }
    th,
    td {
      padding: 0 16px;
      border-right: 1px solid ${({ theme }) => theme.colors.neutral.lavender};
      border-left: 1px solid ${({ theme }) => theme.colors.neutral.lavender};
    }
    th,
    th > div {
      text-align: end;
    }

    th > div {
      display: inline;
    }
  }
`;

export const BlockText = styled(Text)`
  display: block;
`;
