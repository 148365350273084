import { useQuery } from '@apollo/client';
import moment from 'moment';
import React from 'react';

import { BugTracker } from 'kb-shared/utilities/bugTracker';

import AttachmentsList from './components/AttachmentsList';
import DocumentsList from './components/DocumentsList';
import { PatientSignedDocuments } from './components/PatientSignedDocuments/PatientSignedDocuments';
import { DocumentFile } from './components/PatientSignedDocuments/PatientSignedDocuments.types';
import { YourUploads } from './components/YourUploads/YourUploads';
import { AttachmentInformation, DocumentType, PatientDocument } from './Documents.types';
import { allKindbodyUploadsAsConsents, allKindbodyUploadsAsNotConsents } from './Documents.utils';
import { AllAttachmentResponse, AllAttachmentsQuery } from './graphql/attachementsQuery';
import {
  ConsentDocument,
  kindbodyDocumentsQuery,
  KindbodyDocumentsResponse,
  PatientConsent
} from './graphql/kindbodyDocumentsQuery';

const DEFAULT_KINDBODY_UPLOAD = {
  id: -1,
  presignedUrl:
    'https://kindbody-portal-static-files.s3.us-east-2.amazonaws.com/Authorization_to_Release_Medical_Records_from_Kindbody.pdf',
  type: 'PDF',
  fileName: 'Authorization to Release Medical Records from Kindbody'
};

export const useDocuments = (
  onYourConsentsView: (patientConsent?: PatientConsent) => void,
  onTreatmentPlansView: (patientDocument?: PatientDocument) => void
) => {
  const {
    data: allAttachmentsData,
    refetch: refetchAttachments,
    loading: allAttachmentLoading
  } = useQuery<AllAttachmentResponse>(AllAttachmentsQuery, {
    onError: error => BugTracker.notify(error, 'Failed to fetch files uploaded by patient')
  });
  const { data: kindbodyDocumentsData, loading: kindbodyDocumentsLoading } = useQuery<
    KindbodyDocumentsResponse
  >(kindbodyDocumentsQuery, {
    onError: error => BugTracker.notify(error, 'Failed to fetch files uploaded by Kindbody')
  });

  if (
    !allAttachmentsData ||
    !kindbodyDocumentsData ||
    allAttachmentLoading ||
    kindbodyDocumentsLoading
  ) {
    return {
      tabs: [],
      loading: true
    };
  }

  return {
    tabs: generateTabs(
      allAttachmentsData,
      kindbodyDocumentsData,
      refetchAttachments,
      onYourConsentsView,
      onTreatmentPlansView
    ),
    loading: false
  };
};

const getConsentFiles = (
  consents: PatientConsent[],
  kindbodyUploadsConsents: AttachmentInformation[],
  consentDocuments: ConsentDocument[]
): DocumentFile[] => {
  // Note: It is possible that BE will return the same consent multiple times and that the same consent could
  // once be found in consentDocuments and once in consents.
  // Because of that, we first need to process consentDocuments (because it can contain link to consent's PDF) and then
  // consents (legacy way of displaying signed consent to the patient).
  const allConsents: DocumentFile[] = consentDocuments.map(document => {
    return {
      fileName: document.name,
      date: document.date,
      id: document.id,
      presignedUrl: document.link
    };
  });

  const nonDuplicateConsents = consents.filter(
    consent => !allConsents.find(addedConsent => addedConsent.id === consent.id)
  );
  allConsents.push(
    ...nonDuplicateConsents.map(consent => {
      return {
        fileName: consent.consent.name,
        date: consent.completedAt || '',
        id: consent.id
      };
    })
  );

  allConsents.push(
    ...kindbodyUploadsConsents.map(consent => {
      return {
        fileName: consent.fileName,
        date: consent.createdAt || '',
        id: consent.id + '',
        presignedUrl: consent.presignedUrl
      };
    })
  );

  return allConsents;
};

const getTreatmentPlans = (treatmentPlans: PatientDocument[]): DocumentFile[] => {
  return treatmentPlans.map(treatmentPlan => {
    return {
      fileName: treatmentPlan.name,
      date: treatmentPlan.date,
      id: treatmentPlan.jsonData[0].id + ''
    };
  });
};

const generateTabs = (
  allAttachmentsData: AllAttachmentResponse,
  kindbodyDocumentsData: KindbodyDocumentsResponse,
  refetchAttachments: () => void,
  onYourConsentsView: (patientConsent?: PatientConsent) => void,
  onTreatmentPlansView: (patientDocument?: PatientDocument) => void
) => {
  // @ts-ignore
  const kindbodyDocs = kindbodyDocumentsData.labOrderReports.reduce((acc, labOrderReport) => {
    // @ts-ignore
    const pdfResults = labOrderReport.pdfResults.map(v => {
      const date = moment.utc(labOrderReport.createdAt).format('MM/DD/YYYY');
      return { path: v, type: 'PDF', date, fileName: `Lab results ${date}` };
    });

    // @ts-ignore
    const htmlResults = labOrderReport.htmlResults.map(v => {
      const date = moment.utc(labOrderReport.createdAt).format('MM/DD/YYYY');
      return { path: v, type: 'HTML', date, fileName: `Lab results ${date}` };
    });
    return [...acc, ...pdfResults, ...htmlResults];
  }, []);

  const consentsAsDocuments = kindbodyDocumentsData.documents.filter(
    document => document.date && document.documentLink
  );

  const consentDocuments = consentsAsDocuments.map(document => {
    return {
      name: document.name,
      date: document.date,
      link: document.documentLink,
      id: document.databaseId
    };
  });

  const patientTreatmentPlans = kindbodyDocumentsData.documents.filter(
    document => document.date && document.category === DocumentType.TREATMENT_PLANS
  );

  const patientConsents = kindbodyDocumentsData.patientConsents.filter(
    consent => consent.completedAt
  );

  return [
    {
      id: 'kindbody-uploads',
      label: 'Kindbody Uploads',
      isVisible: allKindbodyUploadsAsNotConsents(allAttachmentsData?.allKindbodyAttachments),
      content: (
        <AttachmentsList
          title="Uploads"
          items={[
            DEFAULT_KINDBODY_UPLOAD,
            ...allKindbodyUploadsAsNotConsents(allAttachmentsData?.allKindbodyAttachments)
          ]}
        />
      )
    },
    {
      id: 'your-uploads',
      label: 'Your Uploads',
      isVisible: true,
      content: (
        <YourUploads
          items={allAttachmentsData?.allPatientAttachments || []}
          purposes={allAttachmentsData?.fileUploadPurposes || []}
          onUpload={success => {
            if (!success) return;

            refetchAttachments();
          }}
          onDelete={() => {
            refetchAttachments();
          }}
        />
      )
    },
    {
      id: 'your-consents',
      label: 'Your Consents',
      isVisible:
        patientConsents?.length > 0 ||
        allKindbodyUploadsAsConsents(allAttachmentsData?.allKindbodyAttachments).length > 0 ||
        consentDocuments.length > 0,
      content: (
        <PatientSignedDocuments
          items={getConsentFiles(
            patientConsents,
            allKindbodyUploadsAsConsents(allAttachmentsData?.allKindbodyAttachments) || [],
            consentDocuments
          )}
          onView={item => {
            const consent = patientConsents.find(consent => consent.id === item.id);
            onYourConsentsView(consent);
          }}
        />
      )
    },
    {
      id: 'treatment-plans',
      label: 'Treatment Plans',
      isVisible: patientTreatmentPlans?.length > 0,
      content: (
        <PatientSignedDocuments
          items={getTreatmentPlans(patientTreatmentPlans)}
          onView={item => {
            const treatmentPlan = patientTreatmentPlans.find(
              treatmentPlan => '' + treatmentPlan.jsonData[0].id === item.id
            );

            onTreatmentPlansView(treatmentPlan);
          }}
        />
      )
    },
    {
      id: 'kindbody-lab-results',
      label: 'Kindbody Lab Results',
      isVisible: kindbodyDocs && kindbodyDocs.length > 0,
      content: (
        <DocumentsList
          // @ts-ignore
          items={kindbodyDocs}
        />
      )
    }
  ];
};
