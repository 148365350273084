const breakpointsRaw = Object.freeze({
  minimalSupportedWidth: 320,
  md: 600,
  lg: 1084,
  xl: 1284,

  // deprecated
  desktop: 1440,
  smallDesktop: 1084,
  tabletLandscape: 960,
  tabletPortrait: 600,
  largePhonePortrait: 500,
  phone: 320
});

export default breakpointsRaw;
