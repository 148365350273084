import styled, { css } from 'styled-components';

import { CommonCssProps, HeadingProps, StyleSwitchProps } from './Heading.types';

const commonCss = css<CommonCssProps>`
  display: flex;
  align-items: center;
  color: ${({ theme, color }) => (color ? color : theme.colors.neutral.primaryNavy)};
  background-color: ${({ bg }) => (bg ? bg : 'initial')};
  flex-direction: ${({ flexDirection }) => flexDirection || 'row'};
`;

const h1Css = css<HeadingProps>`
  ${({ theme }) => theme.fonts.DMSerif};
  font-size: 36px;
  font-weight: 400;
  line-height: 1.11;
  letter-spacing: 0.24px;
  ${({ noMargin }) => (noMargin ? '' : 'margin-bottom: 32px;')}

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.tabletLandscape}) {
    font-size: 56px;
    line-height: 64px;
  }
`;

const h2Css = css<HeadingProps>`
  ${({ theme }) => theme.fonts.DMSerif};
  font-size: 28px;
  font-weight: 400;
  line-height: 1.14;
  ${({ noMargin }) => (noMargin ? '' : 'margin-bottom: 24px;')}

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.tabletLandscape}) {
    font-size: 32px;
    line-height: 40px;
    letter-spacing: 0.32px;
  }
`;

const h3Css = css<HeadingProps>`
  ${({ theme }) => theme.fonts.DMSerif};
  font-size: 24px;
  font-weight: 400;
  line-height: 32px;
  letter-spacing: 0;
  ${({ noMargin }) => (noMargin ? '' : 'margin-bottom: 20px;')}

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.tabletLandscape}) {
    ${({ noMargin }) => (noMargin ? '' : 'margin-bottom: 16px;')}
  }
`;

const h4Css = css<HeadingProps>`
  ${({ theme }) => theme.fonts.Satoshi};
  font-size: 24px;
  font-weight: 700;
  line-height: 32px;
  letter-spacing: 0;
  ${({ noMargin }) => (noMargin ? '' : 'margin-bottom: 20px;')}

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.tabletLandscape}) {
    line-height: 1.333;
    font-size: 25px;
    ${({ noMargin }) => (noMargin ? '' : 'margin-bottom: 16px;')}
    letter-spacing: unset;
  }
`;

const h5Css = css<HeadingProps>`
  ${({ theme }) => theme.fonts.Satoshi};
  font-size: 18px;
  font-weight: 500;
  line-height: 1.3;
  ${({ noMargin }) => (noMargin ? '' : 'margin-bottom: 16px;')}

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.tabletLandscape}) {
    line-height: 24px;
    letter-spacing: 0.32px;
  }
`;

const h6Css = css<HeadingProps>`
  ${({ theme }) => theme.fonts.Satoshi};
  font-size: 15px;
  font-weight: 500;
  line-height: 1.143;
  letter-spacing: 0.48px;
  ${({ noMargin }) => (noMargin ? '' : 'margin-bottom: 16px;')}
  text-transform: uppercase;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.tabletLandscape}) {
    font-weight: 400;
    letter-spacing: 0.48px;
    font-size: 12px;
    line-height: 16px;
  }
`;

// By passing in the styledAs prop, we can dynamically switch between the different heading styles for every heading tag.
// This allows us to use "div" styled as an h1, for example where semantically it doesn't make sense an h1 but visually it does.
// It also allows us to use "h1" styled as an h6, for example where semantically it makes sense as an h1 but visually it makes sense as an h6.
const StyleSwitchCss = css<StyleSwitchProps>`
  ${({ styledAs }) => {
    switch (styledAs) {
      case 'h1':
        return h1Css;
      case 'h2':
        return h2Css;
      case 'h3':
        return h3Css;
      case 'h4':
        return h4Css;
      case 'h5':
        return h5Css;
      case 'h6':
        return h6Css;
      default:
        return h1Css;
    }
  }}
`;

type HeadingStyledProps = CommonCssProps & HeadingProps & StyleSwitchProps;

// native heading tags
export const H1 = styled.h1<HeadingStyledProps>`
  ${commonCss}
  ${StyleSwitchCss}
`;
export const H2 = styled.h2<HeadingStyledProps>`
  ${commonCss}
  ${StyleSwitchCss}
`;
export const H3 = styled.h3<HeadingStyledProps>`
  ${commonCss}
  ${StyleSwitchCss}
`;
export const H4 = styled.h4<HeadingStyledProps>`
  ${commonCss}
  ${StyleSwitchCss}
`;
export const H5 = styled.h5<HeadingStyledProps>`
  ${commonCss}
  ${StyleSwitchCss}
`;
export const H6 = styled.h6<HeadingStyledProps>`
  ${commonCss}
  ${StyleSwitchCss}
`;

// div styled as heading
export const DivAsHeading = styled.div<HeadingStyledProps>`
  ${commonCss}
  ${StyleSwitchCss}
`;

export const SpanAsHeading = styled.span<HeadingStyledProps>`
  ${commonCss}
  ${StyleSwitchCss}

  // override margin since this is an inline element
  margin-bottom: 0px;
  @media screen and (min-width: ${({ theme }) => theme.breakpoints.tabletLandscape}) {
    margin-bottom: 0px;
  }
`;
