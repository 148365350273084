import React from 'react';

import { Button as StyledButton } from './Button.styled';
import { Props } from './Button.types';

export const Button = ({
  className,
  label,
  size = 'md',
  category = 'primary',
  gap,
  leftIcon,
  rightIcon,
  onClick,
  fullWidth = false,
  isDisabled,
  uppercase,
  type,
  noWrap,
  // collect "data-", "aria-"... attributes and pass them to the button
  ...rest
}: Props) => {
  return (
    <StyledButton
      className={className}
      type={type}
      fullWidth={fullWidth}
      category={category}
      size={size}
      disabled={isDisabled}
      onClick={e => onClick?.(e)}
      gap={gap}
      label={label}
      aria-describedby={label ? `${label} button` : 'Button'}
      uppercase={uppercase}
      noWrap={noWrap}
      {...rest}
    >
      {leftIcon ?? <></>}
      {label ?? <></>}
      {rightIcon ?? <></>}
    </StyledButton>
  );
};
