import React, { useState, useRef, useEffect } from 'react';

import { ReactComponent as ArrowDown } from 'assets/icons/arrow_down.svg';
import { ReactComponent as ArrowUp } from 'assets/icons/arrow_up.svg';

import { getInputStates } from '../../utils';
import { DropdownMenu } from '../DropdownMenu';
import {
  SelectedOption,
  ArrowIcon,
  SelectedOptionLabel,
  Container,
  Placeholder
} from './SelectInput.styled';
import { Option, Props } from './SelectInput.types';

export const SelectInput = ({
  id,
  labelId,
  dropdownId,
  status = 'default',
  searchPlaceholder,
  options,
  searchable,
  selected,
  placeholder,
  onChange,
  wrapSelectedOptionText
}: Props): JSX.Element => {
  const { isDisabled } = getInputStates(status);
  const [isDropDownOpened, setIsDropDownOpened] = useState<boolean>(false);
  const dropDownMenuRef = useRef<HTMLDivElement>(null);
  const labelRef = useRef<HTMLDivElement>(null);

  const clickOutside = (e: Event) => {
    if (
      !dropDownMenuRef.current?.contains(e.target as Node) &&
      !labelRef.current?.contains(e.target as Node)
    ) {
      setIsDropDownOpened(false);
    }
  };

  const toggle = () => {
    if (isDisabled) return;

    setIsDropDownOpened(!isDropDownOpened);
  };

  const select = (option: Option) => {
    setIsDropDownOpened(false);
    onChange(option);
  };

  useEffect(() => {
    document.addEventListener('mousedown', clickOutside);

    return () => document.removeEventListener('mousedown', clickOutside);
  }, [isDropDownOpened]);

  return (
    <Container>
      <SelectedOption
        id={id}
        isFilled={Boolean(selected)}
        onClick={toggle}
        isDropDownOpened={isDropDownOpened}
        role="combobox"
        aria-labelledby={labelId}
        aria-expanded={isDropDownOpened}
        aria-controls={dropdownId}
        aria-autocomplete="none"
        status={status}
      >
        <SelectedOptionLabel
          ref={labelRef}
          title={selected?.label}
          wrapSelectedOptionText={wrapSelectedOptionText}
        >
          {selected?.label ? (
            selected?.label
          ) : (
            <Placeholder status={status}>{placeholder}</Placeholder>
          )}
        </SelectedOptionLabel>
        <ArrowIcon>{isDropDownOpened ? <ArrowUp /> : <ArrowDown />}</ArrowIcon>
      </SelectedOption>

      {isDropDownOpened && (
        <DropdownMenu
          id={dropdownId}
          ref={dropDownMenuRef}
          options={options}
          selected={selected}
          onSelect={select}
          searchable={searchable}
          searchPlaceholder={searchPlaceholder}
        />
      )}
    </Container>
  );
};
