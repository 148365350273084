import React, { useEffect, useState } from 'react';

import { LinkButton } from 'components/v2/Buttons/LinkButton/LinkButton';
import { TextField } from 'components/v2/Form';
import { Heading, Text } from 'components/v2/Typography';
import { theme } from 'kb-shared';
import { calculateBirthProbability } from 'kb-shared/utilities/eggCount';
import { analytics } from 'utilities/analytics';

import { Form, FormContainer, ResultBox } from './LiveBirth.syled';

export const LiveBirth = () => {
  const [yourAge, setYourAge] = useState('');
  const [eggs, setEggs] = useState('');
  const [liveBirth, setLiveBirth] = useState('');

  useEffect(() => {
    if (!(yourAge && eggs)) return setLiveBirth('');

    const liveBirthResult = calculateBirthProbability(parseInt(yourAge), parseInt(eggs));

    liveBirthResult && setLiveBirth(liveBirthResult.toString());
  }, [yourAge, eggs]);

  const trackNumberOfEggsEntered = () => {
    if (eggs) analytics.track(analytics.EVENTS.NUMBER_OF_EGGS_ENTERED);
  };

  return (
    <div>
      <FormContainer>
        <Form>
          <TextField
            id="age-input"
            type="text"
            label="Your Age"
            placeholder="eg. 35"
            value={yourAge}
            labelBg={theme.colors.semantic.lightPink}
            onChange={event => setYourAge(event.currentTarget.value)}
            hideHelperText
          />
          <TextField
            id="eggs-input"
            type="text"
            label="# of Eggs"
            placeholder="eg. 10"
            value={eggs}
            labelBg={theme.colors.semantic.lightPink}
            onChange={event => setEggs(event.currentTarget.value)}
            onBlur={() => trackNumberOfEggsEntered()}
            hideHelperText
          />
        </Form>
        <ResultBox>
          <Text size="lg" fontStyle="semibold">
            Likelihood of one live birth *
          </Text>
          <Heading tag="div" styledAs="h1" noMargin>
            {liveBirth ? `${liveBirth}%` : '*'}
          </Heading>
        </ResultBox>
      </FormContainer>
      <Text fontStyle="lightItalic" size="sm">
        * This is intended as an estimate only, and your actual number could be higher or lower.
        It's based on this{' '}
        <LinkButton
          text="research paper"
          size="sm"
          fontStyle="lightItalic"
          onClick={() =>
            window.open(
              'https://s3.us-east-2.amazonaws.com/kindbody-portal-static-files/cryo-predictor.pdf',
              '_blank'
            )
          }
        />
      </Text>
    </div>
  );
};
