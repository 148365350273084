import React, { useState } from 'react';

import { Heading } from 'components/v2/Typography';
import { Appointment } from 'kb-shared';
import { CycleVisitDaysResponse } from 'screens/Dashboard/Dashboard.types';
import { ModalCancelReschedule } from 'screens/ModalCancelResched/ModalCancelResched';

import { CycleVisits } from '../CycleVisits/CycleVisits';
import { List } from '../List/List';
import { Container, TodaysAppointments } from './UpcomingAppointments.styled';
import { getTodaysAndFutureAppointments } from './UpcomingAppointments.utils';

export const UpcomingAppointments = ({
  appointments,
  nextCycleVisit,
  cycleVisitData
}: {
  appointments?: Appointment[];
  nextCycleVisit?: string;
  cycleVisitData?: CycleVisitDaysResponse;
}) => {
  const [selectedAppointmentToDelete, setSelectedAppointmentToDelete] = useState<
    Appointment | undefined
  >();

  const { todaysAppointments, futureAppointments } = getTodaysAndFutureAppointments(appointments);

  if (todaysAppointments.length === 0 && futureAppointments.length === 0 && !nextCycleVisit)
    return <></>;

  return (
    <Container>
      {todaysAppointments.length > 0 && (
        <TodaysAppointments>
          <List
            appointments={todaysAppointments}
            onRescheduledClick={appointment => setSelectedAppointmentToDelete(appointment)}
            highlightTodaysListItems={true}
          />
        </TodaysAppointments>
      )}

      {futureAppointments.length > 0 && (
        <>
          <Heading tag="div" styledAs="h3">
            Upcoming Appointments
          </Heading>
          <List
            appointments={futureAppointments}
            onRescheduledClick={appointment => setSelectedAppointmentToDelete(appointment)}
          />
        </>
      )}

      <CycleVisits nextCycleVisit={nextCycleVisit} cycleVisitData={cycleVisitData} />

      {selectedAppointmentToDelete && (
        <ModalCancelReschedule
          visible={!!selectedAppointmentToDelete}
          onClose={() => setSelectedAppointmentToDelete(undefined)}
          appointment={selectedAppointmentToDelete}
          onAppointmentCancelled={() => window.location.reload()}
        />
      )}
    </Container>
  );
};
