import { useEffect } from 'react';

import { analytics, PAGES } from 'utilities/analytics';

type PageNames = typeof PAGES[keyof typeof PAGES];

export const usePageAnalytics = (pageName: PageNames) => {
  useEffect(() => {
    analytics.page(pageName);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageName]);
};
