import React from 'react';

import StepDetail from './StepDetail';
import { Subheader, Description } from './StepDetail';

const FullFertilityAssessmentDetail = () => {
  return (
    <StepDetail>
      <Subheader>Blood test for fertility-related hormones</Subheader>
      <Description>
        Hormones involved in the ovulation process are measured to estimate your current ovarian
        reserve
      </Description>
      <Subheader>Ultrasound to view follicles on your ovaries</Subheader>
      <Description>
        Follicles are counted to determine the number of eggs available in a given month
      </Description>
      <Subheader>Physical exam & medical history review</Subheader>
      <Description>
        Factors including age, lifestyle, medical history, and genetic background are used to create
        your personalized plan
      </Description>
    </StepDetail>
  );
};

export default FullFertilityAssessmentDetail;
