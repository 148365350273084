import { themes } from 'kb-shared';
import styled, { CSSObject } from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
`;

export const SelectContainer = styled.div`
  width: 100%;
`;

const widthAndColor = () => ({
  backgroundColor: themes.colors.yellow.lightestYellow,
  maxWidth: '420px'
});

export const SelectStyles = {
  control: (styles: CSSObject) => ({
    ...styles,
    ...widthAndColor(),
    marginTop: 6
  }),
  valueContainer: (styles: CSSObject) => ({ ...styles, ...widthAndColor() })
};
