import styled from 'styled-components';

export const Container = styled.div`
  padding-left: 20px;
  padding-right: 20px;
  margin: 0 14px;
  width: 100%;
`;

export const CollateContainer = styled.div`
  padding-bottom: 24px;
`;
