import { createGlobalStyle, css } from 'styled-components';
import 'tippy.js/dist/tippy.css';

import cssReset from './styled/cssReset';

const globalStyle = css`
  ${cssReset};

  html,
  body,
  #root {
    ${({ theme }) => theme.fonts.Satoshi};

    height: 100%;
  }

  body {
    color: ${({ theme }) => theme.colors.neutral.primaryNavy};
    background-color: ${({ theme }) => theme.colors.white};
    overflow-y: overlay;
    line-height: 1.3;
  }

  // added because of issue with third-party cookie banner
  div#hs-eu-cookie-confirmation div#hs-eu-policy-wording > p > a {
    color: #272a5e;
    font-weight: 800 !important;
  }

  .tippy-box:has(.message-additional-participants-tooltip) {
    background-color: ${({ theme }) => theme.colors.neutral.darkestNavy};
    padding: 8px 16px;
    border-radius: 8px;

    text-align: left;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    color: ${({ theme }) => theme.colors.neutral.lavenderWhite};

    span {
      font-weight: 700;
    }
  }
`;

export default createGlobalStyle`${globalStyle}`;
