import React from 'react';
import { useHistory } from 'react-router-dom';

import { Alert } from 'components/v2/Alert/Alert';
import { Button } from 'components/v2/Buttons/Button';
import { LinkButton } from 'components/v2/Buttons/LinkButton/LinkButton';
import { CATEGORY_SLUG } from 'screens/Messages/Messages.constants';
import { pageUrl } from 'utilities/pageUrl';

interface Props {
  onRetry: () => void;
}

export const MedicationsWidgetRetry: React.FC<Props> = ({ onRetry }) => {
  const history = useHistory();
  const messagesUrl = pageUrl.messages({
    categoryName: CATEGORY_SLUG.MEDICAL,
    subject: 'Medications and instructions question'
  });

  return (
    <Alert
      type="error"
      title="Oops! We couldn't retrieve your medication instructions at the moment"
      message={
        <>
          If you need immediate assistance, message our medical team{' '}
          <LinkButton text="here" onClick={() => history.push(messagesUrl)} />.
        </>
      }
      actions={<Button type="button" category="primary" label="RETRY" onClick={onRetry} />}
    />
  );
};
