import { ConversationStatus } from 'screens/Messages/Messages.type';
import {
  isClosedConversation,
  isNewConversation,
  isOpenConversation
} from 'screens/Messages/Messages.utils';
import { parseNumber } from 'utilities/parseNumber';

export const showInputs = (status?: ConversationStatus) =>
  isNewConversation(status) || isOpenConversation(status) || isClosedConversation(status);

export const getID = (response: any) => {
  if (response.data.createMessage) {
    return parseNumber(response.data.createMessage.message.id);
  } else {
    const [message] = response.data?.createConversation.conversation.messages || [];
    return parseNumber(message.id);
  }
};
