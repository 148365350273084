import { currencyNumberFormat } from '../../../utilities/number-format';
import { LineItemNextPayer } from '../types/invoices-details';

export const getPaymentAmountContent = (lineItem: {
  amount: number;
  nextPayer: LineItemNextPayer;
}) => {
  const { amount, nextPayer } = lineItem;

  if (!nextPayer || nextPayer === 'PATIENT') return currencyNumberFormat(amount);
  else return 'Pending with Insurance';
};
