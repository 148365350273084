import { useQuery, useMutation } from '@apollo/client';
import moment from 'moment';
import { useEffect, useState } from 'react';

import {
  QUERY_CYCLE_PLAN_ACKNOWLEDGED,
  QUERY_CYCLE_PLAN_TO_ACKNOWLEDGE,
  MUTATION_CYCLE_PLAN_ACKNOWLEDGE
} from './CyclePlanAcknowledge.graphql';
import {
  CyclePlanResult,
  CyclePlanHookResult,
  AcknowledgeStatus,
  CyclePlanAcknowledgeResponse,
  ErrorBase,
  CyclePlanAcknowledgeErrors
} from './CyclePlanAcknowledge.types';

export const useQueryCyclePlanInfoBySections = (id: number): CyclePlanHookResult => {
  const { data, loading } = useQuery(QUERY_CYCLE_PLAN_TO_ACKNOWLEDGE, {
    variables: { id: id }
  });

  if (!data || loading) {
    return { data: {}, loading };
  }

  if (data.cyclePlanToAcknowledge == null) {
    return { data: {}, loading, resentNeeded: true };
  }

  const {
    patientAcknowledgementJson,
    patientAcknowledgementJsonHash,
    patient,
    cycleType
  } = data.cyclePlanToAcknowledge as CyclePlanResult;

  return {
    data: {
      patient: [
        {
          fields: [
            {
              label: 'MRN',
              value: patient.id
            },
            {
              label: 'Name',
              value: patient.name
            },
            {
              label: 'Birthday',
              value: moment(patient.birthday).format('MM/DD/YYYY')
            },
            {
              label: 'Cycle Type',
              value: cycleType
            }
          ]
        }
      ],
      cyclePlans: patientAcknowledgementJson,
      patientAcknowledgementJsonHash: patientAcknowledgementJsonHash
    },
    loading
  };
};

export const useMutationCyclePlanAcknowledge = (id: number, acknowledgeHash?: string) => {
  const { data } = useQuery(QUERY_CYCLE_PLAN_ACKNOWLEDGED, { variables: { id } });
  const [acknowledged, setAcknowledged] = useState<AcknowledgeStatus>({
    isAcknowledged: data?.cyclePlanAcknowledged
  });
  const [mutate] = useMutation<CyclePlanAcknowledgeResponse>(MUTATION_CYCLE_PLAN_ACKNOWLEDGE);

  useEffect(() => {
    setAcknowledged({ isAcknowledged: data?.cyclePlanAcknowledged });
  }, [data]);

  const handleErrors = (errors: ErrorBase[]) => {
    errors.map(error => {
      if (error.code === CyclePlanAcknowledgeErrors.OUT_OF_DATE_VERSION) {
        return setAcknowledged({
          isAcknowledged: false,
          hasError: true,
          outOfDateVersion: true
        });
      } else if (error.code === CyclePlanAcknowledgeErrors.CONTACT_PROVIDER_RESEND) {
        return setAcknowledged({
          isAcknowledged: false,
          hasError: true,
          resentNeeded: true
        });
      }

      return undefined;
    });
  };

  const setCyclePlanAcknowledged = async () => {
    try {
      const { data } = await mutate({ variables: { cyclePlanId: id, hash: acknowledgeHash } });

      if (data?.acknowledgeCyclePlan.errors) {
        handleErrors(data.acknowledgeCyclePlan.errors);
        return;
      }

      setAcknowledged({
        isAcknowledged: true,
        hasError: false
      });
    } catch (error) {
      setAcknowledged({
        isAcknowledged: false,
        hasError: true,
        refreshNeeded: true
      });
      console.error(error);
    }
  };

  return { acknowledged, setCyclePlanAcknowledged };
};
