import React from 'react';

import { Button } from 'components/v2/Buttons/Button';

import { ActionButtonsContainer } from './ActionButtons.styled';
import { ActionButtonsProps } from './ActionButtons.types';

export const ActionButtons = (props: ActionButtonsProps) => {
  const { onBack, onForward, forwardDisabled, forwardText } = props;
  return (
    <ActionButtonsContainer>
      <Button label={'Back'} category="secondary" size="lg" onClick={onBack} />
      <Button
        label={forwardText || 'Next'}
        category="primary"
        isDisabled={forwardDisabled}
        size="lg"
        onClick={onForward}
      />
    </ActionButtonsContainer>
  );
};
