import React from 'react';

import { postOpInstructions } from 'kb-shared';

import instructionRenderer from '../instructionRenderer';
import InstructionsModal, { InstructionRow } from './InstructionsModal';
interface ShowProps {
  show: boolean;
  hide: () => void;
}

const POST_OP_INSTRUCTIONS: InstructionRow[] = postOpInstructions.map(instructionRenderer);

const PostopInstructionsModal = ({ show, hide }: ShowProps) => {
  return (
    <InstructionsModal
      title="Post-operative Instructions"
      show={show}
      hide={hide}
      rows={POST_OP_INSTRUCTIONS}
      alert="You should be monitored by a responsible adult for the next 24 hours"
    />
  );
};

export default PostopInstructionsModal;
