import React from 'react';

import { Pen } from 'assets/icons/Icons';
import { LinkButton } from 'components/v2/Buttons/LinkButton/LinkButton';
import { Text } from 'components/v2/Typography';

import {
  ButtonContainer,
  Container,
  Row,
  Stripe,
  Value,
  ValueContainer
} from './ValuesSelected.styled';

export const ValuesSelected = ({
  label,
  value,
  onClick
}: {
  label: string;
  value: string;
  onClick: () => void;
}) => {
  return (
    <Container>
      <Text size="lg">{label}</Text>
      <Row>
        <ValueContainer>
          <Value tag="h5" styledAs="h2" noMargin>
            {value}
          </Value>
          <Stripe />
        </ValueContainer>
        <ButtonContainer>
          <LinkButton
            leftIcon={<Pen type="regular" />}
            text="Edit"
            underline="none"
            transform="uppercase"
            onClick={onClick}
          />
        </ButtonContainer>
      </Row>
    </Container>
  );
};
