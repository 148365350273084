import React from 'react';

import { utils } from 'kb-shared';

import { NavigatorEmailLink } from './NavigatorEmail.styled';
import { NavigatorEmailProps } from './NavigatorEmail.types';

export const NavigatorEmail = ({ emailBody }: NavigatorEmailProps) => {
  return (
    <NavigatorEmailLink
      href={`mailto:${utils.KBContacts.navigatorEmail}?subject=Appointment Cancellation${emailBody}`}
    >
      {utils.KBContacts.navigatorEmail}
    </NavigatorEmailLink>
  );
};
