import { Text } from 'components/v2/Typography/Text';
import { themes } from 'kb-shared';
import styled from 'styled-components';

export const Container = styled(Text).attrs({
  size: 'xs',
  fontStyle: 'bold',
  color: themes.colors.neutral.lightNavy
})`
  width: 100%;
  padding: 8px;
  text-align: center;
`;
