import styled from 'styled-components';

export const InputWrapper = styled.div`
  min-width: 0;
`;

export const PaymentSection = styled.div`
  padding: 0px 8px 0px 8px;
  margin: 24px -8px 24px -8px;

  @media ${({ theme }) => theme.queries.minTablet} {
    padding: 0px 18px 0px 48px;
    margin: 24px -20px 24px -48px;
  }
`;

export const ResponsiveContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  row-gap: 16px;
  column-gap: 24px;

  border-bottom: 1px solid ${({ theme }) => theme.colors.neutral.lavender};
  padding-bottom: 24px;
`;

export const ResponsiveForm = styled.form`
  min-width: 300px;

  @media ${({ theme }) => theme.queries.minTablet} {
    min-width: 500px;
  }
  @media ${({ theme }) => theme.queries.minDesktop} {
    min-width: 580px;
  }
`;

export const InvoiceField = styled.div`
  word-wrap: break-word;
  overflow-wrap: break-word;
  hyphens: auto;
`;

export const InvoiceButtons = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 16px;
`;

export const PayCycleCheckbox = styled.div`
  margin-bottom: 24px;
`;
