import { gql, useQuery } from '@apollo/client';
import React, { useState } from 'react';

import { MEDICATIONS } from 'kb-shared/utilities/MedicationsInstruction';

import { ButtonList, Description, Title, VideoButton } from './styled-components';

const PROTOCOL_DRUGS = gql`
  query protocolDrugs {
    protocolDrugs
  }
`;

const LearnAboutInjections = () => {
  const [protocolDrugs, setProtocolDrugs] = useState([]);

  useQuery(PROTOCOL_DRUGS, {
    onCompleted: data => setProtocolDrugs(data.protocolDrugs)
  });

  return (
    <>
      <Title>Learn about injections</Title>
      <Description>
        The videos below are for the specific drugs your physician has selected for you. It&apos;s
        critical that you watch each one carefully before your initial cycle visit because you
        can&apos;t start cycling without knowing exactly how the injections work. Each day, your
        care team will display your medication instructions on the dashboard.
      </Description>
      <Description>
        Still not sure? Reach out to{' '}
        <a href="mailto:navigator@kindbody.com">navigator@kindbody.com</a>.
      </Description>

      <ButtonList>
        {protocolDrugs.map(drugName => {
          const medication = MEDICATIONS[drugName];
          if (!medication) return null;

          return (
            <VideoButton
              key={medication.name}
              label={medication.name}
              onClick={() => window.open(medication.url)}
            />
          );
        })}
      </ButtonList>
    </>
  );
};

export default LearnAboutInjections;
