import { errorState } from 'components/v2/common.styled';
import styled, { css } from 'styled-components';

import { Status } from '../../types';

const defaultLabelColor = css`
  color: ${({ theme }) => theme.colors.neutral.navy};
`;

export const labelStatusStyle: Record<Status, ReturnType<typeof css>> = {
  default: defaultLabelColor,
  error: css`
    color: ${errorState.textColor};
  `,
  disabled: css`
    color: ${({ theme }) => theme.colors.neutral.lightNavy};
  `,
  readOnly: defaultLabelColor
};

export const Label = styled.label<{ status: Status; bg?: string }>`
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  text-transform: uppercase;
  margin: 0 0 8px 0;
  flex-wrap: wrap;
  background-color: ${({ bg }) => (bg ? bg : 'white')};
  display: flex;
  column-gap: 5px;
  align-items: center;

  > div {
    width: 100%;
  }

  ${defaultLabelColor}
  ${({ status }) => labelStatusStyle[status]}
`;

// Helper text is conditionally rendered and we need this to prevent screen 'jumping'
// screen's state changes from helper text not rendered to helper text rendered, and vice versa.
export const HelperTextContainer = styled.div`
  height: 16px;
`;

export const HelperText = styled.span`
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  margin: 8px 0 0 0;

  // This is needed to avoid the input to change when the input helper message is shown
  display: inline-block;
  min-height: 16px;
`;

export const containerStatusStyle: Record<Status, ReturnType<typeof css>> = {
  default: css``,
  error: css`
    ${HelperText} {
      color: ${({ theme }) => theme.colors.semantic.red};
    }
  `,
  disabled: css`
    ${Label} {
      color: ${({ theme }) => theme.colors.neutral.lightNavy};
    }
  `,
  readOnly: css``
};

export const Container = styled.div<{ status: Status }>`
  display: flex;
  flex-direction: column;

  ${({ status }) => containerStatusStyle[status]}
`;

export const Description = styled.span`
  display: inline-block;
  font-style: italic;
  font-size: 12px;
  line-height: 16px;
  font-weight: 400;
  text-transform: none;
`;

export const RequiredIndicator = styled.span`
  color: ${({ theme }) => theme.colors.semantic.darkRed};
  margin: 0 5px;
  font-size: 16px;
  font-weight: 600;
  line-height: 12px;
`;
