import React from 'react';
import { useHistory } from 'react-router-dom';

import { ReactComponent as Checkmark } from 'assets/icons/checkmark-green.svg';

import { StatusProp } from '../../InterstitialModal.types';
import { Paragraph, Title, Description, Action } from '../Shared.styled';
import { ConsentsCompleted } from './ConsentsCompleted';
import { Consent, ConsentsSection } from './ConsentsTab.styled';

export const ConsentsTab = ({ status }: StatusProp) => {
  const history = useHistory();
  const { hasRequiredConsents, requiredConsents } = status;

  if (hasRequiredConsents === true) return <ConsentsCompleted />;

  const consents = [...requiredConsents].sort((a, b) => b.id.localeCompare(a.id));

  return (
    <ConsentsSection>
      {consents.map((consent: any) => (
        <Consent key={consent.linkUrl}>
          <Description>
            <Title>{consent.name}</Title>
            <Paragraph>{consent.description}</Paragraph>
          </Description>
          {consent.completed ? (
            <Checkmark className="checkmark" />
          ) : (
            <Action onClick={() => history.push(consent.linkUrl)}>Complete Consent</Action>
          )}
        </Consent>
      ))}
    </ConsentsSection>
  );
};
