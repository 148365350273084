import React, { useState, useEffect } from 'react';

import { Text } from 'components/v2/Typography';
import { setHoneybadgerContext } from 'kb-redux/user.redux';
import { SignUpProps } from 'kb-shared';
import theme from 'kb-shared/theme';
import { SignUpForm } from 'screens/SignUp/SignUpForm';
import { getInitialPatientData } from 'utilities/userUtil';
import { SIGNUP_TYPE } from 'utilities/userUtil.types';

import {
  CreatePatientFormContainer,
  ErrorContainer,
  InfoContainer
} from '../CreatePatientForm.styled';
import { useCreatePatient } from './hooks/use-create-patient';
import RecognizePatientResultCode from './types/recognize-patient-result-code';

const STEPS = {
  editingForm: 'editingForm',
  submittingForm: 'submittingForm'
};

export default function CreatePatientForm(props: {
  idtoken: string;
  onPatientSuccessfullyCreated: any;
}) {
  const [step, setStep] = useState(STEPS.editingForm);
  const [error, setError] = useState('');
  const { idtoken, onPatientSuccessfullyCreated } = props;
  const { responseState: createPatientResponse, createPatient } = useCreatePatient();

  const [patientData, setPatientData] = useState(getInitialPatientData(undefined).patient);

  useEffect(() => {
    if (step === STEPS.submittingForm) {
      createPatient(
        idtoken,
        {
          email: patientData.email,
          firstName: patientData.firstName,
          lastName: patientData.lastName,
          preferredName: patientData.preferredName || '',
          birthday: patientData.birthdate,
          gender: patientData.gender,
          labId: '' + patientData.labId,
          phone: patientData.phone.replace(/\D/g, ''),
          pronoun: patientData.pronoun,
          genderIdentity: patientData.genderIdentity || '',
          referralSource: patientData.referralSource,
          referralSourceDetails: patientData.referralSourceDetails,
          sendSmsAppointmentNotification: patientData.sendSmsAppointmentNotification,
          preferredTimezone: patientData.preferredTimezone,
          sendMarketingEmail: patientData.sendMarketingEmail
        },
        patientData.address
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [step]);

  useEffect(() => {
    if (!(step === STEPS.submittingForm && createPatientResponse.finished)) return;

    if (createPatientResponse.resultCode === RecognizePatientResultCode.PatientCreated) {
      createPatientResponse.patient &&
        setHoneybadgerContext(createPatientResponse.patient.id.toString());
      onPatientSuccessfullyCreated();
    } else if (createPatientResponse.resultCode === RecognizePatientResultCode.PatientRecognized) {
      // well, this scenario should never happen, but just in case..
      onPatientSuccessfullyCreated();
    } else {
      setStep(STEPS.editingForm);
      setError((createPatientResponse.errors as Array<string>).join('. '));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createPatientResponse]);

  const signUp = (patient: SignUpProps) => {
    setPatientData(patient);
    setStep(STEPS.submittingForm);
  };

  return (
    <CreatePatientFormContainer>
      <InfoContainer>
        <Text tag="span" size="md" fontStyle="semibold">
          You are successfully logged in via Medtronic SSO.
        </Text>
        <br />
        <Text tag="span" size="md" fontStyle="semibold">
          Before you can proceed to Kindbody Patient portal, please fill in your personal details.
        </Text>
      </InfoContainer>
      <ErrorContainer>
        <Text tag="span" size="md" fontStyle="semibold" color={theme.colors.semantic.red}>
          {error}
        </Text>
      </ErrorContainer>
      <SignUpForm
        onSignUpPatient={signUp}
        loading={step === STEPS.submittingForm}
        signUpType={SIGNUP_TYPE.MEDTRONIC}
        signInLinkIsVisible={false}
      />
    </CreatePatientFormContainer>
  );
}
