import React from 'react';
import Media from 'react-media';

import { ChevronLeft } from 'assets/icons/Icons';
import { Heading } from 'components/v2/Typography';
import { themes } from 'kb-shared';

import {
  FormContent,
  Content,
  LeftContent,
  RightContent,
  TitleContainer,
  PageTitle,
  ContentContainer,
  FormContainer,
  PageTitleRow,
  BackContainer,
  ForwardContainer,
  ArrowLeftContainer,
  BackText,
  UnderlinedText
} from './AuthFlowLayout.styles';
import { Props } from './AuthFlowLayout.types';

const breakpoints = themes.breakpoints;

export const AuthFlowLayout = ({
  title,
  renderAboveTitleContent,
  renderMainContent,
  renderDesktopRightSidebar,
  onBack
}: Props) => {
  const TitleRow = () => (
    <PageTitleRow>
      {onBack && (
        <BackContainer onClick={onBack}>
          <ArrowLeftContainer>
            <ChevronLeft type="solid" />
          </ArrowLeftContainer>
          <BackText>Back</BackText>
        </BackContainer>
      )}

      {title && (
        <PageTitle>
          <Media query={{ minWidth: breakpoints.raw.smallDesktop }}>
            {desktop => {
              return (
                <UnderlinedText>
                  <Heading tag="span" styledAs={desktop ? 'h1' : 'h2'}>
                    {title}
                  </Heading>
                </UnderlinedText>
              );
            }}
          </Media>
        </PageTitle>
      )}
      {onBack && <ForwardContainer />}
    </PageTitleRow>
  );

  return (
    <ContentContainer>
      <Media query={{ minWidth: breakpoints.raw.smallDesktop }}>
        {desktop => {
          if (desktop) {
            return (
              <>
                <TitleContainer>
                  <LeftContent>
                    {renderAboveTitleContent && renderAboveTitleContent(false)}
                    <TitleRow />
                  </LeftContent>
                  {renderDesktopRightSidebar && <RightContent />}
                </TitleContainer>
                <Content>
                  <LeftContent>
                    <FormContainer>
                      <FormContent>{renderMainContent(false)}</FormContent>
                    </FormContainer>
                  </LeftContent>
                  {renderDesktopRightSidebar && (
                    <RightContent>{renderDesktopRightSidebar()}</RightContent>
                  )}
                </Content>
              </>
            );
          } else {
            return (
              <RightContent>
                <TitleContainer mobile>
                  {renderAboveTitleContent && renderAboveTitleContent(true)}
                  <TitleRow />
                </TitleContainer>
                <Content mobile>
                  <LeftContent>
                    <FormContainer>
                      <FormContent mobile>{renderMainContent(true)}</FormContent>
                    </FormContainer>
                  </LeftContent>
                </Content>
              </RightContent>
            );
          }
        }}
      </Media>
    </ContentContainer>
  );
};
