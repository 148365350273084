import React from 'react';
import { useHistory } from 'react-router-dom';

import { Button } from 'components/v2/Buttons/Button';
import { themes } from 'kb-shared';
import styled from 'styled-components';
import { pageUrl } from 'utilities/pageUrl';

import StepDetail, { Description, Subheader } from './StepDetail';

const { breakpoints } = themes;

const ResultsDetail = () => {
  const history = useHistory();

  return (
    <StepDetail>
      <FlexContainer>
        <div>
          <Subheader>Your results are ready for review</Subheader>
          <Description>
            Let your care team know if you have any further questions before moving forward with
            treatment.
          </Description>
        </div>

        <ButtonStyled label="View your results" onClick={() => history.push(pageUrl.results())} />
      </FlexContainer>
    </StepDetail>
  );
};

export default ResultsDetail;

const FlexContainer = styled.div`
  display: flex;
  flex-direction: column;

  @media screen and (min-width: ${breakpoints.smallDesktop}) {
    flex-direction: row;
  }
`;

const ButtonStyled = styled(Button)`
  min-width: fit-content;
  height: fit-content;
  width: fit-content;
`;
