import React from 'react';

import { PreferredTimezoneSelect } from 'components/v2/Inputs/PreferredTimezoneSelect';

import { FormControl, getFormControlIds } from '../FormControl';
import { Props } from './PreferredTimezoneSelect.types';

export const PreferredTimezoneSelectField = ({
  status = 'default',
  label,
  id,
  required,
  helperText,
  description,
  placeholder,
  searchPlaceholder,
  onChange,
  selected,
  searchable,
  labelBg,
  onOptionsLoaded
}: Props) => {
  const ids = getFormControlIds(id);
  const labelId = ids.label;
  const dropdownId = `${ids.input}-dropdown`;

  return (
    <FormControl
      status={status}
      label={label}
      required={required}
      helperText={helperText}
      description={description}
      labelId={labelId}
      inputId={ids.input}
      helperTextId={ids.helperText}
      labelBg={labelBg}
    >
      <PreferredTimezoneSelect
        id={ids.input}
        labelId={labelId}
        dropdownId={dropdownId}
        status={status}
        placeholder={placeholder}
        onChange={onChange}
        selected={selected}
        searchPlaceholder={searchPlaceholder}
        searchable={searchable}
        onOptionsLoaded={onOptionsLoaded}
      />
    </FormControl>
  );
};
