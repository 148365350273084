import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: 20px;
  padding-right: 20px;
  margin: 0 14px;
`;

export const DisclaimerText = styled.p`
  font-size: 12px;
  font-style: italic;
  background: white;
`;
