import styled from 'styled-components';

export const Container = styled.div`
  padding-left: 20px;
  padding-right: 20px;
  margin: 0 14px;
  width: 100%;
`;

export const Form = styled.div`
  display: flex;
  flex-direction: column;
`;

export const CollateContainer = styled.div`
  padding-bottom: 24px;
`;

export const Bullets = styled.ul`
  list-style: disc;
  padding: 5px 30px 5px 30px;
`;

export const LinkWrapper = styled.span`
  color: ${({ theme }) => theme.colors.neutral.primaryNavy};
  cursor: pointer;
  text-decoration: underline;
`;
