import debounce from 'lodash.debounce';
import React, { Component } from 'react';

import { IntakeForm, FormSection, FormField, FormSubheader, FieldGroup, Answer } from 'kb-shared';
import {
  getNextQuestion,
  smoothScrollToQuestion
} from 'screens/IntakeForm/routes/IntakeFormRoute.helpers';

import FieldGroupHeightForm from '../FieldGroupHeightForm';
import { BooleanForm } from '../Forms/BooleanForm/BooleanForm';
import { DateForm } from '../Forms/DateForm/DateForm';
import { DropDownForm } from '../Forms/DropDownForm/DropDownForm';
import EmailForm from '../Forms/EmailForm/EmailForm';
import FieldGroupBooleanPairForm from '../Forms/FieldGroupBooleanPairForm';
import FieldGroupMultipleSelectionForm from '../Forms/FieldGroupMultipleSelectionForm';
import FieldGroupSelectionForm from '../Forms/FieldGroupSelectionForm';
import { FieldGroupStringForm } from '../Forms/FieldGroupStringForm/FieldGroupStringForm';
import IntegerForm from '../Forms/IntegerForm';
import { MultipleSelectionForm } from '../Forms/MultipleSelectionForm/MultipleSelectionForm';
import SelectionForm from '../Forms/SelectionForm';
import StringForm from '../Forms/StringForm';
import { Subheader } from '../Forms/Subheader/Subheader';
import TextForm from '../Forms/TextForm';
import RoundedButton from '../RoundedButton';
import {
  Container,
  ControlButtons,
  FormsContainer,
  FormSectionStyled,
  Link,
  MarginBottom,
  NextButton,
  PreviousButton,
  ScrollItemContainer
} from './FormRenderer.styled';
import ScrollItem from './ScrollItem';

type Props = {
  formData: IntakeForm;
  questionsToHide: Array<string>;
  currentSection: number;
  currentForm: number;
  savedAnswers: {
    [id: string]: Answer;
  };
  onSubmitAnswer: (response: string | Array<string> | undefined, id: string) => void;
  topOffset: number;
  onSubmitForm: () => void;
  showPreviousSection: () => void;
  showNextSection: () => void;
};

type State = {
  formHeight: number;
  sectionIndex: number;
  formIndex: number;
};
export type CurrentFormType = FormField | FormSubheader | FieldGroup;

export class FormRenderer extends Component<Props, State> {
  _debounceUpdateDimensions: (this: Window, ev: UIEvent) => void;

  constructor(props: Props) {
    super(props);
    this.state = {
      formHeight: this._getFormHeight(),
      sectionIndex: 0,
      formIndex: 0
    };
    this._debounceUpdateDimensions = debounce(this._updateDimensions.bind(this), 25);
  }

  componentDidMount() {
    window.addEventListener('resize', this._debounceUpdateDimensions);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this._debounceUpdateDimensions);
  }

  _getFormHeight() {
    // 80 is a magic number that permits a small amount of "peeking"
    return window.innerHeight - this.props.topOffset * 2 - 80;
  }

  _updateDimensions() {
    // Only update form height if change is more than 100px
    // This prevents jittering on mobile browsers where the chrome gets hidden
    if (this.state.formHeight - this._getFormHeight() > 100) {
      this.setState({
        formHeight: this._getFormHeight()
      });
    }
  }

  isLastSection() {
    return this.props.currentSection === this.props.formData.formSections.length - 1;
  }

  isFirstSection() {
    return this.props.currentSection === 0;
  }

  isActivatedSection(sectionIndex: number) {
    return this.props.currentSection === sectionIndex;
  }

  scrollTop() {
    window.scroll({ top: 0 });
  }

  renderAllForms() {
    return this.props.formData.formSections.map((section: FormSection, sectionIndex: number) =>
      this.renderSection(section, sectionIndex)
    );
  }

  isFormElementForRendering(formElement: FormSubheader | FormField | FieldGroup) {
    if (formElement.type !== 'FormField') {
      // only FormFields can conditionally be rendered
      return true;
    }

    if (!this.props.formData.conditionalFormElements) {
      // if there are no conditions defined for form, element is always rendered
      return true;
    }

    return !this.props.questionsToHide.includes(formElement.id);
  }

  renderSection(section: FormSection, sectionIndex: number) {
    const visibleQuestions = section.formElements.filter(element =>
      this.isFormElementForRendering(element)
    );
    return (
      <FormSectionStyled
        active={this.isActivatedSection(sectionIndex)}
        key={`section-${sectionIndex}`}
      >
        {visibleQuestions.map(
          (element: FormSubheader | FormField | FieldGroup, formIndex: number) => {
            const question = this.renderForm(sectionIndex, element);
            if (question == null) return null;

            return (
              <ScrollItemContainer
                key={`form-${sectionIndex}-${formIndex}`}
                setMinHeight={element.type !== 'FormSubheader'}
                minHeight={this.state.formHeight + 'px'}
                id={`fe-` + element.id}
                isMemberOfLastSection={this.isLastSection()}
              >
                <ScrollItem
                  onActive={() => {
                    this.setState({
                      sectionIndex: sectionIndex,
                      formIndex: formIndex
                    });
                  }}
                  topBreakPoint={window.innerHeight / 2}
                  bottomBreakPoint={200}
                >
                  <div>{question}</div>
                </ScrollItem>
              </ScrollItemContainer>
            );
          }
        )}
      </FormSectionStyled>
    );
  }

  skipLinkComponent(id: number, dataType: string) {
    // we are handling these questions using their ids, because
    // it's was decided to improve it as fast as possible.
    // In the next task, It will be applied an ultimate solution.
    const PREGNANCY_QUESTIONS_ID = 1132;
    const SEQUENCY_PREGNANCY_QUESTIONS_IDS = [898, 914, 930, 961, 966];
    const TREATMENT_QUESTION_ID = 697;

    if (PREGNANCY_QUESTIONS_ID === id && dataType === 'boolean') {
      return (
        <Link onClick={this.props.showNextSection}>
          If you have never been pregnant, please click here to skip ahead to the next section
        </Link>
      );
    }

    if (SEQUENCY_PREGNANCY_QUESTIONS_IDS.includes(id) && dataType === 'date') {
      return (
        <Link onClick={this.props.showNextSection}>
          If you haven't had more pregnancies, click here to skip ahead to the next section
        </Link>
      );
    }

    if (TREATMENT_QUESTION_ID === id && dataType === 'boolean') {
      return (
        <Link onClick={this.scrollToBottom}>If N/A, please click here to go to end of form</Link>
      );
    }

    return null;
  }

  scrollToBottom(): void {
    window.scrollTo({
      top: document.body.scrollHeight,
      behavior: 'smooth'
    });
  }

  onContinue(question: CurrentFormType, answerValue?: string): void {
    const searchResult = getNextQuestion(
      this.props.formData,
      this.state.sectionIndex,
      question.id,
      this.props.questionsToHide,
      answerValue
    );

    if (searchResult.nextQuestion) {
      const questionId = searchResult.nextQuestion.id;
      if (document.getElementById(`fe-${questionId}`)) {
        smoothScrollToQuestion(questionId);
      } else {
        setTimeout(() => {
          questionId && smoothScrollToQuestion(questionId);
        }, 500);
      }
    } else if (searchResult.currentQuestionIsLastInSection && !this.isLastSection())
      this.props.showNextSection();
  }

  renderForm(sectionIndex: number, currentForm: CurrentFormType) {
    const currentSection: FormSection = this.props.formData.formSections[sectionIndex];
    if (!currentSection) {
      return;
    }

    const existingAnswer: Answer | null = this.props.savedAnswers[currentForm.id];

    switch (currentForm.type) {
      case 'FormSubheader':
        return <Subheader text={currentForm.title} />;
      case 'FormField':
        switch (currentForm.dataType) {
          case 'string':
            return (
              <StringForm
                key={currentForm.id}
                isRequired={currentForm.required}
                title={currentForm.title}
                onSave={response => this.props.onSubmitAnswer(response, currentForm.id)}
                onContinue={() => this.onContinue(currentForm)}
                answer={existingAnswer}
                skipLinkComponent={this.skipLinkComponent(
                  parseInt(currentForm.id),
                  currentForm.dataType
                )}
              />
            );
          case 'text':
            return (
              <TextForm
                key={currentForm.id}
                isRequired={currentForm.required}
                title={currentForm.title}
                onSave={response => this.props.onSubmitAnswer(response, currentForm.id)}
                onContinue={() => this.onContinue(currentForm)}
                answer={existingAnswer}
              />
            );
          case 'boolean':
            return (
              <BooleanForm
                key={currentForm.id}
                isRequired={currentForm.required}
                title={currentForm.title}
                onSubmit={value => {
                  this.props.onSubmitAnswer(value, currentForm.id);
                  this.onContinue(currentForm, value);
                }}
                answer={existingAnswer}
                skipLinkComponent={this.skipLinkComponent(
                  parseInt(currentForm.id),
                  currentForm.dataType
                )}
              />
            );
          case 'select':
            return (
              <DropDownForm
                key={currentForm.id}
                answer={existingAnswer?.data}
                title={currentForm.title}
                options={currentForm.options}
                onSubmit={value => {
                  this.props.onSubmitAnswer(value, currentForm.id);
                  this.onContinue(currentForm, value);
                }}
              />
            );
          case 'selection':
            return (
              <SelectionForm
                key={currentForm.id}
                title={currentForm.title}
                options={currentForm.options}
                isRequired={currentForm.required}
                onSubmit={value => {
                  this.props.onSubmitAnswer(value, currentForm.id);
                  this.onContinue(currentForm, value);
                }}
                answer={existingAnswer}
              />
            );
          case 'multiple_selections':
            return (
              <MultipleSelectionForm
                key={currentForm.id}
                isRequired={!!currentForm.required}
                title={currentForm.title}
                options={currentForm.options}
                onSave={response => this.props.onSubmitAnswer(response, currentForm.id)}
                onContinue={() => this.onContinue(currentForm)}
                answer={existingAnswer}
              />
            );
          case 'date':
            return (
              <DateForm
                key={currentForm.id}
                isRequired={currentForm.required}
                title={currentForm.title}
                onSave={response => this.props.onSubmitAnswer(response, currentForm.id)}
                onContinue={() => this.onContinue(currentForm)}
                answer={existingAnswer}
                skipLinkComponent={this.skipLinkComponent(
                  parseInt(currentForm.id),
                  currentForm.dataType
                )}
              />
            );
          case 'integer':
            return (
              <IntegerForm
                key={currentForm.id}
                isRequired={currentForm.required}
                title={currentForm.title}
                onSave={response => this.props.onSubmitAnswer(response, currentForm.id)}
                onContinue={() => this.onContinue(currentForm)}
                answer={existingAnswer}
              />
            );
          case 'height':
            return (
              <FieldGroupHeightForm
                key={currentForm.id}
                isRequired={currentForm.required}
                title={currentForm.title}
                onSave={response => this.props.onSubmitAnswer(response, currentForm.id)}
                onContinue={() => this.onContinue(currentForm)}
                answer={existingAnswer}
              />
            );
          case 'email':
            return (
              <EmailForm
                key={currentForm.id}
                isRequired={currentForm.required}
                title={currentForm.title}
                onSave={response => this.props.onSubmitAnswer(response, currentForm.id)}
                answer={existingAnswer}
              />
            );
          default:
            // NOTE: validateDataTypes function should report invalid data types, nothing to render here
            return null;
        }

      case 'FieldGroup':
        const fieldGroup: FieldGroup = currentSection.fieldGroupsMap[currentForm.id];

        switch (currentForm.dataType) {
          case 'selection':
            return (
              <FieldGroupSelectionForm
                title={currentForm.title}
                fieldGroup={fieldGroup}
                onSubmit={(response: string, id: string) => this.props.onSubmitAnswer(response, id)}
                savedAnswers={this.props.savedAnswers}
                onContinue={() => this.onContinue(currentForm)}
              />
            );
          case 'multiple_selections':
            return (
              <FieldGroupMultipleSelectionForm
                title={currentForm.title}
                fieldGroup={fieldGroup}
                onSubmit={(response: Array<string>, id: string) =>
                  this.props.onSubmitAnswer(response, id)
                }
                onContinue={() => this.onContinue(currentForm)}
                savedAnswers={this.props.savedAnswers}
              />
            );
          case 'strings':
            return (
              <FieldGroupStringForm
                title={currentForm.title}
                fieldGroup={fieldGroup}
                onSubmit={(response: string, id: string) => this.props.onSubmitAnswer(response, id)}
                onContinue={() => this.onContinue(currentForm)}
                savedAnswers={this.props.savedAnswers}
              />
            );
          case 'boolean_pair':
            return (
              <FieldGroupBooleanPairForm
                fieldGroup={fieldGroup}
                onSubmit={(response: string, id: string) => this.props.onSubmitAnswer(response, id)}
                onContinue={() => this.onContinue(currentForm)}
                savedAnswers={this.props.savedAnswers}
              />
            );
          default:
            // NOTE: validateDataTypes function should report invalid data types, nothing to render here
            return null;
        }

      default:
        return <Subheader text={'This type of form has not been implemented yet'} />;
    }
  }

  render() {
    return (
      <Container>
        <FormsContainer>
          {this.renderAllForms()}
          {this.isLastSection() && (
            <RoundedButton text="Submit" onClick={this.props.onSubmitForm} />
          )}
          <ControlButtons>
            <div>
              {!this.isFirstSection() && (
                <PreviousButton onClick={this.props.showPreviousSection}>Previous</PreviousButton>
              )}
            </div>
            <div>
              {!this.isLastSection() && (
                <NextButton onClick={this.props.showNextSection}>Next</NextButton>
              )}
            </div>
          </ControlButtons>
          <MarginBottom height={this.props.topOffset} />
        </FormsContainer>
      </Container>
    );
  }
}
