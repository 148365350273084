import styled from 'styled-components';

export const Section = styled.section`
  border-radius: 8px;
  background-color: ${({ theme }) => theme.colors.yellow.lightestYellow};
  padding: 48px;
  @media ${({ theme }) => theme.queries.mobile} {
    padding: 29px;
  }
  > h4 {
    display: block;
    text-align: center;
    margin-bottom: 48px;
    @media ${({ theme }) => theme.queries.mobile} {
      margin-bottom: 24px;
    }
  }
`;
