import React from 'react';

import { Patient, utils } from 'kb-shared';
import { filterAppointmentsByStartTime } from 'screens/Appointments/Appointments.utils';

import { AppointmentsWidget } from '../AppointmentsWidget/AppointmentsWidget';
import { CustomWidgets } from '../CustomWidgets/CustomWidgets';
import { JourneyResponse, PatientAppointments } from '../Dashboard.types';
import { hasMembership, hasMembershipDescription } from '../Dashboard.utils';
import { EmbryoIncWidget } from '../EmployerWidgets/EmbryoIncWidget/EmbryoIncWidget';
import { SaicWidget } from '../EmployerWidgets/SaicWidget/SaicWidget';
import { FertilityCalculator } from '../FertilityCalculator/FertilityCalculator';
import { JourneyWidget } from '../JourneyWidget/JourneyWidget';
import { ActivateBenefitsWidget } from '../MyBenefitsWidgets/ActivateBenefitsWidget/ActivateBenefitsWidget';
import { BenefitsWidget } from '../MyBenefitsWidgets/BenefitsWidget/BenefitsWidget';
import { PeriodTracker } from '../PeriodTracker/PeriodTracker';
import { PregnancyCalculatorWidget } from '../PregnancyCalculatorWidget/PregnancyCalculatorWidget';
import { ProfileWidget } from '../ProfileWidget/ProfileWidget';
import { Results } from '../Results/Results';
import { SupportGroupWidget } from '../SupportGroupWidget/SupportGroupWidget';
import { isPatientFemale } from './Widgets.utils';

const { isAMH } = utils;

export function Widgets({
  patient,
  isViosPatient,
  journeyData,
  appointmentsData,
  refetchAppointments
}: {
  patient: Patient;
  isViosPatient: boolean | undefined;
  journeyData: JourneyResponse | undefined;
  appointmentsData: PatientAppointments | undefined;
  refetchAppointments: Function;
}) {
  const hasScheduledAMH = appointmentsData?.appointments.some(a => isAMH(a)) || false;
  const upcomingAppointments = filterAppointmentsByStartTime(appointmentsData);
  const patientIsFemale = isPatientFemale(patient);

  return (
    <>
      <SaicWidget patient={patient} />
      <EmbryoIncWidget patient={patient} />
      <AppointmentsWidget
        isViosPatient={isViosPatient}
        upcomingAppointments={upcomingAppointments}
        refetch={refetchAppointments}
      />
      <BenefitsWidget
        benefitsDescription={patient.patientMembership?.membership?.description}
        hasEmployerBenefitsDescription={hasMembershipDescription(patient)}
      />
      <JourneyWidget journeyData={journeyData} isViosPatient={isViosPatient} />
      <Results hasScheduledAMH={hasScheduledAMH} />
      <FertilityCalculator patientIsFemale={patientIsFemale} />
      <PeriodTracker patientIsFemale={patientIsFemale} />
      <PregnancyCalculatorWidget patientIsFemale={patientIsFemale} />
      <ProfileWidget patient={patient} />
      <SupportGroupWidget />
      <ActivateBenefitsWidget hasEmployerMembership={hasMembership(patient)} />
      <CustomWidgets />
    </>
  );
}
