import React, { MouseEvent } from 'react';

import { locationDark } from 'assets/icons';
import { PartnerClinicDetails, themes } from 'kb-shared';
import styled from 'styled-components';

interface Props {
  href?: string;
  partnerClinicDetails: PartnerClinicDetails;
  onClick?: (evt: MouseEvent<HTMLDivElement>) => void;
}

interface CardContainerProps {
  onClick?: (evt: MouseEvent<HTMLDivElement>) => void;
}

const PartnerClinicCard = (props: Props) => {
  const {
    onClick,
    partnerClinicDetails: { name, address, city, tier, state, distance }
  } = props;

  // @ts-ignore
  const isSignature = tier => tier === 'Signature';

  return (
    <CardContainer onClick={onClick}>
      <CardTitle>{name}</CardTitle>
      <AddressText>
        {address} {city}, {state}
      </AddressText>
      <Text>
        {isSignature(tier) ? (
          <SignatureTag>Signature</SignatureTag>
        ) : (
          <DefaultTag>Partner Clinic</DefaultTag>
        )}
      </Text>
      <Text>
        <PinIcon src={locationDark} alt="Location Pin" />
        {Math.round(distance)} miles away
      </Text>
    </CardContainer>
  );
};

const CardContainer = styled.div`
  display: flex;
  min-width: 300px;
  width: 30%;
  height: auto;
  min-height: 200px;
  margin: 10px;
  border-radius: 5px;
  border: 2px solid ${themes.deprecatedColors.gray};
  flex-direction: column;
  justify-content: space-between;
  padding: 10px;
  @media screen and (max-width: ${themes.breakpoints.tabletLandscape}) {
    width: 45%;
  }
  @media screen and (max-width: ${themes.breakpoints.largePhonePortrait}) {
    width: 90%;
  }
  ${(props: CardContainerProps) =>
    props.onClick
      ? `
      cursor: pointer;
      &:hover {
        border-color: ${themes.colors.yellow.primary};
      }`
      : ''}
`;

const CardTitle = styled.div`
  font-size: 20px;
  color: ${themes.colors.neutral.primaryNavy};
`;

const AddressText = styled.div`
  z-index: 1;

  font-size: 16px;
  color: ${themes.colors.neutral.primaryNavy};
`;

const Text = styled.div`
  font-size: 14px;
  color: ${themes.colors.neutral.primaryNavy};
`;

const DefaultTag = styled.span`
  padding: 2px;
  background-color: ${themes.colors.semantic.lightPink};
  margin-left: 5px;
  text-transform: uppercase;
`;

const SignatureTag = styled(DefaultTag)`
  background-color: ${themes.colors.yellow.primary};
`;

const PinIcon = styled.img`
  height: 20px;
  margin: 0 5px -5px 0;
`;

export default PartnerClinicCard;
