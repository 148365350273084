import { useQuery, gql } from '@apollo/client';

import { MedicationsInstructions, PatientToDo } from 'kb-shared';
import { NEXT_INSTRUCTIONS } from 'kb-shared/graphql/queries';
import { BugTracker } from 'kb-shared/utilities/bugTracker';

export const useNextInstructions = () => {
  const { data, error, loading, refetch } = useQuery<MedicationsInstructions>(NEXT_INSTRUCTIONS, {
    onError: error => BugTracker.notify(error, 'Could not fetch next instructions')
  });

  return {
    refetch,
    data,
    error,
    loading
  };
};

type ConsentTodo = Pick<PatientToDo, 'id' | 'category' | 'title'>;

export const useConsentTodos = () => {
  const { data, loading } = useQuery<{ patientTodos: Array<ConsentTodo> }>(
    gql`
      query patientTodosMetadata {
        patientTodos {
          id
          title: name
          category
        }
      }
    `,
    {
      onError: error => BugTracker.notify(error, 'Could not fetch patient todos metadata')
    }
  );

  const patientConsentTodos = data?.patientTodos.filter(
    (todo: ConsentTodo) => todo.category === 'consent'
  );

  return {
    data: patientConsentTodos ?? [],
    loading
  };
};
