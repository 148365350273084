import React from 'react';

import SubstepWrapper from '../../SubstepWrapper';
import { ButtonList, VideoButton } from '../styled-components';
import { Description, Title } from './styled-components';

const ReturnOBAppointment = () => (
  <>
    <Title>Return OB Appointment</Title>
    <Description>
      This appointment includes a visit with your provider, an ultrasound, Tdap vaccine & cervix
      check.
    </Description>
    <Description>
      <strong>Tdap vaccine:</strong> One dose of Tdap vaccine is recommended during each pregnancy
      to protect your newborn from whooping cough (pertussis) regardless of when you had your last
      Tdap or tetanus-diphtheria (Td) vaccination.
    </Description>
    <Description>
      <strong>Cervix Check:</strong> Your provider will check the dilation of your cervix. The
      cervix dilates during labor and some women can start to dilate days or weeks before delivery.
    </Description>
  </>
);

const NutritionAppointment = () => (
  <>
    <Title>Nutrition Appointment</Title>
    <Description>
      This is your 4th individualized nutrition visit. Good diet & nutrition are essential for a
      healthy mom & baby. The team will help you schedule your appointment.
    </Description>
  </>
);

const MedicalFitnessAppointment = () => (
  <>
    <Title>Medical Fitness Appointment</Title>
    <Description>
      This is your 4th individualized physical fitness visit. Physical exercise can help with some
      common discomforts of pregnancy and even help prepare your body for labor and delivery. The
      team will help you schedule your appointment.
    </Description>
  </>
);

const VirtualMentalHealthSession = () => (
  <>
    <Title>Virtual mental health session</Title>
    <Description>
      Mental and emotional health is so important for both you and your developing baby. Our mindset
      program specializes in pregnancy mental health and preparing for childbirth & motherhood. The
      team will help you schedule your appointment.
    </Description>
  </>
);

const ECVHospital = () => (
  <>
    <Title>ECV at hospital (if baby is breech)</Title>
    <Description>
      ECV, External Cephalic Version, is a procedure typically done with regional anesthesia to help
      turn your breech baby to head down. Once your baby is head down, you can try to have a vaginal
      delivery.
    </Description>
    <ButtonList as="div">
      <VideoButton
        label="ECV"
        onClick={() => window.open('https://www.youtube.com/watch?v=qT2jBxVoOQc')}
      />
    </ButtonList>
  </>
);

const SUBSTEPS = [
  { component: <ReturnOBAppointment /> },
  { component: <NutritionAppointment /> },
  { component: <MedicalFitnessAppointment /> },
  { component: <VirtualMentalHealthSession /> },
  { component: <ECVHospital /> }
];

const ObWeek37Substeps = () => <SubstepWrapper substeps={SUBSTEPS} />;

export default ObWeek37Substeps;
