import React from 'react';
import { useHistory } from 'react-router-dom';

import { themes } from 'kb-shared';
import styled from 'styled-components';

import { CardButtonBase, typography } from '../../../styled';
import medications from '../medications';
import { MedicationInstruction } from '../medications';

const {
  sans: { H3 },
  spans: { Bold }
} = typography;
const { colors, breakpoints } = themes;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 10px;
  width: 100%;
`;

const Card = styled(CardButtonBase)`
  align-items: center;
  flex-direction: column;
  max-width: 120px;
  margin-right: 10px;
  padding: 0;

  &:last-of-type {
    margin-right: 0;
  }

  @media (max-width: ${breakpoints.largePhonePortrait}) {
    max-width: 32%;
    margin-right: 2%;
  }
`;

const MedicationImage = styled.img`
  object-fit: cover;
  max-width: 100%;
  padding: 0;
  vertical-align: bottom;
`;

interface SelectedProps {
  selected?: boolean;
}

const DrugTitle = styled(H3)`
  background: ${({ selected }: SelectedProps) =>
    selected ? colors.yellow.primary : colors.yellow.lightestYellow};
  margin: 0;
  padding: 10px 0px;
  width: 100%;
`;

type Props = {
  viewingMedication: MedicationInstruction;
};

const OtherInstructionsRow = (props: Props) => {
  const { viewingMedication } = props;
  const history = useHistory();

  return (
    <Row>
      {medications.map((item, i) => {
        const { id, imageSrc, displayName } = item;
        const selected = viewingMedication.id === id;
        return (
          <Card
            key={`other-instruction-row-${displayName}`}
            onClick={() => {
              history.replace(
                `/medication-instruction?med_index=${i}&medication_name=${displayName}`
              );
              // @ts-ignore
              window.location.reload();
            }}
          >
            <MedicationImage src={imageSrc} alt={displayName} />
            <DrugTitle selected={selected}>
              <Bold>{displayName}</Bold>
            </DrugTitle>
          </Card>
        );
      })}
    </Row>
  );
};

export default OtherInstructionsRow;
