import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';

import { Loader } from 'components/Loader/Loader';
import { Button } from 'components/v2/Buttons/Button';
import { CountrySelectField, SelectField } from 'components/v2/Form';
import { TextField } from 'components/v2/Form/TextField/TextField';
import { Option as SelectInputOption } from 'components/v2/Inputs/SelectInput';
import { BugTracker } from 'kb-shared/utilities/bugTracker';
import { COUNTRIES_OPTIONS, US_COUNTRY_OPTION } from 'kb-shared/utilities/countries';
import states from 'kb-shared/utilities/states';
import { getProceedButtonProps } from 'screens/InterstitialModal/InterstitialModal.utils';
import { useContactInfoFieldsMutations } from 'screens/MyProfile/components/ContactInfoFields/ContactInfoFields.graphql';
import { showErrorToast } from 'utilities/notificationUtils';
import { isUS } from 'utilities/userUtil';

import kindbody from '../../assets/logos/kindbody.svg';
import Modal from '../../components/Modal';
import {
  Content,
  Logo,
  Header,
  Description,
  ButtonContainer,
  ButtonStyled,
  SelectContainer
} from './PatientStateModal.styled';

export const PatientStateModal = ({ open }: { open: boolean }) => {
  const { updateAddress } = useContactInfoFieldsMutations();

  const history = useHistory();
  const [countrySelected, setCountrySelected] = useState<SelectInputOption>(US_COUNTRY_OPTION);
  const [stateSelected, setStateSelected] = useState<{ label: string; value: string }>();
  const [state, setState] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);

  const isDisabled = (country: string, state: string): boolean => {
    const isUSPatient = isUS(country);
    const isEmptyState = !state;

    if (isUSPatient && isEmptyState) {
      return true;
    }

    if (!isUSPatient && isEmptyState) {
      return false;
    }

    return false;
  };

  const onClick = async () => {
    try {
      setLoading(true);

      await updateAddress({
        variables: {
          country: countrySelected.value,
          state: stateSelected?.value ?? state
        }
      });

      history.push(pathname);
    } catch (error) {
      setLoading(false);
      BugTracker.notify(error, 'PatientStateModal');
      showErrorToast("We're sorry, something went wrong. Please try again.");
    }
  };

  const onChange = (option: SelectInputOption) => {
    setCountrySelected(option);
    setStateSelected(undefined);
    setState('');
  };

  const { pathname, text } = getProceedButtonProps();

  return (
    <Modal open={open}>
      <Content>
        <Logo src={kindbody} alt="kindbody" />
        <Header>Finish setting up your Patient Portal!</Header>
        <Description>
          We noticed that you are missing a home state on your profile. Your location will help us
          direct you to the right resources, and ensures that you book with a provider who is
          licensed in your state.
        </Description>
        <SelectContainer>
          <CountrySelectField<SelectInputOption>
            id="select-country"
            label="Select Your Country"
            options={COUNTRIES_OPTIONS}
            placeholder="Select country ..."
            searchPlaceholder="Search for a country"
            selected={countrySelected}
            onChange={onChange}
          />

          {isUS(countrySelected.value) ? (
            <SelectField
              id="select-state"
              label="Select your State"
              placeholder="Select state ..."
              searchPlaceholder="Search for a state"
              searchable
              selected={stateSelected}
              options={states}
              onChange={option => setStateSelected(option)}
            />
          ) : (
            <TextField
              id="input-patient-state"
              placeholder="Enter your state"
              type="text"
              label="State"
              value={state}
              onChange={e => setState(e.currentTarget.value)}
            />
          )}
        </SelectContainer>

        <ButtonContainer>
          <ButtonStyled
            onClick={onClick}
            disabled={isDisabled(countrySelected?.value, stateSelected?.value || state)}
          >
            {loading ? <Loader height={30} width={30} /> : text}
          </ButtonStyled>
          <Button
            fullWidth
            label="LOG OUT"
            category="secondary"
            size="lg"
            onClick={() => {
              history.push('/logout');
            }}
          />
        </ButtonContainer>
      </Content>
    </Modal>
  );
};
