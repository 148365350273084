import { Button } from 'components/v2/Buttons/Button';
import styled from 'styled-components';

import { DatesInfo } from './components/DatesInfo';
import { LineItemsTable } from './components/LineItemsTable';
import { PayeeInfo } from './components/PayeeInfo';
import { PayerInfo } from './components/PayerInfo';

export const Container = styled.div`
  ${({ theme }) => theme.grid.pageContainer};
  width: 100%;
  max-width: 700px;
  margin-top: 100px;
  display: flex;
  flex-direction: column;

  @media ${({ theme }) => theme.queries.minTablet} {
    margin-top: 90px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto;
    grid-template-areas:
      'header header'
      'balance balance'
      'payee payee'
      'payer dates'
      'lineitems lineitems'
      'back back';
  }
`;

export const StyledPayeeInfo = styled(PayeeInfo)`
  grid-area: payee;
`;

export const StyledPayerInfo = styled(PayerInfo)`
  grid-area: payer;
`;

export const StyledDatesInfo = styled(DatesInfo)`
  grid-area: dates;
`;

export const StyledLineItemsTable = styled(LineItemsTable)`
  margin-top: 24px;
  grid-area: lineitems;
`;

export const Header = styled.div`
  grid-area: header;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
  gap: 24px;
  width: 100%;

  & > button {
    flex-shrink: 0;
  }

  & > h1 {
    flex-grow: 1;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

export const MobileBackButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 48px;
  height: 48px;
  svg {
    color: ${({ theme }) => theme.colors.neutral.lighterNavy};
    width: 24px;
    height: 24px;
  }

  @media ${({ theme }) => theme.queries.minTablet} {
    display: none;
  }
`;

export const DesktopBackButton = styled(Button)`
  display: none;

  @media ${({ theme }) => theme.queries.minTablet} {
    display: flex;
  }
`;
