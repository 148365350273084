import React from 'react';

import { PartnerInvite } from 'screens/InterstitialModal/InterstitialModal.types';
import { formatDate } from 'utilities/formatDate';

import {
  ListItemContainer,
  ItemInfoContainer,
  LabelContainer,
  Status,
  MobileDOB,
  Name,
  Email,
  EmailText,
  DOB,
  Label,
  Content,
  StyledHeading
} from './PartnerListItem.styled';

export const PartnerListItem = ({ invite }: { invite: PartnerInvite }) => {
  return (
    <ListItemContainer>
      <ItemInfoContainer>
        <Name>
          <StyledHeading tag="span" styledAs="h4">
            {invite.firstName + ' ' + invite.lastName}
          </StyledHeading>
        </Name>

        <MobileDOB>
          <StyledHeading tag="span" styledAs="h5">
            ({formatDate(invite.birthDate)})
          </StyledHeading>
        </MobileDOB>

        <Email>
          <LabelContainer>
            <Label>
              <StyledHeading tag="span" styledAs="h6">
                Email
              </StyledHeading>
            </Label>
            <Content>
              <StyledHeading tag="span" styledAs="h5">
                <EmailText>{invite.email}</EmailText>
              </StyledHeading>
            </Content>
          </LabelContainer>
        </Email>

        <DOB>
          <LabelContainer>
            <Label>
              <StyledHeading tag="span" styledAs="h6">
                DOB
              </StyledHeading>
            </Label>
            <Content>
              <StyledHeading tag="span" styledAs="h5">
                {formatDate(invite.birthDate)}
              </StyledHeading>
            </Content>
          </LabelContainer>
        </DOB>
        <Status>
          <StyledHeading tag="span" styledAs="h5" bg="inherit">
            {formatWordsToCamelCase(invite.status)}
          </StyledHeading>
        </Status>
      </ItemInfoContainer>
    </ListItemContainer>
  );
};

const formatWordsToCamelCase = (string: string) =>
  string.replace(/\w\S*/g, (w: string) => w.replace(/^\w/, c => c.toUpperCase()));
