import React, { Component } from 'react';

import { themes } from 'kb-shared';
import styled from 'styled-components';

import { ResultColumns } from './DiagnosticResults/DiagnosticResults.types';

type Props = {
  selected: ResultColumns;
  onSelect: (selection: ResultColumns) => void;
};
type State = {};

export default class Popover extends Component<Props, State> {
  render() {
    const selected = this.props.selected;
    return (
      <Container>
        <Title>Select what to display</Title>
        <Option
          selected={selected === ResultColumns.YOUR_RESULT}
          onClick={() => this.props.onSelect(ResultColumns.YOUR_RESULT)}
        >
          <OptionText>Your Results</OptionText>
        </Option>
        <Option
          selected={selected === ResultColumns.NORMAL_RANGE}
          onClick={() => this.props.onSelect(ResultColumns.NORMAL_RANGE)}
        >
          <OptionText>Reference Range</OptionText>
        </Option>
      </Container>
    );
  }
}

const Container = styled.div`
  width: 200px;
  background-color: white;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.2);
`;

interface OptionProps {
  selected?: boolean;
}

const Option = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  min-height: 67px;
  background-color: ${(props: OptionProps) =>
    props.selected ? themes.colors.yellow.primary : themes.colors.yellow.lighterYellow};
  padding-left: 10px;
  padding-right: 10px;
`;

const OptionText = styled.div`
  color: ${themes.colors.neutral.primaryNavy};
  font-size: 16px;
`;

const Title = styled.div`
  display: flex;
  align-items: center;

  min-height: 44px;
  background-color: white;
  padding: 10px;
`;
