import { gql } from '@apollo/client';

export const CREATE_COMPREHENSION_DECISION = gql`
  mutation createPatientComprehensionResponse(
    $patientConsentId: Int!
    $comprehensionQuestionId: Int!
    $patientResponse: String!
  ) {
    createPatientComprehensionResponse(
      patientConsentId: $patientConsentId
      comprehensionQuestionId: $comprehensionQuestionId
      patientResponse: $patientResponse
    ) {
      patientComprehensionResponse {
        id
      }
    }
  }
`;
