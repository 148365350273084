import { useEffect, RefObject } from 'react';

export const useRelativeClickTracker = (
  ref: RefObject<HTMLElement>,
  onClick: (param: boolean) => void
): void => {
  useEffect(() => {
    const handleClick = (event: MouseEvent) => {
      const containsTarget = !!(ref.current && ref.current.contains(event.target as Node));
      onClick(containsTarget);
    };
    const elementRef = ref.current;

    if (elementRef) document.addEventListener('click', handleClick);

    return () => {
      if (elementRef) document.removeEventListener('click', handleClick);
    };
  }, [ref, onClick]);
};
