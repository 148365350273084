/**
 * This is a *very* important workaround for AWS Cognito.
 * Cognito treats username/emails as case sensitive. This is obviously
 * problematic as user's don't have an expectation that their user name is
 * case sensitive. As a temporary workaround on the client, we are lowercasing all
 * email addresses on signup and login. In the future, we should consider a more permanant
 * solution that lives on the server side.
 *
 */
export function sanitizeUsername(username: string): string {
  return username.toLowerCase().replace(/\s+/g, '');
}
