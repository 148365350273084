import React, { useEffect } from 'react';

import { useDropDownOptions } from 'hooks/useDropDownOptions/useDropDownOptions';
import { BugTracker } from 'kb-shared/utilities/bugTracker';

import { SelectInput } from '../SelectInput';
import { PreferredTimeZoneSelectProps } from './PreferredTimezoneSelect.types';
import {
  getSelectedOptionForGuessedTimeZone,
  getSelectedTimeZoneOption
} from './PreferredTimezoneSelect.utils';

export function PreferredTimezoneSelect({
  id,
  labelId,
  dropdownId,
  status = 'default',
  selected,
  placeholder,
  searchable,
  searchPlaceholder,
  onChange,
  onOptionsLoaded
}: PreferredTimeZoneSelectProps) {
  const {
    options: allSupportedTimeZones,
    loading: loadingTimeZones,
    error: errorAllSupportedTimezones
  } = useDropDownOptions({
    key: 'time_zones',
    sort: true
  });
  const {
    options: ianaTimeZoneMappings,
    loading: loadingIANA,
    error: errorIanaTimezoneMappings
  } = useDropDownOptions({
    key: 'iana_time_zones',
    skip: Boolean(selected)
  });

  const loading = loadingTimeZones || loadingIANA;
  const resourcesLoaded =
    // eslint-disable-next-line no-self-compare
    (allSupportedTimeZones?.length ?? 0 > 0) &&
    // eslint-disable-next-line no-self-compare
    (Boolean(selected) || (ianaTimeZoneMappings?.length ?? 0 > 0));
  const errorOnResourceLoad = errorAllSupportedTimezones || errorIanaTimezoneMappings;

  useEffect(() => {
    if (!ianaTimeZoneMappings) return;
    const timeZoneToSave = getSelectedOptionForGuessedTimeZone(
      allSupportedTimeZones,
      ianaTimeZoneMappings
    );
    if (timeZoneToSave) onChange(timeZoneToSave);
  }, [allSupportedTimeZones, ianaTimeZoneMappings, onChange]);

  useEffect(() => {
    if (loading) return;
    if (errorOnResourceLoad) {
      onOptionsLoaded?.(false);
      BugTracker.notify(errorOnResourceLoad, 'Failed to load preferred time zone options');
    }
    if (resourcesLoaded) {
      onOptionsLoaded?.(true);
    } else {
      onOptionsLoaded?.(false);
    }
  }, [resourcesLoaded, errorOnResourceLoad, onOptionsLoaded, loading]);

  if (loading) return null;

  const options = allSupportedTimeZones ?? [];

  return (
    <SelectInput
      id={id}
      labelId={labelId}
      dropdownId={dropdownId}
      status={status}
      selected={getSelectedTimeZoneOption(allSupportedTimeZones, selected, ianaTimeZoneMappings)}
      onChange={onChange}
      placeholder={placeholder}
      searchPlaceholder={searchPlaceholder}
      searchable={searchable}
      options={options}
    />
  );
}
