import { Heading as OriginalHeading } from 'components/v2/Typography';
import styled from 'styled-components';

export const Wrapper = styled.div<{ $topMargin: string }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: ${({ $topMargin }) => $topMargin};
  padding-left: 24px;
  padding-right: 24px;
  max-width: 400px;
  margin: 32px auto;

  @media (min-width: ${({ theme }) => theme.breakpoints.tabletPortrait}) {
    max-width: 480px;
  }
`;

export const Logo = styled.a`
  display: none;
  width: 100%;
  justify-content: flex-start;
  margin-bottom: 16px;

  img {
    height: 20px;
    width: auto;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.tabletPortrait}) {
    display: flex;
  }
`;

export const Heading = styled(OriginalHeading)`
  position: relative;
  text-align: left;
  width: 100%;
  padding: 0 16px;
  margin-top: 0;

  &::before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    max-width: 100%;
    height: 80px;
    background: ${({ theme }) => theme.colors.yellow.primary};
    z-index: -1;
    transform: rotate(-4deg);
    flex-shrink: 0;

    @media (min-width: ${({ theme }) => theme.breakpoints.tabletPortrait}) {
      left: -16px;
    }
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.tabletPortrait}) {
    padding: 0;
  }
`;

export const Description = styled(OriginalHeading).attrs({ tag: 'div', styledAs: 'h5' })`
  padding: 0 16px;

  @media (min-width: ${({ theme }) => theme.breakpoints.tabletPortrait}) {
    padding: 0;
  }
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
  margin-top: 24px;

  @media (min-width: ${({ theme }) => theme.breakpoints.tabletPortrait}) {
    flex-direction: row;
  }
`;
