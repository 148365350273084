import React from 'react';

import { Alert } from 'components/v2/Alert/Alert';
import KBContacts from 'kb-shared/utilities/kindbody_contacts';

import { TextContainer } from '../UnappliedPayments/UnappliedPayments.styled';

export const UnappliedPaymentsError = () => (
  <TextContainer>
    <Alert
      type="error"
      title="Oops! We couldn't retrieve your unapplied payments at the moment"
      message={<>{UNAPPLIED_PAYMENTS_ERROR_MESSAGE}</>}
    />
  </TextContainer>
);

const UNAPPLIED_PAYMENTS_ERROR_MESSAGE = `Unapplied payments currently can't be displayed. Please reload the page or contact us at ${KBContacts.navigatorEmail}.`;
