import theme from 'kb-shared/theme';
import styled from 'styled-components';

const { colors, fontSizes } = theme;

export const AbsoluteCentered = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

export const AbsoluteFill = styled.div`
  bottom: 0;
  left: 0;
  right: 0;
  position: absolute;
  top: 0;
`;

export const FlexCentered = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
`;

export const FlexCenteredFill = styled(FlexCentered)`
  height: 100%;
  width: 100%;
`;

const SerifBase = `
  
  color: ${colors.neutral.primaryNavy};
`;

const SansBase = `
  ;
  color: ${colors.neutral.primaryNavy};
`;

export const cardBoxShadow = '0 5px 12px 0 rgba(0, 0, 0, 0.2)';
const selectedCardBoxShadow = '0 5px 12px 0 rgba(0, 0, 0, 0.35)';

interface SelectedProps {
  selected?: boolean;
}

export const CardButtonBase = styled.button`
  background: ${colors.white};
  box-shadow: ${({ selected }: SelectedProps) =>
    selected ? selectedCardBoxShadow : cardBoxShadow};
  border: none;
  cursor: pointer;
  display: flex;
  height: 100%;
  position: relative;
  transition: box-shadow 0.15s;
  width: 100%;
  z-index: 2;

  &:hover {
    box-shadow: ${selectedCardBoxShadow};
  }

  &:focus {
    outline: ${colors.yellow.primary} auto 5px;
  }

  &:active {
    outline: 0;
  }
`;

export const FullPageLoaderContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  width: 100%;
`;

export const typography = {
  serif: {
    page: {
      H1: styled.h1`
        ${SerifBase}
        ${fontSizes['48']}
      `
    },
    card: {
      H1: styled.h1`
        ${SerifBase}
        ${fontSizes['36']}
      `,
      H2: styled.h2`
        ${SerifBase}
        ${fontSizes['32']}
      `,
      H3: styled.h3`
        ${SerifBase}
        ${fontSizes['24']}
      `
    },
    copy: {
      Large: styled.p`
        ${SerifBase}
        ${fontSizes['20']}
      `
    }
  },
  sans: {
    H2: styled.h2`
      ${SansBase}
      ${fontSizes['24']}
    `,
    H3: styled.h3`
      ${SansBase}
      ${fontSizes['18']}
    `,
    copy: {
      Large: styled.p`
        ${SansBase}
        ${fontSizes['20']}
      `,
      Medium: styled.p`
        ${SansBase}
        ${fontSizes['18']}
      `,
      Small: styled.p`
        ${SansBase}
        ${fontSizes['16']}
      `,
      Nano: styled.p`
        ${SansBase}
        ${fontSizes['14']};
      `
    }
  },
  spans: {
    Bold: styled.span`
      font-weight: 800;
    `
  }
};
