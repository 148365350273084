import { Text } from 'components/v2/Typography';
import styled from 'styled-components';

export const AdditionalInfo = styled.div`
  margin-top: 16px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  line-height: 24px;
`;

export const BoldText = styled(Text)`
  font-weight: 700;
`;

export const AdditionalInfoHeader = styled(Text)`
  display: block;
  font-weight: 700;
  line-height: 24px;
  margin-bottom: 8px;
`;

export const ContactList = styled.ul`
  list-style: inside;
  line-height: 24px;

  a {
    text-decoration: underline;
  }
`;

export const ModalContent = styled.div`
  padding: 32px 48px 32px 48px;

  @media ${({ theme }) => theme.queries.minTablet} {
    padding: 20px;
    width: 100%;
  }
`;

export const ModalHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 16px;
`;

export const LoaderContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;
