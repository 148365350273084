import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: center;
  justify-content: center;
  background-color: white;
`;

export const ErrorText = styled.div`
  font-size: 18px;
  text-align: center;
  padding: 0px 24px 48px 24px;
  max-width: 646px;
`;

export const ButtonWrapper = styled.div`
  padding: 0px 48px 0px 48px;
`;
