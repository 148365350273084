import { analytics, EVENTS } from 'utilities/analytics';

const track = (properties: Record<string, any>) => {
  analytics.track(EVENTS.BOOKING_NAVIGATION, properties);
};

const getUrlSearchParams = (searchString: string) => {
  const search = new URLSearchParams(searchString);
  const paramKeysSorted = Array.from(search.keys())
    .sort()
    .join(',');
  const params = Object.fromEntries(search.entries());

  return {
    params,
    paramKeysSorted
  };
};

export const trackNavigation = (
  relativeUrl: string,
  searchString: string,
  appLoadedFromHere: boolean
) => {
  const { paramKeysSorted, params } = getUrlSearchParams(searchString);

  track({
    appLoadedFromHere,
    relativeUrl,
    paramKeysSorted,
    params
  });
};
