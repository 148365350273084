import { utils } from 'kb-shared';
import { createURLSearchParams } from 'utilities/urlUtils';

const { getIdToken } = utils;

export interface UploadFileErrorResponse {
  errors: string;
}
export interface UploadFileException {
  message: string;
}

export interface UploadFileSuccessResponse {
  file: string;
}

export type UploadFileResponse =
  | UploadFileSuccessResponse
  | UploadFileErrorResponse
  | UploadFileException;

interface FileRoute {
  upload: string;
  retrieve: string;
  delete: string;
  temporaryUpload: string;
}

const routes: FileRoute = {
  upload: `${process.env.REACT_APP_GENERATIONS_URL}/pf2/patient_files/upload`,
  retrieve: `${process.env.REACT_APP_GENERATIONS_URL}/pf2/patient_files/retrieve`,
  delete: `${process.env.REACT_APP_GENERATIONS_URL}/pf2/patient_files/delete`,
  temporaryUpload: `${process.env.REACT_APP_GENERATIONS_URL}/pf2/temporary_patient_files/upload`
};

export enum CATEGORY {
  PROFILE_PHOTO = 'profile_photo',
  INSURANCE_CARD_FRONT = 'insurance_card_front',
  INSURANCE_CARD_BACK = 'insurance_card_back',
  MESSAGE_ATTACHMENT = 'message_attachment',
  DOCUMENT = 'document',
  ID_FRONT = 'id_front'
}

export enum OTHER_ID_TYPES {
  MESSAGE_ID = 'message_id'
}

export const retrieveFile = async (category: CATEGORY): Promise<UploadFileResponse> => {
  const url = `${routes.retrieve}?category=${category}`;
  const idtoken = getIdToken();

  if (!idtoken) {
    throw Error('idtoken invalid while retrieving file');
  }

  return fetch(url, {
    method: 'GET',
    headers: { idtoken: idtoken }
  }).then(response => response.json());
};

interface FileToUpload {
  file: Blob | File;
  category: CATEGORY;
  otherId?: number;
  otherIdType?: OTHER_ID_TYPES;
  description?: string;
  fileName?: string;
  purpose?: string;
}

export const uploadFile = async (fileToUpload: FileToUpload): Promise<Response> => {
  const idtoken = getIdToken();
  if (!idtoken) {
    throw Error('idtoken invalid while uploading file');
  }

  const searchParameters = createURLSearchParams({
    category: fileToUpload.category,
    other_id: fileToUpload.otherId,
    other_id_type: fileToUpload.otherIdType,
    description: fileToUpload.description,
    purpose: fileToUpload.purpose
  });
  const url = `${routes.upload}?${searchParameters}`;

  const formData = new FormData();
  if (fileToUpload.fileName) {
    formData.append('temporary_file', fileToUpload.file, fileToUpload.fileName);
    formData.append('file', fileToUpload.file, fileToUpload.fileName);
  } else {
    formData.append('temporary_file', fileToUpload.file);
    formData.append('file', fileToUpload.file);
  }

  return fetch(url, {
    method: 'POST',
    body: formData,
    headers: { idtoken: idtoken }
  });
};

export const uploadTemporaryFile = async (fileToUpload: FileToUpload): Promise<Response> => {
  const idtoken = getIdToken();
  if (!idtoken) {
    throw Error('idtoken invalid while uploading file');
  }

  const searchParameters = createURLSearchParams({
    category: fileToUpload.category
  });
  const url = `${routes.temporaryUpload}?${searchParameters}`;

  const formData = new FormData();
  if (fileToUpload.fileName) {
    formData.append('temporary_file', fileToUpload.file, fileToUpload.fileName);
    formData.append('file', fileToUpload.file, fileToUpload.fileName);
  } else {
    formData.append('temporary_file', fileToUpload.file);
    formData.append('file', fileToUpload.file);
  }

  return fetch(url, {
    method: 'POST',
    body: formData,
    headers: { idtoken: idtoken }
  });
};

export const deleteFile = async (id: number, category: CATEGORY) => {
  category = category || CATEGORY.DOCUMENT;
  const idtoken = getIdToken();
  if (!idtoken) {
    throw Error('idtoken invalid while deleting file');
  }
  const searchParameters = createURLSearchParams({ category, id });
  const url = `${routes.delete}?${searchParameters}`;

  return fetch(url, {
    method: 'DELETE',
    headers: { idtoken: idtoken }
  });
};
