import moment from 'moment-timezone';
import React, { useCallback, useEffect, useState } from 'react';

import { BugTracker } from 'kb-shared/utilities/bugTracker';
import { List } from 'screens/Documents/components/List/List';
import styled from 'styled-components';

import { deleteFile } from '../../../api/pf2/files';
import { showErrorToast } from '../../../utilities/notificationUtils';
import { AttachmentInformation } from '../Documents.types';

const downloadFile = async (uuid: string, fileName: string) => {
  showErrorToast('File not found');
  BugTracker.notify(
    `Can't download file uuid=${uuid} and fileName=${fileName}`,
    'Download Old Patient File'
  );
};

type Props = {
  title?: string;
  items: AttachmentInformation[];
  isPatientAttachments?: boolean;
  onDelete?: () => void;
};

const AttachmentsList = ({ items, title, onDelete, isPatientAttachments }: Props) => {
  const [documents, setDocuments] = useState([]);

  const handleDelete = useCallback(
    async item => {
      try {
        await deleteFile(item.id, item.category);

        if (onDelete) onDelete();
      } catch (e) {
        BugTracker.notify(e, 'AttachmentsList handleDelete');
        showErrorToast("We're sorry, something unexpected has happened. Please try again.");
      }
    },
    [onDelete]
  );

  const onView = useCallback(
    (item: AttachmentInformation) => {
      const { id, fileName, uuid, presignedUrl } = item;

      if (!isPatientAttachments || id === -1) {
        return window.open(presignedUrl, '_blank');
      }

      if (isPatientAttachments && id !== -1 && uuid) {
        return downloadFile(uuid, fileName);
      }

      if (isPatientAttachments && id !== -1 && !uuid) {
        return window.open(presignedUrl, '_blank');
      }
    },
    [isPatientAttachments]
  );

  useEffect(() => {
    const newItems = items.map(item => {
      const { id, description, fileName, createdAt } = item;
      const formattedDate = createdAt ? moment(createdAt).format('MM/DD/YYYY') : null;

      return {
        id,
        name: fileName,
        description,
        date: formattedDate,
        onDelete: isPatientAttachments ? () => handleDelete(item) : undefined,
        onView: () => onView(item)
      };
    });

    // @ts-ignore
    setDocuments(newItems);
  }, [items, isPatientAttachments, handleDelete, onView]);

  return (
    <Container>
      <List title={title} items={documents} />
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  margin-bottom: 28px;
  display: grid;
  grid-gap: 2px;
`;

export default AttachmentsList;
