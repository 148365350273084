import { Heading, Text } from 'components/v2/Typography';
import styled, { css } from 'styled-components';

export const InsuranceCardConfirmation = styled.div<{ $isPrimaryInsurance: boolean }>`
  border-radius: 2px;
  border-radius: 8px;
  box-shadow: 0px 0px 0px 1px ${({ theme }) => theme.colors.neutral.lavenderLight},
    0px 1px 1px 0px rgba(67, 71, 146, 0.12);
  padding: 24px;
  margin-top: 20px;

  ${({ $isPrimaryInsurance }) =>
    $isPrimaryInsurance &&
    css`
      border: 1px solid ${({ theme }) => theme.colors.yellow.primary};
      box-shadow: 0px 0.5px 0.6px -0.25px rgba(241, 203, 10, 0.56),
        0px 0px 0px 4px rgba(241, 203, 10, 0.32);
    `};
`;

export const InsuranceCardConfirmationHeader = styled(Heading).attrs({ tag: 'h3' })`
  justify-content: space-between;
  gap: 8px;
`;

export const PayerCompanyName = styled.span`
  flex: 1;
`;

export const Badge = styled(Text).attrs({ size: 'sm' })<{ $isPrimaryInsurance: boolean }>`
  border-radius: 4px;
  background: ${({ theme }) => theme.colors.neutral.lavenderLight};
  display: flex;
  padding: 6px 16px;

  ${({ $isPrimaryInsurance }) =>
    $isPrimaryInsurance &&
    css`
      border: 1px solid ${({ theme }) => theme.colors.yellow.darkYellow};
      background: ${({ theme }) => theme.colors.yellow.lighterYellow};
    `};
`;

export const InsuranceDetailsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;

  @media ${({ theme }) => theme.queries.minTablet} {
    gap: 32px;
    flex-direction: row;
  }
`;

export const InsuranceDetailTitle = styled(Text).attrs({
  tag: 'span',
  size: 'xs',
  transform: 'uppercase',
  fontStyle: 'medium'
})``;

export const InsuranceDetailValue = styled(Text).attrs({ tag: 'p' })`
  margin-top: 8px;
`;
