import React from 'react';

import styled from 'styled-components';

import StepDetail from './StepDetail';
import { Description } from './StepDetail';

const FollowUpGenericDetail = () => {
  return (
    <StepDetail>
      <Description>
        You will receive a call with the results of your pregnancy test. The next steps will be
        discussed with you at this time.
      </Description>
      <NoMargin>As always, contact us with any questions!</NoMargin>
    </StepDetail>
  );
};

export default FollowUpGenericDetail;

const NoMargin = styled.p`
  margin: 0;
`;
