import { NullableString } from 'kb-shared';
import { Crop } from 'utilities/imageCropper';

export const getCropSettings = (image: NullableString) => {
  const settings: Crop = {
    aspect: 1 / 1,
    unit: '%',
    x: 25,
    y: 10,
    width: 50,
    height: 0
  };

  if (image) {
    const imageBlob = new Image();
    imageBlob.src = image || '';
    settings.height = (imageBlob.width / imageBlob.height) * 50;
  }

  return settings;
};

export const ALLOWED_FILES_DESCRIPTION = 'PDF, JPEG, JPG and PNG file types are allowed.';

export const ALLOWED_FILE_MAX_SIZE = 'The maximum file size is 5 MB.';

export const ALLOWED_PHOTO_FILES_DESCRIPTION = 'JPEG, JPG and PNG file types are allowed.';

export const ALLOWED_PHOTO_MAX_SIZE = 'The maximum file size is 2 MB.';
