import { Tab } from 'components/v2/DeprecatedTabs/DeprecatedTabs.styles';
import styled from 'styled-components';

export const Content = styled.div`
  width: 900px;
  @media ${({ theme }) => theme.queries.maxTablet} {
    width: 100%;
    padding: 24px;
    padding-top: 16px;
  }
  padding-left: 88px;
  padding-right: 88px;
  padding-top: 32px;
  padding-bottom: 32px;
  &:focus-visible {
    outline: none;
  }

  ${Tab} {
    min-width: fit-content;
  }
`;

export const ButtonContainer = styled.div`
  & > button:last-child {
    margin-top: 12px;
  }
`;

export const Header = styled.h1`
  font-weight: 800;
  margin-top: 16px;
  margin-bottom: 16px;
  color: ${({ theme }) => theme.colors.neutral.primaryNavy};
  font-size: 32px;
  line-height: 40px;

  @media ${({ theme }) => theme.queries.maxTablet} {
    margin: 0 0 12px 0;
    text-align: center;
  }
`;

export const Logo = styled.img`
  margin-top: 32px;
  margin-bottom: 16px;
  height: 24px;

  @media ${({ theme }) => theme.queries.maxTablet} {
    height: 16px;
    margin-top: 16px;
    width: 100%;
  }
`;
