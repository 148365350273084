import { Button as StyledButton } from 'components/v2/Buttons/Button/Button.styled';
import styled from 'styled-components';

export const Container = styled.div`
  align-items: center;
  background-color: ${({ theme }) => theme.colors.yellow.lightestYellow};
  border: 1px dashed ${({ theme }) => theme.colors.yellow.lightYellow};
  border-radius: 8px;
  padding: 72px 16px 128px 16px;
  text-align: center;

  > ${StyledButton} {
    margin-top: 24px;
  }
`;
