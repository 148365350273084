import React from 'react';

import StepDescription from './StepDescription';

const FollowUpEmbryoDetail = () => {
  return (
    <StepDescription
      description={
        <>
          <p>
            On the day of your retrieval before you go home, you&apos;ll be notified of the number
            of eggs retrieved. The next day, we will call you to let you know how many of these eggs
            have developed into embryos. We will continue to monitor your embryos for five to six
            days.
          </p>
          <p>
            On the sixth day, we will call you to let you know how many embryos were successfully
            frozen.
          </p>
          <p>
            When you get your next period, please schedule a post-op call so we can review how
            you&apos;re feeling and make sure you&apos;re feeling fully recovered.
          </p>
          <p>
            <strong>
              If you do not get a period in 2 weeks, please go ahead and schedule call the post-op
              call.
            </strong>
          </p>
        </>
      }
    />
  );
};

export default FollowUpEmbryoDetail;
