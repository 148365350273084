import React, { useState } from 'react';

import { Button } from 'components/v2/Buttons/Button';
import styled from 'styled-components';

import PostopInstructionsModal from './PostopInstructionsModal';
import StepDescription from './StepDescription';
import { Description } from './StepDetail';

const RetrievalDetail = () => {
  const [showModal, setShowModal] = useState(false);
  return (
    <>
      <StepDescription
        description={
          <>
            <FlexContainer>
              <Description>
                Once your provider determines you&apos;re ready, they&apos;ll instruct you to take a
                trigger shot. 35 hours later, we will retrieve your eggs.
              </Description>
              <ButtonStyled
                label="Review Post-Op Instructions"
                onClick={() => setShowModal(!showModal)}
              />
            </FlexContainer>
          </>
        }
      />
      <PostopInstructionsModal show={showModal} hide={() => setShowModal(false)} />
    </>
  );
};

export default RetrievalDetail;

const ButtonStyled = styled(Button)`
  min-width: fit-content;
  height: fit-content;
  margin-bottom: 20px;
`;

const FlexContainer = styled.div`
  display: flex;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.smallDesktop}) {
    flex-wrap: wrap;
  }
`;
