import { Text } from 'components/v2/Typography';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
`;

export const UploadContainer = styled.div`
  max-height: 320px;
  overflow: auto;
`;

export const UploadImageContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px 0;
  cursor: pointer;
  width: 100%;
  align-items: center;
`;

export const ClickableUploadContainer = styled.div`
  width: 100%;
`;

export const RenderedImage = styled.img`
  object-fit: contain;
  height: 240px;
  width: 320px;
`;

export const Description = styled(Text)`
  margin: 8px 0 0 0;
`;

export const HiddenInput = styled.input`
  display: none;
`;

export const DropContainer = styled.div`
  border-radius: 8px;
  border: 1px dashed ${({ theme }) => theme.colors.yellow.darkYellow};
  background: ${({ theme }) => theme.colors.yellow.lightestYellow};
  display: flex;
  height: 128px;
  padding: 16px 94px;

  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 0.5em;

  align-self: stretch;

  > svg {
    color: ${({ theme }) => theme.colors.yellow.darkYellow};
  }
`;

export const PlusIcon = styled.img`
  width: 36px;
  height: 36px;
  margin-right: 4px;
`;

export const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-top: 1.5em;

  > button:nth-child(1) {
    margin-right: 0.5em;
  }

  > button:nth-child(2) {
    margin-left: 8px;
  }
`;
