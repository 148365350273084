import React from 'react';

import { Heading, Number } from './StepCountHeading.styled';

export const StepCountHeading = ({
  currentStep,
  totalSteps
}: {
  currentStep: number;
  totalSteps: number;
}) => {
  return (
    <Heading>
      Step<Number> {currentStep} </Number>
      of
      <Number> {totalSteps}</Number>
    </Heading>
  );
};
