import styled from 'styled-components';

export const Consent = styled.div`
  justify-content: space-between;
  display: flex;
  flex-direction: row;
  border-bottom: 1px solid #abaeba;
  margin-top: 4px;
  min-height: 72px;
  @media ${({ theme }) => theme.queries.maxTablet} {
    align-items: center;
    margin-top: 0px;
  }

  .checkmark {
    margin-top: 12px;
    width: 24px;
    height: 24px;
    min-width: 24px;
    @media ${({ theme }) => theme.queries.maxTablet} {
      margin-top: 0;
    }
  }
`;

export const ConsentsSection = styled.div`
  margin-bottom: 16px;
`;
