import { useLazyQuery, useMutation } from '@apollo/client';
import React, { useEffect } from 'react';

import { XMark } from 'assets/icons/Icons';
import Modal from 'components/Modal';
import { Button } from 'components/v2/Buttons/Button';
import { LinkButton } from 'components/v2/Buttons/LinkButton/LinkButton';
import { Heading } from 'components/v2/Typography';
import { usePatient } from 'hooks';
import { BugTracker } from 'kb-shared/utilities/bugTracker';
import KBContacts from 'kb-shared/utilities/kindbody_contacts';
import { PATIENT_CONSENT_SUBMIT } from 'screens/InformedConsent/Components/ConsentAcceptAction/ConsentAcceptAction.graphql';
import {
  PatientConsentSubmitResponseData,
  PatientConsentSubmitVariables
} from 'screens/InformedConsent/Components/ConsentAcceptAction/ConsentAcceptAction.types';
import {
  LOAD_CONSENT_AND_SIGNED_CONSENTS,
  LOAD_CONSENT_HTML
} from 'screens/InformedConsent/InformedConsent.graphql';
import {
  ConsentAndAllSignedPatientConsentsResponse,
  SignedPatientConsent
} from 'screens/InformedConsent/InformedConsent.types';
import { YourConsent } from 'screens/InformedConsent/Steps/YourConsent/YourConsent';
import { showErrorToast } from 'utilities/notificationUtils';

import { Bottom, ModalContent, ModalHeader } from './ConsentModal.styles';

export const ConsentModal = ({
  title,
  open,
  close,
  id
}: {
  title: string;
  open: boolean;
  close: () => void;
  id: string;
}) => {
  const { isLoggedIn } = usePatient();
  const [loadConsent, { data, loading }] = useLazyQuery<ConsentAndAllSignedPatientConsentsResponse>(
    isLoggedIn ? LOAD_CONSENT_AND_SIGNED_CONSENTS : LOAD_CONSENT_HTML,
    {
      onError: error => BugTracker.notify(error, 'ConsentAndAllSignedConsentsFetch')
    }
  );
  const [acknowledgeConsent] = useMutation<
    PatientConsentSubmitResponseData,
    PatientConsentSubmitVariables
  >(PATIENT_CONSENT_SUBMIT, {
    onCompleted: () => close(),
    onError: error => {
      BugTracker.notify(error, 'FailedCreatingAndSigningConsent');
      showErrorToast(
        `Failed to acknowledge the consent. Please wait then try again, or contact ${KBContacts.navigatorEmail}`
      );
    }
  });

  useEffect(() => {
    if (!open) return;

    loadConsent({ variables: { consentId: parseInt(id) } });
  }, [open, loadConsent, id]);

  const onAcknowledgeConsent = () => {
    acknowledgeConsent({
      variables: {
        consentId: parseInt(id)
      }
    });
  };

  if (!open || loading || !data?.consent?.rawHtml) return null;

  const consentIsSigned = isConsentSigned(data.consent.versionUuid, data.patientConsents);

  return (
    <Modal label={title} open={open} onRequestClose={close} maxWidth="880px" mobileFullscreen>
      <ModalContent>
        <ModalHeader>
          <Heading noMargin tag="h4">
            {title}
          </Heading>
          <LinkButton leftIcon={<XMark type="solid" />} size="sm" onClick={close} />
        </ModalHeader>
        <YourConsent
          consentId={parseInt(id)}
          consentRawHtml={data.consent.rawHtml}
          hasMultipleSteps={false}
          pdf={null}
        />
        {isLoggedIn && !consentIsSigned && (
          <Bottom>
            <Button
              fullWidth
              label="I acknowledge"
              category="primary"
              size="lg"
              onClick={onAcknowledgeConsent}
            />
          </Bottom>
        )}
      </ModalContent>
    </Modal>
  );
};

const isConsentSigned = (
  versioUuid: string | null,
  signedConsents: SignedPatientConsent[] | undefined
) => {
  if (!versioUuid) return false;
  if (!signedConsents) return false;

  return Boolean(signedConsents.find(consent => consent.versionUuid === versioUuid));
};
