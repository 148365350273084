import React, { FunctionComponent } from 'react';

import { themes } from 'kb-shared';
import styled from 'styled-components';

interface SelectedProps {
  selected: boolean;
}

const Container = styled.div`
  display: flex;
  width: 100%;
  padding: 5px 0px;
  cursor: pointer;
`;

const CheckContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 25px;
  height: 25px;
  border: solid 2px ${themes.deprecatedColors.gray};
  background-color: white;
`;

const CheckText = styled.div`
  font-weight: bolder;
  font-size: 34px;
  color: ${themes.colors.neutral.primaryNavy};
  margin-top: -5px;
`;

const CheckBoxLabel = styled.div`
  margin-left: 20px;
  font-size: 18px;
  ${(props: SelectedProps) => {
    return props.selected ? 'font-weight: bold' : '';
  }};
`;

type Props = {
  onSelect: (value: string) => void;
  isSelected: (value: string) => boolean;
  value: any;
};

const CheckboxListItem: FunctionComponent<Props> = ({ isSelected, onSelect, value, children }) => {
  const selected = isSelected(value);

  return (
    <Container onClick={() => onSelect(value)}>
      <CheckContainer>
        <CheckText>{selected ? '✓' : ''}</CheckText>
      </CheckContainer>
      <CheckBoxLabel selected={selected}>{children}</CheckBoxLabel>
    </Container>
  );
};

export default CheckboxListItem;
