import { Patient } from 'kb-shared';
import { momentInTimeZoneAndFormat, stripTimeAndFormat } from 'kb-shared/utilities/momentHelpers';

import { DocumentFile } from './PatientSignedDocuments.types';

export const parseDate = (item: DocumentFile, patient?: Patient) => {
  const { date, presignedUrl } = item;
  if (presignedUrl) {
    // When consent is Kindbody upload (e.g. consent signed by patient using DocuSign),
    // date is in ISO8061 format (YYYY-MM-DDTHH:mm:ss)
    return stripTimeAndFormat(date, 'MM/DD/YYYY');
  } else {
    // When consent is standard Kindbody consent, date is in YYYY-MM-DD HH:mm:ss UTC format
    return momentInTimeZoneAndFormat(date, patient?.lab?.timeZone || 'UTC', 'MM/DD/YYYY');
  }
};
