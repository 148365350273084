import { RenderableItem } from '../types';

export const postOpInstructions: RenderableItem[] = [
  {
    name: 'Activity',
    body: [
      {
        type: 'copy',
        copy: 'No intercourse for the next 2 weeks.'
      },
      {
        type: 'copy',
        copy: 'We do not recommend high impact exercise.'
      },
      {
        type: 'strong',
        copy: 'Do NOT drive a car or operate heavy machinery for 24 hours.'
      }
    ]
  },
  {
    name: 'Diet',
    body: [
      {
        type: 'copy',
        copy: 'As tolerated, but avoid alcohol.'
      },
      {
        type: 'copy',
        copy:
          'Stay well hydrated, with electrolytes, fruit juices and V-8. Drink about 64 - 80 oz a day.'
      }
    ]
  },
  {
    name: 'Discomfort',
    body: [
      {
        type: 'copy',
        copy:
          'You were given two 500mg Tylenol after the retrieval. At home, continue to take 2 regular strength Tylenol every 6 hours as needed for pain. Additionally, you may also take 2 regular strength Ibuprofen (Advil or Motrin) every 8 hours.'
      }
    ]
  },
  {
    name: 'Bleeding',
    body: [
      {
        type: 'copy',
        copy:
          'Vaginal bleeding should be minimal for 1 - 2 days.If it is heavy, please call our office.'
      }
    ]
  },
  {
    name: 'Medications',
    body: [
      {
        type: 'strong',
        copy: 'Refer to any additional information given to you by your care team.'
      }
    ]
  },
  {
    name: 'Other Instructions',
    body: [
      {
        type: 'copy',
        copy: 'Continue any other medications that your physician prescribed to you.'
      },
      {
        type: 'strong',
        copy: 'Notify our office if any of the following symptoms occur:'
      },
      {
        type: 'list',
        items: [
          'Fever > 100.5° F',
          'Heavy bleeding',
          'Continuous pain',
          'Bloating and / or weight gain of more than 2 lbs in 2 consecutive days',
          'Nausea, vomiting, diarrhea and / or decreased urination',
          'Any other abnormal symptoms'
        ]
      }
    ]
  }
];
