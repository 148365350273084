import { useQuery } from '@apollo/client';
import React from 'react';

import { BugTracker } from 'kb-shared/utilities/bugTracker';

import { CustomWidget } from '../CustomWidget/CustomWidget';
import { CUSTOM_DASHBOARD_TILES_QUERY } from './CustomWidgets.graphql';
import { CustomTileArray, CustomTile } from './CustomWidgets.types';
import { showCustomTile } from './CustomWidgets.utils';

export const CustomWidgets = () => {
  const { data, loading } = useQuery<CustomTileArray>(CUSTOM_DASHBOARD_TILES_QUERY, {
    onError: error => BugTracker.notify(error, 'CustomDashboardTilesFetch')
  });

  const customDashboardTiles = data?.customDashboardTiles;
  const dataAvailable = !loading && customDashboardTiles?.length;

  if (!dataAvailable || !customDashboardTiles) return null;

  return <>{getCustomTiles(customDashboardTiles)}</>;
};

const getCustomTiles = (customTiles: CustomTile[]) => {
  return customTiles.filter(showCustomTile).map((tile, index) => {
    return (
      <React.Fragment key={index}>
        <CustomWidget customTile={tile} backgroundVariant={index % 4} />
      </React.Fragment>
    );
  });
};
