import theme from 'kb-shared/theme';
import styled from 'styled-components';

export const ToDoItemsContainer = styled.div`
  font-size: 16px;
  background: ${theme.colors.neutral.lavenderWhite};
`;

export const CounterContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 1.5em 1.5em 0 1.5em;

  @media ${({ theme }) => theme.queries.minDesktop} {
    padding: 1.5em 0;
  }
`;

export const CountContainer = styled.div`
  font-size: 18px;
  background-color: ${theme.colors.semantic.darkRed};
  border-radius: 8px;
  margin-right: 0.9em;
  padding: 0.2em 0.5em;

  @media ${({ theme }) => theme.queries.minDesktop} {
    padding: 0.2em 0.9em;
  }

  > * {
    color: ${theme.colors.white};
  }
`;

export const ItemsContainer = styled.div`
  padding: 1em;
  box-shadow: none;
  display: flex;
  flex-direction: column;
  gap: 1em;
  border-radius: 0;

  @media ${({ theme }) => theme.queries.minDesktop} {
    padding: 2em 3em;
    border-radius: 16px;
    box-shadow: ${theme.shadows.container.glowRed};
    background: ${theme.colors.white};
  }
`;

export const ItemsNamesContainer = styled.div`
  display: none;

  @media ${({ theme }) => theme.queries.minDesktop} {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
`;

export const DisplayedItemsNamesContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

export const AdditionalNames = styled.div`
  border-radius: 8px;
  height: 40px;
  width: 48px;
  background-color: ${theme.colors.white};
  box-shadow: ${theme.shadows.button.default};
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 6px;
`;

export const ItemContainer = styled.div<{ $active: boolean }>`
  max-width: 320px;
  @media screen and (max-width: ${theme.breakpoints.desktop}) {
    max-width: 300px;
  }
  @media screen and (max-width: 1320px) {
    max-width: 280px;
  }
  @media screen and (max-width: 1260px) {
    max-width: 230px;
  }

  padding: 0.5em 1em;
  border-radius: 8px;
  margin-right: 1em;
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;

  background-color: ${theme.colors.white};
  box-shadow: ${({ $active }) => ($active ? theme.shadows.container.glowRed : 'none')};

  border: 1px solid
    ${({ $active }) => ($active ? theme.colors.semantic.lightPink : theme.colors.neutral.lavender)};

  > span {
    color: ${({ $active }) =>
      $active ? theme.colors.neutral.primaryNavy : theme.colors.semantic.red};
  }
`;

export const ItemNumberContainer = styled.div<{ $active: boolean }>`
  margin-right: 0.5em;
  background-color: ${({ $active }) =>
    $active ? theme.colors.semantic.darkRed : theme.colors.semantic.red};
  border-radius: 4px;
  padding: 0.25em 0.5em;
  height: 28px;

  > * {
    color: ${theme.colors.white};
  }
`;

export const ItemTitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 90%;
`;

export const ItemTitle = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
`;

export const ContextMenuContainer = styled.div<{ $x: number; $y: number }>`
  left: ${({ $x }) => `${$x}px`};
  top: ${({ $y }) => `${$y}px`};
  display: none;
  position: absolute;
  background-color: ${theme.colors.white};
  padding: 12px;
  width: 285px;
  border-radius: 8px;
  box-shadow: ${theme.shadows.card.level3};
  z-index: 9999;
`;

export const ContextMenuItems = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const ContextMenuItem = styled.div<{ $active: boolean }>`
  padding: 12px 8px;
  display: flex;
  flex-direction: row;
  border-radius: 4px;
  background-color: ${({ $active }) =>
    $active ? theme.colors.yellow.lighterYellow : theme.colors.white};
  cursor: pointer;
`;

export const ContextMenuItemNumber = styled.div<{ $active: boolean }>`
  margin-right: 8px;
  padding: 0 8px;
  border-radius: 4px;
  background-color: ${({ $active }) =>
    $active ? theme.colors.yellow.primary : theme.colors.neutral.lavenderLight};
`;

export const ContextMenuItemTitle = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
`;
