import Honeybadger from '@honeybadger-io/js';
import { useState } from 'react';

import EpicDisneyConfig from '../config';

const useSubmitAuthorizationCode = () => {
  const [responseState, setResponseState] = useState<ResponseStateType>({
    idtoken: null,
    accessToken: null,
    patientFhirId: null,
    finished: false,
    error: null
  });
  const submitAuthorizationCode = async (authorizationCode: any): Promise<any> => {
    const params = {
      grant_type: 'authorization_code',
      code: authorizationCode,
      client_id: EpicDisneyConfig.appOrchardAppId,
      redirect_uri: EpicDisneyConfig.redirectBackToKindbodyUrl()
    };

    const formBody = [];
    for (const property in params) {
      const encodedKey = encodeURIComponent(property);
      // @ts-ignore
      const encodedValue = encodeURIComponent(params[property]);
      formBody.push(encodedKey + '=' + encodedValue);
    }
    const url = EpicDisneyConfig.epicUrl + '/oauth2/token';
    fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      },
      body: formBody.join('&')
    })
      .then(response => response.json())
      .then(data => {
        if (data.error) {
          // usually {error: "invalid_grant", error_description: null}
          setResponseState({
            idtoken: null,
            accessToken: null,
            patientFhirId: null,
            finished: true,
            error: data.error
          });
        } else {
          setResponseState({
            idtoken: data.id_token,
            accessToken: data.access_token,
            patientFhirId: data.patient,
            finished: true,
            error: null
          });
        }
      })
      .catch(error => Honeybadger.notify(error));
  };

  return { responseState, submitAuthorizationCode };
};

type ResponseStateType = {
  idtoken: any;
  accessToken: any;
  patientFhirId: any;
  finished: boolean;
  error: string | null;
};

export default useSubmitAuthorizationCode;
