import React, { useState } from 'react';

import { CreditCard as CreditCardIcon } from 'assets/icons/Icons';
import { Heading } from 'components/v2/Typography';
import { useCreditCards } from 'hooks/useCreditCards/useCreditCards';
import { CreditCard as CreditCardType } from 'hooks/useCreditCards/useCreditCards.types';

import { CardsContainer } from './PaymentOptions.styled';
import { AddCard } from './PaymentOptions/AddCard';
import { CreditCard } from './PaymentOptions/CreditCard';
import { NewCreditCardModal } from './PaymentOptions/NewCreditCardModal';
import { Container, Icon } from './shared.styled';

export const PaymentOptions = (): JSX.Element => {
  const { creditCards } = useCreditCards();

  const [newCreditCardModalOpen, setNewCreditCardModalOpen] = useState(false);

  const onAddNewPaymentOption = () => {
    setNewCreditCardModalOpen(true);
  };

  return (
    <Container>
      <Heading tag="h2" styledAs="h4">
        <Icon>
          <CreditCardIcon />
        </Icon>
        Payment Options
      </Heading>
      <CardsContainer>
        <AddCard label={'NEW PAYMENT OPTION'} onClick={onAddNewPaymentOption} />
        {creditCards.map((cc: CreditCardType) => (
          <CreditCard key={cc.stripeIdentifier} creditCard={cc} />
        ))}
      </CardsContainer>
      <NewCreditCardModal
        open={newCreditCardModalOpen}
        onClose={() => setNewCreditCardModalOpen(false)}
      />
    </Container>
  );
};
