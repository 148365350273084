import React from 'react';

import { Loader } from 'components/Loader/Loader';
import VirtualImage from 'kb-shared/images/virtual-image.png';

import NewLocationCard from '../../../components/NewLocationCard';
import { LocationContainer } from './LocationCards.styled';
import { Props } from './LocationCards.types';

export const LocationCards = ({
  clinics = [],
  loadingClinics,
  clinicsHasAppointments,
  selectedClinic,
  onSelectClinic,
  onSelectVirtual,
  showVirtual
}: Props) => {
  let clinicsCards: React.ReactNode[] = [];

  if (loadingClinics) return <Loader height={50} width={50} container />;

  if (clinicsHasAppointments) {
    clinicsCards = clinics.map(clinic => (
      <NewLocationCard
        key={clinic.id}
        name={clinic.name}
        address={clinic.address}
        imageUrl={clinic.imageUrl}
        selected={selectedClinic?.id === clinic.id}
        onSelect={() => onSelectClinic(clinic)}
      />
    ));
  }

  if (showVirtual) {
    clinicsCards = clinicsCards.concat([
      <NewLocationCard
        key="virtual"
        name="Virtual"
        address="Meet with your Kindbody doctor online"
        imageUrl={VirtualImage}
        selected={false}
        onSelect={onSelectVirtual}
      />
    ]);
  }

  return (
    <LocationContainer centerContent={clinicsCards.length <= 2}>{clinicsCards}</LocationContainer>
  );
};
