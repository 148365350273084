import React from 'react';
import Media from 'react-media';

import MobileBanner from 'components/MobileBanner';
import { themes } from 'kb-shared';
import { RoutesPath } from 'utilities/pageUrl';

import { EmployerBenefitText, Link } from './EmployerBenefitCTA.styled';

const signUpUrl = () =>
  `/create-account?step=create_account&target=${RoutesPath.ENTER_MEMBERSHIP_ACCESS_CODE}`;

export const EmployerBenefitCTA = () => (
  <Media query={{ minWidth: themes.breakpoints.raw.tabletLandscape }}>
    {desktop => {
      if (desktop) {
        return (
          <EmployerBenefitText>
            Does your employer have the Kindbody benefit?{' '}
            <Link href={signUpUrl()}>Sign Up to Activate</Link>
          </EmployerBenefitText>
        );
      }
      return (
        <MobileBanner>
          Does your employer have the Kindbody benefit?{' '}
          <Link href={signUpUrl()}>Sign Up to Activate</Link>
        </MobileBanner>
      );
    }}
  </Media>
);
