import { MATERNITY_BOOKLET_URL } from 'utilities/documents';

export const widgetActions = [
  {
    line1: 'Pregnancy prep introduction',
    linkUrl: 'https://kindbody.vids.io/videos/d39fdcb31e18e6c55a/pregnancy-prep-intro',
    linkText: 'See More'
  },
  {
    line1: 'Pregnancy prep first trimester',
    linkUrl: 'https://kindbody.vids.io/videos/ea9fdcb31918e0c163/pregnancy-prep-first-trimester',
    linkText: 'See More'
  },
  {
    line1: 'Pregnancy prep second trimester',
    linkUrl: 'https://kindbody.vids.io/videos/ea9fdcb31918eacb63/pregnancy-prep-second-trimester',
    linkText: 'See More'
  },
  {
    line1: 'Pregnancy prep third trimester',
    linkUrl: 'https://kindbody.vids.io/videos/ac9fdcb31517e7c325/pregnancy-prep-third-trimester',
    linkText: 'See More'
  },
  {
    line1: 'Maternity Booklet',
    linkUrl: MATERNITY_BOOKLET_URL,
    linkText: 'See More'
  }
];
