import styled, { css } from 'styled-components';

export const Content = styled.div`
  width: 900px;
  padding-left: 88px;
  padding-right: 88px;
  padding-top: 32px;
  padding-bottom: 32px;

  &:focus-visible {
    outline: none;
  }

  @media ${({ theme }) => theme.queries.mobile} {
    max-width: 320px;
  }

  @media ${({ theme }) => theme.queries.maxTablet} {
    width: 100%;
    padding: 24px;
    padding-top: 16px;
  }
`;

export const Logo = styled.img`
  margin-top: 32px;
  margin-bottom: 16px;
  height: 24px;

  @media ${({ theme }) => theme.queries.maxTablet} {
    height: 16px;
    margin-top: 16px;
    width: 100%;
  }
`;

export const Header = styled.h1`
  font-weight: 800;
  margin-top: 16px;
  margin-bottom: 16px;
  color: ${({ theme }) => theme.colors.neutral.primaryNavy};
  font-size: 32px;
  line-height: 40px;

  @media ${({ theme }) => theme.queries.maxTablet} {
    margin: 0 0 12px 0;
    text-align: center;
  }
`;

export const Description = styled.div`
  color: #272a5e;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
`;

export const SelectContainer = styled.div`
  display: flex;
  gap: 16px;
  margin: 32px 0;

  > div {
    flex: 1;
  }

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tabletPortrait}) {
    flex-direction: column;
  }
`;

export const ButtonContainer = styled.div`
  & > button:last-child {
    margin-top: 12px;
  }
`;

export const ButtonStyled = styled.button`
  width: 100%;
  background: ${({ theme }) => theme.colors.yellow.primary};
  height: 64px;
  margin-top: 24px;
  border-radius: 8px;

  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;
  display: flex;
  align-items: center;
  text-align: center;
  text-transform: uppercase;
  justify-content: center;
  letter-spacing: 0.4px;
  color: ${({ theme }) => theme.colors.neutral.primaryNavy};

  ${props =>
    props.disabled &&
    css`
      background: ${({ theme }) => theme.colors.neutral.lighterNavy};
      color: ${({ theme }) => theme.colors.neutral.navy};
      cursor: not-allowed;
    `}
`;
