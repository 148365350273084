import React, { useState } from 'react';

import { Heading, Text } from 'components/v2/Typography';
import PostopInstructionsModal from 'screens/MyJourney/details/PostopInstructionsModal';

import { CultureResult } from '../EggRetrieval.types';
import { Button, ResultsSection, Section } from './DetailsOfRetrival.styled';

export const DetailsOfRetrieval = ({ cultureResult }: { cultureResult: CultureResult }) => {
  const {
    retrieved,
    embryosThawed,
    eggsThawed,
    eggsIcsid,
    eggsInseminated,
    biopsied,
    eggsCryopreserved,
    embryosCryopreserved,
    eggsDiscarded,
    embryosDiscarded,
    eggsDonated,
    embryosTransferred,
    fertilized,
    attemptType
  } = cultureResult;
  const [showModal, setShowModal] = useState(false);

  if (!attemptType) return null;

  return (
    <>
      <Section>
        <ResultsSection>
          {areRetrievedEggsVisible(attemptType) && (
            <Result value={retrieved} text="Eggs Retrieved" />
          )}
          {areThawedEggsVisible(attemptType) && <Result value={eggsThawed} text="Eggs Thawed" />}
          {areIcsiEggsVisible(attemptType) && <Result value={eggsIcsid} text="ICSI" />}
          {areInseminatedEggsVisible(attemptType) && (
            <Result value={eggsInseminated} text="Conventional Inseminated" />
          )}
          {areFertilisedEggsVisible(attemptType) && (
            <Result value={fertilized} text="Eggs Fertilized" />
          )}
          {areThawedEmbryosVisible(attemptType) && (
            <Result value={embryosThawed} text="Embryos Thawed" />
          )}
          {areTransferedEmbryosVisible(attemptType) && (
            <Result value={embryosTransferred} text="Embryos Transferred" />
          )}
          {areBiopsedEmbryosVisible(attemptType) && (
            <Result value={biopsied} text="Embryos Biopsied" />
          )}
          {areCryopreservedEmbryosVisible(attemptType) && (
            <Result value={embryosCryopreserved} text="Embryos Cryopreserved" />
          )}
          {areCrypoPreservedEggsVisible(attemptType) && (
            <Result value={eggsCryopreserved} text="Eggs Cryopreserved" />
          )}
          {areDiscardedEmbryosVisible(attemptType) && (
            <Result value={embryosDiscarded} text="Embryos Discarded" />
          )}
          {areDiscardedEggsVisible(attemptType) && (
            <Result value={eggsDiscarded} text="Eggs Discarded" />
          )}
          {attemptType === 'donor-ivf' && <Result value={eggsDonated} text="Eggs Donated" />}
        </ResultsSection>

        <Button onClick={() => setShowModal(true)}>View post-op instructions</Button>
      </Section>
      <PostopInstructionsModal show={showModal} hide={() => setShowModal(false)} />
    </>
  );
};

const Result = ({ value, text }: { value: number | null; text: string }) => {
  if (value == null) return null;
  return (
    <div>
      <div>
        <Heading tag="h1">{value}</Heading>
      </div>
      <div>
        <Text size="sm">{text}</Text>
      </div>
    </div>
  );
};

const areRetrievedEggsVisible = (attemptType: string) =>
  ['embryo_banking', 'donor-ivf', 'ivf', 'egg_freezing'].includes(attemptType);

const areIcsiEggsVisible = (attemptType: string) =>
  ['embryo_banking', 'oocyte_thaw', 'ivf', 'recipient-ivf'].includes(attemptType);

const areFertilisedEggsVisible = (attemptType: string) =>
  ['embryo_banking', 'oocyte_thaw', 'ivf', 'recipient-ivf'].includes(attemptType);

const areThawedEggsVisible = (attemptType: string) =>
  ['embryo_banking', 'oocyte_thaw', 'ivf', 'recipient-ivf'].includes(attemptType);

const areInseminatedEggsVisible = (attemptType: string) =>
  ['embryo_banking', 'oocyte_thaw', 'ivf', 'recipient-ivf'].includes(attemptType);

const areCrypoPreservedEggsVisible = (attemptType: string) =>
  ['donor-ivf', 'egg_freezing'].includes(attemptType);

const areDiscardedEggsVisible = (attemptType: string) =>
  ['donor-ivf', 'egg_freezing', 'ivf', 'embryo_banking', 'oocyte_thaw'].includes(attemptType);

const areThawedEmbryosVisible = (attemptType: string) =>
  ['embryo_thaw', 'thaw,_biopsy,_revit'].includes(attemptType);

const areBiopsedEmbryosVisible = (attemptType: string) =>
  ['embryo_banking', 'oocyte_thaw', 'ivf', 'recipient-ivf', 'thaw,_biopsy,_revit'].includes(
    attemptType
  );

const areCryopreservedEmbryosVisible = (attemptType: string) =>
  ['embryo_banking', 'oocyte_thaw', 'ivf', 'recipient-ivf', 'thaw,_biopsy,_revit'].includes(
    attemptType
  );

const areDiscardedEmbryosVisible = (attemptType: string) =>
  [
    'embryo_banking',
    'oocyte_thaw',
    'embryo_thaw',
    'ivf',
    'recipient-ivf',
    'thaw,_biopsy,_revit'
  ].includes(attemptType);

const areTransferedEmbryosVisible = (attemptType: string) =>
  ['oocyte_thaw', 'embryo_thaw', 'ivf', 'recipient-ivf'].includes(attemptType);
