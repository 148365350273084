import { AppointmentType, Clinic } from 'kb-shared/types';

import { StepType } from '../types/types';
import { containsOnlyVirtualAppointmentType } from './validation';

export const shouldSkipLocationStep = ({
  step,
  selectedAppointmentTypes,
  selectedClinic,
  partnerClinicSearch
}: {
  step: StepType;
  selectedAppointmentTypes: Array<AppointmentType>;
  selectedClinic: Clinic | undefined;
  partnerClinicSearch: string;
}): boolean => {
  if (
    step === 'location' &&
    (containsOnlyVirtualAppointmentType(selectedAppointmentTypes ?? []) || selectedClinic) &&
    partnerClinicSearch !== 'search'
  ) {
    return true;
  }

  return false;
};
