import { useQuery } from '@apollo/client';
import { useMemo } from 'react';

import { usePatient } from 'hooks';
import { modifyUrlForEnvironment } from 'utilities/urlUtils';

import GET_RESOURCES, { MultimediaResourcesReturn } from '../queries/get-resources';
import { ResourceItemType } from '../types';

const useResourceLibrary = (): SeparatedResources => {
  const { loading, data } = useQuery<MultimediaResourcesReturn>(GET_RESOURCES);

  const { patient } = usePatient();
  const labId = patient?.lab?.id;

  const educationalContent: ResourceItemType[] = useMemo(() => {
    if (labId == null) {
      return [];
    }
    const multimediaResources = data?.multimediaResources ?? [];
    const eduResources = multimediaResources.filter(
      ({ resourceType }) => resourceType === 'educational_content'
    );

    const sortedEduResources = eduResources.sort(
      (prevResource, nextResource) => prevResource.tierForPortal - nextResource.tierForPortal
    );

    return sortedEduResources.filter(({ title }) => !title.startsWith('Gaea'));
  }, [data, labId]);

  const blogsContent: ResourceItemType[] = useMemo(() => {
    const multimediaResources = data?.multimediaResources ?? [];
    return multimediaResources.filter(({ resourceType }) => resourceType === 'blogs');
  }, [data]);

  const videosContent: ResourceItemType[] = useMemo(() => {
    const multimediaResources = data?.multimediaResources ?? [];
    const videos = multimediaResources.filter(({ resourceType }) => resourceType === 'videos');
    return videos.map(video => ({ ...video, url: modifyUrlForEnvironment(video.url) }));
  }, [data]);

  return {
    loading,
    educationalContent,
    blogsContent,
    videosContent
  };
};

type SeparatedResources = {
  loading: boolean;
  educationalContent: ResourceItemType[];
  blogsContent: ResourceItemType[];
  videosContent: ResourceItemType[];
};

export default useResourceLibrary;
