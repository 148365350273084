import { useQuery } from '@apollo/client';

import { CONVERSATIONS_QUERY } from './Messages.graphql';
import { ConversationsResponse } from './Messages.type';

export const useConversations = () => {
  const { data, loading, error } = useQuery<ConversationsResponse>(CONVERSATIONS_QUERY);

  return {
    conversations: data?.conversations || [],
    loading,
    error
  };
};
