import React from 'react';

const FollicleIcon = ({ size = 1 }: { size: number }) => {
  const height = size * 38 + 12;
  const width = height * 0.88;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox={`0 0 ${32} ${36}`}
    >
      <title>Follicle illustration</title>
      <defs>
        <linearGradient id="a" x1="89.86%" x2="10.14%" y1="0%" y2="100%">
          <stop offset="0%" stopColor="#727589" />
          <stop offset="100%" stopColor="#4B4E68" />
        </linearGradient>
      </defs>
      <ellipse
        cx="12.5"
        cy="14"
        fill="url(#a)"
        fillRule="nonzero"
        stroke="#F0E6DC"
        rx="12"
        ry="13.5"
      />
    </svg>
  );
};

export default FollicleIcon;
