import React from 'react';

import SubstepWrapper from '../../SubstepWrapper';
import { Description, Title } from './styled-components';

const ReturnOBAppointment = () => (
  <>
    <Title>Return OB Appointment</Title>
    <Description>
      This appointment includes a visit with your provider, an ultrasound & cervix check.
    </Description>
    <Description>
      <strong>Cervix Check:</strong> Your provider will check the dilation of your cervix. The
      cervix dilates during labor and some women can start to dilate days or weeks before delivery.
    </Description>
  </>
);

const DoulaAppointment = () => (
  <>
    <Title>Doula (Labor Coach) Appointment</Title>
    <Description>You and your Doula will meet to finalize your birth plan.</Description>
  </>
);

const SUBSTEPS = [{ component: <ReturnOBAppointment /> }, { component: <DoulaAppointment /> }];

const ObWeek36Substeps = () => <SubstepWrapper substeps={SUBSTEPS} />;

export default ObWeek36Substeps;
