import Honeybadger from '@honeybadger-io/js';
import React, { Component } from 'react';

import { FieldGroup, FormField, Answer } from 'kb-shared';
import styled from 'styled-components';

import AnimatedWrapper from '../AnimatedWrapper';
import ChoiceButton from '../ChoiceButton';
import FormButton from '../FormButton';
import GrowingTextArea from '../GrowingTextArea';
import Text from '../Primitives/FormText';
import TextInput from '../Primitives/TextInput';

type Props = {
  fieldGroup: FieldGroup;
  onSubmit: (response: string, id: string) => void;
  savedAnswers: {
    [id: string]: Answer;
  };
  onContinue: Function;
};

type State = {
  selectionOption: null | string;
  textResponse: string;
};

export default class FieldGroupBooleanPairForm extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    const textField: FormField = this._textFormField(props.fieldGroup.formFields);
    const boolField: FormField = this._booleanFormField(props.fieldGroup.formFields);
    const boolAnswer = boolField && props.savedAnswers[boolField.id];
    const textAnswer = textField && props.savedAnswers[textField.id];
    this.state = {
      selectionOption: (boolAnswer && boolAnswer.data) || null,
      textResponse: (textAnswer && textAnswer.data) || ''
    };
  }

  textFormField() {
    return this._textFormField(this.props.fieldGroup.formFields);
  }

  _textFormField(fields: Array<FormField>) {
    const findTextField = fields.filter((formField: FormField) => {
      //NOTE: This is a temporary fix where the graphql data is returning mixed dataTypes for the sametype of field
      return formField.dataType === 'string' || formField.dataType === 'text';
    });
    return findTextField[0];
  }

  booleanFormField() {
    return this._booleanFormField(this.props.fieldGroup.formFields);
  }

  _booleanFormField(fields: Array<FormField>) {
    const findTextField = fields.filter((formField: FormField) => {
      return formField.dataType === 'boolean';
    });
    return findTextField[0];
  }

  optionSelected(option: 'true' | 'false') {
    this.setState(
      {
        selectionOption: option
      },
      () => {
        const booleanField = this.booleanFormField();
        this.props.onSubmit(option, booleanField.id);
        // If user selects no/false, also save the text answer as blank, and auto progress them
        if (option === 'false') {
          this.setState({ textResponse: '' });
          const textField = this.textFormField();
          this.props.onSubmit('', textField.id);
          this.props.onContinue();
        }
      }
    );
  }

  onKeyPress(e: { key: string }) {
    if (e.key === 'Enter') {
      this.props.onSubmit(this.state.textResponse, this.textFormField().id);
      this.props.onContinue();
    }
  }

  renderTextInput() {
    if (this.state.selectionOption === 'true') {
      const inputForm = this.textFormField();
      if (inputForm) {
        if (inputForm.dataType === 'string') {
          return (
            <TextInput
              value={this.state.textResponse}
              placeholder={inputForm.title}
              onKeyPress={this.onKeyPress.bind(this)}
              onChange={e => {
                const response = e.target.value;
                this.setState(() => ({
                  textResponse: response
                }));
              }}
              onBlur={() => this.props.onSubmit(this.state.textResponse, this.textFormField().id)}
            />
          );
        } else if (inputForm.dataType === 'text') {
          return (
            <GrowingTextArea
              value={this.state.textResponse}
              placeholder={inputForm.title}
              onChange={e => {
                const response = e.target.value;
                this.setState(() => ({
                  textResponse: response
                }));
              }}
              onBlur={() => this.props.onSubmit(this.state.textResponse, this.textFormField().id)}
            />
          );
        }
      }
    }
  }

  renderContinueButton() {
    const textField = this.textFormField();
    const hidden = this.state.textResponse ? false : true;
    return (
      <AnimatedWrapper isHidden={hidden}>
        <FormButton
          text={'Continue'}
          onClick={() => {
            this.props.onSubmit(this.state.textResponse, textField.id);
            this.props.onContinue();
          }}
        />
      </AnimatedWrapper>
    );
  }

  reportMalformedFieldGroup() {
    const errorMetaData = JSON.stringify(this.props.fieldGroup);
    Honeybadger.notify('We have a malformed FieldGroup: ' + errorMetaData);
  }

  render() {
    if (this.booleanFormField() == null || this.textFormField() == null) {
      if (
        this.props.fieldGroup.formFields != null &&
        this.props.fieldGroup.formFields.length !== 0
      ) {
        this.reportMalformedFieldGroup();
      }
      return null;
    }
    return (
      <Container>
        <Text isRequired={!!this.booleanFormField().required}>{this.booleanFormField().title}</Text>
        <ButtonContainer>
          <ButtonWrapper>
            <ChoiceButton
              text={'No'}
              selected={this.state.selectionOption === 'false'}
              onClick={this.optionSelected.bind(this, 'false')}
            />
          </ButtonWrapper>
          <ButtonWrapper>
            <ChoiceButton
              text={'Yes'}
              selected={this.state.selectionOption === 'true'}
              onClick={this.optionSelected.bind(this, 'true')}
            />
          </ButtonWrapper>
        </ButtonContainer>
        {this.renderTextInput()}
        {this.renderContinueButton()}
      </Container>
    );
  }
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-flow: row wrap;
`;

const ButtonWrapper = styled.div`
  margin: 24px 12px 24px 12px;
  @media (max-width: 700px) {
    margin: 10px 18px 10px 12px;
  }
`;
