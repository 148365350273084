import { themes } from 'kb-shared';
import styled from 'styled-components';
const { breakpoints } = themes;

export const LoginContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
  max-width: 560px;
`;

export const LoginContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;

export const LoginButtonsContainer = styled.div`
  width: 320px;
  min-width: 320px;

  > * {
    margin-top: 10px;
  }

  button {
    height: 40px;
  }

  button svg {
    width: 14px;
    height: 14px;
  }
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const ResendSmsCodeWrapper = styled.div`
  margin-top: 6px;
`;

export const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding-top: 32px;

  @media screen and (max-width: ${breakpoints.largePhonePortrait}) {
    flex-direction: column;
  }

  > button {
    margin-left: 2px;
    margin-right: 2px;
  }
`;

export const VTextFieldMargin = styled.div`
  margin: 16px 0;
  width: 100%;
`;

export const Link = styled.a`
  text-decoration: underline;
`;

export const SignUpLinkContainer = styled.div`
  padding-top: 32px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;
