import styled from 'styled-components';

export const CardListItem = styled.li`
  width: 100%;
  padding: 1em 1.5em;
  margin: 12px 0;
  border-radius: 8px;
  background-color: ${({ theme }) => theme.colors.white};
`;

export const CardContent = styled.div`
  display: flex;
  flex-direction: column;
`;

export const CardContentRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  .heading {
    font-size: 24px;
    margin-top: 0.3em;
  }
`;

export const Spacer = styled.div`
  margin-top: 1.5em;
`;

export const CardContentAmount = styled.div`
  margin-top: 0.5em;
`;
