import React from 'react';

export const enum CollateType {
  ERROR = 'error',
  SUCCESS = 'success'
}
export interface CollateProps {
  type: CollateType;
  title: string;
  description: string;
  additionalDescription?: React.ReactNode;
}
