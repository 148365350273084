import { faXmark, faBars } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';

import { ReactComponent as LeftBracket } from '../../../../assets/icons/current_cycle_left.svg';
import { ReactComponent as RightBracket } from '../../../../assets/icons/current_cycle_right.svg';
import { MenuCenterIcon, MenuIconContainer, MenuIconImageContainer } from './MenuIcon.styled';
import { MenuIconProps } from './MenuIcon.types';

export const MenuIcon = ({ toggleMenu, isOpen }: MenuIconProps) => {
  return (
    <MenuIconContainer>
      <MenuIconImageContainer role="img" aria-label="menu icon" onClick={toggleMenu}>
        <LeftBracket height={34} width={13} id="menu-icon-left-bracket" />
        <MenuCenterIcon>
          {isOpen ? (
            <FontAwesomeIcon icon={faXmark} title="Close menu icon" />
          ) : (
            <FontAwesomeIcon icon={faBars} title="Open menu icon" />
          )}
        </MenuCenterIcon>
        <RightBracket height={34} width={13} id="menu-icon-right-bracket" />
      </MenuIconImageContainer>
    </MenuIconContainer>
  );
};
