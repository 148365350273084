import { gql } from '@apollo/client';

export const LABS_QUERY = gql`
  query labs {
    labs {
      id
      name
      vios
      timeZone
    }
  }
`;

export const CLINICS_QUERY = gql`
  query clinics($appointmentTypeId: Int!, $labId: Int!, $startDateTime: String!) {
    hasTimeSlots(
      appointmentTypeId: $appointmentTypeId
      labId: $labId
      startDateTime: $startDateTime
    )
    clinics: locations(appointmentTypeId: $appointmentTypeId, labId: $labId) {
      id
      name
      address
      timeZone
      imageUrl
      patientFacing
    }
  }
`;
