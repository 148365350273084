import React from 'react';

import { ReactComponent as PlusIcon } from 'assets/icons/plusYellow.svg';
import { Button } from 'components/v2/Buttons/Button';
import { Heading } from 'components/v2/Typography';

import { Container } from './InitialInviteAction.styled';

export const InitialInviteAction = ({
  partnerAlreadyOnRecord,
  onClick
}: {
  partnerAlreadyOnRecord: boolean;
  onClick: () => void;
}) => {
  return (
    <Container>
      <Heading tag="div" styledAs="h3" bg="inherit" flexDirection="column">
        {!partnerAlreadyOnRecord && <span>Looks like you haven't added a partner yet.</span>}
        <span>Click on the “Invite Partner' button to get started!</span>
        <br />
        <span>We will send your partner an email so they can create their own portal account.</span>
      </Heading>

      <Button size="lg" gap="lg" label="INVITE PARTNER" onClick={onClick} leftIcon={<PlusIcon />} />
    </Container>
  );
};
