import { KeyboardEvent, RefObject } from 'react';

enum Event {
  ARROW_RIGHT = 'ArrowRight',
  ARROW_LEFT = 'ArrowLeft'
}

export const useTabEvents = (elementRef: RefObject<HTMLElement>) => {
  const onKeyDown = (event: KeyboardEvent<HTMLElement>) => {
    switch (event.key) {
      case Event.ARROW_RIGHT:
        return moveFocusToNextTab(elementRef);

      case Event.ARROW_LEFT:
        return moveFocusToPreviousTab(elementRef);

      default:
        break;
    }
  };

  return [onKeyDown];
};

const moveFocusToNextTab = (elementRef: RefObject<HTMLElement>) => {
  if (!elementRef.current) {
    return;
  }

  const activeTab = elementRef.current.querySelector('[aria-selected="true"]');

  if (!activeTab) {
    return;
  }

  let nextTab = activeTab.nextElementSibling as HTMLElement;

  if (!nextTab) {
    nextTab = activeTab.parentElement?.firstElementChild as HTMLElement;
  }

  nextTab.focus();
  nextTab.click();
};

const moveFocusToPreviousTab = (elementRef: RefObject<HTMLElement>) => {
  if (!elementRef.current) {
    return;
  }

  const activeTab = elementRef.current.querySelector('[aria-selected="true"]');

  if (!activeTab) {
    return;
  }

  let nextTab = activeTab.previousElementSibling as HTMLElement;

  if (!nextTab) {
    nextTab = activeTab.parentElement?.lastElementChild as HTMLElement;
  }

  nextTab.focus();
  nextTab.click();
};
