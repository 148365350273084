import styled from 'styled-components';

export const ModalHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 16px;
`;

export const ModalContent = styled.div`
  padding: 32px 48px 32px 48px;

  @media ${({ theme }) => theme.queries.minTablet} {
    padding: 20px;
    width: 100%;
  }
`;

export const Bottom = styled.div`
  margin-top: 60px;
  display: flex;
  gap: 8px;
  flex-direction: column;
`;
