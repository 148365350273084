import React, { useState } from 'react';

import { Comments } from 'assets/icons/Icons';
import { Button } from 'components/v2/Buttons/Button';

import { CategoryIcon } from '../CategoryIcon/CategoryIcon';
import { SelectCategoryModal } from '../SelectCategoryModal/SelectCategoryModal';
import { Container, Description, EmptyStyled } from './EmptyConversationList.styled';

export const EmptyConversationList = ({ description }: { description: string }) => {
  const [openedModal, setOpenedModal] = useState<boolean>(false);

  return (
    <Container>
      <EmptyStyled>
        <CategoryIcon size="lg" icon={<Comments type="light" />} />

        <Description>{description}</Description>

        <Button
          fullWidth
          uppercase
          label="Ask us a Question"
          onClick={() => setOpenedModal(true)}
        />
      </EmptyStyled>

      <SelectCategoryModal open={openedModal} close={() => setOpenedModal(false)} />
    </Container>
  );
};
