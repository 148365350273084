import { ApolloCache, FetchResult, useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { useEffect } from 'react';
import { v4 as uuidV4 } from 'uuid';

import { CATEGORY, OTHER_ID_TYPES, uploadFile } from 'api/pf2/files';
import client from 'kb-shared/graphql/client';
import { BugTracker } from 'kb-shared/utilities/bugTracker';
import { CREATE_CONVERSATION, CREATE_MESSAGE } from 'screens/Messages/Messages.graphql';
import { ConversationResponse, Conversation } from 'screens/Messages/Messages.type';
import { convertToBase64 } from 'utilities/file';

import { MessageAttachment } from '../Inputs/Inputs.types';
import { CONVERSATION_QUERY } from './Conversation.graphql';
import {
  CreateConversationResponse,
  CreateMessageResponse,
  UseMessage
} from './Conversation.types';

const getConversationFromCache = (conversationId: number) => {
  try {
    return client.readQuery<ConversationResponse>({
      query: CONVERSATION_QUERY,
      variables: { id: conversationId }
    });
  } catch (e) {
    BugTracker.notify(e, 'MessageConversationCacheNotFound');
  }
};

const updateCache = (conversation?: Conversation) => {
  if (!conversation) return;

  client.writeQuery({
    query: CONVERSATION_QUERY,
    variables: { id: conversation.id },
    data: { conversation }
  });
};

const createAttachments = (attachments: MessageAttachment[], messageId: string) => {
  const newAttachmentsPromises = attachments.map(async attachment => ({
    id: Math.random(),
    fileName: attachment.file.name,
    file: await convertToBase64(attachment.file),
    isPatient: true,
    messageId: messageId,
    uuid: uuidV4(),
    createdAt: new Date().toISOString(),
    __typename: 'Attachment'
  }));

  return Promise.all(newAttachmentsPromises);
};

const updateCacheWithNewMessage = (conversationId?: number) => (
  _: ApolloCache<unknown>,
  { data }: FetchResult<CreateMessageResponse>
) => {
  if (!data || !conversationId) return;

  const cache = getConversationFromCache(conversationId);

  if (!cache) return;

  const newConversation = {
    ...cache.conversation,
    messages: [...cache.conversation.messages, data.createMessage.message]
  };

  updateCache(newConversation);
};

const updateCacheWithNewFile = async (conversationId: number, files: MessageAttachment[]) => {
  const data = getConversationFromCache(conversationId);

  if (!data) return;

  const lastMessageIndex = data.conversation.messages.length - 1;
  const lastMessage = data.conversation.messages[lastMessageIndex];

  const newMessage = {
    ...lastMessage,
    attachments: await createAttachments(files, lastMessage.id)
  };

  const newConversation = {
    ...data.conversation,
    messages: [...data.conversation.messages.slice(0, lastMessageIndex), newMessage]
  };

  updateCache(newConversation);
};

const updateCacheFromResource = async (conversationId: number) => {
  const { data } = await client.query({
    query: CONVERSATION_QUERY,
    variables: {
      id: conversationId
    },
    fetchPolicy: 'network-only'
  });

  updateCache(data?.conversation);
};

const uploadFiles = (conversationId?: number) => async (
  attachments: MessageAttachment[],
  messageId: number
) => {
  conversationId && updateCacheWithNewFile(conversationId, attachments);

  const uploadPromises = attachments.map(attachment =>
    uploadFile({
      file: attachment.file,
      category: CATEGORY.MESSAGE_ATTACHMENT,
      otherId: messageId,
      otherIdType: OTHER_ID_TYPES.MESSAGE_ID,
      purpose: attachment.purpose,
      description: attachment.description
    })
  );
  await Promise.all(uploadPromises);

  conversationId && updateCacheFromResource(conversationId);
};

const CONVERSATION_INTERVAL_TIMEOUT = 10000;

export const useMessage = (conversationId?: number): UseMessage => {
  const { data: initialData, loading, error: conversationError } = useQuery<ConversationResponse>(
    CONVERSATION_QUERY,
    {
      variables: {
        id: conversationId
      },
      skip: !conversationId
    }
  );
  const [
    getConversationMessages,
    { data: poolingData, error: conversationPollingError }
  ] = useLazyQuery<ConversationResponse>(CONVERSATION_QUERY, {
    variables: {
      id: conversationId
    }
  });
  const [createMessage] = useMutation<CreateMessageResponse>(CREATE_MESSAGE, {
    update: updateCacheWithNewMessage(conversationId)
  });
  const [createConversation] = useMutation<CreateConversationResponse>(CREATE_CONVERSATION);

  useEffect(() => {
    if (!conversationId) return;

    const pollingInterval = setInterval(() => {
      getConversationMessages();
    }, CONVERSATION_INTERVAL_TIMEOUT);

    return () => clearInterval(pollingInterval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [conversationId]);

  return {
    loading,
    conversation: poolingData?.conversation || initialData?.conversation,
    send: conversationId
      ? argument => createMessage({ variables: argument })
      : argument => createConversation({ variables: argument }),
    upload: uploadFiles(conversationId),
    error: conversationError || conversationPollingError
  };
};
