import * as React from 'react';

import { FootnotesStar, Paragraph } from './Footnotes.styled';

export const Footnotes = () => {
  return (
    <Paragraph>
      <FootnotesStar>*&nbsp;</FootnotesStar>
      <span>
        This calculator is based on a model established by{' '}
        <a
          href="https://s3.us-east-2.amazonaws.com/kindbody-portal-static-files/cryo-predictor.pdf"
          target="_blank"
          rel="noopener noreferrer"
        >
          this study
        </a>
      </span>
    </Paragraph>
  );
};
