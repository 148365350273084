import React, { useState } from 'react';

import { Button } from 'components/v2/Buttons/Button';
import styled from 'styled-components';

import PostEmbryoTransferInstructionsModal from './PostEmbryoTransferInstructionsModal';
import StepDescription from './StepDescription';

const EmbryoTransferDetail = () => {
  const [showModal, setShowModal] = useState(false);

  return (
    <StepDescription
      description={
        <>
          <FlexContainer>
            <div>
              <p>
                <strong>
                  Please come in with a full bladder (drink 2 bottles of water on your way to the
                  office).
                </strong>{' '}
                Do not wear any perfume, lotions, scents on your skin. Arrive in comfortable
                clothing. Have a light breakfast/lunch. Due to current regulations and Covid
                precautions, your partner may not be able to accompany you into the room during your
                embryo transfer. Please speak with a member of your care team to see if the facility
                for embryo transfer will allow your partner. If your partner is allowed to attend,
                please ask your partner not to wear any scents, perfumes, or lotions on his or her
                skin as well. During the transfer, your embryo isplaced in a thin catheter which
                will deliver it into your uterus. The physician will use an abdominal ultrasound to
                help them guide it in.
              </p>
              <p>Continue all medications as normally scheduled.</p>
              <p>
                On the day of your procedure, the physician performing the embryo transfer will
                review with you the details of the embryo selected for transfer. Note: ASRM
                guidelines suggest that one embryo be transferred into the uterus at embryo
                transfer. This should be discussed with your physician ahead of time.
              </p>
            </div>
            <ButtonStyled
              label="Review Post-Procedure Instructions"
              onClick={() => setShowModal(!showModal)}
            />
          </FlexContainer>
          <PostEmbryoTransferInstructionsModal show={showModal} hide={() => setShowModal(false)} />
        </>
      }
    />
  );
};

export default EmbryoTransferDetail;

const ButtonStyled = styled(Button)`
  min-width: fit-content;
  height: fit-content;
  margin-bottom: 20px;
`;

const FlexContainer = styled.div`
  display: flex;
  margin-bottom: 20px;
  gap: 12px;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.smallDesktop}) {
    flex-wrap: wrap;
    margin-bottom: 0;
  }
`;
