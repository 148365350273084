import React from 'react';

import { Heading, Text } from 'components/v2/Typography';

import { ResultContainer, ResultDesktopTitleContainer } from './Results.styled';

export const Results: React.FC = () => {
  return (
    <ResultContainer>
      <ResultDesktopTitleContainer>
        <Heading tag="h1" noMargin>
          My Results
        </Heading>
      </ResultDesktopTitleContainer>
      <Text fontStyle="regular" size="lg">
        Overview of all your appointments and their respective results here.
      </Text>
    </ResultContainer>
  );
};
