import React from 'react';

import { Title, Description } from './styled-components';

const ObtainMedications = () => (
  <>
    <Title>Obtain medications</Title>
    <Description>
      Your care team will notify the pharmacy about your care plan and assist you with the
      coordination and delivery of your medications. If you have not heard from the pharmacy then
      please contact your care team.
    </Description>
  </>
);

export default ObtainMedications;
