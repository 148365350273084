import {
  IntakeForm,
  FormSection,
  FieldGroup,
  FormConditionLogicOperator,
  FormConditionWithAnswer,
  Answer
} from '../types';
interface FormRecord {
  [id: string]: FieldGroup;
}
/**
 * Normalize the GraphQL data so that its easier to digest on the FE
 *
 */
export function normalizeIntakeForm(form: IntakeForm): IntakeForm {
  return {
    id: form.id,
    title: form.title,
    formSections: form.formSections.map((section: FormSection) => {
      const normalizedFieldGroups = section.fieldGroups.reduce(
        (map: FormRecord, group: FieldGroup) => {
          const id = group.id;
          map[id] = group;
          return map;
        },
        {}
      );

      return {
        ...section,
        fieldGroupsMap: normalizedFieldGroups
      };
    }),
    conditionalFormElements: form.conditionalFormElements
  };
}

export interface FormAnswers {
  [id: string]: Answer;
}

/**
 *
 * Creates a map where the key is the id of the formElement
 */
export function mapIntakeFormAnswers(answers: Array<Answer | null>): FormAnswers {
  return answers.reduce((map: FormAnswers, answer: Answer | null) => {
    if (answer != null) {
      map[answer.formElementId] = answer;
    }
    return map;
  }, {});
}

export function intakeFormId(gender?: string): number {
  return gender === 'male' ? 11 : 10;
}

export function canContainMultipleValues(answer: string): boolean {
  const startBracketIx = answer.indexOf('[');
  const endBracketIx = answer.indexOf(']');
  return startBracketIx !== -1 && endBracketIx !== -1 && startBracketIx < endBracketIx;
}

export function areConditionsSatisfied(conditionsWithAnswers: FormConditionWithAnswer[]): boolean {
  const conditions = conditionsWithAnswers.map(
    conditionWithAnswer => conditionWithAnswer.renderingCondition
  );

  const applyOrLogic = conditions.every(
    condition => condition.logicOperator === FormConditionLogicOperator.OR
  );

  for (const conditionWithAnswer of conditionsWithAnswers) {
    const condition = conditionWithAnswer.renderingCondition;
    const answerOnDependency = conditionWithAnswer.answerOnDependency;

    if (!answerOnDependency) {
      if (!applyOrLogic) {
        return false;
      } else {
        continue;
      }
    } else if (canContainMultipleValues(answerOnDependency)) {
      // for some questions, multiple answers are possible and in that case answers are stored as a string represention of the array
      const parsedAnswers = JSON.stringify(answerOnDependency);
      if (!parsedAnswers.includes(condition.value)) {
        if (!applyOrLogic) {
          return false;
        } else {
          continue;
        }
      } else if (applyOrLogic) {
        return true;
      }
    } else if (answerOnDependency !== condition.value) {
      if (!applyOrLogic) {
        return false;
      } else {
        continue;
      }
    } else if (applyOrLogic && answerOnDependency === condition.value) {
      return true;
    }
  }

  return applyOrLogic ? false : true;
}

export function findLastIndex<T>(
  array: Array<T>,
  predicate: (value: T, index: number, obj: T[]) => boolean
): number {
  let l = array.length;
  while (l--) {
    if (predicate(array[l], l, array)) return l;
  }
  return -1;
}
