import React from 'react';
import { useHistory } from 'react-router-dom';

import { ChevronLeft } from 'assets/icons/Icons';
import { GenericDropdown } from 'components/v2/DropdownMenu/Dropdown';
import {
  getCategoryInfo,
  getFirstName,
  isClosedConversation,
  isOpenConversation,
  isResolvedConversation
} from 'screens/Messages/Messages.utils';
import { pageUrl } from 'utilities/pageUrl';
import { useBreakpoints } from 'utilities/useBreakpoints';

import { AssigneePhoto } from '../AssigneePhoto/AssigneePhoto';
import { Badge } from '../Badge/Badge';
import {
  BackButton,
  Container,
  Navbar,
  NavbarInfo,
  Subheader,
  Subject,
  Title
} from './Header.styled';
import { Props } from './Header.types';
import { getParticipantsMenuStructure } from './Header.utils';

export const Header = ({ conversation, categoryName }: Props) => {
  const history = useHistory();
  const { isDesktop } = useBreakpoints();

  const currentCategory = getCategoryInfo(categoryName);

  const title = getFirstName(conversation?.assignedToUserName) ?? currentCategory.title;
  const subject = conversation?.subject;
  const status = conversation?.status;

  const { participantsMenuStructure, additionalParticipants } = getParticipantsMenuStructure(
    conversation
  );

  return (
    <Container>
      <Navbar>
        <BackButton data-testid="back-button" onClick={() => history.push(pageUrl.messages())}>
          <ChevronLeft type="solid" />
        </BackButton>
        <NavbarInfo>
          {!isDesktop && (
            <AssigneePhoto
              assignedToUserPhotoUrl={conversation?.assignedToUserPhotoUrl}
              category={currentCategory}
              categoryIconSize="sm"
            />
          )}
          <Title>{title}</Title>
        </NavbarInfo>
        {participantsMenuStructure.length > 0 && (
          <GenericDropdown items={participantsMenuStructure}>
            <Badge label={'+' + additionalParticipants.length} type="default" size="md" />
          </GenericDropdown>
        )}
      </Navbar>

      {subject && (
        <Subheader>
          <Subject title={subject}>Subject: {subject}</Subject>

          <div>
            {isResolvedConversation(status) && <Badge label="Resolved" type="success" size="xs" />}
            {(isOpenConversation(status) || isClosedConversation(status)) && (
              <Badge label="Open" type="default" size="sm" />
            )}
          </div>
        </Subheader>
      )}
    </Container>
  );
};
