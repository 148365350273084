import moment from 'moment';

import { Vital } from 'kb-shared';

import { State } from './PeriodTracker.types';

export const DEFAULT_USER_VITAL: Vital = {
  daysBetweenCycles: null,
  id: null,
  periodDate: null,
  nextPeriodDate: null
};

export const INITIAL_STATE: State = {
  step: 'start',
  editedLastPeriodDate: null,
  logNewPeriodDate: null,
  editedDaysBetweenPeriod: null,
  datePickerVisible: false,
  updatingVitals: false,
  showCompleteModal: false,
  isInitialLoad: true,
  currentMonth: moment()
};
