import React from 'react';

import { themes } from 'kb-shared';
import styled from 'styled-components';

const { colors, deprecatedColors } = themes;

const CardButton = styled.div`
  display: flex;

  width: 27%;
  min-width: 350px;
  min-height: 180px;
  height: auto;
  margin: 1%;
  border-radius: 4px;
  border: 1px solid ${deprecatedColors.gray};
  cursor: pointer;
  ${({ selected }: SelectedOverlayProps) => {
    if (selected) {
      return `
        border: 4px solid ${colors.yellow.primary};
      `;
    }
  }}
`;

interface SelectedOverlayProps {
  selected?: boolean;
}

const Content = styled.div`
  padding: 30px 15px;
  width: 60%;
`;

const Title = styled.div`
  font-size: 24px;
  color: ${colors.neutral.primaryNavy};
  padding-bottom: 8px;
`;

const AddressText = styled.div`
  z-index: 1;

  font-size: 16px;
  color: ${colors.neutral.primaryNavy};
`;

interface LocationImageProps {
  image: string | undefined;
}

const LocationImage = styled.div`
  width: 40%;
  background-size: cover;
  background-position: center;
  ${({ image }: LocationImageProps) => {
    return `
        background-image: url(${image});
      `;
  }}
`;

type Props = {
  name: string;
  address: string;
  selected: boolean;
  imageUrl: string | undefined;
  onSelect: () => void;
};

const NewLocationCard = (props: Props) => {
  const { name, address, imageUrl, selected, onSelect } = props;
  return (
    <CardButton onClick={onSelect} selected={selected}>
      <Content>
        <Title>{name}</Title>
        <AddressText>{address}</AddressText>
      </Content>
      <LocationImage image={imageUrl} />
    </CardButton>
  );
};

export default NewLocationCard;
