import React, { Component, MouseEvent } from 'react';

import { themes } from 'kb-shared';
import styled from 'styled-components';

type Props = {
  text: string;
  selected: boolean;
  onClick: (event: MouseEvent) => void;
};
type State = {};

export default class ChoiceButton extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <Container selected={this.props.selected} onClick={this.props.onClick}>
        <Text>{this.props.text}</Text>
      </Container>
    );
  }
}

const SelectedState = `
  background-color: ${themes.colors.yellow.primary};
  border: solid 5px ${themes.colors.yellow.primary};
  box-shadow: 0 2px 8px 0 #ababab;
`;

interface ContainerProps {
  selected: boolean;
}

const Container = styled.div`
  cursor: pointer;
  display: flex;
  width: 320px;
  height: 105px;
  border-radius: 24px;
  border: solid 5px #f0e6dc;
  box-shadow: 0 2px 8px 0 #ababab, inset 0 2px 8px 0 #ababab;
  align-items: center;
  justify-content: center;
  background-color: ${themes.colors.yellow.lightestYellow};
  ${(props: ContainerProps) => (props.selected ? SelectedState : ``)}
  :active {
    ${SelectedState}
  }
  :focus {
    ${SelectedState}
    outline: 0;
  }
  @media (max-width: 700px) {
    width: 100%;
    height: auto;
    padding: 5px;
  }
`;

const Text = styled.div`
  font-size: 20px;
  text-align: center;
  padding: 4px;
`;
