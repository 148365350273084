import { ApolloError, gql, useMutation } from '@apollo/client';

import { BugTracker } from 'kb-shared/utilities/bugTracker';
import { STATUS } from 'screens/InterstitialModal/InterstitialModal.graphql';
import { graphQLErrorMessage } from 'utilities/errors';
import { showErrorToast } from 'utilities/notificationUtils';

const DELETE_INSURANCE_PROVIDER = gql`
  mutation deleteInsuranceProvider($id: ID!, $deleteCards: Boolean) {
    deleteInsuranceProvider(id: $id, deleteCards: $deleteCards) {
      insuranceProvider {
        id
      }
    }
  }
`;

export const UPDATE_INSURANCE_PROVIDER_MUTATION = gql`
  mutation updateInsuranceProvider($id: String!, $terminationDate: String) {
    updateInsuranceProvider(id: $id, terminationDate: $terminationDate) {
      insuranceProvider {
        id
      }
    }
  }
`;

export const useDeleteInsuranceData = () => {
  const [
    deleteInsuranceDataMutation,
    { loading: loadingDeletMutation, error: errorDeleteMutation }
  ] = useMutation(DELETE_INSURANCE_PROVIDER, {
    onError: error => {
      BugTracker.notify(error, 'Failed to delete the insurance data.');
    }
  });

  const [
    updateInsuranceProvider,
    { loading: loadingUpdateMutation, error: errorUpdateMutation }
  ] = useMutation(UPDATE_INSURANCE_PROVIDER_MUTATION, {
    onError: error => {
      BugTracker.notify(error, 'Failed to update the insurance data.');
    }
  });

  const deleteInsuranceData = async (
    id: string,
    terminationDate?: Date | null,
    deleteCards = false
  ) => {
    try {
      if (terminationDate) {
        await updateInsuranceProvider({
          variables: {
            id,
            terminationDate
          }
        });
      }

      const result = await deleteInsuranceDataMutation({
        variables: {
          id,
          deleteCards
        },
        refetchQueries: [{ query: STATUS }]
      });
      return result;
    } catch (error) {
      showErrorToast(graphQLErrorMessage(error as ApolloError));
    }
  };

  return {
    deleteInsuranceData,
    loading: loadingDeletMutation || loadingUpdateMutation,
    error: errorDeleteMutation || errorUpdateMutation
  };
};
