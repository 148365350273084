import React, { ChangeEvent, DragEvent, MouseEvent, useState } from 'react';

import { CATEGORY, uploadFile } from 'api/pf2/files';
import { Plus } from 'assets/icons/Icons';
import { Loader } from 'components/Loader/Loader';
import { Text } from 'components/v2/Typography';
import theme from 'kb-shared/theme';
import { BugTracker } from 'kb-shared/utilities/bugTracker';
import KBContacts from 'kb-shared/utilities/kindbody_contacts';
import { isValidFileExtension, showErrorValidExtensionToast } from 'utilities/fileValidation';
import { showErrorToast } from 'utilities/notificationUtils';

import AttachmentsList from '../AttachmentsList';
import { UploadFileModal } from '../UploadFileModal/UploadFileModal';
import {
  Container,
  DesktopUpload,
  FileUpload,
  FileUploadContainer,
  MobileUpload,
  Title,
  UploadContent
} from './YourUploads.styled';
import { Props } from './YourUploads.types';

export const YourUploads = (props: Props) => {
  const { items, purposes, onUpload, onDelete } = props;
  const MAX_MEGABYTES = 15;
  const [uploading, setUploading] = useState(false);
  const [showUploadModal, setShowUploadModal] = useState(false);
  const [uploadedFile, setUploadedFile] = useState<File | undefined>(undefined);

  const handleChange = async (e: ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    const {
      target: { files }
    } = e;

    if (!files || !files[0]) {
      return;
    }

    handleFileUpload(files[0]);
  };

  const handleFileUpload = async (file: File) => {
    if (!isValidFileExtension(file.type)) {
      return showErrorValidExtensionToast();
    }

    const maxSize = 1048576 * MAX_MEGABYTES; // 1mb = 1048576
    if (file.size > maxSize) {
      showErrorToast(
        `Your file is too big. Please upload a file less than ${MAX_MEGABYTES}mb. Please contact ${KBContacts.navigatorEmail} if you need assistance.`
      );
      return;
    }

    setUploadedFile(file);
    setShowUploadModal(true);
  };

  const upload = async (filePurpose: string | undefined, fileDescription: string) => {
    setShowUploadModal(false);
    if (uploadedFile == null) return;

    try {
      setUploading(true);
      // note: fetch doesn't throw an error when the server returns a bad HTTP status
      const response = await uploadFile({
        file: uploadedFile,
        category: CATEGORY.DOCUMENT,
        description: fileDescription,
        purpose: filePurpose
      });
      if (response.ok) onUpload(true);
      else logError(await response.text());
    } catch (err) {
      logError(err);
    } finally {
      setUploading(false);
    }
  };

  const logError = (err: unknown) => {
    BugTracker.notify(err, { name: 'DocumentUploadError' });
    showErrorToast(`Upload failed. Please try again or contact us at ${KBContacts.navigatorEmail}`);
  };

  const handleDrop = (e: DragEvent) => {
    e.preventDefault();
    e.stopPropagation();
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      handleFileUpload(e.dataTransfer.files[0]);
    }
  };

  const onFileUploadClick = (event: MouseEvent<HTMLInputElement, globalThis.MouseEvent>) => {
    // Reset the file input value allowing uset to repeat the upload with the same file.
    // (e.g. first upload failed and you want to try again)
    const element = event.target as HTMLInputElement;
    element.value = '';
  };

  if (uploading) return <Loader container />;

  return (
    <Container hasItems={items.length > 0}>
      <Title>Upload a New Document</Title>

      <UploadContent onDrop={handleDrop} onDragOver={e => e.preventDefault()}>
        <FileUploadContainer>
          <FileUpload
            type="file"
            accept=".jpeg, .jpg, .png, .pdf"
            onChange={handleChange}
            onClick={onFileUploadClick}
          />
          <Plus type="regular" />
        </FileUploadContainer>

        <DesktopUpload>
          <Text tag="p" size="lg" fontStyle="semibold" color={theme.colors.neutral.navy}>
            Drag a document into the dotted box or click to upload the file!
          </Text>

          <Text tag="p" size="xs" color={theme.colors.neutral.navy}>
            PDF, JPEG, JPG and PNG file types are allowed. The maximum file size is {MAX_MEGABYTES}{' '}
            MB
          </Text>
        </DesktopUpload>

        <MobileUpload>
          <Text tag="p" size="lg" fontStyle="semibold" color={theme.colors.neutral.navy}>
            Tap to upload!
          </Text>

          <Text tag="p" size="xs" color={theme.colors.neutral.navy}>
            PDF, JPEG, JPG and PNG file types are allowed. The maximum file size is {MAX_MEGABYTES}{' '}
            MB
          </Text>
        </MobileUpload>
      </UploadContent>

      <AttachmentsList
        title="Uploaded Files"
        isPatientAttachments
        items={items}
        onDelete={onDelete}
      />

      {showUploadModal && (
        <UploadFileModal
          open={true}
          purposes={purposes}
          uploading={uploading}
          onClose={() => setShowUploadModal(false)}
          onDescriptionEntered={upload}
        />
      )}
    </Container>
  );
};
