import { PatientToDo, graphql } from 'kb-shared';
import { daysSince } from 'kb-shared/utilities/momentHelpers';
import { pageUrl } from 'utilities/pageUrl';

import { MARK_LEARN_ABOUT_KB360_COMPLETE } from './ToDoItems.graphql';

export const PORTAL_FEATURE_DISCOVERY_CATEGORY = 'portal feature discovery';

export const INTAKE_TODO_TITLE = 'Complete intake form';

export const preProcessToDos = (todos: PatientToDo[]) => {
  const filteredTodos: PatientToDo[] = [];
  todos.forEach(todo => {
    const updatedTodo = JSON.parse(JSON.stringify(todo));
    if (todo.title === INTAKE_TODO_TITLE && todo.linkUrl) {
      updatedTodo.linkUrl = pageUrl.intakeForm();
    }

    if (isToDoForHiding(todo) && !todo.completed) {
      graphql.client.mutate({ mutation: MARK_LEARN_ABOUT_KB360_COMPLETE });
      updatedTodo.completed = true;
    } else {
      filteredTodos.push(updatedTodo);
    }
  });
  return filteredTodos;
};

export const isToDoForHiding = (todo: PatientToDo): boolean => {
  if (todo.category === PORTAL_FEATURE_DISCOVERY_CATEGORY) {
    return daysSince(todo.createdAt) > 90;
  }

  return false;
};

export const additionalInfoForIntakeToDo = (title: string | undefined, completionPercent = 0) => {
  if (title !== INTAKE_TODO_TITLE) return;

  return `(${completionPercent}% Completed)`;
};

export const todosForDisplay = (
  todos: PatientToDo[],
  selectedToDo: PatientToDo,
  maxNumberOfDisplayedToDos: number
): PatientToDo[] => {
  const selectedToDoIndex = todos.findIndex(todo => todo.id === selectedToDo.id);
  if (selectedToDoIndex < maxNumberOfDisplayedToDos)
    return todos.slice(0, maxNumberOfDisplayedToDos);

  let todosForDisplay = [];
  todosForDisplay.push(selectedToDo);

  let indexOfItemToAdd = selectedToDoIndex - 1;
  while (todosForDisplay.length < maxNumberOfDisplayedToDos && indexOfItemToAdd >= 0) {
    todosForDisplay = [todos[indexOfItemToAdd], ...todosForDisplay];
    --indexOfItemToAdd;
  }

  indexOfItemToAdd = selectedToDoIndex + 1;
  while (todosForDisplay.length < maxNumberOfDisplayedToDos && indexOfItemToAdd < todos.length) {
    todosForDisplay.push(todos[indexOfItemToAdd]);
    ++indexOfItemToAdd;
  }

  return todosForDisplay;
};

export const todoIndex = (todos: PatientToDo[], todoToFind: PatientToDo): number =>
  todos.findIndex(todo => todo.id === todoToFind.id);
