import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { retrieveFile, CATEGORY } from 'api/pf2/files';
import { SharedUploadModal } from 'components/SharedUploadModal/SharedUploadModal';
import { usePatient } from 'hooks';
import * as redux from 'kb-redux';
import { ReduxState } from 'kb-redux';
import { ProfileImageError } from 'kb-shared';
import { BugTracker } from 'kb-shared/utilities/bugTracker';

import { FileUploadContainer } from '../lib/styled';
import { ProfileImage } from './components/ProfileImage';
import { ImageRow, ImageText } from './ProfileImageUploadButton.styled';
import { ProfileImageUploadButtonProps } from './ProfileImageUploadButton.types';

const {
  patient: { updateProfileImage, profileImageError }
} = redux;

export const ProfileImageUploadButton = (
  props: ProfileImageUploadButtonProps
): JSX.Element | null => {
  const [uploadModalVisible, setUploadModalVisible] = useState(false);
  const dispatch = useDispatch();
  const { isLoggedIn } = usePatient();
  const profileImage = useSelector<ReduxState>(state =>
    state.patient ? state.patient.profileImage : undefined
  ) as string | undefined;

  const profileImageErrorText = useSelector<ReduxState>(
    state => state.patient.profileImageError
  ) as ProfileImageError | null;

  const { size, className, id, clickableLabelText } = props;
  const body = document.body;

  useEffect(() => {
    // usually this is used only when the user is logged in
    // but we had error fixed with KE-11481, where tokenId was not set
    // probably due to some kind of race condition
    // and it was reporting errors to the HB
    if (!isLoggedIn) return;
    getUserImage();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoggedIn]);

  const getUserImage = async () => {
    try {
      // @ts-ignore
      const { file } = await retrieveFile(CATEGORY.PROFILE_PHOTO);
      dispatch(updateProfileImage(file));
    } catch (error) {
      BugTracker.notify(error, 'ProfileImageUploadButton.getUserImage');
      dispatch(
        profileImageError({
          type: 'UnableToLoadImage'
        })
      );
    }
  };

  const onUserImageError = () =>
    dispatch(
      profileImageError({
        type: 'UnableToLoadImage'
      })
    );

  const renderButton = () => {
    return (
      <ImageRow>
        <ProfileImage
          profileImage={profileImage}
          error={profileImageErrorText}
          size={size}
          onError={onUserImageError}
        />
        {clickableLabelText && <ImageText>{clickableLabelText}</ImageText>}
      </ImageRow>
    );
  };

  if (!body) return null;

  return (
    <>
      <FileUploadContainer
        htmlFor={id}
        size={size}
        className={className}
        onClick={() => setUploadModalVisible(!uploadModalVisible)}
      >
        {renderButton()}
      </FileUploadContainer>

      {uploadModalVisible && (
        <SharedUploadModal
          onClose={() => setUploadModalVisible(false)}
          onUploadCompleted={() => setUploadModalVisible(false)}
          category="profile photo"
          visible={uploadModalVisible}
        />
      )}
    </>
  );
};
