import { gql } from '@apollo/client';

export const CUSTOM_DASHBOARD_TILES_QUERY = gql`
  query customDashboardTiles {
    customDashboardTiles {
      id
      name
      tileType
      employerId
      header
      boldText
      linkUrl
      regularText
      buttonText
      buttonStyle
      listItems
      listStyle
      svgIconUrl
    }
  }
`;
