import React from 'react';

import { Button, TitleText, FinishedSubtitle, ContainerStyled } from './AcknowledgeStatus.styles';

const RefreshNeeded: React.FC = () => (
  <ContainerStyled>
    <TitleText>Cycle plan acknowledge failed!</TitleText>
    <FinishedSubtitle>Please refresh your browser and try again.</FinishedSubtitle>

    <Button text="Reload" onClick={() => window.location.reload()} />
  </ContainerStyled>
);

export default RefreshNeeded;
