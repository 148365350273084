import React from 'react';

import { themes } from 'kb-shared';
import styled from 'styled-components';

import { ContainerPadding, Title } from '../styled';

const Container = styled(ContainerPadding)`
  padding-top: 40px;
`;

const Text = styled.div`
  font-size: 16px;
  font-weight: 300;
  color: white;
  padding-bottom: 28px;
`;

const ExplainerContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${themes.colors.neutral.navy};
  margin-bottom: 39px;

  @media screen and (min-width: ${themes.breakpoints.tabletPortrait}) {
    flex-direction: row;
  }
`;

const Explainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: 20px;

  :first-of-type {
    border-bottom: 2px solid ${themes.colors.neutral.primaryNavy};
  }

  @media screen and (min-width: ${themes.breakpoints.tabletPortrait}) {
    :first-of-type {
      border-bottom: 0px solid ${themes.colors.neutral.primaryNavy};
      border-right: 2px solid ${themes.colors.neutral.primaryNavy};
    }
  }
`;

const SectionTitleText = styled.div`
  font-weight: bold;
  color: ${themes.colors.yellow.primary};
  line-height: 1.43;
`;

const SectionText = styled.div`
  font-size: 14px;
  font-weight: 300;
  line-height: 1.43;
  color: white;
`;

const Interperatation = () => {
  return (
    <Container>
      <Title>Interpreting These Results</Title>
      <Text>
        During stimulation, we are monitoring a number of factors to optimize the success of your
        cycle. Everyone's response is different and can vary from month to month. It typically takes
        about 8-12 days of injections to get your ovaries ready.
      </Text>
      <ExplainerContainer>
        <Explainer>
          <SectionTitleText>When medication dosage might need to be adjusted</SectionTitleText>
          <SectionText>
            We may need to adjust the dose of medications if too few, or too many follicles are
            growing. We monitor you closely so that you do not get overstimulated, which can result
            in twins, triplets or medical complications.
          </SectionText>
        </Explainer>
        <Explainer>
          <SectionTitleText>Follicle growth & estrogen levels</SectionTitleText>
          <SectionText>
            When follicles get to 10mm, they will usually increase by 1-2 mm per day, and estrogen
            levels will increase as the follicles grow. You are usually getting ready for the final
            trigger shot when the biggest follicles get to 18-21 mm.
          </SectionText>
        </Explainer>
      </ExplainerContainer>
    </Container>
  );
};

export default Interperatation;
