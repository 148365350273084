import { DEFAULT_PLACEHOLDER_CHAR, DEFAULT_FORMAT_CHARACTERS, ESCAPE_CHAR } from './InputMask';
import { CharsFormatters } from './InputWithMask.types';

export class Pattern {
  placeholderChar = '';
  formatCharacters: CharsFormatters = {};
  source = '';
  pattern: string[] = [];
  length = 0;
  firstEditableIndex: number | null = null;
  lastEditableIndex: number | null = null;
  _editableIndices: { [key: number]: boolean } = {};
  isRevealingMask = false;

  constructor(
    source: string,
    formatCharacters: CharsFormatters,
    placeholderChar: string,
    isRevealingMask: boolean | undefined
  ) {
    if (!(this instanceof Pattern)) {
      return new Pattern(source, formatCharacters, placeholderChar, isRevealingMask);
    }

    this.placeholderChar = placeholderChar || DEFAULT_PLACEHOLDER_CHAR;
    this.formatCharacters = formatCharacters || DEFAULT_FORMAT_CHARACTERS;
    this.source = source;
    this.pattern = [];
    this.length = 0;
    this.firstEditableIndex = null;
    this.lastEditableIndex = null;
    this._editableIndices = {};
    this.isRevealingMask = isRevealingMask || false;

    this._parse();
  }

  private _parse() {
    const sourceChars = this.source.split('');
    let patternIndex = 0;
    const pattern = [];

    for (let i = 0, l = sourceChars.length; i < l; i++) {
      let char = sourceChars[i];
      if (char === ESCAPE_CHAR) {
        if (i === l - 1) {
          throw new Error('InputMask: pattern ends with a raw ' + ESCAPE_CHAR);
        }
        char = sourceChars[++i];
      } else if (char in this.formatCharacters) {
        if (this.firstEditableIndex === null) {
          this.firstEditableIndex = patternIndex;
        }
        this.lastEditableIndex = patternIndex;
        this._editableIndices[patternIndex] = true;
      }

      pattern.push(char);
      patternIndex++;
    }

    if (this.firstEditableIndex === null) {
      throw new Error(
        'InputMask: pattern "' + this.source + '" does not contain any editable characters.'
      );
    }

    this.pattern = pattern;
    this.length = pattern.length;
  }

  public formatValue(value: string[]): string[] {
    const valueBuffer = new Array<string>(this.length);
    let valueIndex = 0;

    for (let i = 0, l = this.length; i < l; i++) {
      if (this.isEditableIndex(i)) {
        if (
          this.isRevealingMask &&
          value.length <= valueIndex &&
          !this.isValidAtIndex(value[valueIndex], i)
        ) {
          break;
        }
        valueBuffer[i] =
          value.length > valueIndex && this.isValidAtIndex(value[valueIndex], i)
            ? this.transform(value[valueIndex], i)
            : this.placeholderChar;
        valueIndex++;
      } else {
        valueBuffer[i] = this.pattern[i];
        // Also allow the value to contain static values from the pattern by
        // advancing its index.
        if (value.length > valueIndex && value[valueIndex] === this.pattern[i]) {
          valueIndex++;
        }
      }
    }

    return valueBuffer;
  }

  public isEditableIndex(index: number): boolean {
    return !!this._editableIndices[index];
  }

  public isValidAtIndex(char: string, index: number): boolean {
    return this.formatCharacters[this.pattern[index]].validate(char);
  }

  public transform(char: string, index: number): string {
    const format = this.formatCharacters[this.pattern[index]];
    return typeof format.transform == 'function' ? format.transform(char) : char;
  }
}
