import { Text } from 'components/v2/Typography/Text';
import styled from 'styled-components';

export const ReassignmentInfoContainer = styled.div`
  display: flex;
  width: 100%;
  max-width: 460px;
  border-radius: 8px;
  background-color: ${({ theme }) => theme.colors.blue.lighterBlue};
  box-shadow: 0px 1px 1px 0px rgba(10, 13, 61, 0.08),
    0px 0.48px 0px 0px ${({ theme }) => theme.colors.lilac.lightLilac};
  justify-content: center;
  align-self: center;
  align-items: center;
  padding: 8px;
  margin: 8px 0;
`;

export const Icon = styled.i`
  display: inline-block;
  width: 24px;
  height: 24px;
  color: ${({ theme }) => theme.colors.blue.darkBlue};
  margin-right: 8px;
  text-align: center;
  line-height: 24px;
`;

export const ReassignmentMessageText = styled(Text).attrs({
  size: 'sm',
  fontStyle: 'medium'
})`
  color: ${({ theme }) => theme.colors.blue.darkBlue};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
