import React from 'react';

import HelperModal from 'screens/Results/HelperModal';

import { ResultType } from '../Results.types';

interface Props {
  open: boolean;
  onDismiss: () => void;
  hormone: ResultType | null;
}

export const HormoneHelperModal = ({ hormone, open, onDismiss }: Props) => (
  <HelperModal
    open={open}
    title={hormone?.fullName || ''}
    copy={hormone?.description}
    onDismiss={onDismiss}
  />
);
