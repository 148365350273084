import React, { useState } from 'react';
import Media from 'react-media';

import kindbody from 'assets/logos/kindbody.svg';
import ContactModal from 'components/Footer/ContactModal';
import { Button } from 'components/v2/Buttons/Button';
import theme from 'kb-shared/theme';
import { isLoadedFromFileSystem } from 'kb-shared/utilities/bugTracker';
import { pageUrl } from 'utilities/pageUrl';

import { ButtonsWrapper, Description, Heading, Logo, Wrapper } from './ErrorFallback.styled';

export const ErrorFallback = () => {
  const [showModal, setShowModal] = useState(false);

  const loadedFromFileSystem = isLoadedFromFileSystem();

  return (
    <Wrapper $topMargin="64px">
      <Logo href={pageUrl.dashboard()}>
        <img src={kindbody} alt="Kindbody logo" />
      </Logo>

      <Heading tag="h1">
        {loadedFromFileSystem
          ? 'Please reload to visit online portal.'
          : 'Oops! Something’s not right.'}
      </Heading>

      {!loadedFromFileSystem && (
        <Media query={{ minWidth: theme.breakpoints.tabletPortrait }}>
          {matchesQuery =>
            matchesQuery ? (
              <Description>
                Just breathe and stay calm —<br />
                we're on it and will have things smoothed out shortly.
              </Description>
            ) : (
              <Description>
                Just breathe and stay calm — we're on it and will have things smoothed out shortly.
              </Description>
            )
          }
        </Media>
      )}

      <ButtonsWrapper>
        <Button
          label="Reload page"
          category="secondary"
          size="md"
          onClick={() => (loadedFromFileSystem ? redirectToPortal() : window.location.reload())}
          uppercase
          fullWidth
        />
        <Button
          label="Contact Support"
          category="tertiary"
          size="md"
          onClick={() => setShowModal(true)}
          uppercase
          fullWidth
        />
      </ButtonsWrapper>

      <ContactModal visible={showModal} onDismiss={() => setShowModal(false)} />
    </Wrapper>
  );
};

const redirectToPortal = () =>
  (window.location.href = process.env.REACT_APP_APPLE_REDIRECT_URL || pageUrl.dashboard());
