import styled from 'styled-components';

import {
  Description as DescriptionBase,
  FlexWrapper as FlexWrapperBase
} from '../styled-components';

export { Link, OutlineButton, Title } from '../styled-components';

export const Description = styled(DescriptionBase)`
  &:last-child {
    margin-bottom: 0px;
  }

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.tabletPortrait}) {
    &:last-child {
      margin-bottom: 28px;
    }
  }
`;

export const FlexWrapper = styled(FlexWrapperBase)`
  margin-bottom: 14px;
  align-items: self-start;
  gap: 12px;

  ${Description} {
    margin-bottom: 14px;
  }

  &:last-child {
    margin-bottom: 0px;
  }

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.tabletPortrait}) {
    &:last-child {
      margin-bottom: 28px;
    }
  }
`;
