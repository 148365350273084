import React from 'react';

import { ReactComponent as ArrowDown } from 'screens/IntakeForm/svg/arrow_down.svg';
import { ReactComponent as ArrowUp } from 'screens/IntakeForm/svg/arrow_up.svg';

import ProgressBar from '../ProgressBar';
import { ArrowButton, Container } from './ProgressFooter.styled';

export const ProgressFooter = ({ percentComplete }: { percentComplete: number }) => {
  return (
    <Container>
      <ProgressBar progress={percentComplete} />
      <ArrowButton onClick={() => window.scrollTo(0, 0)}>
        <ArrowUp />
      </ArrowButton>
      <ArrowButton onClick={() => window.scrollTo(0, document.body.scrollHeight)}>
        <ArrowDown />
      </ArrowButton>
    </Container>
  );
};
