import { useMutation, useQuery } from '@apollo/client';
import React, { useState } from 'react';

import { ReactComponent as IconPerson } from 'assets/icons/MyProfile/person.svg';
import { ReactComponent as IconPhoto } from 'assets/icons/MyProfile/photo.svg';
import { ProfileImageUploadButton } from 'components/PhotoUpload/ProfilePhoto/ProfileImageUploadButton';
import { SelectField, TextField, DateField } from 'components/v2/Form';
import { Heading } from 'components/v2/Typography';
import { utils, GetDropdownOptionsResponse, GenderIdentity } from 'kb-shared';
import { PATIENT, GET_DROPDOWN_OPTIONS } from 'kb-shared/graphql/queries';
import { PatientProfileResponseData } from 'kb-shared/graphql/types';
import { getGraphQLErrorsMessages } from 'utilities/errors';
import { formatDate, stringToDate } from 'utilities/formatDate';
import { showErrorToast } from 'utilities/notificationUtils';

import { UPDATE_PATIENT_PROFILE } from './PatientFields.graphql';
import { ThreeColumnGrid, Icon, Container } from './shared.styled';

const { PRONOUN_INPUT_DEFAULTS } = utils;

type Props = {
  id?: string;
  firstName?: string;
  lastName?: string;
  pronoun?: string;
  gender?: string;
  birthday?: string;
  genderIdentity?: GenderIdentity;
  identifier?: string | undefined;
};

export function PatientFields({
  firstName,
  lastName,
  pronoun,
  birthday,
  gender,
  genderIdentity,
  identifier,
  id
}: Props) {
  const [profileDetails, setProfileDetails] = useState({
    firstName,
    lastName,
    pronoun,
    birthday: birthday ? formatDate(birthday) : '',
    genderIdentity,
    identifier
  });

  const [updateProfile] = useMutation<PatientProfileResponseData>(UPDATE_PATIENT_PROFILE, {
    refetchQueries: [{ query: PATIENT }]
  });

  const { data } = useQuery<GetDropdownOptionsResponse>(GET_DROPDOWN_OPTIONS, {
    variables: { key: 'gender_identity' }
  });

  const pronounInput = PRONOUN_INPUT_DEFAULTS.find(opt => opt.value === profileDetails.pronoun);
  const genderIdentityOptions = data?.getDropdownOptions;
  const genderIdentityInput =
    genderIdentityOptions &&
    genderIdentityOptions.find(opt => opt.value === profileDetails.genderIdentity);

  const handlePatientDataChange = (key: string, value: string) => {
    setProfileDetails({
      ...profileDetails,
      [key]: value
    });
  };

  const updatePatientIdentifier = () => {
    updateProfile({
      variables: {
        ...profileDetails,
        identifier: profileDetails.identifier
      }
    }).catch(error => {
      const graphqlError = getGraphQLErrorsMessages(error);
      showErrorToast(
        graphqlError.length > 0
          ? graphqlError.join(', ')
          : 'Failed to update preferred name. Please try again.'
      );
    });
  };

  return (
    <Container>
      <div>
        <Heading tag="h2" styledAs="h4">
          <Icon>
            <IconPhoto />
          </Icon>
          My Photo
        </Heading>

        <ProfileImageUploadButton
          id="my-profile-profile-image-upload"
          size={59}
          clickableLabelText="CLICK TO UPLOAD OR REPLACE"
        />
      </div>

      <div>
        <Heading tag="h2" styledAs="h4">
          <Icon>
            <IconPerson />
          </Icon>
          Legal name
        </Heading>

        <ThreeColumnGrid>
          <TextField
            status="readOnly"
            label="First Name"
            id="first-name-input"
            value={profileDetails.firstName}
          />
          <TextField
            status="readOnly"
            label="Last Name"
            id="last-name-input"
            value={profileDetails.lastName}
          />
          <TextField
            label="Preferred Name"
            id="preferred-name-input"
            placeholder="Enter preferred name"
            value={profileDetails.identifier}
            onChange={e => handlePatientDataChange('identifier', e.currentTarget.value)}
            onBlur={updatePatientIdentifier}
          />

          <DateField
            status="readOnly"
            id="birthday-input"
            label="Birthdate"
            dateFormat="MM/dd/yyyy"
            placeholder="MM/DD/YYYY"
            value={stringToDate(profileDetails.birthday)}
          />

          <TextField
            status="readOnly"
            id="medical-record-number-input"
            label="Medical Record Number (MRN)"
            value={id}
          />

          <TextField
            id="sex-at-birth-input"
            status="readOnly"
            label="Sex assigned at Birth"
            value={gender}
          />

          <SelectField
            id="pronoun-select"
            selected={pronounInput}
            options={PRONOUN_INPUT_DEFAULTS}
            label="Pronoun"
            placeholder="Select your pronoun"
            onChange={option => {
              setProfileDetails({ ...profileDetails, pronoun: option.value });
              updateProfile({
                variables: {
                  genderIdentity: profileDetails.genderIdentity,
                  pronoun: option.value
                }
              }).catch(error => {
                const errors = getGraphQLErrorsMessages(error);
                const errorCopy = errors.join(', ');
                return showErrorToast(errorCopy);
              });
            }}
          />

          <SelectField
            id="gender-identity-select"
            label="Gender Identity"
            placeholder="Select gender identity"
            selected={genderIdentityInput}
            options={genderIdentityOptions ?? []}
            onChange={option => {
              // @ts-ignore
              setProfileDetails({ ...profileDetails, genderIdentity: option.value });
              updateProfile({
                variables: {
                  pronoun: profileDetails.pronoun,
                  genderIdentity: option.value
                }
              }).catch(error => {
                const errors = getGraphQLErrorsMessages(error);
                const errorCopy = errors.join(', ');
                return showErrorToast(errorCopy);
              });
            }}
          />
        </ThreeColumnGrid>
      </div>
    </Container>
  );
}
