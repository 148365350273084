import styled from 'styled-components';

// TODO: should be replaced by button new button component
// plus icon on the left side is also missing

export const AddPartner = styled.button`
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;
  margin-top: 20px;
  margin-bottom: 32px;
  margin-left: 20px;
  padding-left: 4px;
  text-transform: uppercase;
  display: flex;
  align-items: center;
`;

export const PlusIcon = styled.img`
  width: 24px;
  height: 24px;
  margin: 4px 24px 4px 4px;
`;
