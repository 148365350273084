import React from 'react';

import { BoldText, Contact } from './AdditionalInfo.styled';

export const AdditionalInfoProviderSearch = (): JSX.Element => {
  return (
    <>
      <BoldText>
        For booking assistance or to book an in person appointment please{' '}
        <a
          href="https://kindbody.com/assessment-booking-request/"
          target="_blank"
          rel="noreferrer noopener"
        >
          email
        </a>{' '}
        or call us at <a href="tel://855-563-2639">855-563-2639</a>.
      </BoldText>
      <br />
      If you would like to book with a specific doctor, please visit our{' '}
      <Contact
        href="https://kindbody.com/kindbody-doctors/"
        target="_blank"
        rel="noreferrer noopener"
      >
        doctor pages
      </Contact>
      .
    </>
  );
};
