import React, { ReactNode } from 'react';

import { ResultCategoryType } from '../Results.types';

export type CopyType = { [key in ResultCategoryType]: { title: string; copy: ReactNode } };

export const Copy: CopyType = {
  other: {
    title: '',
    copy: ''
  },
  hormones_and_vitamins: {
    title: 'Hormones & Vitamins',
    copy:
      "A critical piece of the women's health puzzle! We check things like TSH to understand your thyroid function, and AMH to understand your ovarian reserve. Vitamin D is also a critical test and very common deficiency. It's very important for bone and hormone health. We'll recommend a Vitamin D supplement if your levels are low."
  },
  blood_profile: {
    title: 'Blood Profile',
    // @ts-ignore
    copy: (
      <div>
        It&apos;s very important to know your blood type before an medical procedure or pregnancy.
        We also check for anemia, and will recommend an iron supplement if needed. Hemoglobin
        electrophoresis may be done to see if you are a carrier for any genetic anemias, which may
        require having your partner or sperm donor being screened as well.{' '}
        <b>Note that being a carrier alone does not put you or your future baby at risk.</b>
      </div>
    )
  },
  diseases_and_infections: {
    title: 'Diseases & Infections',
    // @ts-ignore
    copy: (
      <div>
        We check on your immunity to things like Rubella and Varicella. It&apos;s important that you
        get vaccinated before pregnancy, and at least 2 months before you start trying to conceive.{' '}
        <b>You do not need to be immune to freeze your eggs. </b>
        <br />
        <br />
        STD screening is also important, and we will treat any STDs before initiating a fertility
        treatment.
      </div>
    )
  }
};
