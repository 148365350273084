import { themes } from 'kb-shared';
import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  display: flex;
  height: 70px;
  background-color: ${themes.colors.yellow.lightestYellow};
  justify-content: space-between;
  box-shadow: 0 2px 8px 0 #d8d8d8;
  padding: 0px 20px 0px 20px;
  overflow: visible;
  z-index: 1;
  @media screen and (min-width: ${themes.breakpoints.largePhonePortrait}) {
    padding: 0px 70px 0px 70px;
  }
  @media screen and (max-width: ${themes.breakpoints.smallDesktop}) {
    flex-direction: column;
    height: 90px;
    padding-top: 10px;
  }
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  @media screen and (max-width: ${themes.breakpoints.smallDesktop}) {
    justify-content: space-between;
    margin-top: 6px;
  }
`;

export const LogoAndProgressWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const DashboardNavigation = styled.div`
  cursor: pointer;
  width: 100%;
  padding-left: 12px;
  text-decoration: underline;
  @media screen and (max-width: ${themes.breakpoints.largePhonePortrait}) {
    font-size: 13px;
  }
`;

export const TitleWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  @media screen and (max-width: ${themes.breakpoints.smallDesktop}) {
    justify-content: flex-start;
  }
`;

export const Title = styled.div`
  font-size: 16px;
  min-width: 110px;
  height: 24px;
  @media screen and (max-width: ${themes.breakpoints.largePhonePortrait}) {
    display: none;
  }
`;

export const SelectWrapper = styled.div`
  width: 200px;
  @media screen and (max-width: ${themes.breakpoints.largePhonePortrait}) {
    width: 100%;
  }
  > div {
    > div {
      > div {
        padding-left: 0;
        padding-right: 0;
        border-bottom: none;
        background-color: ${themes.colors.yellow.lightestYellow};

        > div {
          padding: 0;
        }
      }
    }
    > div div {
      font-size: 16px !important;
    }
  }
`;

export const CompletedLabel = styled.div`
  background: ${({ theme }) => theme.colors.neutral.primaryNavy};
  border-radius: 8px;
  color: #fff;
  text-align: center;
  padding: 6px 12px;
  margin: 0 0 0 16px;
  line-height: 1.2;
  span {
    display: none;
  }
  @media screen and (min-width: ${({ theme }) => theme.breakpoints.tabletPortrait}) {
    span {
      display: inline;
    }
  }
`;
