import { Patient, SignUpProps } from 'kb-shared';
import { utils } from 'kb-shared';
import { isEpicUser, isMedtronicUser } from 'kb-shared/utilities/api';
import {
  isDateValid,
  isDOBValid,
  isEmailValid,
  isInputValid,
  isPasswordValid,
  isPhoneNumberValid
} from 'kb-shared/utilities/validation';

import { SIGNUP_TYPE, SignUpData } from './userUtil.types';

const { GENDER_INPUT_DEFAULTS, GENDER_IDENTITY_DEFAULTS, US_COUNTRY_OPTION } = utils;

export const handleKeySubmit = (key: number, onClick: () => void) => {
  // Enter = 13
  const ENTER_KEY = 13;
  // Space = 32
  const SPACE_KEY = 32;
  switch (key) {
    case ENTER_KEY:
      onClick();
      break;
    case SPACE_KEY:
      onClick();
      break;
    default:
      break;
  }
};

export const getDefaultGenderIdentity = () =>
  GENDER_IDENTITY_DEFAULTS[GENDER_IDENTITY_DEFAULTS.length - 1];

export const getUserDisplayName = (patient: Patient): string =>
  patient.identifier || patient.firstName || '';

export const isUS = (country: string): boolean =>
  country === US_COUNTRY_OPTION.label || country === US_COUNTRY_OPTION.value;

const isEmptyString = (str: string) => str.match(/^ *$/) !== null;

export const isReferralSourceDetailsVisible = (referralSource: string): boolean =>
  referralSource === 'Influencer' || referralSource === 'Referring Provider';

export const hasEmailInput = (signUpType: SIGNUP_TYPE) =>
  signUpType === SIGNUP_TYPE.EMAIL ||
  signUpType === SIGNUP_TYPE.MEDTRONIC ||
  signUpType === SIGNUP_TYPE.DISNEY;

export const hasPasswordInput = (signUpType: SIGNUP_TYPE) => signUpType === SIGNUP_TYPE.EMAIL;

export const validateSignUpData = (
  inputState: SignUpProps,
  signupType: SIGNUP_TYPE
): { [key: string]: boolean } => {
  const { firstName, lastName, email, password, labId } = inputState;

  const errors: { [key: string]: boolean } = {};

  if (hasEmailInput(signupType) && !isEmailValid(email)) {
    errors.email = true;
  }

  if (!isInputValid(firstName, 2)) {
    errors.firstName = true;
  }
  if (!isInputValid(lastName, 2)) {
    errors.lastName = true;
  }

  validateCommonProps(inputState, errors);

  if (signupType !== SIGNUP_TYPE.DISNEY && !labId) {
    errors.patientLab = true;
  }

  if (hasPasswordInput(signupType) && (password == null || !isPasswordValid(password))) {
    errors.password = true;
  }

  validateAddress(inputState, errors);

  return errors;
};

const validateCommonProps = (inputState: SignUpProps, errors: { [key: string]: boolean }) => {
  const {
    gender,
    birthdate,
    phone,
    address,
    referralSource,
    referralSourceDetails,
    preferredTimezone
  } = inputState;

  if (!isInputValid(gender)) {
    errors.gender = true;
  }
  if (!isDOBValid(birthdate)) {
    errors.birthdate = true;
  }
  if (!isDateValid(birthdate)) {
    errors.birthdate = true;
  }

  if (isUS(address.country) && !isPhoneNumberValid(phone)) {
    errors.phone = true;
  }
  if (!isUS(address.country) && phone.length <= 1) {
    errors.phone = true;
  }

  if (!referralSource || isEmptyString(referralSource)) {
    errors.referralSource = true;
  }

  if (
    isReferralSourceDetailsVisible(referralSource) &&
    !referralSourceDetails &&
    isEmptyString(referralSourceDetails)
  ) {
    errors.referralSourceDetails = true;
  }

  if (!preferredTimezone || isEmptyString(preferredTimezone)) {
    errors.preferredTimezone = true;
  }
};

const validateAddress = (inputState: SignUpProps, errors: { [key: string]: boolean }) => {
  const { address } = inputState;

  if (isEmptyString(address.address1)) {
    errors.address1 = true;
  }
  if (isEmptyString(address.city)) {
    errors.city = true;
  }
  if (isUS(address.country) && isEmptyString(address.state)) {
    errors.state = true;
  }
  if (isUS(address.country) && isEmptyString(address.zipcode)) {
    errors.zipcode = true;
  }
  if (isEmptyString(address.country)) {
    errors.country = true;
  }
};

export const collectErrorMessagesForFieldsWithError = (errors: {
  [key: string]: boolean;
}): string[] => {
  const errorMessages = [];

  if (errors.email) {
    errorMessages.push('Please enter a valid email.');
  }
  if (errors.firstName) {
    errorMessages.push('Please enter a valid first name.');
  }
  if (errors.lastName) {
    errorMessages.push('Please enter a valid last name.');
  }
  if (errors.country) {
    errorMessages.push('Please select a country.');
  }
  if (errors.address1) {
    errorMessages.push('Please enter an address line.');
  }
  if (errors.state) {
    errorMessages.push('Please select a state.');
  }
  if (errors.city) {
    errorMessages.push('Please enter a city name.');
  }
  if (errors.zipcode) {
    errorMessages.push('Please enter a Zip Code.');
  }
  if (errors.phone) {
    errorMessages.push('You must provide a phone number.');
  }
  if (errors.birthdate) {
    errorMessages.push(
      'Please enter or select a valid date. You must be 18 years or older to schedule a consult.'
    );
  }
  if (errors.gender) {
    errorMessages.push('You must select a gender.');
  }
  if (errors.patientLab) {
    errorMessages.push('You must select a location.');
  }
  if (errors.referralSource) {
    errorMessages.push('Please let us know how you heard about us.');
  }
  if (errors.referralSourceDetails) {
    errorMessages.push('Please enter their name.');
  }
  if (errors.preferredTimezone) {
    errorMessages.push('Please select a time zone.');
  }
  if (errors.password) {
    errorMessages.push(
      'Please enter a valid password. At least 8 characters with uppercase, lowercase, and special characters.'
    );
  }

  return errorMessages;
};

export const defaultOption = { label: '', value: '' };

export const getInitialPatientData = (patient: Patient | undefined) => {
  const initialPatientData: SignUpData = {
    patient: {
      birthdate: '',
      email: '',
      firstName: '',
      gender: GENDER_INPUT_DEFAULTS[1].value, // female
      genderIdentity: getDefaultGenderIdentity().value,
      lastName: '',
      password: '',
      phone: '',
      preferredName: '',
      referralSource: '',
      referralSourceDetails: '',
      pronoun: '',
      labId: 0,
      preferredTimezone: '',
      sendSmsAppointmentNotification: false,
      sendMarketingEmail: true,
      address: {
        address1: '',
        address2: '',
        city: '',
        zipcode: '',
        state: '',
        country: US_COUNTRY_OPTION.value
      }
    },
    countryOption: US_COUNTRY_OPTION,
    errors: { password: false },
    genderIdentityOption: getDefaultGenderIdentity(),
    genderOption: GENDER_INPUT_DEFAULTS[1], // { label: 'female', value: 'female' }
    patientLab: null,
    labOption: defaultOption,
    pronounOption: defaultOption,
    stateOption: defaultOption,
    preferredTimeZoneOption: defaultOption,
    emailInputValid: undefined,
    firstNameInputValid: undefined,
    lastNameInputValid: undefined,
    address1InputValid: undefined,
    stateInputValid: undefined,
    cityInputValid: undefined,
    zipcodeInputValid: undefined,
    phoneInputValid: undefined,
    birthdateInputValid: undefined,
    preferredTimezoneInputValid: undefined,
    patientLabInputValid: undefined,
    referralSourceInputValid: undefined,
    referralSourceDetailsInputValid: undefined,
    passwordInputValid: undefined
  };

  if (patient == null) return initialPatientData;

  initialPatientData.patient.email = patient.email;
  initialPatientData.patient.firstName = patient.firstName;
  initialPatientData.patient.lastName = patient.lastName;

  return initialPatientData;
};

export const getPostLogoutLandingUrl = () => {
  if (isEpicUser()) {
    return 'https://www.mypremisehealth.com/MyChart/Authentication/Login';
  }
  if (isMedtronicUser()) {
    return 'https://login.microsoftonline.com/common/wsfederation?wa=wsignout1.0';
  }

  return '';
};
