import React, { PropsWithChildren } from 'react';

import { Container, LabelText, IconContainer } from './Label.styled';
import { Props } from './Label.types';
import { getIconSize } from './Label.utils';

export const Label = ({
  children,
  size,
  type,
  icon,
  iconLeft,
  iconRight,
  width
}: PropsWithChildren<Props>) => {
  const iconSize = getIconSize(size);

  return (
    <Container
      size={size}
      type={type}
      width={width}
      icon={icon}
      iconLeft={iconLeft}
      iconRight={iconRight}
    >
      {iconLeft && (
        <IconContainer position="left" size={iconSize}>
          {iconLeft}
        </IconContainer>
      )}
      {icon ? (
        <IconContainer position="left" size={iconSize}>
          {icon}
        </IconContainer>
      ) : (
        <LabelText size="sm">{children}</LabelText>
      )}
      {iconRight && (
        <IconContainer position="right" size={iconSize}>
          {iconRight}
        </IconContainer>
      )}
    </Container>
  );
};
