import React from 'react';

import { CheckMark, XMark } from 'assets/icons/Icons';

import {
  CheckMarkContainer,
  CollateContainer,
  CollateDescription,
  CollateTitle,
  XMarkContainer
} from './Collate.styled';
import { CollateProps, CollateType } from './Collate.types';
export const Collate = (props: CollateProps) => {
  const { type, title, description, additionalDescription } = props;
  return (
    <CollateContainer type={type}>
      <div>
        {type === CollateType.ERROR ? (
          <XMarkContainer>
            <XMark type="solid" />
          </XMarkContainer>
        ) : (
          <CheckMarkContainer>
            <CheckMark type="solid" />
          </CheckMarkContainer>
        )}
      </div>
      <div>
        <CollateTitle type={type}>{title}</CollateTitle>
        <CollateDescription>{description}</CollateDescription>
        {additionalDescription}
      </div>
    </CollateContainer>
  );
};
