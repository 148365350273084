import { ObJourneyData } from '../../types';

// We assume a 40 weeks gestation period
const GESTATION_PERIOD_DAYS = 40 * 7;

function addDays(date: Date, days: number) {
  const result = new Date(date);
  result.setDate(result.getDate() + days);
  return result;
}

/**
 * Calculates whether a pre-delivery time based step is completed
 * @param stepEndOffsetInDays offset in days from start of pregnancy to the end of step
 * @param edd Expected delivery date
 * @returns
 */
const isPreDeliveryComplete = (stepEndOffsetInDays: number, edd?: Date) => {
  if (!edd) {
    return false;
  }

  const gestationStart = addDays(edd, -GESTATION_PERIOD_DAYS);
  const stepEndDate = addDays(gestationStart, stepEndOffsetInDays);
  const currentDate = new Date();

  return stepEndDate < currentDate;
};

/**
 * Calculates whether a post-delivery time based step is completed
 * @param stepEndOffsetInDays offset in days from delivery of pregnancy to the of step
 * @param deliveryDate Delivery date
 * @returns
 */
const isPostDeliveryComplete = (stepEndOffsetInDays: number, deliveryDate?: Date) => {
  if (!deliveryDate) {
    return false;
  }

  const stepEndDate = addDays(deliveryDate, stepEndOffsetInDays);
  const currentDate = new Date();

  return stepEndDate < currentDate;
};

const preDeliveryStepFactory = (title: string, offsetInDays: number) => ({
  finished,
  expectedDeliveryDate,
  deliveryDate
}: ObJourneyData) => ({
  title: title,
  isComplete:
    finished || !!deliveryDate || isPreDeliveryComplete(offsetInDays, expectedDeliveryDate)
});

const postDeliveryStepFactory = (title: string, offsetInDays: number) => ({
  finished,
  deliveryDate
}: ObJourneyData) => ({
  title: title,
  isComplete: finished || isPostDeliveryComplete(offsetInDays, deliveryDate)
});

export const stepFactories = {
  initialVisit: {
    title: 'New OB Visit',
    isComplete: true
  },
  week10to12: preDeliveryStepFactory('10-12 weeks', 12 * 7),
  week16: preDeliveryStepFactory('16 weeks', 16 * 7),
  week20: preDeliveryStepFactory('20 weeks', 20 * 7),
  week24: preDeliveryStepFactory('24 weeks', 24 * 7),
  week26to28: preDeliveryStepFactory('26-28 weeks', 28 * 7 - 1),
  week28: preDeliveryStepFactory('28 weeks', 28 * 7),
  week30: preDeliveryStepFactory('30 weeks', 30 * 7),
  week32: preDeliveryStepFactory('32 weeks', 32 * 7),
  week35: preDeliveryStepFactory('35 weeks', 35 * 7),
  week36: preDeliveryStepFactory('36 weeks', 36 * 7),
  week37: preDeliveryStepFactory('37 weeks', 37 * 7),
  week38: preDeliveryStepFactory('38 weeks', 38 * 7),
  week39: preDeliveryStepFactory('39 weeks', 39 * 7),
  week40: preDeliveryStepFactory('40 weeks', 40 * 7),
  week40and3days: preDeliveryStepFactory('40 weeks 3 days', 40 * 7 + 3),
  week40and6days: preDeliveryStepFactory('40 weeks 6 days', 40 * 7 + 6),
  week41: preDeliveryStepFactory('41 weeks', 41 * 7),
  week41and2days: preDeliveryStepFactory('41 weeks 2 days', 41 * 7 + 2),
  delivery: postDeliveryStepFactory('Delivery', 0),
  postPartumDay2: postDeliveryStepFactory('Postpartum Day 2', 2),
  postPartumDay7: postDeliveryStepFactory('Postpartum Day 7', 7),
  postPartumDay14: postDeliveryStepFactory('Postpartum Day 14', 14),
  postPartumWeek4: postDeliveryStepFactory('Postpartum 4 weeks', 4 * 7),
  postPartumWeek6: postDeliveryStepFactory('Postpartum 6 weeks', 6 * 7),

  // Steps used in compact version (Dashboard tile)
  firstTrimester: preDeliveryStepFactory('First Trimester', 30 * 3),
  secondTrimester: preDeliveryStepFactory('Second Trimester', 2 * 30 * 3),
  thirdTrimester: preDeliveryStepFactory('Third Trimester', 3 * 30 * 3),
  postPartum: postDeliveryStepFactory('Postpartum', 1)
};
