import React from 'react';

import { BoldText, Contact } from './AdditionalInfo.styled';
import { AdditionalInfoProps } from './AdditionalInfo.types';
import { AdditionalInfoProviderSearch } from './AdditionalInfoProviderSearch';
import { AdditionalInfoVirtual } from './AdditionalInfoVirtual';

export const AdditionalInfo = (props: AdditionalInfoProps): JSX.Element => {
  if (props.requiresPointerToDoctorPages) return <AdditionalInfoProviderSearch />;
  if (props.isVirtual) return <AdditionalInfoVirtual />;

  return (
    <>
      <BoldText>
        Do you need more immediate scheduling or need help booking with a specific provider?
      </BoldText>
      <br />{' '}
      <Contact href="https://kindbody.com/assessment-booking-request">
        Contact our patient navigators
      </Contact>{' '}
      or call <Contact href={`tel://855-563-2639`}>855-563-2639</Contact>
    </>
  );
};
