import React from 'react';

import { OrderedList, UnorderedList } from '../CustomWidget.styled';
import { ListItem } from './ListItem';

export const CustomList = ({ type, items }: { type: 'numbered' | 'bulleted'; items: string }) => {
  const displayItems = (items: string) => {
    const itemArray = items.split('\n').filter(item => !!item);

    return itemArray.map((item: string, index: number) => (
      <li key={index}>
        <ListItem item={item} />
      </li>
    ));
  };

  return type === 'numbered' ? (
    <OrderedList>{displayItems(items)}</OrderedList>
  ) : (
    <UnorderedList>{displayItems(items)}</UnorderedList>
  );
};
