import React, { useState } from 'react';

import { Seedling } from 'assets/icons/Icons';
import { Heading, Text } from 'components/v2/Typography';
import { themes } from 'kb-shared';
import theme from 'kb-shared/theme';
import { analytics } from 'utilities/analytics';

import {
  IconContainer,
  IllustratedBackgroundWidget,
  WidgetTitleContainer
} from '../Widgets/Widgets.styled';
import { EggCount } from './components/EggCount/EggCount';
import { LiveBirth } from './components/LiveBirth/LiveBirth';
import { TabItem, Tabs } from './FertilityCalculator.styled';

export const FertilityCalculator = ({ patientIsFemale }: { patientIsFemale: boolean }) => {
  const [calculator, setCalculator] = useState<'egg-count' | 'live-birth'>('egg-count');

  if (!patientIsFemale) return null;
  return (
    <IllustratedBackgroundWidget $variant={6} $bgColor={theme.colors.semantic.lightPink}>
      <IconContainer $iconWidth={48} $iconHeight={48} $color={themes.colors.semantic.red}>
        <Seedling type="solid" />
      </IconContainer>
      <WidgetTitleContainer $noMargin>
        <Heading tag="div" styledAs="h2">
          Fertility Calculators
        </Heading>
      </WidgetTitleContainer>
      <Tabs>
        <TabItem
          active={calculator === 'egg-count'}
          onClick={() => {
            analytics.track(analytics.EVENTS.EGG_COUNT_TAB_SELECTED);
            setCalculator('egg-count');
          }}
        >
          <Text fontStyle={calculator === 'egg-count' ? 'semibold' : 'regular'}>Egg Count</Text>
        </TabItem>
        <TabItem
          active={calculator === 'live-birth'}
          onClick={() => {
            analytics.track(analytics.EVENTS.LIVE_BIRTH_TAB_SELECTED);
            setCalculator('live-birth');
          }}
        >
          <Text fontStyle={calculator === 'live-birth' ? 'semibold' : 'regular'}>Live Birth</Text>
        </TabItem>
      </Tabs>
      <div>
        <Text tag="p" size="lg">
          Get a rough sense of what you can expect out of an egg freezing cycle using key data
          points you'll receive during your initial assessment.
        </Text>
        <Text tag="p" size="lg">
          Your{' '}
          <Text tag="strong" size="lg">
            anti-Müllerian hormone (AMH)
          </Text>{' '}
          level will be measured with a blood test, and your{' '}
          <Text tag="strong" size="lg">
            {' '}
            antral follicle count (AFC)
          </Text>{' '}
          will be determined with an ultrasound.
        </Text>
      </div>
      {calculator === 'egg-count' ? <EggCount /> : <LiveBirth />}
    </IllustratedBackgroundWidget>
  );
};
