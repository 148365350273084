import { useMutation } from '@apollo/client';
import React, { FormEvent, useState, useEffect, useRef } from 'react';

import { Button } from 'components/v2/Buttons/Button';
import { TextField, DateField } from 'components/v2/Form';
import { useTooltip } from 'hooks';
import { UPDATE_DECLARED_PARTNER } from 'kb-shared/graphql/mutations';
import { BugTracker } from 'kb-shared/utilities/bugTracker';
import { isEmailValid, isBirthDateValid } from 'kb-shared/utilities/validation';
import { formatAsList } from 'screens/InterstitialModal/InterstitialModal.utils';
import { TwoColumnContent } from 'screens/styled_common';
import { analytics } from 'utilities/analytics';
import { getUtcDate, dateToString } from 'utilities/formatDate';
import { showErrorToast } from 'utilities/notificationUtils';

import { useCreatePartnerInvite } from '../../hooks/useCreatePartnerInvite';
import { STATUS } from '../../InterstitialModal.graphql';
import {
  InvitePartnerButtonContainer,
  OutlineIndent,
  TooltipTrigger
} from './InvitePartner.styled';

type InvitePartnerProps = { onDone: () => void };

type FormState = {
  firstName: string;
  lastName: string;
  email: string;
  birthDate: Date | null;
};

export const InvitePartner = ({ onDone }: InvitePartnerProps) => {
  const tooltipTrigger = useRef<HTMLDivElement>(null);
  const { createPartnerInvite, loading, error } = useCreatePartnerInvite();
  const [updatePatient] = useMutation(UPDATE_DECLARED_PARTNER);
  const [formState, setFormState] = useState<FormState>({
    firstName: '',
    lastName: '',
    email: '',
    birthDate: null
  });

  const { firstName, lastName, email, birthDate } = formState;
  const isEmailEntered = email && isEmailValid(email);
  const isFormValid = Boolean(
    firstName && lastName && isEmailEntered && birthDate && isBirthDateValid(birthDate)
  );
  const tooltipFieldStates = [
    { name: 'first name', valid: Boolean(firstName) },
    { name: 'last name', valid: Boolean(lastName) },
    { name: 'email', valid: isEmailEntered },
    { name: 'birth date', valid: birthDate && isBirthDateValid(birthDate) }
  ];
  const tooltipInvalidFieldNames = tooltipFieldStates
    .filter(field => !field.valid)
    .map(field => field.name);

  const formattedInvalidFieldNames = formatAsList(tooltipInvalidFieldNames, '');

  useTooltip({
    triggerElementRef: tooltipTrigger,
    content: `Please enter a valid ${formattedInvalidFieldNames}`,
    visible: !isFormValid
  });

  useEffect(() => {
    if (!error) return;

    if (error.message?.includes('Email already received invitation')) {
      showErrorToast('This email address already received invitation.');
      return;
    }

    if (error.message?.includes("You can't send invite to existing partner")) {
      showErrorToast("You can't send invite to existing partner");
      return;
    }

    showErrorToast('Something went wrong while trying to add partner. Please try again later.');
    BugTracker.notify(error, 'Send Partner Invite Error');
  }, [error]);

  const updateFormState = (key: string, event: FormEvent<HTMLInputElement>) => {
    const value = event?.currentTarget?.value;
    setFormState({ ...formState, [key]: value });
  };

  const updateDate = (date: Date | null) => {
    setFormState({ ...formState, birthDate: date });
  };

  const onSendInvite = async () => {
    const formattedDate = birthDate ? dateToString(getUtcDate(birthDate)) : '';

    let response;
    try {
      response = await createPartnerInvite(email, formattedDate, firstName, lastName);
    } catch {
      return;
    }
    const partnerInvite = response?.data?.createPartnerInvite?.partnerInvite;

    if (partnerInvite) onDone();

    updatePatient({
      variables: { hasPartnersPatientDeclared: true },
      refetchQueries: [{ query: STATUS }]
    }).catch(error => {
      BugTracker.notify(error, 'Declaring partner status failed');
    });

    analytics.track(analytics.EVENTS.PARTNER_INVITE_SENT);
  };

  return (
    <OutlineIndent>
      <TwoColumnContent>
        <TextField
          required
          status="default"
          type="text"
          id="input-first-name"
          placeholder="Enter partner’s legal first name"
          label="partner legal first name"
          value={formState.firstName}
          onChange={event => updateFormState('firstName', event)}
        />
        <TextField
          required
          status="default"
          type="text"
          id="input-last-name"
          placeholder="Enter partner’s legal last name"
          label="partner legal last name"
          value={formState.lastName}
          onChange={event => updateFormState('lastName', event)}
        />
        <TextField
          required
          status="default"
          type="text"
          id="input-email"
          placeholder="Enter partner’s email address"
          label="partner email address"
          value={formState.email}
          onChange={event => updateFormState('email', event)}
        />
        <DateField
          required
          status="default"
          id="input-birth-date"
          placeholder="Select partner’s date of birth"
          label="partner date of birth"
          value={formState.birthDate}
          onChange={date => updateDate(date)}
        />
        <InvitePartnerButtonContainer>
          <Button label={'Cancel'} onClick={onDone} category="secondary" />
        </InvitePartnerButtonContainer>
        <InvitePartnerButtonContainer>
          <TooltipTrigger ref={tooltipTrigger}>
            <Button
              label={'Send invitation'}
              onClick={onSendInvite}
              category="primary"
              isDisabled={loading || !isFormValid}
            />
          </TooltipTrigger>
        </InvitePartnerButtonContainer>
      </TwoColumnContent>
    </OutlineIndent>
  );
};
