import { useQuery } from '@apollo/client';
import moment from 'moment';
import React from 'react';

import { AppointmentType, Clinic } from 'kb-shared';
import getAppointmentChannel from 'utilities/getAppointmentChannel';

import AppointmentTimeCard from './../../components/BookingCalendarTime/AppointmentTimeCard';
import {
  TimeSlotsAvailableQueryResponse,
  TIMESLOTS_AVAILABLE_QUERY,
  ProviderNameQueryResponse,
  PROVIDER_NAME_QUERY
} from './graphql/queries';
import { DEFAULT_FALLBACK } from './MissingTimeSlotsFallback';

interface Props {
  locationId: number | null;
  labId: number | null;
  fallback?: React.ReactNode;
  children?: React.ReactNode;
  selectedClinic: Clinic | null;
  appointmentType: AppointmentType;
  providerId: number | undefined;
}

export const AppointmentTimeSlotsSelection = ({
  locationId,
  labId,
  fallback,
  selectedClinic,
  appointmentType,
  children,
  providerId
}: Props) => {
  const fallbackComponent: React.ReactNode = fallback ?? DEFAULT_FALLBACK;

  const { data, loading } = useQuery<TimeSlotsAvailableQueryResponse>(TIMESLOTS_AVAILABLE_QUERY, {
    variables: {
      appointmentTypeId: parseInt(appointmentType.id),
      locationId: locationId ? locationId : null,
      labId: labId,
      startDateTime: moment.utc().startOf('month'),
      providerId: providerId
    }
  });

  const { data: providerData, loading: providerDataLoading } = useQuery<ProviderNameQueryResponse>(
    PROVIDER_NAME_QUERY,
    {
      variables: {
        providerId: providerId
      },
      skip: providerId == null
    }
  );

  const { isInPerson } = getAppointmentChannel(appointmentType);

  if (loading || providerDataLoading) return null;

  const hasTimeSlots = data?.hasTimeSlots;
  const providerName = providerData?.providerName;
  const appointmentLocation =
    isInPerson && selectedClinic
      ? selectedClinic?.address
      : 'Virtual, with follow-ups in your preferred Kindbody clinic';

  return (
    <>
      {selectedClinic && !appointmentType?.virtual && hasTimeSlots && (
        <AppointmentTimeCard
          appointmentTypeName={appointmentType.name}
          imageUrl={selectedClinic ? selectedClinic.imageUrl : undefined}
          providerName={providerName}
          address={appointmentLocation}
        />
      )}

      {hasTimeSlots ? children : fallbackComponent}
    </>
  );
};
