import React from 'react';

import { Button } from 'components/v2/Buttons/Button';

import GeneticTestingPDF from '../../assets/Booklet-GeneticTesting.pdf';
import MentalHealthPDF from '../../assets/Booklet-MentalHealth.pdf';
import SubstepWrapper from '../../SubstepWrapper';
import { ButtonList, VideoButton } from '../styled-components';
import { Description, FlexWrapper, Title } from './styled-components';

const ReturnOBAppointment = () => (
  <>
    <Title>Return OB Appointment</Title>
    <Description>
      This appointment includes a visit with your provider, discussion on genetic testing, an
      ultrasound & blood work.
    </Description>
    <FlexWrapper>
      <Description>
        <strong>Fetal Genetic Screening:</strong> This is a non-invasive prenatal blood test (like a
        blood draw) performed at {'>'}10wks pregnant to check for the following chromosomes: Trisomy
        21 (Down Syndrome), Trisomy 18, Trisomy 13 & Sex chromosome.
      </Description>
      <Button
        label="View More"
        category="secondary"
        size="md"
        onClick={() => window.open(GeneticTestingPDF)}
      />
    </FlexWrapper>
    <Description>
      <strong>Fetal Genetic Testing:</strong> Options include chorionic villus sampling (CVS) or
      Amniocentesis.
    </Description>
    <ButtonList>
      <VideoButton
        label="AMNIOCENTESIS"
        onClick={() =>
          window.open(
            'https://www.mayoclinic.org/tests-procedures/amniocentesis/multimedia/vid-20121024'
          )
        }
      />
      <VideoButton
        label="CVS"
        onClick={() =>
          window.open(
            'https://www.mayoclinic.org/tests-procedures/chorionic-villus-sampling/multimedia/chorionic-villus-sampling-video/vid-20121056'
          )
        }
      />
    </ButtonList>
  </>
);

const VirtualMentalHealthSession = () => (
  <>
    <Title>Virtual Mental Health Session</Title>
    <FlexWrapper>
      <Description>
        Mental and emotional health is so important for both you and your developing baby. Our
        mindset program specializes in pregnancy mental health and preparing for childbirth &
        motherhood. The team will help you schedule these appointments.
      </Description>
      <Button
        label="View More"
        category="secondary"
        size="md"
        onClick={() => window.open(MentalHealthPDF)}
      />
    </FlexWrapper>
  </>
);

const DoulaAppointment = () => (
  <>
    <Title>Doula (Labor Coach) Appointment</Title>
    <Description>
      This is an orientation & educational session with a certified doula to help you get to know
      the hospital you will be delivering at, childbirth classes & trustworthy resources. Come
      prepared to share your family's hopes, most pressing questions, and unique needs. You will
      also gain a clear understanding of the role of the doula during pregnancy, childbirth and the
      early postpartum time. You will be supported on a spiritual, mental, emotional and physical
      level to prepare for a successful birth journey.
    </Description>
  </>
);

const SUBSTEPS = [
  { component: <ReturnOBAppointment /> },
  { component: <VirtualMentalHealthSession /> },
  { component: <DoulaAppointment /> }
];

const ObWeek10to12Substeps = () => <SubstepWrapper substeps={SUBSTEPS} />;

export default ObWeek10to12Substeps;
