import React from 'react';

import { Text } from 'components/v2/Typography';

import { GoToSignInButton, GoToSignInContainer } from './GoToSignIn.styled';

export const GoToSignIn = ({ onSignInClick }: { onSignInClick: () => void }) => {
  return (
    <GoToSignInContainer>
      <div>
        <Text tag="span" size="sm" fontStyle="semibold">
          Already have an account?
        </Text>
        <br />
        <GoToSignInButton onClick={onSignInClick} aria-describedby="Sign in here button">
          <Text tag="span" size="sm" fontStyle="semibold">
            SIGN IN HERE
          </Text>
        </GoToSignInButton>
      </div>
    </GoToSignInContainer>
  );
};
