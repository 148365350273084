import { containerSize } from 'screens/Messages/Messages.styled';
import styled, { css } from 'styled-components';

import { Badge } from '../Badge/Badge';

export const ContainerConversationItems = styled.ul`
  display: flex;
  flex-direction: column;
  margin-top: 16px;

  @media (min-width: ${({ theme }) => theme.breakpoints.smallDesktop}) {
    overflow: auto;
    margin: 16px -16px 0 -16px;
  }
`;

export const CategoryName = styled.span`
  margin: 0 8px 0 0;

  &::first-letter {
    text-transform: uppercase;
  }
`;

export const MessagesContainer = styled.div<{ isEmpty: boolean }>`
  ${containerSize}

  padding: 16px;
  background: ${({ theme }) => theme.colors.neutral.lavenderWhite};
  margin: 0 auto;
  

  ${({ isEmpty }) =>
    isEmpty &&
    css`
      height: 100%;
    `}

  @media (min-width: ${({ theme }) => theme.breakpoints.smallDesktop}) {
    grid-area: conversationList;
    height: 100%;
    overflow: auto;
    display: grid;
    grid-template-rows: auto 1fr;
    box-shadow: 0px 1px 3px 0px rgba(67, 71, 146, 0.16), 0px 1px 1px 0px rgba(10, 13, 61, 0.08);
    border-radius: 8px 0 0 8px;
    background: white;
    border-right: 1px solid ${({ theme }) => theme.colors.neutral.lavender};
  }
`;

export const BadgeStyled = styled(Badge)`
  @media (min-width: ${({ theme }) => theme.breakpoints.smallDesktop}) {
    display: none;
  }
`;

export const NoConversationsContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 16px;
  padding: 12px 0;
`;

export const NoConversationsIconContainer = styled.div`
  height: 48px;
  width: 48px;
  border-radius: 48px;
  border: 1px dashed ${({ theme }) => theme.colors.neutral.lavender};
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 0.75em;
  font-size: 20px;
`;

export const ConversationsTitleLeftSpacer = styled.div`
  padding-left: 1em;
`;

export const ConversationsTitleContainer = styled.div`
  padding: 0.75em 0;
`;
