import moment from 'moment-timezone';

import { AppointmentType } from 'kb-shared';
import { Patient } from 'kb-shared';
import client from 'kb-shared/graphql/client';
import { PATIENT } from 'kb-shared/graphql/queries';
import { MOMENT_TIME_ZONES } from 'kb-shared/utilities/momentHelpers';
import getAppointmentChannel from 'utilities/getAppointmentChannel';

export function getTimeZone(
  appointmentTimeZone: keyof typeof MOMENT_TIME_ZONES | string | undefined,
  appointmentType: AppointmentType
) {
  const { isInPerson } = getAppointmentChannel(appointmentType);
  const defaultTimeZone = moment.tz.guess();
  // read the patient data from apollo cache
  const patient = client.readQuery({ query: PATIENT })?.patient as Patient | undefined;
  const isLoggedIn = Boolean(patient);

  let timeZone: keyof typeof MOMENT_TIME_ZONES | string | undefined = defaultTimeZone;

  if (!isLoggedIn) {
    timeZone = defaultTimeZone;
  } else {
    timeZone = isInPerson
      ? appointmentTimeZone
      : patient?.preferredTimezone || patient?.lab?.timeZone || appointmentTimeZone;
  }

  const momentTimeZone =
    typeof timeZone === 'undefined'
      ? ''
      : MOMENT_TIME_ZONES[timeZone as keyof typeof MOMENT_TIME_ZONES];
  const formattedTimeZone = momentTimeZone ? momentTimeZone : timeZone;
  const displayTimeZone = moment.tz(formattedTimeZone || '').format('z');

  const formatTime = (date: string, format = 'h:mm a') =>
    moment(date)
      .tz(formattedTimeZone || '')
      .format(format);

  return {
    formattedTimeZone,
    displayTimeZone,
    formatTime
  };
}
