import React from 'react';
import ReactLoading from 'react-loading';

// left on purpose as default import because of the issue with
// storybook that compiles the the whole module and displays unrelated errors
// there is a ticket to fix those errors and avoid this workaround
import theme from 'kb-shared/theme';

import { AbsoluteCentered } from '../../styled';
import { LoaderContainer } from './Loader.styled';
import { Props } from './Loader.types';

export const Loader = ({
  color = theme.colors.yellow.primary,
  type = 'spin',
  width = 150,
  height = 150,
  className = '',
  container = false,
  absoluteCentered = false
}: Props): JSX.Element => {
  const LoaderComponent = (
    <ReactLoading type={type} color={color} className={className} width={width} height={height} />
  );

  if (absoluteCentered) return <AbsoluteCentered>{LoaderComponent}</AbsoluteCentered>;

  return container ? <LoaderContainer>{LoaderComponent}</LoaderContainer> : LoaderComponent;
};
