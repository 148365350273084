import { BugTracker } from 'kb-shared/utilities/bugTracker';

import { useDeleteInsuranceData } from './useDeleteInsuranceData';
import { InsuranceFormData, useSubmitInsuranceData } from './useSubmitInsuranceData';

export const useEditInsuranceData = () => {
  const { deleteInsuranceData, loading: deleting } = useDeleteInsuranceData();
  const { submitInsuranceData, submitting } = useSubmitInsuranceData();

  const editInsuranceData = async (id: string, insuranceData: InsuranceFormData) => {
    try {
      await deleteInsuranceData(id);
      const result = await submitInsuranceData(insuranceData);
      return result as Response;
    } catch (error) {
      BugTracker.notify(error as Error, 'Failed to delete the insurance data.');
      throw error;
    }
  };

  return { editInsuranceData, loading: deleting || submitting };
};
