import React from 'react';

import Invoice from 'screens/Invoices/types/invoice';

import { CardList } from './InvoiceCardList.styled';
import { InvoiceCardListItem } from './InvoiceCardListItem';

export const InvoiceCardList = ({
  invoices,
  onPayInvoice,
  onViewInvoice
}: {
  invoices: Invoice[];
  onPayInvoice: (invoice: Invoice) => void;
  onViewInvoice: (invoice: Invoice) => void;
}) => {
  return (
    <CardList>
      {invoices.map(invoice => (
        <InvoiceCardListItem
          key={invoice.id}
          invoice={invoice}
          onPayClick={() => onPayInvoice(invoice)}
          onViewInvoice={() => onViewInvoice(invoice)}
        />
      ))}
    </CardList>
  );
};
