import { Heading } from 'components/v2/Typography';
import { Text } from 'components/v2/Typography/Text';
import styled from 'styled-components';

export const Container = styled.div`
  background: ${({ theme }) => theme.colors.neutral.lavenderWhite};
  width: 100%;
  top: 0;
  left: 0;
  z-index: 300;
  position: fixed;

  @media (min-width: ${({ theme }) => theme.breakpoints.smallDesktop}) {
    position: static;
    background-color: white;
    display: flex;
    box-shadow: 0px 1px 3px -1px rgba(67, 71, 146, 0.16), 0px 1px 1px -1px rgba(10, 13, 61, 0.08);
  }
`;

export const Navbar = styled.div`
  width: 100%;
  padding: 4px 8px;
  color: ${({ theme }) => theme.colors.neutral.primaryNavy};
  box-shadow: 0px 1px 3px -1px rgba(67, 71, 146, 0.16), 0px 1px 1px -1px rgba(10, 13, 61, 0.08);
  position: relative;
  display: flex;
  align-items: center;

  & > div:nth-child(3) {
    margin-left: 8px;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.smallDesktop}) {
    padding: 16px 0px 16px 24px;
    width: auto;
    box-shadow: none;
  }
`;

export const Title = styled(Heading).attrs({ tag: 'h1', styledAs: 'h4' })`
  flex: 1;
  margin: 0 0 0 8px;
`;

export const BackButton = styled.div`
  margin: 0 4px 0 0;
  height: 48px;
  width: 48px;
  display: flex;
  align-items: center;
  justify-content: center;

  @media (min-width: ${({ theme }) => theme.breakpoints.smallDesktop}) {
    display: none;
  }
`;

export const Subheader = styled(Text).attrs({ size: 'sm', fontStyle: 'regular' })`
  padding: 8px 24px;
  background: #fff;
  box-shadow: 0px 1px 3px -1px rgba(67, 71, 146, 0.16), 0px 1px 1px -1px rgba(10, 13, 61, 0.08);
  color: ${({ theme }) => theme.colors.neutral.darkestNavy};
  margin: 0 auto;
  display: flex;
  align-items: center;
  flex-direction: row;

  @media (min-width: ${({ theme }) => theme.breakpoints.smallDesktop}) {
    margin: 0;
    flex-direction: row-reverse;
    gap: 12px;
    flex: 1;
    box-shadow: none;
    padding: 8px 24px 8px 16px;
  }
`;

export const Subject = styled.div`
  flex: 1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-right: 4px;

  @media (min-width: ${({ theme }) => theme.breakpoints.smallDesktop}) {
    white-space: normal;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    display: -webkit-box;
  }
`;

export const NavbarInfo = styled.div`
  display: flex;
  align-items: center;
`;
