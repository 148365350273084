import 'rc-slider/assets/index.css';
import 'rc-tooltip/assets/bootstrap.css';
import Slider, { Handle } from 'rc-slider';
import React from 'react';

import { themes } from 'kb-shared';
import styled from 'styled-components';

import { ReactComponent as SliderIcon } from '../../../../assets/icons/stimulation-slider.svg';
import Indicator from '../Indicator';

const Container = styled.div`
  padding: 20px 10px;
  padding-top: 70px;
`;

const SliderContainer = styled.div`
  margin-top: -18px;
  margin-left: -11px;
`;

const IndicatorContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 100px;
`;

const IndicatorPosition = styled.div`
  position: absolute;
  top: -70px;
  left: -45px;
`;

type Props = {
  onValueChange: (value: number) => void;
  validSteps: Array<number>;
  currentValue: number;
  maxValue: number;
};

const CustomSlider = (props: Props) => {
  // Generate invisible marks so that slider snaps into locations where valid steps are
  const marks = props.validSteps.reduce((map, value) => {
    // @ts-ignore
    map[value] = '';
    return map;
  }, {});
  const initialSliderPosition = props.validSteps[props.validSteps.length - 1];
  return (
    <Container>
      <Slider
        marks={marks}
        dotStyle={{ opacity: 0 }}
        step={null}
        min={0}
        max={props.maxValue}
        defaultValue={initialSliderPosition}
        handle={({ _dragging, _index, ...rest }: any) => (
          <Handle {...rest}>
            <SliderContainer>
              <SliderIcon />
              <IndicatorPosition>
                <IndicatorContainer>
                  <Indicator text={`Day ${props.currentValue + 1}`} icons={[]} />
                </IndicatorContainer>
              </IndicatorPosition>
            </SliderContainer>
          </Handle>
        )}
        trackStyle={[{ backgroundColor: themes.colors.neutral.lavender, height: 10 }]}
        railStyle={{ backgroundColor: themes.colors.neutral.primaryNavy, height: 10 }}
        onChange={props.onValueChange}
      />
    </Container>
  );
};

export default CustomSlider;
