import maxBy from 'lodash.maxby';
import moment from 'moment-timezone';
import React, { useMemo } from 'react';

import { themes } from 'kb-shared';
import styled from 'styled-components';

import DiagnosticResult from '../DiagnosticResults';
import FertilityResult from '../FertilityResult';
import { LoadedResults, ResultType } from '../Results.types';
import { ContainerPadding } from '../styled';
import { Title } from '../styled';

type Props = {
  onScheduleAssessment: () => void;
  results: LoadedResults;
  patient: Patient;
};

export type Patient = {
  firstName?: string;
  lastName?: string;
  birthday?: string;
};

const Subheader = styled.p`
  ${({ theme }) => theme.typos.bodyText};
  color: ${({ theme }) => theme.colors.yellow.primary};
`;

const DiagnosticTitle = styled(Title)`
  padding-top: 20px;
  padding-left: 0px;
  padding-right: 0px;
  @media screen and (min-width: ${themes.breakpoints.largePhonePortrait}) {
    padding-top: 30px;
    padding-left: 0px;
  }
`;

const Assessment = (props: Props) => {
  const { results, onScheduleAssessment, patient } = props;
  const patientName = patient && `${patient.firstName} ${patient.lastName}`;
  const afcResult = results.afc[0];
  const amhResult = results.labs.find((value: ResultType) => {
    return value.type === 'amh';
  });

  const lastUpdatedDate = useMemo(() => {
    const allResults = results.labs.concat(afcResult).concat(results.spermAnalyses);
    const mostRecentResult = maxBy(allResults, 'date');
    return mostRecentResult && mostRecentResult.date;
  }, [afcResult, results]);

  return (
    <ContainerPadding>
      <DiagnosticTitle>
        Diagnostic Results
        <Subheader>
          {patientName}, DOB: {moment(patient && patient.birthday).format('M/D/YY')}
        </Subheader>
        <Subheader>Last Updated: {moment(lastUpdatedDate).format('M/D/YY')}</Subheader>
      </DiagnosticTitle>
      <FertilityResult
        onScheduleAssessment={onScheduleAssessment}
        // @ts-ignore
        amhResult={amhResult}
        afcResult={afcResult}
      />
      <DiagnosticResult results={results.labs.concat(results.spermAnalyses)} />
    </ContainerPadding>
  );
};

export default Assessment;
