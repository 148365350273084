import React from 'react';

import { Text } from 'components/v2/Typography';
import {
  formatAmount,
  formatDate,
  formatReason
} from 'screens/Invoices/components/UnappliedPayments/UnappliedPayments.utils';

import { Payment } from '../../types/payment';
import {
  CardListItem,
  CardContent,
  CardContentRow,
  Spacer,
  CardContentAmount
} from './UnappliedPaymentCardListItem.styled';

export const UnappliedPaymentCardListItem = ({ payment }: { payment: Payment }) => {
  const { date, reason, amountCents, azAppliedAmountCents } = payment;

  return (
    <CardListItem>
      <CardContent>
        <CardContentRow>
          <Text tag="span" fontStyle="medium" transform="uppercase">
            Reason
          </Text>
          <Text tag="span" fontStyle="regular">
            {formatDate(date)}
          </Text>
        </CardContentRow>

        <CardContentRow>
          <Text tag="span" fontStyle="regular" className="heading">
            {formatReason(reason)}
          </Text>
        </CardContentRow>

        <Spacer />

        <CardContentRow>
          <div>
            <div>
              <Text tag="span" fontStyle="medium" transform="uppercase">
                Amount
              </Text>
            </div>
            <CardContentAmount>
              <Text tag="span" fontStyle="regular">
                {formatAmount(amountCents)}
              </Text>
            </CardContentAmount>
          </div>

          <div>
            <div>
              <Text tag="span" fontStyle="medium" transform="uppercase">
                Applied Amount
              </Text>
            </div>
            <CardContentAmount>
              <Text tag="span" fontStyle="regular">
                {formatAmount(azAppliedAmountCents)}
              </Text>
            </CardContentAmount>
          </div>
        </CardContentRow>
      </CardContent>
    </CardListItem>
  );
};
