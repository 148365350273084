import { Maybe } from 'kb-shared';

export interface BenefitsProfile {
  bid: string;
  firstName: string;
  lastName: string;
  dateOfBirth: string;
}

export interface BenefitsProfileOption {
  value: any;
  label: string;
}

export type CreateWalmartDependentPatientMembershipVariables = {
  patientsDateOfBirth: string;
  bid: string;
};

export type CreateWalmartDependentPatientMembershipResult = {
  createWalmartPatientMembership: {
    patientMembership: {
      id: string;
      employerName: Maybe<string>;
    };
  };
};

export const STEPS_NAME = ['Services', 'Location', 'Activate'];
