import { themes } from 'kb-shared';
import styled from 'styled-components';

export const Icon = styled.img`
  width: 20px;
  height: 20px;
  margin: 4px 14px 4px 4px;
`;

export const MainText = styled.span`
  color: ${({ theme }) => theme.colors.neutral.primaryNavy};
  font-weight: 500;
  font-size: 13px;
  text-transform: uppercase;
  margin: 0px;
`;

export const UploadBtnContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  &:first-child {
    margin-top: 14px;
  }

  margin-bottom: 14px;

  @media screen and (min-width: ${themes.breakpoints.tabletPortrait}) {
    &:first-child {
      margin-top: unset;
    }
    margin-bottom: 26px;
  }
`;

export const UploadBtn = styled.button`
  padding: 24px 32px;
  width: 100%;
  height: 80px;
  background: ${({ theme }) => theme.colors.yellow.lightestYellow};
  border: 2px dashed ${({ theme }) => theme.colors.yellow.primary};
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;

  &:hover {
    border-style: solid;
  }
`;

export const ButtonErrorText = styled.span`
  color: ${({ theme }) => theme.colors.semantic.darkRed};
`;

export const FileInput = styled.input`
  display: none;
`;

export const UploadPreview = styled.div`
  display: flex;
  gap: 8px;
  justify-content: space-between;
  align-items: center;
  padding: 8px;
  width: 100%;
  height: 80px;
  background: ${({ theme }) => theme.colors.yellow.lightestYellow};
  border: 2px solid ${({ theme }) => theme.colors.yellow.primary};
  border-radius: 8px;
`;

export const UploadPreviewFileName = styled.span`
  font-size: 14px;
  font-weight: 500;
  word-break: break-all;
  flex-grow: 1;
  max-height: 100%;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;

  ${({ theme }) => {
    return `
      @media (min-width: ${theme.breakpoints.tabletLandscape}) {
        overflow: auto;
         text-overflow: unset;
         white-space: unset;
      }
    `;
  }}
`;

export const UploadPreviewImg = styled.img`
  width: 52px;
  height: 52px;
  border-radius: 4px;
  border: 1px solid ${({ theme }) => theme.colors.neutral.lightNavy};
  object-fit: cover;
  flex-shrink: 0;
`;

export const UploadPreviewImgContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 24px;
  align-items: center;
`;

export const UploadPreviewImgFull = styled.img`
  max-width: 100%;
`;

export const UploadPreviewOptions = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

export const UploadPreviewBtn = styled.button<{ variant: 'primary' | 'secondary' }>`
  font-size: 12px;
  font-weight: 600;
  padding: 4px 8px;
  border-radius: 4px;

  ${({ variant, theme }) => {
    switch (variant) {
      case 'primary':
        return `
          background: ${theme.colors.yellow.primary};
          border: none;
          color: ${theme.colors.neutral.primaryNavy}
        `;

      case 'secondary':
        return `
          background: ${theme.deprecatedColors.gray};
          border: 1px solid ${theme.colors.neutral.lighterNavy};
          color: ${theme.colors.neutral.primaryNavy};
        `;

      default:
        return '';
    }
  }}
`;

export const ClosePreviewBtn = styled.button`
  display: flex;

  font-size: 24px;
  font-weight: 500;
  align-items: center;
  margin-bottom: 20px;
`;
