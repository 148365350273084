import React from 'react';

import { MenuIcon } from '../MenuIcon/MenuIcon';
import { NavigationItems } from '../NavigationItems/NavigationItems';
import { UserProfileButton } from '../UserProfileButton/UserProfileButton';
import {
  MenuContent,
  CollapsedMenuContainer,
  MenuUserContainer
} from './CollapsedNavigationItems.styled';
import { CollapsedNavigationItemsProps } from './CollapsedNavigationItems.types';

export const CollapsedNavigationItems = ({
  toggleMenu,
  currentPathName,
  numberOfUnreadMessages,
  numberOfOutstandingInvoices,
  items
}: CollapsedNavigationItemsProps) => {
  return (
    <CollapsedMenuContainer>
      <MenuContent>
        <MenuIcon toggleMenu={toggleMenu} isOpen={false} />
        <NavigationItems
          items={items}
          currentPathName={currentPathName}
          labelVisible={false}
          numberOfUnreadMessages={numberOfUnreadMessages}
          numberOfOutstandingInvoices={numberOfOutstandingInvoices}
        />
        <MenuUserContainer>
          <UserProfileButton size={48} />
        </MenuUserContainer>
      </MenuContent>
    </CollapsedMenuContainer>
  );
};
