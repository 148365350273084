import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { Apple } from 'assets/icons/Icons';
import { Button } from 'components/v2/Buttons/Button';
import { utils } from 'kb-shared';
import { BugTracker } from 'kb-shared/utilities/bugTracker';
import { analytics } from 'utilities/analytics';

import * as redux from '../kb-redux';
import AppleDisclaimerModal from './Apple/AppleDisclaimerModal';
import AppleNotLoadedModal from './Apple/AppleNotLoadedModal';

const { REACT_APP_APPLE_CLIENT_ID, REACT_APP_APPLE_REDIRECT_URL } = process.env;

const { signInAppleUser } = redux.patient;
const { decodeJwtPayload } = utils;

interface Props {
  operation: 'login' | 'signUp';
}

const AppleAuthButton = (props: Props) => {
  const dispatch = useDispatch();
  const [showDisclaimerModal, setShowDisclaimerModal] = useState(false);
  const [showNotLoadedModal, setShowNotLoadedModal] = useState(false);
  const handleSignInFailure = useCallback(event => {
    //@ts-ignore
    const errorDetails = event?.detail?.error;
    processAppleErrorCode(errorDetails);
  }, []);

  useEffect(() => {
    initAppleSignIn();
    window.document.addEventListener('AppleIDSignInOnFailure', handleSignInFailure);

    return () => window.document.removeEventListener('AppleIDSignInOnFailure', handleSignInFailure);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const singInApple = async () => {
    try {
      // response documentation https://developer.apple.com/documentation/sign_in_with_apple/sign_in_with_apple_js/configuring_your_webpage_for_sign_in_with_apple
      const { authorization, user } = await window.AppleID.auth.signIn();
      window?.sessionStorage?.setItem('@AppleSSO:idToken', authorization.id_token as string);
      const jsonPayload = decodeJwtPayload(authorization.id_token);
      const firstName = user?.name?.firstName;
      const lastName = user?.name?.lastName;
      analytics.track(analytics.EVENTS.APPLE_SIGN_IN_SUCCEEDED);
      dispatch(
        signInAppleUser(
          authorization.id_token,
          jsonPayload.email,
          props.operation,
          firstName,
          lastName
        )
      );
    } catch (error) {
      //@ts-ignore
      const errorCode = error?.error;
      processAppleErrorCode(errorCode);
    }
  };

  const processAppleErrorCode = (errorCode: string) => {
    if (
      !errorCode ||
      errorCode === 'popup_closed_by_user' ||
      errorCode === 'user_cancelled_authorize' ||
      errorCode === 'user_trigger_new_signin_flow'
    )
      return;

    BugTracker.notify(errorCode, 'AppleAuthButtonError');
  };

  const handleClick = () => {
    analytics.track(analytics.EVENTS.APPLE_SIGN_IN_STARTED);

    if (!window.AppleID) {
      setShowNotLoadedModal(true);
      return;
    }

    if (props.operation === 'login') {
      return singInApple();
    }

    setShowDisclaimerModal(true);
  };

  const handleModalClose = () => {
    setShowDisclaimerModal(false);
    singInApple();
  };

  const handleShowLoadedModalClose = () => {
    window.location.reload();
  };

  return (
    <>
      <Button
        label="Continue with Apple"
        category="primary-dark"
        size="sm"
        fullWidth
        leftIcon={<Apple />}
        onClick={handleClick}
      />
      <AppleDisclaimerModal showModal={showDisclaimerModal} handleClose={handleModalClose} />
      <AppleNotLoadedModal
        showModal={showNotLoadedModal}
        handleClose={handleShowLoadedModalClose}
      />
    </>
  );
};

const initAppleSignIn = () => {
  if (!window.AppleID) {
    BugTracker.notify('Apple SignIn', 'Apple sign-in failed to load');
    return;
  }

  window.AppleID.auth.init({
    clientId: REACT_APP_APPLE_CLIENT_ID, // This is the service ID we created.
    scope: 'name email', // To tell apple we want the user name and emails fields in the response it sends us.
    redirectURI: REACT_APP_APPLE_REDIRECT_URL, // As registered along with our service ID
    state: 'origin:web', // Any string of your choice that you may use for some logic. It's optional and you may omit it.
    usePopup: true // Important if we want to capture the data apple sends on the client side.
  });
};

export default AppleAuthButton;
