import React from 'react';

import { Button } from 'components/v2/Buttons/Button';

import UltrasoundsPDF from '../../assets/Booklet-Ultrasounds.pdf';
import SubstepWrapper from '../../SubstepWrapper';
import { Description, FlexWrapper, Title } from './styled-components';

const ReturnOBAppointment = () => (
  <>
    <Title>Return OB Appointment</Title>
    <Description>
      This appointment includes a visit with your provider, ultrasound & blood work.
    </Description>
    <FlexWrapper>
      <Description>
        <strong>Early anatomy ultrasound:</strong> This is a detailed ultrasound to look at all your
        baby's body parts from head to toe, including bones and organs. This ultrasound takes about
        1 hour to perform.
      </Description>
      <Button
        label="View More"
        category="secondary"
        size="md"
        onClick={() => window.open(UltrasoundsPDF)}
      />
    </FlexWrapper>
  </>
);

const NutritionAppointment = () => (
  <>
    <Title>Nutrition Appointment</Title>
    <Description>
      This is your 2nd individualized nutrition appointment. Good diet & nutrition are essential for
      a healthy mom & baby. The team will help you schedule your appointment.
    </Description>
  </>
);

const MedicalFitnessAppointment = () => (
  <>
    <Title>Medical Fitness Appointment</Title>
    <Description>
      This is your 2nd individualized physical fitness. Physical exercise can help with some common
      discomforts of pregnancy and even help prepare your body for labor and delivery. The team will
      help you schedule your appointment.
    </Description>
  </>
);

const SUBSTEPS = [
  { component: <ReturnOBAppointment /> },
  { component: <NutritionAppointment /> },
  { component: <MedicalFitnessAppointment /> }
];

const ObWeek16Substeps = () => <SubstepWrapper substeps={SUBSTEPS} />;

export default ObWeek16Substeps;
