import React from 'react';
import { useParams } from 'react-router-dom';

import { Loader } from 'components/Loader/Loader';

import SuccessfullyAcknowledged from './components/AcknowledgeStatus';
import CyclePlanOutOfDate from './components/AcknowledgeStatus/CyclePlanOutOfDate';
import RefreshNeeded from './components/AcknowledgeStatus/RefreshNeeded';
import ResentNeeded from './components/AcknowledgeStatus/ResentNeeded';
import CyclePlanSummary from './components/CyclePlanSummary/CyclePlanSummary';
import {
  useMutationCyclePlanAcknowledge,
  useQueryCyclePlanInfoBySections
} from './CyclePlanAcknowledge.hooks';
import {
  Bottom,
  ConsentText,
  Container,
  Divider,
  YellowButton
} from './CyclePlanAcknowledge.styles';
import { Params } from './CyclePlanAcknowledge.types';

const CyclePlanAcknowledge: React.FC = () => {
  const params = useParams<Params>();
  const id = parseInt(params.id);

  const { data, loading, resentNeeded } = useQueryCyclePlanInfoBySections(id);
  const { acknowledged, setCyclePlanAcknowledged } = useMutationCyclePlanAcknowledge(
    id,
    data.patientAcknowledgementJsonHash
  );

  if (loading) return <Loader absoluteCentered />;

  if (resentNeeded || (acknowledged.hasError && acknowledged.resentNeeded)) {
    return <ResentNeeded />;
  }

  if (acknowledged?.hasError && acknowledged?.outOfDateVersion) {
    return <CyclePlanOutOfDate />;
  }

  if (acknowledged?.hasError && acknowledged?.refreshNeeded) {
    return <RefreshNeeded />;
  }

  if (acknowledged?.isAcknowledged) {
    return <SuccessfullyAcknowledged />;
  }

  const cyclePlans = data?.cyclePlans;
  const patient = data?.patient;

  if (patient == null) return null;

  return (
    <Container>
      <CyclePlanSummary cyclePlans={cyclePlans} patient={patient} />

      <Divider />

      <Bottom>
        <ConsentText>
          By clicking “I acknowledge“, I agree and acknowledge that I have read and understand the
          information provided above.
        </ConsentText>
        <YellowButton onClick={setCyclePlanAcknowledged}>I Acknowledge</YellowButton>
      </Bottom>
    </Container>
  );
};

export default CyclePlanAcknowledge;
