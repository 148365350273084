import { Button } from 'components/v2/Buttons/Button/Button.styled';
import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;

export const BenefitWrapper = styled.div`
  padding: 24px 0;
  max-width: 380px;
`;

export const BenefitEmptyStateWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  justify-content: space-between;
  padding: 28px 32px;
  border-radius: 8px;
  background-color: ${({ theme }) => theme.colors.yellow.lighterYellow};

  @media (min-width: ${({ theme }) => theme.breakpoints.tabletLandscape}) {
    flex-direction: row;
  }

  ${Button} {
    white-space: wrap;
    min-width: min-content;

    @media (min-width: ${({ theme }) => theme.breakpoints.tabletLandscape}) {
      white-space: nowrap;
    }
  }
`;
