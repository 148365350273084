import React, { useRef } from 'react';
import { useHistory } from 'react-router-dom';

import { useTooltip } from 'hooks';
import { analytics } from 'utilities/analytics';

import { Status } from '../InterstitialModal.types';
import { deactivateModal, formatAsList, getProceedButtonProps } from '../InterstitialModal.utils';
import { Button } from './ProceedToDashboardButton.styled';

type Props = { status: Status };

export const ProceedToDashboardButton = ({ status }: Props) => {
  const history = useHistory();
  const tooltipTrigger = useRef<HTMLDivElement>(null);
  const { active, hasRequiredConsents, partnerDataCollected, skipPartnerDataCapture } = status;

  const isActive = skipPartnerDataCapture
    ? active && hasRequiredConsents
    : active && hasRequiredConsents && partnerDataCollected;

  useTooltip({
    triggerElementRef: tooltipTrigger,
    content: getTooltipMessage(status),
    visible: !isActive
  });

  const { pathname, text } = getProceedButtonProps();

  const onClick = () => {
    localStorage.removeItem('redirectBackToMembership');

    deactivateModal();

    const backToBookingUrl = localStorage.getItem('redirectBackToBookingUrl');
    analytics.track(analytics.EVENTS.SIGN_UP_TASKS_COMPLETED);
    if (backToBookingUrl) {
      localStorage.removeItem('redirectBackToBooking');
      localStorage.removeItem('redirectBackToBookingUrl');
      history.push(backToBookingUrl);
    } else {
      history.push(pathname);
    }
  };

  return (
    <div ref={tooltipTrigger}>
      <Button disabled={!isActive} onClick={onClick}>
        {text}
      </Button>
    </div>
  );
};

const getTooltipMessage = (interstitialStatus: Status) => {
  const {
    active,
    hasRequiredConsents,
    partnerDataCollected,
    skipPartnerDataCapture
  } = interstitialStatus;

  if (!active) return '';

  const sectionStatus = [
    {
      name: 'Consents',
      include: true,
      status: hasRequiredConsents,
      unfinishedMessage: 'sign missing consents'
    },
    {
      name: 'Partner',
      include: !skipPartnerDataCapture,
      status: partnerDataCollected,
      unfinishedMessage: 'add partner information'
    }
  ];

  const unfinishedSectionMessages = sectionStatus
    .filter(({ include, status }) => include && !status)
    .map(({ unfinishedMessage }) => unfinishedMessage);

  if (unfinishedSectionMessages.length) {
    return formatAsList(unfinishedSectionMessages, 'Please ');
  }

  return '';
};
