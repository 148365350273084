import { gql, useMutation } from '@apollo/client';

import { StripeContext } from 'components/CreditCardForm/types/creditCardForm';
import { handlePaymentError } from 'utilities/errors';

import {
  CreatePaymentWithCreditCardNumberMutationVariables,
  CreatePaymentWithCreditCardNumberParams,
  CreatePaymentWithStripeIdentifierMutationVariables,
  CreatePaymentWithStripeIdentifierParams,
  UseCreatePaymentResponse
} from '../types/invoice';

const CREATE_PAYMENT = gql`
  mutation createPayment(
    $stripeTokenId: String
    $amountCents: Int!
    $reason: String!
    $reasonDetails: String
    $kbStripeCardStripeIdentifier: String
  ) {
    createPayment(
      stripeTokenId: $stripeTokenId
      amountCents: $amountCents
      reason: $reason
      reasonDetails: $reasonDetails
      kbStripeCardStripeIdentifier: $kbStripeCardStripeIdentifier
    ) {
      succeeded
    }
  }
`;

const createStripeTokenId = async (
  stripe: StripeContext['stripe'],
  cardNumberElement: StripeContext['cardNumberElement']
) => {
  const tokenResponse = await stripe.createToken(cardNumberElement);
  if (tokenResponse.error?.message) {
    throw new Error(tokenResponse.error.message);
  }

  return tokenResponse.token?.id ?? '';
};

const useCreatePaymentWithCreditCardNumber = () => {
  const [createPaymentMutation] = useMutation<
    unknown,
    CreatePaymentWithCreditCardNumberMutationVariables
  >(CREATE_PAYMENT);

  return async (
    params: CreatePaymentWithCreditCardNumberParams,
    onSuccess: () => void,
    onError: (errorMessage: string) => void
  ) => {
    try {
      const { stripe, cardNumberElement, reason, reasonDetails, amountCents } = params;

      const stripeTokenId = await createStripeTokenId(stripe, cardNumberElement);

      await createPaymentMutation({
        variables: {
          stripeTokenId,
          reason,
          reasonDetails,
          amountCents
        }
      });

      onSuccess();
    } catch (error) {
      handlePaymentError(error, onError);
    }
  };
};

const useCreatePaymentWithStripeIdentifier = () => {
  const [createPaymentMutation] = useMutation<
    unknown,
    CreatePaymentWithStripeIdentifierMutationVariables
  >(CREATE_PAYMENT);

  return async (
    params: CreatePaymentWithStripeIdentifierParams,
    onSuccess: () => void,
    onError: (errorMessage: string) => void
  ) => {
    try {
      await createPaymentMutation({ variables: params });

      onSuccess();
    } catch (error) {
      handlePaymentError(error, onError);
    }
  };
};

const useCreatePayment = (): UseCreatePaymentResponse => {
  const createPaymentWithCreditCardNumber = useCreatePaymentWithCreditCardNumber();
  const createPaymentWithStripeIdentifier = useCreatePaymentWithStripeIdentifier();

  return { createPaymentWithCreditCardNumber, createPaymentWithStripeIdentifier };
};

export default useCreatePayment;
