import React from 'react';

import StepDescription from './StepDescription';

const PregnancyTestDetail = () => {
  return (
    <StepDescription
      description={
        <p>
          This is a blood test that will indicate whether you are pregnant. We're rooting for you!
          If it didn't stick, we are here for you to discuss further options. The clinic will book
          this appointment after your procedure, but if you have any questions, please contact your
          care team.
        </p>
      }
    />
  );
};

export default PregnancyTestDetail;
