import React from 'react';

import { Text } from 'components/v2/Typography';
import { Payment } from 'screens/Invoices/types/payment';

import { TableHead } from '../../Invoices.styled';
import {
  formatAmount,
  formatDate,
  formatReason
} from '../UnappliedPayments/UnappliedPayments.utils';
import { TableBody, TableComponent, TableCell } from './UnappliedPaymentsTable.styled';

export function UnappliedPaymentsTable({ payments }: { payments: Payment[] }) {
  const rows = payments.map(unappliedPayment => (
    <tr key={unappliedPayment.id}>
      <TableCell>
        <Text tag="span" fontStyle="regular">
          {formatDate(unappliedPayment.date)}
        </Text>
      </TableCell>
      <TableCell>
        <Text tag="span" fontStyle="regular">
          {formatReason(unappliedPayment.reason)}
        </Text>
      </TableCell>
      <TableCell $alignRight={true}>
        <Text tag="span" fontStyle="regular">
          {formatAmount(unappliedPayment.amountCents)}
        </Text>
      </TableCell>
      <TableCell $alignRight={true}>
        <Text tag="span" fontStyle="regular">
          {formatAmount(unappliedPayment.azAppliedAmountCents)}
        </Text>
      </TableCell>
    </tr>
  ));

  return (
    <div>
      <TableComponent>
        <thead>
          <tr>
            {TABLE_HEADERS.map(header => (
              <TableHead key={header.text} $alignRight={header.alignRight}>
                <Text tag="span" fontStyle="medium">
                  {header.text}
                </Text>
              </TableHead>
            ))}
          </tr>
        </thead>

        {<TableBody>{rows}</TableBody>}
      </TableComponent>
    </div>
  );
}

const TABLE_HEADERS = [
  { text: 'Date of Service', alignRight: false },
  { text: 'Reason', alignRight: false },
  { text: 'Amount', alignRight: true },
  { text: 'Applied Amount', alignRight: true }
];
