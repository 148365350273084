import React from 'react';

import { HeartPulse } from 'assets/icons/Icons';
import { Button } from 'components/v2/Buttons/Button';
import { Heading, Text } from 'components/v2/Typography';

import { CustomList } from '../CustomWidget/Components/CustomList';
import {
  ButtonsContainer,
  IconContainer,
  IllustratedBackgroundWidget,
  TextRow,
  WidgetTitleContainer
} from '../Widgets/Widgets.styled';

function redirectToExternalPage() {
  const url = 'https://www.eventbrite.com/cc/kindbody-family-building-support-groups-1299939';

  const newTab = window.open(url, '_blank');

  if (newTab) {
    newTab.focus();
  }
}

export const SupportGroupWidget = () => {
  const bulletPoints = `Infertility and Family Building Support Group
  Egg Freezing and Single Parents by Choice Support Group
  Sister Circle: Infertility Support for BIPOC Women`;

  return (
    <IllustratedBackgroundWidget $variant={3}>
      <IconContainer $iconWidth={54} $iconHeight={48}>
        <HeartPulse type="solid" />
      </IconContainer>
      <WidgetTitleContainer>
        <Heading tag="div" styledAs="h2">
          Learn about our free support groups
        </Heading>
      </WidgetTitleContainer>
      <TextRow>
        <Text fontStyle="semibold">
          When you are navigating infertility and the family building journey, it can be an
          emotionally difficult time. Carve out time in your day in a therapist led, supportive
          space to spend time with others who “get it."
        </Text>
      </TextRow>
      <TextRow>
        <Text fontStyle="regular">We offer the following support groups:</Text>
      </TextRow>
      <CustomList type="bulleted" items={bulletPoints} />
      <ButtonsContainer>
        <Button
          size="lg"
          label="Learn More"
          category="primary-dark"
          onClick={redirectToExternalPage}
        />{' '}
      </ButtonsContainer>
    </IllustratedBackgroundWidget>
  );
};
