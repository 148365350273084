import React from 'react';

import SubstepWrapper from '../../SubstepWrapper';
import { Description, Title } from './styled-components';

const PostpartumVisit = () => (
  <>
    <Title>Postpartum Visit (Virtual if vaginal delivery; In-Person if c-section)</Title>
    <Description>
      Your provider will check to see how you are feeling physically, get updates on your blood
      pressure, bleeding, pain and address any questions or issues you are having. Your mental and
      emotional health will be a key component of this visit.
    </Description>
  </>
);

const DoulaAppointment = () => (
  <>
    <Title>Doula (Lactation) Appointment</Title>
    <Description>
      This is a virtual or in-home visit with your Doula to address your emotional wellbeing and to
      provide lactation support.
    </Description>
  </>
);

const VirtualMentalHealthSession = () => (
  <>
    <Title>Virtual Mental Health Session (if needed)</Title>
    <Description>
      This visit focuses on your emotional health and adjustment to motherhood and how you are
      coping with having a new baby at home. You can discuss any anxiety, fears or any issues you
      are having.
    </Description>
  </>
);

const SUBSTEPS = [
  { component: <PostpartumVisit /> },
  { component: <DoulaAppointment /> },
  { component: <VirtualMentalHealthSession /> }
];

const ObPostPartumDay14Substeps = () => <SubstepWrapper substeps={SUBSTEPS} />;

export default ObPostPartumDay14Substeps;
