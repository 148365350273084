import React from 'react';
import { useHistory } from 'react-router-dom';

import Initials from 'components/Initials';
import { Button } from 'components/v2/Buttons/Button';
import { LinkButton } from 'components/v2/Buttons/LinkButton/LinkButton';
import { useFeatureFlags } from 'hooks';
import { getTimeZone } from 'hooks/getTimeZone';
import { Appointment, AppointmentType, Clinic, Patient, utils } from 'kb-shared';
import { VIRTUAL_APPOINTMENT_LICENSING_DISCLAIMER } from 'kb-shared/utilities/appointment.helper';
import { CATEGORY_SLUG } from 'screens/Messages/Messages.constants';
import getAppointmentChannel from 'utilities/getAppointmentChannel';
import { pageUrl } from 'utilities/pageUrl';
import { VIOS_CHICAGO_LAB_ID } from 'utilities/vios';

import { locationDark, location as locationIcon } from '../../../assets/icons';
import { generateCalendarLink } from '../../../utilities/appointmentProduct';
import {
  Address,
  AppointmentLocation,
  AppointmentName,
  AppointmentTime,
  CardContainer,
  ClinicName,
  ConsultInstructions,
  FutureActionsWrapper,
  LocationWrapper,
  PhoneConsult,
  ProviderInfo,
  ProviderLabel,
  ProviderName,
  RescheduledText,
  VideoConsult
} from './AppointmentCard.styled';

const { inFuture, shouldDisplayProvider } = utils;

type Props = {
  type: AppointmentType;
  appointment: Appointment;
  startTime: string;
  endTime: string;
  timeZone?: string;
  location: Clinic;
  toggleModal: () => void;
  hasStepper?: boolean;
  videoLink?: string;
  patient?: Patient;
};

const AppointmentCard = (props: Props) => {
  const {
    type,
    startTime,
    endTime,
    location: { name: locationName, address },
    toggleModal,
    hasStepper,
    videoLink,
    appointment,
    patient
  } = props;

  const history = useHistory();
  const isFuture = inFuture(endTime);
  const { ppPreventSelfAppointmentChicagoPatient } = useFeatureFlags();

  const { isPhoneCall, isVideoCall, isInPerson } = getAppointmentChannel(type);

  const apptVideoLink = videoLink ?? type.videoLink;

  const goToVideoLink = () => {
    if (!apptVideoLink) return;
    window.location.href = apptVideoLink;
  };

  const addToCalendar = () =>
    window.open(
      generateCalendarLink(
        {
          appointmentType: type,
          startTime,
          endTime,
          location: props.location
        },
        apptVideoLink
      )
    );

  const { formatTime } = getTimeZone(appointment.timeZone, type);

  const messagesUrl = pageUrl.messages({
    categoryName: CATEGORY_SLUG.ADMIN
  });
  const isChicagoPatient = patient?.lab?.id === VIOS_CHICAGO_LAB_ID.toString();

  return (
    <CardContainer isFuture={isFuture}>
      <AppointmentTime hasStepper={hasStepper}>
        <span>{formatTime(startTime, 'dddd, ')}</span>
        <span>{formatTime(startTime, 'MMMM Do')}</span>
        <span>{formatTime(startTime, 'h:mm A z')}</span>
      </AppointmentTime>
      <AppointmentName>{type.name}</AppointmentName>
      {shouldDisplayProvider(appointment) && appointment.providerName && (
        <ProviderInfo>
          <ProviderLabel>Provider:</ProviderLabel>
          <Initials name={appointment.providerName} />
          <ProviderName>{appointment.providerName}</ProviderName>
        </ProviderInfo>
      )}

      <LocationWrapper>
        {isVideoCall && isFuture && (
          <VideoConsult>
            <ConsultInstructions>{VIRTUAL_APPOINTMENT_LICENSING_DISCLAIMER}</ConsultInstructions>
            <Button
              label="Join video consult"
              category="primary"
              size="md"
              type="submit"
              onClick={goToVideoLink}
            />
          </VideoConsult>
        )}
        {isPhoneCall && isFuture && (
          <PhoneConsult>
            <ConsultInstructions>
              Your provider will call you. By joining this phone call appointment, you confirm that
              you are physically present in one of the state(s) where this provider is licensed.
            </ConsultInstructions>
          </PhoneConsult>
        )}
        {isInPerson && (
          <>
            <img
              src={isFuture ? locationIcon : locationDark}
              alt="A yellow map pin"
              style={{ marginRight: 16 }}
            />
            <AppointmentLocation>
              <ClinicName>{locationName}</ClinicName>
              <Address>{address}</Address>
            </AppointmentLocation>
          </>
        )}
      </LocationWrapper>

      {isFuture && (
        <FutureActionsWrapper>
          <Button label="Add to Calendar" category="secondary" size="md" onClick={addToCalendar} />
          {ppPreventSelfAppointmentChicagoPatient && isChicagoPatient ? (
            <RescheduledText fontStyle="regularItalic">
              To reschedule or cancel, or if you have any questions, please send us a{' '}
              <LinkButton
                text="message"
                fontStyle="regularItalic"
                onClick={() => history.push(messagesUrl)}
              />{' '}
              through the portal or call us at 773-435-9036.
            </RescheduledText>
          ) : (
            <LinkButton text="Cancel/Reschedule" onClick={toggleModal} size="sm" />
          )}
        </FutureActionsWrapper>
      )}
    </CardContainer>
  );
};

export default AppointmentCard;
