import React from 'react';
import { useHistory } from 'react-router-dom';

import {
  CakeCandles,
  ClipboardUser,
  Envelope,
  House,
  Phone,
  Prescription
} from 'assets/icons/Icons';
import { Button } from 'components/v2/Buttons/Button';
import { TextInput } from 'components/v2/Inputs';
import { Heading } from 'components/v2/Typography';
import { Patient } from 'kb-shared';
import { formatPhoneNumber } from 'kb-shared/utilities/phones';
import { analytics } from 'utilities/analytics';
import { formatDate } from 'utilities/formatDate';
import { pageUrl } from 'utilities/pageUrl';

import {
  ButtonsContainer,
  IconContainer,
  IllustratedBackgroundWidget,
  WidgetTitleContainer
} from '../Widgets/Widgets.styled';
import { DetailsInfoContainer } from './ProfileWidget.styled';

export const ProfileWidget = ({ patient }: { patient: Patient }) => {
  const history = useHistory();

  const birthdate = patient?.birthday ? formatDate(patient.birthday, 'MMMM DD, YYYY') : undefined;
  const patientPhone = formatPhoneNumber(patient.phone) || undefined;
  const pharmacyPhone = formatPhoneNumber(patient.patientPharmacy?.phone) || undefined;
  const { address, patientPharmacy } = patient;

  const addressDetails = [
    address?.address1,
    address?.address2,
    address?.city && address?.state ? `${address.city}, ${address.state}` : undefined,
    address?.zipcode
  ]
    .filter(Boolean)
    .join(', ');

  const pharmacyDetails = [patientPharmacy?.name, pharmacyPhone].filter(Boolean).join(', ');

  return (
    <IllustratedBackgroundWidget $variant={2}>
      <IconContainer $iconHeight={48} $iconWidth={36}>
        <ClipboardUser type="solid" />
      </IconContainer>
      <WidgetTitleContainer>
        <Heading tag="div" styledAs="h2">
          My Profile
        </Heading>
      </WidgetTitleContainer>
      <DetailsInfoContainer>
        {birthdate && (
          <TextInput
            ariaLabel="Birthdate"
            status="readOnly"
            type="text"
            value={birthdate}
            leftIcon={<CakeCandles type="regular" />}
          />
        )}
        {patient?.email && (
          <TextInput
            ariaLabel="Email"
            status="readOnly"
            type="text"
            value={patient?.email}
            leftIcon={<Envelope type="regular" />}
          />
        )}
        {patientPhone && (
          <TextInput
            ariaLabel="Phone"
            status="readOnly"
            type="text"
            value={patientPhone}
            leftIcon={<Phone type="regular" />}
          />
        )}
        {pharmacyDetails && (
          <TextInput
            ariaLabel="Pharmacy"
            status="readOnly"
            type="text"
            value={pharmacyDetails}
            leftIcon={<Prescription type="regular" />}
          />
        )}
        {addressDetails && (
          <TextInput
            ariaLabel="Address"
            status="readOnly"
            type="text"
            value={addressDetails}
            leftIcon={<House type="regular" />}
          />
        )}
      </DetailsInfoContainer>

      <ButtonsContainer>
        <Button
          label="Update Your Details"
          category="secondary"
          size="lg"
          onClick={() => {
            analytics.track(analytics.EVENTS.PROFILE_DETAILS_UPDATE_STARTED);
            history.push(pageUrl.myProfile());
          }}
        />
      </ButtonsContainer>
    </IllustratedBackgroundWidget>
  );
};
