import React from 'react';

import plus from 'assets/icons/plusYellow.svg';

import { PARTNER_TAB_INFO_BOX_MESSAGE } from '../../InterstitialModal.constants';
import { PartnerListProps } from '../../InterstitialModal.types';
import { InfoBox } from '../InfoBox';
import { AddPartnerButton } from './AddPartnerButton';
import { AddPartner, PlusIcon } from './PartnerList.styled';
import { PartnerListItem } from './PartnerListItem';

export const PartnerList = ({ onAddPartner, invites }: PartnerListProps) => {
  const invitesExist = invites && invites.length > 0;

  if (invitesExist) {
    return (
      <div>
        <div>
          {invites.map(i => (
            <PartnerListItem key={i.id} {...i} />
          ))}
        </div>

        <AddPartner onClick={onAddPartner}>
          <PlusIcon src={plus} />
          Add Partner
        </AddPartner>
      </div>
    );
  }

  return (
    <>
      <AddPartnerButton onClick={onAddPartner} />
      <InfoBox message={PARTNER_TAB_INFO_BOX_MESSAGE} />
    </>
  );
};
