import { gql, useQuery } from '@apollo/client';
import Honeybadger from '@honeybadger-io/js';
import { useEffect } from 'react';

import { PartnerInvite } from '../InterstitialModal.types';

const PARTNER_INVITES_QUERY = gql`
  query partnerInvites {
    partnerInvites {
      id
      firstName
      lastName
      email
      birthDate
      status
    }
  }
`;

export const usePartnerInvites = () => {
  const { data, loading, error, refetch } = useQuery(PARTNER_INVITES_QUERY);
  const allInvites: PartnerInvite[] = data ? data.partnerInvites : [];

  useEffect(() => {
    if (!error) return;

    Honeybadger.notify(error, 'Failed to fetch partner invites');
  }, [error]);

  return {
    invites: allInvites,
    refetch,
    loading,
    error
  };
};
