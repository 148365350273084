import React from 'react';

import StepDetail from '../details/StepDetail';
import { Description } from '../details/StepDetail';

const FollowUpBiopsy = () => {
  return (
    <StepDetail>
      <Description>
        10 days to 2 weeks later, you will receive a call with the results of your biopsy. The next
        steps will be discussed with you at this time.
      </Description>
      <Description>As always, contact us with any questions!</Description>
    </StepDetail>
  );
};

export default FollowUpBiopsy;
