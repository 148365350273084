import styled, { css } from 'styled-components';

export const V2_COMPONENT_CLASS_NAME = 'v2';

export const fontCSS = css`
  font-weight: 400;
  font-size: 16px;
`;

export const filledOutlineShadow = css`0px 1px 4px 1px rgba(67, 71, 146, 0.16), 0px 1px 1px 0px rgba(10, 13, 61, 0.12), 0px 0px 1px 0px rgba(10, 13, 61, 0.08);
`;

type CommonField =
  | 'placeholderColor'
  | 'textColor'
  | 'sideIconFillColor'
  | 'borderStyle'
  | 'borderColor'
  | 'borderWidth'
  | 'borderRadius'
  | 'backgroundColor'
  | 'boxShadow'
  | 'cursor';

type CommonStyling = Readonly<Partial<Record<CommonField, string | ReturnType<typeof css>>>>;

export const defaultState: CommonStyling = {
  backgroundColor: css`white`,
  textColor: css`
    ${({ theme }) => theme.colors.neutral.primaryNavy}
  `,
  placeholderColor: css`
    ${({ theme }) => theme.colors.neutral.lightNavy}
  `,
  sideIconFillColor: css`
    ${({ theme }) => theme.colors.neutral.primaryNavy}
  `,
  borderStyle: css`hidden`,
  borderColor: css`
    ${({ theme }) => theme.colors.neutral.lighterNavy}
  `,
  borderWidth: css`1px`,
  borderRadius: css`8px`,
  boxShadow: css`
    ${({ theme }) => theme.shadows.form.level0}
  `,
  cursor: css`default`
};

export const focusedState: CommonStyling = {
  ...defaultState,

  // overrides of defaultState
  boxShadow: css`
    ${({ theme }) => theme.shadows.form.focused}
  `
};

export const errorState: CommonStyling = {
  ...defaultState,

  // overrides of defaultState
  textColor: css`
    ${({ theme }) => theme.colors.semantic.red}
  `,
  placeholderColor: css`
    ${({ theme }) => theme.colors.semantic.red}
  `,
  borderColor: css`
    ${({ theme }) => theme.colors.semantic.red}
  `,
  borderWidth: css`2px`,
  sideIconFillColor: css`
    ${({ theme }) => theme.colors.semantic.red}
  `,
  boxShadow: css`
    ${({ theme }) => theme.shadows.form.error}
  `
};

export const disabledState: CommonStyling = {
  ...defaultState,

  // overrides of defaultState
  borderColor: css`
    ${({ theme }) => theme.colors.neutral.lightNavy}
  `,
  backgroundColor: css`
    ${({ theme }) => theme.colors.neutral.lavenderLight}
  `,
  placeholderColor: css`
    ${({ theme }) => theme.colors.neutral.lighterNavy}
  `,
  textColor: css`
    ${({ theme }) => theme.colors.neutral.lighterNavy}
  `,
  cursor: css`not-allowed`
};

export const readOnlyState: CommonStyling = {
  ...disabledState,

  // overrides of disabledState
  textColor: css`
    ${({ theme }) => theme.colors.neutral.primaryNavy}
  `,
  backgroundColor: css`
    ${({ theme }) => theme.colors.neutral.lavenderWhite}
  `,
  cursor: css`text`
};

export const LongWordContainer = styled.div`
  word-break: break-all;
`;
