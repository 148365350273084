import { themes } from 'kb-shared';
import styled from 'styled-components';
const { colors, breakpoints } = themes;

export const ActionBlockWrapper = styled.div`
  display: flex;
  justify-content: space-evenly;
  border: 4px solid ${colors.yellow.primary};
  flex-wrap: wrap;
  @media (max-width: ${breakpoints.raw.smallDesktop}px) {
    flex-direction: column;
  }
`;

export const ActionBlockContainer = styled.div`
  display: flex;
  justify-content: space-evenly;
  border: 4px solid ${colors.yellow.primary};
  flex-wrap: wrap;
  @media (max-width: ${breakpoints.raw.smallDesktop}px) {
    flex-direction: column;
  }
`;

export const ActionBlock = styled.div`
  border-right: 1px solid ${colors.neutral.primaryNavy};
  flex: 1 1 0;
  margin: 20px 0;
  text-align: center;
  display: flex;
  flex-direction: column;
  padding: 10px;

  @media (max-width: ${breakpoints.raw.smallDesktop}px) {
    border-bottom: 1px solid ${colors.neutral.primaryNavy};
    border-right: none;
    margin: 0 20px;
    padding: 10px 0;
  }

  :last-of-type {
    border: none;
  }
`;

export const ActionBlockHeader = styled.div`
  flex: 1;

  svg {
    color: ${colors.yellow.primary};
  }
`;

export const ActionBlockTitle = styled.h3`
  background-color: white;
  font-size: 24px;
  margin-bottom: 0;
`;

export const ActionBlockDescription = styled.div`
  background-color: white;
  font-size: 16px;
  margin-bottom: 28px;
`;
