import { LOCATION_OPTIONS, STRIPE_ACCOUNTS_BY_LOCATION } from './DirectPayment.constants';

// function that return string and filters only numbers and decimal point
export const filterNumberOnly = (value: string) => {
  return value.replace(/[^0-9.]/g, '');
};

// function that takes string and return string in format of currency with fixed 2 decimal places and comma separated thousands
export const formatCurrency = (value: string) => {
  if (!value) return '$0.00';
  const number = parseFloat(value);
  return `$${number.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}`;
};

export const getStripeAccount = (location: string): any => {
  const locationOption = LOCATION_OPTIONS.find(option => option.value === location);
  const state = locationOption?.state;

  const stripeAccount = STRIPE_ACCOUNTS_BY_LOCATION.find(account => account.state === state);

  if (!stripeAccount) throw new Error('Stripe account not found for location: ' + location);

  return stripeAccount;
};

export const getStripeAccountId = (location: string): string => {
  const { stripeAccountId, stripeTestAccountId } = getStripeAccount(location);

  return process.env.REACT_APP_ENV === 'production' ? stripeAccountId : stripeTestAccountId;
};
