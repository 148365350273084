import React, { useState } from 'react';

import { Answer } from 'kb-shared';

import ChoiceButton from '../../ChoiceButton';
import Text from '../../Primitives/FormText';
import { Container, ButtonContainer, ButtonWrapper } from './BooleanForm.styled';

export const BooleanForm = ({
  title,
  isRequired,
  onSubmit,
  answer,
  skipLinkComponent
}: {
  title: string;
  isRequired: boolean | null;
  onSubmit: (response: string) => void;
  answer: Answer | null;
  skipLinkComponent: JSX.Element | null;
}) => {
  const [selectedOption, setSelectedOption] = useState(answer?.data || '');

  const optionSelected = (option: string) => {
    setSelectedOption(option);
    onSubmit(option);
  };

  return (
    <Container>
      <Text isRequired={isRequired}>{title}</Text>
      <ButtonContainer>
        <ButtonWrapper>
          <ChoiceButton
            text={'No'}
            selected={selectedOption === 'false'}
            onClick={() => optionSelected('false')}
          />
        </ButtonWrapper>
        <ButtonWrapper>
          <ChoiceButton
            text={'Yes'}
            selected={selectedOption === 'true'}
            onClick={() => optionSelected('true')}
          />
        </ButtonWrapper>
      </ButtonContainer>
      {skipLinkComponent}
    </Container>
  );
};
