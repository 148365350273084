import styled from 'styled-components';

export const TermsOfUseContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  > div {
    max-width: 345px;
    text-align: center;
  }

  > div > span > span {
    text-decoration: underline;
    cursor: pointer;
  }
`;
