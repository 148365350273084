import { InputHistory, InputOptions } from './InputMask.types';
import { CharsFormatters } from './InputWithMask.types';
import { Selection } from './InputWithMask.types';
import { Pattern } from './Pattern';

function extend(dest: any, src: any) {
  if (src) {
    const props = Object.keys(src);
    for (let i = 0, l = props.length; i < l; i++) {
      dest[props[i]] = src[props[i]];
    }
  }
  return dest;
}

function copy(obj: Selection): Selection {
  return extend({}, obj);
}

/**
 * Merge an object defining format characters into the defaults.
 * Passing null/undefined for en existing format character removes it.
 * Passing a definition for an existing format character overrides it.
 * @param {?Object} formatCharacters.
 */
function mergeFormatCharacters(formatCharacters: CharsFormatters | undefined) {
  const merged = extend({}, DEFAULT_FORMAT_CHARACTERS);
  if (formatCharacters) {
    const chars = Object.keys(formatCharacters);
    for (let i = 0, l = chars.length; i < l; i++) {
      const char = chars[i];
      if (formatCharacters[char] == null) {
        delete merged[char];
      } else {
        merged[char] = formatCharacters[char];
      }
    }
  }
  return merged;
}

export const ESCAPE_CHAR = '\\';

const DIGIT_RE = /^\d$/;
const LETTER_RE = /^[A-Za-z]$/;
const ALPHANNUMERIC_RE = /^[\dA-Za-z]$/;

export const DEFAULT_PLACEHOLDER_CHAR = '_';
export const DEFAULT_FORMAT_CHARACTERS: CharsFormatters = {
  '*': {
    validate: function(char: string) {
      return ALPHANNUMERIC_RE.test(char);
    }
  },
  '1': {
    validate: function(char: string) {
      return DIGIT_RE.test(char);
    }
  },
  a: {
    validate: function(char: string) {
      return LETTER_RE.test(char);
    }
  },
  A: {
    validate: function(char: string) {
      return LETTER_RE.test(char);
    },
    transform: function(char: string) {
      return char.toUpperCase();
    }
  },
  '#': {
    validate: function(char: string) {
      return ALPHANNUMERIC_RE.test(char);
    },
    transform: function(char: string) {
      return char.toUpperCase();
    }
  }
};

export class InputMask {
  placeholderChar = '';
  formatCharacters: CharsFormatters = {};
  selection: Selection = { start: 0, end: 0 };
  pattern: Pattern | null = null;
  value: string[] = [];
  _lastSelection: Selection | null = null;
  _lastOp: string | null = '';
  _history: InputHistory[] = [];
  _historyIndex: number | null = null;
  emptyValue = '';

  constructor(options: {
    formatCharacters: CharsFormatters | undefined;
    pattern: string;
    isRevealingMask?: boolean;
    placeholderChar?: string;
    selection?: { start: number; end: number };
    value?: string | undefined;
  }) {
    if (!(this instanceof InputMask)) {
      return new InputMask(options);
    }

    options = {
      ...options,
      isRevealingMask: false,
      placeholderChar: DEFAULT_PLACEHOLDER_CHAR,
      selection: { start: 0, end: 0 }
    };

    if (options.pattern == null) {
      throw new Error('InputMask: you must provide a pattern.');
    }

    if (typeof options.placeholderChar !== 'string' || options.placeholderChar.length > 1) {
      throw new Error(
        'InputMask: placeholderChar should be a single character or an empty string.'
      );
    }

    this.placeholderChar = options.placeholderChar;
    this.formatCharacters = mergeFormatCharacters(options.formatCharacters);
    this.setPattern(options.pattern, {
      value: options.value,
      selection: options.selection || { start: 0, end: 0 },
      isRevealingMask: options.isRevealingMask
    });
  }

  public input(char: string): boolean {
    // Ignore additional input if the cursor's at the end of the pattern
    if (
      this.selection.start === this.selection.end &&
      this.selection.start === this.pattern?.length
    ) {
      return false;
    }

    const selectionBefore = copy(this.selection) as Selection;
    const valueBefore = this.getValue();

    let inputIndex = this.selection.start;

    // If the cursor or selection is prior to the first editable character, make
    // sure any input given is applied to it.
    if (this.pattern?.firstEditableIndex != null && inputIndex < this.pattern.firstEditableIndex) {
      inputIndex = this.pattern.firstEditableIndex;
    }

    // Bail out or add the character to input
    if (this.pattern?.isEditableIndex(inputIndex)) {
      if (!this.pattern.isValidAtIndex(char, inputIndex)) {
        return false;
      }
      this.value[inputIndex] = this.pattern.transform(char, inputIndex);
    }

    // If multiple characters were selected, blank the remainder out based on the
    // pattern.
    let end = this.selection.end - 1;
    while (end > inputIndex) {
      if (this.pattern?.isEditableIndex(end)) {
        this.value[end] = this.placeholderChar;
      }
      end--;
    }

    // Advance the cursor to the next character
    this.selection.start = this.selection.end = inputIndex + 1;

    // Skip over any subsequent static characters
    while (
      this.pattern &&
      this.pattern.length > this.selection.start &&
      !this.pattern?.isEditableIndex(this.selection.start)
    ) {
      this.selection.start++;
      this.selection.end++;
    }

    // History
    if (this._historyIndex != null) {
      // Took more input after undoing, so blow any subsequent history away
      this._history.splice(this._historyIndex, this._history.length - this._historyIndex);
      this._historyIndex = null;
    }
    if (
      this._lastOp !== 'input' ||
      selectionBefore.start !== selectionBefore.end ||
      (this._lastSelection !== null && selectionBefore.start !== this._lastSelection.start)
    ) {
      this._history.push({ value: valueBefore, selection: selectionBefore, lastOp: this._lastOp });
    }
    this._lastOp = 'input';
    this._lastSelection = copy(this.selection);

    return true;
  }

  public setPattern(pattern: string, options: InputOptions) {
    options = extend(
      {
        selection: { start: 0, end: 0 },
        value: ''
      },
      options
    );
    this.pattern = new Pattern(
      pattern,
      this.formatCharacters,
      this.placeholderChar,
      options.isRevealingMask
    );
    this.setValue(options.value || '');
    this.emptyValue = this.pattern.formatValue([]).join('');
    this.selection = options.selection;
    this._resetHistory();
  }

  public backspace() {
    // If the cursor is at the start there's nothing to do
    if (this.selection.start === 0 && this.selection.end === 0) {
      return false;
    }

    const selectionBefore = copy(this.selection);
    const valueBefore = this.getValue();

    // No range selected - work on the character preceding the cursor
    if (this.selection.start === this.selection.end) {
      if (this.pattern?.isEditableIndex(this.selection.start - 1)) {
        if (this.pattern.isRevealingMask) {
          this.value.splice(this.selection.start - 1);
        } else {
          this.value[this.selection.start - 1] = this.placeholderChar;
        }
      }
      this.selection.start--;
      this.selection.end--;
    }
    // Range selected - delete characters and leave the cursor at the start of the selection
    else {
      let end = this.selection.end - 1;
      while (end >= this.selection.start) {
        if (this.pattern?.isEditableIndex(end)) {
          this.value[end] = this.placeholderChar;
        }
        end--;
      }
      this.selection.end = this.selection.start;
    }

    // History
    if (this._historyIndex != null) {
      // Took more input after undoing, so blow any subsequent history away
      this._history.splice(this._historyIndex, this._history.length - this._historyIndex);
    }
    if (
      this._lastOp !== 'backspace' ||
      selectionBefore.start !== selectionBefore.end ||
      (this._lastSelection !== null && selectionBefore.start !== this._lastSelection.start)
    ) {
      this._history.push({ value: valueBefore, selection: selectionBefore, lastOp: this._lastOp });
    }
    this._lastOp = 'backspace';
    this._lastSelection = copy(this.selection);

    return true;
  }

  public getValue() {
    if (this.pattern?.isRevealingMask) {
      this.value = this.pattern.formatValue(this.getRawValue().split(''));
    }
    return this.value.join('');
  }

  public paste(input: string) {
    // This is necessary because we're just calling input() with each character
    // and rolling back if any were invalid, rather than checking up-front.
    const initialState = {
      value: this.value.slice(),
      selection: copy(this.selection),
      _lastOp: this._lastOp,
      _history: this._history.slice(),
      _historyIndex: this._historyIndex,
      _lastSelection: this._lastSelection == null ? this._lastSelection : copy(this._lastSelection)
    };

    // If there are static characters at the start of the pattern and the cursor
    // or selection is within them, the static characters must match for a valid
    // paste.
    if (
      this.pattern?.firstEditableIndex &&
      this.selection.start < this.pattern.firstEditableIndex
    ) {
      for (let i = 0, l = this.pattern.firstEditableIndex - this.selection.start; i < l; i++) {
        if (input.charAt(i) !== this.pattern.pattern[i]) {
          return false;
        }
      }

      // Continue as if the selection and input started from the editable part of
      // the pattern.
      input = input.substring(this.pattern.firstEditableIndex - this.selection.start);
      this.selection.start = this.pattern.firstEditableIndex;
    }

    if (this.pattern?.lastEditableIndex) {
      for (
        let i = 0, l = input.length;
        i < l && this.selection.start <= this.pattern.lastEditableIndex;
        i++
      ) {
        const valid = this.input(input.charAt(i));
        // Allow static parts of the pattern to appear in pasted input - they will
        // already have been stepped over by input(), so verify that the value
        // deemed invalid by input() was the expected static character.
        if (!valid) {
          if (this.selection.start > 0) {
            // XXX This only allows for one static character to be skipped
            const patternIndex = this.selection.start - 1;
            if (
              !this.pattern.isEditableIndex(patternIndex) &&
              input.charAt(i) === this.pattern.pattern[patternIndex]
            ) {
              continue;
            }
          }
          extend(this, initialState);
          return false;
        }
      }
    }

    return true;
  }

  public undo() {
    // If there is no history, or nothing more on the history stack, we can't undo
    if (this._history.length === 0 || this._historyIndex === 0) {
      return false;
    }

    let historyItem;
    if (this._historyIndex == null) {
      // Not currently undoing, set up the initial history index
      this._historyIndex = this._history.length - 1;
      historyItem = this._history[this._historyIndex];
      // Add a new history entry if anything has changed since the last one, so we
      // can redo back to the initial state we started undoing from.
      const value = this.getValue();
      if (
        historyItem.value !== value ||
        historyItem.selection.start !== this.selection.start ||
        historyItem.selection.end !== this.selection.end
      ) {
        this._history.push({
          value: value,
          selection: copy(this.selection),
          lastOp: this._lastOp,
          startUndo: true
        });
      }
    } else {
      historyItem = this._history[--this._historyIndex];
    }

    this.value = historyItem.value.split('');
    this.selection = historyItem.selection;
    this._lastOp = historyItem.lastOp;
    return true;
  }

  public redo() {
    if (this._history.length === 0 || this._historyIndex == null) {
      return false;
    }
    const historyItem = this._history[++this._historyIndex];
    // If this is the last history item, we're done redoing
    if (this._historyIndex === this._history.length - 1) {
      this._historyIndex = null;
      // If the last history item was only added to start undoing, remove it
      if (historyItem.startUndo) {
        this._history.pop();
      }
    }
    this.value = historyItem.value.split('');
    this.selection = historyItem.selection;
    this._lastOp = historyItem.lastOp;
    return true;
  }

  public setValue(value: string | undefined) {
    if (value == null) {
      value = '';
    }
    if (this.pattern) {
      this.value = this.pattern.formatValue(value.split(''));
    }
  }

  public getRawValue() {
    const rawValue = [];
    for (let i = 0; i < this.value.length; i++) {
      if (this.pattern?._editableIndices[i] === true) {
        rawValue.push(this.value[i]);
      }
    }
    return rawValue.join('');
  }

  public _resetHistory() {
    this._history = [];
    this._historyIndex = null;
    this._lastOp = null;
    this._lastSelection = copy(this.selection);
  }
}
