import React from 'react';

import { Button } from 'components/v2/Buttons/Button';

import GlucoseTestingPDF from '../../assets/Booklet-GlucoseTesting.pdf';
import SubstepWrapper from '../../SubstepWrapper';
import { Description, FlexWrapper, Title } from './styled-components';

const BloodTest = () => (
  <>
    <Title>Blood Test Only (if needed)</Title>
    <FlexWrapper>
      <Description>
        <strong>Glucose Tolerance Test:</strong> This is a 3 hr diabetes test and is only needed if
        you had an abnormal gestational diabetes test. Do not eat or drink anything for 8 hours
        prior to this test.
      </Description>
      <Button
        label="View More"
        category="secondary"
        size="md"
        onClick={() => window.open(GlucoseTestingPDF)}
      />
    </FlexWrapper>
  </>
);

const SUBSTEPS = [{ component: <BloodTest /> }];

const Week26To28Substeps = () => <SubstepWrapper substeps={SUBSTEPS} />;

export default Week26To28Substeps;
