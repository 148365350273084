import EpicDisneyConfig from './config';

interface Params {
  launch?: string;
  client_id: string;
  redirect_uri: string;
  scope: string;
  response_type: string;
  aud: string;
}

// we request authorization code both in Standalone launch and Embedded launch
export default function requestAuthorizationCode(launchToken: string | null) {
  const params: Params = {
    client_id: EpicDisneyConfig.appOrchardAppId,
    redirect_uri: EpicDisneyConfig.redirectBackToKindbodyUrl(),
    // you can use scope: 'launch openid profile fhirUser' to get more data in response if you need it
    scope: 'openid fhirUser',
    response_type: 'code',
    // Starting in the May 2023 version of Epic, this parameter (aud) is required for Standalone and EHR
    // launch workflows. This parameter is optional in February 2023 versions and earlier. The value to use is
    // the FHIR base URL of the resource server the application intends to access, which is typically the FHIR
    // server returned by the iss.
    aud: `${EpicDisneyConfig.epicUrl}/oauth2`
  };

  if (launchToken) {
    // with embedded launch, we are no asked again to login in MyChart
    // thanks to the launch token
    params.launch = launchToken;
    params.scope = 'launch openid fhirUser';
  }

  const url = `${EpicDisneyConfig.epicUrl}/oauth2/authorize`;
  window.location.replace(url + '?' + new URLSearchParams(params as any));
}
