import { gql, useQuery } from '@apollo/client';
import React from 'react';

import { ObtainMedications, Injections, Todos, LogYourPeriod, ScheduleFDAAppointment } from '.';
import SubstepWrapper from '../SubstepWrapper';

const JOURNEY_TYPE_QUERY = gql`
  query preparingForCycle {
    journeyType

    cyclePlan {
      id
      hasMedications
      __typename
    }
  }
`;

const PREPARE_SUBSTEPS = [
  { component: <ObtainMedications key="medications" /> },
  { component: <ScheduleFDAAppointment key="schedule-fda-appointment" /> },
  { component: <Todos key="todos" /> },
  { component: <LogYourPeriod key="log-period" /> },
  { component: <Injections key="injections" /> }
];

const PreparingForCycleListForDonorIVF = () => {
  const { data } = useQuery(JOURNEY_TYPE_QUERY);
  const journeyType = data?.journeyType;
  const hasMedications = data?.cyclePlan?.hasMedications === 'true';

  if (!journeyType) return null;

  const [medications, scheduleFDAAppointment, todos, logPeriod, injections] = PREPARE_SUBSTEPS;

  let substeps = [];

  if (hasMedications) {
    substeps.push(medications);
  }

  substeps = substeps.concat([scheduleFDAAppointment, todos, logPeriod]);

  if (journeyType !== 'IUI' && hasMedications) {
    substeps.push(injections);
  }

  return <SubstepWrapper substeps={substeps} />;
};

export default PreparingForCycleListForDonorIVF;
