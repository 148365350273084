import React from 'react';
import { Link } from 'react-router-dom';

import { ContainerStyled, Button, FinishedSubtitle, TitleText } from './AcknowledgeStatus.styles';

const ResentNeeded: React.FC = () => (
  <ContainerStyled>
    <TitleText>Your cycle plan needs to be resent!</TitleText>
    <FinishedSubtitle>Please reach out to your provider.</FinishedSubtitle>

    <Link to="/dashboard">
      <Button text="Go to dashboard" />
    </Link>
  </ContainerStyled>
);

export default ResentNeeded;
