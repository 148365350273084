import React from 'react';

import StepDetail, { Description, Subheader } from '../StepDetail';

const ObPostPartumDay2Details = () => (
  <StepDetail>
    <Subheader>Phone Call with your Provider</Subheader>
    <Description>
      Your provider will call you to see how you & baby are doing. We are here for you physically,
      emotionally & as your support system.
    </Description>
  </StepDetail>
);

export default ObPostPartumDay2Details;
