import { OptionParams } from 'utilities/userUtil.types';

export const sortLabsOptions = (a: OptionParams, b: OptionParams) => {
  // equal items sort equally
  if (a.label === b.label) {
    return 0;
  }
  // Other sort after anything else
  else if (a.label === 'Other') {
    return 1;
  } else if (b.label === 'Other') {
    return -1;
  }
  // otherwise lowest sorts first
  else {
    return a.label < b.label ? -1 : 1;
  }
};
