import { gql } from '@apollo/client';

export const INTAKE_AND_TODO = gql`
  query formAndTodo($formId: Int!) {
    formCompletion(formId: $formId) {
      id
      percentageComplete
    }
    patientTodos {
      id
      todoId
      title: name
      linkUrl
      buttonText
      description
      completed
      order
      headingText
      category
      createdAt
    }
  }
`;
