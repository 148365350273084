import moment from 'moment';

import {
  isPasswordEqualOrGreaterThan,
  doesPasswordContainsNumericCharacters,
  doesPasswordContainsUpperAndLowerCaseCharacters,
  doesPasswordContainsSpecialCharacters
} from './passwordValidation';

// Simple regex for validating @ and . in email
export const isEmailValid = (email: string | undefined) => {
  return email && email.slice(-3) !== 'con' && /.*@.*\..{2,}/.test(email);
};

export const isEmailCodeValid = (emailCode: string) => /^[0-9]{6}$/g.test(emailCode);

export const PASSWORD_ERRORS = {
  EQUAL_OR_GREATER_THAN: 'EQUAL_OR_GREATER_THAN',
  CONTAINS_NUMERIC_CHARACTERS: 'CONTAINS_NUMERIC_CHARACTERS',
  CONTAINS_UPPER_AND_LOWER_CASE_CHARACTERS: 'CONTAINS_UPPER_AND_LOWER_CASE_CHARACTERS',
  CONTAINS_SPECIAL_CHARACTERS: 'CONTAINS_SPECIAL_CHARACTERS'
};

export const getPasswordErrors = (password: string) => {
  const errors = [];

  if (!isPasswordEqualOrGreaterThan(password, 8)) {
    errors.push(PASSWORD_ERRORS.EQUAL_OR_GREATER_THAN);
  }

  if (!doesPasswordContainsNumericCharacters(password)) {
    errors.push(PASSWORD_ERRORS.CONTAINS_NUMERIC_CHARACTERS);
  }

  if (!doesPasswordContainsUpperAndLowerCaseCharacters(password)) {
    errors.push(PASSWORD_ERRORS.CONTAINS_UPPER_AND_LOWER_CASE_CHARACTERS);
  }

  if (!doesPasswordContainsSpecialCharacters(password)) {
    errors.push(PASSWORD_ERRORS.CONTAINS_SPECIAL_CHARACTERS);
  }

  return errors;
};

export const isPasswordValid = (password: string) => {
  const errors = getPasswordErrors(password);

  if (errors.length !== 0) {
    return false;
  }

  return true;
};

export const isPasswordMatch = (set: string, confirm: string): boolean => {
  return set === confirm;
};

// String cannot contain special chars and must be 1 char long.
export const isInputValid = (str: string | undefined, minLength = 1): boolean => {
  if (!str) {
    return false;
  }

  str = str.trim();

  // eslint-disable-next-line no-useless-escape
  const regex = /^[\p{Alphabetic}.'\-\s\/)(+=._-]{1,}$/gu;
  return regex.test(str) && str.length >= minLength;
};

// Checks length and chars within phone number
export const isPhoneNumberValid = (str: string) => {
  const justDigits = str.replace(/[-)(+=._-\s]/g, '');
  const regex = /^[0-9.\-)(+=._-]{10,15}$/g;
  return regex.test(justDigits);
};

export const isLabValid = (lab?: { id: string }) => {
  if (!lab) return false;
  return !!lab.id;
};

// Users must be 18 years or older
export const isDOBValid = (birthday: string) => {
  const today = moment()
    .add(1, 'day')
    .format('L');
  return moment(birthday)
    .add(18, 'years')
    .isBefore(today);
};

export const isDateValid = (date: string) => {
  return moment(date, 'MM-DD-YYYY').isValid() || moment(date, 'YYYY-MM-DD').isValid();
};

export const isBirthDateValid = (date: Date) => {
  return date < new Date();
};
