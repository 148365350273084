import React from 'react';

import { Button } from 'components/v2/Buttons/Button';

import ExercisePDF from '../../assets/Booklet-Exercise.pdf';
import NutritionPDF from '../../assets/Booklet-Nutrition.pdf';
import SubstepWrapper from '../../SubstepWrapper';
import { Description, FlexWrapper, Title } from './styled-components';

const FirstOBAppointment = () => (
  <>
    <Title>First OB Appointment</Title>
    <Description>
      This visit includes a comprehensive consultation, introduction to your pregnancy journey,
      physical exam, vaginal ultrasound to see the pregnancy, & blood work.
    </Description>
    <Description>
      <strong>Blood work:</strong> Your provider will call you within a week of your visit to review
      your results and you will be able to review them in the portal.
    </Description>
  </>
);

const NutritionAppointment = () => (
  <>
    <Title>Nutrition Appointment</Title>
    <FlexWrapper>
      <Description>
        You will have 5 scheduled nutrition visits during your pregnancy. Good diet & nutrition are
        essential for a healthy mom & baby. The 1st visit provides an introduction to your
        individualized nutrition program. The team will help you schedule your appointments after
        your 1st OB visit.
      </Description>
      <Button
        label="View More"
        category="secondary"
        size="md"
        onClick={() => window.open(NutritionPDF)}
      />
    </FlexWrapper>
  </>
);

const MedicalFitnessAppointment = () => (
  <>
    <Title>Medical Fitness Appointment</Title>
    <FlexWrapper>
      <Description>
        You will have 5 scheduled physical fitness visits during your pregnancy. Physical exercise
        can help with some common discomforts of pregnancy and even help prepare your body for labor
        and delivery. The 1st visit provides an introduction to your individualized fitness program.
        The team will help you schedule your appointments after your 1st OB visit.
      </Description>
      <Button
        label="View More"
        category="secondary"
        size="md"
        onClick={() => window.open(ExercisePDF)}
      />
    </FlexWrapper>
  </>
);

const SUBSTEPS = [
  { component: <FirstOBAppointment /> },
  { component: <NutritionAppointment /> },
  { component: <MedicalFitnessAppointment /> }
];

const ObWeek0Substeps = () => <SubstepWrapper substeps={SUBSTEPS} />;

export default ObWeek0Substeps;
