import React, { useRef } from 'react';

import { useTooltip } from 'hooks/useTooltip';

import { DropdownItemLabel, DropdownItem as DropdownItemRow } from './Dropdown.styled';
import { MenuItemDetails } from './Dropdown.types';

export function DropdownItem({
  label,
  onClick,
  type,
  tooltipText = '',
  isDisabled = false,
  rightSideText
}: MenuItemDetails) {
  const tooltipTrigger = useRef<HTMLDivElement>(null);

  useTooltip({
    triggerElementRef: tooltipTrigger,
    content: tooltipText,
    visible: !!isDisabled && Boolean(tooltipText)
  });

  return (
    <div ref={tooltipTrigger}>
      <DropdownItemRow onClick={onClick} itemType={type} disabled={isDisabled}>
        <DropdownItemLabel type={type} disabled={isDisabled}>
          {label}
          {rightSideText && <span>{rightSideText}</span>}
        </DropdownItemLabel>
      </DropdownItemRow>
    </div>
  );
}
