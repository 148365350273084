import { gql, useQuery } from '@apollo/client';
import React, { useState } from 'react';

import { Button } from 'components/v2/Buttons/Button';
import styled from 'styled-components';

import PreopInstructionsModal from './PreopInstructionsModal';
import StepDetail, { Description } from './StepDetail';

const TRIGGER_INSTRUCTION = gql`
  query triggerInstruction {
    journeyType
  }
`;

enum JOURNEY_TYPE {
  IUI = 'IUI',
  TI = 'TIMED INTERCOURSE'
}

const isIUIorTIJourney = (journeyType: string) =>
  journeyType === JOURNEY_TYPE.IUI || journeyType === JOURNEY_TYPE.TI;

const TriggerInjectionDetail = () => {
  const [showModal, setShowModal] = useState(false);
  const { loading, data } = useQuery(TRIGGER_INSTRUCTION);

  if (loading || !data) {
    return null;
  }

  const { journeyType } = data;
  const isIUIOrTI = isIUIorTIJourney(journeyType);

  const getReviewInstuctionsButton = () => {
    return !isIUIOrTI ? (
      <ButtonStyled label="Review Pre-Op Instructions" onClick={() => setShowModal(!showModal)} />
    ) : null;
  };

  return (
    <>
      <StepDetail>
        <FlexContainer>
          <Description>
            You will be provided with the specific medication that's right for you as well as a
            specific time to inject it.
          </Description>
          {getReviewInstuctionsButton()}
        </FlexContainer>
      </StepDetail>
      <PreopInstructionsModal show={showModal} hide={() => setShowModal(false)} />
    </>
  );
};

export default TriggerInjectionDetail;

const FlexContainer = styled.div`
  display: flex;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.smallDesktop}) {
    flex-wrap: wrap;
  }
`;

const ButtonStyled = styled(Button)`
  min-width: fit-content;
  height: fit-content;
`;
