import { CyclePlanType } from 'screens/CyclePlanAcknowledge/CyclePlanAcknowledge.types';

export type FoundFile = {
  path: string;
  fileName: string;
  destination: string;
  s3Path: string;
};

export type AttachmentInformation = {
  id: number;
  fileName: string;
  presignedUrl: string;
  createdAt?: string;
  uuid?: string;
  description?: string;
  purpose?: string;
};

export type DocumentTabType = {
  key: string;
  title: string;
};

export enum DocumentType {
  TREATMENT_PLANS = 'treatment_plans',
  CONSENT = 'consents'
}

export interface PatientDocument {
  category: DocumentType;
  date: string;
  jsonData: CyclePlanType[];
  name: string;
  documentLink: string;
  databaseId: string;
}
