/* eslint-disable @typescript-eslint/indent */
import React, { Component } from 'react';
import Select from 'react-select';

import { themes } from 'kb-shared';
import styled from 'styled-components';
import { isUndefinedOrEmptyString } from 'utilities/isUndefinedOrEmptyString';

import { Label, RequiredIndicatorLabel } from './TextInput/NewTextInput.styles';

const { colors, deprecatedColors } = themes;

type ContainerInterface = {
  fullMode?: boolean;
  disableHeightLimit: boolean;
};

const Container = styled.div<ContainerInterface>`
  display: block;
  width: 100%;
  flex-direction: column;
  align-items: flex-start;
  height: ${({ disableHeightLimit }) => (disableHeightLimit ? 'auto' : '55px')};
  max-height: ${({ disableHeightLimit }) => (disableHeightLimit ? 'unset' : '55px')};
  max-width: ${({ fullMode }) => (fullMode ? '100%' : '340px')};
  @media screen and (max-width: ${themes.breakpoints.largePhonePortrait}) {
    max-width: 100%;
  }
  background: white;
`;

type Props<T> = {
  options: T[];
  label: string;
  placeholder: string;
  selectedOption: T | null;
  value?: string;
  type?: string;
  error?: boolean;
  className?: string;
  isNew?: boolean;
  name?: string;
  disabled?: boolean;
  onSelect: (item: T) => void;
  extractTitle: (item: T | null) => string;
  isRequired?: boolean;
  fullMode?: boolean;
  maxHeight?: number;
  ariaLabelledby?: string;
  externalAriaLabelledby?: string;
  inputId?: string;
  disableHeightLimit?: boolean;
};

export interface SelectItem {
  // @ts-ignore
  original: T;
  label: string;
  value: string;
}

export interface SelectAction {
  name: string;
}

export default class SelectInput<T> extends Component<Props<T>> {
  static defaultProps: Props<unknown> = {
    options: [],
    label: '',
    placeholder: '',
    selectedOption: null,
    isNew: false,
    name: '',
    onSelect: () => {},
    extractTitle: () => '',
    ariaLabelledby: '',
    externalAriaLabelledby: ''
  };

  customStyles = {
    // @ts-ignore
    control: (provided, { isFocused, isSelected }) => ({
      ...provided,
      backgroundColor: 'transparent',
      borderRadius: '0',
      border: isSelected ? 'none' : isFocused ? 'none' : 'none',
      cursor: 'pointer',
      boxShadow: 'none',
      borderBottom: isSelected
        ? `solid 1px ${colors.yellow.primary}`
        : isFocused
        ? `solid 1px ${colors.yellow.primary}`
        : `solid 1px black`,
      '&:hover': {
        borderBottom: `solid 1px ${colors.yellow.primary}`
      }
    }),
    // @ts-ignore
    option: (provided, { isFocused, isSelected }) => ({
      ...provided,
      backgroundColor: isSelected
        ? colors.yellow.primary
        : isFocused
        ? colors.yellow.lighterYellow
        : 'transparent',
      fontSize: '24px',
      color: colors.neutral.primaryNavy,
      '&:hover': {
        cursor: 'pointer'
      }
    }),
    // @ts-ignore
    dropdownIndicator: (provided, { isFocused, isSelected }) => ({
      ...provided,
      color: isSelected ? colors.yellow.primary : isFocused ? colors.yellow.primary : null
    }),
    // @ts-ignore
    indicatorSeparator: provided => ({
      ...provided,
      display: 'none'
    }),
    // @ts-ignore
    placeholder: provided => ({
      ...provided,
      fontDisplay: 'swap',
      fontSize: '24px',
      textAlign: 'left',
      color: deprecatedColors.gray
    }),
    // @ts-ignore
    valueContainer: provided => ({
      ...provided,
      fontDisplay: 'swap',
      fontSize: '24px'
    }),
    // @ts-ignore
    menu: provided => ({
      ...provided,
      zIndex: '101'
    })
  };

  newStyles = {
    // @ts-ignore
    control: (provided, { isFocused, isSelected }) => ({
      ...provided,
      backgroundColor: 'transparent',
      borderRadius: '4px',
      cursor: 'pointer',
      boxShadow: 'none',
      border: isSelected
        ? `solid 2px ${colors.yellow.primary}`
        : isFocused
        ? `solid 2px ${colors.yellow.primary}`
        : `solid 1px ${deprecatedColors.gray}`,
      '&:hover': {
        backgroundColor: colors.yellow.primary
      },
      padding: '18px'
    }),
    // @ts-ignore
    option: (provided, { isFocused, isSelected }) => ({
      ...provided,
      backgroundColor: isSelected
        ? colors.yellow.primary
        : isFocused
        ? colors.yellow.lighterYellow
        : 'transparent',
      fontSize: '16px',
      borderBottom: `solid 1px ${deprecatedColors.gray}`,
      borderRadius: '4px',
      padding: '18px',
      fontWeight: 'bolder',
      color: colors.neutral.primaryNavy,
      margin: '0'
    }),
    // @ts-ignore
    indicatorSeparator: provided => ({
      ...provided,
      display: 'none'
    }),
    // @ts-ignore
    dropdownIndicator: (provided, { isFocused, isSelected }) => ({
      ...provided,
      color: isSelected ? colors.yellow.primary : isFocused ? colors.yellow.primary : null
    }),
    // @ts-ignore
    valueContainer: provided => ({
      ...provided,
      fontDisplay: 'swap',
      fontSize: '16px',
      fontWeight: 'bolder'
    }),
    // @ts-ignore
    menu: provided => ({
      ...provided,
      position: 'relative',
      zIndex: '101'
    })
  };

  render() {
    const {
      label,
      selectedOption,
      type,
      name,
      isRequired,
      fullMode,
      inputId,
      disableHeightLimit
    } = this.props;
    const value = this.props.extractTitle(selectedOption);

    const options = this.props.options.map(item => {
      const title = this.props.extractTitle(item);

      return {
        label: title,
        value: title,
        original: item
      };
    });

    const renderSelect = () => {
      const props: {
        [key: string]: string;
      } = {
        'aria-labelledby': `${this.props.ariaLabelledby} ${this.props.externalAriaLabelledby}`
      };

      Object.keys(props).forEach(
        (key: string) => isUndefinedOrEmptyString(props[key]) && delete props[key]
      );

      return (
        // @ts-ignore
        <Select
          name={name}
          options={options}
          isDisabled={this.props.disabled}
          styles={this.props.isNew ? this.newStyles : this.customStyles}
          onChange={(item: SelectItem, action: SelectAction) =>
            // @ts-ignore
            this.props.onSelect(item.original, action)
          }
          placeholder={this.props.placeholder}
          className={this.props.className}
          isSearchable={false}
          value={value !== '' ? { label: value, value: value } : null}
          type={type}
          maxMenuHeight={this.props.maxHeight ?? 250}
          inputId={this.props.inputId}
          {...props}
        />
      );
    };

    return (
      <Container fullMode={fullMode} disableHeightLimit={disableHeightLimit ?? false}>
        <Label id={this.props.ariaLabelledby} visible={true} htmlFor={inputId}>
          {label}
        </Label>
        {isRequired && <RequiredIndicatorLabel>{'*'}</RequiredIndicatorLabel>}
        {renderSelect()}
      </Container>
    );
  }
}
