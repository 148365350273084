import React from 'react';

import { Title, Description } from './styled-components';

const ScheduleFDAAppointment = () => (
  <>
    <Title>Schedule FDA Appointment</Title>
    <Description>
      Please call Kindbody to schedule your FDA appointment. At this appointment, you will meet with
      a provider, fill out a questionnaire, have a quick physical exam, and have bloodwork drawn. We
      anticipate this appointment will last for one hour.
    </Description>
  </>
);

export default ScheduleFDAAppointment;
