import React from 'react';
import { useHistory } from 'react-router-dom';

import Modal from 'components/Modal/Modal';
import { Button } from 'components/v2/Buttons/Button';
import { RoutesPath } from 'utilities/pageUrl';

import { ButtonContainer, Container, Text } from './EmailDisclaimerModal.styled';
import { EmailDisclaimerModalProps } from './EmailDisclaimerModal.types';

export const EmailDisclaimerModal = ({ open, onClose }: EmailDisclaimerModalProps) => {
  const history = useHistory();

  if (!open) return null;

  return (
    <Modal label="Account changes" open={open}>
      <Container>
        <Text>
          You will be prompted to reset your password as part of this process. Do you want to
          continue?
        </Text>
        <ButtonContainer>
          <Button label={'Cancel'} category="primary-dark" size="lg" onClick={() => onClose()} />
          <Button
            label={'Continue'}
            category="primary"
            size="lg"
            onClick={() => history.push(RoutesPath.CHANGE_EMAIL)}
          />
        </ButtonContainer>
      </Container>
    </Modal>
  );
};
