import { gql } from '@apollo/client';
//Queries to improve with pdf_link
export const OPEN_PATIENT_CONSENT = gql`
  query openPatientConsent($consentId: Int!) {
    openPatientConsent(consentId: $consentId) {
      id
    }
  }
`;

export const LOAD_CONSENT_HTML = gql`
  query consentHtml($consentId: Int!) {
    consent(id: $consentId) {
      id
      rawHtml
      versionUuid
    }
  }
`;

export const LOAD_CONSENT_AND_SIGNED_CONSENTS = gql`
  query consentAndSignedConsents($consentId: Int!) {
    consent(id: $consentId) {
      id
      rawHtml
      versionUuid
    }
    patientConsents {
      versionUuid
      consent {
        id
      }
    }
  }
`;

export const LOAD_CONSENT = gql`
  query patientConsent($consentId: Int!) {
    patientConsent(id: $consentId) {
      patientPortalHtml
      pdfLink
      versionUuid
      id
      name
      consent {
        comprehensionQuestions {
          consentId
          id
          correctResponse
          explanation
          options
          text
        }
        consentDecisions {
          id
          name
          consentId
          description
          consentOptions {
            consentDecisionId
            description
            id
            name
          }
        }
      }
    }
  }
`;

export const COMPLETE_FORM = gql`
  mutation completePatientConsent($patientConsentId: Int!, $versionUuid: String) {
    completePatientConsent(patientConsentId: $patientConsentId, versionUuid: $versionUuid) {
      patientConsent {
        id
        consentId
        consentedAt
      }
    }
  }
`;
