import React from 'react';

import { ReactComponent as ConsentsCompletedIcon } from 'assets/icons/consents-complete.svg';

import { ConsentCompletedContainer, Explanation } from './ConsentsCompleted.styled';

export const ConsentsCompleted = () => (
  <ConsentCompletedContainer>
    <ConsentsCompletedIcon />
    <Explanation>All of your consents are signed!</Explanation>
    <Explanation>Thank you!</Explanation>
  </ConsentCompletedContainer>
);
