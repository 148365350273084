import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
`;

export const ButtonContainer = styled.div`
  align-items: flex-start;
  justify-content: flex-start;
`;

export const ButtonWrapper = styled.div`
  float: left;
  margin: 12px 12px 12px 12px;
  @media (max-width: 700px) {
    float: none;
    display: initial;
  }
`;
