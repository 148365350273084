import React from 'react';

import { Category, Message } from 'screens/Messages/Messages.type';
import { getMessageOwner } from 'screens/Messages/Messages.utils';

import { Container } from './MessageOwner.styled';

export const MessageOwner = ({
  currentMessage,
  previousMessage,
  currentCategory
}: {
  currentMessage: Message;
  previousMessage?: Message;
  currentCategory: Category;
}) => {
  if (currentMessage.senderId === previousMessage?.senderId) return null;

  const patientLabel = 'You';
  const conversationLabel = getMessageOwner(currentMessage, currentCategory);

  return (
    <Container fromPatient={currentMessage.fromPatient}>
      {currentMessage.fromPatient ? patientLabel : conversationLabel}
    </Container>
  );
};
