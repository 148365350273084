import React, { Component } from 'react';
import ReactLoading from 'react-loading';

import styled from 'styled-components';
import './styles.scss';

const ReactLoadingStyled = styled(ReactLoading)`
  display: inline-block;
`;

const PercentageText = styled.p`
  display: inline-block;
  margin: 0 0 0 5px;
  vertical-align: top;
`;

type Props = {
  className?: string;
  label: string;
  disabled?: boolean;
  loading?: boolean;
  type?: string;
  onClick?: () => void;
  color?: string;
  loadingPercentage?: number | null;
};

export default class Button extends Component<Props> {
  render() {
    const {
      className,
      label,
      disabled,
      loading,
      type,
      onClick,
      color,
      loadingPercentage
    } = this.props;

    return (
      <div className={`button__container ${className || ''}`}>
        <button
          disabled={disabled}
          // @ts-ignore
          type={type}
          className={`button type__button ${disabled ? 'button__disabled' : ''}`}
          onClick={onClick}
          style={color ? { background: color, borderColor: color } : {}}
        >
          {loading ? (
            <>
              <ReactLoadingStyled className="button__loader" type="spin" height={20} width={20} />
              {loadingPercentage && <PercentageText>{`${loadingPercentage}%`}</PercentageText>}
            </>
          ) : (
            label
          )}
        </button>
      </div>
    );
  }
}
