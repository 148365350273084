import React from 'react';

import { ReactComponent as FertilityIcon } from 'assets/icons/fertility.svg';
import { ReactComponent as GynIcon } from 'assets/icons/gyn.svg';
import { ReactComponent as MaternityIcon } from 'assets/icons/maternity.svg';
import { ReactComponent as WellnessIcon } from 'assets/icons/wellness.svg';

export const CATEGORY_ICONS = {
  fertility: <FertilityIcon />,
  gynecology: <GynIcon />,
  wellness: <WellnessIcon />,
  maternity: <MaternityIcon />
};
