import { Heading as HeadingOriginal, Text } from 'components/v2/Typography';
import styled from 'styled-components';

export const Number = styled(Text).attrs({ tag: 'span' })`
  color: ${({ theme }) => theme.colors.neutral.primaryNavy};
`;

export const Heading = styled(HeadingOriginal).attrs({ tag: 'h6', styledAs: 'h6' })`
  color: ${({ theme }) => theme.colors.neutral.lightNavy};
  gap: 8px;
  justify-content: center;
  margin-bottom: 16px;
`;
