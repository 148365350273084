import styled from 'styled-components';

export const OutlineIndent = styled.div`
  padding: 16px 0px 16px 0px;
  @media ${({ theme }) => theme.queries.minTablet} {
    // 16 in the end adds 0.16 opacity to the color provided
    box-shadow: 0px 12px 64px -32px ${({ theme }) => theme.colors.neutral.primaryNavy}16;
    border-radius: 24px;
    padding: 32px;
  }
`;

export const TooltipTrigger = styled.div`
  display: flex;

  > button {
    width: 100%;
  }
`;

export const InvitePartnerButtonContainer = styled.div`
  padding-top: 4px;
  padding-bottom: 4px;
  > button {
    width: 100%;
  }
`;
