import React from 'react';

import { TextField } from 'components/v2/Form';

import { WrapperDiv } from './CalculatorField.styled';
import { Props } from './CalculatorField.types';

export const CalculatorField = ({
  className,
  value,
  onValueChange,
  postfix,
  maxValue,
  id,
  label
}: Props) => (
  <WrapperDiv className={className}>
    <TextField
      id={id}
      label={label}
      type="number"
      onChange={e => {
        const num = Number(e.currentTarget.value);
        if (Number.isNaN(num) || (maxValue && num > maxValue)) return onValueChange(value);

        onValueChange(num);
      }}
      value={`${value}`}
      postfix={postfix ? <span>{postfix}</span> : <></>}
    />
  </WrapperDiv>
);
