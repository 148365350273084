import { Appointment, Clinic, TimeSlot } from 'kb-shared/types';
import { analytics } from 'utilities/analytics';

export const bookingAnalytics = {
  track: {
    partnerClinicSearchStarted: () => {
      analytics.track(analytics.EVENTS.PARTNER_CLINIC_SEARCH_STARTED);
    },

    labSelected: (labId?: string) => {
      analytics.track(analytics.EVENTS.LAB_SELECTED, {
        lab_id: labId
      });
    },

    virtualClinicSelected: () => {
      analytics.track(analytics.EVENTS.CLINIC_SELECTED, {
        clinic_name: 'virtual'
      });
    },

    clinicSelected: (clinic: Clinic | null) => {
      analytics.track(analytics.EVENTS.CLINIC_SELECTED, {
        clinic_name: clinic?.name,
        clinic_id: clinic?.id
      });
    },

    timeSlotSelected: (timeSlot: TimeSlot | null, formattedTime = '') => {
      analytics.track(analytics.EVENTS.TIME_SLOT_SELECTED, {
        time_slot_id: timeSlot?.id,
        time_slot_time: formattedTime || ''
      });
    },

    appointmentBookingSucceeded: (appointment: Appointment) => {
      analytics.track(analytics.EVENTS.APPOINTMENT_BOOKING_SUCCEEDED, {
        appointment_category: appointment.appointmentType?.category?.name,
        appointment_type_id: appointment.appointmentType?.id,
        appointment_type_name: appointment.appointmentType?.name,
        appointment_id: appointment.id,
        lab_id: appointment.location?.lab?.id,
        clinic_id: appointment.location?.id
      });
    }
  },

  page: {
    enterInsuranceInformation: () => {
      analytics.page(analytics.PAGES.ENTER_INSURANCE_INFORMATION);
    },

    confirmPurchase: () => {
      analytics.page(analytics.PAGES.CONFIRM_PURCHASE);
    }
  }
} as const;
