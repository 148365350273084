import styled from 'styled-components';

export const Card = styled.div`
  max-width: 600px;
  padding: 20px;
  box-shadow: 0 5px 12px 0 rgba(0, 0, 0, 0.2);
  background-color: white;
  text-align: center;
  margin: 50px 0 0 0;
  @media screen and (min-width: ${({ theme }) => theme.breakpoints.tabletPortrait}) {
    padding: 30px 80px;
  }
`;

export const Title = styled.div`
  font-size: 24px;
  display: flex;
  flex-direction: column;
  align-self: flex-start;
  margin: 0 0 15px 0;
`;

export const Description = styled.div`
  font-size: 16px;
  margin-bottom: 28px;
`;
