import moment, { Moment } from 'moment';
import { ReactNode } from 'react';

import { getTimeZone } from 'hooks/getTimeZone';
import { JOURNEY_TYPES } from 'screens/MyJourney/MyJourney.types';

import { PatientToDo, Appointment } from '../../types';
import {
  isInitialCycleVisit,
  isCycleVisit,
  isAssessment,
  isRetrieval,
  isFollowUp,
  isEmbryoTransfer,
  isPregnancyTest,
  isIUIProcedure
} from '../appointment.helper';
import { inPast } from '../momentHelpers';
import { stepFactories as obStepFactories } from './obstetrics';

export { obStepFactories };

interface ResultValue {
  date: string;
}

interface Result {
  afc: ResultValue[];
  labs: ResultValue[];
}

export type JourneyType =
  | 'EGG FREEZING'
  | 'DONOR-IVF'
  | 'IVF'
  | 'IUI'
  | 'EMBRYO BANKING'
  | 'TIMED INTERCOURSE'
  | 'EMBRYO THAW'
  | 'MOCK CYCLE'
  | 'OBSTETRICS'
  | 'VIRTUAL FIRST WORKUP';

export type MyJourneyStepType =
  | 'blood-test'
  | 'biopsy'
  | 'during-cycle'
  | 'embryo-transfer'
  | 'fertility'
  | 'follow-up-call'
  | 'follow-up-calls'
  | 'follow-up'
  | 'iui'
  | 'pregnancy-test'
  | 'prep-cycle'
  | 'retrieval'
  | 'review-results'
  | 'progesterone-supplements'
  | 'trigger'
  | 'obstetrics-initial-step'
  | 'obstetrics-timed-step';

export interface MyJourneyStepBase {
  description?: string;
  descriptionForType?: (journeyType: JourneyType) => string;
  getDate: (a: Appointment[], r?: Result) => Moment | null | string;
  isComplete: (a: Appointment[], r?: Result, p?: PatientToDo[]) => boolean;
  showTime?: boolean;
  title: string;
  type: MyJourneyStepType;
}

export interface MyJourneyStep<T = ReactNode> extends MyJourneyStepBase {
  detail?: T;
  icon?: T;
  substeps?: T;
}

export interface Journey {
  name:
    | 'Egg Freezing'
    | 'IVF'
    | 'IUI'
    | 'Embryo Banking'
    | 'TI'
    | 'FET'
    | 'MOCK CYCLE'
    | 'Donor IVF'
    | 'Obstetrics';
  steps: MyJourneyStep[];
}

// HELPERS
const getAppointmentDate = (
  appointments: Appointment[],
  filterFunction: (appt: Appointment) => void
): Moment | null | string => {
  if (!appointments) return null;
  const appt = appointments.find(appt => filterFunction(appt));
  if (!appt) return null;

  const { formattedTimeZone } = getTimeZone(appt.timeZone, appt.appointmentType);

  return moment.tz(appt.startTime, formattedTimeZone || '');
};

const appointmentDatePassed = (
  appointments: Appointment[],
  filterFunction: (appt: Appointment) => void
): boolean => {
  const date = getAppointmentDate(appointments, filterFunction);
  if (!date) return false;
  return inPast(date);
};

const getFirstCycleVisitDate = (appointments: Appointment[]): Moment | null | string => {
  if (!appointments) {
    return null;
  }
  let initialCycleVisitDate = getAppointmentDate(appointments, isInitialCycleVisit);

  if (!initialCycleVisitDate) {
    // this is because some older patients cycled before we had an "initial cycle visit" type
    const firstCycleVisit = appointments.filter((appt: Appointment) => isCycleVisit(appt))[0];
    if (firstCycleVisit) {
      const { formattedTimeZone } = getTimeZone(
        firstCycleVisit.timeZone,
        firstCycleVisit.appointmentType
      );

      initialCycleVisitDate = moment.tz(firstCycleVisit.startTime, formattedTimeZone || '');
    }
  }

  return initialCycleVisitDate;
};

const getCycleProcedureDate = (appointments: Appointment[]): Moment | null => {
  const retrievalDate = getAppointmentDate(appointments, isRetrieval) as Moment | null;
  const embryoTransferDate = getAppointmentDate(appointments, isEmbryoTransfer) as Moment | null;
  const iuiProcedureDate = getAppointmentDate(appointments, isIUIProcedure) as Moment | null;
  return retrievalDate || embryoTransferDate || iuiProcedureDate;
};

export const fertilityAssessmentBase: MyJourneyStepBase = {
  title: 'Full fertility assessment',
  type: 'fertility',
  getDate: appointments => getAppointmentDate(appointments, isAssessment),
  isComplete: appointments => appointmentDatePassed(appointments, isAssessment)
};

export const resultsBase: MyJourneyStepBase = {
  title: 'Review results',
  type: 'review-results',
  getDate: (appointments, results?) => {
    if (!results) {
      return null;
    }
    const allResults = results.afc.concat(results.labs);
    const fertilityAssessmentDate = getAppointmentDate(appointments, isAssessment);
    if (allResults.length === 0 || !fertilityAssessmentDate) {
      return null;
    }
    if (typeof fertilityAssessmentDate === 'string') return null;
    const resultsAfterAssessment = allResults.filter(result =>
      moment(result.date, 'YYYY-MM-DD').isSameOrAfter(fertilityAssessmentDate.subtract(1, 'day'))
    );
    const firstResultAfterAssessment =
      resultsAfterAssessment.length > 0 &&
      resultsAfterAssessment.reduce((i, j) => (i.date < j.date ? i : j));
    const date = firstResultAfterAssessment && firstResultAfterAssessment.date;
    if (!date) return null;
    return moment(date, 'YYYY-MM-DD');
  },
  isComplete: (appointments, results?) => {
    if (!results) return false;
    return results.afc.concat(results.labs).length > 0;
  }
};

export const preparingForCycleBase: MyJourneyStepBase = {
  title: 'Preparing for your cycle',
  type: 'prep-cycle',
  descriptionForType: (journeyType: JourneyType) => {
    if (journeyType === JOURNEY_TYPES.IUI || journeyType === JOURNEY_TYPES.TIMED_INTERCOURSE) {
      return 'This stage begins when you get your period. Notify us when this happens. We’ll do blood work + an ultrasound to establish a baseline. You’ll also learn how to administer the injections if your doctor has prescribed them.';
    } else if (journeyType === JOURNEY_TYPES.EMBRYO_THAW) {
      return 'This stage begins once you have had a consultation about proceeding with a Frozen Embryo Transfer with your care team.';
    } else if (journeyType === JOURNEY_TYPES.MOCK_CYCLE) {
      return 'This stage begins once you have had a consultation about proceeding with a Mock Cycle with your care team.';
    }
    return 'This stage begins when you get your period. Notify us when this happens. We’ll do blood work + an ultrasound to establish a baseline and get you started on an oral contraceptive. You’ll also learn how to administer the injections for the stimulation process.';
  },
  getDate: (appointments, resultsData?) => {
    const startDate = resultsBase.getDate(appointments, resultsData);
    if (!appointments || !startDate) {
      return null;
    }
    const endDate = getFirstCycleVisitDate(appointments);
    if (!endDate) return 'Current';
    return `${moment(startDate).format('M/D')} - ${moment(endDate, 'YYYY-MM-DD').format('M/D')}`;
  },
  isComplete: (appointments, _?, patientTodos?) => {
    const initialCycleVisitDate = getFirstCycleVisitDate(appointments);
    if (typeof initialCycleVisitDate === 'string') return false;
    const initialCycleVisitTodayOrPast =
      initialCycleVisitDate && initialCycleVisitDate.isSameOrBefore(moment());
    const allTodosCompleted =
      patientTodos && patientTodos.filter(todo => !todo.completed).length === 0;
    if (allTodosCompleted || initialCycleVisitTodayOrPast) return true;
    return false;
  }
};

export const duringCycleBase: MyJourneyStepBase = {
  title: 'During your cycle',
  type: 'during-cycle',
  getDate: appointments => {
    if (!appointments) {
      return null;
    }
    const startDate = getFirstCycleVisitDate(appointments) as Moment | null;
    const cycleProcedureDate = getCycleProcedureDate(appointments) as Moment | null;
    let endDate = cycleProcedureDate;
    const retrievalDate = getAppointmentDate(appointments, isRetrieval) as Moment | null;
    if (retrievalDate) {
      // the trigger shot (next step) must be administered precisely 34.5 hours before the retrieval appointment
      endDate = retrievalDate.subtract(34.5, 'hours');
    }
    if (startDate && endDate && endDate.isSameOrBefore(moment())) {
      // completed
      return `${moment(startDate).format('M/D')} - ${moment(endDate).format('M/D')}`;
    } else if (startDate && startDate.isSameOrBefore(moment(), 'day')) {
      // current
      return 'Current';
    } else if (startDate) {
      // upcoming
      return moment(startDate);
    }
    return null;
  },
  isComplete: appointments => {
    const cycleVisits = appointments.filter(appt => isCycleVisit(appt));
    if (cycleVisits.length < 1) return false;
    const procedureDate = getCycleProcedureDate(appointments);
    if (!procedureDate) return false;
    return cycleVisits.every(appt => inPast(appt.startTime)) || inPast(procedureDate);
  }
};

export const triggerBase: MyJourneyStepBase = {
  title: 'Trigger injection',
  type: 'trigger',
  getDate: () => null,
  isComplete: appointments => {
    const retrievalDate = getAppointmentDate(appointments, isRetrieval) as Moment | null;
    if (!retrievalDate) return false;
    const triggerDate = retrievalDate.subtract(34.5, 'hours');
    return inPast(triggerDate);
  }
};

export const retrievalBase: MyJourneyStepBase = {
  title: 'Retrieval',
  type: 'retrieval',
  getDate: appointments => {
    const retrievalDate = getAppointmentDate(appointments, isRetrieval);
    if (retrievalDate) {
      return retrievalDate;
    }
    return null;
  },
  isComplete: appointments => appointmentDatePassed(appointments, isRetrieval),
  showTime: true
};

export const followUpCallBase: MyJourneyStepBase = {
  title: 'Follow-up call',
  type: 'follow-up-call',
  getDate: appointments => getAppointmentDate(appointments, isFollowUp),
  isComplete: appointments => appointmentDatePassed(appointments, isFollowUp)
};

export const embryoTransferBase: MyJourneyStepBase = {
  title: 'Embryo transfer',
  type: 'embryo-transfer',
  getDate: appointments => getAppointmentDate(appointments, isEmbryoTransfer),
  isComplete: appointments => appointmentDatePassed(appointments, isEmbryoTransfer)
};

export const pregnancyTestBase: MyJourneyStepBase = {
  title: 'Pregnancy test',
  type: 'pregnancy-test',
  getDate: appointments => getAppointmentDate(appointments, isPregnancyTest),
  isComplete: appointments => appointmentDatePassed(appointments, isPregnancyTest)
};

export const followUpGenericBase: MyJourneyStepBase = {
  title: 'Follow up',
  type: 'follow-up',
  getDate: () => null, // no date
  isComplete: () => false // considered complete when attempt closed
};

export const followUpEmbryoBase: MyJourneyStepBase = {
  title: 'Follow up calls',
  type: 'follow-up-calls',
  getDate: appointments => getAppointmentDate(appointments, isFollowUp),
  isComplete: appointments => appointmentDatePassed(appointments, isFollowUp)
};

export const iuiProcedureBase: MyJourneyStepBase = {
  title: 'IUI procedure',
  type: 'iui',
  getDate: appointments => getAppointmentDate(appointments, isIUIProcedure),
  isComplete: appointments => appointmentDatePassed(appointments, isIUIProcedure)
};

export const startProgesteroneSupplementsBase: MyJourneyStepBase = {
  title: 'Start progesterone supplements',
  type: 'progesterone-supplements',
  getDate: appointments => {
    const transferDate = getAppointmentDate(appointments, isEmbryoTransfer) as Moment | null;
    return transferDate && transferDate.subtract(5, 'days');
  },
  isComplete: appointments => {
    const transferDate = getAppointmentDate(appointments, isEmbryoTransfer) as Moment | null;
    if (!transferDate) return false;
    const triggerDate = transferDate.subtract(5, 'hours');
    return inPast(triggerDate);
  }
};

export const biopsyBase: MyJourneyStepBase = {
  title: 'Biopsy',
  type: 'biopsy',
  getDate: () => null,
  isComplete: () => false
};
