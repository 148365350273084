import { gql } from '@apollo/client';

export const dateTimeSlotsQuery = gql`
  query dateTimeSlots(
    $appointmentTypeId: Int!
    $locationId: Int
    $startDateTime: String!
    $endDateOffsetInMonths: Int
    $labId: Int
    $providerId: Int
    $appointmentId: Int
  ) {
    dateTimeSlots(
      appointmentTypeId: $appointmentTypeId
      locationId: $locationId
      startDateTime: $startDateTime
      endDateOffsetInMonths: $endDateOffsetInMonths
      labId: $labId
      providerId: $providerId
      appointmentId: $appointmentId
    ) {
      id
      startTime
      endTime
      timeZone
      videoLink
      licensedProviderName
      lab {
        id
      }
    }
  }
`;
