import * as React from 'react';

import { ReactComponent as ProfileImageSVG } from 'assets/icons/placeholder-profile.svg';
import { ProfileImageError } from 'kb-shared';
import styled from 'styled-components';

import { imageSize } from '../../lib/common';

interface ImageProps {
  size?: number;
}

// we are importing svg placeholder as a component intentionally
// that way we prevent delay in loading it on my profile and left navigation
// it was fixed in KE-11998
const Image = styled(ProfileImageSVG)`
  ${(props: ImageProps) => imageSize(props.size)}
`;

const UserImage = styled.img`
  ${(props: ImageProps) => imageSize(props.size)}
  border-radius: 50%;
`;

type Props = {
  profileImage?: string;
  error: ProfileImageError | null;
  size?: number;
  onError: () => void;
};

export const ProfileImage = (props: Props) => {
  const { profileImage, onError, size, error } = props;

  if (!profileImage || error) return <Image size={size} />;

  return <UserImage src={profileImage} alt="Profile photo" onError={onError} size={size} />;
};
