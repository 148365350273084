import Honeybadger from '@honeybadger-io/js';
import { useState } from 'react';

import { graphql } from 'kb-shared';

import Patient from '../types/patient';
import RecognizePatientResultCode from '../types/recognize-patient-result-code';

const { client, mutate } = graphql;
const { RECOGNIZE_PATIENT_FROM_MEDTRONIC_SSO } = mutate;

const useRecognizePatientOnBackend = () => {
  const [responseState, setResponseState] = useState<ResponseStateType>({
    patient: null,
    finished: false,
    errors: null,
    resultCode: null
  });
  const recognizePatientOnBackend = async (idtoken: string): Promise<any> => {
    try {
      // make sure idtoken is NOT set in the header here, because that will cause egg-chicken problem:
      // 1. backend is about to set current_user from idtoken from header
      // 2. but the relationship between idtoken.sub (person's UUID in MS Azure world) and patient is not established yet for
      // a person that is using medtronic sso for the very first time
      const { data } = await client.mutate({
        mutation: RECOGNIZE_PATIENT_FROM_MEDTRONIC_SSO,
        variables: {
          medtronicIdtoken: idtoken
        }
      });
      setResponseState({
        patient: data.recognizePatientFromMedtronicSso.patient,
        finished: true,
        errors: data.recognizePatientFromMedtronicSso.errors,
        resultCode: data.recognizePatientFromMedtronicSso.resultCode
      });
    } catch (error) {
      Honeybadger.notify(
        error as Error,
        'Medtronic SSO: recognizePatientOnBackend mutation failed'
      );
      setResponseState({
        patient: null,
        finished: true,
        errors: [`Unexpected error: ${error}`],
        resultCode: RecognizePatientResultCode.Failed
      });
    }
  };

  return { responseState, recognizePatientOnBackend };
};

type ResponseStateType = {
  patient: Patient | null;
  finished: boolean;
  errors: string[] | null;
  resultCode: RecognizePatientResultCode | null;
};

export default useRecognizePatientOnBackend;
