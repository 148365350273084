import React from 'react';

import { themes } from 'kb-shared';
interface Props {
  fill?: string;
}

const ArrowDown = ({ fill = themes.colors.neutral.primaryNavy }: Props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="48" height="40" viewBox="0 0 48 40">
      <path
        fill={fill}
        fillRule="evenodd"
        d="M12 0h24v17a1 1 0 0 0 1 1h5.858a2 2 0 0 1 1.352 3.474L25.35 38.761a2 2 0 0 1-2.702 0L3.79 21.474A2 2 0 0 1 5.142 18H11a1 1 0 0 0 1-1V0z"
      />
    </svg>
  );
};

export default ArrowDown;
