import React, { useEffect } from 'react';

import { NotificationsContainer } from 'utilities/notificationUtils';

import SegmentAnalytics from './config/SegmentAnalytics';
import GlobalStyle from './GlobalStyle';
import { Router } from './Router';
import { checkAndSetConsent } from './utilities/consent';

const App = () => {
  useEffect(() => {
    checkAndSetConsent();
  }, []);

  return (
    <>
      <SegmentAnalytics />
      <NotificationsContainer />
      <GlobalStyle />
      <Router />
    </>
  );
};

export default App;
