import styled from 'styled-components';

const Row = styled.div`
  ${({ theme }) => theme.grid.row};
`;

const Column = styled.div<{ mobile: number; tablet?: number; desktop?: number }>`
  ${({ theme, mobile, tablet, desktop }) => theme.grid.column({ mobile, tablet, desktop })};
`;

export { Row, Column };
