import React, { Component, MouseEvent } from 'react';

import { themes } from 'kb-shared';
import styled from 'styled-components';

type Props = {
  text: string;
  onClick: (event: MouseEvent) => void;
};

type State = {};

export default class FormButton extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <Container onClick={this.props.onClick}>
        <Text>{this.props.text}</Text>
      </Container>
    );
  }
}

const Container = styled.button`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 55px;
  width: 247px;
  border-radius: 23.5px;
  border-width: 0px;
  background-color: #fff;
  box-shadow: 0 2px 8px 0 #d8d8d8;
  :focus {
    outline: 0;
  }
  :active {
    background-color: ${themes.colors.yellow.primary};
  }
`;

const Text = styled.div`
  font-size: 14px;
`;
