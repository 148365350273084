import React from 'react';

export const AdditionalInfoVirtual = (): JSX.Element => {
  return (
    <>
      <strong>
        If you would like to book with a specific provider, you can call us at{' '}
        <a href="tel://855-563-2639">855-563-2639</a> and a Patient Care Navigator will happily
        assist you with scheduling.
      </strong>
      <br />
      For services that require you to be seen in clinic, such as fertility care, the provider you
      meet with virtually may differ from your treating provider.
      <p>
        In order to provide you with the earliest availability for your appointment, the appointment
        times listed include all providers for this location.
      </p>
    </>
  );
};
