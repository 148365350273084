import React from 'react';

import { ZipInput } from '../../Inputs/ZipInput';
import { FormControl, getFormControlIds } from '../FormControl';
import { Props } from './ZipField.types';

export const ZipField = ({
  status = 'default',
  label,
  id,
  required,
  helperText,
  description,
  placeholder,
  spellCheck,
  autoComplete,
  value,
  onChange,
  onBlur,
  onKeyPress,
  onFocus,
  zipType,
  labelBg
}: Props) => {
  const ids = getFormControlIds(id);

  return (
    <FormControl
      status={status}
      label={label}
      required={required}
      helperText={helperText}
      description={description}
      labelId={ids.label}
      inputId={ids.input}
      helperTextId={ids.helperText}
      labelBg={labelBg}
    >
      <ZipInput
        id={ids.input}
        zipType={zipType}
        helperTextId={ids.helperText}
        labelId={ids.label}
        status={status}
        placeholder={placeholder}
        spellCheck={spellCheck}
        autoComplete={autoComplete}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        onKeyPress={onKeyPress}
        onFocus={onFocus}
      />
    </FormControl>
  );
};
