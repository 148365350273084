import styled from 'styled-components';

export const AppointmentDetailsContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  @media ${({ theme }) => theme.queries.minTablet} {
    padding: 24px;

    border-radius: 8px;
    background-color: ${({ theme }) => theme.colors.neutral.lavenderWhite};
    box-shadow: ${({ theme }) => theme.shadows.card.level0};
  }
`;

export const AppointmentDetailsButton = styled.div`
  width: 100%;
  margin-top: 16px;

  @media ${({ theme }) => theme.queries.minTablet} {
    margin-top: 24px;
  }
`;

export const AppointmentDetailsWhenWhoContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  width: 100%;
  padding-bottom: 16px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.neutral.lavender};
`;

export const AppointmentDetailsWhen = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

export const AppointmentDetailsWho = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const AppointmentDetails = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
  padding: 16px 0;
  border-bottom: 1px solid ${({ theme }) => theme.colors.neutral.lavender};
`;

export const AppointmentDetailsLocationContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-item: flex-start;

  @media ${({ theme }) => theme.queries.minTablet} {
    flex-direction: row;
    align-item: flex-start;
    justify-content: space-between;

    padding: 16px 0px;
  }
`;

export const AppointmentDetailsLocation = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  padding: 16px 0;

  @media ${({ theme }) => theme.queries.minTablet} {
    padding: 0;
  }
`;

export const AppointmentDetailsLocationActionButtons = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: 8px;
`;

export const AppointmentDetailsResponsiveText = styled.div`
  display: flex;
  align-item: center;
  gap: 8px;
`;

export const AppointmentDetailsDisclaimerContainer = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 8px;
  padding: 8px 0;
`;

export const AppointmentDetailsDisclaimerText = styled.div`
  display: flex;
  align-items: flex-start;
`;

export const AppointmentDetailsDisclaimerIcon = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;
