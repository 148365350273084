import { StripeContext } from 'components/CreditCardForm/types/creditCardForm';
import { BugTracker } from 'kb-shared/utilities/bugTracker';
import { showErrorToast } from 'utilities/notificationUtils';

export const createStripeTokenId = async (
  stripe: StripeContext['stripe'],
  cardNumberElement: StripeContext['cardNumberElement']
) => {
  const tokenResponse = await stripe.createToken(cardNumberElement);
  if (tokenResponse.error?.message) {
    const errorMessage = tokenResponse.error.message;

    handleError(new Error(errorMessage), 'Error creating new card from details provided.');

    throw new Error(tokenResponse.error.message);
  }

  return tokenResponse.token?.id ?? '';
};

export const handleError = (error: Error, context: string) => {
  BugTracker.notify(context, error);
  showErrorToast(context + '. Please try again later.');
};
