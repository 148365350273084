import breakpointsRaw from './breakpointsRaw';

const queries = Object.freeze({
  mobile: `(max-width: ${breakpointsRaw.md - 0.02}px)`,
  minTablet: `(min-width: ${breakpointsRaw.md}px)`,
  tablet: `(min-width: ${breakpointsRaw.md}px) and (max-width: ${breakpointsRaw.lg - 0.02}px)`,
  maxTablet: `(max-width: ${breakpointsRaw.lg - 0.02}px)`,
  minDesktop: `(min-width: ${breakpointsRaw.lg}px)`,
  minLargeDesktop: `(min-width: ${breakpointsRaw.xl}px)`
});

export default queries;
