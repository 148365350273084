import { gql } from '@apollo/client';

export const PATIENT_FORM_QUERY = gql`
  query patientForm($formId: Int!) {
    patientForm(formId: $formId) {
      id
      title
      formSections {
        formFields {
          id
          title
          position
        }
      }
    }
  }
`;

export interface PatientFormQueryArguments {
  formId: number;
}

export interface PatientFormQueryResponse {
  patientForm: {
    title: string;
    formSections: {
      formFields: {
        id: string;
        title: string;
        position: number;
      }[];
    }[];
  };
}
