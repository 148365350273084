import React, { useState } from 'react';

import { InvitePartnerFormProps } from '../../InterstitialModal.types';
import { InvitePartner } from './InvitePartner';
import { PartnerList } from './PartnerList';

export const InvitePartnerForm = ({ invites, refetch }: InvitePartnerFormProps) => {
  const [invitePartner, setInvitePartner] = useState<boolean>(false);

  if (!invitePartner) {
    return <PartnerList invites={invites} onAddPartner={() => setInvitePartner(true)} />;
  }

  return (
    <InvitePartner
      onDone={() => {
        setInvitePartner(false);
        refetch();
      }}
    />
  );
};
