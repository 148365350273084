import { gql } from '@apollo/client';

export const ACKNOWLEDGE_DAILY_INSTRUCTION = gql`
  mutation acknowledgeDailyInstruction($dailyInstructionId: Int!, $acknowledged: Boolean!) {
    acknowledgeDailyInstruction(
      dailyInstructionId: $dailyInstructionId
      acknowledged: $acknowledged
    ) {
      dailyInstruction {
        acknowledged
        id
      }
    }
  }
`;
