import { css } from 'styled-components';

import queries from './queries';

const smallGutter = 16;
const bigGutter = 24;

const container = css`
  padding-right: ${smallGutter}px;
  padding-left: ${smallGutter}px;
  width: 100%;

  @media ${({ theme }) => theme.queries.minDesktop} {
    padding-right: ${bigGutter}px;
    padding-left: ${bigGutter}px;
  }
`;

/**
 * We need to deprecate this container.
 * It doesn't work ok on resolutions between mobile and desktop.
 */
const pageContainer = css`
  ${container};
  margin: 0 auto;
  min-width: 320px;
  @media ${({ theme }) => theme.queries.minTablet} {
    max-width: min(1172px, 100vw - 32px);
  }
  @media ${({ theme }) => theme.queries.minDesktop} {
    max-width: min(1172px, 100vw - 172px);
  }
`;

const stretchedPageContainerCss = css`
  ${container};
  margin: 0 auto;
  min-width: 320px;
  @media ${({ theme }) => theme.queries.minTablet} {
    max-width: min(1172px, 100vw);
  }
`;

const row = css`
  display: flex;
  flex-wrap: wrap;
  margin: 0 ${-smallGutter / 2}px;

  @media ${({ theme }) => theme.queries.minDesktop} {
    margin: 0 ${-bigGutter / 2}px;
  }
`;

type Breakpoints = {
  mobile: number;
  tablet?: number;
  desktop?: number;
};

const numberOfColumn = 12;

const calculateWidth = (breakpoints: Breakpoints) => {
  const { mobile, tablet, desktop } = breakpoints;
  return css`
    flex-basis: ${(mobile / numberOfColumn) * 100}%;

    ${tablet &&
      css`
        @media ${queries.tablet} {
          flex-basis: ${(tablet / numberOfColumn) * 100}%;
        }
      `}

    ${desktop &&
      css`
        @media ${queries.minDesktop} {
          flex-basis: ${(desktop / numberOfColumn) * 100}%;
        }
      `}
  `;
};

const column = (breakpoints: Breakpoints) => css`
  ${calculateWidth(breakpoints)};
  padding: 0 ${smallGutter / 2}px;
  margin-bottom: 30px;

  @media ${({ theme }) => theme.queries.minDesktop} {
    padding: 0 ${bigGutter / 2}px;
  }
`;

export default {
  container,
  pageContainer,
  stretchedPageContainerCss,
  row,
  column,
  smallGutter,
  bigGutter
};
