import React from 'react';

const ArrowRight = () => {
  return (
    <svg
      width="8px"
      height="13px"
      viewBox="0 0 8 13"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>Arrow pointed to the right</title>
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g transform="translate(-399.000000, -551.000000)" stroke="#343754">
          <g transform="translate(97.000000, 534.000000)">
            <polyline
              transform="translate(306.000000, 24.000000) scale(-1, 1) rotate(-180.000000) translate(-306.000000, -24.000000) "
              points="303 18 309 24.1570625 303 30"
            />
          </g>
        </g>
      </g>
    </svg>
  );
};

export default ArrowRight;
