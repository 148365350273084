import React, { Component } from 'react';

import { FormField, FieldGroup, Answer } from 'kb-shared';
import styled from 'styled-components';

import AnimatedWrapper from '../../AnimatedWrapper';
import FormButton from '../../FormButton';
import Text from '../../Primitives/FormText';
import FieldGroupMultiSelection from './FieldGroupMultiSelection';

type Props = {
  title: string;
  fieldGroup: FieldGroup;
  onSubmit: (response: Array<string>, id: string) => void;
  savedAnswers: {
    [id: string]: Answer;
  };
  onContinue: Function;
};

type State = {
  expandedFields: {
    [id: string]: boolean;
  };
  selectedOptions: {
    [id: string]: Array<string>;
  };
};

export default class FieldGroupMultipleSelectionForm extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    const selectedOptions = props.fieldGroup.formFields.reduce((map, field: FormField) => {
      const answerForField: Answer | null = props.savedAnswers[field.id];
      if (answerForField) {
        // @ts-ignore
        map[field.id] = answerForField.dataArray || [];
      } else {
        // @ts-ignore
        map[field.id] = [];
      }
      return map;
    }, {});
    this.state = {
      expandedFields: {},
      selectedOptions: selectedOptions
    };
  }

  expandField(index: number) {
    const updatedFields = { ...this.state.expandedFields };
    if (this.state.expandedFields[index]) {
      delete updatedFields[index];
    } else {
      updatedFields[index] = true;
    }
    this.setState({
      expandedFields: updatedFields
    });
  }

  continuePressed() {
    // If user presses continue, check each field to see if they did not respond.
    // If they havent responded to a specific field, submit a blank answer
    this.props.fieldGroup.formFields.forEach((field: FormField) => {
      const answersForField: Array<string> = this.state.selectedOptions[field.id];
      if (answersForField.length === 0) {
        // Submit blank array if no answers provided
        this.props.onSubmit([], field.id);
      }
    });
    this.props.onContinue();
  }

  noneOfTheAbovePressed = () => {
    // Clears the state of any selected options and submits an empty array for each selection.
    const clearedOptions = {};
    Object.keys(this.state.selectedOptions).forEach(key => {
      // @ts-ignore
      clearedOptions[key] = [];
    });
    this.setState({ selectedOptions: clearedOptions, expandedFields: {} }, () => {
      this.props.fieldGroup.formFields.forEach((field: FormField) => {
        this.props.onSubmit([], field.id);
      });
    });
    this.props.onContinue();
  };

  hasSelectedAtLeastOneOption = () => {
    let hasSelectedAnOption = false;
    Object.keys(this.state.selectedOptions).forEach(key => {
      if (this.state.selectedOptions[key].length > 0) {
        hasSelectedAnOption = true;
      }
    });
    return hasSelectedAnOption;
  };

  renderContinueButton() {
    return (
      <ContinueButtonWrapper>
        <AnimatedWrapper isHidden={false}>
          <FormButton text={'Continue'} onClick={this.continuePressed.bind(this)} />
        </AnimatedWrapper>
      </ContinueButtonWrapper>
    );
  }

  renderNoneOfTheAboveButton = () => {
    return (
      <ContinueButtonWrapper>
        <AnimatedWrapper isHidden={false}>
          <FormButton text={'None of the above'} onClick={this.noneOfTheAbovePressed} />
        </AnimatedWrapper>
      </ContinueButtonWrapper>
    );
  };

  renderButtons = () => {
    if (this.hasSelectedAtLeastOneOption()) {
      return (
        <>
          {this.renderContinueButton()}
          {this.renderNoneOfTheAboveButton()}
        </>
      );
    } else {
      return this.renderNoneOfTheAboveButton();
    }
  };

  renderOptions() {
    return this.props.fieldGroup.formFields.map((field: FormField, index: number) => {
      const expanded = this.state.expandedFields[index];
      return (
        <GroupWrapper key={field.id}>
          <FieldGroupMultiSelection
            expanded={expanded}
            onExpandClicked={this.expandField.bind(this, index)}
            title={field.title}
            isRequired={field.required}
            options={field.options}
            selectedOptions={this.state.selectedOptions[field.id] || []}
            onSelect={(responses: Array<string>) => {
              this.setState({
                selectedOptions: {
                  ...this.state.selectedOptions,
                  [field.id]: responses
                }
              });
              this.props.onSubmit(responses, field.id);
            }}
          />
        </GroupWrapper>
      );
    });
  }

  render() {
    return (
      <Container>
        <Text>{this.props.title}</Text>
        <OptionsContainer>{this.renderOptions()}</OptionsContainer>
        <ButtonContainer>{this.renderButtons()}</ButtonContainer>
      </Container>
    );
  }
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
`;

const OptionsContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const GroupWrapper = styled.div`
  margin-bottom: 12px;
`;

const ContinueButtonWrapper = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  margin-right: 34px;
`;

const ButtonContainer = styled.div`
  display: flex;
`;
