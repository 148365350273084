import React, { FormEvent, useState } from 'react';

import { Button } from 'components/v2/Buttons/Button';
import { DateField, PhoneFieldBasic, SelectField, TextField, ZipField } from 'components/v2/Form';
import { Option } from 'components/v2/Inputs/SelectInput';
import { GENDER_INPUT_DEFAULTS } from 'kb-shared/utilities/input_defaults.helper';
import { Row } from 'screens/Book/steps/Insurance/Insurance.styled';
import { TwoColumnContent } from 'screens/styled_common';

import { ButtonWrap } from './InsuranceForm.styled';
import { RelationshipOption } from './InsuranceForm.types';
import { RELATIONSHIP_TO_INSURED } from './InsuranceForm.utils';
import { InsuranceFormNonSelfState, Props } from './InsuranceFormNonSelf.types';

type SexAtBirthOption = 'male' | 'female';

const getDefaultSexAtBirthOption = (gender?: SexAtBirthOption) => {
  if (gender) {
    return GENDER_INPUT_DEFAULTS.find(genderOption => genderOption.value === gender);
  }

  return undefined;
};

export const InsuranceFormNonSelf = ({
  insuranceProvider,
  disableSubmit,
  payerCompanies,
  relationshipToInsured,
  backgroundColor,
  setRelationshipToInsured,
  onSave,
  onCancel
}: Props) => {
  const [formState, setFormState] = useState<InsuranceFormNonSelfState>({
    groupNumber: insuranceProvider?.groupNumber ?? '',
    firstName: insuranceProvider?.insuredFirstName ?? '',
    lastName: insuranceProvider?.insuredLastName ?? '',
    payerCompany: {
      id: insuranceProvider?.payerCompanyId ?? '',
      name: insuranceProvider?.derivedPayerCompanyName ?? ''
    },
    memberId: insuranceProvider?.memberId ?? '',
    address: insuranceProvider?.insuredAddress ?? '',
    city: insuranceProvider?.insuredCity ?? '',
    state: insuranceProvider?.insuredState ?? '',
    zipCode: insuranceProvider?.insuredZipcode ?? '',
    phoneNumber: insuranceProvider?.insuredPhoneNumber ?? '',
    dob: insuranceProvider?.insuredDob ?? '',
    sexAtBirth: getDefaultSexAtBirthOption(insuranceProvider?.insuredGender as SexAtBirthOption)
  });
  const isFormEditMode = Boolean(insuranceProvider?.id);
  const {
    groupNumber,
    firstName,
    lastName,
    payerCompany,
    memberId,
    sexAtBirth,
    address,
    city,
    state,
    zipCode,
    phoneNumber,
    dob
  } = formState;
  const isFormValid = Boolean(
    firstName &&
      lastName &&
      payerCompany.id &&
      memberId &&
      address &&
      city &&
      state &&
      zipCode &&
      dob &&
      phoneNumber &&
      (!isFormEditMode ? !!sexAtBirth : true)
  );

  const updateFormState = (key: string, event: FormEvent<HTMLInputElement>) => {
    const value = event?.currentTarget?.value;
    setFormState({ ...formState, [key]: value });
  };

  const updateDob = (date: Date | null) => {
    setFormState({ ...formState, dob: date?.toISOString() ?? '' });
  };

  const updateSexAtBirth = (sexAtBirth: typeof GENDER_INPUT_DEFAULTS[number]) => {
    setFormState({ ...formState, sexAtBirth });
  };

  const updatePayerCompany = (option: Option) => {
    setFormState({
      ...formState,
      payerCompany: {
        id: option.value,
        name: option.label
      }
    });
  };

  const updateMemberId = (memberId: string) => {
    const scrubbedMemberId = memberId.replace(/\s+/g, '');
    setFormState({ ...formState, memberId: scrubbedMemberId });
  };

  const payerCompaniesOptions = payerCompanies.map(payer => ({
    label: payer.name,
    value: payer.id.toString()
  }));

  const selectedPayerCompanyOption = {
    label: formState.payerCompany.name,
    value: formState.payerCompany.id
  };

  return (
    <>
      <TwoColumnContent>
        <SelectField
          id="input-relationship-to-insured"
          label="RELATIONSHIP TO INSURED"
          selected={relationshipToInsured}
          placeholder={'Chose your relationship to the pearson insured'}
          onChange={item => setRelationshipToInsured(item as RelationshipOption)}
          options={RELATIONSHIP_TO_INSURED}
          labelBg={backgroundColor}
          required
        />
        <TextField
          id="input-group-number"
          type="text"
          placeholder="Enter group number"
          label="Group number (optional)"
          value={groupNumber}
          onChange={event => updateFormState('groupNumber', event)}
          labelBg={backgroundColor}
        />
      </TwoColumnContent>

      <Row>
        <TwoColumnContent>
          <TextField
            id="input-insured-first-name"
            type="text"
            placeholder="Enter insured first name"
            label="Insured first name"
            description="Please provide name on the insurance card"
            required
            value={firstName}
            onChange={event => updateFormState('firstName', event)}
            labelBg={backgroundColor}
          />

          <TextField
            type="text"
            id="input-insured-last-name"
            placeholder="Enter insured last name"
            description="Please provide name on the insurance card"
            label="Insured last name"
            required
            value={lastName}
            onChange={event => updateFormState('lastName', event)}
            labelBg={backgroundColor}
          />
        </TwoColumnContent>
      </Row>

      <SelectField
        id="insurance-form-nonself-payer"
        searchable
        label="Payer"
        placeholder="Enter payer"
        searchPlaceholder="Search for a payer"
        required
        options={payerCompaniesOptions}
        selected={selectedPayerCompanyOption}
        onChange={updatePayerCompany}
        description="(If you can’t find your payer, type in and select “Unknown Payer” instead)"
        wrapSelectedOptionText
        labelBg={backgroundColor}
      />

      <TwoColumnContent>
        <TextField
          id="input-insured-member-id"
          type="text"
          placeholder="Enter insured member ID"
          label="Insured member ID"
          required
          value={memberId}
          onChange={event => updateMemberId(event.currentTarget.value)}
          labelBg={backgroundColor}
        />

        <SelectField
          id="insured-sex-at-birth"
          label="INSURED SEX AT BIRTH"
          placeholder={'Insured sex at birth'}
          onChange={item => updateSexAtBirth(item as typeof GENDER_INPUT_DEFAULTS[0])}
          options={GENDER_INPUT_DEFAULTS}
          selected={sexAtBirth}
          labelBg={backgroundColor}
          required={!isFormEditMode}
        />

        <DateField
          id="input-birth-date"
          placeholder="Select insured date of birth"
          label="Insured date of birth"
          value={dob ? new Date(dob) : undefined}
          onChange={date => updateDob(date)}
          required
          labelBg={backgroundColor}
        />

        <TextField
          id="input-insured-address"
          type="text"
          placeholder="Enter insured address"
          label="Insured address"
          required
          value={address}
          onChange={event => updateFormState('address', event)}
          labelBg={backgroundColor}
        />
        <TextField
          id="input-insured-city"
          type="text"
          placeholder="Enter insured city"
          label="Insured city"
          required
          value={city}
          onChange={event => updateFormState('city', event)}
          labelBg={backgroundColor}
        />

        <TextField
          type="text"
          id="input-insured-state"
          placeholder="Enter insured state"
          label="Insured state"
          required
          value={state}
          onChange={event => updateFormState('state', event)}
          labelBg={backgroundColor}
        />

        <ZipField
          id="input-insured-zip"
          label="INSURED ZIP"
          required
          placeholder="Enter insured zip"
          autoComplete="postal-code"
          value={zipCode}
          onChange={event => updateFormState('zipCode', event)}
          labelBg={backgroundColor}
        />

        <PhoneFieldBasic
          required
          id="phoneInput"
          label="Insured phone number"
          placeholder="555-555-5555"
          value={phoneNumber}
          onChange={event => updateFormState('phoneNumber', event)}
          status="default"
          country="US"
          labelBg={backgroundColor}
        />
      </TwoColumnContent>

      <ButtonWrap>
        {onCancel && <Button label="Cancel" size="md" category="secondary" onClick={onCancel} />}
        <Button
          label="Save"
          category="primary"
          fullWidth={!onCancel}
          size="md"
          onClick={() => onSave(formState)}
          isDisabled={!isFormValid || disableSubmit}
        />
      </ButtonWrap>
    </>
  );
};
