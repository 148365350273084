import React, { useState } from 'react';

import { Answer, FieldGroup, FormField, themes } from 'kb-shared';
import styled from 'styled-components';
import { useBreakpoints } from 'utilities/useBreakpoints';

import AnimatedWrapper from '../../AnimatedWrapper';
import FormButton from '../../FormButton';
import Text from '../../Primitives/FormText';
import FieldGroupSelection from './FieldGroupSelection';

type Props = {
  title: string;
  fieldGroup: FieldGroup;
  onSubmit: (response: string, id: string) => void;
  onContinue: Function;
  savedAnswers: {
    [id: string]: Answer;
  };
};

const FieldGroupSelectionForm = (props: Props) => {
  const { isMobile } = useBreakpoints();
  const [savedAnswers, setSavedAnswers] = useState(
    props.fieldGroup.formFields.reduce((map, field: FormField) => {
      const answer: Answer | null = props.savedAnswers[field.id];
      if (answer) {
        // @ts-ignore
        map[field.id] = answer.data;
      }
      return map;
    }, {})
  );

  const respondNeverToFields = (fields: Array<FormField>) => {
    const newState = {};
    fields.forEach((field: FormField) => {
      props.onSubmit('Never', field.id);
      // @ts-ignore
      newState[field.id] = 'Never';
    });
    setSavedAnswers({ ...savedAnswers, ...newState });
    props.onContinue();
  };

  const respondNeverToAll = () => {
    respondNeverToFields(props.fieldGroup.formFields);
  };

  const renderSelectNeverToAll = () => {
    return (
      <SelectContainer>
        <SelectAllText onClick={() => respondNeverToAll()}>{'Select "never" to all'}</SelectAllText>
      </SelectContainer>
    );
  };

  const renderContinueButton = () => {
    return (
      <ContinueButtonWrapper>
        <AnimatedWrapper isHidden={false}>
          <FormButton text={'Continue'} onClick={() => props.onContinue()} />
        </AnimatedWrapper>
      </ContinueButtonWrapper>
    );
  };

  const _renderSingleOption = (field: FormField) => {
    // @ts-ignore
    const answerForField: string | null = savedAnswers[field.id];
    return (
      <FieldGroupSelection
        title={field.title}
        options={field.options}
        onSelect={(option: string) => {
          props.onSubmit(option, field.id);
          setSavedAnswers({ ...savedAnswers, [field.id]: option });
        }}
        answer={answerForField}
        key={field.id}
      />
    );
  };

  const renderOptions = () => {
    let optionsToRender: Array<FormField> = [];
    if (isMobile) {
      optionsToRender = [...props.fieldGroup.formFields];
    } else {
      /**
       * Split the fields into two batches, left column and right column
       */
      const fields = [...props.fieldGroup.formFields];
      const leftNumFields = Math.ceil(fields.length / 2);
      const leftOptionsObjects = fields.splice(0, leftNumFields);
      const rightOptionsObjects = fields;
      leftOptionsObjects.forEach((leftOption: FormField, ix: number) => {
        optionsToRender.push(leftOption);
        if (ix < rightOptionsObjects.length) {
          optionsToRender.push(rightOptionsObjects[ix]);
        }
      });
    }

    return (
      <OptionsContainer>
        {optionsToRender.map(option => {
          return _renderSingleOption(option);
        })}
      </OptionsContainer>
    );
  };

  return (
    <Container>
      <Text>{props.title}</Text>
      {renderOptions()}
      {renderSelectNeverToAll()}
      {renderContinueButton()}
    </Container>
  );
};

export default FieldGroupSelectionForm;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
`;

const SelectAllText = styled.a`
  cursor: pointer;
  font-size: 16px;
  font-weight: bold;
  text-align: left;
  border-bottom: solid 1px ${themes.colors.neutral.primaryNavy};
`;

const SelectContainer = styled.div`
  margin: 0 0 24px 12px;
  width: 100%;
`;

const OptionsContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: stretch;
  @media (max-width: 1400px) {
    flex-direction: column;
    width: 100%;
  }
`;

const ContinueButtonWrapper = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
`;
