import breakpointsRaw from './breakpointsRaw';
import queries from './queries';

const fonts = Object.freeze({
  Satoshi: 'font-family: "Satoshi", "Helvetica", "Arial", sans-serif;',
  DMSerif: 'font-family: "DM Serif", "Helvetica", "Arial", sans-serif;'
});

const fontSizes = Object.freeze({
  '48': 'font-size: 48px;',
  '36': 'font-size: 36px;',
  '32': 'font-size: 32px;',
  '24': 'font-size: 24px;',
  '20': 'font-size: 20px;',
  '18': 'font-size: 18px;',
  '16': 'font-size: 16px;',
  '14': 'font-size: 14px;'
});

const deprecatedColors = Object.freeze({
  beige: '#f0e6dc', // 23
  gray: '#c6c7ce', // 25
  grayLight: '#f0f0f0' // 8
});

const colors = Object.freeze({
  white: '#FFFFFF',
  yellow: {
    darkYellow: '#F1CB0A',
    primary: '#F9E168',
    lightYellow: '#FFEA80',
    lighterYellow: '#FDF4CA',
    lightestYellow: '#FEFBF6'
  },
  neutral: {
    darkestNavy: '#0A0D3D',
    primaryNavy: '#272A5E',
    navy: '#434792',
    lightNavy: '#6464CE',
    lighterNavy: '#9EA3E5',
    lavender: '#D3D4F3',
    lavenderLight: '#E7E8F9',
    lavenderWhite: '#F7F7FD'
  },
  semantic: {
    darkRed: '#B94237',
    red: '#DB685D',
    pink: '#F0C8BF',
    lightPink: '#FDEEED',
    darkBlue: '#1550C2'
  },
  blue: {
    darkestBlue: '#121C6F',
    darkBlue: '#2B38AB',
    blue: '#7DA3EF',
    lightBlue: '#B6C8F3',
    lighterBlue: '#D7E5FA',
    lightestBlue: '#F6FAFE'
  },
  green: {
    darkestGreen: '#36791E',
    darkGreen: '#61AA48',
    green: '#92D06D',
    lightGreen: '#B2EC9D',
    lighterGreen: '#D2F9D2',
    lightestGreen: '#EBF7E5'
  },
  lilac: {
    lilac: '#A095F0',
    lightLilac: '#ACA2F8',
    lighterLilac: '#C3BAFD'
  }
});

const layers = Object.freeze({
  kindbodyLogo: 3,
  footer: 0
});

const {
  desktop,
  smallDesktop,
  tabletLandscape,
  tabletPortrait,
  largePhonePortrait,
  phone
} = breakpointsRaw;

const breakpoints = Object.freeze({
  desktop: `${desktop}px`,
  smallDesktop: `${smallDesktop}px`,
  tabletLandscape: `${tabletLandscape}px`,
  tabletPortrait: `${tabletPortrait}px`,
  largePhonePortrait: `${largePhonePortrait}px`,
  phone: `${phone}px`,
  raw: breakpointsRaw
});

const shadows = Object.freeze({
  form: {
    level0: '0px 1px 3px 0px rgba(67, 71, 146, 0.16), 0px 1px 1px 0px rgba(10, 13, 61, 0.08)',
    level1:
      '0px 1px 4px 1px rgba(67, 71, 146, 0.16), 0px 1px 1px 0px rgba(10, 13, 61, 0.12), 0px 0px 1px 0px rgba(10, 13, 61, 0.08)',
    focused:
      '0px 1px 0.5px 0px rgba(158, 163, 229, 0.16), 0px 0px 0px 1px rgba(39, 42, 94, 0.10), 0px 1px 3px 0px rgba(39, 42, 94, 0.16), 0px 0px 0px 6px rgba(100, 100, 206, 0.12)',
    error:
      '0px 1px 0.5px 0px rgba(185, 66, 55, 0.16), 0px 0px 0px 1px rgba(185, 66, 55, 0.10), 0px 1px 3px 0px rgba(185, 66, 55, 0.16), 0px 0px 0px 6px rgba(185, 66, 55, 0.12)'
  },
  button: {
    default:
      '0px 1px 3px 0px rgba(39, 42, 94, 0.16), 0px 0px 0px 1px rgba(39, 42, 94, 0.10), 0px 1px 0.5px 0px rgba(39, 42, 94, 0.16)',
    hover:
      '0px 3px 6px 0px rgba(39, 42, 94, 0.18), 0px 1px 1px 1px rgba(39, 42, 94, 0.14), 0px 1px 0.5px 0px rgba(39, 42, 94, 0.16)',
    hoverDanger:
      '0px 1px 3px 0px rgba(185, 66, 55, 0.16), 0px 0px 0px 1px rgba(185, 66, 55, 0.10), 0px 1px 0.5px 0px rgba(185, 66, 55, 0.16)',
    active:
      '0px 0px 0px 6px rgba(241, 203, 10, 0.24), 0px 1px 3px 0px rgba(39, 42, 94, 0.16), 0px 0px 0px 1px #F1CB0A, 0px 1px 0.5px 0px rgba(39, 42, 94, 0.16)',
    activeSecondary:
      '0px 0px 0px 6px rgba(100, 100, 206, 0.12), 0px 1px 3px 0px rgba(39, 42, 94, 0.16), 0px 0px 0px 1px rgba(39, 42, 94, 0.10), 0px 1px 0.5px 0px rgba(39, 42, 94, 0.16)',
    activeError:
      '0px 0px 0px 6px rgba(185, 66, 55, 0.12), 0px 1px 3px 0px rgba(185, 66, 55, 0.16), 0px 0px 0px 1px rgba(185, 66, 55, 0.10), 0px 1px 0.5px 0px rgba(185, 66, 55, 0.16)',
    activeSuccess:
      '0px 0px 0px 6px rgba(97, 170, 72, 0.12), 0px 1px 3px 0px rgba(54, 121, 30, 0.16), 0px 0px 0px 1px rgba(54, 121, 30, 0.14), 0px 1px 0.5px 0px rgba(54, 121, 30, 0.24)'
  },
  container: {
    glowDarkBlue: '0px 1px 1px 0px rgba(10, 13, 61, 0.08), 0px 1px 3px 0px rgba(67, 71, 146, 0.16)',
    glowBlue:
      '0px 0px 0px 6px rgba(125, 163, 239, 0.32), 0px 1px 3px 0px rgba(125, 163, 239, 0.24), 0px 0px 0px 1px rgba(125, 163, 239, 0.32), 0px 1px 0.5px 0px rgba(125, 163, 239, 0.24)',
    glowGreen:
      '0px 0px 0px 6px rgba(97, 170, 72, 0.12), 0px 1px 3px 0px rgba(54, 121, 30, 0.16), 0px 0px 0px 1px rgba(54, 121, 30, 0.14), 0px 1px 0.5px 0px rgba(54, 121, 30, 0.24)',
    glowRed:
      '0px 0px 0px 6px rgba(185, 66, 55, 0.12), 0px 1px 3px 0px rgba(185, 66, 55, 0.16), 0px 0px 0px 1px rgba(185, 66, 55, 0.10), 0px 1px 0.5px 0px rgba(185, 66, 55, 0.16)',
    glowYellow:
      '0px 0px 0px 6px rgba(241, 203, 10, 0.24), 0px 1px 3px 0px rgba(241, 203, 10, 0.48), 0px 0px 0px 1px rgba(241, 203, 10, 0.24), 0px 1px 0.5px 0px rgba(241, 203, 10, 0.48)'
  },
  card: {
    level0: '0px 0px 0px 1px #e7e8f9, 0px 1px 1px 0px rgba(67, 71, 146, 0.12)',
    level1: '0px 1px 1px 0px rgba(10, 13, 61, 0.08), 0px 1px 3px 0px rgba(67, 71, 146, 0.16)',
    level2:
      '0px 0px 1px 0px rgba(10, 13, 61, 0.08), 0px 1px 1px 0px rgba(10, 13, 61, 0.12), 0px 1px 4px 1px rgba(67, 71, 146, 0.16)',
    level3:
      '0px 8px 12px 0px rgba(10, 13, 61, 0.08), 0px 1px 4px 0px rgba(10, 13, 61, 0.12), 0px 1px 4px 1px rgba(67, 71, 146, 0.16)'
  }
});

const gradients = Object.freeze({
  containerRadial:
    'radial-gradient(112.26% 141.42% at 0% 0%, rgba(255, 255, 255, 0.8) 0%, rgba(255, 255, 255, 0) 100%)'
});

export default {
  fonts,
  fontSizes,
  deprecatedColors,
  colors,
  layers,
  queries,
  breakpoints,
  shadows,
  gradients
};
