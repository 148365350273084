import { useEffect, useState } from 'react';

import { CATEGORY, retrieveFile, uploadTemporaryFile } from 'api/pf2/files';
import {
  FINGERPRINTING_DISABLED_INDICATOR,
  INVALID_IMAGE_FILE_INDICATOR
} from 'components/PhotoUpload/photoUpload.utils';
import { BugTracker } from 'kb-shared/utilities/bugTracker';
import { isValidFileExtension } from 'utilities/fileValidation';
import imageResize from 'utilities/imageResize';

const useReportIfError = (error: Error | null, message: string) => {
  useEffect(() => {
    if (!error) return;
    if (error.message?.includes(INVALID_IMAGE_FILE_INDICATOR)) return;
    if (error.message?.includes(FINGERPRINTING_DISABLED_INDICATOR)) return;

    BugTracker.notify(error, message);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);
};

export const useUploadFile = (category: CATEGORY, file: File | null) => {
  const [error, setError] = useState<any | null>(null);
  const [errorPatientMessage, setErrorPatientMessage] = useState<string | null>(null);
  const [uploading, setUploading] = useState(false);
  useReportIfError(error, 'Insurance file upload error');

  const upload = async () => {
    if (!file || !isValidFileExtension(file.type)) {
      return;
    }

    setUploading(true);

    let fileToUpload = null;
    try {
      fileToUpload = await imageResize(file);
    } catch (error) {
      if (error instanceof Error) {
        if (error?.message?.includes(INVALID_IMAGE_FILE_INDICATOR)) {
          setErrorPatientMessage("Selected file doesn't seem to be an image!");
          setError(error);
        } else if (error?.message?.includes(FINGERPRINTING_DISABLED_INDICATOR)) {
          // if fingerprinting protection prevents resize, we will upload file without resizing
          fileToUpload = file;
        }
      } else {
        setError(error);
      }
    }

    if (fileToUpload) {
      try {
        const response = await uploadTemporaryFile({
          file: fileToUpload,
          category: category
        });
        return response.json();
      } catch (error) {
        setErrorPatientMessage('Failed to upload file!');
        setError(error);
      } finally {
        setUploading(false);
      }
    }
  };

  return {
    upload,
    uploading,
    error,
    errorPatientMessage
  };
};

export const useUploadedFile = (category: CATEGORY) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<any | null>(null);
  const [fileUrl, setFileUrl] = useState<string>();
  useReportIfError(error, 'Insurance file retrieve error');

  const retrieve = async () => {
    setLoading(true);

    try {
      const response = await retrieveFile(category);

      if ('file' in response) {
        setFileUrl(response.file);
      } else {
        setError("Couldn't retrieve insurance file URL");
      }
    } catch (e) {
      setError(e);
    }

    setLoading(false);
  };

  return { retrieve, fileUrl, loading };
};
