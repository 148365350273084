import styled from 'styled-components';

export const Aside = styled.aside`
  ${({ theme }) => theme.grid.container};
  background-color: ${({ theme }) => theme.colors.white};
  position: relative;

  h1 {
    ${({ theme }) => theme.typos.subsectionHeader};
    color: ${({ theme }) => theme.colors.neutral.primaryNavy};
    padding: 20px 0 15px;
  }
`;

export const InputRow = styled.div`
  &:not(:last-child) {
    margin-bottom: 30px;
  }

  p {
    ${({ theme }) => theme.typos.bodyText};
    flex: 0 1 160px;
    margin-right: 5px;
    text-transform: uppercase;
  }
`;
