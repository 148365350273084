import React from 'react';

import info from 'assets/icons/info.svg';

import { Container, InfoBoxIcon, InfoBoxText } from './InfoBox.styled';

type Props = {
  message: string;
};

export const InfoBox = ({ message }: Props) => {
  return (
    <Container>
      <InfoBoxIcon src={info} alt={'Show information sign'} />
      <InfoBoxText>{message}</InfoBoxText>
    </Container>
  );
};
