import React from 'react';

import { FamilyPlanningIcon, PregnancyParentingIcon, WellnessIcon } from 'assets/icons/kb360';

export const SERVICE_TYPE_ICONS = {
  'Fertility and Family Building': FamilyPlanningIcon,
  'Pregnancy and Beyond': PregnancyParentingIcon,
  Wellness: WellnessIcon
};

export const SERVICE_TYPES = Object.keys(SERVICE_TYPE_ICONS) as Array<KB360ServiceType>;

export type KB360ServiceType = keyof typeof SERVICE_TYPE_ICONS;

interface ServiceTypeIconProps {
  serviceType: KB360ServiceType;
  className?: string;
}

export const ServiceTypeIcon = ({ serviceType, className }: ServiceTypeIconProps) => {
  const Icon: React.FunctionComponent<React.SVGProps<SVGSVGElement>> =
    SERVICE_TYPE_ICONS[serviceType];

  return Icon ? <Icon className={className} /> : <>{serviceType}</>;
};
