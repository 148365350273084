import React from 'react';
import { Link } from 'react-router-dom';

import { pageUrl } from 'utilities/pageUrl';

import { MenuItem } from '../menuItems';
import {
  NavItems,
  MenuItemWrapper,
  UnreadBubble,
  NavigationItemLabel,
  NavItem,
  NavItemContainer
} from './NavigationItems.styled';
import { NavigationItemsProps } from './NavigationItems.types';

const belongsToMenuItem = (pathname: string, item: MenuItem) => {
  return (
    (pathname === pageUrl.booking.index() && item.id === 'appointment') ||
    (pathname === pageUrl.booking.bookingQuiz() && item.id === 'appointment')
  );
};

export const NavigationItems = ({
  currentPathName,
  numberOfUnreadMessages,
  numberOfOutstandingInvoices,
  items,
  labelVisible
}: NavigationItemsProps) => {
  return (
    <NavItems>
      {items.map((item: MenuItem) => {
        const isActive = currentPathName === item.to || belongsToMenuItem(currentPathName, item);
        return (
          <NavItemContainer key={item.to}>
            <Link to={{ pathname: item.to, state: { menuOpen: false } }}>
              <NavItem active={isActive}>
                <MenuItemWrapper active={isActive}>
                  {item.icon
                    ? item.icon({ type: isActive ? 'regular' : 'thin', iconTitle: item.label })
                    : null}
                  {item.notificationKey === 'unreadMessagesCount' && numberOfUnreadMessages > 0 ? (
                    <UnreadBubble>{numberOfUnreadMessages}</UnreadBubble>
                  ) : null}
                  {item.notificationKey === 'outstandingInvoicesCount' &&
                  numberOfOutstandingInvoices > 0 ? (
                    <UnreadBubble>{numberOfOutstandingInvoices}</UnreadBubble>
                  ) : null}
                </MenuItemWrapper>
                {labelVisible && <NavigationItemLabel>{item.label}</NavigationItemLabel>}
              </NavItem>
            </Link>
          </NavItemContainer>
        );
      })}
    </NavItems>
  );
};
