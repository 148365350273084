import { gql } from '@apollo/client';

export const UPDATE_PATIENT_TRUSTEE = gql`
  mutation updatePatientTrustee(
    $name: String
    $id: String
    $phone: String
    $state: String
    $relationship: String
    $email: String
    $preferredContacts: [String!]
  ) {
    updatePatientTrustee(
      id: $id
      name: $name
      phone: $phone
      state: $state
      relationship: $relationship
      email: $email
      preferredContacts: $preferredContacts
    ) {
      patientTrustee {
        id
        phone
        name
        relationship
        state
        email
        preferredContacts
      }
    }
  }
`;

export const DELETE_PATIENT_TRUSTEE = gql`
  mutation deletePatientTrustee($id: String!) {
    deletePatientTrustee(id: $id) {
      id
    }
  }
`;
