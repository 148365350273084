import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { RoutesPath } from 'utilities/pageUrl';

const RedirectToEnterpriseMembership = () => {
  const [redirected, setRedirected] = useState(false);
  const history = useHistory();

  useEffect(() => {
    if (redirected) return;

    setRedirected(true);
    history.replace(
      `/create-account?step=create_account&target=${RoutesPath.ENTER_MEMBERSHIP_ACCESS_CODE}`
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return null;
};

export default RedirectToEnterpriseMembership;
