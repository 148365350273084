import React from 'react';
import { Link } from 'react-router-dom';

import { utils } from 'kb-shared';

import Button from '../../../../components/Button/NewButton';
import { ButtonWrapper, Container, ErrorText } from './ConsentHTML.styled';
import { ConsentHTMLErrorProps } from './ConsentHTMLError.types';

const { KBContacts } = utils;

export const ConsentHTMLError = ({ text, link }: ConsentHTMLErrorProps) => {
  return (
    <Container>
      <ErrorText>
        Sorry, we&apos;re unable to load this form.
        <br />
        Please try again, or contact us at{' '}
        <a className="link" href={`mailto:${KBContacts.navigatorEmail}`}>
          {KBContacts.navigatorEmail}
        </a>
        .
      </ErrorText>
      {text && (
        <Link to={link}>
          <ButtonWrapper>
            <Button text={text} />
          </ButtonWrapper>
        </Link>
      )}
    </Container>
  );
};

export default ConsentHTMLError;
