import styled from 'styled-components';

export const OrderedList = styled.ol`
  list-style: decimal;

  > li {
    margin-left: 24px;
  }
`;

export const UnorderedList = styled.ul`
  list-style: disc;

  > li {
    margin-left: 24px;
  }
`;
