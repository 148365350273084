import * as React from 'react';

export function getAMHDisclaimer(level: number) {
  // AMH 1-6
  if (level >= 1 && level <= 6) {
    return (
      <div>
        <b>NOTE:</b> Though this may seem like a broad range—any number between 1 and 6 is
        considered within normal limits
      </div>
    );
  }
}
