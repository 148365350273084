import React from 'react';

import { LinkButton } from 'components/v2/Buttons/LinkButton/LinkButton';

export const SmsConsentLabel: React.FC = () => {
  return (
    <>
      I agree to Kindbody’s{' '}
      <LinkButton
        text="SMS Terms"
        onClick={() => window.open('https://kindbody.com/sms-terms-2024/', '_blank')}
      />{' '}
      and will receive updates about my account via text and calls. Message rates may apply. Reply
      STOP to opt out.
    </>
  );
};
