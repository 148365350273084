import { Heading } from 'components/v2/Typography/Heading';
import styled from 'styled-components';

export const StyledHeading = styled(Heading)`
  width: 100%;
`;

export const EmailText = styled.div`
  white-space: normal;
  word-break: break-all;
`;

export const ListItemContainer = styled.div`
  box-shadow: 0px 1px 3px 0px rgba(67, 71, 146, 0.16), 0px 1px 1px 0px rgba(10, 13, 61, 0.08);
  padding: 24px 32px;
  border-radius: 8px;
  min-width: 0;

  @media (min-width: ${({ theme }) => theme.breakpoints.smallDesktop}) {
    display: flex;
    gap: 24px;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
`;

export const ItemInfoContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;

  @media (min-width: ${({ theme }) => theme.breakpoints.smallDesktop}) {
    display: grid;
    grid-template-columns: 40% 40% 10%;
    gap: 3.33%;
  }
`;

export const Label = styled.div`
  display: none;

  @media (min-width: ${({ theme }) => theme.breakpoints.smallDesktop}) {
    display: block;
    ${StyledHeading} {
      color: ${({ theme }) => theme.colors.neutral.navy};
    }
  }
`;

export const Content = styled.div``;

export const LabelContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: 8px;
`;

export const Name = styled.div`
  width: 100%;
  min-width: 0;

  ${StyledHeading} {
    display: inline-block;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.smallDesktop}) {
    padding-top: 8px;
  }
`;

export const Email = styled.div`
  display: flex;

  ${LabelContainer} ${StyledHeading} {
    color: ${({ theme }) => theme.colors.neutral.lightNavy};
  }

  ${Content} {
    width: 100%;
    min-width: 0;
  }

  ${Content} ${StyledHeading} {
    color: ${({ theme }) => theme.colors.neutral.primaryNavy};
    display: inline-block;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
`;

export const DOB = styled.div`
  display: none;

  @media (min-width: ${({ theme }) => theme.breakpoints.smallDesktop}) {
    display: block;
    width: min-content;
    color: ${({ theme }) => theme.colors.neutral.primaryNavy};
  }
`;

export const MobileDOB = styled.div`
  display: block;

  ${StyledHeading} {
    color: ${({ theme }) => theme.colors.neutral.navy};
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.smallDesktop}) {
    display: none;
  }
`;

export const Status = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2px 16px;
  background: ${({ theme }) => theme.colors.blue.lighterBlue};
  border-radius: 4px;
  margin-top: 8px;
  height: min-content;
  width: 120px;

  ${StyledHeading} {
    width: 100%;
    justify-content: center;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.smallDesktop}) {
    margin-top: 0px;
  }
`;
