import { themes } from 'kb-shared';
import styled from 'styled-components';

import { ClickableProps, CurrentIndexProps, SelectedProps } from './StepTracker.types';

const ROW_HEIGHT = 48;

export const Text = styled.div`
  color: ${themes.colors.neutral.primaryNavy};
  text-align: left;

  ${({ selected }: SelectedProps) => `
    font-weight: ${selected ? 'bolder' : 'normal'};
    transition: all 400ms ease 0s;
  `}
`;

export const StepperBackground = styled.div`
  width: 4px;
  background-color: ${themes.colors.yellow.lightestYellow};
  flex: 1;
`;

export const Stepper = styled.div`
  position: absolute;
  height: ${`${ROW_HEIGHT}px`};
  background-color: ${themes.colors.yellow.primary};
  width: 4px;
  transition: all 400ms ease 0s;
  top: ${(props: CurrentIndexProps) => `${props.currentIndex * ROW_HEIGHT}px`};
`;

export const TextRow = styled.div`
  display: flex;
  align-items: center;
  height: ${`${ROW_HEIGHT}px`};
  ${({ clickable }: ClickableProps) => `
    cursor: ${clickable ? 'pointer' : 'auto'}
  `}
`;

export const Container = styled.div`
  display: flex;
  margin-left: 20px;
`;

export const TextRowContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  background-color: white;
`;

export const TextRowContainerMargin = styled(TextRowContainer)`
  padding-left: 20px;
`;
