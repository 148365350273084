import { useQuery } from '@apollo/client';
import _flatMap from 'lodash/flatMap';
import _sortBy from 'lodash/sortBy';
import { useState, useMemo } from 'react';

import { BugTracker } from 'kb-shared/utilities/bugTracker';

import {
  PatientFormQueryArguments,
  PatientFormQueryResponse,
  PATIENT_FORM_QUERY
} from '../graphql/patient-form-query';
import RiskAssessmentField from '../types/RiskAssessmentField';
import RiskAssessmentForm from '../types/RiskAssessmentForm';
import { useRiskAssessmentAnswers } from './use-risk-assessment-answers';

export const RISK_ASSESSMENT_FORM_ID = 12;

const buildFormFields = (
  response: PatientFormQueryResponse | undefined,
  answerByFieldId: Record<string, boolean>
): RiskAssessmentField[] => {
  if (!response?.patientForm) {
    return [];
  }

  const formFields = _flatMap(response?.patientForm.formSections, section => section.formFields);
  const formFieldsWithAnswer = formFields.map(field => ({
    ...field,
    id: parseInt(field.id),
    answer: answerByFieldId[field.id]
  }));

  return _sortBy(formFieldsWithAnswer, field => field.position);
};

export const useRiskAssessmentForm = (): UseRiskAssessmentFormResponse => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);

  const { answerByFieldId, saveAnswer } = useRiskAssessmentAnswers(RISK_ASSESSMENT_FORM_ID);

  const { data, loading } = useQuery<PatientFormQueryResponse, PatientFormQueryArguments>(
    PATIENT_FORM_QUERY,
    {
      variables: { formId: RISK_ASSESSMENT_FORM_ID },
      onError: error => BugTracker.notify(error, 'RiskAssessmentFormLoadingForm')
    }
  );

  const fields = useMemo(() => buildFormFields(data, answerByFieldId), [data, answerByFieldId]);

  const form = data && { title: data.patientForm?.title || '', fields };

  return { form, loading, isModalOpen, openModal, closeModal, saveAnswer };
};

interface UseRiskAssessmentFormResponse {
  form?: RiskAssessmentForm;
  loading: boolean;
  isModalOpen: boolean;
  saveAnswer: (fieldId: number, answer: boolean) => Promise<any>;
  openModal: () => void;
  closeModal: () => void;
}
