import React from 'react';

import styled from 'styled-components';

import StepDetail from './StepDetail';
import { Description } from './StepDetail';

const StartProgesteroneSupplementsWithEmbryoTransfer = () => {
  return (
    <StepDetail>
      <Description>
        You will start your progesterone supplements five days prior to your embryo transfer. Your
        progesterone supplement will start at 8:00PM at night (unless specified otherwise) and you
        will continue the medication as prescribed until instructed to stop. It is important to
        remember that if you become pregnant, the progesterone supplements will continue. You may
        need to have this prescription refilled.
      </Description>
      <NoMargin>Please review the details of your embryo transfer below.</NoMargin>
    </StepDetail>
  );
};

export const StartProgesteroneSupplementsWithBiopsy = () => {
  return (
    <StepDetail>
      <Description>
        You will start your progesterone supplements five days prior to your biopsy. Your
        progesterone supplement will start at 8:00AM (unless specified otherwise) and you will
        continue the medication as prescribed until instructed to stop.
      </Description>
      <Description>Please review the details of your biopsy below.</Description>
    </StepDetail>
  );
};

export default StartProgesteroneSupplementsWithEmbryoTransfer;

const NoMargin = styled.p`
  margin: 0;
`;
