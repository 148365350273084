import React from 'react';
import { useHistory } from 'react-router-dom';

import { useRedirectIfMember } from 'kb-shared/booking/hooks/useRedirectIfMember';
import { LoadedEntities } from 'kb-shared/booking/types/types';
import { Appointment } from 'kb-shared/types';
import { Clinic, TimeSlot } from 'kb-shared/types';
import { RoutesPath } from 'utilities/pageUrl';

import { hasEmployer } from '../../Book.utils';
import { ConfirmOrder } from './ConfirmOrder/ConfirmOrder';

export const ConfirmStep = ({
  selectedClinic,
  selectedProduct,
  selectedTimeSlot,
  onBookAppointment,
  providerId
}: {
  selectedClinic?: Clinic;
  selectedProduct?: LoadedEntities['selectedProduct'];
  selectedTimeSlot?: TimeSlot;
  onBookAppointment: (appointment: Appointment) => void;
  providerId?: number;
}) => {
  const history = useHistory();
  useRedirectIfMember({ product: selectedProduct ?? null });

  if (selectedProduct?.type === 'enterprise_membership') {
    history.replace(RoutesPath.ENTER_MEMBERSHIP_ACCESS_CODE);

    return null;
  } else {
    const query = new URLSearchParams(history.location.search);
    const isOldMembership = query.get('membership_id');

    if (isOldMembership != null) {
      query.delete('membership_id');
      query.append('enterprise_membership', 'true');
      history.replace(`${history.location.pathname}?${query.toString()}`);
      return null;
    } else {
      // wait until the product is loaded
      if (!selectedProduct) return null;

      return (
        <ConfirmOrder
          providerId={providerId}
          product={selectedProduct}
          timeSlot={selectedTimeSlot ?? null}
          clinic={selectedClinic ?? null}
          hasEmployer={hasEmployer(selectedProduct)}
          onBookAppointment={(appointment: Appointment) => onBookAppointment(appointment)}
        />
      );
    }
  }
};
