import { themes } from 'kb-shared';
import styled from 'styled-components';

export const Container = styled.div`
  flex-direction: row;
  background-color: ${themes.deprecatedColors.beige};
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px 20px 0px 20px;

  @media screen and (min-width: ${themes.breakpoints.largePhonePortrait}) {
    height: 130px;
    padding: 0px 90px 0px 90px;
  }
`;

export const ArrowButton = styled.button`
  cursor: pointer;
  padding: 0px 6px 0px 6px;
  border-width: 0px;
  background-color: transparent;
  :focus {
    outline: 0;
  }
`;
