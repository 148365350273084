import React from 'react';

import { Button } from 'components/v2/Buttons/Button';

import GlucoseTestingPDF from '../../assets/Booklet-GlucoseTesting.pdf';
import SubstepWrapper from '../../SubstepWrapper';
import { Description, FlexWrapper, Title } from './styled-components';

const ReturnOBAppointment = () => (
  <>
    <Title>Return OB Appointment</Title>
    <Description>
      This appointment inlcudes a visit with your provider, ultrasound, Gestational Diabetes
      Screening, & Rhogam Injection (if needed).
    </Description>
    <FlexWrapper>
      <Description>
        <strong>Gestational Diabetes Screening:</strong> It is routine for all pregnant women to be
        tested for diabetes between 26-28wks of pregnancy. Please do not eat or drink anything for 1
        hour before your visit. This test takes 1 hour to perform.
      </Description>
      <Button
        label="View More"
        category="secondary"
        size="md"
        onClick={() => window.open(GlucoseTestingPDF)}
      />
    </FlexWrapper>
    <Description>
      <strong>Rhogam Injection:</strong> If your blood type is Rh negative, you will get this
      injection at 28wks. If your male partner's blood type is also negative, then you do NOT need
      Rhogam.
    </Description>
  </>
);

const NutritionAppointment = () => (
  <>
    <Title>Nutrition Appointment</Title>
    <Description>
      This is your 3rd individualized nutrition visit. Good diet and nutrition are essential for a
      healthy mom & baby. The team will help you schedule your appointment.
    </Description>
  </>
);

const MedicalFitnessAppointment = () => (
  <>
    <Title>Medical Fitness Appointment</Title>
    <Description>
      This is your 3rd individualized physical fitness visit. Physical exercise can help with some
      common discomforts of pregnancy and even help prepare your body for labor and delivery. The
      team will help you schedule your appointment.
    </Description>
  </>
);

const SUBSTEPS = [
  { component: <ReturnOBAppointment /> },
  { component: <NutritionAppointment /> },
  { component: <MedicalFitnessAppointment /> }
];

const Week26To28Substeps = () => <SubstepWrapper substeps={SUBSTEPS} />;

export default Week26To28Substeps;
