import styled from 'styled-components';

export const Container = styled.div`
  margin: 0 0 10px 0;
`;

export const Title = styled.div`
  background-color: ${({ theme }) => theme.deprecatedColors.grayLight};
  padding: 8px;
  flex: 1;
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  border: 1px solid ${({ theme }) => theme.deprecatedColors.gray};
`;

export const Header = styled.div`
  border: 1px solid ${({ theme }) => theme.deprecatedColors.gray};
  border-bottom: none;
  background-color: ${({ theme }) => theme.deprecatedColors.grayLight};
  font-weight: bold;
  padding: 8px;
`;

export const Value = styled.div`
  padding: 8px;
  flex: 1;
`;
