import * as React from 'react';

import { themes } from 'kb-shared';
import styled from 'styled-components';

const { breakpoints } = themes;

const ErrorWrapper = styled.div`
  position: absolute;
  word-wrap: break-word;
  overflow-wrap: break-word;
  left: 300px;
  right: 20px;

  @media (max-width: ${breakpoints.smallDesktop}) {
    left: 20px;
    right: 20px;
  }
`;

export default function Error(props: { text: string }) {
  return (
    <ErrorWrapper className="text-break">
      <div>
        We were unable to match your credentials to complete the login process. Please contact{' '}
        <a href="tel://407-934-4100">407-934-4100</a> for assistance.
      </div>
      <div>{props.text}</div>
    </ErrorWrapper>
  );
}
