import Honeybadger from '@honeybadger-io/js';
import * as React from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import Error from 'components/LoginSso/EpicDisney/error';
import { useGetPatientDetails } from 'components/LoginSso/EpicDisney/hooks/use-get-patient-details';
import { useRecognizePatientOnBackend } from 'components/LoginSso/EpicDisney/hooks/use-recognize-patient-on-backend';
import useSubmitAuthorizationCode from 'components/LoginSso/EpicDisney/hooks/use-submit-authorization-code';
import requestAuthorizationCode from 'components/LoginSso/EpicDisney/request-authorization-code';
import RecognizePatientResultCode from 'components/LoginSso/EpicDisney/types/recognize-patient-result-code';
import Loader from 'components/LoginSso/loader';
import client from 'kb-shared/graphql/client';
import { UPDATE_PATIENT_LAST_SIGN_IN } from 'kb-shared/graphql/mutations';
import styled from 'styled-components';
import { showErrorToast } from 'utilities/notificationUtils';
import { pageUrl } from 'utilities/pageUrl';

const Container = styled.div`
  display: flex;
  justify-content: center;
`;

const STEPS = {
  initializing: 'initializing',
  waitingForLoginButtonClick: 'waitingForLoginButtonClick',
  requestingAuthorizationCode: 'requestingAuthorizationCode',
  submittingAuthorizationCode: 'submittingAuthorizationCode',
  retrievingPatientDetails: 'retrievingPatientDetails',
  recognizingPatientOnBackend: 'recognizingPatientOnBackend',
  loggedIn: 'loggedIn',
  failed: 'failed'
};

export default function LoginSsoEpicDisney() {
  const history = useHistory();

  const [step, setStep] = useState(STEPS.initializing);
  const [errorText, setErrorText] = useState('');

  const urlParams = new URLSearchParams(window.location.search);
  const launchToken = urlParams.get('launch');
  const authorizationCode = urlParams.get('code');
  const {
    responseState: submitAuthorizationCodeResponse,
    submitAuthorizationCode
  } = useSubmitAuthorizationCode();
  const { responseState: getPatientDetailsResponse, getPatientDetails } = useGetPatientDetails();
  const {
    responseState: recognizePatientOnBackendResponse,
    recognizePatientOnBackend
  } = useRecognizePatientOnBackend();

  useEffect(() => {
    if (step === STEPS.initializing) {
      if (!authorizationCode) {
        // when there is no authorization ?code= in URL, the login attempt only started
        // the login attempt can be invoked in two ways:
        // 1. standalone launch, you visit /login-sso/epic-disney URL (no URL params)
        // 2. embedded launch, in My Chart app, you click Kindbody, and you are redirected to
        // /login-sso/epic-disney?iss=<value>&launch=<value>
        // in both cases, we need to retrieve the authorization code by making a full HTTP redirect to Mychart
        // in standalone launch, we will be asked to login to MyChart app
        // in embedded launch, thanks to the launch token, we will be already recognized as 'logged in'
        setStep(STEPS.requestingAuthorizationCode);
      } else {
        // in both cases, we are eventually redirected back here with /login-sso/epic-disney?code=<value>
        setStep(STEPS.submittingAuthorizationCode);
      }
    }
    if (step === STEPS.submittingAuthorizationCode && submitAuthorizationCodeResponse.finished) {
      if (submitAuthorizationCodeResponse.idtoken) setStep(STEPS.retrievingPatientDetails);
      else if (submitAuthorizationCodeResponse.error) {
        setStep(STEPS.failed);
        setErrorText(
          'Failed to submit authorization code: ' + submitAuthorizationCodeResponse.error
        );
      }
    }
    if (step === STEPS.retrievingPatientDetails && getPatientDetailsResponse.finished) {
      if (getPatientDetailsResponse.error) {
        setStep(STEPS.failed);
        setErrorText(
          'Failed to retrieve your details from MyChart: ' + getPatientDetailsResponse.error
        );
      } else setStep(STEPS.recognizingPatientOnBackend);
    }
    if (step === STEPS.recognizingPatientOnBackend && recognizePatientOnBackendResponse.finished) {
      if (
        recognizePatientOnBackendResponse.resultCode ===
        RecognizePatientResultCode.PatientRecognized
      ) {
        window.sessionStorage.setItem('epicSsoIdToken', submitAuthorizationCodeResponse.idtoken);
        setStep(STEPS.loggedIn);
      } else if (
        recognizePatientOnBackendResponse.resultCode ===
          RecognizePatientResultCode.PatientNotRecognized ||
        recognizePatientOnBackendResponse.resultCode === RecognizePatientResultCode.Failed
      ) {
        setStep(STEPS.failed);
        setErrorText((recognizePatientOnBackendResponse.errors as Array<string>).join('. '));
      } else if (
        recognizePatientOnBackendResponse.resultCode ===
        RecognizePatientResultCode.PatientNotRecognizedButCanBeCreated
      ) {
        showErrorToast(
          'Sign-up using Epic SSO is no longer supported. Please use an alternative method, such as Google SSO, or sign up with your email address and password.'
        );
        history.push(pageUrl.createAccount({ step: 'create_account', previous: 'login' }));
      } else if (recognizePatientOnBackendResponse.errors) {
        setStep(STEPS.failed);
        setErrorText(
          'Failed to find you in Kindbody database: ' +
            recognizePatientOnBackendResponse.errors.join('. ')
        );
      } else {
        window.sessionStorage.setItem('epicSsoIdToken', submitAuthorizationCodeResponse.idtoken);
        setStep(STEPS.loggedIn);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    step,
    submitAuthorizationCodeResponse,
    getPatientDetailsResponse,
    recognizePatientOnBackendResponse
  ]);

  useEffect(() => {
    if (step === STEPS.requestingAuthorizationCode) {
      // if this is login invoked in MyChart, we have launch token
      // if this is standalone launch (from button in our app), we don't have the launch token (we pass null)
      // this is doing full page redirect, we leave React here
      requestAuthorizationCode(launchToken);
    } else if (step === STEPS.submittingAuthorizationCode) {
      submitAuthorizationCode(authorizationCode);
    } else if (step === STEPS.retrievingPatientDetails) {
      // we don't reall need this here, but for now I keep it for demo purposes
      getPatientDetails(
        submitAuthorizationCodeResponse.accessToken,
        submitAuthorizationCodeResponse.patientFhirId
      );
    } else if (step === STEPS.recognizingPatientOnBackend) {
      recognizePatientOnBackend(
        submitAuthorizationCodeResponse.accessToken,
        submitAuthorizationCodeResponse.idtoken
      );
    } else if (step === STEPS.loggedIn) {
      window.sessionStorage.setItem('epicSsoIdToken', submitAuthorizationCodeResponse.idtoken);
      client
        .mutate({
          mutation: UPDATE_PATIENT_LAST_SIGN_IN
        })
        .catch(error => Honeybadger.notify(error, 'DisneyUpdateLastSignIn'))
        .finally(() => history.push('/'));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [step]);

  const showLoader =
    step === STEPS.requestingAuthorizationCode ||
    step === STEPS.submittingAuthorizationCode ||
    step === STEPS.retrievingPatientDetails ||
    step === STEPS.recognizingPatientOnBackend;

  return (
    <Container>
      <div>
        {showLoader && <Loader labelText="Please wait, we are logging you in." />}
        {step === STEPS.loggedIn && (
          <div>You are successfully logged in. Redirecting you to dashboard</div>
        )}
        {step === STEPS.failed && <Error text={errorText} />}
      </div>
    </Container>
  );
}
