import React, { ReactNode } from 'react';

import { KindbodyLogo } from 'layouts/MainLayout/Logo/KindBodyLogo';
import { NavigationContainer } from 'layouts/MainLayout/Navigation/NavigationContainer/NavigationContainer';
import styled from 'styled-components';

import Footer from '../components/Footer';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const Main = styled.main`
  margin: 0 10px 80px 0;
  flex: 1 0 auto;
`;

const StickyBottom = styled.div`
  flex-shrink: 0;
`;

interface Props {
  children: ReactNode;
}

export const AuthLayout = ({ children }: Props) => (
  <Container>
    <KindbodyLogo href="https://kindbody.com/" marginTop="40" />
    <NavigationContainer />
    <Main>{children}</Main>
    <StickyBottom>
      <Footer />
    </StickyBottom>
  </Container>
);
