import React from 'react';

import { Plus } from 'assets/icons/Icons';
import { LinkButton } from 'components/v2/Buttons/LinkButton/LinkButton';

import { ButtonBackgroundAndBorder } from './AddCard.styled';

type Props = {
  onClick: () => void;
  label: string;
};

export const AddCard = ({ onClick, label }: Props) => (
  <ButtonBackgroundAndBorder>
    <LinkButton text={label} size="lg" onClick={onClick} leftIcon={<Plus />} underline="none" />
  </ButtonBackgroundAndBorder>
);
