import React, { forwardRef } from 'react';

import { Container } from './Badge.styled';
import { Props } from './Badge.types';

export const Badge = forwardRef<HTMLElement, Props>(({ label, type, size, className }, ref) => {
  return (
    <Container type={type} className={className} size={size} ref={ref}>
      {label}
    </Container>
  );
});

Badge.displayName = 'Badge';
