import { Component, PropsWithChildren, ReactNode, ErrorInfo } from 'react';

import { BugTracker } from 'kb-shared/utilities/bugTracker';

type Props = PropsWithChildren<{
  // like "app", "route", 'component', etc.;
  // used to better identify the source of the error
  id: string;
  fallback: ReactNode;
}>;

type State = {
  hasError: boolean;
};

export class ErrorBoundary extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(_error: Error): State {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error: Error, _info: ErrorInfo): void {
    BugTracker.notify(error, `ErrorBoundary [${this.props.id}]: ${error.name}`);
  }

  render(): ReactNode {
    if (this.state.hasError) {
      return this.props.fallback;
    }

    return this.props.children;
  }
}
