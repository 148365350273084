import { Text } from 'components/v2/Typography/Text';
import { themes } from 'kb-shared';
import styled from 'styled-components';

import { InfoAreaContainer } from './styled-components';

export const Header = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-top: 16px;
  margin-bottom: 16px;
`;

export const ColumnContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const StyledInfoAreaContainer = styled(InfoAreaContainer)`
  margin-top: 0px;
  margin-bottom: 12px;
  padding: 20px 24px;
  border-radius: 8px;
  width: 100%;

  box-shadow: ${themes.shadows.card.level0};
  background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTY2IiBoZWlnaHQ9Ijc2IiB2aWV3Qm94PSIwIDAgMTY2IDc2IiBmaWxsPSJub25lIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPgo8cGF0aCBpZD0iUGF0aCAxMyIgb3BhY2l0eT0iMC4yNCIgZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik0xOTAuMDg0IDE5LjEzMTVMMTkzIDE0LjQxMkwxNzIuMzU3IC05LjAyMzIyZS0wN0wxNjguOTU1IDUuNDg0MTJDMTUxLjM3OSAzMy44Mjc0IDEyNC45NzggNTAuMDgxIDk2LjUyMDMgNTAuMDgxQzY3Ljk4MjcgNTAuMDgxIDQxLjU3ODcgMzMuOTAzMiAyNC4wODA5IDUuNjk4NTlMMjAuNzI4MyAwLjI5NDkwNEwtNi4yNzMwMmUtMDcgMTQuMzUxTDIuOTU0OTIgMTkuMTMxNkMxOC45MDE3IDQ0LjkyNDIgNDguMDgyOCA3NS42Njc1IDk2LjUyMDMgNzUuNjY3NUMxNDQuOTYgNzUuNjY3NSAxNzQuMTM4IDQ0LjkyNDIgMTkwLjA4NCAxOS4xMzE2TDE5MC4wODQgMTkuMTMxNVoiIGZpbGw9IiNGOUUxNjgiLz4KPC9zdmc+Cg==);
  background-repeat: no-repeat;
  background-position: top 10px right 0px;
`;

export const TinyText = styled(Text)`
  font-size: 13px;
  font-style: italic;
  line-height: 16px;
`;
