import styled from 'styled-components';

const getSizeStyles = ($size: 'sm' | 'md') => {
  switch ($size) {
    case 'sm':
      return `
        height: 24px;
        width: 24px;
        min-width: 24px;
        font-size: 12px;
      `;
    case 'md':
      return `
        height: 48px;
        width: 48px;
        min-width: 48px;
        font-size: 18px;
      `;
    default:
      return `
        height: 24px;
        width: 24px;
        min-width: 24px;
        font-size: 12px;
      `;
  }
};

export const Container = styled.div<{ $size: 'sm' | 'md' }>`
  align-items: center;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.colors.neutral.primaryNavy};
  color: ${({ theme }) => theme.colors.white};
  display: inline-flex;
  justify-content: center;

  ${({ $size }) => getSizeStyles($size)}
`;
