import { CheckCircle, CircleQuestion } from 'assets/icons/Icons';
import { Badge } from 'screens/Messages/components/Badge/Badge';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.75em;
`;

export const ListHeader = styled.div`
  display: grid;
  align-items: center;
  grid-template-columns: 2fr 1fr 1fr 1fr;
  gap: 3em;

  @media ${({ theme }) => theme.queries.maxTablet} {
    gap: 1em;
    grid-template-columns: 1.5fr 1fr 1fr;
  }
`;

export const ListItem = styled.div<{
  highlightTodaysListItems?: boolean;
}>`
  display: grid;
  align-items: center;
  grid-template-columns: 1.5fr 1fr 1fr 0.5fr;
  gap: 1em;
  border-radius: 0.5em;
  background: ${({ highlightTodaysListItems, theme }) =>
    highlightTodaysListItems
      ? `radial-gradient(50% 50% at 50% 0%, rgba(255, 255, 255, 0.48) 0%, rgba(255, 255, 255, 0.00) 100%), ${theme.colors.yellow.lightestYellow}`
      : theme.colors.white};
  box-shadow: ${({ highlightTodaysListItems, theme }) =>
    highlightTodaysListItems ? theme.shadows.container.glowYellow : theme.shadows.card.level0};
  min-height: 72px;
  padding: 0.8em 1.5em;

  @media ${({ theme }) => theme.queries.maxTablet} {
    gap: 1em;
  }
`;

export const ListItemInfo = styled.div`
  display: grid;
  grid-template-columns: 1.5em 1fr;
  grid-template-rows: 1fr 1fr;
  column-gap: 0.5em;
  row-gap: 0.25em;
  flex-direction: column;
  align-items: center;

  svg {
    justify-self: center;
    color: ${({ theme }) => theme.colors.neutral.lighterNavy};
  }
`;

export const HeaderMobile = styled.div`
  display: flex;
  gap: 0.75em;
  align-items: center;
`;

export const LisItemMobile = styled.div<{
  highlightTodaysListItems?: boolean;
}>`
  border-radius: 8px;
  background: ${({ highlightTodaysListItems, theme }) =>
    highlightTodaysListItems
      ? `radial-gradient(50% 50% at 50% 0%, rgba(255, 255, 255, 0.48) 0%, rgba(255, 255, 255, 0.00) 100%), ${theme.colors.yellow.lightestYellow}`
      : theme.colors.white};
  box-shadow: ${({ highlightTodaysListItems, theme }) =>
    highlightTodaysListItems ? theme.shadows.container.glowYellow : theme.shadows.card.level0};
  padding: 0.75em 1em;
  display: flex;
  position: relative;
  flex-direction: column;
  gap: 0.75em;
`;

export const DateAndTime = styled.div`
  display: flex;
  gap: 1em;
`;

export const Date = styled.div`
  display: flex;
  gap: 0.5em;

  svg {
    color: ${({ theme }) => theme.colors.neutral.lighterNavy};
  }
`;

export const Time = styled.div`
  display: flex;
  gap: 0.25em;
  align-items: center;

  svg {
    color: ${({ theme }) => theme.colors.neutral.lighterNavy};
  }
`;

export const CheckIcon = styled(CheckCircle).attrs({ type: 'solid' })`
  color: ${({ theme }) => theme.colors.green.darkGreen};
  margin-right: 0.5em;
`;

export const QuestionIcon = styled(CircleQuestion).attrs({ type: 'solid' })`
  color: ${({ theme }) => theme.colors.neutral.lighterNavy};
  margin-right: 0.5em;
`;

export const TodaysBadge = styled(Badge).attrs({ label: 'Today!', size: 'md', type: 'default' })`
  background-color: ${({ theme }) => theme.colors.yellow.primary};
  position: absolute;
  top: -10px;
  right: -12px;
  transform: rotate(5deg);
  transform-origin: top right;
  z-index: 1;

  @media ${({ theme }) => theme.queries.minDesktop} {
    display: inline-flex;
    position: static;
    top: auto;
    right: auto;
    transform: none;
    transform-origin: initial;
    margin-right: 0.5em;
  }
`;
