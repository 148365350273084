import React from 'react';

import { convertDateForm } from 'kb-shared/utilities/momentHelpers';
import { Message } from 'screens/Messages/Messages.type';

import { Container } from './DateSeparator.styled';

export const DateSeparator = ({
  previousMessage,
  currentMessage
}: {
  previousMessage?: Message;
  currentMessage: Message;
}) => {
  const previousMessageDate = previousMessage ? convertDateForm(previousMessage.createdAt) : null;
  const currentMessageDate = convertDateForm(currentMessage.createdAt);

  if (previousMessageDate === currentMessageDate) return null;

  const formattedDate = convertDateForm(currentMessage.createdAt, 'ddd DD, MMM YYYY');

  return <Container>{formattedDate}</Container>;
};
