import { useQuery } from '@apollo/client';
import React, { ReactNode } from 'react';

import { Heading } from 'components/v2/Typography';
import { usePatient } from 'hooks';
import { Appointment } from 'kb-shared';
import { PATIENT_APPOINTMENTS } from 'kb-shared/graphql/queries';
import { BugTracker } from 'kb-shared/utilities/bugTracker';
import { ResponsiveContainer } from 'screens/styled_common';

import { UpcomingAppointment } from '../../components/UpcomingAppointment';
import { Column, Row } from '../../styled/Grid';
import { AppointmentSection, H3 } from './Appointments.styled';
import { PatientAppointmentsResponse } from './Appointments.types';
import { getFutureAppointments, getPastAppointments } from './Appointments.utils';
import ActionBanner from './components/ActionBanner';

export const Appointments: React.FC = () => {
  const { patient } = usePatient();
  const { data } = useQuery<PatientAppointmentsResponse>(PATIENT_APPOINTMENTS, {
    onError: error => BugTracker.notify(error, 'PatientAppointments-Appointments.tsx')
  });

  const patientMembership = patient?.patientMembership;

  const scheduledAppointments = getFutureAppointments(data);
  const pastAppointments = getPastAppointments(data);

  const renderAppointments = (appointments: Appointment[]): ReactNode | null => {
    return appointments.map(appointment => (
      <Column key={appointment.id} mobile={12} tablet={6} desktop={4}>
        <UpcomingAppointment
          appointment={appointment}
          key={`dashboard-appointment-row-${appointment.id}`}
          timeSlot={appointment}
          type={appointment.appointmentType}
          isConfirmation={false}
          onAppointmentCancelled={() => window.location.reload()}
          hasStepper
          patient={patient}
        />
      </Column>
    ));
  };

  const numAppointmentsBooked = scheduledAppointments && scheduledAppointments.length;
  const numPastAppointments = pastAppointments && pastAppointments.length;

  const hasEmployerMembership =
    patientMembership && patientMembership.employer && patientMembership.membershipId;

  return (
    <ResponsiveContainer paddedContent>
      <Heading>Appointments {!hasEmployerMembership ? '& Packages' : ''}</Heading>

      <ActionBanner />
      {!!scheduledAppointments && !!numAppointmentsBooked && (
        <AppointmentSection>
          <H3>
            {numAppointmentsBooked} upcoming appointment{numAppointmentsBooked > 1 && 's'}:
          </H3>
          <Row>{renderAppointments(scheduledAppointments)}</Row>
        </AppointmentSection>
      )}
      {!!pastAppointments && !!numPastAppointments && (
        <AppointmentSection>
          <H3>
            {numPastAppointments} past appointment{numPastAppointments > 1 && 's'}:
          </H3>
          <Row>{renderAppointments(pastAppointments)}</Row>
        </AppointmentSection>
      )}
    </ResponsiveContainer>
  );
};
