import React, { ReactNode } from 'react';

import styled from 'styled-components';

import StepDetail from './StepDetail';
interface Props {
  description: ReactNode;
}

const StepDescription = ({ description }: Props) => {
  return (
    <StepDetail>
      <FlexContainer>
        <Description>{description}</Description>
      </FlexContainer>
    </StepDetail>
  );
};

export default StepDescription;

const FlexContainer = styled.div`
  display: flex;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.smallDesktop}) {
    flex-wrap: wrap;
  }
`;

const Description = styled.div`
  p {
    margin-top: 20px;
  }
  p:first-of-type {
    margin-top: 0;
  }
`;
