import React from 'react';

import { V2_COMPONENT_CLASS_NAME } from 'components/v2/common.styled';
import { getInputStates } from 'components/v2/utils';

import { MaskedInput, RegularInput } from './ZipInput.styled';
import { Props } from './ZipInput.types';

export const ZipInput = ({
  id,
  labelId,
  helperTextId,
  status = 'default',
  required,
  placeholder,
  spellCheck,
  autoComplete,
  value,
  onChange,
  onBlur,
  onFocus,
  onKeyPress,
  zipType = 'US'
}: Props) => {
  const isFilled = Boolean(value);
  const { isDisabled, isValid, isReadOnly } = getInputStates(status);

  return (
    <>
      {zipType === 'US' && (
        <MaskedInput
          isFilled={isFilled}
          className={V2_COMPONENT_CLASS_NAME}
          status={status}
          mask="11111"
          id={id}
          placeholder={placeholder}
          type="text"
          disabled={isDisabled}
          required={required}
          spellCheck={spellCheck}
          autoComplete={autoComplete}
          value={value}
          onChange={onChange}
          onBlur={onBlur}
          onKeyPress={onKeyPress}
          onFocus={onFocus}
          aria-labelledby={labelId}
          aria-disabled={isDisabled}
          aria-invalid={isValid}
          aria-readonly={isReadOnly}
          aria-errormessage={helperTextId}
        />
      )}

      {zipType === 'other' && (
        <RegularInput
          isFilled={isFilled}
          className={V2_COMPONENT_CLASS_NAME}
          status={status}
          id={id}
          placeholder={placeholder}
          type="text"
          disabled={isDisabled}
          required={required}
          spellCheck={spellCheck}
          autoComplete={autoComplete}
          value={value}
          onChange={onChange}
          onBlur={onBlur}
          onKeyPress={onKeyPress}
          onFocus={onFocus}
          aria-labelledby={labelId}
          aria-disabled={isDisabled}
          aria-invalid={isValid}
          aria-readonly={isReadOnly}
          aria-errormessage={helperTextId}
        />
      )}
    </>
  );
};
