import React from 'react';

import { Alert } from 'components/v2/Alert/Alert';
import KBContacts from 'kb-shared/utilities/kindbody_contacts';

import { ErrorContainer } from './InvoicesError.styled';

export const InvoicesError = () => (
  <ErrorContainer>
    <Alert
      type="error"
      title="Oops! We couldn't retrieve your invoices at the moment"
      message={<>{INVOICES_ERROR_MESSAGE}</>}
    />
  </ErrorContainer>
);

const INVOICES_ERROR_MESSAGE = `Invoices currently can't be displayed. Please reload the page or contact us at ${KBContacts.navigatorEmail}.`;
