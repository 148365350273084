function calculateMiddlePosition(value: number, low: number, high: number): number {
  const factor = (value - low) / (high - low);
  return (0.33 * factor + 0.33) * 100;
}

function calculatePointerPosition(value: number, low: number, high: number): number {
  const minimumPosition = 5;
  const lowPosition = 33;
  const highPosition = 66;
  const maximumPosition = 90;

  if (value <= low) {
    return Math.max(minimumPosition, (value * lowPosition) / low);
  } else if (value >= high) {
    return Math.min(maximumPosition, (value * highPosition) / high);
  }

  return calculateMiddlePosition(value, low, high);
}

export { calculatePointerPosition };
