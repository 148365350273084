import { useMutation } from '@apollo/client';

import { CREATE_PARTNER_INVITE } from './useCreatePartnerInvite.graphql';

export const useCreatePartnerInvite = () => {
  const [createPartnerInviteMutation, { data, loading, error }] = useMutation(
    CREATE_PARTNER_INVITE
  );

  const createPartnerInvite = async (
    email: string,
    birthDate: string,
    firstName: string,
    lastName: string
  ) =>
    createPartnerInviteMutation({
      variables: {
        email,
        birthDate,
        firstName,
        lastName
      }
    });

  return { createPartnerInvite, data, loading, error };
};
