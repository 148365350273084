import React, { useState } from 'react';

import { Text } from 'components/v2/Typography';
import { themes } from 'kb-shared';

import { TooltipContainer, TooltipBox, TooltipArrow } from './Tooltip.styled';
import { TooltipProps } from './Tooltip.types';

export const Tooltip: React.FC<TooltipProps> = ({
  displayTooltip,
  tooltip,
  children,
  position = 'top',
  backgroundColor = themes.colors.neutral.primaryNavy,
  textColor = themes.colors.white,
  textSize,
  fontStyle,
  minWidth
}) => {
  const [hover, setHover] = useState(false);

  return (
    <TooltipContainer onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)}>
      {children}
      {hover && displayTooltip && tooltip && (
        <TooltipBox position={position} backgroundColor={backgroundColor} minWidth={minWidth}>
          <Text color={textColor} size={textSize} fontStyle={fontStyle}>
            {tooltip}
          </Text>
          <TooltipArrow position={position} borderColor={backgroundColor} />
        </TooltipBox>
      )}
    </TooltipContainer>
  );
};
