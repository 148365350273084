import { useMutation } from '@apollo/client';
import React, { useState } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';

import { TextField } from 'components/v2/Form';
import { StepTrackerLayout } from 'components/v2/Layouts/StepTrackerLayout/StepTrackerLayout';
import { ActionButtons } from 'screens/ActivateMembership/components/ActionButtons/ActionButtons';
import { Collate } from 'screens/ActivateMembership/components/Collate/Collate';
import { CollateType } from 'screens/ActivateMembership/components/Collate/Collate.types';
import { pageUrl, RoutesPath } from 'utilities/pageUrl';

import { VALIDATE_PATIENT_ENTERPRISE_MEMBERSHIP } from './UniqueIdScreen.graphql';
import { Bullets, CollateContainer, Container, Form, LinkWrapper } from './UniqueIdScreen.styled';
import { ValidateMembershipResponse, ValidateMembershipVariables } from './UniqueIdScreen.types';

export const UniqueIdScreen = () => {
  const history = useHistory();
  const location = useLocation();
  const [uniqueId, setUniqueId] = useState('');
  const [uniqueIdValidationFailed, setUniqueIdValidationFailed] = useState(false);
  const { code } = location?.state ?? { code: null };
  const { fnLnDobValidationFailed } = location?.state ?? { fnLnDobValidationFailed: false };

  const [validateMembership, { loading }] = useMutation<
    ValidateMembershipResponse,
    ValidateMembershipVariables
  >(VALIDATE_PATIENT_ENTERPRISE_MEMBERSHIP, {
    onError: () => setUniqueIdValidationFailed(true),
    onCompleted: () =>
      history.push(RoutesPath.CONFIRM_MEMBERSHIP_ACTIVATION, {
        code: code,
        uniqueId: uniqueId
      })
  });

  if (!code) {
    history.replace(RoutesPath.ENTER_MEMBERSHIP_ACCESS_CODE);
  }

  const onValidateMembership = async () => {
    validateMembership({
      variables: {
        employerAccessCode: code,
        employeeAccessCode: uniqueId
      }
    });
  };

  return (
    <StepTrackerLayout
      title={'Verify my Eligibility'}
      stepNames={['Services', 'Location', 'Activate']}
      selectedStep={2}
      renderMainContent={() => {
        return (
          <Container>
            {fnLnDobValidationFailed && !uniqueIdValidationFailed && (
              <CollateContainer>
                <Collate
                  type={CollateType.ERROR}
                  title="We need additional information to verify your eligibility."
                  description="We kindly request you input your Unique User ID as provided by your employer in the
                designated field below. Thank you for your cooperation."
                />
              </CollateContainer>
            )}
            {uniqueIdValidationFailed && (
              <CollateContainer>
                <Collate
                  type={CollateType.ERROR}
                  title="Unfortunately, we couldn't verify your eligibility due to the following reasons:"
                  description=""
                  additionalDescription={
                    <Bullets>
                      <li>The Employee Access Code (Unique ID) you entered is invalid</li>
                      <li>
                        You haven&apos;t been granted eligibility by your employer yet. Please
                        contact our Benefits Team for further assistance{' '}
                        <Link to={{ pathname: pageUrl.messages({ categoryName: 'benefits' }) }}>
                          <LinkWrapper>here</LinkWrapper>
                        </Link>
                      </li>
                    </Bullets>
                  }
                />
              </CollateContainer>
            )}
            <Form>
              <TextField
                type="text"
                status="default"
                id="input-unique-id"
                placeholder="Enter your unique ID"
                label="UNIQUE ID"
                value={uniqueId}
                onChange={event => setUniqueId(event.currentTarget.value)}
              />
            </Form>
            <ActionButtons
              onBack={() => history.goBack()}
              onForward={onValidateMembership}
              forwardDisabled={!uniqueId || loading}
              forwardText="Validate"
            />
          </Container>
        );
      }}
    />
  );
};

export default UniqueIdScreen;
