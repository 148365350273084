import styled from 'styled-components';

export const Body = styled('div')`
  h1,
  h2,
  h3 {
  }

  h1 {
    font-size: 48px;
    text-align: center;
    width: 100%;
  }

  h2 {
    font-size: 36px;
  }

  h3 {
    font-size: 24px;
  }

  ul {
    list-style-type: disc;
    padding-inline-start: 40px;
  }

  p {
    margin-block-start: 1em;
    margin-block-end: 1em;
  }

  a,
  p,
  li {
  }

  li {
    padding-bottom: 5px;
  }

  table {
    border-collapse: collapse;
    width: 100%;
  }

  table,
  th,
  td {
    border: 1px solid black;
    padding: 8px;
    text-align: left;
  }

  display: inline-block;
  word-wrap: break-word;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tabletPortrait}) {
    max-width: 100%;
    h1 {
      font-size: 32px;
    }

    h2 {
      font-size: 20px;
    }

    h3 {
      font-size: 14px;
    }

    ul {
      padding-inline-start: 14px;
    }

    p {
      font-size: 14px;
      max-width: 100%;
    }

    table {
      max-width: 100%;
      table-layout: fixed;
    }

    table td {
      font-size: 10px;
      word-wrap: break-word;
      hyphens: auto;
    }

    li {
      font-size: 14px;
    }
  }
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: center;
  justify-content: center;
  background-color: white;
  width: 100%;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 1;
  width: 90%;
`;

export const Divider = styled.div`
  border: solid 1px black;
  width: 100%;
  margin: 48px 0px 48px 0px;
`;

export const ConsentText = styled.div`
  font-size: 24px;
  text-align: center;
  padding: 0px 24px 48px 24px;
  max-width: 646px;
`;
