import React, { useState } from 'react';

import { Checkbox } from 'components/v2/Inputs';
import {
  EnterpriseTermsBlock,
  TermsInnerContainer,
  CheckboxContainer,
  TextContainer,
  EmployeeAttestation,
  Separator,
  AcceptTermsWrapper
} from 'screens/ActivateMembership/components/ConfirmationBox/ConfirmationBox.styled';
import { Props } from 'screens/ActivateMembership/components/ConfirmationBox/ConfirmationBox.types';
import TermsLinks from 'screens/ActivateMembership/components/TermsLinks';

const ConfirmationBox = (props: Props) => {
  const { onTermsChecked } = props;
  const [termsChecked, setTermsChecked] = useState(false);

  const onCheckboxSelected = () => {
    const checked = !termsChecked;
    setTermsChecked(checked);
    onTermsChecked(checked);
  };

  return (
    <AcceptTermsWrapper>
      <EnterpriseTermsBlock>
        <TermsInnerContainer>
          <CheckboxContainer>
            <Checkbox
              useAlternateColor={true}
              size="large"
              isChecked={termsChecked}
              onChange={onCheckboxSelected}
              label="I authorize Kindbody to send me secure communications, which may include my medical information. Kindbody only sends unencrypted text messages for appointment reminders and portal notifications. I have also read and acknowledged the employee or spouse/partner attestation below."
              id="agree-box"
            />
          </CheckboxContainer>
        </TermsInnerContainer>
        <EmployeeAttestation onClick={onCheckboxSelected}>
          <p>Employee or Spouse/Partner Attestation:</p>
          <p>
            If I am an employee, I understand my employer offers the Kindbody family-building
            benefit and I am eligible to participate in this benefit so long as I am enrolled in my
            employer&apos;s group medical plan or I am enrolled in another employer&apos;s group
            medical plan that provides “minimum value” coverage pursuant to Internal Revenue Code
            Section 36B(c)(2)(C)(ii). Typically, if I am enrolled in my spouse or partner&apos;s
            group medical plan, that plan meets the definition above and I can check with the plan
            to confirm. By clicking the &lsquo;Submit&rsquo; button below, I hereby attest that I am
            either enrolled in my employer&apos;s group medical plan or I am enrolled in a
            qualifying group medical plan (e.g., through my spouse or partner). OR
          </p>
          <p>
            If I am a spouse/partner, I understand my spouse or partner&apos;s employer offers the
            Kindbody family-building benefit. I am either eligible to enroll in the Kindbody benefit
            because of: (i) my enrollment in my spouse or partner&apos;s medical plan, or (ii) if I
            am <u>not</u> enrolled in my spouse or partner&apos;s medical plan, I am enrolled in
            another group health plan that provides “minimum value” coverage pursuant to Internal
            Revenue Code Section 36B(c)(2)(C)(ii). Typically, if I am enrolled in my employer&apos;s
            group medical plan, that plan meets the definition above and I can check with the plan
            to confirm. By clicking the &lsquo;Submit&rsquo; button below, I hereby attest that I am
            either enrolled in my employer&apos;s group medical plan or I am enrolled in a
            qualifying group medical plan (e.g., through my spouse or partner).
          </p>
          <p>
            <i>
              Note: This attestation does not apply if I am <u>only</u> enrolling in the Kindbody
              third party or adoption services benefit.
            </i>
          </p>
        </EmployeeAttestation>
        <Separator />
        <TextContainer>
          <TermsLinks />
        </TextContainer>
      </EnterpriseTermsBlock>
    </AcceptTermsWrapper>
  );
};

export default ConfirmationBox;
