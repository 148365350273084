import React, { Component, MouseEvent } from 'react';

import { themes } from 'kb-shared';
import styled from 'styled-components';

type Props = {
  text: string;
  onClick: (event: MouseEvent) => void;
};
type State = {};

export default class RoundedButton extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <Container onClick={this.props.onClick}>
        <div>{this.props.text}</div>
      </Container>
    );
  }
}

const Container = styled.a`
  margin-bottom: 100px;
  cursor: pointer;
  height: 48px;
  width: 240px;
  box-shadow: 0 2px 8px 0 #ababab;
  border-radius: 24px;
  background-color: ${themes.colors.yellow.primary};
  display: flex;
  align-items: center;
  justify-content: center;
  :hover {
    background-color: ${themes.colors.yellow.lighterYellow};
  }
`;
