import { gql } from '@apollo/client';

import { JourneyType } from 'kb-shared';

export interface MyJourneyQueryResponse {
  journeyType: JourneyType;
  cyclePlan: {
    id: number;
    finished?: string;
    expectedDeliveryDate?: string;
    deliveryDate?: string;
  };
  [key: string]: any;
}

export const MY_JOURNEY_QUERY = gql`
  query myJourney {
    journeyType

    appointments: journeyAppointments {
      id
      startTime
      endTime
      timeZone

      appointmentType {
        id
        channel
      }
    }

    results {
      afc: follicleCounts {
        date
      }
      labs {
        date
      }
    }

    patientTodos {
      id
      todoId
      title: name
      linkUrl
      buttonText
      description
      completed
    }

    cyclePlan {
      id
      finished
      expectedDeliveryDate
      deliveryDate
    }

    triggerInstruction {
      id
    }
  }
`;
