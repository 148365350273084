let KINDBODY_CONSENT_ID: string | null = null;
let KINDBODY_CONSENT_SEARCH: string | null = null;

export const setConsent = (consent: string | null) => {
  if (!consent) {
    KINDBODY_CONSENT_ID = null;
    return;
  }
  KINDBODY_CONSENT_ID = consent;
};

export const setConsentSearch = (search: string | null) => {
  if (!search) {
    KINDBODY_CONSENT_SEARCH = null;
    return;
  }
  KINDBODY_CONSENT_SEARCH = search;
};

export const getConsent = () => KINDBODY_CONSENT_ID;

export const getConsentSearch = () => KINDBODY_CONSENT_SEARCH;
