import { Text } from 'components/v2/Typography/Text';
import theme from 'kb-shared/theme';
import styled from 'styled-components';

export const Container = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(39, 42, 94, 0.32);
  // This z-index should be bigger than Message Header to avoid the header abode modal.
  z-index: 4000;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  @media (orientation: landscape) {
    padding: 16px;
  }
`;

export const Modal = styled.div`
  border-radius: 16px 16px 0 0;
  background: ${({ theme }) => theme.colors.neutral.lavenderWhite};
  display: inline-flex;
  padding: 16px 16px 80px 16px;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  max-width: 350px;

  @media (orientation: landscape) {
    overflow: auto;
    border-radius: 16px;
    padding: 16px;
    height: 100%;
  }

  @media ${({ theme }) => theme.queries.tablet} {
    max-width: 640px;
    min-height: 80%;
    padding: 32px 32px 80px 32px;
    width: 100%;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.smallDesktop}) {
    max-height: none;
    max-width: 560px;
    height: auto;
  }
`;

export const Categories = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
`;

export const Category = styled.li`
  display: flex;
  padding: 12px 16px;
  flex-direction: column;
  border-radius: 8px;
  background: #fff;
  box-shadow: 0px 0px 0px 1px rgba(18, 18, 18, 0.1), 0px 1px 1px 0px rgba(18, 18, 18, 0.1);
  display: grid;
  grid-template-columns: 48px 1fr;
  grid-template-rows: 25px;
  grid-template-areas:
    'icon name'
    'icon description';
  column-gap: 16px;
  cursor: pointer;

  :hover {
    background-color: ${({ theme }) => theme.colors.neutral.lavenderLight};
  }
`;

export const Icon = styled.div`
  grid-area: icon;
  align-self: center;
`;

export const CategoryName = styled(Text).attrs({ size: 'md', fontStyle: 'bold' })`
  grid-area: name;
  margin-right: 8px;
`;

export const CategoryDescription = styled(Text).attrs({
  size: 'sm',
  fontStyle: 'regular',
  color: theme.colors.neutral.lightNavy
})`
  grid-area: description;
`;

export const Bottom = styled.div`
  flex: 1;
  display: flex;
  align-items: flex-end;
  width: 100%;
`;
