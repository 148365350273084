import { handleActions } from 'redux-actions';

import { InitializationState } from 'kb-shared';

import * as actionTypes from './actionTypes';

const initialState: InitializationState = {
  googleAuth: false
};

const handlers = {
  [actionTypes.INITIALIZATION_GOOGLE_AUTH]: (state: InitializationState) => ({
    ...state,
    googleAuth: true
  })
};

export default handleActions(handlers, initialState);
