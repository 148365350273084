import styled from 'styled-components';

export const TextContainer = styled.div`
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  overflow-wrap: break-word;
`;

export const ShowMoreContainer = styled.div`
  cursor: pointer;
  margin-top: 2px;
  color: ${({ theme }) => theme.colors.blue.blue};
`;
