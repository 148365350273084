import styled from 'styled-components';

export const Container = styled.div`
  flex: 1;
  width: 100%;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const Link = styled.a`
  text-decoration: underline;
`;

export const SignInLinkContainer = styled.div`
  padding-top: 32px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;
