import React from 'react';

import { themes } from 'kb-shared';
import DefaultImage from 'kb-shared/images/default-image.png';
import styled from 'styled-components';

const { colors, deprecatedColors } = themes;

const Card = styled.div`
  display: flex;
  width: 30%;
  min-width: 355px;
  min-height: 180px;
  height: auto;
  margin: 0 0 40px 0;
  border-radius: 4px;
  border: 1px solid ${deprecatedColors.gray};
  @media screen and (max-width: ${themes.breakpoints.largePhonePortrait}) {
    margin-top: 60px;
  }
`;

const Content = styled.div`
  padding: 20px 10px;
  width: 60%;
`;

const Detail = styled.div`
  padding: 10px 10px;
`;

const Title = styled.div`
  font-size: 20px;
  color: ${colors.neutral.primaryNavy};
  padding-bottom: 8px;
`;

const Text = styled.div`
  z-index: 1;
  font-size: 14px;
  color: ${colors.neutral.primaryNavy};
`;

interface LocationImageProps {
  image: string | undefined;
}

const LocationImage = styled.div`
  width: 41%;
  background-size: cover;
  background-position: center;
  ${({ image }: LocationImageProps) => {
    return `
        background-image: url(${image});
      `;
  }}
`;

type Props = {
  appointmentTypeName: string;
  address: string;
  imageUrl: string | undefined;
  providerName?: string;
};

const AppointmentTimeCard = (props: Props) => {
  const { appointmentTypeName, address, imageUrl, providerName } = props;
  return (
    <Card>
      <Content>
        <Detail>
          <Title>Appointment</Title>
          <Text>{appointmentTypeName}</Text>
        </Detail>
        {providerName && (
          <Detail>
            <Title>With</Title>
            <Text>{providerName}</Text>
          </Detail>
        )}
        <Detail>
          <Title>Location</Title>
          <Text>{address}</Text>
        </Detail>
      </Content>
      <LocationImage image={imageUrl ? imageUrl : DefaultImage} />
    </Card>
  );
};

export default AppointmentTimeCard;
