import styled, { css } from 'styled-components';

export const Container = styled.footer<{ $hasColoredBackground: boolean }>`
  width: 100%;
  grid-area: footer;
  margin-top: auto;
  ${({ $hasColoredBackground }) =>
    $hasColoredBackground &&
    css`
      background: ${({ theme }) => theme.colors.neutral.lavenderWhite};
    `};

  ${({ $hasColoredBackground }) =>
    $hasColoredBackground &&
    css`
      background: ${({ theme }) => theme.colors.neutral.lavenderWhite};
    `};

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.tabletPortrait}) {
    display: flex;
    flex-direction: column;
    align-items: end;
  }
`;

export const Content = styled.div`
  padding: 60px 32px 16px 32px;
  height: 144px;
  width: 100%;
  z-index: ${({ theme }) => theme.layers.footer};
  position: relative;
  background-color: ${({ theme }) => theme.colors.neutral.lavenderLight};

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.tabletPortrait}) {
    padding: 32px 28px;
  }

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.smallDesktop}) {
    flex-direction: row;
    height: 160px;
  }
`;
