import { Appointment, AppointmentType } from './types';

export const isAppointmentType = (maybe: unknown): maybe is AppointmentType => {
  if (!maybe || typeof maybe !== 'object') return false;
  const hasKeys = maybe as Record<string, unknown>;
  const { id, name, description, rateCents, category } = hasKeys;
  return (
    id !== undefined &&
    name !== undefined &&
    description !== undefined &&
    rateCents !== undefined &&
    category !== undefined
  );
};

export const isAppointment = (maybe: unknown): maybe is Appointment => {
  if (!maybe || typeof maybe !== 'object') return false;
  const hasKeys = maybe as Record<string, unknown>;
  const {
    id,
    appointmentType,
    location,
    startTime,
    endTime,
    timeZone,
    cancelable,
    reschedulable
  } = hasKeys;
  return (
    id !== undefined &&
    appointmentType !== undefined &&
    location !== undefined &&
    startTime !== undefined &&
    endTime !== undefined &&
    timeZone !== undefined &&
    cancelable !== undefined &&
    reschedulable !== undefined
  );
};
