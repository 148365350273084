import React from 'react';
import { useHistory } from 'react-router-dom';

import { themes } from 'kb-shared';
import styled from 'styled-components';

import { typography } from '../../styled';
import AssistanceCard from './components/AssistanceCard';
import InstructionCard from './components/InstructionCard';
import medications from './medications';

const {
  serif: {
    page: { H1 }
  },
  sans: {
    copy: { Large }
  }
} = typography;
const { breakpoints } = themes;

const Container = styled.div`
  ${({ theme }) => theme.grid.pageContainer};
  margin-top: 100px;
  display: flex;
  flex-direction: column;
`;

const Title = styled(H1)`
  margin-bottom: 20px;
`;

const RowContainer = styled.div`
  display: grid;
  grid-gap: 20px 20px;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  grid-template-areas: '. . .' '. . .';
  margin-top: 30px;

  @media (max-width: ${breakpoints.tabletLandscape}) {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr 1fr;
    grid-template-areas: '. .' '. .' 'assistance assistance';

    > div {
      grid-area: assistance;
    }
  }

  @media (max-width: ${breakpoints.largePhonePortrait}) {
    display: flex;
    flex-direction: column;

    > button,
    > div {
      margin-bottom: 20px;
    }

    > div {
      margin-top: 20px;
    }
  }
`;

const MedicationInstructions = () => {
  const history = useHistory();

  return (
    <Container>
      <Title>Injection Tutorials</Title>
      <Large>
        Select a tutorial that corresponds to the medication(s) you have been prescribed:
      </Large>
      <RowContainer>
        {medications.map((med, i) => {
          return (
            <InstructionCard
              key={`tutorial-card-${med.id}`}
              instruction={med}
              onClick={() => {
                history.push(
                  `/medication-instruction?med_index=${i}&medication_name=${med.displayName}`
                );
              }}
            />
          );
        })}
        <AssistanceCard />
      </RowContainer>
    </Container>
  );
};

export default MedicationInstructions;
