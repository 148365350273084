import React from 'react';
import { Link } from 'react-router-dom';

import SelectInput from 'components/SelectInput';
import { KindbodyLogo } from 'layouts/MainLayout/Logo/KindBodyLogo';

import {
  Container,
  Wrapper,
  CompletedLabel,
  DashboardNavigation,
  TitleWrapper,
  Title,
  LogoAndProgressWrapper,
  SelectWrapper
} from './Header.styled';
import { Props } from './Header.types';

const Header = ({
  percentageComplete,
  currentSectionIndex,
  totalSections,
  sections,
  selectedSection,
  onSectionSelected
}: Props) => {
  return (
    <Container id="intake-form-header">
      <Wrapper>
        <LogoAndProgressWrapper>
          <KindbodyLogo />
          {percentageComplete ? (
            <CompletedLabel>
              {percentageComplete}% <span>Complete</span>
            </CompletedLabel>
          ) : (
            <></>
          )}
        </LogoAndProgressWrapper>
        <Link to="/">
          <DashboardNavigation>Go back to dashboard</DashboardNavigation>
        </Link>
      </Wrapper>
      <TitleWrapper>
        <Title>{`Section ${currentSectionIndex} of ${totalSections} - `}</Title>
        <SelectWrapper>
          <SelectInput
            label=""
            extractTitle={item => (item ? item.title : '')}
            placeholder={'Select'}
            selectedOption={selectedSection}
            onSelect={value => onSectionSelected(value)}
            options={sections}
            disableHeightLimit
          />
        </SelectWrapper>
      </TitleWrapper>
    </Container>
  );
};

export default Header;
