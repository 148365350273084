export const getGoogleIdToken: () => null | string = () => {
  return window?.sessionStorage?.getItem('@GoogleSSO:idToken');
};

export const getEpicSsoIdToken: () => null | string = () => {
  return window?.sessionStorage?.getItem('epicSsoIdToken');
};

export const getAppleSsoIdToken: () => null | string = () => {
  return window?.sessionStorage?.getItem('@AppleSSO:idToken');
};

export const waitUntilEpicSsoIdTokenIsSet: () => Promise<null> = async () => {
  return new Promise(function(resolve) {
    (function waitForEpic() {
      // @ts-ignore
      if (getEpicSsoIdToken()) return resolve();
      setTimeout(waitForEpic, 1000);
    })();
  });
};

export const getMedtronicSsoIdToken: () => null | string = () => {
  return window?.sessionStorage?.getItem('medtronicSsoIdToken');
};

export const getCognitoSsoIdToken: () => null | string = () => {
  return window?.sessionStorage?.getItem('cognitoSsoIdToken');
};

export const getCognitoAccessToken: () => null | string = () => {
  return window?.sessionStorage?.getItem('cognitoAccessToken');
};

export const waitUntilMedtronicSsoIdTokenIsSet: () => Promise<null> = async () => {
  return new Promise(function(resolve) {
    (function waitForMedtronic() {
      // @ts-ignore
      if (getMedtronicSsoIdToken()) return resolve();
      setTimeout(waitForMedtronic, 1000);
    })();
  });
};

export const getIdToken: () => null | string = () => {
  let idToken = getEpicSsoIdToken();
  if (idToken) return idToken;

  idToken = getMedtronicSsoIdToken();
  if (idToken) return idToken;

  idToken = getAppleSsoIdToken();
  if (idToken) return idToken;

  idToken = getGoogleIdToken();
  if (idToken) return idToken;

  idToken = getCognitoSsoIdToken();
  return idToken;
};

export const isEpicUser = () => {
  return !!getEpicSsoIdToken();
};

export const isMedtronicUser = () => {
  return !!getMedtronicSsoIdToken();
};

export const logout = async () => {
  if (!window || !window.sessionStorage) return;

  window.sessionStorage.removeItem('epicSsoIdToken');
  window.sessionStorage.removeItem('medtronicSsoIdToken');
  window.sessionStorage.removeItem('appleSsoIdToken');
  window.sessionStorage.removeItem('cognitoSsoIdToken');
  window.sessionStorage.removeItem('cognitoAccessToken');
  window.sessionStorage.removeItem('@GoogleSSO:idToken');
  window.sessionStorage.removeItem('@AppleSSO:idToken');
};
