// This is the default export for kb-shared
// but contains web-specific technology/modules
// such as styled-components and css

import grid from './grid';
import theme from './index';
import typos from './typos';

export default {
  ...theme,
  grid,
  typos
};
