import { Button } from 'components/v2/Buttons/Button/Button.styled';
import styled from 'styled-components';

export const Container = styled.div`
  padding: 12px 24px 24px 24px;
  background-color: ${({ theme }) => theme.colors.neutral.lavenderWhite};
  border-radius: 8px;
}`;

export const Columns = styled.div`
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 12px;

  ${Button} {
    white-space: nowrap;
    min-width: fit-content;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.tabletLandscape}) {
    grid-template-columns: repeat(2, minmax(0, 1fr));
    align-items: center;
    gap: 8px;

    ${Button} {
      grid-column: span 2;

      svg {
        display: none;
      }
    }
  }
`;
