import React from 'react';

import SubstepWrapper from '../../SubstepWrapper';
import { Description, Title } from './styled-components';

const PostpartumVirtualVisit = () => (
  <>
    <Title>Postpartum Virtual Visit (PostPartum Depression Screening)</Title>
    <Description>
      Your provider will check to see how you are physically feeling, and address any questions or
      issues you are having. Your mental and emotional health will be a key component of this visit.
    </Description>
  </>
);

const VirtualMentalHealthSession = () => (
  <>
    <Title>Virtual Mental Health Session (if needed)</Title>
    <Description>
      This visit focuses on your emotional health and adjustment to motherhood and how you are
      coping with having a new baby at home. You can discuss any anxiety, fears or any issues you
      are having.
    </Description>
  </>
);

const SUBSTEPS = [
  { component: <PostpartumVirtualVisit /> },
  { component: <VirtualMentalHealthSession /> }
];

const ObPostPartumWeek4Substeps = () => <SubstepWrapper substeps={SUBSTEPS} />;

export default ObPostPartumWeek4Substeps;
