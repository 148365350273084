import React from 'react';

import { YellowButton } from 'components/Buttons';
import { stripTimeAndFormat } from 'kb-shared/utilities/momentHelpers';
import CyclePlanSummary from 'screens/CyclePlanAcknowledge/components/CyclePlanSummary/CyclePlanSummary';

import { Container, Bottom, Divider } from './SignedCyclePlan.styled';
import { SignedCyclePlanProps } from './SignedCyclePlan.types';

const SignedCyclePlan: React.FC<SignedCyclePlanProps> = ({
  cyclePlans,
  patient,
  onBack
}: SignedCyclePlanProps) => {
  if (cyclePlans == null) return null;
  return (
    <Container>
      <CyclePlanSummary
        cyclePlans={cyclePlans}
        patient={[
          {
            fields: [
              {
                label: 'MRN',
                value: patient.id
              },
              {
                label: 'Name',
                value: `${patient.firstName} ${patient.lastName}`
              },
              {
                label: 'Birthday',
                value: patient.birthday ? stripTimeAndFormat(patient.birthday, 'MM/DD/YYYY') : ''
              },
              {
                label: 'Cycle Type',
                value: cyclePlans.length === 0 ? '' : cyclePlans[0].cycle_type
              }
            ]
          }
        ]}
      />

      <Divider />

      <Bottom>
        <YellowButton onClick={onBack}>Back to documents</YellowButton>
      </Bottom>
    </Container>
  );
};

export default SignedCyclePlan;
