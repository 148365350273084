import { gql } from '@apollo/client';

import { CareTeamPeople, PatientCareLocation } from './CareTeam.types';

export interface CareTeamResponse {
  careTeam: CareTeamPeople;
  patientPharmacy: PatientCareLocation;
  patientLabCenter: PatientCareLocation;
  patientRadiologyCenter: PatientCareLocation;
}

export const CARE_TEAM_QUERY = gql`
  query careTeam {
    careTeam {
      clinicNurse
      cloudNurse
      doctor
    }
    patientPharmacy {
      name
      address
      city
      state
      zipcode
      phone
    }
    patientLabCenter {
      name
      address
      city
      state
      zipcode
      phone
    }
    patientRadiologyCenter {
      name
      address
      city
      state
      zipcode
      phone
    }
  }
`;
