import styled from 'styled-components';

export const PaymentDetailsCreditCard = styled.div`
  display: flex;
  gap: 20px;
  align-items: center;
  flex-wrap: wrap;

  > div {
    flex: 1;
  }

  > button {
    margin-top: 20px;
    padding: 20px 32px;
  }

  & > div:first-child {
    margin-bottom: 4px;
  }

  @media ${({ theme }) => theme.queries.maxTablet} {
    flex-direction: column;
    align-items: stretch;
    gap: 8px;

    > button {
      flex: 1;
      margin-top: 0;
    }
  }
`;

export const BaseCardContainer = styled.label`
  font-size: 14px;
  border-bottom: 1px solid;
`;

export const CardNumberContainer = styled(BaseCardContainer)`
  flex: 3;

  @media ${({ theme }) => theme.queries.maxTablet} {
    flex-basis: 100%;
  }
`;

export const ExpirationAndCvcContainer = styled.div`
  display: flex;
  gap: 20px;
  width: 100%;

  @media ${({ theme }) => theme.queries.maxTablet} {
    flex-direction: row;
    gap: 8px;
  }
`;

export const CardExpirationContainer = styled(BaseCardContainer)`
  flex: 1;
`;

export const CardCvcContainer = styled(BaseCardContainer)`
  flex: 1;
`;
