export enum CodeType {
  WIN = 'WIN',
  BID = 'benefitId'
}

export type createPatientWalmartDetailResult = {
  createPatientWalmartDetail: {
    benefitsProfiles: {
      bid: string;
      firstName: string;
      lastName: string;
      dateOfBirth: string;
    };
  };
};
