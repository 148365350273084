import { Text } from 'components/v2/Typography/Text';
import styled, { css } from 'styled-components';

export type BadgeTypes = 'default' | 'error' | 'info' | 'success' | 'neutral';

export const Container = styled(Text).attrs({ fontStyle: 'bold' })<{
  type: BadgeTypes;
  size: 'xs' | 'sm' | 'md';
}>`
  display: flex;
  padding: ${({ size }) => (size === 'md' ? '2px 16px' : '0 8px')};
  border-radius: 16px;
  align-items: center;

  ${({ type }) => getColors(type)};
`;

const getColors = (type: BadgeTypes) => {
  switch (type) {
    case 'default':
      return css`
        background: ${({ theme }) => theme.colors.neutral.lavenderLight};
        color: ${({ theme }) => theme.colors.neutral.primaryNavy};
      `;
    case 'error':
      return css`
        background: ${({ theme }) => theme.colors.semantic.darkRed};
        color: ${({ theme }) => theme.colors.white};
      `;
    case 'info':
      return css`
        background: ${({ theme }) => theme.colors.blue.darkBlue};
        color: ${({ theme }) => theme.colors.white};
      `;
    case 'success':
      return css`
        background: ${({ theme }) => theme.colors.green.lightestGreen};
        color: ${({ theme }) => theme.colors.green.darkestGreen};
      `;
    case 'neutral':
      return css`
        background: ${({ theme }) => theme.colors.neutral.lavenderLight};
        color: ${({ theme }) => theme.colors.neutral.primaryNavy};
      `;
  }
};
