import React from 'react';

import styled from 'styled-components';

import placeholderSrc from '../assets/images/player_placeholder.png';

const Container = styled.div`
  height: 100%;
  margin: 0 auto;
  width: 100%;
`;

const Inner = styled.div`
  position: relative;
`;

// We use a little hack here to make sure
// we always get a 16:9 ratio with this component
// This is a transparent image that's 16x9
const PlayerPlaceholder = styled.img`
  display: block;
  height: auto;
  width: 100%;
`;

const Player = styled.iframe`
  height: 100%;
  left: 0;
  position: absolute;
  top: 0%;
  width: 100%;
`;

type Props = {
  videoHash: string;
};

const VideoPlayer = (props: Props) => {
  const { videoHash } = props;
  const videoSrc = `https://www.youtube.com/embed/${videoHash}?autoplay=0&rel=0&modestbranding=1`;

  return (
    <Container>
      <Inner>
        <PlayerPlaceholder src={placeholderSrc} />
        <Player
          src={videoSrc}
          frameBorder="0"
          allow="fullscreen" // for Chrome
          allowFullScreen={true} // for Safari
        />
      </Inner>
    </Container>
  );
};

export default VideoPlayer;
