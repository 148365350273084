import React from 'react';

import SubstepWrapper from '../../SubstepWrapper';
import { Description, Title } from './styled-components';

const ReturnOBAppointment = () => (
  <>
    <Title>Return OB Appointment</Title>
    <Description>
      This appointment includes a visit with your provider, an ultrasound & cervix check.
    </Description>
    <Description>
      <strong>Cervix Check:</strong> Your provider will check the dilation of your cervix. The
      cervix dilates during labor and in some women can start to dilate days or weeks before
      delivery.
    </Description>
  </>
);

const DoulaLactationConsultation = () => (
  <>
    <Title>Doula and Lactation Consultation</Title>
    <Description>
      You and your Doula will meet to prepare for labor and delivery based on your risk factors and
      anticipated mode of delivery. You will also have a virtual or in-person prenatal lactation
      session with an International Board Certified Lactation Consultant®.
    </Description>
  </>
);

const SUBSTEPS = [
  { component: <ReturnOBAppointment /> },
  { component: <DoulaLactationConsultation /> }
];

const ObWeek38Substeps = () => <SubstepWrapper substeps={SUBSTEPS} />;

export default ObWeek38Substeps;
