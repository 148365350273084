import React from 'react';

import {
  Wrapper,
  Header,
  TitleLarge,
  TitleMedium,
  MessageWrapper,
  Message,
  Body,
  TextContent,
  ActionsContainer,
  IconInfo,
  IconCheckmark,
  IconWarning,
  IconExclamation
} from './Alert.styled';
import { Props } from './Alert.types';

export const Alert: React.FC<Props> = ({
  className,
  type,
  title,
  message,
  actions,
  noShadow = false,
  size = 'lg'
}) => {
  const iconNode = (
    <>
      {type === 'info' && <IconInfo size={size} />}
      {type === 'success' && <IconCheckmark size={size} />}
      {type === 'warning' && <IconWarning size={size} />}
      {type === 'error' && <IconExclamation size={size} />}
    </>
  );

  return (
    <Wrapper className={className} $type={type} $noShadow={noShadow}>
      <Body $size={size}>
        <TextContent $size={size}>
          {title && (
            // show icon alongside title if title is present
            <Header $size={size}>
              {iconNode}
              {size === 'lg' && <TitleLarge>{title}</TitleLarge>}
              {size === 'md' && <TitleMedium>{title}</TitleMedium>}
            </Header>
          )}
          <MessageWrapper $size={size}>
            {// show icon alongside message if no title is present
            !title && iconNode}
            <Message>{message}</Message>
          </MessageWrapper>
        </TextContent>

        {actions && <ActionsContainer>{actions}</ActionsContainer>}
      </Body>
    </Wrapper>
  );
};
