import { allCountries, defaultCountry } from './CountrySelect.constants';
import { Country, Option } from './CountrySelect.types';

export const defaultGetOption = (country: Country): Option => ({
  label: `${country.flag} ${country.name}`,
  value: country.code
});

export const getCountryByCode = (code: string): Country | undefined =>
  allCountries.find(country => country.code === code);

export const getFlagByCountryCode = (code: string): string =>
  getCountryByCode(code)?.flag || defaultCountry.flag;

export const getPhonePrefixByCountryCode = (code: string): string =>
  getCountryByCode(code)?.phonePrefix || defaultCountry.phonePrefix;

export const optionFormatter = (country: Country): Option => ({
  label: `${country.flag} ${country.name}`,
  value: country.code
});

export const getOptions = (
  countries: ReadonlyArray<Country>,
  optionFormatter?: (country: Country) => Option
): Array<Option> => {
  if (optionFormatter) {
    return countries.map(optionFormatter);
  }

  return countries.map(defaultGetOption);
};

/**
 * Replaces all special characters with their escaped version
 * Meant for use with user provided string that ends up in new RegExp()
 */
export const escapeRegex = (input: string) => input.replace(/[/\-\\^$*+?.()|[\]{}]/g, '\\$&');
