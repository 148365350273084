import { themes } from 'kb-shared';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

export const SubTitle = styled.div`
  font-size: 18px;
  padding: 10px 0px;
`;

export const Error = styled.div`
  font-size: 18px;
  padding: 10px 0px;
  color: ${themes.colors.semantic.darkRed};
`;
