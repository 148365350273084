export const GENDER_INPUT_DEFAULTS = [
  { label: 'male', value: 'male' },
  { label: 'female', value: 'female' }
];

export const GENDER_IDENTITY_DEFAULTS = [
  { label: 'male', value: 'male' },
  { label: 'female', value: 'female' },
  { label: 'transgender male', value: 'female_to_male' },
  { label: 'transgender female', value: 'male_to_female' },
  { label: 'gender nonconforming', value: 'non_conforming' },
  { label: 'genderqueer', value: 'genderqueer' },
  { label: 'other', value: 'other' },
  { label: 'prefer not to answer', value: 'not_answer' }
];

export const PRONOUN_INPUT_DEFAULTS = [
  { label: 'he/him', value: 'he' },
  { label: 'she/her', value: 'she' },
  { label: 'they/them', value: 'they' },
  { label: 'other', value: 'other' }
];
