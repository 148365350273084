import React from 'react';

import { Text } from 'components/v2/Typography';
import theme from 'kb-shared/theme';
import { formatPhoneNumber } from 'kb-shared/utilities/phones';

import { PatientCareLocation } from '../CareTeam.types';
import { getFormattedAddress } from '../CareTeam.utils';
import { DetailsWrapper, LocationDetailsWrapper } from '../styled';

export const PreferredLocation = ({
  type,
  location
}: {
  type: string;
  location: PatientCareLocation;
}) => {
  const { name, phone, address, city, state, zipcode } = location;

  return (
    <DetailsWrapper>
      <Text size="sm" color={theme.colors.neutral.primaryNavy} transform="uppercase">
        {type}
      </Text>

      <LocationDetailsWrapper>
        <Text fontStyle="semibold">{name}</Text>
        <Text size="sm">{getFormattedAddress(address, city, state, zipcode)}</Text>
        <a href={`tel:${phone}`}>
          <Text size="sm" color={theme.colors.blue.darkBlue}>
            {formatPhoneNumber(phone)}
          </Text>
        </a>
      </LocationDetailsWrapper>
    </DetailsWrapper>
  );
};
