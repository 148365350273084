import styled from 'styled-components';

export const CardContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 0 16px 0;
`;

export const Title = styled.label`
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  text-transform: uppercase;
  color: ${({ theme }) => theme.colors.neutral.navy};
  margin: 0 0 8px 0;
`;

export const Subtitle = styled.label`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: ${({ theme }) => theme.colors.neutral.navy};
  margin: 0 0 8px 0;
`;

export const Description = styled.label`
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: ${({ theme }) => theme.colors.neutral.navy};
  margin: 0 0 8px 0;
`;
