import React from 'react';

import { themes, utils } from 'kb-shared';
import styled from 'styled-components';

import { ReactComponent as CareTeamContact } from '../../../assets/icons/care-team-contact.svg';
import { typography } from '../../../styled';

const {
  serif: {
    card: { H2 }
  }
} = typography;
const { colors } = themes;

const Container = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
`;

const CareTeamContactIcon = styled(CareTeamContact)`
  height: 75px;
  object-fit: contain;
  width: 81px;
`;

const Link = styled.a`
  color: ${colors.neutral.primaryNavy};
  padding: 10px 20px;
  border: 1px solid ${colors.neutral.navy};
  border-radius: 18px;
  background: transparent;
  display: block;
  font-size: 14px;
  text-transform: uppercase;
  text-decoration: none;
  cursor: pointer;

  :active,
  :focus {
    font-weight: normal;
    text-decoration: none;
  }

  :hover,
  :focus {
    outline: none;
    background: ${colors.white};
  }
`;

const AssistanceCard = () => {
  return (
    <Container>
      <CareTeamContactIcon />
      <H2 style={{ margin: '25px 0' }}>Need further assistance?</H2>
      <Link
        href={`mailto:${utils.KBContacts.navigatorEmail}?subject=Kindbody Medication Instructions`}
      >
        CONTACT US
      </Link>
    </Container>
  );
};

export default AssistanceCard;
