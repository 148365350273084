import { RelationshipOption, RelationshipToInsured } from './InsuranceForm.types';

export const RELATIONSHIP_TO_INSURED = [
  { label: 'Self', value: 'self' },
  { label: 'Spouse', value: 'spouse' },
  { label: 'Child', value: 'child' },
  { label: 'Partner', value: 'partner' },
  { label: 'Other', value: 'unknown' }
];

export enum COB_PRIORITY_CODE {
  PRIMARY = 'primary',
  SECONDARY = 'secondary',
  TERTIARY = 'tertiary'
}

export const getDefaultRelationShipToInsured = (
  realtionship?: RelationshipToInsured
): RelationshipOption => {
  if (!realtionship) {
    return RELATIONSHIP_TO_INSURED.find(i => i.value === 'self') as RelationshipOption;
  }

  return RELATIONSHIP_TO_INSURED.find(i => i.value === realtionship) as RelationshipOption;
};
