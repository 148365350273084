import { Heading } from 'components/v2/Typography';
import styled from 'styled-components';

import { ContainerProps } from './YourUploads.types';

export const Container = styled.div`
  width: 100%;
  margin-bottom: ${({ hasItems }: ContainerProps) => (hasItems ? '0' : '10px')};
`;

export const UploadContent = styled.div`
  border-radius: 8px;
  border: 1px dashed ${({ theme }) => theme.colors.neutral.lighterNavy};
  background: ${({ theme }) => theme.colors.neutral.lavenderLight};
  display: flex;
  align-items: center;
  padding: 0.5em;
  margin-bottom: 1.66em;
  flex-direction: row;
  gap: 1em;
  position: relative;

  @media (min-width: ${({ theme }) => theme.breakpoints.smallDesktop}) {
    gap: 2em;
    padding: 1.5em;
  }
`;

export const FileUploadContainer = styled.div`
  border-radius: 8px;
  border: 1px dashed ${({ theme }) => theme.colors.neutral.lighterNavy};
  background: ${({ theme }) => theme.colors.neutral.lavender};

  padding: 2em;

  svg {
    font-size: 24px;
  }
`;

export const FileUpload = styled.input`
  cursor: pointer;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
`;

export const MobileUpload = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.33em;

  @media (min-width: ${({ theme }) => theme.breakpoints.smallDesktop}) {
    display: none;
  }
`;

export const DesktopUpload = styled.div`
  display: none;

  @media (min-width: ${({ theme }) => theme.breakpoints.smallDesktop}) {
    display: flex;
    flex-direction: column;
    gap: 0.33em;
  }
`;

export const Title = styled(Heading).attrs({ tag: 'div', styledAs: 'h4' })`
  display: none;

  @media (min-width: ${({ theme }) => theme.breakpoints.smallDesktop}) {
    display: block;
  }
`;
