import { themes } from 'kb-shared';
import styled from 'styled-components';
const { colors } = themes;

interface OutlineButtonProps {
  small?: boolean;
}

export default styled.button`
  background: transparent;
  border-radius: 48px;
  border: 2px solid ${themes.colors.neutral.primaryNavy};
  color: ${colors.neutral.primaryNavy};
  cursor: pointer;
  font-size: 14px;
  padding: ${({ small }: OutlineButtonProps) => (small ? '9px 24px;' : '15px 24px')};
  text-transform: uppercase;

  :hover,
  :focus,
  :hover a,
  :focus a {
    background-color: ${({ theme }) => theme.colors.neutral.primaryNavy};
    color: white;
    outline: none;

    path {
      fill: white;
    }
  }
`;
