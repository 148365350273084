import { gql } from '@apollo/client';

import { KB360ServiceType } from '../ServiceTypeIcon';

export const KB360_APPOINTMENT_TYPES = gql`
  query appointmentTypes {
    appointmentTypes {
      id
      name
      kb360Heading
      description
      serviceTypes {
        name
      }
    }
  }
`;

export interface Kb360AppointmentTypesResponse {
  appointmentTypes: KB360AppointmentType[];
}

export interface KB360AppointmentType {
  id: number;
  name: string;
  kb360Heading: string;
  description: string;
  serviceTypes: { name: KB360ServiceType }[];
  overrideUrl?: string;
}
