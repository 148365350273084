import { themes } from 'kb-shared';
import styled from 'styled-components';

export default styled.textarea`
  font-size: 24px;
  color: black;
  height: 240px;
  max-width: 420px;
  width: 100%;
  border: 0px;
  border: solid 1px ${themes.colors.neutral.lavender};
  text-align: left;
  background-color: ${themes.deprecatedColors.beige};

  padding: 10px;
  ::placeholder {
    font-size: 24px;
    text-align: left;
    color: #a9a7b0;
  }
  :focus {
    outline: 0;
    border: solid 1px ${themes.colors.yellow.primary};
  }
`;
