import { themes } from 'kb-shared';
import styled, { css } from 'styled-components';

const { colors, deprecatedColors } = themes;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: ${colors.neutral.primaryNavy};
  margin-top: 1em;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 20px;
`;

export const CopyShared = css`
  text-align: center;
`;

export const Price = styled.h2`
  ${CopyShared};
  font-size: 36px;
  color: ${colors.white};
  margin-bottom: 12px;
`;

export const PriceContainer = styled.div`
  margin: 0 0 30px 0;
`;

export const InsuranceContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 20px;
`;

export const Header = styled.h2`
  ${CopyShared};
  font-size: 36px;
  margin: 0;
  color: ${colors.white};
`;

export const SmallPrint = styled.p`
  ${CopyShared};
  font-size: 16px;
  padding: 0 10%;
  color: ${deprecatedColors.gray};
  margin: 16px 0;
`;
