import { gql } from '@apollo/client';

export const QUIZ_QUESTION_QUERY = gql`
  query bookingQuizQuestion($parentBookingQuizAnswerId: ID) {
    bookingQuizQuestions(parentBookingQuizAnswerId: $parentBookingQuizAnswerId) {
      id
      category
      text
      parentBookingQuizAnswerId
      childBookingQuizAnswers {
        id
        category
        text
        parentBookingQuizQuestionId
        appointmentTypeIds
      }
    }
  }
`;

export const QUIZ_ANSWER_QUERY = gql`
  query bookingQuizAnswer($id: Int!) {
    bookingQuizAnswer(id: $id) {
      id
      category
      text
      parentBookingQuizQuestionId
      appointmentTypeIds
      appointmentTypes {
        id
        name
        description
        virtual
      }
    }
  }
`;
