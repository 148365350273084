import React from 'react';

import StepDetail, { Description, Subheader } from '../StepDetail';

const ObWeek24Details = () => (
  <StepDetail>
    <Subheader>Return OB Appointment</Subheader>
    <Description>This appointment includes a visit with your provider & an ultrasound.</Description>
  </StepDetail>
);

export default ObWeek24Details;
