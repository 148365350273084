import { gql } from '@apollo/client';

export const VIRTUAL_MEETING_QUERY = gql`
  query($appointmentPublicIdentifier: String!) {
    appointmentVirtualMeeting(appointmentPublicIdentifier: $appointmentPublicIdentifier) {
      status
      uiMessage
      realMeetingUrl
      software
    }
  }
`;
