import { gql } from '@apollo/client';

export const QUERY_LAB = gql`
  query lab($labId: Int!) {
    lab(labId: $labId) {
      id
      name
    }
  }
`;
