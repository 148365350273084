import React from 'react';

import { ReactComponent as CloseIcon } from 'assets/icons/close-icon.svg';
import Modal from 'components/Modal';
import { Button } from 'components/v2/Buttons/Button';

import {
  ButtonContainer,
  Container,
  Content,
  IconContainer,
  Body
} from './IntakeFormNotAvailableModal.styled';

interface Props {
  visible: boolean;
  onDismiss: Function;
}

export const IntakeFormNotAvailableModal = ({ visible, onDismiss }: Props) => {
  return (
    <Modal open={visible} onRequestClose={() => onDismiss()}>
      <Container>
        <Content>
          <IconContainer onClick={() => onDismiss()}>
            <CloseIcon />
          </IconContainer>
          <Body>Your intake form has already been submitted!</Body>
          <ButtonContainer>
            <Button label={'OK'} category="primary-dark" size="md" onClick={() => onDismiss()} />
          </ButtonContainer>
        </Content>
      </Container>
    </Modal>
  );
};
