import posed from 'react-pose';

import { ChevronRight, ChevronUp } from 'assets/icons/Icons';
import { Alert } from 'components/v2/Alert/Alert';
import theme from 'kb-shared/theme';
import styled from 'styled-components';

export const ToDoItemContainer = styled.div<{ $active: boolean }>`
  padding: 1em;
  border-radius: 8px;
  background: ${({ $active }) => ($active ? theme.colors.semantic.lightPink : theme.colors.white)};

  box-shadow: ${({ $active }) =>
    $active
      ? theme.shadows.container.glowRed
      : '0px 1px 3px 0px rgba(185, 66, 55, 0.16), 0px 0px 0px 1px rgba(185, 66, 55, 0.1), 0px 1px 0.5px 0px rgba(185, 66, 55, 0.16)'};

  @media ${({ theme }) => theme.queries.minDesktop} {
    background: ${theme.colors.white};
    padding: 1.5em 0;
    margin-bottom: 1em;
    box-shadow: none;
  }
`;

export const ToDoItemTitleArea = styled.div`
  cursor: pointer;

  @media ${({ theme }) => theme.queries.minDesktop} {
    display: none;
  }
`;

export const ToDoItemTitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const ToDoItemTitle = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-right: 12px;
`;

export const ToDoItemTitleNumberContainer = styled.div<{ $active: boolean }>`
  margin-right: 0.5em;
  background-color: ${({ $active }) =>
    $active ? theme.colors.semantic.darkRed : theme.colors.semantic.red};
  border-radius: 4px;
  padding: 0.25em 0.5em;
  height: 28px;
  min-width: 24px;

  > * {
    color: ${theme.colors.white};
  }
`;

export const GoForwardArrow = styled(ChevronRight).attrs({ type: 'solid' })`
  display: inline;
  color: ${({ theme }) => theme.colors.neutral.navy};
`;

export const GoUpArrow = styled(ChevronUp).attrs({ type: 'solid' })`
  display: inline;
  color: ${({ theme }) => theme.colors.neutral.navy};
`;

const ItemContentArea = styled.div`
  overflow: hidden;
  display: flex;
  flex-direction: column;

  @media ${({ theme }) => theme.queries.minDesktop} {
    flex-direction: row;
    overflow: visible;
  }
`;

export const ToDoItemContentArea = posed(ItemContentArea)({
  visible: {
    height: 'auto',
    duration: 200
  },
  hidden: {
    height: 0,
    duration: 200
  }
});

export const ToDoItemTextArea = styled.div`
  flex: 1;
  padding-top: 1em;

  @media ${({ theme }) => theme.queries.minDesktop} {
    width: 80%;
    flex: 4;
  }
`;

export const ToDoItemActionsArea = styled.div`
  flex: 1;
`;

export const ToDoItemActionsButtons = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 1.5em;

  button {
    width: 100%;
  }

  @media ${({ theme }) => theme.queries.minDesktop} {
    justify-content: flex-end;

    button {
      width: auto;
    }
  }
`;

export const ToDoItemConsentWarning = styled(Alert).attrs({
  type: 'error',
  size: 'md',
  noShadow: true
})`
  margin-top: 1em;
`;
