import { gql } from '@apollo/client';

export const MARK_LEARN_ABOUT_KB360_COMPLETE = gql`
  mutation completePortalTodo {
    completePortalTodo(todoId: 212) {
      patientTodo {
        completed
      }
    }
  }
`;
