import React from 'react';

import { TitleText, FinishedSubtitle, ContainerStyled, Button } from './AcknowledgeStatus.styles';

const CyclePlanOutOfDate: React.FC = () => {
  return (
    <ContainerStyled>
      <TitleText>Your cycle plan has been updated!</TitleText>
      <FinishedSubtitle>
        Please try refreshing the page or reaching out to your provider for the latest version.
      </FinishedSubtitle>

      <Button text="Reload" onClick={() => window.location.reload()} />
    </ContainerStyled>
  );
};

export default CyclePlanOutOfDate;
