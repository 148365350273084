import React from 'react';

import { Text } from 'components/v2/Typography';
import { pageUrl } from 'utilities/pageUrl';

export const ToDoItemDescription = ({ description }: { description: string }) => {
  return (
    <Text tag="p">
      {description}
      {description.includes('HIPAA') ? (
        <>
          {' '}
          You can also go to your profile and add trustees who may receive access to your medical
          information.
          <a onClick={e => e.stopPropagation()} href={pageUrl.myProfile('trustees')} target="_self">
            {' '}
            <u>Go to profile </u>
          </a>
        </>
      ) : (
        ''
      )}
    </Text>
  );
};
