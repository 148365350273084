import { ApolloError, useMutation } from '@apollo/client';
import React, { ReactComponentElement, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { ReactComponent as LockIcon } from 'assets/icons/secure-lock.svg';
import Button from 'components/Button/NewButton';
import { StepTrackerLayout } from 'components/v2/Layouts/StepTrackerLayout/StepTrackerLayout';
import { BugTracker } from 'kb-shared/utilities/bugTracker';
import {
  Container,
  Form
} from 'screens/ActivateMembership/EnterAccessCode/ActivateMembershipScreen.styled';
import { graphQLErrorMessage } from 'utilities/errors';
import { showErrorToast, showSuccessToast } from 'utilities/notificationUtils';
import { RoutesPath } from 'utilities/pageUrl';

import { PATIENT } from '../../kb-shared/graphql/queries';
import { AcceptTermsWrapper } from './components/ConfirmationBox/ConfirmationBox.styled';
import { CREATE_PATIENT_WALMART_DETAIL } from './WalmartWinOrBidScreen.graphql';
import {
  Tab,
  TabItem,
  WalmartTextInput,
  WalmartForm,
  HelperText,
  Description,
  Title,
  TitleContainer
} from './WalmartWinOrBidScreen.styled';
import { CodeType, createPatientWalmartDetailResult } from './WalmartWinOrBidScreen.types';

const helperElementBID = () => {
  return (
    <HelperText>
      If the BID on your medical card is W2N99999999W, only input the 8 digit number between the
      alphanumeric prefix and suffix: 99999999. If there are any leading zeros, please do not
      include, e.g., if your BID is is W2N00999999W, just input 999999.
    </HelperText>
  );
};

const helperElementWIN = () => {
  return (
    <HelperText>Your WIN (Walmart Identification Number) is 7, 8 or 9 digits in length.</HelperText>
  );
};

export const WalmartWinOrBidScreen = () => {
  const history = useHistory();
  const [walmartUid, setWalmartUid] = useState<string>('');
  const [walmartUidType, setWalmartUidType] = useState<CodeType>(CodeType.WIN);
  const [associatesDateOfBirth, setAssociateDateOfBirth] = useState<string>('');
  const [disabled, setDisabled] = useState<boolean>(true);
  const [helperElement, setHelperElement] = useState<ReactComponentElement<any>>();

  const [createPatientWalmartDetail, { data, loading }] = useMutation<
    createPatientWalmartDetailResult
  >(CREATE_PATIENT_WALMART_DETAIL, {
    refetchQueries: [{ query: PATIENT }]
  });

  const handleErrors = (error: ApolloError) => {
    const ERROR_MORE_EIGHT_DIGITS = 'benefitId: [a-zA-Z\\d]+ cannot be more than 8 digits';
    const ERROR_INVALID_BID = 'Invalid benefitId provided , benefitId must contain only numbers';
    const ERROR_BID_WIN_NOT_FOUND =
      'Could not find information using the BID/WIN & DOB you provided';
    const ERROR_INVALID_WIN = 'Invalid WIN provided , WIN must contain only numbers';

    if (error.message.match(new RegExp(ERROR_MORE_EIGHT_DIGITS))) {
      showErrorToast('BID cannot be more than 8 digits');
    } else if (error.message.includes(ERROR_INVALID_BID)) {
      showErrorToast('BID must contain only numbers');
    } else if (error.message.includes(ERROR_BID_WIN_NOT_FOUND)) {
      showErrorToast('Could not find information using the BID/WIN & DOB you provided');
    } else if (error.message.includes(ERROR_INVALID_WIN)) {
      showErrorToast('WIN must contain only numbers');
    } else {
      showErrorToast(graphQLErrorMessage(error));
      BugTracker.notify(error);
    }

    setDisabled(true);
  };

  useEffect(() => {
    setDisabled(!walmartUid || !associatesDateOfBirth || loading);
  }, [walmartUid, associatesDateOfBirth, loading]);

  useEffect(() => {
    if (!data) return;
    if (data) {
      showSuccessToast('Please confirm your identity from the list');
      history.push(RoutesPath.ACTIVATE_MEMBERSHIP_WALMART_CONFIRM_IDENTITY, {
        benefitsProfiles: data?.createPatientWalmartDetail?.benefitsProfiles
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  useEffect(() => {
    if (walmartUidType === CodeType.BID) {
      setHelperElement(helperElementBID);
    } else {
      setHelperElement(helperElementWIN);
    }
  }, [walmartUidType]);

  const handleConfirm = async () => {
    try {
      await createPatientWalmartDetail({
        variables: {
          associatesDateOfBirth,
          walmartUid,
          walmartUidType
        }
      });
    } catch (error) {
      if (error instanceof ApolloError) {
        return handleErrors(error);
      }

      BugTracker.notify(error);
    }
  };

  return (
    <StepTrackerLayout
      title={'Activate Membership'}
      stepNames={['Services', 'Location', 'Activate']}
      selectedStep={2}
      renderMainContent={() => {
        return (
          <Container>
            <TitleContainer>
              <Title>
                Enter the associate's WIN (Walmart Identification Number) or BID (Benefit ID Number){' '}
                <LockIcon width={23} height={23} />
              </Title>
              <Description>
                Please input the associate's 9 digit WIN or BID number. The BID number is located on
                associate's medical ID card. <br /> Input only one or the other and the Walmart
                associate's date of birth, then click ‘Next’
              </Description>
            </TitleContainer>
            <Form>
              <div>
                <Tab>
                  <TabItem
                    active={walmartUidType === CodeType.WIN}
                    onClick={() => setWalmartUidType(CodeType.WIN)}
                  >
                    WIN <span>(Walmart Identification Number)</span>
                  </TabItem>
                  <TabItem
                    active={walmartUidType === CodeType.BID}
                    onClick={() => setWalmartUidType(CodeType.BID)}
                  >
                    BID <span>(Benefit ID)</span>
                  </TabItem>
                </Tab>
                <WalmartForm>
                  <WalmartTextInput
                    label={`UNIQUE ID (${walmartUidType === CodeType.WIN ? 'WIN' : 'BID'})`}
                    placeholder={`Example ${
                      walmartUidType === CodeType.WIN ? 'WIN' : 'BID'
                    }: 12345678`}
                    isRequired
                    maxLength={walmartUidType === CodeType.WIN ? 9 : 8}
                    value={walmartUid}
                    onChange={event => setWalmartUid(event.currentTarget.value.trim())}
                    helperElement={helperElement}
                  />

                  <WalmartTextInput
                    inputType="date"
                    label="Associate's DOB"
                    placeholder="Enter your date of birth"
                    isRequired
                    value={associatesDateOfBirth}
                    autoComplete="bday"
                    onChange={event => setAssociateDateOfBirth(event.currentTarget.value)}
                  />
                </WalmartForm>
              </div>
            </Form>
            <AcceptTermsWrapper>
              <Button
                text={'Next'}
                type={'submit'}
                loading={loading}
                onClick={handleConfirm}
                disabled={disabled}
              />
            </AcceptTermsWrapper>
          </Container>
        );
      }}
    />
  );
};
