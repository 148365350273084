import breakpointsRaw from 'kb-shared/theme/breakpointsRaw';
import styled from 'styled-components';

export const PageInfo = styled.div`
  color: #353148;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 20px;
  font-weight: 400;
`;

export const TwoColumnContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px 20px;

  @media ${({ theme }) => theme.queries.minTablet} {
    flex-direction: row;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    > div,
    > button {
      width: calc(50% - 10px);
      margin-top: 0px;
    }
  }
`;

export const ResponsiveContainer = styled.div<{ paddedContent?: boolean }>`
  width: 100%;
  min-width: ${breakpointsRaw.minimalSupportedWidth}px;
  margin: 0 auto;
  margin-top: 64px;
  padding: 0 ${({ paddedContent }) => paddedContent && '1em'};

  @media ${({ theme }) => theme.queries.minDesktop} {
    margin-top: 56px;
    max-width: 1084px;
    padding: 0;
  }

  @media ${({ theme }) => theme.queries.minLargeDesktop} {
    max-width: 1184px;
  }
`;
