import React, { useEffect, useState } from 'react';

import { XMark } from 'assets/icons/Icons';
import { convertToBase64 } from 'utilities/file';

import { FilePreviewItem } from '../FilePreviewItem/FilePreviewItem';
import { Container, DeletePreview, PreviewItem } from './FilePreviewList.styled';
import { Props } from './FilePreviewList.types';

export const FilePreviewList = ({ attachments, onDeleteAttachment }: Props) => {
  const [previews, setPreviews] = useState<string[]>([]);

  const createPreview = async (files: File[]) => {
    const preparingPreview = files.map(file => convertToBase64(file));
    const previewReady = await Promise.all(preparingPreview);

    setPreviews(previewReady as string[]);
  };

  useEffect(() => {
    createPreview(attachments);
  }, [attachments]);

  return (
    <Container>
      {previews.map((preview: string, index: number) => (
        <PreviewItem key={index}>
          <DeletePreview onClick={() => onDeleteAttachment(index)}>
            <XMark type="solid" />
          </DeletePreview>
          {attachments[index] && (
            <FilePreviewItem attachment={attachments[index]} preview={preview} />
          )}
        </PreviewItem>
      ))}
    </Container>
  );
};
