import styled from 'styled-components';

export const FormContainer = styled.div`
  border-radius: 16px;
  background: ${({ theme }) => theme.colors.neutral.lavenderWhite};
  padding: 2em;
  box-shadow: ${({ theme }) => theme.shadows.container.glowDarkBlue};
  margin-top: 20px;

  @media (min-width: ${({ theme }) => theme.breakpoints.smallDesktop}) {
    padding: 2em 4em;
  }
`;
