import { InitializationState, PatientState } from 'kb-shared';

import InitializationReducer from './initialization.redux';
import UserReducer, {
  login,
  resendConfirmationCode,
  confirmUser,
  appLoaded,
  signInAppleUser,
  updateProfileImage,
  profileImageError,
  resetProfileImageError,
  updateSubmittedInsuranceCard,
  insuranceCardError,
  resetInsuranceCardError,
  LOG_OUT,
  clearGoogleAuthError,
  updateSubmittedID,
  resetIDError,
  idError,
  resetConfirmationState
} from './user.redux';

export interface ReduxState {
  patient: PatientState;
  initialization: InitializationState;
}

export const patient = {
  reducer: UserReducer,
  login,
  confirmUser,
  resendConfirmationCode,
  appLoaded,
  signInAppleUser,
  updateProfileImage,
  profileImageError,
  resetProfileImageError,
  updateSubmittedInsuranceCard,
  insuranceCardError,
  resetInsuranceCardError,
  LOG_OUT,
  clearGoogleAuthError,
  updateSubmittedID,
  resetIDError,
  idError,
  resetConfirmationState
};

export const initialization = {
  reducer: InitializationReducer
};
