import React, { Component } from 'react';

import { Answer } from 'kb-shared';
import styled from 'styled-components';

import AnimatedWrapper from '../AnimatedWrapper';
import FormButton from '../FormButton';
import Text from '../Primitives/FormText';
import TextInput from '../Primitives/TextInput';

type Props = {
  title: string;
  onContinue: () => void;
  onSave: (response: string) => void;
  answer: Answer | null;
  isRequired: boolean | null;
  skipLinkComponent: JSX.Element | null;
};
type State = {
  response: string;
};

export default class StringForm extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      response: (props.answer && props.answer.data) || ''
    };
  }

  onKeyPress(e: { key: string }) {
    if (e.key === 'Enter') {
      this.props.onSave(this.state.response);
      if (this.props.onContinue) this.props.onContinue();
    }
  }

  renderContinueButton() {
    const hidden = this.state.response ? false : true;
    return (
      <AnimatedWrapper isHidden={hidden}>
        <FormButton text={'Continue'} onClick={this.props.onContinue} />
      </AnimatedWrapper>
    );
  }

  render() {
    return (
      <Container>
        <Text isRequired={this.props.isRequired}>{this.props.title}</Text>

        <TextInput
          value={this.state.response}
          placeholder={'Type your answer here...'}
          onKeyPress={this.onKeyPress.bind(this)}
          onChange={e => {
            const response = e.target.value;
            this.setState(() => ({ response: response }));
          }}
          onBlur={e => this.props.onSave(e.target.value)}
        />
        {this.props.skipLinkComponent}
        {this.renderContinueButton()}
      </Container>
    );
  }
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
`;
