import { themes } from 'kb-shared';
import styled from 'styled-components';

export const ActivateBenefitsContainer = styled.div`
  display: flex;
  padding: 16px 24px 24px 24px;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
  margin-bottom: 24px;

  border-radius: 8px;
  background: ${themes.colors.neutral.navy};
`;
