import styled, { css } from 'styled-components';

import { CategoryIconSizes } from './CategoryIcon.types';

export const Circle = styled.div<{ size: CategoryIconSizes }>`
  background: #fff;
  border: 1px solid ${({ theme }) => theme.colors.neutral.lavender};
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;

  ${({ size }) => getSizes(size)};
`;

const getSizes = (size: CategoryIconSizes) => {
  switch (size) {
    case 'sm':
      return css`
        width: 24px;
        height: 24px;

        svg {
          width: 10px;
          height: 10px;
        }
      `;
    case 'md':
      return css`
        width: 48px;
        height: 48px;

        svg {
          width: 20px;
          height: 20px;
        }
      `;
    case 'lg':
      return css`
        width: 120px;
        height: 120px;

        svg {
          width: 60px;
          height: 60px;
        }
      `;
  }
};
