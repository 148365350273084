import React from 'react';

import { ShieldHeart } from 'assets/icons/Icons';
import { InsuranceProvider } from 'components/InsuranceForm/InsuranceForm.types';
import { COB_PRIORITY_CODE } from 'components/InsuranceForm/InsuranceForm.utils';
import { isPrimaryInsurance } from 'components/InsuranceList/InsuranceList.utils';
import { Dropdown } from 'components/v2/DropdownMenu/Dropdown';
import { MenuItemDetailsArray } from 'components/v2/DropdownMenu/Dropdown.types';
import { Heading } from 'components/v2/Typography';
import { Icon } from 'screens/MyProfile/components/shared.styled';

import {
  Badge,
  InsuranceCardConfirmation,
  InsuranceCardConfirmationHeader,
  InsuranceDetailTitle,
  InsuranceDetailValue,
  InsuranceDetailsContainer,
  PayerCompanyName
} from './InsuranceInfoBox.styled';

export const InsuranceInfoBox = ({
  title,
  insurance,
  cobPriorityCode,
  badgeLabel,
  disableDelete,
  openAddForm,
  openDeleteModal
}: {
  title: string;
  insurance: InsuranceProvider;
  cobPriorityCode: COB_PRIORITY_CODE;
  badgeLabel: string;
  disableDelete?: boolean;
  openAddForm: (cobPriorityCode: COB_PRIORITY_CODE, insuranceProvider?: InsuranceProvider) => void;
  openDeleteModal: (insuranceProvider: InsuranceProvider) => void;
}) => {
  const generateMenuItems = (
    cobPriorityCode: COB_PRIORITY_CODE,
    insuranceProvider: InsuranceProvider
  ): MenuItemDetailsArray => {
    return [
      {
        label: 'Edit Coverage',
        onClick: () => openAddForm(cobPriorityCode, insuranceProvider),
        type: 'default'
      },
      {
        label: 'Delete Coverage',
        onClick: () => openDeleteModal(insuranceProvider),
        type: 'warning',
        isDisabled: disableDelete,
        tooltipText:
          'Primary insurance coverage cannot be removed while secondary or tertiary insurance coverage is active'
      }
    ];
  };

  return (
    <div>
      <Heading tag="h2" styledAs="h5" noMargin>
        <Icon>
          <ShieldHeart />
        </Icon>
        {title}
      </Heading>
      <InsuranceCardConfirmation
        $isPrimaryInsurance={isPrimaryInsurance(insurance.cobPriorityCode)}
      >
        <InsuranceCardConfirmationHeader>
          <PayerCompanyName>{insurance?.derivedPayerCompanyName}</PayerCompanyName>
          <Badge $isPrimaryInsurance={isPrimaryInsurance(insurance.cobPriorityCode)}>
            {badgeLabel}
          </Badge>
          <Dropdown items={generateMenuItems(cobPriorityCode, insurance)} />
        </InsuranceCardConfirmationHeader>
        <InsuranceDetailsContainer>
          <div>
            <InsuranceDetailTitle>Insurance type</InsuranceDetailTitle>
            <InsuranceDetailValue>{insurance?.relationshipToInsured}</InsuranceDetailValue>
          </div>
          <div>
            <InsuranceDetailTitle>Member ID</InsuranceDetailTitle>
            <InsuranceDetailValue>{insurance?.memberId}</InsuranceDetailValue>
          </div>
        </InsuranceDetailsContainer>
      </InsuranceCardConfirmation>
    </div>
  );
};
