import moment from 'moment';
import React from 'react';

import usePatient from 'hooks/usePatient';
import { getEggsRequired } from 'kb-shared/utilities/eggRetrieval';
import { Row, Column } from 'styled/Grid';

import { CalculatorField } from './CalculatorField/CalculatorField';
import { Aside, InputRow } from './FamilyPlanningCalculator.styled';
import { Footnotes } from './Footnotes/Footnotes';
import ResultDiv from './ResultDiv';

export const FamilyPlanningCalculator = () => {
  const { patient } = usePatient();
  const userAge = moment().diff(patient?.birthday, 'years');

  const [numKids, setNumKids] = React.useState(0);
  const [probability, setProbability] = React.useState(70);
  const [age, setAge] = React.useState(userAge || 0);

  return (
    <Aside>
      <h1>Family planning calculator</h1>
      <Row>
        <Column mobile={12} tablet={6} desktop={6}>
          <InputRow>
            <CalculatorField
              value={numKids}
              onValueChange={setNumKids}
              id="howManyKidsInput"
              label="How many kids do you want?"
            />
          </InputRow>
          <InputRow>
            <CalculatorField
              value={probability}
              onValueChange={setProbability}
              maxValue={100}
              id="chanceInput"
              label="Chance you can have all children"
              postfix="%"
            />
          </InputRow>
          <InputRow>
            <CalculatorField value={age} onValueChange={setAge} id="ageInput" label="Your age" />
          </InputRow>
        </Column>

        <Column mobile={12} tablet={6} desktop={6}>
          <ResultDiv resultValue={getEggsRequired(numKids, probability, age)} />
        </Column>
      </Row>

      <Footnotes />
    </Aside>
  );
};
