import React, { ChangeEvent } from 'react';

import { InputMask } from './InputMask';
import { MaskedInputProps, Selection } from './InputWithMask.types';

const KEYCODE_Z = 90;
const KEYCODE_Y = 89;

function isUndo(e: ChangeEvent<HTMLInputElement>) {
  // @ts-ignore
  return (e.ctrlKey || e.metaKey) && e.keyCode === (e.shiftKey ? KEYCODE_Y : KEYCODE_Z);
}

function isRedo(e: ChangeEvent<HTMLInputElement>) {
  // @ts-ignore
  return (e.ctrlKey || e.metaKey) && e.keyCode === (e.shiftKey ? KEYCODE_Z : KEYCODE_Y);
}

function getSelection(el: HTMLInputElement) {
  let start, end;
  if (el.selectionStart !== undefined) {
    start = el.selectionStart;
    end = el.selectionEnd;
  } else {
    try {
      el.focus();
      // @ts-ignore
      const rangeEl = el.createTextRange();
      const clone = rangeEl.duplicate();

      // @ts-ignore
      rangeEl.moveToBookmark(document.selection.createRange().getBookmark());
      clone.setEndPoint('EndToStart', rangeEl);

      start = clone.text.length;
      end = start + rangeEl.text.length;
    } catch (e) {
      /* not focused or not visible */
    }
  }

  return { start, end };
}

function setSelection(el: HTMLInputElement, selection: Selection) {
  try {
    if (el.selectionStart !== undefined) {
      el.focus();
      el.setSelectionRange(selection.start, selection.end);
    } else {
      el.focus();
      // @ts-ignore
      const rangeEl = el.createTextRange();
      rangeEl.collapse(true);
      rangeEl.moveStart('character', selection.start);
      rangeEl.moveEnd('character', selection.end - selection.start);
      rangeEl.select();
    }
  } catch (e) {
    /* not focused or not visible */
  }
}

class InputWithMask extends React.Component<MaskedInputProps> {
  mask: InputMask = new InputMask({
    pattern: this.props.mask,
    value: this.props.value,
    formatCharacters: this.props.formatCharacters,
    placeholderChar: this.props.placeholderChar
  });
  // @ts-ignore
  input: HTMLInputElement = {};

  UNSAFE_componentWillMount() {
    const options = {
      pattern: this.props.mask,
      value: this.props.value,
      formatCharacters: this.props.formatCharacters,
      placeholderChar: this.props.placeholderChar
    };

    this.mask = new InputMask(options);
  }

  UNSAFE_componentWillReceiveProps(nextProps: MaskedInputProps) {
    if (this.props.mask !== nextProps.mask && this.props.value !== nextProps.mask) {
      // if we get a new value and a new mask at the same time
      // check if the mask.value is still the initial value
      // - if so use the nextProps value
      // - otherwise the `this.mask` has a value for us (most likely from paste action)
      if (this.mask.getValue() === this.mask.emptyValue) {
        this.mask.setPattern(nextProps.mask, {
          value: nextProps.value,
          selection: { start: 0, end: 0 }
        });
      } else {
        this.mask.setPattern(nextProps.mask, {
          value: this.mask.getRawValue(),
          selection: { start: 0, end: 0 }
        });
      }
    } else if (this.props.mask !== nextProps.mask) {
      this.mask.setPattern(nextProps.mask, {
        value: this.mask.getRawValue(),
        selection: { start: 0, end: 0 }
      });
    } else {
      this.mask.setValue(nextProps.value);
    }
  }

  UNSAFE_componentWillUpdate(nextProps: MaskedInputProps) {
    if (nextProps.mask !== this.props.mask) {
      this._updatePattern(nextProps);
    }
  }

  componentDidUpdate(prevProps: MaskedInputProps) {
    if (prevProps.mask !== this.props.mask && this.mask.selection.start) {
      this._updateInputSelection();
    }
  }

  _updatePattern(props: MaskedInputProps) {
    this.mask.setPattern(props.mask, {
      value: this.mask.getRawValue(),
      selection: getSelection(this.input)
    });
  }

  _updateMaskSelection() {
    this.mask.selection = getSelection(this.input);
  }

  _updateInputSelection() {
    setSelection(this.input, this.mask.selection);
  }

  _onChange = (e: ChangeEvent<HTMLInputElement>) => {
    const maskValue = this.mask.getValue();
    const incomingValue = e.target.value;
    if (incomingValue !== maskValue) {
      // only modify mask if form contents actually changed
      this._updateMaskSelection();
      this.mask.setValue(incomingValue); // write the whole updated value into the mask
      e.target.value = this._getDisplayValue(); // update the form with pattern applied to the value
      this._updateInputSelection();
    }

    if (this.props.onChange) {
      this.props.onChange(e);
    }
  };

  _onKeyDown = (e: ChangeEvent<HTMLInputElement>) => {
    if (isUndo(e)) {
      e.preventDefault();
      if (this.mask.undo()) {
        e.target.value = this._getDisplayValue();
        this._updateInputSelection();
        if (this.props.onChange) {
          this.props.onChange(e);
        }
      }
      return;
    } else if (isRedo(e)) {
      e.preventDefault();
      if (this.mask.redo()) {
        e.target.value = this._getDisplayValue();
        this._updateInputSelection();
        if (this.props.onChange) {
          this.props.onChange(e);
        }
      }
      return;
    }

    // @ts-ignore
    if (e.key === 'Backspace') {
      e.preventDefault();
      this._updateMaskSelection();
      if (this.mask.backspace()) {
        const value = this._getDisplayValue();
        e.target.value = value;
        if (value) {
          this._updateInputSelection();
        }
        if (this.props.onChange) {
          this.props.onChange(e);
        }
      }
    }
  };

  _onKeyPress = (e: ChangeEvent<HTMLInputElement>) => {
    // Ignore modified key presses
    // Ignore enter key to allow form submission
    // @ts-ignore
    if (e.metaKey || e.altKey || e.ctrlKey || e.key === 'Enter') {
      return;
    }

    e.preventDefault();
    this._updateMaskSelection();
    // @ts-ignore
    if (this.mask.input(e.key || e.data)) {
      e.target.value = this.mask.getValue();
      this._updateInputSelection();
      if (this.props.onChange) {
        this.props.onChange(e);
      }
    }
  };

  _onPaste = (e: ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    this._updateMaskSelection();
    // getData value needed for IE also works in FF & Chrome

    // @ts-ignore
    if (e.clipboardData && this.mask.paste(e.clipboardData.getData('Text'))) {
      e.target.value = this.mask.getValue();
      // Timeout needed for IE
      setTimeout(() => this._updateInputSelection(), 0);
      if (this.props.onChange) {
        this.props.onChange(e);
      }
    }
  };

  _getDisplayValue() {
    const value = this.mask.getValue();
    return value === this.mask.emptyValue ? '' : value;
  }

  _keyPressPropName() {
    if (typeof navigator !== 'undefined') {
      return navigator.userAgent.match(/Android/i) ? 'onBeforeInput' : 'onKeyPress';
    }
    return 'onKeyPress';
  }

  _getEventHandlers() {
    return {
      onChange: this._onChange,
      onKeyDown: this._onKeyDown,
      onPaste: this._onPaste,
      [this._keyPressPropName()]: this._onKeyPress
    };
  }

  focus() {
    this.input.focus();
  }

  blur() {
    this.input.blur();
  }

  render() {
    const ref = (r: HTMLInputElement) => (this.input = r);
    const maxLength = this.mask.pattern ? this.mask.pattern.length : 0;
    const value = this._getDisplayValue();
    const eventHandlers = this._getEventHandlers();
    const { size = maxLength, placeholder = this.mask.emptyValue } = this.props;

    // cleanup prop used only for CSS styles
    const propsToPass = { ...this.props };
    // @ts-ignore
    delete propsToPass.isFilled;

    const inputProps = {
      ...propsToPass,
      ...eventHandlers,
      ref,
      maxLength,
      value,
      size,
      placeholder
    };

    // @ts-ignore
    return <input {...inputProps} />;
  }
}

export default InputWithMask;
