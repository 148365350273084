import styled from 'styled-components';

import TextInput from './NewTextInput';

export const InputWrapper = styled.div`
  margin: 14px 28px 14px 0;
  width: 100%;
`;

export const TextInputStyled = styled(TextInput)`
  padding-bottom: 0;
  width: 100%;
  max-width: 100%;

  input {
    height: initial;
    padding-top: 8px;
    padding-bottom: 5px;
  }

  input:disabled {
    opacity: 1;
  }
`;
