import React from 'react';
import { useHistory } from 'react-router-dom';

import { ReactComponent as UnpaidInvoicesIcon } from 'assets/icons/unpaid-invoices.svg';
import { Button } from 'components/v2/Buttons/Button';
import { Heading } from 'components/v2/Typography';
import { useUnpaidInvoicesCount } from 'screens/Invoices/hooks/useUnpaidInvoicesCount';
import { pageUrl } from 'utilities/pageUrl';

import {
  Container,
  UnpaidInvoicesContainer,
  UnpaidInvoicesIconContainer
} from './UnpaidInvoices.styled';

export const UnpaidInvoices = () => {
  const history = useHistory();

  const { unpaidInvoicesCount: count } = useUnpaidInvoicesCount();
  if (count === 0) return null;

  return (
    <Container>
      <UnpaidInvoicesIconContainer>
        <UnpaidInvoicesIcon />
      </UnpaidInvoicesIconContainer>
      <UnpaidInvoicesContainer>
        <Heading tag="h2" noMargin>
          You have {count} unpaid invoice
          {count > 1 ? 's' : ''}!
        </Heading>
        <Button
          category="error"
          label={`View Invoice${count > 1 ? 's' : ''}`}
          onClick={() => history.push(pageUrl.invoices({ tabId: 'invoices' }))}
        />
      </UnpaidInvoicesContainer>
    </Container>
  );
};
