import React, { Component } from 'react';

import { themes } from 'kb-shared';
import styled from 'styled-components';

type Props = {
  sections: number;
  selectedSection: number;
};

type State = {};

export default class SectionIndicator extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {};
  }

  renderSections() {
    const lines = [];
    for (let section = 0; section < this.props.sections; section++) {
      lines.push(<Line key={section} selected={section === this.props.selectedSection} />);
    }
    return lines;
  }

  render() {
    return <Container>{this.renderSections()}</Container>;
  }
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`;

interface LineProps {
  selected: boolean;
}

const Line = styled.div`
  height: 2px;
  width: ${(props: LineProps) => (props.selected ? '24px' : '12px')};
  background-color: black;
  margin: 10px 0px 10px 0px;
  transition: width 300ms;

  @media screen and (min-width: ${themes.breakpoints.largePhonePortrait}) {
    width: ${(props: LineProps) => (props.selected ? '64px' : '44px')};
  }
`;
