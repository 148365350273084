import styled, { css } from 'styled-components';

import BaseModal from './BaseModal';
import './styles.scss';

const overlayClassName = 'styled-overlay-classname';
const modalClassName = 'styled-modal-classname';

interface StyledModalProps {
  mobileFullscreen?: boolean;
  fullScreen?: boolean;
  maxWidth?: string; // e.g. '960px'
  addPadding?: boolean;
}

const mobileFullscreenStyles = css`
  .${overlayClassName} {
    height: 100%;
    overflow-y: scroll;
    -webkit-overflow-scrolling: auto;
  }

  .${modalClassName} {
    min-height: 100%;
    width: 100%;

    border: none;
    box-shadow: none;
  }
`;

const fullscreenStyles = css`
  .${overlayClassName} {
    height: 100%;
    overflow-y: scroll;
    -webkit-overflow-scrolling: auto;
    padding: 0;
  }

  .${modalClassName} {
    min-height: 100%;
    width: 100%;

    border: none;
    box-shadow: none;
  }
`;

const Modal = styled(BaseModal).attrs({
  overlayClassName,
  modalClassName
})<StyledModalProps>`
  .${overlayClassName} {
    position: fixed;
    z-index: 301;
    inset: 0px;
    height: 100%;
    overflow-y: scroll;
    padding: 0px;
    margin: 0px;
    display: flex;
    background-color: #0000007f;
  }

  .${modalClassName} {
    background-color: #fff;
    ${({ addPadding }) => (addPadding ? 'margin: auto 20px;' : 'margin: auto;')}
    border: 2px solid ${({ theme }) => theme.colors.neutral.primaryNavy};
    box-shadow: -5px 5px 0 0 ${({ theme }) => theme.colors.neutral.primaryNavy};
    &:focus-visible {
      outline: none;
    }
  }

  ${({ mobileFullscreen }) => (mobileFullscreen ? mobileFullscreenStyles : '')}

  @media ${({ theme }) => theme.queries.minTablet} {
    .${overlayClassName} {
      background-color: #0000007f;
    }

    .${modalClassName} {
      padding: 0px;
      height: auto;
      width: ${({ maxWidth }) => (maxWidth ? maxWidth : 'auto')};
      margin: auto;

      border: 2px solid ${({ theme }) => theme.colors.neutral.primaryNavy};
      box-shadow: -5px 5px 0 0 ${({ theme }) => theme.colors.neutral.primaryNavy};
    }
  }

  @media ${({ theme }) => theme.queries.minDesktop} {
    .${overlayClassName} {
      padding: 2% 0px;
      padding-left: 124px;
    }
    .${modalClassName} {
      min-height: auto;
    }
  }

  ${({ fullScreen }) => (fullScreen ? fullscreenStyles : '')}
`;

export default Modal;
