import { Heading } from 'components/v2/Typography';
import styled, { css } from 'styled-components';

export const containerSize = css`
  max-width: 560px;
`;

export const Container = styled.div`
  padding-top: 52px;
  height: 100%;
  overflow: auto;

  @media (min-width: ${({ theme }) => theme.breakpoints.smallDesktop}) {
    display: grid;
    max-width: 1104px;
    margin: 0 auto;
    grid-template-columns: 336px 1fr;
    grid-template-rows: 100px 1fr;
    grid-template-areas:
      'header header'
      'conversationList conversation';
    overflow: hidden;
    height: 100vh;
    padding: 52px 16px;
  }
`;

export const Header = styled(Heading).attrs({ tag: 'h1' })`
  display: none;

  @media (min-width: ${({ theme }) => theme.breakpoints.smallDesktop}) {
    grid-area: header;
    display: block;
  }
`;

export const ConversationList = styled.ul`
  display: flex;
  flex-direction: column;
  margin: 16px 0 0 0;
`;

export const ConversationItem = styled.li`
  display: flex;
  flex-direction: column;
  padding: 12px 16px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.neutral.lavender};
`;
