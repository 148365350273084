// Implementation taken from: https://codepen.io/Libor_G/pen/eyzwOx
import React, { ChangeEvent, Component } from 'react';

import { themes } from 'kb-shared';
import styled from 'styled-components';

type Props = {
  value: string;
  placeholder: string;
  onChange: (event: ChangeEvent<HTMLTextAreaElement>) => void;
  onBlur?: (event: ChangeEvent<HTMLTextAreaElement>) => void;
};
type State = {
  rows: number;
  minRows: number;
  maxRows: number;
};

export default class GrowingTextArea extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      rows: 1,
      minRows: 1,
      maxRows: 10
    };
  }

  handleChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
    const textareaLineHeight = 24;
    const { minRows, maxRows } = this.state;

    const previousRows = event.target.rows;
    event.target.rows = minRows; // reset number of rows in textarea

    const currentRows = ~~(event.target.scrollHeight / textareaLineHeight);

    if (currentRows === previousRows) {
      event.target.rows = currentRows;
    }

    if (currentRows >= maxRows) {
      event.target.rows = maxRows;
      event.target.scrollTop = event.target.scrollHeight;
    }

    this.setState({
      rows: currentRows < maxRows ? currentRows : maxRows
    });
    this.props.onChange(event);
  };

  render() {
    return (
      <TextArea
        rows={this.state.rows}
        value={this.props.value}
        placeholder={this.props.placeholder}
        onChange={this.handleChange}
        onBlur={this.props.onBlur}
      />
    );
  }
}

const TextArea = styled.textarea`
  box-sizing: border-box;
  resize: none;
  font-size: 24px;
  line-height: 24px;
  overflow: auto;
  height: auto;
  width: 420px;
  color: black;
  border: 0px;
  border-bottom: solid 1px ${themes.colors.neutral.lavender};
  text-align: left;
  background-color: transparent;

  padding: 10px;
  ::placeholder {
    font-size: 24px;
    text-align: left;
    color: #a9a7b0;
  }
  :focus {
    outline: 0;
    border-bottom: solid 1px ${themes.colors.yellow.primary};
  }
`;
