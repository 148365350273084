import { SubStep } from 'components/auth/CreateAccount/CreateAccount.types';

import { stepOrder, steps } from './CreateAccount.constants';

export const getAuthSubStepTitle = (subStep: SubStep) => {
  switch (subStep) {
    case 'choose_signup_option':
      return 'Create an Account';
    case 'email':
      return 'Sign up With Email';
    case 'google_attributes':
      return 'Tell us about yourself';
    case 'login':
      return 'Welcome back!';
    case 'forgot_password':
      return 'Create your new password';
    case 'verification_code':
      return 'Enter verification code';
    default:
      return 'Create an Account';
  }
};

export const getStepNumber = (currentStepName: typeof steps[keyof typeof steps]) => {
  const startingStepNumber = 2;
  const stepOrderIndex = Math.max(
    stepOrder.indexOf(currentStepName === 'time' ? 'location' : currentStepName),
    0
  );

  return startingStepNumber + stepOrderIndex;
};
