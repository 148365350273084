import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import { AuthFlowLayout } from 'components/v2/Layouts/AuthFlowLayout/AuthFlowLayout';
import { FormType } from 'screens/ForgotPassword/ForgotPasswordForm.types';
import { analytics } from 'utilities/analytics';
import { pageUrl } from 'utilities/pageUrl';

import { ForgotPasswordForm } from '../ForgotPassword/ForgotPasswordForm';

export const AccessAccount = () => {
  const history = useHistory();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const queryEmail = queryParams.get('email') || undefined;
  const [formType, setFormType] = useState<FormType>('request_code');

  useEffect(() => {
    analytics.track(analytics.EVENTS.ACCOUNT_ACTIVATION_STARTED);
  }, []);

  return (
    <AuthFlowLayout
      title={getTitle(formType)}
      renderMainContent={() => {
        return (
          <ForgotPasswordForm
            initialEmail={queryEmail}
            activateAccount={true}
            onSuccessfulReset={() => history.replace(pageUrl.login())}
            onGoBack={() => history.replace(pageUrl.login())}
            handleUnconfirmed={email => {
              history.replace(pageUrl.emailAddressVerification(), {
                email,
                forgotPassword: true
              });
            }}
            formType={formType}
            updateFormType={(type: FormType) => setFormType(type)}
            requestVerificationCodeDescription="Enter the email address where your access instructions were sent; we will send you a verification code that will enable you to set a password."
          />
        );
      }}
    />
  );
};

const getTitle = (formType: FormType) =>
  formType === 'request_code' ? 'Access your account!' : 'Complete Your Account Activation';
