import * as React from 'react';
import ReactLoading from 'react-loading';

import { themes } from 'kb-shared';
import styled from 'styled-components';

const { colors } = themes;

const CenteredSpinner = styled.div`
  display: flex;
  justify-content: center;
`;

export default function Loader(props: { labelText: null | string }) {
  const { labelText } = props;
  return (
    <div>
      <CenteredSpinner>
        <ReactLoading type="spin" height={30} width={30} color={colors.yellow.primary} />
      </CenteredSpinner>
      {labelText && <div>{labelText}</div>}
    </div>
  );
}
