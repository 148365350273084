import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
`;

export const OptionsContainer = styled.div`
  display: flex;
  flex-flow: row wrap;
  flex: 1 0 50%;
`;

export const GroupWrapper = styled.div`
  margin: 0px 12px 12px 0px;
  width: 420px;

  @media (max-width: ${({ theme }) => theme.breakpoints.tabletPortrait}) {
    width: 100%;
    min-width: 200px;
  }
`;

export const ContinueButtonWrapper = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
`;
