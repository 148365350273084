import Honeybadger from '@honeybadger-io/js';
import { useState } from 'react';

import { graphql } from 'kb-shared';

import RecognizePatientResultCode from '../types/recognize-patient-result-code';
const { client, mutate } = graphql;
const { RECOGNIZE_PATIENT_FROM_EPIC_SSO } = mutate;

export const useRecognizePatientOnBackend = () => {
  const [responseState, setResponseState] = useState<ResponseStateType>({
    patient: null,
    finished: false,
    errors: null,
    resultCode: null
  });
  const recognizePatientOnBackend = async (accessToken: string, idtoken: string): Promise<any> => {
    try {
      // make sure idtoken is NOT set in the header here, because that will cause egg-chicken problem:
      // 1. backend is about to set current_user from idtoken from header
      // 2. but the relationship between idtoken.sub (fhir) and patient is not established yet for
      // a person that is using disney sso for the very first time
      const { data } = await client.mutate({
        mutation: RECOGNIZE_PATIENT_FROM_EPIC_SSO,
        variables: {
          epicAccessToken: accessToken,
          epicIdtoken: idtoken
        }
      });
      setResponseState({
        patient: data.recognizePatientFromEpicSso.patient,
        finished: true,
        errors: data.recognizePatientFromEpicSso.errors,
        resultCode: data.recognizePatientFromEpicSso.resultCode
      });
    } catch (error) {
      Honeybadger.notify(error as Error, 'Epic SSO: recognizePatientOnBackend mutation failed');
      setResponseState({
        patient: null,
        finished: true,
        errors: [`Unexpected error: ${error}`],
        resultCode: RecognizePatientResultCode.Failed
      });
    }
  };

  return { responseState, recognizePatientOnBackend };
};

type Patient = {
  id: number;
  email: string;
  firstName: string;
  lastName: string;
};

type ResponseStateType = {
  patient: Patient | null;
  finished: boolean;
  errors: string[] | null;
  resultCode: RecognizePatientResultCode | null;
};
