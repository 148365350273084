import { Location } from 'history';
import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';

import { useInterstitialStatus } from 'hooks/useInterstitialStatus';

import { StepType } from '../types/types';
import { BookingSearchParams } from '../utils/bookingSearchParams';
import { shouldSkipLocationStep } from '../utils/shouldSkipLocationStep';
import { containsOnlyVirtualAppointmentType, isSupportedStep } from '../utils/validation';
import { useInsuranceStepCompletedOnInitialLoad } from './useInsuranceCompletedOnInitialLoad';
import { useLoadEntities } from './useLoadEntities';
import { usePreselectedClinic } from './usePreselectedClinic';

export const useBookingState = () => {
  const location = useLocation() as Location;
  const bookingParams = useMemo(() => new BookingSearchParams(location.search), [location.search]);
  const {
    status,
    loading: loadingInterstitial,
    error: interstitialError
  } = useInterstitialStatus();
  const insuranceStepCompletedInitially = useInsuranceStepCompletedOnInitialLoad();
  const insuranceStepCompleted = Boolean(status?.insuranceDataCompleted);
  const completedSteps: Array<StepType> = insuranceStepCompletedInitially ? ['insurance'] : [];
  const normalizedBookingParams = bookingParams.toNormalizedBookingParams();
  const entities = useLoadEntities(normalizedBookingParams);
  const { loadedEntities } = entities;

  // Preselect clinic for certain scenarios
  const {
    clinic: preselectedClinic,
    loading: loadingPreselectedClinic,
    error: preselectedClinicError
  } = usePreselectedClinic({
    clinicId: normalizedBookingParams.clinicId ?? undefined,
    isOnlyVirtual: containsOnlyVirtualAppointmentType(loadedEntities.allAppointmentTypes ?? []),
    selectedLab: loadedEntities.selectedLab,
    selectedProduct: loadedEntities.selectedProduct
  });
  if (preselectedClinic) {
    loadedEntities.selectedClinic = preselectedClinic;
  }

  const skipLocationStep = shouldSkipLocationStep({
    step: bookingParams.get('step') as StepType,
    selectedAppointmentTypes: loadedEntities.allAppointmentTypes ?? [],
    selectedClinic: loadedEntities.selectedClinic,
    partnerClinicSearch: bookingParams.get('partner_clinic_search') ?? ''
  });

  const stepSupported = isSupportedStep(bookingParams.get('step') ?? '');
  const calculatedStep =
    bookingParams.get('step') === 'location' && skipLocationStep
      ? 'time'
      : bookingParams.get('step');

  return {
    completedSteps,
    entities: entities.loadedEntities,
    loading: entities.loading || loadingInterstitial || loadingPreselectedClinic,
    error: entities.error || interstitialError || preselectedClinicError,
    insuranceStepCompleted,
    stepSupported,
    calculatedStep,
    bookingParams
  };
};
