import React from 'react';

const CalendarIcon = () => {
  return (
    <svg
      width="21px"
      height="22px"
      viewBox="0 0 21 22"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>Calendar Icon</title>
      <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Appointment-Icon" fill="#343754">
          <path
            d="M1.63381828,20.5947387 L19.6058194,20.5947387 L19.6058194,7.54057772 L1.63381828,7.54057772 L1.63381828,20.5947387 Z M2.95951139,4.08373167 C2.98311831,5.4129858 4.07452649,6.45788625 5.35863299,6.38353429 C6.501098,6.31769591 7.40090585,5.45725643 7.5293714,4.28011166 C7.56725226,3.93729804 7.54035136,3.58653841 7.54254735,3.23975179 C7.54309635,3.16710254 7.54254735,3.0944533 7.54254735,3.0127229 L13.4569037,3.0127229 C13.4569037,3.32488762 13.4601976,3.63307933 13.4563547,3.94183862 C13.4519627,4.25967906 13.4942355,4.56787077 13.6045841,4.86641376 C13.9806478,5.8806653 14.8991216,6.4629944 15.9960198,6.37842614 C16.9419435,6.30577689 17.8077134,5.4986892 17.9987648,4.49805939 C18.0349986,4.30905784 18.0371946,4.11154271 18.0410376,3.91856816 C18.0481746,3.62002517 18.0432336,3.32091461 18.0432336,3.01158776 C18.1052704,3.0087499 18.1541312,3.00364175 18.203541,3.00364175 C18.788224,3.00307417 19.3734559,3.0025066 19.9586879,3.00307417 C20.5796047,3.00420932 20.9995883,3.43783449 20.9995883,4.08373167 C21.0001372,9.70553446 21.0001372,15.3279048 20.9995883,20.9497076 C20.9995883,21.554172 20.5757617,21.9985811 19.9927258,21.9991486 C13.6644249,22.0002838 7.33612407,22.0002838 1.00782322,21.9991486 C0.422042273,21.9991486 0.000411748559,21.5587126 0.000411748559,20.9525455 C-0.00013724952,15.3091749 -0.00013724952,9.66637198 0.000411748559,4.02356902 C0.000411748559,3.47869969 0.399533352,3.0229392 0.927120505,3.0087499 C1.58756519,2.99115516 2.24910788,3.00534446 2.91065056,3.00647961 C2.9584134,3.37994212 2.95292341,3.7318369 2.95951139,4.08373167 Z"
            id="Fill-1"
          />
          <path
            d="M4.00074533,2.50179966 C4.00074533,2.02573317 3.99906834,1.5490686 4.00074533,1.0730021 C4.0040993,0.471938249 4.43452615,0.00544092927 4.98849109,5.82678895e-05 C5.53239411,-0.00592246698 5.98685778,0.449211457 5.99244774,1.03831384 C6.00306866,2.02393895 6.00195067,3.01016213 5.99244774,3.99578723 C5.98741678,4.5513975 5.52177319,5.00533528 4.99072708,4.99995262 C4.45912197,4.99397188 4.01472023,4.54960328 4.0046583,3.99698338 C3.99571436,3.49878817 4.00298131,2.99999488 4.00298131,2.50179966 L4.00074533,2.50179966 Z"
            id="Fill-3"
          />
          <path
            d="M15.0008466,2.49228997 C15.0008466,2.01622588 14.998611,1.53956372 15.0014055,1.06349964 C15.004759,0.473802164 15.4351199,0.00969948689 15.982293,0.00013035953 C16.5261126,-0.00884069737 16.9855368,0.446290923 16.9922437,1.03239997 C17.002863,2.02340273 17.0023041,3.01500355 16.9922437,4.0060063 C16.9866546,4.55144656 16.523318,5.00059747 15.9968246,4.9999994 C15.4680956,4.9999994 15.0159372,4.55383884 15.0053179,4.00421209 C14.9958164,3.50063676 15.0030823,2.99646336 15.0030823,2.49228997 L15.0008466,2.49228997 Z"
            id="Fill-4"
          />
          <path
            d="M9.32912209,14.510013 C9.3915214,14.424668 9.42216392,14.3732271 9.46172062,14.3311392 C10.7487064,12.9778947 12.0368064,11.6252347 13.3254636,10.2725748 C13.6575171,9.92418011 14.2670246,9.90722802 14.6269349,10.2363324 C15.043116,10.6168776 15.1211152,11.2090317 14.8102329,11.6492015 C14.7550764,11.7269473 14.692677,11.8000166 14.6269349,11.8684096 C13.1204373,13.4513841 11.6128254,15.0337741 10.1046564,16.614995 C9.61660462,17.1270651 9.03996814,17.1282342 8.55470208,16.6190869 C7.80591037,15.8346143 7.05767579,15.0489726 6.30944121,14.2639154 C5.90440283,13.8383594 5.89604578,13.2064556 6.28994142,12.7808997 C6.6782657,12.3611893 7.29613029,12.3512519 7.70506862,12.7709623 C8.20704879,13.2865397 8.69955762,13.8114699 9.19485214,14.3334774 C9.23496599,14.3755654 9.26616564,14.4264216 9.32912209,14.510013"
            id="Fill-5"
          />
        </g>
      </g>
    </svg>
  );
};

export default CalendarIcon;
