import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  gap: 8px;
  overflow-x: auto;
`;

export const PreviewItem = styled.div`
  position: relative;
  width: 48px;
  height: 48px;
  min-height: 48px;
  min-width: 48px;
  background: lightgray 50% / cover no-repeat, ${({ theme }) => theme.colors.neutral.lavenderWhite};
  margin: 8px 8px 0 0;
  border: 1px solid ${({ theme }) => theme.colors.neutral.lavender};
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const DeletePreview = styled.div`
  width: 20px;
  height: 20px;
  background: #fff;
  border-radius: 50%;
  border: 1px solid ${({ theme }) => theme.colors.neutral.primaryNavy};
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: -8px;
  top: -8px;
`;
