import { Appointment, AppointmentType } from '../types';

const Category = {
  FERTILITY: 1,
  GYN: 2,
  COACHING: '3'
};

export const AppointmentTypeIds = {
  AMH: '57',
  ANNUAL_WELL_WOMAN: '14',
  COMPLEX_GYN_CONSULTATION: '145',
  COMPLEX_GYN_VIRTUAL: '146',
  CYCLE_VISIT: '9',
  EGG_FREEZING_ASSESMENT: '58',
  EGG_FREEZING_VIRTUAL: '143',
  EMBRYO_TRANSFER: '33',
  FERTILITY_APPOINTMENT: '3',
  FERTILITY_ASSESSMENT: ['3', '58', '59', '93', '112', '312', '313', '157', '319', '320', '351'],
  FERTILITY_VIRTUAL: '93',
  FOLLOW_UP: '21',
  FOLLOW_UP_PHONE_CALL: '117',
  GYN_CONSULTAION: '44',
  GYN_VIRTUAL: '114',
  INITIAL_CYCLE_VISIT: '35',
  IUI_PROCEDURE: ['34', '77'],
  MALE_FERTULITY_ASSESSMENT_SEMEN_ANALYSIS: '157',
  NEW_OB_VISIT: '189',
  POST_OP: '330',
  POST_OP_PHONE_CALL: '21',
  POST_OP_VIRTUAL: '343',
  PRE_OP: '177',
  PRE_OP_VIRTUAL: '178',
  PREGNANCY: '75',
  PROGESTERONE: '76',
  PULSE_PHONE_CALL: '350',
  PULSE_ULTRASOUND_BLOODWORK: '351',
  RETRIEVAL: '15',
  VIRTUAL_CONSULTATION: ['143', '144'],
  PREGNANCY_CONFIRMATION_VISIT: '135'
};

export function isGYNAppointment(categoryId: number) {
  return categoryId === Category.GYN;
}

export function isFertility(categoryId: number) {
  return categoryId === Category.FERTILITY;
}

export function isRequiredToBeInTheSameState(appt: AppointmentType) {
  const appointmentIds: string[] = [
    AppointmentTypeIds.FERTILITY_VIRTUAL,
    AppointmentTypeIds.EGG_FREEZING_VIRTUAL,
    AppointmentTypeIds.GYN_VIRTUAL,
    AppointmentTypeIds.COMPLEX_GYN_VIRTUAL
  ];

  return appointmentIds.includes(appt.id);
}

export function shouldDisplayProvider(appt: Appointment) {
  const appointmentTypesRequiredProviders = [
    AppointmentTypeIds.FERTILITY_VIRTUAL,
    AppointmentTypeIds.FERTILITY_APPOINTMENT,
    AppointmentTypeIds.EGG_FREEZING_VIRTUAL,
    AppointmentTypeIds.EGG_FREEZING_ASSESMENT,
    AppointmentTypeIds.PULSE_PHONE_CALL,
    AppointmentTypeIds.MALE_FERTULITY_ASSESSMENT_SEMEN_ANALYSIS,
    AppointmentTypeIds.GYN_CONSULTAION,
    AppointmentTypeIds.GYN_VIRTUAL,
    AppointmentTypeIds.ANNUAL_WELL_WOMAN,
    AppointmentTypeIds.COMPLEX_GYN_VIRTUAL,
    AppointmentTypeIds.COMPLEX_GYN_CONSULTATION,
    AppointmentTypeIds.FOLLOW_UP_PHONE_CALL,
    AppointmentTypeIds.PRE_OP,
    AppointmentTypeIds.PRE_OP_VIRTUAL,
    AppointmentTypeIds.POST_OP,
    AppointmentTypeIds.POST_OP_VIRTUAL,
    AppointmentTypeIds.POST_OP_PHONE_CALL
  ];

  return appointmentTypesRequiredProviders.includes(appt.appointmentType.id);
}

export function isAssessment(appt: Appointment) {
  const fertilityAssessmentIds: string[] = AppointmentTypeIds.FERTILITY_ASSESSMENT;
  return fertilityAssessmentIds.includes(appt.appointmentType.id);
}

export function isVirtualConsultation(appt: Appointment) {
  const virtualConsultationIds: string | string[] = AppointmentTypeIds.VIRTUAL_CONSULTATION;
  return virtualConsultationIds.includes(appt.appointmentType.id);
}

export function isIUIProcedure(appt: Appointment) {
  const iuiProcedureIds: string[] = AppointmentTypeIds.IUI_PROCEDURE;
  return iuiProcedureIds.includes(appt.appointmentType.id);
}

export function isInitialCycleVisit(appt: Appointment) {
  const initialCycleVisitId: string = AppointmentTypeIds.INITIAL_CYCLE_VISIT;
  return appt.appointmentType.id === initialCycleVisitId;
}

export function isCycleVisit(appt: Appointment) {
  const cycleVisitId: string = AppointmentTypeIds.CYCLE_VISIT;
  return appt.appointmentType.id === cycleVisitId;
}

export function isRetrieval(appt: Appointment) {
  const retrievalId: string = AppointmentTypeIds.RETRIEVAL;
  return appt.appointmentType.id === retrievalId;
}

export function isFollowUp(appt: Appointment) {
  const followUpId: string = AppointmentTypeIds.FOLLOW_UP;
  return appt.appointmentType.id === followUpId;
}

export function isPregnancyTest(appt: Appointment) {
  const pregnancyTestId: string = AppointmentTypeIds.PREGNANCY;
  return appt.appointmentType.id === pregnancyTestId;
}

export function isEmbryoTransfer(appt: Appointment) {
  const embryoTransfer: string = AppointmentTypeIds.EMBRYO_TRANSFER;
  return appt.appointmentType.id === embryoTransfer;
}

export function isPulseUltrasoundBloodwork(appt: Appointment) {
  const pulseUltrasoundBloodwork: string = AppointmentTypeIds.PULSE_ULTRASOUND_BLOODWORK;
  return appt.appointmentType.id === pulseUltrasoundBloodwork;
}

export function isCoachingAppointment(appointment: AppointmentType) {
  return appointment.category?.id === Category.COACHING;
}

export function isAMH(appt: Appointment) {
  return appt.appointmentType.id === AppointmentTypeIds.AMH;
}

export function cycleVisitId() {
  return AppointmentTypeIds.CYCLE_VISIT;
}

export function initialCycleVisitId() {
  return AppointmentTypeIds.INITIAL_CYCLE_VISIT;
}

export const appointmentSupportedInPartnerClinics = (
  appointmentId: string | undefined
): boolean => {
  return appointmentId !== AppointmentTypeIds.PULSE_ULTRASOUND_BLOODWORK;
};

export const VIRTUAL_APPOINTMENT_LICENSING_DISCLAIMER =
  'At your scheduled time, join the consult using the button below. By joining this virtual appointment, you confirm that you are physically present in one of the state(s) where this provider is licensed.';

export const PHONE_CALL_DISCLAIMER =
  'Your provider will call you! By joining this phone call appointment, you confirm that you are physically present in one of the state(s) where this provider is licensed.';
