import { CustomTile } from './CustomWidgets.types';

export const showCustomTile = (tile: CustomTile) => {
  const { svgIconUrl, header, boldText, regularText, buttonStyle, buttonText } = tile;
  const bodyText = !!boldText || !!regularText;
  const button = buttonStyle && buttonText;

  const requiredFields = svgIconUrl && header && bodyText && button;

  return !!requiredFields;
};
