import debounce from 'lodash/debounce';
import { useEffect, useRef } from 'react';

export const useOnWindowResize = (callback: () => void, delay = 100, isActive = true) => {
  const previousCallback = useRef(callback);

  useEffect(() => {
    // Remove previous listener
    if (previousCallback.current) {
      window.removeEventListener('resize', previousCallback.current);
    }

    // if active, set new listener
    if (isActive) {
      const debouncedCallback = debounce(callback, delay);
      window.addEventListener('resize', debouncedCallback);
      previousCallback.current = debouncedCallback;
    }

    return () => {
      if (previousCallback.current) {
        window.removeEventListener('resize', previousCallback.current);
      }
    };
  }, [callback, delay, isActive]);
};
