import React from 'react';

import { Button } from 'components/v2/Buttons/Button';
import { Modal } from 'components/v2/Modal/Modal';
import { Text } from 'components/v2/Typography';

import { ButtonContainer, TextContainer } from './NotesModal.styled';

export const NotesModal = ({
  visible,
  notes,
  title,
  onDismiss
}: {
  visible: boolean;
  notes: string;
  title: string;
  onDismiss: () => void;
}) => {
  return (
    <Modal title={title} open={visible} onCancel={onDismiss}>
      <TextContainer>
        <Text>{notes}</Text>
      </TextContainer>
      <ButtonContainer>
        <Button label="Close" uppercase onClick={onDismiss} fullWidth />
      </ButtonContainer>
    </Modal>
  );
};
