import { useQuery } from '@apollo/client';
import { useEffect, useRef } from 'react';

import { BugTracker } from 'kb-shared/utilities/bugTracker';

import { CREDIT_CARDS_QUERY } from './useCreditCards.graphql';
import { CreditCardsResponse, CreditCard } from './useCreditCards.types';

export const useCreditCards = () => {
  const fallbackCardsValue = useRef<Array<CreditCard>>([]);
  const { data, loading, error } = useQuery<CreditCardsResponse>(CREDIT_CARDS_QUERY);

  useEffect(() => {
    if (!error) return;

    BugTracker.notify('useCreditCards', error);
  }, [error]);

  return {
    // ref is used instead of [] directly to avoid re-rendering(on successive calls where the value remains [])
    // for other places that check for equality
    creditCards: data?.kbStripeCards ?? fallbackCardsValue.current,
    creditCardsLoading: loading,
    creditCardsError: error
  };
};
