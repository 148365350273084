import React from 'react';
import { useHistory } from 'react-router-dom';

import { usePatient } from 'hooks';
import { AppointmentType } from 'kb-shared/types';
import { analytics } from 'utilities/analytics';

import { ReactComponent as AppointmentIcon } from '../../../assets/icons/appointment.svg';
import { Card, Container, Description, Name, ScheduleButton } from './AppointmentTypeCard.styled';

interface Props {
  appointmentType: AppointmentType;
}

export const AppointmentTypeCard = ({ appointmentType }: Props) => {
  const { isLoggedIn } = usePatient();
  const history = useHistory();
  const scheduleUrl = `/${isLoggedIn ? 'Book' : 'create-account'}?step=location&appointment_id=${
    appointmentType.id
  }`;

  return (
    <Container>
      <Card>
        <AppointmentIcon />
        <Name>{appointmentType.name}</Name>
        <Description>{appointmentType.description}</Description>
      </Card>
      <ScheduleButton
        onClick={() => {
          analytics.track(analytics.EVENTS.APPOINTMENT_SELECTED);
          history.push(scheduleUrl);
        }}
      >
        Schedule Appointment
      </ScheduleButton>
    </Container>
  );
};
