import { useEffect, useRef } from 'react';
import { useHistory } from 'react-router-dom';

import { trackNavigation } from './trackNavigation';

const trackedPrefixes = [
  '/book',
  '/create-account',
  '/booking-quiz',
  '/confirmation',
  '/appointments',
  '/virtual-meeting'
];

export const BookingNavigationTracker = () => {
  const history = useHistory();
  const prevUrl = useRef<string>('');

  const trackUrlChange = (appLoadedFromHere: boolean) => {
    const { location } = history;
    const url = `${location.pathname}${location.search}`;
    const trackingEnabled = trackedPrefixes.some(prefix => url.toLowerCase().startsWith(prefix));

    if (trackingEnabled && url !== prevUrl.current) {
      trackNavigation(url, location.search, appLoadedFromHere);
      prevUrl.current = url;
    }
  };

  // track initial url
  useEffect(
    () => trackUrlChange(true),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  // track subsequent url changes
  useEffect(() => {
    const unlisten = history.listen(() => trackUrlChange(false));

    return () => unlisten();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // this needs to be a component child of Router to get access to history,
  // and track changes, but we don't want to render anything
  return null;
};
