import styled from 'styled-components';

export const CycleVisitContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 12px 16px 16px 16px;
  margin-top: 16px;
  width: 100%;
  min-height: 72px;
  gap: 12px;
  border-radius: 8px;
  background: ${({ theme }) => theme.colors.white};
  box-shadow: ${({ theme }) => theme.shadows.card.level0};
  
  @media${({ theme }) => theme.queries.minTablet} {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 12px;
    margin-top: 12px;
    box-shadow: ${({ theme }) => theme.shadows.button.activeError};
  }
`;

export const CycleVisitInfo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-items: center;
  gap: 12px;

  @media${({ theme }) => theme.queries.minTablet} {
    flex-direction: row;
    align-items: center;
    gap: 64px;
  }
`;

export const CycleVisitTitle = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;

  @media${({ theme }) => theme.queries.minTablet} {
    gap: 12px;
  }

  @media${({ theme }) => theme.queries.minDesktop} {
    width: 320px;
  }
`;

export const CycleVisitDescription = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;
