export function getAMHDescription(level: number) {
  // AMH <1
  if (level < 1) {
    return "An AMH of less than 1 warrants a full discussion about your fertility and options. An AMH <1 may indicate a low number of eggs or diminished ovarian reserve. Let's talk about it! It is very important to combine the information from your fertility assessment to get a complete and accurate evaluation of your fertility.";
  }
  // AMH 1-6
  if (level >= 1 && level <= 6) {
    return 'An AMH of 1-6 indicates an adequate number of eggs or good ovarian reserve.  AMH of 1-6 can be an indication of good response to ovarian stimulation needed for egg freezing or infertility treatments. AMH is only one piece of your fertility profile, and it is very important to combine the information from your fertility assessment to get a complete and accurate evaluation of your fertility. Though this may seem like a broad range, any number between 1 and 6 is considered normal.';
  }
  // AMH >6
  if (level > 6) {
    return 'An AMH greater than 6 tells us you have an adequate number of eggs, but may be an indication of a common hormonal imbalance called PCOS (poly-cystic ovarian syndrome). That said, PCOS is not diagnosed based on an AMH level alone and it is very important to combine the information about your menstrual cycles and medical history along with your fertility assessment to get a complete and accurate evaluation and diagnosis.';
  }
}

export function getAFCDescription(levelString: string) {
  const level = parseFloat(levelString);
  if (isNaN(level)) {
    return "Whoops! Looks like we're having a technical issue. Please contact navigator@kindbody.com to let us know.";
  }

  // AFC <10
  if (level < 10) {
    return "An AFC of less than 10 is typically indicative of a low ovarian reserve or low number of eggs. Importantly though, AFC is the pool of follicles recruited for that specific month, your AFC will vary from month to month (typically plus or minus 5 follicles). Let's discuss this! Your care team is here to help you understand your options.";
  }
  //  AFC >10
  return 'An AFC in the teens to 20’s+ indicates good ovarian reserve or a good number of eggs. Importantly though, AFC is the pool of follicles recruited for that specific month, your AFC will vary from month to month (typically plus or minus 5 follicles).';
}

export interface NormalizedFollicleData {
  date: string;
  value: number;
  normalizedValue: number;
}

export interface Follicle {
  date: string;
  value: string;
}

export function getNormalizedFollicleData(follicles: Follicle[]): NormalizedFollicleData[] {
  // If user has no follicle results for this day, skip
  if (!follicles || follicles.length === 0) {
    return [];
  }

  // Sort follicles for this day by their size
  const follicleData = follicles
    .map((follicle: Follicle) => {
      return {
        date: follicle.date,
        value: parseFloat(follicle.value)
      };
    })
    .sort((l, r) => {
      return l.value - r.value;
    });

  //Normalize all follicle sizes down to a number between 0-1
  const normalizedfollicleData: Array<NormalizedFollicleData> = follicleData.map(
    (follicleDatum: { date: string; value: number }) => {
      return {
        ...follicleDatum,
        // Noramlize based on 22 which is more or less the largest size of follicle
        normalizedValue: follicleDatum.value / 22
      };
    }
  );

  return normalizedfollicleData;
}

export function getSizeRange(normalizedfollicleData: NormalizedFollicleData[]): string | null {
  if (normalizedfollicleData.length === 0) {
    return null;
  }
  const highRange = normalizedfollicleData[normalizedfollicleData.length - 1].value.toFixed(1);
  const lowRange = normalizedfollicleData[0].value.toFixed(1);
  const range = `${lowRange} - ${highRange}`;
  return range;
}

export function getEstradiolLevel(
  result: { labs: Array<{ type: string; value: string; units: string }> } | null
) {
  if (result) {
    const estradiolLab = result.labs.find(lab => {
      return lab.type === 'estradiol';
    });
    if (estradiolLab) {
      return {
        value: parseInt(estradiolLab.value),
        units: estradiolLab.units
      };
    }
  }
  return {
    value: null,
    units: null
  };
}
