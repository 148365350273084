import { Lab, SignUpProps } from 'kb-shared';

export interface OptionParams {
  label: string;
  value: string;
}

export interface SignUpInputError {
  address1: boolean;
  birthdate: boolean;
  city: boolean;
  country: boolean;
  email: boolean;
  firstName: boolean;
  gender: boolean;
  lastName: boolean;
  password: boolean;
  patientLab: boolean;
  phone: boolean;
  referralSource: boolean;
  referralSourceDetails: boolean;
  state: boolean;
  zipcode: boolean;
}

export enum SIGNUP_TYPE {
  EMAIL = 'email',
  GOOGLE = 'google',
  APPLE = 'apple',
  MEDTRONIC = 'medtronic',
  DISNEY = 'disney'
}

export interface SignUpState {
  addressLine: string;
  addressLine2: string;
  birthdate: string;
  city: string;
  country: string;
  countryOption: OptionParams | null;
  emailAddress: string;
  errors: Partial<SignUpInputError>;
  firstName: string;
  gender: string;
  genderIdentity: string | null;
  genderIdentityOption: OptionParams | null;
  genderOption: OptionParams | null;
  hasDisplayedError?: boolean;
  lastName: string;
  password?: string;
  patientLab?: Lab | null;
  phone: string;
  preferredName: string;
  pronoun: string;
  pronounOption: OptionParams | null;
  referralSource: string;
  referralSourceDetails: string;
  state: string;
  stateOption: OptionParams | null;
  zipCode: string;
  signupType: SIGNUP_TYPE;
}

export interface SignUpData {
  patient: SignUpProps;
  countryOption: OptionParams | undefined;
  errors: Partial<SignUpInputError>;
  genderIdentityOption: OptionParams | null;
  genderOption: OptionParams | undefined;
  hasDisplayedError?: boolean;
  patientLab?: Lab | null;
  labOption?: OptionParams;
  pronounOption: OptionParams | undefined;
  stateOption: OptionParams | undefined;
  preferredTimeZoneOption: OptionParams | null;
  emailInputValid?: boolean;
  firstNameInputValid?: boolean;
  lastNameInputValid?: boolean;
  address1InputValid?: boolean;
  stateInputValid?: boolean;
  cityInputValid?: boolean;
  zipcodeInputValid?: boolean;
  phoneInputValid?: boolean;
  birthdateInputValid?: boolean;
  preferredTimezoneInputValid?: boolean;
  patientLabInputValid?: boolean;
  referralSourceInputValid?: boolean;
  referralSourceDetailsInputValid?: boolean;
  passwordInputValid?: boolean;
}
