import React from 'react';

import { Button } from 'components/v2/Buttons/Button';

import UltrasoundsPDF from '../../assets/Booklet-Ultrasounds.pdf';
import SubstepWrapper from '../../SubstepWrapper';
import { Description, FlexWrapper, Title } from './styled-components';

const ReturnOBAppointment = () => (
  <>
    <Title>Return OB Appointment</Title>
    <Description>This appointment includes a visit with your provider & an ultrasound.</Description>
    <FlexWrapper>
      <Description>
        <strong>Anatomy ultrasound:</strong> This is a detailed ultrasound to look at all your
        baby's body parts from head to toe, including bones and organs. This ultrasound takes about
        1 hour to perform.
      </Description>
      <Button
        label="View More"
        category="secondary"
        size="md"
        onClick={() => window.open(UltrasoundsPDF)}
      />
    </FlexWrapper>
  </>
);

const SUBSTEPS = [{ component: <ReturnOBAppointment /> }];

const ObWeek20Substeps = () => <SubstepWrapper substeps={SUBSTEPS} />;

export default ObWeek20Substeps;
