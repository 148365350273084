import React from 'react';

import { Text } from 'components/v2/Typography';

export const CardContentItemLabel = ({ value }: { value: string }) => {
  return (
    <div>
      <Text tag="span" fontStyle="medium" size="xs" transform="uppercase">
        {value}
      </Text>
    </div>
  );
};
