import { gql } from '@apollo/client';

export const CREATE_CONSENT_DECISION = gql`
  mutation createPatientConsentDecision(
    $consentOptionId: Int!
    $patientConsentId: Int!
    $consentDecisionId: Int!
  ) {
    createPatientConsentDecision(
      consentOptionId: $consentOptionId
      patientConsentId: $patientConsentId
      consentDecisionId: $consentDecisionId
    ) {
      patientConsentDecision {
        id
      }
    }
  }
`;

export const PARTNER_CONSENT_DECISIONS = gql`
  query PartnerConsentDecisions($patientConsentId: Int!) {
    partnerConsentDecisions(patientConsentId: $patientConsentId) {
      id
      consentOptionId
      consentDecisionId
      patientConsentId
      partnersToMismatch
    }
  }
`;
