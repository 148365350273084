import React from 'react';

import Modal from 'components/Modal/Modal';
import CalendarIcon from 'components/SVG/Calendar';
import LeftBracket from 'components/SVG/LeftBracket';
import RightBracket from 'components/SVG/RightBracket';

import { BottomLink, Container, Content, IconContainer, Text, Title } from './FinishModal.styled';

type Props = {
  visible: boolean;
  dismiss: () => void;
};

export const FinishModal: React.FC<Props> = ({ visible, dismiss }) => (
  <Modal onRequestClose={dismiss} open={visible} label=" Electronic Communications Consent">
    <Container>
      <Content>
        <IconContainer>
          <LeftBracket />
          <CalendarIcon />
          <RightBracket />
        </IconContainer>
        <Title>Great!</Title>
        <Text>
          Your care team has been notified. If you are starting a treatment cycle this month you
          will hear from your care team about the next steps soon!
        </Text>
        <BottomLink onClick={dismiss}>Okay</BottomLink>
      </Content>
    </Container>
  </Modal>
);
