import React from 'react';

import { Plus, ShieldHeart } from 'assets/icons/Icons';
import { InsuranceProvider } from 'components/InsuranceForm/InsuranceForm.types';
import { COB_PRIORITY_CODE } from 'components/InsuranceForm/InsuranceForm.utils';
import { Heading } from 'components/v2/Typography';
import { Icon } from 'screens/MyProfile/components/shared.styled';

import { EnterInsuranceDetailsBox, OpenInsuranceModalButton } from './InsurancePlaceholder.styled';

export const InsurancePlaceholder = ({
  title,
  cobPriorityCode,
  openAddForm
}: {
  title: string;
  cobPriorityCode: COB_PRIORITY_CODE;
  openAddForm: (cobPriorityCode: COB_PRIORITY_CODE, insuranceProvider?: InsuranceProvider) => void;
}) => {
  return (
    <div>
      <Heading tag="h2" styledAs="h5" noMargin>
        <Icon>
          <ShieldHeart />
        </Icon>
        {title}
      </Heading>

      <EnterInsuranceDetailsBox>
        <OpenInsuranceModalButton
          text="New insurance coverage"
          leftIcon={<Plus />}
          onClick={_ => openAddForm(cobPriorityCode)}
        />
      </EnterInsuranceDetailsBox>
    </div>
  );
};
