import { Patient } from 'kb-shared';
import { INTERSTITIAL_MODAL_ACTIVATED_KEY } from 'screens/InterstitialModal/InterstitialModal.constants';
import { Consent, Status } from 'screens/InterstitialModal/InterstitialModal.types';
import { pageUrl } from 'utilities/pageUrl';
import { isUS } from 'utilities/userUtil';

export const shouldOpenInterstitialModal = (status: Status) => {
  const { hasRequiredConsents, partnerDataCollected, skipPartnerDataCapture } = status;
  const modalActivated = !!sessionStorage.getItem(INTERSTITIAL_MODAL_ACTIVATED_KEY);

  if (modalActivated) return true;

  if (skipPartnerDataCapture) {
    return !hasRequiredConsents;
  }

  return !hasRequiredConsents || !partnerDataCollected;
};

export const shouldOpenPatientStateModal = (patient?: Patient) => {
  const isUSPatient = isUS(patient?.address?.country || '');
  const isEmptyState = !patient?.address?.state;

  if (isUSPatient && isEmptyState) {
    return true;
  }

  if (!isUSPatient) {
    return false;
  }
};

export const isOnConsentPage = (requiredConsents: Consent[] = [], location: Location) =>
  requiredConsents.some((c: Consent) => c.linkUrl === location.pathname);

export const isOnConfirmationPage = (location: Location) =>
  location.pathname.startsWith(pageUrl.booking.confirmation());
