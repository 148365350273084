import React, { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import { ReactComponent as Checkmark } from 'assets/icons/checkmark-green.svg';
import { Button } from 'components/v2/Buttons/Button';
import { DeprecatedTabs, TabIconPosition } from 'components/v2/DeprecatedTabs';
import useHomeMarket from 'hooks/useHomeMarket';
import { useInterstitialStatus } from 'hooks/useInterstitialStatus';

import kindbody from '../../assets/logos/kindbody.svg';
import Modal from '../../components/Modal';
import { ConsentsTab } from './components/consent/ConsentsTab';
import { Contacts } from './components/consent/Contacts';
import { PartnerDetails } from './components/partner/PartnerDetails';
import { ProceedToDashboardButton } from './components/ProceedToDashboardButton';
import { ButtonContainer, Content, Header, Logo } from './InterstitialModal.styled';
import { Status } from './InterstitialModal.types';
import { activateModal, checkShouldRedirectBackToMembership } from './InterstitialModal.utils';

export const InterstitialModal = ({ open }: { open: boolean }) => {
  const location = useLocation();
  const history = useHistory();

  const { id, vios } = useHomeMarket();
  const { status, loading: statusLoading } = useInterstitialStatus();
  const skipPartnerDataCapture = status?.skipPartnerDataCapture;
  const hasRequiredConsents = status?.hasRequiredConsents;
  const partnerDataCollected = status?.partnerDataCollected;

  useEffect(() => {
    if (statusLoading || !status) return;

    const shouldCollectPartnerData = !skipPartnerDataCapture && !partnerDataCollected;

    if (!hasRequiredConsents || shouldCollectPartnerData) {
      activateModal();
    }
  }, [skipPartnerDataCapture, hasRequiredConsents, partnerDataCollected, statusLoading, status]);

  if (statusLoading) return null;
  if (!id || vios || !status?.active) return null;

  checkShouldRedirectBackToMembership(location, open);

  const options = getTabOptions({
    status,
    skipPartnerDataCapture
  });

  const firstIncompleteTab = options.find(option => !option.completed);

  return (
    <Modal label="You have new documents to sign" open={open} mobileFullscreen>
      <Content>
        <Logo src={kindbody} alt="kindbody" />
        <Header>You have new tasks to complete!</Header>
        <Contacts />
        <DeprecatedTabs
          fixedHeightScrollContainer="380px"
          name="List of tabs for interstitial modal"
          options={options}
          size="lg"
          initialActiveTabId={firstIncompleteTab?.id}
        />
        <ButtonContainer>
          <ProceedToDashboardButton status={status} />
          <Button
            fullWidth
            label={'LOG OUT'}
            category="secondary"
            size="lg"
            onClick={() => {
              history.push('/logout');
            }}
          />
        </ButtonContainer>
      </Content>
    </Modal>
  );
};

const getTabOptions = ({
  status,
  skipPartnerDataCapture
}: {
  status: Status;
  skipPartnerDataCapture: boolean | undefined;
}) => {
  const iconOption = {
    component: <Checkmark />,
    position: 'left' as TabIconPosition
  };

  const options = [
    {
      id: '1',
      label: 'Consents',
      panel: <ConsentsTab status={status} />,
      icon: status.hasRequiredConsents ? iconOption : undefined,
      completed: status.hasRequiredConsents
    }
  ];

  if (!skipPartnerDataCapture) {
    options.push({
      id: '2',
      label: 'Partner Details',
      panel: <PartnerDetails status={status} />,
      icon: status.partnerDataCollected ? iconOption : undefined,
      completed: status.partnerDataCollected
    });
  }

  return options;
};

export default InterstitialModal;
