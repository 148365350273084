import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';

import { LinkButton } from 'components/v2/Buttons/LinkButton/LinkButton';
import { Text } from 'components/v2/Typography';
import { useContactInfo, usePatient } from 'hooks';
import { useOnWindowResize } from 'hooks/useOnWindowResize';
import { themes } from 'kb-shared';
import breakpointsRaw from 'kb-shared/theme/breakpointsRaw';

import { Container } from './Contact.styled';

export const Contact = () => {
  const { isLoggedIn } = usePatient();
  const history = useHistory();
  const [textSize, setTextSize] = useState<'sm' | 'md'>('sm');
  const { numberLabel, number } = useContactInfo();

  useOnWindowResize(() => {
    const isSmallOrMedium = document.body.clientWidth <= breakpointsRaw.tabletLandscape;

    setTextSize(isSmallOrMedium ? 'sm' : 'md');
  }, 300);

  return (
    <Container>
      {isLoggedIn && (
        <Text size={textSize}>
          Have questions? Send us a portal message by clicking{' '}
          <LinkButton
            text="here"
            size={textSize}
            fontStyle="semibold"
            ariaLabel="Button to open a modal with the medical contact"
            onClick={() => history.push('/messages')}
            color={themes.colors.blue.darkBlue}
            underline="none"
          />
          .
        </Text>
      )}
      <Text size={textSize}>
        Call us at{' '}
        <a href={`tel:${number}`}>
          <strong>{numberLabel}</strong>
        </a>
        .
      </Text>
      <Text size={textSize}>
        If at any time you are experiencing a medical emergency, please call{' '}
        <a href="tel:911">
          <strong>911</strong>
        </a>{' '}
        or report to your nearest emergency room.
      </Text>
    </Container>
  );
};
