import { useQuery } from '@apollo/client';
import React from 'react';
import { useHistory } from 'react-router-dom';

import { JOURNEY_TYPES } from 'screens/MyJourney/MyJourney.types';

import StepDetail, { Description } from './StepDetail';
import { ContentWrapper, RightYellowButton } from './ViewDailyInstructionsDetail.styled';
import { JOURNEY_TYPE } from './ViewInstructionsDetail.graphql';

interface JourneyTypeResponse {
  journeyType: string;
}

const ViewDailyInstructionsDetail = () => {
  const history = useHistory();

  const { loading, data } = useQuery<JourneyTypeResponse>(JOURNEY_TYPE);
  const journeyType = data?.journeyType;

  const getDescription = (type: string) => {
    if (type === JOURNEY_TYPES.TIMED_INTERCOURSE) {
      return "During this time, you'll receive instructions about when to have sex with your partner or take any specific medications. Please follow the instructions on your dashboard each day for details.";
    } else if (type === JOURNEY_TYPES.MOCK_CYCLE) {
      return "During this time, you'll be taking medications daily. Follow the instructions on your dashboard for specific details.";
    }
    return "During this time, you'll be doing injections daily. Follow the instructions on your dashboard for specific details.";
  };

  if (loading) return null;

  return (
    <StepDetail>
      <ContentWrapper>
        <div>
          <Description>{journeyType && getDescription(journeyType)}</Description>
        </div>
        <RightYellowButton label="Go to dashboard" onClick={() => history.push(`/`)} />
      </ContentWrapper>
    </StepDetail>
  );
};

export default ViewDailyInstructionsDetail;
