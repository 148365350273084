import React, { Component, ChangeEvent } from 'react';

import TextInput from 'components/TextInput/NewTextInput';

import { CancellationReasonOptions } from './CancellationReasonOptions';
import { Container } from './CancellationSelection.styled';

type State = {
  currentSelected: string;
  specifyOtherValue: string;
};

type Props = {
  setCancellationInformation: (parameters?: any) => void;
};

class CancellationSelection extends Component<Props, State> {
  state = {
    currentSelected: '',
    specifyOtherValue: ''
  };

  onSelect = (selectedValue: string) => {
    this.setState({ currentSelected: selectedValue });
    this.props.setCancellationInformation({
      reason: selectedValue,
      note: this.state.specifyOtherValue
    });
  };

  isSelected = (value: string) => {
    const { currentSelected } = this.state;
    return value === currentSelected;
  };

  onChangeSpecifyOther = (e: ChangeEvent<HTMLInputElement>) => {
    this.setState({ specifyOtherValue: e.target.value });
    this.props.setCancellationInformation({
      reason: this.state.currentSelected,
      note: e.target.value
    });
  };

  render() {
    const { specifyOtherValue } = this.state;
    return (
      <Container>
        <CancellationReasonOptions onSelect={this.onSelect} isSelected={this.isSelected} />
        {this.isSelected('Other - Specify') && (
          <TextInput
            name="Specify"
            label="Reason"
            placeholder="Please specify a reason"
            value={specifyOtherValue}
            onChange={this.onChangeSpecifyOther}
          />
        )}
      </Container>
    );
  }
}

export default CancellationSelection;
