import moment from 'moment-timezone';
import React, { Component, ReactNode } from 'react';
import Media from 'react-media';

import InfoButton from 'components/InfoButton';
import ExpandIcon from 'components/SVG/Expand';
import { themes } from 'kb-shared';
import { convertDateForm } from 'kb-shared/utilities/momentHelpers';

import { getRecommendation } from '../ResultRecommendation';
import { ResultType, ResultCategoryType } from '../Results.types';
import { Copy } from '../utils/resultsCopy';
import {
  CategoryTitle,
  CategoryTitleContainer,
  Column1,
  Column2,
  Column3,
  ColumnContent,
  ColumnHeader,
  DiagnosticContainer,
  Divider,
  EmptyRow,
  ExpandIconWrapper,
  HelperModal,
  HelperText,
  InfoButtonWrapper,
  LineItemText,
  Link,
  LinkHTML,
  MatrixContainer,
  Popover,
  PopoverContainer,
  ResultsContactModal,
  Row,
  ResultRecommendation
} from './DiagnosticResults.styles';
import { Category, Props, ResultColumns, Results, State } from './DiagnosticResults.types';
import { stripNonNumericCharacters } from './DiagnosticResults.utils';

const openRawHtml = (title: string, data: string) => {
  const win = window.open('', title, '');
  // @ts-ignore
  win.document.write(data);
};

export default class DiagnosticResults extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    const categorized = props.results.reduce((map: Results, result: ResultType) => {
      const newMap = { ...map };
      let newCategory: Category = newMap[result.category] || {
        results: [],
        categoryName: result.categoryDisplayName,
        categorySlug: result.category
      };
      newCategory = {
        ...newCategory,
        results: [...newCategory.results, result]
      };
      newMap[result.category] = newCategory;
      return newMap;
    }, {});

    this.state = {
      results: categorized,
      resultsColumnMobileLayout: ResultColumns.YOUR_RESULT,
      resultsPopoverVisible: false,
      modalType: null,
      contactModal: false
    };
  }

  onInfoPress(category: ResultCategoryType) {
    this.setState({
      modalType: category
    });
  }

  showResultsPopover() {
    this.setState({
      resultsPopoverVisible: true
    });
  }

  renderModal() {
    let title = '';
    let copy: ReactNode = '';

    const { modalType } = this.state;
    const open = !!modalType;

    if (modalType && Copy[modalType]) {
      title = Copy[modalType]?.title;
      copy = Copy[modalType]?.copy;
    }

    return (
      <HelperModal
        open={open}
        title={title}
        copy={copy}
        onDismiss={() => {
          this.setState({ modalType: null });
        }}
      />
    );
  }

  renderContactModal() {
    return (
      <ResultsContactModal
        visible={this.state.contactModal}
        onDismiss={() => {
          this.setState({ contactModal: false });
        }}
      />
    );
  }

  renderResults() {
    return (
      <React.Fragment>
        {Object.keys(this.state.results)
          .sort() // Alphabetically sort categories
          .map((categorySlug: string) => {
            const category: Category = this.state.results[categorySlug];
            // Saves the index of the most recent diagnostic for each diagnostic type
            const mostRecentResults = category.results.reduce((acc, next, i, arr) => {
              if (next.type in acc) {
                // @ts-ignore
                const index = acc[next.type];
                const current = arr[index];
                const isMoreRecent = moment(next.date)
                  .tz('UTC')
                  .isSameOrAfter(moment(current.date).tz('UTC'));
                if (!isMoreRecent) return acc;
              }

              return { ...acc, [next.type]: i };
            }, {});

            return (
              <React.Fragment key={category.categorySlug}>
                {this.renderDiagnosticTitle(category)}
                {category.results.map((result, i) => {
                  // @ts-ignore
                  const isMostRecent = mostRecentResults[result.type] === i;
                  return this.renderRow(result, isMostRecent);
                })}
              </React.Fragment>
            );
          })}
      </React.Fragment>
    );
  }

  renderDiagnosticTitle(category: Category) {
    return (
      <Media query={{ minWidth: themes.breakpoints.raw.largePhonePortrait }}>
        {matches => {
          if (matches) {
            return (
              <Row>
                <Column1>
                  <ColumnContent>
                    <CategoryTitleContainer>
                      <CategoryTitle>{category.categoryName}</CategoryTitle>
                      {'hormones_and_vitamins blood_profile diseases_and_infections'.includes(
                        category.categorySlug
                      ) && (
                        <InfoButtonWrapper
                          // @ts-ignore
                          onClick={this.onInfoPress.bind(this, category.categorySlug)}
                        >
                          <InfoButton />
                        </InfoButtonWrapper>
                      )}
                    </CategoryTitleContainer>
                  </ColumnContent>
                </Column1>
                <Column2>
                  <ColumnContent>
                    <LineItemText />
                  </ColumnContent>
                </Column2>
                <Column2>
                  <ColumnContent>
                    <LineItemText />
                  </ColumnContent>
                </Column2>
                <Column3>
                  <ColumnContent>
                    {category.categorySlug.includes('sperm_analysis') && (
                      <CategoryTitleContainer>
                        <CategoryTitle>WHO 5th Ed Values</CategoryTitle>
                      </CategoryTitleContainer>
                    )}
                    <LineItemText />
                  </ColumnContent>
                </Column3>
              </Row>
            );
          } else {
            return (
              <Row>
                <Column1>
                  <ColumnContent>
                    <CategoryTitleContainer>
                      <CategoryTitle>{category.categoryName}</CategoryTitle>
                      {'hormones_and_vitamins blood_profile diseases_and_infections'.includes(
                        category.categorySlug
                      ) && (
                        <InfoButtonWrapper
                          // @ts-ignore
                          onClick={this.onInfoPress.bind(this, category.categorySlug)}
                        >
                          <InfoButton />
                        </InfoButtonWrapper>
                      )}
                    </CategoryTitleContainer>
                  </ColumnContent>
                </Column1>
              </Row>
            );
          }
        }}
      </Media>
    );
  }

  // empty row to give readable space for recommendation
  renderEmptyRow(key: string) {
    return <EmptyRow key={key} />;
  }

  renderLinks(title: string, htmlReports?: Array<string>, pdfReports?: Array<string>) {
    const htmlReportsExist = htmlReports && htmlReports.length > 0;
    const pdfReportsExist = pdfReports && pdfReports.length > 0;

    return (
      <LineItemText>
        {htmlReports &&
          htmlReports.length > 0 &&
          htmlReports.map((report, i) => {
            return (
              <LinkHTML key={i} onClick={() => openRawHtml(title, report)}>
                {i === 0 ? title : `(${i + 1})`}
                &nbsp;
              </LinkHTML>
            );
          })}
        {pdfReports &&
          pdfReports.length > 0 &&
          pdfReports.map((report, i) => {
            return (
              <Link key={i} target="_blank" href={report}>
                {i === 0 && !htmlReportsExist ? title : `(${i + 1})`}
                &nbsp;
              </Link>
            );
          })}
        {!htmlReportsExist && !pdfReportsExist && title}
      </LineItemText>
    );
  }

  renderRow(result: ResultType, isMostRecent: boolean) {
    const value = result.value || '';
    let recommendation = null;
    if (isMostRecent && value) {
      recommendation = getRecommendation({
        name: result.name,
        value: parseFloat(stripNonNumericCharacters(value))
      });
    }

    const htmlReports = result.report && result.report.htmlResults;
    const pdfReports = result.report && result.report.pdfResults;

    return (
      <React.Fragment key={result.type + value}>
        <Row>
          <Column1>
            <ColumnContent>
              <LineItemText>{result.name}</LineItemText>
            </ColumnContent>
          </Column1>
          <Media query={{ minWidth: themes.breakpoints.raw.largePhonePortrait }}>
            {matches => {
              const yourResults = [value, result.units].join(' ');
              const normalRange = [result.normalRange, result.units].join(' ');
              if (matches) {
                return (
                  <React.Fragment>
                    <Column2>
                      <ColumnContent>
                        <LineItemText>
                          {this.renderLinks(yourResults, htmlReports, pdfReports)}
                        </LineItemText>
                      </ColumnContent>
                    </Column2>
                    <Column2>
                      <ColumnContent>
                        <LineItemText>{convertDateForm(result.date)}</LineItemText>
                      </ColumnContent>
                    </Column2>
                    <Column3>
                      <ColumnContent>
                        <LineItemText>{result.normalRange && normalRange}</LineItemText>
                      </ColumnContent>
                    </Column3>
                  </React.Fragment>
                );
              } else {
                const content = (() => {
                  if (this.state.resultsColumnMobileLayout === ResultColumns.YOUR_RESULT) {
                    return yourResults;
                  }
                  return normalRange;
                })();
                return (
                  <React.Fragment>
                    <Column2>
                      <ColumnContent>
                        <LineItemText>{convertDateForm(result.date)}</LineItemText>
                      </ColumnContent>
                    </Column2>
                    <Column2>
                      <ColumnContent>
                        {this.renderLinks(content, htmlReports, pdfReports)}
                      </ColumnContent>
                    </Column2>
                  </React.Fragment>
                );
              }
            }}
          </Media>
        </Row>
        {recommendation && (
          <ResultRecommendation
            {...recommendation}
            onTalkToUs={() => {
              this.setState({ contactModal: true });
            }}
          />
        )}
        {/* empty row to give readable space for recommendation */}
        {recommendation && this.renderEmptyRow(result.type + result.value + 'recommendation')}
      </React.Fragment>
    );
  }

  renderPopover() {
    if (this.state.resultsPopoverVisible) {
      return (
        <PopoverContainer>
          <Popover
            selected={this.state.resultsColumnMobileLayout}
            onSelect={(selection: ResultColumns) => {
              this.setState({
                resultsColumnMobileLayout: selection,
                resultsPopoverVisible: false
              });
            }}
          />
        </PopoverContainer>
      );
    }
  }

  renderMobileHeaderColumns() {
    return (
      <React.Fragment>
        <Column2>
          <ColumnHeader>Date</ColumnHeader>
        </Column2>
        <Column2 onClick={this.showResultsPopover.bind(this)}>
          <ColumnHeader>
            {this.state.resultsColumnMobileLayout === ResultColumns.YOUR_RESULT
              ? 'Results'
              : 'Range'}
            <ExpandIconWrapper>
              <ExpandIcon />
            </ExpandIconWrapper>
          </ColumnHeader>
        </Column2>
        {this.renderPopover()}
      </React.Fragment>
    );
  }

  renderDesktopHeaderColumns() {
    return (
      <React.Fragment>
        <Column2>
          <ColumnHeader>Your Results</ColumnHeader>
        </Column2>
        <Column2>
          <ColumnHeader>Date</ColumnHeader>
        </Column2>
        <Column3>
          <ColumnHeader>Reference Range</ColumnHeader>
        </Column3>
      </React.Fragment>
    );
  }

  renderHeaders() {
    return (
      <React.Fragment>
        <Column1>
          <ColumnHeader>Diagnostic</ColumnHeader>
        </Column1>
        <Media query={{ minWidth: themes.breakpoints.raw.largePhonePortrait }}>
          {matches => {
            if (matches) {
              return this.renderDesktopHeaderColumns();
            } else {
              return this.renderMobileHeaderColumns();
            }
          }}
        </Media>
      </React.Fragment>
    );
  }

  render() {
    return (
      <DiagnosticContainer>
        <Divider />
        <HelperText>
          The <strong>Reference Range</strong> column is meant to help you understand your lab
          results by giving you a typical range for a test. Any abnormal results will be discussed
          with you by your provider.
        </HelperText>
        <MatrixContainer>
          <Row>{this.renderHeaders()}</Row>
          {this.renderResults()}
        </MatrixContainer>
        {this.renderModal()}
        {this.renderContactModal()}
      </DiagnosticContainer>
    );
  }
}
